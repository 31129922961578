import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Observable, of } from 'rxjs';
import { NotificationService } from '../../../../core/providers/notification/notification.service';
import { QuestionsTransService } from '../../../../core/providers/questions-trans/questions-trans.service';
import { UtilsService } from '../../../../core/utils/utils.service';
import { I18nAvailableLanguage } from '../../../interfaces/i18n.interface';
import { StatementTranslationFromBackend } from '../../../interfaces/statements.interface';
import { QuestionType } from '../../../interfaces/survey.interface';
import { IOneMoreQuestionOptionFormat } from '../one-more-question-edit/one-more-question-format/one-more-question.format.interface';
import {
  OneMoreQuestionTimePeriod,
  OneMoreQuestionWhenToAsk,
} from '../one-more-question-edit/one-more-question.interface';
import {
  IOneMoreBasic,
  IOneMoreQuestionFormValue,
  IOneMoreQuestionGeneralBody,
  IOneMoreQuestionOptionBackend,
  IOneMoreQuestionResponseListItem,
  IQuestionFormatRawValue,
} from '../one-more-question/one-more-question.interface';
import { OneMoreQuestionService } from './one-more-question.service';

@Injectable()
export class OneMoreQuestionTransService extends QuestionsTransService {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _translateService: TranslateService;
  constructor(
    private oneMoreQuestionService: OneMoreQuestionService,
    private notificationService: NotificationService,
    private utilsService: UtilsService,
    translateService: TranslateService
  ) {
    super(translateService);
    this._translateService = translateService;
  }

  getTranslationLabel(
    translationCount: number,
    allLanguagesCount: number
  ): string {
    return this._translateService.instant('SHARED.SYSTEM.NUMB_OF_NUMB', {
      0: translationCount,
      1: allLanguagesCount,
    });
  }
  getNumberOfTranslatedLanguages(
    oneMoreQuestion:
      | IOneMoreQuestionGeneralBody
      | IOneMoreQuestionResponseListItem,
    allActiveLanguages: I18nAvailableLanguage[]
  ): number {
    let counter = 0;

    allActiveLanguages.forEach((language: I18nAvailableLanguage) => {
      const followUpQuestion: IOneMoreBasic =
        oneMoreQuestion.followUpQuestions &&
        oneMoreQuestion.followUpQuestions.length
          ? oneMoreQuestion.followUpQuestions[0]
          : null;

      if (
        this.isQuestionHasFullyTranslated(language, oneMoreQuestion) &&
        (!followUpQuestion ||
          (followUpQuestion &&
            this.isQuestionHasFullyTranslated(language, followUpQuestion)))
      ) {
        counter++;
      }
    });

    return counter;
  }

  getFormAndQuestionWithTranslations(
    currentObject: IOneMoreBasic,
    form: FormGroup,
    lang: string
  ): IOneMoreQuestionGeneralBody {
    const questionValue: IOneMoreQuestionGeneralBody = JSON.parse(
      JSON.stringify(
        currentObject
          ? currentObject
          : this.oneMoreQuestionService.preparePayload(form.value, null, lang)
      )
    );

    this.setBasicParametersFromForm(questionValue, form, lang);
    this.setMainFollowQuestionTranslation(questionValue, form, lang);
    this.setOptionsFormatTranslations(questionValue, form, lang);

    return questionValue;
  }

  checkIfSourceTranslationHasChanged$(
    formValue: IOneMoreQuestionFormValue,
    oneMoreQuestionCopy:
      | IOneMoreQuestionGeneralBody
      | IOneMoreQuestionResponseListItem,
    lang: string
  ): Observable<boolean> {
    const isGeneralQuestionHasChanged: boolean =
      formValue.question !==
      this.getQuestionTranslation(oneMoreQuestionCopy.translations, lang);
    let isFollowQuestionHasChanged = false;

    if (formValue.askFollowQuestion) {
      isFollowQuestionHasChanged =
        formValue.followQuestion !==
        (oneMoreQuestionCopy.followUpQuestions &&
        oneMoreQuestionCopy.followUpQuestions.length > 0
          ? this.getQuestionTranslation(
              oneMoreQuestionCopy.followUpQuestions[0].translations,
              lang
            )
          : null);
    }
    let isQuestionFormatOptionsHasChanged = false;

    if (formValue.questionFormat.options.length > 0) {
      isQuestionFormatOptionsHasChanged = this.isFormatOptionsChanged(
        formValue.questionFormat.options,
        oneMoreQuestionCopy.options,
        lang
      );
    }
    let isFollowQuestionFormatOptionsHasChanged = false;

    if (
      formValue.followQuestionFormat &&
      formValue.followQuestionFormat.options.length > 0
    ) {
      let followUpQuestions = oneMoreQuestionCopy.followUpQuestions && oneMoreQuestionCopy.followUpQuestions[0] ? oneMoreQuestionCopy.followUpQuestions[0].options : [];
      isFollowQuestionFormatOptionsHasChanged = this.isFormatOptionsChanged(
        formValue.followQuestionFormat.options,
        followUpQuestions,
        lang
      );
    }
    if (
      isGeneralQuestionHasChanged ||
      isFollowQuestionHasChanged ||
      isQuestionFormatOptionsHasChanged ||
      isFollowQuestionFormatOptionsHasChanged
    ) {
      return this.notificationService.displayConfirmationPopupWithTranslationKey(
        'ACCOUNT.FOLDER.LANGUAGES.MODALS.TRANSLATIONS_CHANGE_ALERT',
        'warning'
      );
    }

    return of(true);
  }

  showCannotChangeTypeEditMsg(): void {
    this.notificationService.error(
      this._translateService.instant('ONE_MORE_QUESTION.CANNOT_CHANGE_TYPE')
    );
  }

  private isFormatOptionsChanged(
    formOptions: IOneMoreQuestionOptionFormat[],
    editedOptionsTranslations: IOneMoreQuestionOptionBackend[],
    lang: string
  ): boolean {
    if (formOptions.length === editedOptionsTranslations.length) {
      for (let i = 0; i < formOptions.length; i++) {
        if (
          formOptions[i].optionValue !==
          this.getQuestionTranslation(
            editedOptionsTranslations[i].translations,
            lang
          )
        ) {
          return true;
        }
      }
    } else if (
      formOptions.length > 0 &&
      formOptions.length !== editedOptionsTranslations.length
    ) {
      return true;
    }

    return false;
  }

  private setMainFollowQuestionTranslation(
    questionValue: IOneMoreQuestionGeneralBody,
    form: FormGroup,
    lang: string
  ): void {
    const mainTranslation: StatementTranslationFromBackend =
      this.getQuestionTranslationObject(questionValue.translations, lang);

    this.setQuestionTranslation(
      questionValue.translations,
      mainTranslation,
      form.controls.question.value,
      lang
    );

    if (
      questionValue.followUpQuestions &&
      questionValue.followUpQuestions.length > 0
    ) {
      const followTranslation: StatementTranslationFromBackend =
        this.getQuestionTranslationObject(
          questionValue.followUpQuestions[0].translations,
          lang
        );

      this.setQuestionTranslation(
        questionValue.followUpQuestions[0].translations,
        followTranslation,
        form.controls.followQuestion.value,
        lang
      );
    }
  }

  private setQuestionTranslation(
    translationSet: StatementTranslationFromBackend[],
    translation: StatementTranslationFromBackend,
    formValue: string,
    lang: string
  ): void {
    if (translation) {
      translation.value = formValue;
    } else if (formValue !== '') {
      translationSet.push(
        this.oneMoreQuestionService.prepareTranslationItem(lang, formValue)
      );
    }
  }

  private isQuestionHasFullyTranslated(
    language: I18nAvailableLanguage,
    question: IOneMoreBasic
  ): boolean {
    return (
      this.isTranslatedInLanguage(language.code, question.translations) &&
      (!question.options ||
        (question.options && question.options.length === 0) ||
        (question.options.length > 0 &&
          this.isFullOptionsTranslated(
            question.type,
            question.options,
            language.code
          )))
    );
  }

  private isFullOptionsTranslated(
    questionType: QuestionType,
    options: IOneMoreQuestionOptionBackend[],
    languageCode: string
  ): boolean {
    if (
      questionType !== QuestionType.SCALE &&
      questionType !== QuestionType.BOOLEAN
    ) {
      for (let i = 0; options.length > i; i++) {
        if (
          !this.isTranslatedInLanguage(languageCode, options[i].translations)
        ) {
          return false;
        }
      }
    }

    return true;
  }

  private isTranslatedInLanguage(
    languageCode: string,
    translations: StatementTranslationFromBackend[]
  ): boolean {
    const translationInLanguage: StatementTranslationFromBackend =
      translations.find(
        (translation: StatementTranslationFromBackend) =>
          translation.lang === languageCode
      );

    return translationInLanguage && translationInLanguage.value !== '';
  }

  private setBasicParametersFromForm(
    questionValue: IOneMoreQuestionGeneralBody,
    form: FormGroup,
    lang: string
  ): void {
    questionValue.isActive = form.controls.isActive.value;
    questionValue.activeFrom =
      form.controls.activePeriod.value &&
      form.controls.activePeriod.value.id ===
        OneMoreQuestionTimePeriod.TIME_FRAME
        ? this.prepareDateForRequest(
            (form.controls.between as FormGroup).controls.dateFrom.value
          )
        : null;
    questionValue.activeTo =
      form.controls.activePeriod.value &&
      form.controls.activePeriod.value.id ===
        OneMoreQuestionTimePeriod.TIME_FRAME
        ? this.prepareDateForRequest(
            (form.controls.between as FormGroup).controls.dateTo.value
          )
        : null;
    questionValue.whenToAsk = form.controls.whenToAsk.value
      ? this.getQuestionWhenToAskValue(form)
      : null;

    if (form.controls.askFollowQuestion.value) {
      if (
        !questionValue.followUpQuestions ||
        questionValue.followUpQuestions.length === 0
      ) {
        questionValue.followUpQuestions = [
          {
            type: form.value.followQuestionFormat
              ? form.value.followQuestionFormat.formatType
              : null,
            isEnabled: form.value.askFollowQuestion,
            options: form.value.followQuestionFormat
              ? this.oneMoreQuestionService.getFormatOptionsFromForm(
                  form.value.followQuestionFormat.options,
                  lang
                )
              : null,
            translations:
              form.value.askFollowQuestion && form.value.followQuestion !== ''
                ? [
                    this.oneMoreQuestionService.prepareTranslationItem(
                      lang,
                      form.value.followQuestion
                    ),
                  ]
                : [],
          },
        ];
      } else {
        questionValue.followUpQuestions[0].type = form.value
          .followQuestionFormat
          ? form.value.followQuestionFormat.formatType
          : null;
        questionValue.followUpQuestions[0].isEnabled =
          form.value.askFollowQuestion;
      }
    } else {
      questionValue.followUpQuestions = [];
    }
  }

  private setOptionsFormatTranslations(
    questionObject: IOneMoreQuestionGeneralBody,
    form: FormGroup,
    lang: string
  ): void {
    const questionFormatValue: IQuestionFormatRawValue =
      form.controls.questionFormat.value;
    const followQuestionFormatValue: IQuestionFormatRawValue =
      form.controls.followQuestionFormat.value;

    if (questionFormatValue) {
      this.setAndGetOptionTranslations(
        questionFormatValue,
        questionObject,
        lang
      );
    }
    if (
      followQuestionFormatValue &&
      questionObject.followUpQuestions &&
      questionObject.followUpQuestions.length > 0
    ) {
      this.setAndGetOptionTranslations(
        followQuestionFormatValue,
        questionObject.followUpQuestions[0],
        lang
      );
    }
  }

  private setAndGetOptionTranslations(
    formValue: IQuestionFormatRawValue,
    question: IOneMoreBasic,
    lang: string
  ): void {
    question.type = formValue.formatType;
    question.options = question.options ? question.options : [];

    if (formValue.options && formValue.options.length > 0) {
      this.setOptionTranslations(formValue, question, lang);
    } else {
      question.options = [];
    }
  }

  private setOptionTranslations(
    formValue: IQuestionFormatRawValue,
    question: IOneMoreBasic,
    lang: string
  ): void {
    formValue.options.forEach(
      (option: IOneMoreQuestionOptionFormat, index: number) => {
        const questionOption: IOneMoreQuestionOptionBackend =
          question.options[index];

        if (
          questionOption !== undefined &&
          questionOption.translations &&
          questionOption.translations.length > 0
        ) {
          const generalTranslation: StatementTranslationFromBackend =
            questionOption.translations.find(
              (translation: StatementTranslationFromBackend) =>
                translation.lang === lang
            );

          generalTranslation.value = option.optionValue;
        } else if (
          questionOption !== undefined &&
          questionOption.translations &&
          questionOption.translations.length === 0
        ) {
          questionOption.translations.push({ lang, value: option.optionValue });
        } else {
          const translationOption: IOneMoreQuestionOptionBackend = {
            translations: [{ lang, value: option.optionValue }],
          };

          question.options.push(translationOption);
        }
      }
    );

    if (formValue.options.length < question.options.length) {
      let elementsToDelete: number =
        question.options.length - formValue.options.length;

      while (elementsToDelete--) {
        question.options.pop();
      }
    }
  }

  private getQuestionWhenToAskValue(form: FormGroup): string {
    switch (form.controls.whenToAsk.value.id) {
      case OneMoreQuestionWhenToAsk.ONLY_ONCE:
        return '1';
      case OneMoreQuestionWhenToAsk.ONLY_X_TIME_WEEKS:
      case OneMoreQuestionWhenToAsk.ONLY_X_TIME_MONTHS:
        return form.controls.whenToAskValue.value &&
          form.controls.whenToAsk.value
          ? this.oneMoreQuestionService.getWhenToAskPayloadValue(
              form.controls.whenToAskValue.value,
              form.controls.whenToAsk.value.id as OneMoreQuestionWhenToAsk
            )
          : null;
      default:
        return null;
    }
  }

  private prepareDateForRequest(date: moment.Moment): string {
    if (date && typeof date === 'string') {
      return date;
    }
    if (date) {
      return this.utilsService.prepareDateForRequest(date.toDate());
    }

    return null;
  }
}
