import { Component, HostBinding, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { ACCOUNT_SETTINGS_ROUTES_PATHS_FULL } from '../../../components/account/settings/acc-settings-routes-paths';
import { VENUE_SETTINGS_ROUTES_PATHS_FULL } from '../../../components/venue/settings/ven-settings-routes-paths';
import { VENUE_ROUTES_PATHS_FULL } from '../../../components/venue/venue-routes-paths';
import { CONSTANTS } from '../../constants';
import { RouterLinkPipe } from '../../pipes/router-link.pipe';
import { EmptyListConfig } from './empty-list.interface';
import { ContextService } from 'app/core/providers/context/context.service';

@Component({
  selector: 'app-empty-list',
  templateUrl: './empty-list.component.html',
  styleUrls: ['./empty-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [RouterLinkPipe],
})
export class EmptyListComponent implements OnInit, OnDestroy {
  @HostBinding('class.empty-list') defaultStyle = true;
  @Input() listType: string;
  @Input() isButtonVisible = true;
  @Input() contextUuid = '';
  @Input() headerTranslateKey?: string = 'SHARED.SYSTEM.HEY_ITS_EMPTY';
  @Input() useWideDescription?: boolean = false;
  // private routerLinkPipe: RouterLinkPipe;
  config: EmptyListConfig;
  accountChildrenNameSingular: string;
  accountChildrenNamePlural: string;
  description: string;
  buttonTranslation: string;
  private EMPTY_LIST = {
    DESCRIPTION: '',
    BUTTON: '',
    PATH: [],
  };
  private PATHS;
  private onDestroy$ = new Subject<void>();

  constructor(private router: Router, private translateService: TranslateService, private routerLinkPipe: RouterLinkPipe, private contextService: ContextService, private translate: TranslateService) {}

  addNew() {
    if (this.config && this.config.PATH) {
      this.router.navigate(this.config.PATH);
    }
  }

  getDescription() {
    this.description = this.config.DESCRIPTION;
    this.description = this.description.replace('{{ childrenName }}', this.accountChildrenNameSingular);
    this.description = this.description.replace('{{ childrenNames }}', this.accountChildrenNamePlural);
    return this.description;
  }

  getButtonTranslation() {
    this.buttonTranslation = this.config.BUTTON;
    this.buttonTranslation = this.buttonTranslation.replace('{{ childrenName }}', this.accountChildrenNameSingular);
    this.buttonTranslation = this.buttonTranslation.replace('{{ childrenNames }}', this.accountChildrenNamePlural);
    return this.buttonTranslation;
  }

  ngOnInit() {
    this.accountChildrenNameSingular = this.contextService.getAccountChildrenNameTranslation(true);
    this.accountChildrenNamePlural = this.contextService.getAccountChildrenNameTranslation(false);
    this.config = this.EMPTY_LIST;
    this.PATHS = this.createPaths();
    this.translateService
      .get('SHARED.EMPTY_LIST.LISTS')
      .pipe(first(), takeUntil(this.onDestroy$))
      .subscribe(translations => {
        if (translations && translations[this.listType]) {
          this.config = translations[this.listType];
          this.config.PATH = this.PATHS[this.listType];
        }
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private createPaths() {
    return {
      [CONSTANTS.EMPTY_LIST_TYPE.VENUE_USER_LIST]: [
        this.routerLinkPipe.transform(VENUE_SETTINGS_ROUTES_PATHS_FULL.USERS.CREATE, {
          [CONSTANTS.ROUTE_PARAMS.VENUE_ID]: this.contextUuid,
        }),
      ],
      [CONSTANTS.EMPTY_LIST_TYPE.ACCOUNT_USER_LIST]: [
        this.routerLinkPipe.transform(ACCOUNT_SETTINGS_ROUTES_PATHS_FULL.USERS.CREATE, {
          [CONSTANTS.ROUTE_PARAMS.ACCOUNT_ID]: this.contextUuid,
        }),
      ],
      [CONSTANTS.EMPTY_LIST_TYPE.ACCOUNT_VENUE_LIST]: [
        this.routerLinkPipe.transform(ACCOUNT_SETTINGS_ROUTES_PATHS_FULL.VENUES.CREATE, {
          [CONSTANTS.ROUTE_PARAMS.ACCOUNT_ID]: this.contextUuid,
        }),
      ],
      [CONSTANTS.EMPTY_LIST_TYPE.VENUE_NOTIFICATIONS_LIST]: [
        this.routerLinkPipe.transform(VENUE_ROUTES_PATHS_FULL.NOTIFICATIONS.ADD, {
          [CONSTANTS.ROUTE_PARAMS.VENUE_ID]: this.contextUuid,
        }),
      ],
      [CONSTANTS.EMPTY_LIST_TYPE.ACCOUNT_ONE_MORE_QUESTION_LIST]: [
        this.routerLinkPipe.transform(ACCOUNT_SETTINGS_ROUTES_PATHS_FULL.ONE_MORE_QUESTION.CREATE, {
          [CONSTANTS.ROUTE_PARAMS.ACCOUNT_ID]: this.contextUuid,
        }),
      ],
      [CONSTANTS.EMPTY_LIST_TYPE.ACCOUNT_CAMPAIGN_LIST]: [
        this.routerLinkPipe.transform(ACCOUNT_SETTINGS_ROUTES_PATHS_FULL.CAMPAIGNS.CREATE, {
          [CONSTANTS.ROUTE_PARAMS.ACCOUNT_ID]: this.contextUuid,
        }),
      ],
      [CONSTANTS.EMPTY_LIST_TYPE.ACCOUNT_CAMPAIGN_ANALYTICS_LIST]: [
        this.routerLinkPipe.transform(ACCOUNT_SETTINGS_ROUTES_PATHS_FULL.CAMPAIGNS.CREATE, {
          [CONSTANTS.ROUTE_PARAMS.ACCOUNT_ID]: this.contextUuid,
        }),
      ],
      [CONSTANTS.EMPTY_LIST_TYPE.ADMIN_USER_LIST]: ['/', CONSTANTS.ROUTES.ADMIN, CONSTANTS.ROUTES.USERS, CONSTANTS.ROUTES.CREATE],
      [CONSTANTS.EMPTY_LIST_TYPE.ADMIN_ACCOUNT_LIST]: [
        '/',
        CONSTANTS.ROUTES.ADMIN,
        CONSTANTS.ROUTES.ACCOUNT_MANAGEMENT,
        CONSTANTS.ROUTES.CREATE,
      ],
      [CONSTANTS.EMPTY_LIST_TYPE.ADMIN_DYNAMIC_QR_LIST]: [
        '/',
        CONSTANTS.ROUTES.ADMIN,
        CONSTANTS.ROUTES.DYNAMIC_QR,
        CONSTANTS.ROUTES.CREATE,
      ],
    };
  }
}
