import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { ContextObject, LoginObject, LoginPayload } from '../../../shared/interfaces/login-object';
import { AuthorizedHttp } from '../authorized-http/authorized-http.service';

@Injectable()
export class IamBackendService {
  private iamPath = `${environment.apiBaseUrl.authorization}/iam`;

  constructor(private httpClient: HttpClient, private authorizedHttp: AuthorizedHttp) {}

  loginToIam(payload: LoginPayload): Observable<LoginObject> {
    return this.httpClient.post<LoginObject>(`${this.iamPath}/auth/signin`, payload);
  }

  updateCurrentContext(userUuid: string, context: ContextObject): Observable<void> {
    return this.authorizedHttp.put(`${this.iamPath}/users/${userUuid}/defaultItem`, context).pipe(map(() => void 0));
  }

  sendResetPasswordEmail(email: string): Observable<any> {
    return this.httpClient.post<void>(`${this.iamPath}/users/send-reset-password-email`, { email });
  }

  getUserRedirectInfo(): Observable<ContextObject> {
    return this.authorizedHttp.get(`${this.iamPath}/auth/default-item`);
  }

  isAllowedToAccessTopLevelDashboard(userUuid: string): Observable<boolean> {
    return this.authorizedHttp.get(`${this.iamPath}/users/${userUuid}/access-tld`);
  }
}
