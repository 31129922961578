import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild } from '@angular/router';
import { Observable, of } from 'rxjs';
import { CONSTANTS } from '../../../shared/constants';
import { GuardsUtilsService } from './guards-utils.service';

@Injectable()
export class VenueContextGuardService implements CanActivate, CanActivateChild {
  constructor(private guardsUtilsService: GuardsUtilsService) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const { [CONSTANTS.ROUTE_PARAMS.VENUE_ID]: venueId } = route.params;

    if (!venueId) {
      return of(true);
    }

    return this.guardsUtilsService.ensureValidContextIsSet$(CONSTANTS.CONTEXT.VENUE, venueId);
  }

  canActivateChild(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.canActivate(route.parent);
  }
}
