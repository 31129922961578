import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { first, mergeMap } from 'rxjs/operators';
import { ContextService } from '../../core/providers/context/context.service';
import { NavigationService } from '../../core/providers/navigation/navigation.service';
import { CONSTANTS } from '../../shared/constants';

@Injectable()
export class VenueAdminGuard implements CanActivate, CanActivateChild {
  constructor(private navigationService: NavigationService, private contextService: ContextService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    if (state.url.endsWith(CONSTANTS.ROUTES.NOT_FOUND)) {
      return of(true);
    }

    return this.contextService.getContext$().pipe(
      first(),
      mergeMap(contextObject => {
        if (!contextObject.isUserVenueAdmin) {
          return this.refreshContextAndCheckPermissionsAgain();
        }

        return of(contextObject.isUserVenueAdmin);
      })
    );
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.canActivate(route, state);
  }

  private refreshContextAndCheckPermissionsAgain() {
    return this.contextService.refreshContext$().pipe(
      mergeMap(refreshedContextObject => {
        if (!refreshedContextObject.isUserVenueAdmin) {
          this.navigationService.goToInner404();
        }

        return of(refreshedContextObject.isUserVenueAdmin);
      })
    );
  }
}
