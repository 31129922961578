import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CONSTANTS } from '../../shared/constants';
import { SharedModule } from '../../shared/shared.module';
import { AuthGuard } from '../login/auth-guard.service';
import { HasNoAccessToFbiGuard } from '../login/has-no-access-to-fbi-guard.service';
import { NoAccessComponent } from './no-access.component';

const noAccessRoutes: Routes = [
  {
    path: CONSTANTS.ROUTES.NO_ACCESS,
    canActivate: [AuthGuard, HasNoAccessToFbiGuard],
    component: NoAccessComponent,
  },
];

@NgModule({
  declarations: [NoAccessComponent],
  imports: [RouterModule.forChild(noAccessRoutes), SharedModule],
})
export class NoAccessModule {}
