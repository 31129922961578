import { AfterViewChecked, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Auth0Result } from 'auth0-js';
import { Auth0LockStatic } from 'auth0-lock';
import { Subject, Subscription, fromEvent } from 'rxjs';
import { throwError as observableThrowError } from 'rxjs/internal/observable/throwError';
import { catchError, takeUntil } from 'rxjs/operators';
import { IamBackendService } from '../../core/providers/backend/iam-backend.service';
import { NotificationService } from '../../core/providers/notification/notification.service';
import { UnsplashApiService } from '../../core/providers/third-party-api/unsplash/unsplash-api.service';
import { CONSTANTS } from '../../shared/constants';
import { EMAIL_REG_EX } from '../../shared/custom-validators';
import { AuthService } from './auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LoginComponent implements AfterViewChecked, OnDestroy, OnInit {
  backgroundUrl: SafeStyle;
  backgroundPhotoAuthorName: string;
  backgroundPhotoAuthorLink: string;
  private subscriptions: Subscription = new Subscription();
  private isResetPasswordSubscribed = false;
  private onFormDestroy$: Subject<void> = new Subject<void>();

  constructor(
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private notificationService: NotificationService,
    private sanitizer: DomSanitizer,
    private unsplashApiService: UnsplashApiService,
    private translateService: TranslateService,
    private iamBackendService: IamBackendService,
    private router: Router
  ) {}

  ngAfterViewChecked(): void {
    this.handleResetPassword();
  }

  ngOnInit() {
    this.subscriptions.add(
      this.translateService
        .use(
          this.authService.isCorrectLangInLocalStorage()
            ? localStorage.getItem(CONSTANTS.AUTH.LANGUAGE)
            : CONSTANTS.COUNTRIES.ALFA3_TO_LANGUAGE.GBR
        )
        .subscribe(() =>
          this.subscriptions.add(
            this.activatedRoute.queryParams.subscribe(params => {
              if (params) {
                // this.resetQueryParams();
                this.logIn();
              }
            })
          )
        )
    );
    this.subscriptions.add(
      this.unsplashApiService.getRandomRestaurantCoverPhoto().subscribe(photoData => {
        this.backgroundUrl = this.sanitizer.bypassSecurityTrustStyle(`url("${photoData.urls.regular}")`);
        this.backgroundPhotoAuthorName = photoData.user.name;
        this.backgroundPhotoAuthorLink = photoData.user.links.html;
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    this.onFormDestroy$.next();
    this.onFormDestroy$.complete();
  }

  private logIn(): void {
    this.subscriptions.add(
      this.authService.logIn$().subscribe((authLock: Auth0LockStatic) => {
        authLock.on('authenticated', (result: Auth0Result) => {
          this.authService.handleAuthenticated(result);
          this.notificationService.dismissSnackBar();
        });
      })
    );
  }

  private handleResetPassword(): void {
    if (this.isInResetPasswordMode() && !this.isResetPasswordSubscribed) {
      this.setAuthMsg(null);
      this.blockSubmitBtnAndHandleResetPassword();
    }
    if (!this.isInResetPasswordMode()) {
      const btnElement: Element = this.getSubmitBtnElement();

      if (btnElement && (btnElement as any).type !== 'submit') {
        this.setBtnSubmitType(btnElement, 'submit');
      }

      this.removeSuccessMsgIfExists();
    } else {
      this.setAuthMsg(null);
    }
  }

  private removeSuccessMsgIfExists(): void {
    const elements: HTMLCollectionOf<Element> = document.getElementsByClassName('auth0-global-message-success');

    if (elements && elements.length > 0 && elements[0].parentElement.children.length > 1) {
      elements[0].parentElement.removeChild(elements[0]);
    }
  }

  private blockSubmitBtnAndHandleResetPassword(): void {
    const btnElement: Element = this.getSubmitBtnElement();

    if (btnElement) {
      this.setBtnSubmitType(btnElement, 'button');
      this.handleOnEmailInputChange();
      fromEvent(btnElement, 'click')
        .pipe(takeUntil(this.onFormDestroy$))
        .subscribe(() => {
          this.sendResetPasswordEmail();
        });
      this.isResetPasswordSubscribed = true;
    }
  }

  private setBtnSubmitType(btnElement: Element, btnType: 'button' | 'submit'): void {
    btnElement.setAttribute('type', btnType);
  }

  private getSubmitBtnElement(): Element {
    const btnElements: HTMLCollectionOf<Element> = document.getElementsByClassName('auth0-lock-submit');

    return btnElements && btnElements.length > 0 ? btnElements[0] : null;
  }

  private sendResetPasswordEmail(): void {
    if (this.isValidEmail()) {
      this.sendEmail();
    } else {
      this.showErrorMsg();
    }
  }

  private goToLoginSection(): void {
    const backBtn: HTMLElement = document.getElementById('undefined-back-button');

    if (backBtn) {
      const clickEvent: MouseEvent = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: false,
      });

      backBtn.dispatchEvent(clickEvent);
    }
  }

  private showResetPasswordResult(): void {
    const text: string = this.translateService.instant('LOGIN.EMAIL_WITH_RESET_PASSWORD_RESULT_MSG');
    const msg = `<div class="auth0-global-message auth0-global-message-success"><span class="animated fadeInUp"><span>${text}</span></span></div>`;

    this.setAuthMsg(msg);
  }

  private showErrorMsgResult(): void {
    const text: string = this.translateService.instant('SHARED.NOTIFICATIONS.ERROR_PROCESSING.TITLE');
    const msg = `<div class="auth0-global-message auth0-global-message-error"><span class="animated fadeInUp"><span>${text}</span></span></div>`;

    this.setAuthMsg(msg);
  }

  private setAuthMsg(msg: string): void {
    document.querySelector(
      '#login-auth-container > div > div > form > div > div > div > div > div.auth0-lock-content-body-wrapper > div:nth-child(1) > div'
    ).innerHTML = msg;
  }

  private isInResetPasswordMode(): boolean {
    const formElements: HTMLCollectionOf<Element> = document.getElementsByClassName('auth0-lock-input-block');

    if (formElements.length === 1) {
      return true;
    }
    if (formElements.length === 3) {
      return false;
    }

    return false;
  }

  private isValidEmail(): boolean {
    return EMAIL_REG_EX.test(this.getEmailInputValue());
  }

  private getEmailInputValue(): string {
    const inputElement: HTMLElement = document.getElementById('1-email');

    return inputElement ? (inputElement as any).value : '';
  }

  private handleOnEmailInputChange(): void {
    fromEvent(document.getElementById('1-email'), 'input')
      .pipe(takeUntil(this.onFormDestroy$))
      .subscribe((event: InputEvent) => {
        if (this.isValidEmail()) {
          this.hideErrorMsg();
        }
      });
  }

  private showErrorMsg(): void {
    const elements: HTMLCollectionOf<Element> = document.getElementsByClassName('auth0-lock-input-block');

    if (elements && elements.length > 0 && elements[0].children.length === 1) {
      elements[0].classList.add('auth0-lock-error');
      elements[0].appendChild(this.getErrorContainer());
    }
  }

  private hideErrorMsg(): void {
    const elements: HTMLCollectionOf<Element> = document.getElementsByClassName('auth0-lock-input-block');
    const childrenCount = 2;

    if (elements && elements.length > 0 && elements[0].children.length === childrenCount) {
      elements[0].removeChild(elements[0].lastChild);
      elements[0].classList.remove('auth0-lock-error');
    }
  }

  private getErrorContainer(): Element {
    const element: Element = document.createElement('div');

    element.innerHTML = `<div role="alert" id="auth0-lock-error-msg-email" class="auth0-lock-error-msg"><div class="auth0-lock-error-invalid-hint">${this.translateService.instant(
      'SHARED.FORM_ERRORS.INVALID_EMAIL'
    )}</div></div>`;

    return element;
  }

  private sendEmail(): void {
    this.iamBackendService
      .sendResetPasswordEmail(this.getEmailInputValue())
      .pipe(
        takeUntil(this.onFormDestroy$),
        catchError(() => {
          this.resetForm();
          this.showErrorMsgResult();

          return observableThrowError(null);
        })
      )
      .subscribe(() => {
        this.resetForm();
        this.showResetPasswordResult();
      });
  }

  private resetForm(): void {
    this.hideErrorMsg();
    this.goToLoginSection();
    this.onFormDestroy$.next();
    this.isResetPasswordSubscribed = false;
  }

  private resetQueryParams() {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams:  {},
      queryParamsHandling: 'merge', // remove to replace all query params by provided
    });
  }
}
