import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { debounce } from 'lodash';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { DomService } from '../../../../core/providers/dom/dom.service';
import { CONSTANTS } from '../../../constants';
import { ContentService } from './content.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'layout-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
})
export class ContentComponent implements OnInit, OnDestroy, AfterViewInit {
  @HostListener('window: resize') onResize = debounce(() => this.refreshData(), CONSTANTS.TIME_IN_MS.TIME_100);
  private headerRef: Element;
  private submitPanelRef: Element;
  private onDestroy$ = new Subject<void>();

  constructor(private elRef: ElementRef, private router: Router, private contentService: ContentService) {}

  ngAfterViewInit() {
    this.refreshData();
  }

  ngOnInit() {
    this.onRouteNavigationEnd()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => this.refreshData());
    this.isHeaderRefresh()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => this.refreshData());
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private onRouteNavigationEnd(): Observable<RouterEvent> {
    return this.router.events.pipe(filter((event: RouterEvent) => event instanceof NavigationEnd));
  }

  private isHeaderRefresh(): Observable<boolean> {
    return this.contentService.isHeaderRefresh;
  }

  private refreshData() {
    setTimeout(() => {
      this.headerRef = document.getElementById('header');
      this.submitPanelRef = document.getElementsByClassName('submit-panel')[0];
      // Actually there should be maximum one submit panel per page so it should have an ID instead of class.
      // Not mostly elegant searching for an element. Will be changed in the future.
      DomService.setStyles(this.elRef.nativeElement, [
        `top: ${this.headerRef ? this.headerRef.getBoundingClientRect().height + 1 : 0}px`,
        `bottom: ${this.submitPanelRef ? this.submitPanelRef.getBoundingClientRect().height + 1 : 0}px`,
      ]);
    }, CONSTANTS.TIME_IN_MS.TIME_1000);
  }
}
