export interface BillFolderFromBackend {
  appVersionName?: string;
  batteryLevel?: string;
  lastFoodback?: string;
  deviceId: string;
}
export interface ActivationCodeFromBackend {
  deviceActivationCode: string;
}
export interface AdvertisementForBackend {
  advertisementFileUuid: string;
  advertisementEnforcement?: boolean;
  mobile?: boolean;
  url?: string;
}
export interface BrandingForBackend {
  brandingEnforcement?: boolean;
  colorTint: string;
  logoFileUuid: string;
}
export interface VenueDemographicsConfigurationTranslationEntry {
  lang: string;
  value?: string;
}
export type VenueDemographicsConfigurationTranslations = VenueDemographicsConfigurationTranslationEntry[];
export enum VenueDemographicsConfigurationVariant {
  ALL = 'all',
  NONE = 'none',
}
export interface VenueDemographicsConfiguration {
  demographicsVariant: VenueDemographicsConfigurationVariant;
  headerTranslations: VenueDemographicsConfigurationTranslations;
  textTranslations: VenueDemographicsConfigurationTranslations;
  demographicsEnforcement?: boolean;
}
export enum LanguageCodes {
  EN_GB = 'en-GB',
  PL = 'pl',
  sv_SE = 'sv-SE',
}
export interface VenueDemographicsFields {
  lang: LanguageCodes;
  value?: string;
}
