import { Injectable } from '@angular/core';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { ApiResponse } from '../../../shared/interfaces/api-response.interface';
import {
  NewNotificationRequestBody, NotificationRecipient,
  NotificationsListResponse,
  NotificationsListResponseEntry,
} from '../../../shared/interfaces/notifications-backend.interface';
import { AuthorizedHttp } from '../authorized-http/authorized-http.service';
import { ValidationService } from './validation.service';

@Injectable()
export class NotificationsBackendService {
  private notificationsRequestPath = `${environment.apiBaseUrl.handbook}/surveys/venues`;
  private userIamPath = `${environment.apiBaseUrl.handbook}/iam/users/live-notification/recipients/venue`;

  constructor(private authorizedHttp: AuthorizedHttp, private validationService: ValidationService) {}

  addNotification(venueUuid: string, notificationBody: NewNotificationRequestBody): Observable<void> {
    return this.authorizedHttp.post(`${this.notificationsRequestPath}/${venueUuid}/live-notification`, notificationBody).pipe(
      catchError((error): Observable<ApiResponse> => {
        this.validationService.displayNotification(error);

        return observableThrowError(error);
      }),
      map(() => null)
    );
  }

  updateNotification(notificationUuid: string, notificationBody: NewNotificationRequestBody): Observable<void> {
    return this.authorizedHttp
      .put(`${environment.apiBaseUrl.handbook}/surveys/live-notification/${notificationUuid}`, notificationBody)
      .pipe(
        catchError((error): Observable<ApiResponse> => {
          this.validationService.displayNotification(error);

          return observableThrowError(error);
        }),
        map(() => null)
      );
  }

  getNotifications(venueUuid: string): Observable<NotificationsListResponse> {
    return this.authorizedHttp.get(`${this.notificationsRequestPath}/${venueUuid}/live-notification`);
  }

  changeNotificationStatus(notificationUuid: string, newStatus: boolean): Observable<void> {
    return this.authorizedHttp
      .put(`${environment.apiBaseUrl.handbook}/surveys/live-notification/${notificationUuid}/status`, {
        isEnabled: newStatus,
      })
      .pipe(map(() => null));
  }

  getSingleNotification(notificationUuid: string): Observable<NotificationsListResponseEntry> {
    return this.authorizedHttp.get(`${environment.apiBaseUrl.handbook}/surveys/live-notification/${notificationUuid}`);
  }

  getNotificationsRecipients$(notificationUuid: string, venueId: string): Observable<NotificationRecipient[]> {
    return this.authorizedHttp.get(`${this.userIamPath}/${venueId}/notification/${notificationUuid}`)
      .pipe(map(response => response.recipients));
  }
}
