import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { DateGroupPickerValue } from '../components/date-range-picker/date-range-picker.interface';
import { DateRange, DynamicDateRange } from '../interfaces/date-range.interface';

@Pipe({
  name: 'prettyDateRange',
})
export class PrettyDateRangePipe implements PipeTransform {
  transform(dateRange: DateRange, groupBy: DateGroupPickerValue): string {
    const value: DynamicDateRange = dateRange as DynamicDateRange;
    const start = this.prepareDate(value.start);
    const end = this.prepareDate(value.end);

    switch (groupBy) {
      case DateGroupPickerValue.YEAR:
        return moment(start).format('YYYY');
      case DateGroupPickerValue.MONTH:
        return moment(start).format('MMMM YYYY');
      case DateGroupPickerValue.WEEK:
        return `${moment(start).format('MMM D')} → ${moment(end).format('MMM D')}`;
      case DateGroupPickerValue.DAY: {
        if (value.start.getFullYear() === new Date().getFullYear()) {
          return moment(start).format('dddd, MMMM Do');
        }

        return moment(start).format('dddd, MMMM Do, YYYY');
      }
      default:
        return '';
    }
  }

  private prepareDate(date: Date): string {
    const day = date.getDate();
    const month = date.getMonth() + 1;

    return `${date.getFullYear()}-${this.prepareDayOrMonthString(month)}-${this.prepareDayOrMonthString(day)}`;
  }

  private prepareDayOrMonthString(value: number): string {
    // eslint-disable-next-line no-magic-numbers
    return `${value < 10 ? `0${value}` : value}`;
  }
}
