import { Injectable } from '@angular/core';
import { find } from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { VenueFromBackend } from '../../../shared/form-models-interfaces';
import { VenuePermissionsEntry } from '../../../shared/interfaces/permissions.interface';

@Injectable()
export class VenuesService {
  private venues: BehaviorSubject<Map<string, VenueFromBackend[]>> = new BehaviorSubject(new Map());
  private venuesPermissions: BehaviorSubject<Map<string, VenuePermissionsEntry[]>> = new BehaviorSubject(new Map());

  getVenues(accountUuid: string): Observable<VenueFromBackend[]> {
    return this.venues.pipe(
      map((venues: Map<string, VenueFromBackend[]>) => venues.get(accountUuid)),
      filter((venues: VenueFromBackend[]) => venues !== undefined)
    );
  }

  setVenues(accountUuid: string, data: VenueFromBackend[]) {
    const venues: Map<string, VenueFromBackend[]> = this.venues.getValue();

    venues.set(accountUuid, data);
    this.venues.next(venues);
  }

  getVenuesPermissions(accountUuid: string): Observable<VenuePermissionsEntry[]> {
    return this.venuesPermissions.pipe(
      map((venuesPermissions: Map<string, VenuePermissionsEntry[]>) => venuesPermissions.get(accountUuid)),
      filter((venuesPermissions: VenuePermissionsEntry[]) => venuesPermissions !== undefined)
    );
  }

  setVenuesPermissions(accountUuid: string, data: VenuePermissionsEntry[]) {
    const venuesPermissions: Map<string, VenuePermissionsEntry[]> = this.venuesPermissions.getValue();

    venuesPermissions.set(accountUuid, data);
    this.venuesPermissions.next(venuesPermissions);
  }

  getEnabledVenues(venues: VenueFromBackend[], venuesPermissions: VenuePermissionsEntry[]): VenueFromBackend[] {
    return venues.filter((venue: VenueFromBackend) => find(venuesPermissions, { venueUuid: venue.uuid }) !== undefined);
  }
}
