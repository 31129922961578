import { AnimationTriggerMetadata, animate, state, style, transition, trigger } from '@angular/animations';

export const fadeSlideUpAnimationDuration = 150;
export const fadeSlideUpAnimation: AnimationTriggerMetadata = trigger('fadeSlideUpAnimationState', [
  state(
    'start',
    style({
      opacity: 0,
      display: 'none',
      marginTop: '50px',
    })
  ),
  state(
    'done',
    style({
      opacity: 1,
      display: 'block',
    })
  ),
  transition(`start <=> done`, [animate(`${fadeSlideUpAnimationDuration}ms ease-in-out`)]),
]);
