import { Component, Input } from '@angular/core';
import { ValidationErrorsInputEntry } from './validation-errors.interface';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'mat-error',
  templateUrl: './validation-errors.component.html',
  styleUrls: ['./validation-errors.component.scss'],
})
export class ValidationErrorsComponent {
  @Input() set errors(value: ValidationErrorsInputEntry[]) {
    this.errorsList = value.map((error: ValidationErrorsInputEntry) => ({
      ...error,
      isLastVisibleError: false,
    }));
  }

  isManyErrors = false;
  errorsList: ValidationErrorsInputEntry[] = [];

  setHiddenFlag(flag: boolean) {
    this.isManyErrors = flag;
  }
}
