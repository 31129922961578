import { Component, Input } from '@angular/core';
import { InfoBoxStatus } from './info-box.enum';

@Component({
  selector: 'app-info-form-box',
  templateUrl: './info-form-box.component.html',
  styleUrls: ['./info-form-box.component.scss'],
})
export class InfoFormBoxComponent {
  @Input() status: InfoBoxStatus = InfoBoxStatus.INFO;
  @Input() showIcon = true;
}
