import { CONSTANTS } from '../../../shared/constants';
import { ACCOUNT_CAMPAIGNS_ROUTES_PATHS_FULL, ACCOUNT_CAMPAIGNS_ROUTES_PATHS_PARTIALS } from './acc-campaigns/acc-campaigns-routes-paths';
import {
  ACCOUNT_CONFIGURATION_ROUTES_PATHS_FULL,
  ACCOUNT_CONFIGURATION_ROUTES_PATHS_PARTIALS,
} from './acc-folder/acc-configuration-routes-paths';
import {
  ACCOUNT_ONE_MORE_QUESTION_ROUTES_PATHS_FULL,
  ACCOUNT_ONE_MORE_QUESTION_ROUTES_PATHS_PARTIALS,
} from './acc-one-more-question/acc-one-more-question-routes-paths';
import { ACCOUNT_USERS_ROUTES_PATHS_FULL, ACCOUNT_USERS_ROUTES_PATHS_PARTIALS } from './acc-users/acc-users-routes-paths';
import { ACCOUNT_VENUES_ROUTES_PATHS_FULL, ACCOUNT_VENUES_ROUTES_PATHS_PARTIALS } from './acc-venues/acc-venues-routes-paths';

export const ACCOUNT_SETTINGS_ROUTES_PATHS_PARTIALS: any = {
  BASE_PATH: `${CONSTANTS.ROUTES.ACCOUNT}/:${CONSTANTS.ROUTE_PARAMS.ACCOUNT_ID}/${CONSTANTS.ROUTES.SETTINGS}`,
  CONFIGURATION: CONSTANTS.ROUTES.CONFIGURATION,
  DETAILS: CONSTANTS.ROUTES.ACCOUNT_DETAILS,
  GOALS: CONSTANTS.ROUTES.GOALS,
  GROUPS: CONSTANTS.ROUTES.GROUPS,
  FOODBACK_QUESTIONS: CONSTANTS.ROUTES.FOODBACK_QUESTIONS,
  USERS: ACCOUNT_USERS_ROUTES_PATHS_PARTIALS.BASE_PATH,
  VENUES: ACCOUNT_VENUES_ROUTES_PATHS_PARTIALS.BASE_PATH,
  WHEEL_OF_FORTUNE: CONSTANTS.ROUTES.WHEEL_OF_FORTUNE,
  ONE_MORE_QUESTION: ACCOUNT_ONE_MORE_QUESTION_ROUTES_PATHS_PARTIALS.BASE_PATH,
  CAMPAIGNS: ACCOUNT_CAMPAIGNS_ROUTES_PATHS_PARTIALS.BASE_PATH,
};
export const ACCOUNT_SETTINGS_ROUTES_PATHS_FULL: any = {
  DETAILS: `${ACCOUNT_SETTINGS_ROUTES_PATHS_PARTIALS.BASE_PATH}/${ACCOUNT_SETTINGS_ROUTES_PATHS_PARTIALS.DETAILS}`,
  GOALS: `${ACCOUNT_SETTINGS_ROUTES_PATHS_PARTIALS.BASE_PATH}/${ACCOUNT_SETTINGS_ROUTES_PATHS_PARTIALS.GOALS}`,
  WHEEL_OF_FORTUNE: `${ACCOUNT_SETTINGS_ROUTES_PATHS_PARTIALS.BASE_PATH}/${ACCOUNT_SETTINGS_ROUTES_PATHS_PARTIALS.WHEEL_OF_FORTUNE}`,
  GROUPS: `${ACCOUNT_SETTINGS_ROUTES_PATHS_PARTIALS.BASE_PATH}/${ACCOUNT_SETTINGS_ROUTES_PATHS_PARTIALS.GROUPS}`,
  FOODBACK_QUESTIONS: `${ACCOUNT_SETTINGS_ROUTES_PATHS_PARTIALS.BASE_PATH}/${ACCOUNT_SETTINGS_ROUTES_PATHS_PARTIALS.FOODBACK_QUESTIONS}`,
  ONE_MORE_QUESTION: {
    BASE_PATH: `${ACCOUNT_SETTINGS_ROUTES_PATHS_PARTIALS.BASE_PATH}/${ACCOUNT_SETTINGS_ROUTES_PATHS_PARTIALS.ONE_MORE_QUESTION}`,
    CREATE: `${ACCOUNT_SETTINGS_ROUTES_PATHS_PARTIALS.BASE_PATH}/${ACCOUNT_ONE_MORE_QUESTION_ROUTES_PATHS_FULL.CREATE}`,
    EDIT: `${ACCOUNT_SETTINGS_ROUTES_PATHS_PARTIALS.BASE_PATH}/${ACCOUNT_ONE_MORE_QUESTION_ROUTES_PATHS_FULL.EDIT}`,
  },
  CAMPAIGNS: {
    BASE_PATH: `${ACCOUNT_SETTINGS_ROUTES_PATHS_PARTIALS.BASE_PATH}/${ACCOUNT_SETTINGS_ROUTES_PATHS_PARTIALS.CAMPAIGNS}`,
    CREATE: `${ACCOUNT_SETTINGS_ROUTES_PATHS_PARTIALS.BASE_PATH}/${ACCOUNT_CAMPAIGNS_ROUTES_PATHS_FULL.CREATE}`,
    EDIT: `${ACCOUNT_SETTINGS_ROUTES_PATHS_PARTIALS.BASE_PATH}/${ACCOUNT_CAMPAIGNS_ROUTES_PATHS_FULL.EDIT}`,
  },
  USERS: {
    BASE_PATH: `${ACCOUNT_SETTINGS_ROUTES_PATHS_PARTIALS.BASE_PATH}/${ACCOUNT_SETTINGS_ROUTES_PATHS_PARTIALS.USERS}`,
    CREATE: `${ACCOUNT_SETTINGS_ROUTES_PATHS_PARTIALS.BASE_PATH}/${ACCOUNT_USERS_ROUTES_PATHS_FULL.CREATE}`,
    LIST: `${ACCOUNT_SETTINGS_ROUTES_PATHS_PARTIALS.BASE_PATH}/${ACCOUNT_SETTINGS_ROUTES_PATHS_PARTIALS.USERS}`,
  },
  VENUES: {
    CREATE: `${ACCOUNT_SETTINGS_ROUTES_PATHS_PARTIALS.BASE_PATH}/${ACCOUNT_VENUES_ROUTES_PATHS_FULL.CREATE}`,
    LIST: `${ACCOUNT_SETTINGS_ROUTES_PATHS_PARTIALS.BASE_PATH}/${ACCOUNT_VENUES_ROUTES_PATHS_FULL.LIST}`,
  },
  CONFIGURATION: {
    BASE_PATH: `${ACCOUNT_SETTINGS_ROUTES_PATHS_PARTIALS.BASE_PATH}/${ACCOUNT_CONFIGURATION_ROUTES_PATHS_PARTIALS.BASE_PATH}`,
    BRANDING: `${ACCOUNT_SETTINGS_ROUTES_PATHS_PARTIALS.BASE_PATH}/${ACCOUNT_CONFIGURATION_ROUTES_PATHS_FULL.BRANDING}`,
    ADVERTISEMENT: `${ACCOUNT_SETTINGS_ROUTES_PATHS_PARTIALS.BASE_PATH}/${ACCOUNT_CONFIGURATION_ROUTES_PATHS_FULL.ADVERTISEMENT}`,
    DEMOGRAPHICS: `${ACCOUNT_SETTINGS_ROUTES_PATHS_PARTIALS.BASE_PATH}/${ACCOUNT_CONFIGURATION_ROUTES_PATHS_FULL.DEMOGRAPHICS}`,
    REGIONAL_SETTINGS: `${ACCOUNT_SETTINGS_ROUTES_PATHS_PARTIALS.BASE_PATH}/${ACCOUNT_CONFIGURATION_ROUTES_PATHS_FULL.REGIONAL_SETTINGS}`,
    QR_URLS: `${ACCOUNT_SETTINGS_ROUTES_PATHS_PARTIALS.BASE_PATH}/${ACCOUNT_CONFIGURATION_ROUTES_PATHS_FULL.QR_URLS}`,
  },
};
