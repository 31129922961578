import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StatementFromBackend } from '../../../../shared/interfaces/statements.interface';
import { StandardStatementsFirstPipe } from '../../../../shared/pipes/standard-statements-first.pipe';

@Injectable()
export class AccGoalsService {
  constructor(private translateService: TranslateService) {}

  sortQuestionsByStandardFirst(questions: StatementFromBackend[]): StatementFromBackend[] {
    const pipe = new StandardStatementsFirstPipe();

    return pipe.transform(questions);
  }

  getEnglishTranslationOrFirst(question: StatementFromBackend): string {
    const foundTranslation = question.translations.find(translation => translation.lang === this.translateService.currentLang);

    return foundTranslation ? foundTranslation.value : question.translations[0].value;
  }
}
