<div class="sidebar-footer">
  <span class="SidebarFooter-link mr-sm" *ngIf="!isAdminPanel">
    <img *ngIf="userData?.picture?.url; else icon" class="img-circle img-thumbnail thumb24 p0" [src]="getProfilePhoto()" />
    <ng-template #icon>
      <i class="fa fa-user thumb16 img-circle img-thumbnail SidebarFooter-missingIcon"></i>
    </ng-template>
  </span>

  <a class="SidebarFooter-link fb-user-icon" (click)="hideAdminPanel()" *ngIf="isAdminPanel">
    <i class="icon-close mr"></i>
    <span *ngIf="isAdminPanel && !isCollapsed">{{ 'SIDEBAR.FOOTER.CLOSE_SETTINGS' | translate }}</span>
  </a>

  <p class="SidebarFooter-text" *ngIf="!isCollapsed">
    <span *ngIf="!isAdminPanel">{{ userData?.name || userEmail | ellipsis: [CONSTANTS.PAGE_DEFAULTS.MAX_USER_NAME] }}</span>
  </p>

  <a class="SidebarFooter-link">
    <nav class="btn-group dropup" dropdown [dropup]="true">
      <i class="dropdown-toggle mdi mdi-dots-horizontal" dropdownToggle></i>
      <ul class="dropdown-menu" *dropdownMenu appAdjustPosition role="menu">
        <li role="menuitem">
          <a class="dropdown-item" *ngIf="hasAdminPermission" (click)="showAdminPanel()">
            <i class="mdi mdi-18px mdi-settings align-middle"></i>
            <span>{{ 'SIDEBAR.FOOTER.USER_MENU.ADMIN_PANEL' | translate }}</span>
          </a>
        </li>
        <li role="menuitem">
          <a class="dropdown-item" [routerLink]="['/', ROUTES.PROFILE]">
            <i class="mdi mdi-18px mdi-account-circle align-middle"></i>
            <span>{{ 'SIDEBAR.FOOTER.USER_MENU.USER_PROFILE' | translate }}</span>
          </a>
        </li>
        <li role="menuitem">
          <a class="dropdown-item" href="https://foodback.atlassian.net/servicedesk/customer/portals" target="_blank">
            <i class="mdi mdi-18px mdi-help-circle align-middle"></i>
            <span>{{ 'SIDEBAR.FOOTER.USER_MENU.HELP' | translate }}</span>
          </a>
        </li>
        <li class="divider dropdown-divider"></li>
        <li role="menuitem" class="dropdown-item" (click)="logOut()">
          <a class="dropdown-item">
            <i class="mdi mdi-18px mdi-logout mdi-flip-h align-middle"></i>
            <span>{{ 'SIDEBAR.FOOTER.USER_MENU.LOG_OUT' | translate }}</span>
          </a>
        </li>
      </ul>
    </nav>
  </a>
</div>
