import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { share, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { ServerEnvironmentConfig } from '../../interfaces/server-environment.interface';

@Injectable()
export class ServerEnvironmentService {
  private serverEnvironmentRequest$: Observable<ServerEnvironmentConfig>;
  private serverEnvironment: ServerEnvironmentConfig;

  constructor(private http: HttpClient) {}

  getServerEnvironmentConfig$(): Observable<Readonly<ServerEnvironmentConfig>> {
    if (this.serverEnvironment) {
      return of(this.serverEnvironment);
    }
    if (this.serverEnvironmentRequest$) {
      return this.serverEnvironmentRequest$;
    }

    this.serverEnvironmentRequest$ = this.getConfigFromServer$().pipe(
      tap(serverEnvironmentConfig => {
        this.serverEnvironment = serverEnvironmentConfig;
      }),
      share()
    );

    return this.serverEnvironmentRequest$;
  }

  private getConfigFromServer$(): Observable<ServerEnvironmentConfig> {
    return this.http.get(`${environment.apiBaseUrl.handbook}/fbi/configuration`) as Observable<ServerEnvironmentConfig>;
  }
}
