<div fxLayout="row" fxLayoutAlign="space-between center">
  <div class="SnackBar-content" fxLayout="row" fxLayoutAlign="start center" fxFlex>
    <div>
      <span [innerHTML]="data.message"></span>
    </div>
  </div>
  <div>
    <button class="SnackBar-close p0" mat-icon-button aria-label="Close" (click)="dismissSnackBar()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
