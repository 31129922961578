import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { first, mergeMap } from 'rxjs/operators';
import { ImageResolutionRequirements } from '../../../core/interfaces/image-resolution-options';
import { BillFolderBackendService } from '../../../core/providers/backend/bill-folder-backend.service';
import { ContextService } from '../../../core/providers/context/context.service';
import { CONSTANTS } from '../../constants';
import { AdvertisementForBackend } from '../../interfaces/bill-folders';

@Injectable()
export class AdvertisementService implements OnDestroy {
  private advertisementImageRequirements: ImageResolutionRequirements;
  private onDestroy$ = new Subject<void>();

  constructor(private billFolderBackendService: BillFolderBackendService, private contextService: ContextService) {
    this.setAdvertisementImageRequirements();
  }

  getAdvertisementImageRequirements(): ImageResolutionRequirements {
    return this.advertisementImageRequirements;
  }

  updateAdvertisement(payload: AdvertisementForBackend): Observable<any> {
    return this.contextService.getContext$().pipe(
      first(),
      mergeMap(context => this.billFolderBackendService.updateAdvertisement$(context.type, context.uuid, payload))
    );
  }

  setAdvertisementImageRequirements() {
    this.advertisementImageRequirements = {
      width: CONSTANTS.IMAGES.ADVERTISEMENT_WIDTH,
      height: CONSTANTS.IMAGES.ADVERTISEMENT_HEIGHT,
    };
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
