import {
  AfterViewInit,
  Component,
  EventEmitter,
  Injector,
  Input,
  Output,
  ViewEncapsulation,
  forwardRef,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { KeyboardStatusService } from '../../../core/providers/keyboard-status/keyboard-status.service';
import { ValidationErrorsInputEntry } from '../validation-errors/validation-errors.interface';

@Component({
  selector: 'app-input-with-counter',
  templateUrl: './input-with-counter.component.html',
  styleUrls: ['./input-with-counter.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputWithCounterComponent),
      multi: true,
    },
  ],
  encapsulation: ViewEncapsulation.None,
})
export class InputWithCounterComponent implements AfterViewInit, ControlValueAccessor {
  @Input() maxLength: number;
  @Input() minHeight: number;
  @Input() placeholder?: string = '';
  @Input() hint?: string = '';
  @Input() label?: string = '';
  @Input() className?: string = '';
  @Input() required = false;
  @Input() iconClass: string;
  @Input() iconName: string;
  @Input() errors: ValidationErrorsInputEntry[];

  @Input() set hostTouched(value: boolean) {
    this.updatedTouched();
  }

  @ViewChild('inputRef') inputRef;
  @Output() readonly blur: EventEmitter<void> = new EventEmitter();
  @Output() readonly focus: EventEmitter<void> = new EventEmitter();

  value: string;
  counter = 0;
  disabled = false;
  invalid = false;
  hostControl: NgControl;
  // Function.prototype is a safe reference that will always point to an empty function. This is only initial, to prevent errors
  private propagateChange: Function = Function.prototype;
  private propagateTouched: Function = Function.prototype;

  constructor(private keyboardStatusService: KeyboardStatusService, private inj: Injector) {
  }

  onTouched: Function = (_: any) => {
  };

  ngAfterViewInit(): void {
    this.hostControl = this.inj.get(NgControl);
    this.updatedTouched();
  }

  onChange(newValue: string) {
    this.value = newValue;
    this.updateCounter(newValue);
    this.propagateChange(newValue);
  }

  updateCounter(newValue: string) {
    this.counter = newValue.length;
  }

  markAsTouched(): void {
    this.keyboardStatusService.setEscModalStatus(true);
    this.propagateTouched();
  }

  writeValue(value: any): void {
    this.value = value ? String(value).slice(0, this.maxLength) : '';
    this.updateCounter(this.value);
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onFocus(): void {
    this.keyboardStatusService.setEscModalStatus(false);
    this.focus.emit();
  }

  onBlur(): void {
    this.markAsTouched();
    this.blur.emit();
  }

  private updatedTouched(): void {
    if (this.hostControl && this.hostControl.touched && this.inputRef) {
      this.inputRef.control.markAllAsTouched();
    }
  }
}
