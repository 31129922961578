import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChange, SimpleChanges } from '@angular/core';
import { Event, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { MenuService } from '../../../core/menu/menu.service';
import { SidebarMenu } from '../../../shared/interfaces/sidebar/sidebar-menu.interface';
import { SidebarService } from '../sidebar.service';
import { SidebarSettingsService } from './sidebar-settings.service';
import { ContextService } from 'app/core/providers/context/context.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
})
export class SettingsComponent implements OnInit, OnDestroy, OnChanges {
  @Input() isSidebarCollapsed: boolean;
  sidebar: SidebarMenu;
  accountChildrenNameSingular: string;
  accountChildrenNamePlural: string;

  constructor(
    menu: MenuService,
    private router: Router,
    private sidebarService: SidebarService,
    private sidebarSettingsService: SidebarSettingsService,
    private contextService: ContextService
  ) {}

  onSidebarBlur(mouseEvent: MouseEvent) {
    mouseEvent.preventDefault();

    if (this.isSidebarCollapsed) {
      this.sidebar.isHovered = false;
    }
  }

  onSidebarHover(mouseEvent: MouseEvent) {
    mouseEvent.preventDefault();

    if (this.isSidebarCollapsed) {
      this.sidebar.isHovered = true;
    }
  }

  toggleSidebar() {
    this.sidebar.isActive = this.sidebarService.isAnySubmenuActive(this.sidebar);
    this.sidebar.isExpanded = !this.sidebar.isExpanded;
  }

  ngOnChanges(changes: SimpleChanges) {
    const isSidebarCollapsedChange: SimpleChange = changes.isSidebarCollapsed;

    if (isSidebarCollapsedChange.currentValue !== isSidebarCollapsedChange.previousValue && !isSidebarCollapsedChange.firstChange) {
      this.sidebar.isHovered = false;
    }
  }

  ngOnInit() {
    this.getSettingsSidebar().subscribe((sidebar: SidebarMenu) => {
      this.sidebar = sidebar;
      this.accountChildrenNameSingular = this.contextService.getAccountChildrenNameTranslation(true);
      this.accountChildrenNamePlural = this.contextService.getAccountChildrenNameTranslation(false);
      this.sidebar.submenu.forEach((element) => {
        if (element.title.toLowerCase().includes('venue') || element.title.toLowerCase().includes('product') || element.title.toLowerCase().includes('room')) {
          element.title = 'SHARED.SYSTEM.' + this.accountChildrenNameSingular.toUpperCase() + "S";
        }
      });
    });
    this.getRouterEvents().subscribe(() => {
      this.sidebar = this.sidebarService.setActiveSidebarMenu([this.sidebar])[0];

      if (this.isSidebarCollapsed && this.sidebar.isExpanded) {
        // If sidebar is collapsed then submenus are in a 'hover mode'. Clicking one of those items should hide the submenu.
        this.sidebar.isHovered = false;
      }
    });
    this.sidebar.submenu.forEach((element) => {
      if (element.title.toLowerCase().includes('venue')) {
        if (this.accountChildrenNameSingular.toLowerCase() !== 'venue') {
          element.title = element.title.replace('VENUE', this.accountChildrenNameSingular.toUpperCase());
        }
      }
    });

  }

  ngOnDestroy() {}

  private getSettingsSidebar(): Observable<SidebarMenu> {
    return this.sidebarSettingsService.getSidebarMenu$();
  }

  private getRouterEvents(): Observable<Event> {
    return this.router.events.pipe(filter((event: RouterEvent) => event instanceof NavigationEnd));
  }
}
