import { Pipe, PipeTransform } from '@angular/core';
import { get, isNaN, isNil, isString, reverse, sortBy } from 'lodash';
import './sortByScandinavian';

@Pipe({
  name: 'sortByField',
})
export class SortByFieldPipe implements PipeTransform {
  transform<T>(value: T[], fieldName: keyof T | string, isAscending: boolean): T[] {
    const sortedCollection = this.getSortedCollection(value, fieldName as keyof T);
    const orderedCollection = isAscending
      ? sortedCollection
      : reverse(sortedCollection);

    return this.ensureEmptyElementsLast(orderedCollection, fieldName as any);
  }

  private getSortedCollection<T>(value: T[], fieldName: keyof T): T[] {
    const notEmpty: T[] = value.filter(
      (collectionElement: T ) => get(collectionElement, fieldName)
    );

    if (notEmpty.length > 0) {
      return this.getSortedCollectionByType(
        value,
        fieldName,
        isString(notEmpty[0][fieldName])
      );
    }

    return value;
  }

  private getSortedCollectionByType<T>(
    value: T[],
    fieldName: keyof T,
    isStringValue: boolean
  ): T[] {
    if (isStringValue) {
      return value.sortDa(1, fieldName);
    }

    return sortBy(value, collectionElement =>
      this.unifyElements(collectionElement, fieldName as any)
    );
  }

  private getTextValue<T>(element: T, fieldName: keyof T): string {
    return element[fieldName]
      ? (element[fieldName] as string).toLowerCase()
      : '';
  }

  private unifyElements<T>(element: T, fieldName: string): T {
    const value = get(element, fieldName);

    return isString(value) ? value.toLowerCase() : value;
  }

  private ensureEmptyElementsLast<T>(
    orderedCollection: T[],
    fieldName: string
  ) {
    return sortBy(orderedCollection, element =>
      this.isEmptyValue(get(element, fieldName))
    );
  }

  private isEmptyValue(value: any): boolean {
    return isNil(value) || isNaN(value);
  }
}
