import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { DashboardQueryParams } from '../../../../core/interfaces/dashboard-query-params.interface';
import { IDataCardWidgetScaffold } from '../data-card-widget-scaffold.interface';

@Component({
  selector: 'app-data-card-widget',
  templateUrl: './data-card-widget.component.html',
  styleUrls: ['./data-card-widget.component.scss'],
})
export class DataCardWidgetComponent extends IDataCardWidgetScaffold {
  @Input() isTitleUppercase = true;
  @Input() showIcon = true;
  @Input() isAccessToGoals: boolean;
  @Input() accountContext = false;
  @Input() canRedirectToAnotherPage = true;

  @Output() readonly onScrollTrigger = new EventEmitter();

  isVenuesExcluded = false;
  venues: string[] = [];
  venuesQuery = '&d';

  constructor(private activatedRoute: ActivatedRoute) {
    super();
    this.activatedRoute.queryParams
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((params: DashboardQueryParams) => {
        this.venues = params.venues
          ? typeof params.venues === 'string'
            ? [params.venues]
            : params.venues
          : [];
        this.isVenuesExcluded = params.isVenuesExcluded === 'true';
        this.venuesQuery =
          this.venues && this.venues.length > 0
            ? `&venues=${this.venues.join('&venues=')}&isVenuesExcluded=${
                params.isVenuesExcluded
              }`
            : '';
      });
  }

  scrollToElement(entry) {
    const isFoodbacksLink = entry.title === "SHARED.SYSTEM.FOODBACKS" ? true : false;
    this.onScrollTrigger.emit(isFoodbacksLink);
}

  getRouterLink(entry: any): any {
    var firstURLPart = entry.mainUrl.url.substring(0, entry.mainUrl.url.indexOf('all-foodbacks') + 13);
    return firstURLPart;
  }

  getParamsForRoute(entry: any): any {
    if (entry.title.includes('EMAIL')) {
      return {hasEmail: true, isVenuesExcluded: this.isVenuesExcluded, venues: this.venues};
    } else if (entry.title.includes('COMMENTS')) {
      return {hasComment: true, isVenuesExcluded: this.isVenuesExcluded, venues: this.venues};    }
    return {isVenuesExcluded: this.isVenuesExcluded, venues: this.venues}; 
  }
}
