import { Routes } from '@angular/router';
import { LoginComponent } from './login.component';
import { LoginGuard } from './login.guard';

export const LoginRoutes: Routes = [
  {
    path: '',
    component: LoginComponent,
    canActivate: [LoginGuard],
  },
  { path: 'login', component: LoginComponent, canActivate: [LoginGuard] },
];
