import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ConfirmDialogInterface } from '../../../../interfaces/confirm-dialog.interface';

export const confirmDialogConfig: MatDialogConfig = {
  width: '600px',
  height: '200px',
  disableClose: false,
};
@Component({
  selector: 'app-demographics-export-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent implements OnInit {
  constructor(private mdDialogRef: MatDialogRef<ConfirmDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogInterface) {}

  ngOnInit() {}

  hideExportModal() {
    this.mdDialogRef.close(false);
  }

  exportData() {
    this.mdDialogRef.close(true);
  }
}
