import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ContextService } from '../context/context.service';
import { NavigationService } from '../navigation/navigation.service';

@Injectable()
export class GuardsUtilsService {
  constructor(private contextService: ContextService, private navigationService: NavigationService) {}

  ensureValidContextIsSet$(contextType: string, uuid: string): Observable<boolean> {
    return this.contextService
      .ensureProperContext$(contextType, uuid)
      .pipe(map(() => true))
      .pipe(
        catchError(() => {
          this.navigationService.goToInner404();

          return this.contextService.switchToFallbackContext$();
        }),
        map(() => true),
        catchError(() => of(false))
      );
  }

  ensureFallbackContextIsSetIfNoCurrentContext$(): Observable<boolean> {
    if (this.contextService.isContextSet()) {
      return of(true);
    }

    return this.contextService.switchToFallbackContext$().pipe(map(() => true));
  }
}
