import { Pipe, PipeTransform } from '@angular/core';
import { RouteBuilderService } from '../../core/providers/backend/route-builder.service';
import { Dictionary } from '../interfaces/common.interface';

@Pipe({
  name: 'routerLink',
  pure: true,
})
export class RouterLinkPipe implements PipeTransform {
  constructor(private routeBuilderService: RouteBuilderService) {}

  transform(routePath: string, params: Dictionary<string> | [string, string][] | [string, string] = {}): string {
    if (Array.isArray(params)) {
      return this.routeBuilderService.getUrlWithParams(routePath, params);
    }

    return this.routeBuilderService.getAbsoluteRoute(routePath, params);
  }
}
