import { ChangeDetectionStrategy, Component, ElementRef, HostListener, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { cloneDeep, find } from 'lodash';
import { Observable, Subject, combineLatest } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { KeyboardStatusService } from '../../../../core/providers/keyboard-status/keyboard-status.service';
import { DropdownUtil } from '../../../../core/utils/dropdown-util';
import { KeyboardUtils } from '../../../../core/utils/events/keyboard.utils';
import { CONSTANTS } from '../../../constants';
import { FormModels } from '../../../form-models';
import { VenueFromBackend } from '../../../form-models-interfaces';
import { IDropdownItem } from '../../../interfaces/dropdown/dropdown-item.interface';
import { IGroupPayload } from '../../../interfaces/groups/add-group-payload.interface';
import { IGroup } from '../../../interfaces/groups/group.interface';
import { GroupsService } from '../groups.service';
import { LocalStorageService } from 'ngx-webstorage';
import { ContextService } from 'app/core/providers/context/context.service';

export const newGroupDialogConfig: MatDialogConfig = {
  width: '600px',
  height: '480px',
  disableClose: true,
};
@Component({
  selector: 'app-new-group-dialog',
  templateUrl: './new-group-dialog.component.html',
  styleUrls: ['./new-group-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewGroupDialogComponent implements OnInit, OnDestroy {
  @ViewChild('nameInput', { static: true }) nameInputRef: ElementRef;
  categories: IGroup[] = [];
  groups: IGroup[] = [];
  venues: VenueFromBackend[] = [];
  groupForm: FormGroup;
  selectedVenues: VenueFromBackend[] = [];
  venuesDropdownItems: IDropdownItem[] = [];
  errors = FormModels.AccountAccount.GroupErrors;
  isDisabledDropdown = false;
  filteredCategories: IGroup[];
  categoriesDropdown: IDropdownItem[] = [];
  accountChildrenNameSingular: string;
  accountChildrenNamePlural: string;

  private selectedCategoryUuid = '';
  private selectedGroup: IGroup = null;
  private fakeCategory: IGroup = null;
  private escModalStatus = false;
  private onDestroy$ = new Subject<void>();

  constructor(
    private mdDialogRef: MatDialogRef<NewGroupDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IGroup,
    private groupsService: GroupsService,
    private formBuilder: FormBuilder,
    private keyboardStatusService: KeyboardStatusService,
    private contextService: ContextService
  ) {}

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (KeyboardUtils.pressedEscape(event) && this.escModalStatus) {
      this.mdDialogRef.close();
    }
  }

  ngOnInit() {
    this.accountChildrenNameSingular = this.contextService.getAccountChildrenNameTranslation(true);
    this.accountChildrenNamePlural = this.contextService.getAccountChildrenNameTranslation(false);
    combineLatest([this.getGroupsWithCategories$(), this.getGroups$(), this.getVenues$()])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(([categories, groups, venues]) => {
        this.setCategories(categories);
        this.groups = groups;
        this.venues = venues;
        this.setForm();
        this.venuesDropdownItems = this.getActiveCategoryDropdownItem();
      });
    this.getEscModalStatus$()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(status => (this.escModalStatus = status));
  }

  onCategorySelect(selectedCategory: IDropdownItem) {
    this.selectedCategoryUuid = selectedCategory.id;
  }

  getActiveCategoryDropdownItem(): IDropdownItem[] {
    return [find(this.categoriesDropdown, { id: this.selectedCategoryUuid })];
  }

  save() {
    if (this.groupForm.valid) {
      if (this.selectedGroup) {
        this.updateExisting();
      } else {
        this.saveNew();
      }
    } else {
      this.groupForm.get('name').markAsTouched();
      this.groupForm.get('name').markAsDirty();
    }
  }

  getVenuesAsDropdownItems(venues: VenueFromBackend[]): IDropdownItem[] {
    return cloneDeep(DropdownUtil.convertToDropdownItems(venues, ['uuid', 'brandName']));
  }

  assignVenue(item: IDropdownItem) {
    this.addVenue(item);
  }

  unassignVenueFromGroup(item: IDropdownItem) {
    this.selectedVenues = this.selectedVenues.filter(venue => venue.uuid !== item.id);
  }

  getGroupsWithCategories$(): Observable<IGroup[]> {
    return this.groupsService.getGroupsWithCategories$().pipe(map(items => items.filter(item => !item.isEmptyGroup)));
  }

  getGroups$(): Observable<IGroup[]> {
    return this.groupsService.getGroups$();
  }

  getVenues$(): Observable<VenueFromBackend[]> {
    return this.groupsService.getVenues$();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private setForm() {
    const minLength = 2;

    this.groupForm = this.formBuilder.group({
      name: new FormControl('', [Validators.required, Validators.minLength(minLength), this.isUniqueGroupName.bind(this)]),
    });

    if (this.data) {
      this.selectedGroup = this.data;
      this.resetFormDialog();
      this.setData();
    } else {
      this.resetFormDialog();
    }
  }

  private setCategories(value: IGroup[]) {
    this.fakeCategory = value.find(category => !category.uuid);
    const categories: IGroup[] = [];

    if (this.fakeCategory) {
      categories.push(this.fakeCategory);
    }

    this.filteredCategories = [...categories, ...value.filter(category => category.uuid)];
    this.categoriesDropdown = DropdownUtil.convertToDropdownItems(this.filteredCategories, ['uuid', 'name']);
  }

  private isUniqueGroupName(control: AbstractControl) {
    const allGroup = [...this.filteredCategories, ...this.groups];
    const groups = this.selectedGroup ? allGroup.filter(group => group.uuid !== this.selectedGroup.uuid) : allGroup;
    const isExists = !!groups.find(category => category.name === control.value);

    return isExists ? { isUnique: {} } : null;
  }

  private updateExisting() {
    const { name, category, venues } = this.getFormData();
    const payload: IGroupPayload = {
      name,
      venues,
      isCategory: false,
      venueGroups: [],
      category: category === CONSTANTS.GROUPS.NO_CATEGORY_UUID ? null : category,
    };

    this.updateGroupOrCategory$(this.selectedGroup.uuid, payload)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(updatedCategory => this.handleResponseData(updatedCategory));
  }

  private addVenue(item: IDropdownItem) {
    const venue: VenueFromBackend = this.venues.find(v => v.uuid === item.id);

    if (venue) {
      this.selectedVenues.push(venue);
    }
  }

  private resetFormDialog() {
    this.selectedVenues = [];
    this.selectedCategoryUuid = null;
    this.venuesDropdownItems = this.getActiveCategoryDropdownItem();
    this.groupForm.reset();
    this.groupForm.controls.name.enable();
    this.isDisabledDropdown = false;

    if (this.nameInputRef) {
      setTimeout(() => {
        this.nameInputRef.nativeElement.focus();
      }, CONSTANTS.TIME_IN_MS.TIME_250);
    }
  }

  private setData() {
    this.groupForm.controls.name.setValue(this.selectedGroup.name);
    this.setVenuesFromData();
    this.selectedCategoryUuid = this.selectedGroup.category;
    this.venuesDropdownItems = this.getActiveCategoryDropdownItem();
  }

  private setVenuesFromData() {
    this.selectedGroup.venues.forEach(selectedVenue => {
      const venue: VenueFromBackend = this.venues.find(v => v.uuid === selectedVenue.uuid);

      if (venue) {
        this.selectedVenues.push(venue);
      }
    });
  }

  private saveNew() {
    const { name, category, venues } = this.getFormData();
    const payload: IGroupPayload = {
      name,
      category,
      venues,
      isCategory: false,
      venueGroups: [],
    };

    this.saveNewGroupOrCategory$(payload)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(newGroup => this.handleResponseData(newGroup));
  }

  private handleResponseData(data: IGroup) {
    if (data && data.uuid) {
      this.mdDialogRef.close();
    } else {
      this.groupForm.controls.name.enable();
      this.isDisabledDropdown = false;
    }
  }

  private getFormData(): { name: string; category: string; venues: string[] } {
    const name: string = this.groupForm.controls.name.value;
    const category: string = this.selectedCategoryUuid;
    const venues = this.selectedVenues.map(venue => venue.uuid);

    return { name, category, venues };
  }

  private saveNewGroupOrCategory$(data: IGroupPayload): Observable<any> {
    return this.groupsService.addGroup$(data);
  }

  private updateGroupOrCategory$(uuid: string, data: IGroupPayload) {
    return this.groupsService.updateGroup$(uuid, data);
  }

  private getEscModalStatus$(): Observable<boolean> {
    return this.keyboardStatusService.getEscModalStatus();
  }
}
