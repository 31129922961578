import { Pipe, PipeTransform } from '@angular/core';
import { sortBy } from 'lodash';
import { Statement, StatementFromBackend, StatementType } from '../interfaces/statements.interface';

@Pipe({
  name: 'standardStatementsFirst',
})
export class StandardStatementsFirstPipe implements PipeTransform {
  transform<T extends Statement | StatementFromBackend>(value: T[]): T[] {
    return sortBy(value, (element: T) => element.type !== StatementType.STANDARD);
  }
}
