import { Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import { CONSTANTS } from '../../../shared/constants';

@Injectable()
export class RolesService {
  private roles: string[];

  constructor(private localStorage: LocalStorageService) {}

  setRoles(roles: string[]): void {
    this.roles = roles;
    this.localStorage.store(CONSTANTS.STORAGE.ROLES, roles);
  }

  isAdmin(): boolean {
    if (!this.roles) {
      this.retrieveRoles();
    }

    return this.roles.includes(CONSTANTS.ROLES.ADMIN);
  }

  private retrieveRoles(): void {
    this.roles = this.localStorage.retrieve(CONSTANTS.STORAGE.ROLES) || [];
  }
}
