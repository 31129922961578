import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { map, sortBy } from 'lodash';
import { AccountForBackend, VenueFromBackend } from '../../../shared/form-models-interfaces';
import { IDropdownItem } from '../../../shared/interfaces/dropdown/dropdown-item.interface';

@Injectable()
export class LevelFilterService {
  private static readonly ALL_ENTITIES = 'all';

  constructor(private translateService: TranslateService) {}

  createArrayOfAccounts(accountsList: AccountForBackend[]): IDropdownItem[] {
    const defaultItem = this.getAllAccountsItem();
    const enabledAccounts = accountsList.filter(account => account.isEnabled);
    const arrayOfAccounts: IDropdownItem[] = this.sortNgSelectArray(
      map(enabledAccounts, (singleAccount: AccountForBackend) => ({
        id: singleAccount.uuid,
        text: singleAccount.companyName,
      }))
    );

    arrayOfAccounts.unshift(defaultItem);

    return arrayOfAccounts;
  }

  createArrayOfVenues(venuesList: VenueFromBackend[]): IDropdownItem[] {
    const defaultItem = this.getAllVenuesItem();
    const enabledVenues = venuesList.filter(venue => venue.isEnabled);
    const arrayOfVenues: IDropdownItem[] = this.sortNgSelectArray(
      map(enabledVenues, (singleVenue: VenueFromBackend) => ({
        id: singleVenue.uuid,
        text: singleVenue.brandName,
      }))
    );

    arrayOfVenues.unshift(defaultItem);

    return arrayOfVenues;
  }

  isAllEntitiesItem(item: IDropdownItem): boolean {
    return item.id === LevelFilterService.ALL_ENTITIES;
  }

  getAllVenuesItem(): IDropdownItem {
    return {
      id: LevelFilterService.ALL_ENTITIES,
      text: this.translateService.instant('SHARED.SYSTEM.ALL_VENUES'),
    };
  }

  getAllAccountsItem(): IDropdownItem {
    return {
      id: LevelFilterService.ALL_ENTITIES,
      text: this.translateService.instant('SHARED.COMPONENTS.LEVEL_FILTER.ALL_ACCOUNTS'),
    };
  }

  private sortNgSelectArray(ngSelectArray: IDropdownItem[]): IDropdownItem[] {
    return sortBy(ngSelectArray, (singleVenue: IDropdownItem) => singleVenue.text.toLowerCase());
  }
}
