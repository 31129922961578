import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { PermissionsBackendService } from '../../core/providers/backend/permissions-backend.service';
import { RolesService } from '../../core/providers/backend/roles.service';
import { NavigationService } from '../../core/providers/navigation/navigation.service';
import { AuthService } from './auth.service';

@Injectable()
export class HasAccessToFbiGuard implements CanActivate, CanActivateChild {
  constructor(
    private navigationService: NavigationService,
    private rolesService: RolesService,
    private permissionsBackendService: PermissionsBackendService,
    private authService: AuthService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    if (!this.authService.authenticated()) {
      this.navigationService.goToLogin();

      return of(false);
    }
    if (this.rolesService.isAdmin()) {
      return of(true);
    }

    return this.permissionsBackendService.hasAccess$().pipe(
      mergeMap((hasAccess: boolean) => {
        if (!hasAccess) {
          this.navigationService.goToNoAccess();
        }

        return of(hasAccess);
      })
    );
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.canActivate(route, state);
  }
}
