<div class="GoalSlider">
  <span>{{ MIN_VALUE }}</span>
  <nouislider
    class="GoalSlider-slider"
    [min]="MIN_VALUE"
    [max]="MAX_VALUE"
    [step]="STEP"
    [ngModel]="value"
    (ngModelChange)="onChange($event)"
    [connect]="[true, false]"></nouislider>
  <span>{{ MAX_VALUE }}</span>
  <input
    class="GoalSlider-input"
    type="number"
    [ngModel]="value"
    (ngModelChange)="onChange($event)"
    [min]="MIN_VALUE"
    [max]="MAX_VALUE"
    [step]="STEP" />
</div>
