import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-acc-enforcement-switcher',
  templateUrl: './acc-enforcement-switcher.component.html',
  styleUrls: ['./acc-enforcement-switcher.component.scss'],
})
export class AccEnforcementSwitcherComponent {
  @Output() readonly enforcementStatusChanged: EventEmitter<boolean> = new EventEmitter();
  @Input() isActivated: boolean;
  @Input() isLocked = true;

  sendEnforcementStateToParent(state: boolean) {
    this.enforcementStatusChanged.emit(state);
  }
}
