import { TitleCasePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { Router } from '@angular/router';
import { UserProfileContextGuardService } from '../components/user-profile/user-profile-context-guard.service';
import { DateRangePickerService } from '../shared/components/date-range-picker/date-range-picker.service';
import { FoodbackOverviewService } from '../shared/components/overview/foodback-overview';
import { FoodbacksWithThisQuestionService } from '../shared/components/overview/foodbacks-with-this-question/foodbacks-with-this-question.service';
import { QuestionDetailsService } from '../shared/components/overview/question-details/question-details.service';
import { TableProgressBarService } from '../shared/components/table-progress-bar.service';
import { MenuService } from './menu/menu.service';
import { AccountService } from './providers/account/account.service';
import { ApiService } from './providers/api/api.service';
import { AuthorizedHttp } from './providers/authorized-http/authorized-http.service';
import { AccountAnalyticsBackendService } from './providers/backend/account-analytics-backend.service';
import { AccountBackendService } from './providers/backend/account-backend.service';
import { AccountSingleVenueBackendService } from './providers/backend/account-single-venue-backend.service';
import { AdminAnalyticsBackendService } from './providers/backend/admin-analytics-backend.service';
import { AnalyticsBackendService } from './providers/backend/analytics/analytics-backend.service';
import { BillFolderBackendService } from './providers/backend/bill-folder-backend.service';
import { CategoriesBackendService } from './providers/backend/categories-backend.service';
import { DashboardQueryParamsService } from './providers/backend/dashboard-query-params.service';
import { DynamicQrService } from './providers/backend/dynamic-qr.service';
import { FileSaverService } from './providers/backend/file-saver-service.service';
import { GoalsBackendService } from './providers/backend/goals-backend.service';
import { GoogleAnalyticsService } from './providers/backend/google-analytics.service';
import { GroupsBackendService } from './providers/backend/groups-backend.service';
import { I18nBackendService } from './providers/backend/i18n-backend.service';
import { IamBackendService } from './providers/backend/iam-backend.service';
import { IntercomService } from './providers/backend/intercom.service';
import { NotificationsBackendService } from './providers/backend/notifications-backend.service';
import { PermissionsBackendService } from './providers/backend/permissions-backend.service';
import { RolesService } from './providers/backend/roles.service';
import { RouteBuilderService } from './providers/backend/route-builder.service';
import { SharedAnalyticsBackendService } from './providers/backend/shared-analytics-backend.service';
import { StatementsBackendService } from './providers/backend/statements-backend.service';
import { TooltipsHelperService } from './providers/backend/tooltips-helper.service';
import { UploadService } from './providers/backend/upload.service';
import { UsersBackendService } from './providers/backend/users-backend.service';
import * as Utilities from './utils';
import { UtilsService } from './utils/utils.service';
import { ValidationService } from './providers/backend/validation.service';
import { VenueAnalyticsBackendService } from './providers/backend/venue-analytics-backend.service';
import { VenueContextGuardService } from './providers/context-guards/venue-context-guard.service';
import { VenueTypesBackendService } from './providers/backend/venue-types-backend.service';
import { WheelOfFortuneBackendService } from './providers/backend/wheel-of-fortune-backend.service';
import { CommonService } from './providers/common/common.service';
import { AccountContextGuardService } from './providers/context-guards/account-context-guard.service';
import { FallbackContextGuardService } from './providers/context-guards/fallback-context-guard.service';
import { GuardsUtilsService } from './providers/context-guards/guards-utils.service';
import { SingleVenueModeGuardService } from './providers/context-guards/single-venue-mode-guard.service';
import { ContextService } from './providers/context/context.service';
import { DomService } from './providers/dom/dom.service';
import { DomainService } from './providers/domain/domain.service';
import { GenericErrorHandlerService } from './providers/generic-error-handler/generic-error-handler.service';
import { LevelFilterService } from './providers/level-filter/level-filter.service';
import { NavigationService } from './providers/navigation/navigation.service';
import { VenueNavigationService } from './providers/navigation/venue-navigation.service';
import { NotificationService } from './providers/notification/notification.service';
import { ServerEnvironmentService } from './providers/server-environment/server-environment.service';
import { UnsplashApiService } from './providers/third-party-api/unsplash/unsplash-api.service';
import { UserService } from './providers/user/user.service';
import { VenueUsersService } from './providers/venue-users/venue-users.service';
import { VenuesService } from './providers/venues/venues.service';
import { TopLevelDashboardGuard } from "../components/top-level-dashboard/top-level-dashboard.guard";

@NgModule({
  providers: [
    {
      provide: NotificationService,
      useClass: NotificationService,
      // deps: [ToasterService]
    },
    AuthorizedHttp,
    AccountAnalyticsBackendService,
    AccountBackendService,
    AccountContextGuardService,
    AccountService,
    AccountSingleVenueBackendService,
    AdminAnalyticsBackendService,
    AnalyticsBackendService,
    ApiService,
    BillFolderBackendService,
    CategoriesBackendService,
    ContextService,
    Utilities.ContextUtil,
    DashboardQueryParamsService,
    DateRangePickerService,
    DomainService,
    DomService,
    FallbackContextGuardService,
    FileSaverService,
    GenericErrorHandlerService,
    GoalsBackendService,
    GoogleAnalyticsService,
    GuardsUtilsService,
    I18nBackendService,
    IamBackendService,
    IntercomService,
    LevelFilterService,
    MenuService,
    NavigationService,
    NotificationsBackendService,
    PermissionsBackendService,
    RolesService,
    RouteBuilderService,
    ServerEnvironmentService,
    SharedAnalyticsBackendService,
    SingleVenueModeGuardService,
    StatementsBackendService,
    TableProgressBarService,
    TooltipsHelperService,
    UnsplashApiService,
    UploadService,
    UsersBackendService,
    UserService,
    VenueTypesBackendService,
    Utilities.ChartUtilsService,
    Utilities.EditUtilService,
    Utilities.FormsUtil,
    Utilities.LayoutUtil,
    {
      provide: Utilities.RouterUtilities,
      useClass: Utilities.RouterUtilities,
      deps: [Router],
    },
    ValidationService,
    VenueAnalyticsBackendService,
    VenueContextGuardService,
    VenueNavigationService,
    VenuesService,
    VenueUsersService,
    GroupsBackendService,
    WheelOfFortuneBackendService,
    CommonService,
    DynamicQrService,
    UtilsService,
    QuestionDetailsService,
    FoodbacksWithThisQuestionService,
    FoodbackOverviewService,
    UserProfileContextGuardService,
    TitleCasePipe,
    TopLevelDashboardGuard,
  ],
})
export class CoreModule {}
