import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, fromEvent } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

const MAX_SCROLL_VALUE = 100;

@Component({
  selector: 'app-scroll-hint',
  templateUrl: './scroll-hint.component.html',
  styleUrls: ['./scroll-hint.component.scss'],
})
export class ScrollHintComponent implements AfterViewInit, OnInit, OnDestroy {
  isScrolled = false;
  showScrollHint = false;
  private resizeObserver: ResizeObserver;
  private onDestroy$: Subject<void> = new Subject<void>();

  ngOnInit(): void {
    this.resizeObserver = new ResizeObserver((entries: ResizeObserverEntry[]) => {
      this.showScrollHint = entries[0].target.clientHeight < entries[0].target.scrollHeight;
    });
  }

  ngAfterViewInit(): void {
    const layoutRef: HTMLCollectionOf<Element> = document.getElementsByTagName('layout-content');

    if (layoutRef.length > 0) {
      this.resizeObserver.observe(layoutRef[0]);
      fromEvent(layoutRef[0], 'scroll', { capture: true })
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(() => this.showOrHideHint(layoutRef[0].scrollTop));
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private showOrHideHint(value: number): void {
    this.isScrolled = value > MAX_SCROLL_VALUE;
  }
}
