import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { SidebarMenu } from '../../../shared/interfaces/sidebar/sidebar-menu.interface';
import { SidebarConstants } from '../sidebar.constants';
import { SidebarService } from '../sidebar.service';

@Component({
  selector: 'app-sidebar-admin',
  templateUrl: './sidebar-admin.component.html',
})
export class SidebarAdminComponent {
  @Input() isSidebarCollapsed: boolean;
  sidebars: SidebarMenu[] = SidebarConstants.ADMIN.SIDEBAR;

  constructor(private router: Router, private sidebarService: SidebarService) {}

  onSidebarBlur(sidebar: SidebarMenu, mouseEvent: MouseEvent) {
    mouseEvent.preventDefault();

    if (this.isSidebarCollapsed) {
      sidebar.isExpanded = false;
    }
  }

  onSidebarHover(sidebar: SidebarMenu, mouseEvent: MouseEvent) {
    mouseEvent.preventDefault();

    if (this.isSidebarCollapsed) {
      sidebar.isExpanded = true;
    }
  }

  toggleSidebar(targetedSidebar: SidebarMenu) {
    this.sidebars = this.sidebars.map((sidebar: SidebarMenu) => {
      sidebar.isActive = sidebar === targetedSidebar;
      sidebar.isExpanded = false;

      return sidebar;
    });
    targetedSidebar.isExpanded = !targetedSidebar.isExpanded;

    if (!this.sidebarService.doesMenuHaveSubmenu(targetedSidebar)) {
      this.router.navigateByUrl(targetedSidebar.link);
    }
  }
}
