import { Injectable } from '@angular/core';
import { Observable, combineLatest as observableCombineLatest, of } from 'rxjs';
import { first, map, shareReplay, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { CONSTANTS } from '../../../shared/constants';
import { VenueCategoryDetails } from '../../../shared/interfaces/analytics.interface';
import { AccountService } from '../account/account.service';
import { ApiService } from '../api/api.service';

@Injectable()
export class CategoriesBackendService {
  private cachedCategories: Map<string, VenueCategoryDetails> = new Map<string, VenueCategoryDetails>();
  private contextUuid: string;
  private categoriesCachedRequest: Observable<VenueCategoryDetails[]>;

  constructor(private apiService: ApiService, private accountService: AccountService) {}

  getAllCategories(contextUuid: string, contextType: string, selectedGroupsUuids?: string[]): Observable<VenueCategoryDetails[]> {
    if (this.contextUuid === contextUuid && this.cachedCategories.size) {
      return of(Array.from(this.cachedCategories.values()));
    }

    this.clearCachedData();

    if (this.contextUuid === contextUuid && this.categoriesCachedRequest) {
      return this.categoriesCachedRequest;
    }

    this.contextUuid = contextUuid;
    const context = contextType === CONSTANTS.CONTEXT.ACCOUNT ? CONSTANTS.ENDPOINTS.ACCOUNTS : CONSTANTS.ENDPOINTS.VENUE;

    this.categoriesCachedRequest = this.apiService
      .get$(`${environment.apiBaseUrl.handbook}/surveys/categories/${context}/${contextUuid}`)
      .pipe(
        map(response => response.content),
        tap((categories: VenueCategoryDetails[]) => {
          categories.forEach(category => {
            this.cachedCategories.set(category.uuid, category);
          });
        }),
        shareReplay(1)
      );

    return this.categoriesCachedRequest;
  }

  getAllCategoriesForLoggedAccount(
    contextUuid: string,
    contextType: string,
    selectedGroupsUuids?: string[]
  ): Observable<VenueCategoryDetails[]> {
    return observableCombineLatest([
      this.accountService.getAccount$().pipe(first()),
      this.getAllCategories(contextUuid, contextType, selectedGroupsUuids),
    ]).pipe(map(([account, categories]) => categories.filter(category => category.categoryType === account.venueType.uuid)));
  }

  getCategoryDetails(categoryUuid: string, contextUuid: string, contextType: string): Observable<VenueCategoryDetails> {
    if (!this.cachedCategories.has(categoryUuid)) {
      const context = contextType === CONSTANTS.CONTEXT.ACCOUNT ? CONSTANTS.ENDPOINTS.ACCOUNTS : CONSTANTS.ENDPOINTS.VENUE;

      return this.apiService.get$(`${environment.apiBaseUrl.handbook}/surveys/categories/${categoryUuid}/${context}/${contextUuid}`).pipe(
        map(response => response.content),
        tap((category: VenueCategoryDetails) => {
          this.cachedCategories.set(category.uuid, category);
        })
      );
    }

    return of(this.cachedCategories.get(categoryUuid));
  }

  getOverallExperienceDetails(contextUuid: string, contextType: string, selectedGroupsUuids?: string[]): Observable<VenueCategoryDetails> {
    return this.getAllCategoriesForLoggedAccount(contextUuid, contextType, selectedGroupsUuids).pipe(
      map(categories => categories.find(category => category.overallExperience))
    );
  }

  clearCachedData() {
    this.cachedCategories = new Map<string, VenueCategoryDetails>();
  }
}
