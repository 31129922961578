<div class="Groups">
  <div id="header" class="content-heading empty-list-heading">
    <p class="heading-text">{{ 'SHARED.SYSTEM.GROUPS' | translate }}</p>
  </div>
  <layout-content>
    <div class="row">
      <div class="Groups__groups-list col-sm-12 col-md-6 col-md-push-6 mb-xl">
        <div class="page-header">
          <h4 class="mt0 d-inline-block Groups-header">{{ 'SHARED.SYSTEM.GROUPS' | translate }}</h4>
          <div class="d-inline-block pull-right">
            <a (click)="showAddCategoryDialog(null)" class="btn btn-foodback btn-foodback--secondary text-uppercase mr-sm">
              {{ 'ACCOUNT.GROUPS.BUTTONS.CREATE_CATEGORY' | translate }}
            </a>
            <a (click)="showAddGroup(null)" class="btn btn-foodback text-uppercase"> {{ 'SHARED.GROUPS.NEW_GROUP' | translate }} </a>
          </div>
        </div>
        <p class="Groups-desc">
          <span>{{ 'ACCOUNT.GROUPS.DESCRIPTION.GROUPS' | translate: { childrenNamePlural: accountChildrenNamePlural, childrenName: accountChildrenNameSingular } }}</span>
        </p>
        <app-groups-list #groupsListComponent (onSelectedGroup)="onSelectedGroup($event)"></app-groups-list>
      </div>

      <div class="Groups__venues-list col-sm-12 col-md-6 col-md-pull-6">
        <div class="page-header">
          <h4 class="mt0 Groups-header">{{ 'ACCOUNT.GROUPS.LISTS.VENUES.HEADER' | translate: { childrenName: accountChildrenNameSingular } }}</h4>
        </div>
        <p class="Groups-desc">
          <span>{{ 'ACCOUNT.GROUPS.DESCRIPTION.VENUES' | translate: { childrenName: accountChildrenNameSingular } }}</span>
        </p>

        <app-venues-list></app-venues-list>
      </div>
    </div>
  </layout-content>
</div>
