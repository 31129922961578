import { Injectable } from '@angular/core';
import { chain, isString } from 'lodash';

@Injectable()
export class DomService {
  static setStyles(element: HTMLElement | string, styles: string[]) {
    const elementRef: HTMLElement = isString(element) ? document.getElementById(element) : element;
    const uniqueStyles: string = chain([...styles, elementRef.getAttribute('style')].join(';'))
      .split(';')
      .uniqBy((attribute: string) => attribute.split(':')[0])
      .join(';')
      .value();

    if (elementRef) {
      elementRef.setAttribute('style', uniqueStyles);
    } else {
      console.error('HTML element has not been found.');
    }
  }

  static removeAttributes(element: HTMLElement | string, attributes: string[]) {
    const elementRef: HTMLElement = isString(element) ? document.getElementById(element) : element;

    if (elementRef) {
      attributes.forEach((attribute: string) => elementRef.removeAttribute(attribute));
    } else {
      console.error('HTML element has not been found.');
    }
  }

  static addClass(element: HTMLElement | string, classNames: string[]) {
    const elementRef: HTMLElement = isString(element) ? document.getElementById(element) : element;

    if (elementRef) {
      classNames.forEach((className: string) => elementRef.classList.add(className));
    } else {
      console.error('HTML element has not been found.');
    }
  }

  static removeClass(element: HTMLElement | string, classNames: string[]) {
    const elementRef: HTMLElement = isString(element) ? document.getElementById(element) : element;

    if (elementRef) {
      classNames.forEach((className: string) => elementRef.classList.remove(className));
    } else {
      console.error('HTML element has not been found.');
    }
  }

  static getComputedStyle(element: HTMLElement | string): CSSStyleDeclaration {
    const elementRef: HTMLElement = isString(element) ? document.getElementById(element) : element;

    if (elementRef) {
      return window.getComputedStyle(elementRef);
    }

    console.error('HTML element has not been found.');
  }
}
