import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AccountForBackend, UserForBackend, VenueForm } from '../../shared/form-models-interfaces';

@Injectable()
export class EditUtilService {
  private chosenVenue$: BehaviorSubject<VenueForm> = new BehaviorSubject(null);
  private chosenAccount$: BehaviorSubject<AccountForBackend> = new BehaviorSubject(null);
  private chosenUser$: BehaviorSubject<UserForBackend> = new BehaviorSubject(null);

  setChosenVenue(newValue: VenueForm) {
    this.chosenVenue$.next(newValue);
  }

  getChosenAccount$(): Observable<AccountForBackend> {
    return this.chosenAccount$.pipe(filter(item => item !== null));
  }

  setChosenAccount(newValue: AccountForBackend) {
    this.chosenAccount$.next(newValue);
  }

  getChosenUser$(): Observable<UserForBackend> {
    return this.chosenUser$.pipe(filter(item => item !== null));
  }

  setChosenUser(newValue: UserForBackend) {
    this.chosenUser$.next(newValue);
  }
}
