import { Component, EventEmitter, Output } from '@angular/core';
import { ISwitcherItem } from '../../switcher-large/switcher-item.interface';
import { AdvertisementCategories } from './advertisement-categories.enum';

@Component({
  selector: 'app-advertisement-category-switcher',
  templateUrl: './advertisement-category-switcher.component.html',
  styleUrls: ['./advertisement-category-switcher.component.scss'],
})
export class AdvertisementCategorySwitcherComponent {
  @Output() readonly onSelect: EventEmitter<AdvertisementCategories> = new EventEmitter();
  readonly categories: ISwitcherItem<AdvertisementCategories>[] = [
    {
      id: AdvertisementCategories.BILL_FOLDER,
      text: 'Bill Folder',
    },
    {
      id: AdvertisementCategories.MOBILE,
      text: 'Mobile',
    },
  ];

  handleSwitcherSelection(item: ISwitcherItem<AdvertisementCategories>) {
    this.onSelect.emit(item.id);
  }
}
