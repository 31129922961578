import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild } from '@angular/router';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { PermissionsBackendService } from '../../core/providers/backend/permissions-backend.service';
import { GuardsUtilsService } from '../../core/providers/context-guards/guards-utils.service';
import { CONSTANTS } from '../../shared/constants';
import { PermissionsEntry } from '../../shared/interfaces/permissions.interface';

@Injectable()
export class UserProfileContextGuardService implements CanActivate, CanActivateChild {
  constructor(private guardsUtilsService: GuardsUtilsService, private permissionsBackendService: PermissionsBackendService) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const { [CONSTANTS.ROUTE_PARAMS.ACCOUNT_ID]: accountId } = route.params;
    const sessionContextUuid = sessionStorage.getItem(CONSTANTS.LOCAL_STORAGE_KEYS.CONTEXT_UUID);
    const sessionContextType = sessionStorage.getItem(CONSTANTS.LOCAL_STORAGE_KEYS.CONTEXT_TYPE);

    if (!accountId) {
      return this.permissionsBackendService.getPermissions$().pipe(
        mergeMap(permissions => {
          const accPermission = this.getAndSetIfAllowAccountContext(sessionContextUuid, sessionContextType, permissions);

          if (accPermission && accPermission.hasAccountAdminPermissions) {
            return this.guardsUtilsService.ensureValidContextIsSet$(CONSTANTS.CONTEXT.ACCOUNT, accPermission.accountUuid);
          }
          if ((accPermission && !accPermission.hasAccountAdminPermissions) || !accPermission) {
            if (sessionContextType === CONSTANTS.CONTEXT.VENUE && sessionContextType) {
              return this.guardsUtilsService.ensureValidContextIsSet$(CONSTANTS.CONTEXT.VENUE, sessionContextUuid);
            }

            return this.permissionsBackendService.getVenuePermissions$(permissions[0].accountUuid).pipe(
              mergeMap(venuePermissions => {
                if (venuePermissions && venuePermissions.length > 0) {
                  return this.guardsUtilsService.ensureValidContextIsSet$(CONSTANTS.CONTEXT.VENUE, venuePermissions[0].venueUuid);
                }

                return of(true);
              })
            );
          }

          return of(true);
        })
      );
    }

    return this.guardsUtilsService.ensureValidContextIsSet$(CONSTANTS.CONTEXT.ACCOUNT, accountId);
  }

  canActivateChild(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.canActivate(route);
  }

  private getAndSetIfAllowAccountContext(
    sessionContextUuid: string,
    sessionContextType: string,
    permissions: PermissionsEntry[]
  ): PermissionsEntry {
    if (sessionContextType === CONSTANTS.CONTEXT.ACCOUNT) {
      const selectedPermission = permissions.find(permission => permission.accountUuid === sessionContextUuid);

      if (selectedPermission) {
        return selectedPermission;
      }
      if (permissions && permissions.length > 0) {
        return permissions[0];
      }
    }

    return null;
  }
}
