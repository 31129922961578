import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import * as fromActivateGroupDialog from '../../../components/account/settings/acc-folder/acc-folder-qr-urls/activate-group-dialog/activate-group-dialog.component';
import * as fromFoodbackCustomerContactDialog from '../../../components/venue/foodbacks/all-foodbacks/components/foodback-update-contact/foodback-update-contact.component';
import { ConfirmDialogInterface } from '../../interfaces/confirm-dialog.interface';
import { IGroup } from '../../interfaces/groups/group.interface';
import { ImageCropperInterface } from '../../interfaces/image-cropper.interface';
import { Statement } from '../../interfaces/statements.interface';
import * as fromCampaignQuestionDialog from '../campaigns/campaign-question-translate-dialog/campaign-question-translate-dialog.component';
import * as fromCampaignWelcomeScreenDialog from '../campaigns/campaign-welcome-screen-translate-dialog/campaign-welcome-screen-translate-dialog.component';
import {
  ICampaignQuestionTranslateInput,
  ICampaignWelcomeScreenTranslateInput,
} from '../campaigns/campaigns.interface';
import * as fromConfirmDialog from '../dialog/dialogs/confirm-dialog/confirm-dialog.component';
import * as fromAddQuestionDialog from '../foodback-questions/add-question-modal/add-question-modal.component';
import * as fromRenameCategories from '../foodback-questions/rename-categories-modal/rename-categories-modal.component';
import { RenameCategoriesModalInput } from '../foodback-questions/rename-categories-modal/rename-categories-modal.interface';
import * as fromNewGroupCategoryDialog from '../groups/new-category-dialog/new-category-dialog.component';
import * as fromNewGroupDialog from '../groups/new-group-dialog/new-group-dialog.component';
import { IOneMoreQuestionTranslationDialogData } from '../one-more-question/one-more-question/one-more-question.interface';
import * as fromOneMoreQuestionLanguagesDialog from '../one-more-question/one-more-question-edit/one-more-question-languages-dialog/one-more-question-languages-dialog.component';
import * as fromProfileAvatarDialog from '../profile-avatar/components/profile-avartar-cropper-dialog/profile-avatar-cropper-dialog.component';
import {
  customContactDialogConfig,
  FoodbackUpdateContactComponent,
} from '../../../components/venue/foodbacks/all-foodbacks/components/foodback-update-contact/foodback-update-contact.component';
import {FoodbackStatistic} from "../../../components/venue/foodbacks/all-foodbacks/foodback-statistic";

@Injectable()
export class DialogService {
  constructor(private matDialog: MatDialog) {}

  newGroupCategory(data: IGroup) {
    return this.matDialog.open(
      fromNewGroupCategoryDialog.NewCategoryDialogComponent,
      {
        data,
        ...fromNewGroupCategoryDialog.newCategoryDialogConfig,
      }
    );
  }

  newGroup(data: IGroup) {
    return this.matDialog.open(fromNewGroupDialog.NewGroupDialogComponent, {
      data,
      ...fromNewGroupDialog.newGroupDialogConfig,
    });
  }

  questionDialog(data: Statement) {
    return this.matDialog.open(
      fromAddQuestionDialog.AddQuestionModalComponent,
      {
        data,
        ...fromAddQuestionDialog.addQuestionDialogConfig,
      }
    );
  }

  renameCategoriesDialog(data: RenameCategoriesModalInput) {
    return this.matDialog.open(
      fromRenameCategories.RenameCategoriesModalComponent,
      {
        data,
        ...fromRenameCategories.renameCategoriesDialogConfig,
      }
    );
  }

  profileAvatarCropperDialog(data: ImageCropperInterface) {
    return this.matDialog.open(
      fromProfileAvatarDialog.ProfileAvatarCropperDialogComponent,
      {
        data,
        ...fromProfileAvatarDialog.profileAvatarCropperDialogConfig,
      }
    );
  }

  confirmDialog(data: ConfirmDialogInterface) {
    return this.matDialog.open(fromConfirmDialog.ConfirmDialogComponent, {
      data,
      ...fromConfirmDialog.confirmDialogConfig,
    });
  }

  activeGroupDialog() {
    return this.matDialog.open(
      fromActivateGroupDialog.ActivateGroupDialogComponent,
      fromActivateGroupDialog.activateGroupDialogConfig
    );
  }

  oneMoreQuestionLanguagesDialog(
    data: IOneMoreQuestionTranslationDialogData
  ): MatDialogRef<any> {
    return this.matDialog.open(
      fromOneMoreQuestionLanguagesDialog.OneMoreQuestionLanguagesDialogComponent,
      {
        data,
        ...fromOneMoreQuestionLanguagesDialog.oneMoreQuestionLanguagesDialogConfig,
      }
    );
  }

  campaignQuestionLanguagesDialog(
    data: ICampaignQuestionTranslateInput
  ): MatDialogRef<any> {
    return this.matDialog.open(
      fromCampaignQuestionDialog.CampaignQuestionTranslateDialogComponent,
      {
        data,
        ...fromCampaignQuestionDialog.campaignQuestionLanguagesDialogConfig,
      }
    );
  }

  campaignWelcomeScreenLanguagesDialog(
    data: ICampaignWelcomeScreenTranslateInput
  ): MatDialogRef<any> {
    return this.matDialog.open(
      fromCampaignWelcomeScreenDialog.CampaignWelcomeScreenTranslateDialogComponent,
      {
        data,
        ...fromCampaignWelcomeScreenDialog.campaignWelcomeScreenLanguagesDialogConfig,
      }
    );
  }

  updateContactCustomerDialog(data: FoodbackStatistic): MatDialogRef<any> {
    return this.matDialog.open(
      fromFoodbackCustomerContactDialog.FoodbackUpdateContactComponent,
      {
        data,
        ...fromFoodbackCustomerContactDialog.customContactDialogConfig,
      }
    );
  }
}
