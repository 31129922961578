import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ISwitcherItem } from './switcher-item.interface';

@Component({
  selector: 'app-switcher-large',
  templateUrl: './switcher-large.component.html',
  styleUrls: ['./switcher-large.component.scss'],
})
export class SwitcherLargeComponent {
  @Input() items: ISwitcherItem[] = [];
  @Input() activeItem: ISwitcherItem;
  @Output() readonly onSelect: EventEmitter<ISwitcherItem> = new EventEmitter();

  selectItem(item: ISwitcherItem) {
    this.activeItem = item;
    this.onSelect.emit(item);
  }
}
