<app-data-card-widget-panel class="DataCardWidget DataCardWidgetOverall">
  <ng-container header>
    <div>
      <span class="align-middle">{{ title | translate }}</span>
    </div>
  </ng-container>
  <div class="DataCardWidget-contentContainer" content>
    <app-percent-circle
      class="DataCardWidget-circle"
      [goal]="goal"
      [useCustomContent]="true"
      [percentValue]="percentValue"
      [variant]="PercentCircleVariant.BLUE"
    >
      <div custom-content>
      <span
        appTooltip [text]="'SHARED.TOP_LEVEL_DASHBOARD.MAIN_SCORE_INFO' | translate"
        class="h1">{{dataStatisticsSet?.overallExperienceAverage || 0 | number: CONSTANTS.NUMBER_PIPE.DEFAULT_FORMAT}}</span>
        <span class="h1" *ngIf="dataStatisticsSet?.prevOverallExperienceAverage">
        <ng-container [ngTemplateOutlet]="arrowIndicator"
                      [ngTemplateOutletContext]="{isHigher: dataStatisticsSet?.overallExperienceAverage > dataStatisticsSet?.prevOverallExperienceAverage}">
        </ng-container>
      </span>
        <br/>
        <span *ngIf="dataStatisticsSet?.prevOverallExperienceAverage" class="h4 fb-text-info">
        {{ 'SHARED.TOP_LEVEL_DASHBOARD.PREV_VALUE' | translate:{'0': (dataStatisticsSet?.prevOverallExperienceAverage | number: CONSTANTS.NUMBER_PIPE.DEFAULT_FORMAT)} }}
      </span>
      </div>
      <ng-template #arrowIndicator let-isHigher="isHigher">
        <i *ngIf="isHigher" class="mdi mdi-arrow-up DataCardWidget-arrow--up"></i>
        <i *ngIf="!isHigher" class="mdi mdi-arrow-down DataCardWidget-arrow--down"></i>
      </ng-template>
    </app-percent-circle>
    <div *ngIf="dataStatisticsSet" class="bt DataCardWidget-statistics">
      <div
        *ngFor="let entry of dataStatisticsSet.entries"
        class="DataCardWidgetStatisticsEntry"
      >
        <div class="DataCardWidgetStatisticsEntry-number">
          <a
            *ngIf="entry.mainUrl && entry.mainUrl.url"
            class="DataCardWidgetStatisticsEntry-link"
            [href]="entry.mainUrl.url"
          >{{ entry.data | number: CONSTANTS.NUMBER_PIPE.INTEGERS }}</a
          >
          <span *ngIf="!entry.mainUrl || (entry.mainUrl && !entry.mainUrl.url)">{{
            entry.data
            }}</span>
        </div>
        <div class="DataCardWidgetStatisticsEntry-text">
          {{ entry.title | translate }}
        </div>
      </div>
    </div>
  </div>
</app-data-card-widget-panel>
