import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { UserService } from '../../../core/providers/user/user.service';
import { LayoutUtil } from '../../../core/utils';
import { CONSTANTS } from '../../../shared/constants';
import { UserData } from '../../../shared/interfaces/login-object';
import { UserblockService } from './userblock.service';

@Component({
  selector: 'app-userblock',
  templateUrl: './userblock.component.html',
  styleUrls: ['./userblock.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UserblockComponent implements OnDestroy, OnInit {
  subscriptions: Subscription = new Subscription(null);
  userData: UserData = null;
  userEmail: string;
  CONSTANTS = CONSTANTS;

  constructor(public userblockService: UserblockService, private layoutUtil: LayoutUtil, private userService: UserService) {}

  toggleAdminPanel() {
    this.layoutUtil.toggleIsAdminPanel();
  }

  ngOnInit() {
    this.subscriptions.add(
      this.userService.getUserData$().subscribe(userData => {
        this.userData = userData;
        this.userEmail = this.userService.getUserEmail();
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  userBlockIsVisible() {
    return this.userblockService.getVisibility();
  }
}
