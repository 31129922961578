import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CONSTANTS } from './shared/constants';

@Injectable()
export class TemplatePageTitleStrategy extends TitleStrategy {
  constructor(
    private readonly title: Title,
    private translateService: TranslateService
  ) {
    super();
  }

  updateTitle(routerState: RouterStateSnapshot): void {
    const title = this.buildTitle(routerState);
    const contextName: string = this.getContextName(
      routerState.url.split('/')[1]
    );

    if (title !== undefined) {
      this.title.setTitle(
        `${title}${contextName ? ` - ${contextName}` : ''} - Foodback`
      );
    }
  }

  private getContextName(contextType: string): string {
    switch (contextType) {
      case CONSTANTS.CONTEXT.ACCOUNT:
        return this.translateService.instant('SHARED.SYSTEM.ACCOUNT');
      case CONSTANTS.CONTEXT.VENUE:
        return this.translateService.instant('SHARED.SYSTEM.VENUE');
      case CONSTANTS.CONTEXT.ADMIN:
        return this.translateService.instant('SHARED.SYSTEM.ADMIN');
      default:
        return null;
    }
  }
}
