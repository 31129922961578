import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'commaDecimalSeparator',
})
export class CommaDecimalSeparatorPipe implements PipeTransform {
  transform(value: string | number): string {
    if (value === 0) {
      return '0';
    }

    return value ? value.toString().replace(/\./g, ',') : '';
  }
}
