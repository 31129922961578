import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { DateGroupPickerValue } from '../../../shared/components/date-range-picker';
import { DynamicDateRange, StaticDateRange, StaticDateRangePeriod } from '../../../shared/interfaces/date-range.interface';

@Injectable()
export class ChartStaticRangeService {
  static getNarrowedDateRange(day: Date, dateRange: StaticDateRange): DynamicDateRange {
    switch (dateRange.period) {
      case StaticDateRangePeriod.ALL_TIME:
        // changing from all time date range will always point towards specific month
        return {
          start: moment(day).startOf('month').toDate(),
          end: moment(day).endOf('month').toDate(),
          groupBy: DateGroupPickerValue.MONTH,
        };
      default:
        return {
          start: moment(day).startOf('day').toDate(),
          end: moment(day).endOf('day').toDate(),
          groupBy: DateGroupPickerValue.DAY,
        };
    }
  }
}
