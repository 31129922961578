import { Injectable } from '@angular/core';
import { pick } from 'lodash';
import { Observable, throwError as observableThrowError, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { RenameCategoriesStatementModalOutput } from '../../../shared/components/foodback-questions/rename-categories-modal/rename-categories-modal.interface';
import { CONSTANTS } from '../../../shared/constants';
import {
  ModifiedStatementFormForBackend,
  NewStatementFormForBackend,
  Statement,
  StatementActivationChangeStatus,
  StatementModifiedStatus,
  StatementSavedStatus,
  StatementsFromBackend,
} from '../../../shared/interfaces/statements.interface';
import { ApiService } from '../api/api.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '../notification/notification.service';

@Injectable()
export class StatementsBackendService {
  private readonly ACCOUNT_PATH = `${environment.apiBaseUrl.handbook}/surveys/accounts`;
  private readonly VENUE_PATH = `${environment.apiBaseUrl.handbook}/surveys/venues`;
  private readonly CATEGORY_PATH = `${environment.apiBaseUrl.handbook}/surveys/survey-category-account/accounts`;

  constructor(private apiService: ApiService, private translateService: TranslateService, private notificationService: NotificationService) {}

  getActiveStatementsForAccount$(accountUuid: string): Observable<StatementsFromBackend> {
    return this.apiService.get$(`${this.ACCOUNT_PATH}/${accountUuid}/statements`).pipe(map(response => response.content));
  }

  getActiveStatementsForVenue$(venueUuid: string): Observable<StatementsFromBackend> {
    return this.apiService.get$(`${this.VENUE_PATH}/${venueUuid}/statements`).pipe(map(response => response.content));
  }

  addNewStatement$(ownerUuid: string, statementObj: NewStatementFormForBackend): Observable<StatementSavedStatus> {
    const requestPath = `${environment.apiBaseUrl.handbook}/surveys/accounts/${ownerUuid}/${CONSTANTS.ENDPOINTS.STATEMENTS}`;

    return this.apiService.post$(requestPath, pick(statementObj, ['categoryUuid', 'translations'])).pipe(map(response => response.content));
  }

  editStatement$(questionUuid: string, statementObj: ModifiedStatementFormForBackend): Observable<StatementModifiedStatus> {
    const requestPath = `${environment.apiBaseUrl.handbook}/surveys/${CONSTANTS.ENDPOINTS.STATEMENTS}/${questionUuid}`;

    return this.apiService
      .put$(requestPath, {
        ...pick(statementObj, ['translations']),
        accountUuid: statementObj.accountUuid,
      })
      .pipe(map(response => ({ ...response.content, questionCopy: statementObj })));
  }

  deleteStatement$(statementUuid: string, accountUuid: string): Observable<void> {
    const requestPath = `${environment.apiBaseUrl.handbook}/surveys/${CONSTANTS.ENDPOINTS.STATEMENTS}/${statementUuid}/accounts/${accountUuid}`;

    return this.apiService.delete$(requestPath).pipe(map(response => response.content));
  }

  changeStatementStatusForAccount$(accountUuid: string, statement: Statement): Observable<StatementActivationChangeStatus> {
    // eslint-disable-next-line max-len
    const requestPath = `${this.ACCOUNT_PATH}/${accountUuid}/${CONSTANTS.ENDPOINTS.STATEMENTS}/${statement.uuid}/${CONSTANTS.ENDPOINTS.ASSIGNMENTS}`;
    return this.apiService
      .post$(requestPath, {
        isEnabled: statement.isEnabled,
        isEnforced: statement.isEnforced,
      })
      .pipe(map(response => response.content))
      .pipe(
        catchError((error) => {
          if (error.error.errorCode === 2400) {
            this.notificationService.errorWithTranslationKey('SHARED.NOTIFICATIONS.DEACTIVATE_RESULT_FAILURE');
          }
          return of(error.error.errorCode);
        })
      );
  }

  changeStatementStatusForVenue$(venueUuid: string, statement: Statement): Observable<StatementActivationChangeStatus> {
    // eslint-disable-next-line max-len
    const requestPath = `${this.VENUE_PATH}/${venueUuid}/${CONSTANTS.ENDPOINTS.STATEMENTS}/${statement.uuid}/${CONSTANTS.ENDPOINTS.ASSIGNMENTS}`;

    return this.apiService
      .post$(requestPath, {
        isEnabled: statement.isEnabled,
        isEnforced: statement.isEnforced,
      })
      .pipe(map(response => response.content))
      .pipe(
        catchError((error) => {
          if (error.error.errorCode === 2400) {
            this.notificationService.errorWithTranslationKey('SHARED.NOTIFICATIONS.DEACTIVATE_RESULT_FAILURE');
          }
          return of(error.error.errorCode);
        })
      );
  }

  renameCategoriesNames$(accountUuid: string, categories: RenameCategoriesStatementModalOutput[]): Observable<void> {
    return this.apiService.put$(`${this.CATEGORY_PATH}/${accountUuid}`, { categories }).pipe(map(response => response.content));
  }
}
