<app-dialog>
  <app-dialog-title> {{ data.headerText }} </app-dialog-title>
  <app-dialog-content> {{ data.contentText }} </app-dialog-content>
  <app-dialog-actions>
    <button
      type="button"
      class="btn btn-transparent pull-right text-uppercase"
      [attr.aria-label]="data.cancelBtnText"
      (click)="hideExportModal()">
      {{ data.cancelBtnText }}
    </button>
    <button type="button" class="btn btn-foodback pull-right" [attr.aria-label]="data.confirmBtnText" (click)="exportData()">
      {{ data.confirmBtnText }}
    </button>
  </app-dialog-actions>
</app-dialog>
