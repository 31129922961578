import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { date } from 'ng2-validation/dist/date';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  IFusionChartCategories,
  IFusionChartDataSet,
} from '../../../../../vendor/types/fusionCharts';
import { ChartUtilsService } from '../../../../core/utils';
import {
  AccountScores,
  IVenueScores,
} from '../../../interfaces/analytics.interface';
import { Dictionary } from '../../../interfaces/common.interface';
import { DynamicDateRange } from '../../../interfaces/date-range.interface';
import { CommaDecimalSeparatorPipe } from '../../../pipes/comma-decimal-separator.pipe';
import { DateRangePickerService } from '../../date-range-picker/date-range-picker.service';
import {
  FoodbacksScoreChartLabelsAndDates,
  FoodbacksScoreChartRawData,
} from './foodbacks-score-chart.interface';
import { FoodbacksScoreChartService } from './foodbacks-score-chart.service';

@Component({
  selector: 'app-foodbacks-score-chart',
  templateUrl: './foodbacks-score-chart.component.html',
  styleUrls: ['./foodbacks-score-chart.component.scss'],
  providers: [
    FoodbacksScoreChartService,
    DateRangePickerService,
    CommaDecimalSeparatorPipe,
  ],
})
export class FoodbacksScoreChartComponent implements OnDestroy, OnChanges {
  /**
   * This component is required to be inside relatively positioned container.
   */

  @Input() set data(value: IVenueScores) {
    if (value) {
      this.setData(value);
    }
  }

  @Input() set accountScores(value: AccountScores) {
    if (value) {
      this.rawData.accountAvgScores =
        this.foodbackScoreChartService.getChartDataForCurrentScores(
          value.currentScores,
          this.dateRange
        );
      this.updateChartData();
    }
  }

  @Input() dateRange: DynamicDateRange;
  @Input() translateKey = 'HOME.OVERVIEW.SHARED.FOODBACK_CHART';
  @Input() goal: number;
  @Input() height = '100%';
  rawData: FoodbacksScoreChartRawData = {};
  chartCategories: IFusionChartCategories[];
  chartDataSet: IFusionChartDataSet[];
  dataSource: any;
  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    private translateService: TranslateService,
    private foodbackScoreChartService: FoodbacksScoreChartService,
    private chartUtilsService: ChartUtilsService
  ) {
    this.initChart();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dateRange && changes.dateRange.currentValue) {
      this.handleDateRangeChanged();
      const value: IVenueScores = {
        currentCount: [],
        currentScores: [],
        previousScores: [],
      };

      this.setData(value);
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private handleDateRangeChanged(): void {
    const { chartLabels }: FoodbacksScoreChartLabelsAndDates =
      this.chartUtilsService.getChartLabelsAndDates(this.dateRange);

    this.chartDataSet = null;
    this.setChartCategories(chartLabels);
  }

  private updateChartData(): void {
    setTimeout(() => {
      this.translateService
        .get(this.translateKey)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((labels: Dictionary<string>) => {
          this.chartDataSet =
            this.foodbackScoreChartService.getChartDataSetFromRawData(
              this.rawData,
              labels
            );
          this.dataSource.categories = this.chartCategories;
          this.dataSource.dataset = this.chartDataSet;
          this.dataSource.chart.numvdivlines =
            this.chartDataSet[0].data.length.toString();
        });
    });
  }

  private setData(data: IVenueScores): void {
    if (data) {
      this.rawData.currentScores =
        this.foodbackScoreChartService.getChartDataForCurrentScores(
          data.currentScores,
          this.dateRange
        );
      this.rawData.currentAnswersCount =
        this.foodbackScoreChartService.getChartDataForCurrentScores(
          data.currentCount,
          this.dateRange
        );
      this.rawData.previousScores =
        this.foodbackScoreChartService.getChartDataForPreviousScores(
          data.previousScores,
          this.dateRange
        );
      this.updateChartData();
    }
  }

  private setChartCategories(chartLabels: string[]): void {
    this.chartCategories = [
      {
        category: chartLabels.map((label: string) => ({ label })),
      },
    ];
    this.dataSource.categories = this.chartCategories;
  }

  private initChart(): void {
    const additionalOptions: any = {
      usePlotGradientColor: '0',
      anchorRadius: '3',
      pYAxisMaxValue: '6.0',
      forceSYAxisValueDecimals: '0',
      forceYAxisValueDecimals: '0',
      sYAxisValueDecimals: '1',
      sDecimals: '1',
      setAdaptiveYMin: '1',
      outCnvBaseFontSize: '15',
      labelDisplay: 'rotate',
      slantLabel: '1'
    };

    this.dataSource =
      this.chartUtilsService.initChartWithDataSetAndCategories(
        additionalOptions
      );
  }
}
