import { Component, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CONSTANTS } from '../../shared/constants';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PageNotFoundComponent {
  constructor(private translateService: TranslateService) {
    this.translateService.use(localStorage.getItem(CONSTANTS.AUTH.LANGUAGE) || CONSTANTS.COUNTRIES.ALFA3_TO_LANGUAGE.GBR);
  }
}
