import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { CONSTANTS } from '../../constants';
import { IDropdownItem } from '../../interfaces/dropdown/dropdown-item.interface';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent implements OnInit, OnDestroy {
  @Input('activeItems') set setActiveItems(data: IDropdownItem[]) {
    if (this.itemsCtrl && data && data.length > 0) {
      if (this.multipleChoice || this.isSingleAndAllModeEnable) {
        this.itemsCtrl.setValue(data, { emitEvent: false });
      } else {
        this.itemsCtrl.setValue(data[0], { emitEvent: false });
      }
    }
  }

  @Input() set disabled(val: boolean) {
    this._disabled = val;

    if (val) {
      this.itemsCtrl.disable({ emitEvent: false });
    } else {
      this.itemsCtrl.enable({ emitEvent: false });
    }
  }
  @Input() set items(value: IDropdownItem[]) {
    if (!value || (value && value.length === 0) || this._disabled) {
      this.itemsCtrl.disable({ emitEvent: false });
    } else {
      this.itemsCtrl.enable({ emitEvent: false });
    }

    this.dropdownItems = value;
  }
  @Input() multipleChoice = false;
  @Input() isSearchEnabled = false;

  @Input() placeholder: string = this.translate.instant('SHARED.SYSTEM.SELECT_ITEM');
  @Input() label: string = this.translate.instant('SHARED.SYSTEM.SELECT_ITEM');
  @Input() required = false;
  @Input() isSingleAndAllModeEnable = false;
  @Input() allSelectedText = '';
  @Input() searchPlaceholder: string;

  @Output() readonly onSelect: EventEmitter<IDropdownItem | IDropdownItem[]> = new EventEmitter();
  itemsCtrl: FormControl = new FormControl([]);
  dropdownItems: IDropdownItem[] = [];
  private subscriptions: Subscription;
  private _disabled = false;

  constructor(private translate: TranslateService, private cdf: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.subscriptions = new Subscription();
    this.subscriptions.add(
      this.itemsCtrl.valueChanges.subscribe(value => {
        this.propagateChange(value);
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private propagateChange(value: IDropdownItem | IDropdownItem[]) {
    const allItem: IDropdownItem = {
      id: CONSTANTS.SELECT_ITEM_NAMES.ALL,
      text: '',
    };
    const item = this.isSingleAndAllModeEnable && Array.isArray(value) && value.length === this.dropdownItems.length ? allItem : value;

    this.onSelect.emit(item);
    this.cdf.detectChanges();
  }
}
