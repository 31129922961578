import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { chain, sortBy } from 'lodash';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { PermissionsBackendService } from '../../core/providers/backend/permissions-backend.service';
import { RouteBuilderService } from '../../core/providers/backend/route-builder.service';
import { UsersBackendService } from '../../core/providers/backend/users-backend.service';
import { CONSTANTS } from '../../shared/constants';
import { AccountForBackend, VenueFromBackend } from '../../shared/form-models-interfaces';
import { ContextObject } from '../../shared/interfaces/login-object';
import { PermissionsEntry, VenuePermissionsEntry } from '../../shared/interfaces/permissions.interface';
import { SidebarMenu } from '../../shared/interfaces/sidebar/sidebar-menu.interface';
import { SidebarAccountPermissions } from './sidebar.interface';

@Injectable()
export class SidebarService {
  constructor(
    private usersBackendService: UsersBackendService,
    private permissionsBackend: PermissionsBackendService,
    private router: Router,
    private routeBuilderService: RouteBuilderService
  ) {}

  getSidebarMenu(sidebarMenu: SidebarMenu[], isAccountContext: boolean, uuid: string): SidebarMenu[] {
    return chain(sidebarMenu)
      .thru((data: SidebarMenu[]) => this.setSidebarsURLS(data, isAccountContext, uuid))
      .thru((data: SidebarMenu[]) => this.sortSidebarMenus(data))
      .thru((data: SidebarMenu[]) => this.setActiveSidebarMenu(data))
      .value();
  }

  doesMenuHaveSubmenu(sidebar: SidebarMenu): boolean {
    return sidebar.submenu.length > 0;
  }

  getAccountDataFromVenueUuid$(venueUuid: string): Observable<Partial<ContextObject>> {
    return this.usersBackendService.getSingleVenue$(venueUuid).pipe(
      mergeMap(venueResponse => this.usersBackendService.getSingleAccount$(venueResponse.accountUuid)),
      mergeMap((accountResponse: AccountForBackend) =>
        of({
          uuid: accountResponse.uuid,
          accountName: accountResponse.companyName,
        })
      )
    );
  }

  getVenueListForAccount$(accountUuid: string): Observable<Partial<VenueFromBackend>[]> {
    return this.getVenuesListFromAccountPermissions$(accountUuid);
  }

  simplifyAccountPermissionsFromBackend(permissions: PermissionsEntry[]): SidebarAccountPermissions {
    return permissions.reduce<SidebarAccountPermissions>((reduced, accountPermissionsEntry) => {
      reduced[accountPermissionsEntry.accountUuid] = {
        canChangeToAccount: accountPermissionsEntry.permissions.permission_account_use,
      };

      return reduced;
    }, {});
  }

  setSidebarsURLS(sidebarMenus: SidebarMenu[], isAccountContext: boolean, uuid: string): SidebarMenu[] {
    return sidebarMenus.map((sidebarMenu: SidebarMenu) => {
      if (sidebarMenu.submenu.length) {
        this.setSidebarsURLS(sidebarMenu.submenu, isAccountContext, uuid);
      } else {
        sidebarMenu.link = this.getSubmenuURL(isAccountContext, sidebarMenu, uuid);
      }

      return sidebarMenu;
    });
  }

  sortSidebarMenus(sidebarMenus: SidebarMenu[]): SidebarMenu[] {
    return chain(sidebarMenus)
      .sortBy('order')
      .map((sidebarMenu: SidebarMenu) => ({
        ...sidebarMenu,
        submenu: sortBy(sidebarMenu.submenu, 'order'),
      }))
      .value();
  }

  setActiveSidebarMenu(sidebars: SidebarMenu[]): SidebarMenu[] {
    return sidebars.map((sidebar: SidebarMenu) => {
      const isSidebarURLActive: boolean = this.isSidebarMenuURLActive(sidebar) || this.isAnySubmenuActive(sidebar);

      sidebar.isActive = isSidebarURLActive;
      sidebar.isExpanded = isSidebarURLActive;

      if (sidebar.submenu.length) {
        sidebar.submenu = this.setActiveSidebarMenu(sidebar.submenu);
      }

      return sidebar;
    });
  }

  isAnySubmenuActive(sidebarMenu: SidebarMenu): boolean {
    return sidebarMenu.submenu.some((submenu: SidebarMenu) => this.isSidebarMenuURLActive(submenu));
  }

  isAccountWithCampaign(uuid: string): boolean {
    return true;
    // if (!environment.production) {
    //   return true;
    // }

    // return !!this.getCampaignAccountUuids().find((accountUuid: string) => accountUuid === uuid);
  }

  private getCampaignAccountUuids(): string[] {
    return [
      '34b119f4-e726-460c-b171-00a23bf16b40',
      '5d3dc116-63c4-4891-aa2f-d1cd060d3cb8',
      '1d854f48-06c4-4d27-8ed0-a1700443a92d',
      'd2505137-a452-4124-b0f0-c3b7fb95b7ea',
      '40825788-87aa-4994-bd91-ea037a930800',
      'a0922327-5a4c-4dda-af2d-43ac3e683ef8',
      '3d91a930-9b00-4053-94a9-d15910307e3d',
      'db8e937f-9a93-4d67-a44b-fa5f453c3fd6',
      '49127444-28e7-4272-bded-ff77303f949e',
      'db8e937f-9a93-4d67-a44b-fa5f453c3fd6',
      '4e7a6058-185c-4744-8d4b-09f6401a951c',
      '6a9dd611-6491-45cd-8ef4-bfa70cb29444',
      '16926181-0557-4b34-b091-15aed83b3211',
      '97fa3eae-c137-469a-b917-076bd50d3265',
      'c7cc8b10-b8b9-42f5-9f83-6865ea2c8316',
      'be9dacd1-5af0-4f07-b4e9-2f39ed15d4c9',
      '77e211a1-c0a9-4332-b62d-cc28a8baa9fa',
      '3516796c-b5dc-4b4c-a48a-821de61fc9c5',
      '4c69e81c-837c-47e6-b798-c2dd10343829'
    ];
  }

  private isSidebarMenuURLActive(submenu: SidebarMenu): boolean {
    return !!submenu.link && this.router.isActive(submenu.link, false);
  }

  private getVenuesListFromAccountPermissions$(accountUuid: string): Observable<Partial<VenueFromBackend>[]> {
    return this.permissionsBackend.getVenuePermissions$(accountUuid).pipe(
      mergeMap((venuePermissionsResponse: VenuePermissionsEntry[]) => {
        const mappedResponse = venuePermissionsResponse.map(venuePermissionsEntry => ({
          uuid: venuePermissionsEntry.venueUuid,
          brandName: venuePermissionsEntry.venueName,
        }));

        return of(mappedResponse);
      })
    );
  }

  private getSubmenuURL(isAccountContext: boolean, submenu: SidebarMenu, uuid: string): string {
    const URLParam: string = isAccountContext ? CONSTANTS.ROUTE_PARAMS.ACCOUNT_ID : CONSTANTS.ROUTE_PARAMS.VENUE_ID;

    return this.routeBuilderService.getAbsoluteRoute(submenu.link, {
      [URLParam]: uuid,
    });
  }
}
