import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { date } from 'ng2-validation/dist/date';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import {
  IFusionChartCategories,
  IFusionChartCategory,
  IFusionChartData,
  IFusionChartDataSet,
} from '../../../../vendor/types/fusionCharts';
import { ChartUtilsService } from '../../../core/utils';
import {
  AccountScores,
  ITopLevelScores,
  IVenueScores,
} from '../../interfaces/analytics.interface';
import { Dictionary } from '../../interfaces/common.interface';
import { DynamicDateRange } from '../../interfaces/date-range.interface';
import { CommaDecimalSeparatorPipe } from '../../pipes/comma-decimal-separator.pipe';
import { DateRangePickerService } from '../date-range-picker/date-range-picker.service';
import {
  TrendlineChartTopLevelLabelsAndDates,
  TrendlineChartTopLevelRawData,
} from './trendline-chart-top-level.interface';
import { TrendlineChartTopLevelService } from './trendline-chart-top-level.service';
import { DashboardDatesService } from 'app/components/venue/venue-shared/dashboard-dates.service';
import { CONSTANTS } from 'app/shared/constants';
import * as moment from 'moment';
import * as FusionCharts from 'fusioncharts';

@Component({
  selector: 'app-trendline-chart-top-level',
  templateUrl: './trendline-chart-top-level.component.html',
  styleUrls: ['./trendline-chart-top-level.component.scss'],
  providers: [
    TrendlineChartTopLevelService,
    DateRangePickerService,
    CommaDecimalSeparatorPipe,
  ],
})
export class TrendlineChartTopLevelComponent implements OnDestroy, OnChanges {
  /**
   * This component is required to be inside relatively positioned container.
   */


  @Input() translateKey = 'HOME.OVERVIEW.SHARED.FOODBACK_CHART';
  @Input() height = '100%';
  @Input() showLabels = true;
  @Input() endDate: any;
  @Input() startDate: any;

  @Input() set chartData(value: ITopLevelScores) {
    this.trendlineData = value ? value : [];
    if (value && !!this.startDate) {
      this.initChart(value);
    }

  }

  rawData: TrendlineChartTopLevelRawData = {};
  chartCategories: IFusionChartCategory[] = [];
  chartDataSet: IFusionChartDataSet[];
  dataSource: any;
  trendlineData: any;
  isDayChart: boolean = false;

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    private translateService: TranslateService,
    private foodbackScoreChartService: TrendlineChartTopLevelService,
    private chartUtilsService: ChartUtilsService,
    private dashboardDatesService: DashboardDatesService
  ) {
    // this.initChart(this.trendlineData);
  }
  

  ngOnInit(): void {
    if (this.trendlineData.length > 0 && (moment.utc(this.startDate).format('YYYY-MM-DD') !== moment.utc(this.endDate).format('YYYY-MM-DD'))) {
      this.initChart(this.trendlineData);

    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.trendlineData.length > 0 && (moment.utc(this.startDate).format('YYYY-MM-DD') !== moment.utc(this.endDate).format('YYYY-MM-DD'))) {
      this.initChart(this.trendlineData);

    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private initChart(value: any): void {
    const answerChartData: any[] = value ? this.getChartData(value) : [];
    const answerChartDataScore: any[] = value ? this.getChartDataScore(value) : [];
    const chartDataSet: any = value
      ? {
        seriesName: this.translateService.instant('SHARED.SYSTEM.ANSWERS'),
        renderAs: 'splinearea',
        drawAnchors: true,
        parentYAxis: 'S',
        color:
          CONSTANTS.CHART_COLORS.CAMPAIGNS.CHART_TRENDLINE.backgroundColor,
        data: answerChartData,
      }
      : {
        renderAs: 'splinearea',
        drawAnchors: true,
        plotBorderAlpha: 0,
        parentYAxis: 'S',
        color:
          CONSTANTS.CHART_COLORS.CAMPAIGNS.CHART_TRENDLINE.backgroundColor,
        data: answerChartData,
      };
    const dataset: any[] = [chartDataSet];

    if (value) {
      dataset.push({
        seriesName: this.translateService.instant('SHARED.TOP_LEVEL_DASHBOARD.AVG_OVERALL_SCORE'),
        renderAs: 'spline',
        drawAnchors: true,
        plotBorderAlpha: 0,
        color: CONSTANTS.CHART_COLORS.CAMPAIGNS.primaryColor,
        data: answerChartDataScore,
      });
    }

    this.initChartWithConfig();
    this.dataSource.dataset = dataset;
    this.dataSource.categories = [
      {
        category: this.chartCategories,
      },
    ];
  }

  private removeDuplicates(data) {
    return data.filter((value, index) => data.indexOf(value) === index);
  }

  private getDateRangeArray() {
    return this.dashboardDatesService.getAllDates(
      this.startDate,
      this.endDate
    );
  }

  private formatDateRangeArray(values, dateRangeArray) {
    if (values && values[0] && values[0].date.length === 7) {
      dateRangeArray = dateRangeArray.map((date) => {
        return moment.utc(date).format('YYYY-MM')
      });

    } else if (values && values[0] && values[0].date.length === 10) {
      dateRangeArray = dateRangeArray.map((date) => {
        return moment.utc(date).format('YYYY-MM-DD')
      });
    }

    return this.removeDuplicates(dateRangeArray);
  }

  private getChartData(
    values: any[]
  ): any[] {
    var dateRangeArray: string[] = this.getDateRangeArray();

    if (dateRangeArray.length !== 1) {
      this.isDayChart = false;
      dateRangeArray = this.formatDateRangeArray(values, dateRangeArray);
    } else {
      this.isDayChart = true;
      dateRangeArray = this.chartUtilsService.getHoursForDay();
    }
    this.chartCategories = dateRangeArray.map((date: string) => ({
      label: this.labelFormat(date),
    }));
    return dateRangeArray.map((date: string) => {
      var valueAtDate;
      if (this.isDayChart) {
        valueAtDate = values.find(value => {
          var dateToCompareBeforeConvert =  moment.utc(value.date, 'yyyy-mm-dd hh:mm:ss a').format("hh:mm a");
          return this.convertTo24HourFormat(dateToCompareBeforeConvert) === date;
          // moment.utc(value.date, 'yyyy-mm-dd hh:mm:ss a').format("hh:mm") === date;
        });
      } else {
        valueAtDate = values.find(value => value.date === date);
      }

      return {
        value: valueAtDate ? valueAtDate.foodbacks.toString() : '0',
        color: CONSTANTS.CHART_COLORS.CAMPAIGNS.CHART_TRENDLINE.backgroundColor,
        toolText: this.chartUtilsService.getTooltipTextForItem()
      };
    });
  }



private convertTo24HourFormat(timeString) { 
  const [time, period] = timeString.split(' '); 
  const [hour, minute] = time.split(':'); 
  let formattedHour = hour;

  if (period.toUpperCase() == 'PM' && hour != 12) { 
    formattedHour = parseInt(formattedHour) + 12;
  } else if (period.toUpperCase() == 'AM' && hour == 12) {
    formattedHour = '00';
  }

  return `${formattedHour}:${minute}`; 
} 



  private labelFormat(date: any) {
    var dateToFormat = new Date(date);
    if (date.length === 7) {
      return moment.utc(dateToFormat).format('MMM YYYY');
    }else if (date.length === 10) {
      return moment.utc(dateToFormat).format('MMM DD');
    } else {
      return date
    }

  }
  private getChartDataScore(
    values: any[]
  ): any[] {
    var dateRangeArray: string[] = this.getDateRangeArray();

    if (dateRangeArray.length !== 1) {
      this.isDayChart = false;
      dateRangeArray = this.formatDateRangeArray(values, dateRangeArray);
    } else {
      this.isDayChart = true;
      dateRangeArray = this.chartUtilsService.getHoursForDay();
    }
    // this.chartCategories = dateRangeArray.map((date: string) => ({
    //   label: this.labelFormat(date),
    // }));

    return dateRangeArray.map((date: string) => {
      var valueAtDate;
      if (this.isDayChart) {
        valueAtDate = values.find(value => {
          var dateToCompareBeforeConvert =  moment.utc(value.date, 'yyyy-mm-dd hh:mm:ss a').format("hh:mm a");
          return this.convertTo24HourFormat(dateToCompareBeforeConvert) === date;})
      } else {
        valueAtDate = values.find(value => value.date === date);
      }

      return {
        value: valueAtDate ? valueAtDate.score.toString() : '0',
        color: CONSTANTS.CHART_COLORS.CAMPAIGNS.primaryColor,
        toolText: this.chartUtilsService.getTooltipTextForItem(),
      };
    });
  }

  private initChartWithConfig(): void {
    const defaultMargin = 20;
    const additionalOptions: any = {
      anchorRadius: 3,
      theme: 'fusion',
      decimals: 0,
      pYAxisMaxValue: 7.0,
      sYAxisMaxValue: 150,
      // pYAxisMinValue: 1,
      // sYAxisMinValue: 1,
      forceYAxisValueDecimals: 0,
      forcePYAxisValueDecimals: 0,
      forceSYAxisValueDecimals: 0,
      setAdaptiveYMin: 0,
      setAdaptiveYMax: 1,
      showLimits: 0,
      showXAxisLine: false,
      showYAxisLine: false,
      showLabels: true,
      showToolTip: this.showLabels,
      showYAxisValues: false,
      showDivLineValues: this.showLabels,
      showDivLineSecondaryValue: this.showLabels,
      showVLineLabelBorder: false,
      drawFullAreaBorder: 0,
      showcanvasborder: false,
      divLineAlpha: 0,
      chartLeftMargin: this.showLabels ? defaultMargin : 0,
      chartRightMargin: this.showLabels ? defaultMargin : 0,
      chartTopMargin: 0,
      chartBottomMargin: 0,
      legendPadding: 0,
      canvasPadding: 0,
      // legendItemFontSize: 8,
      yAxisMinValue: 0,
      yAxisMaxValue: 6,
      abelDisplay: 'rotate',
      slantLabel: '1',
      sYAxisValueDecimals: '1',
      sDecimals: '1',
      minimizeTendency: '1',
      divlineThickness: '1',
      labelDisplay: 'rotate',

    };
    this.dataSource =
      this.chartUtilsService.initChartWithDataSetAndCategories(
        additionalOptions
      );
  }
}
