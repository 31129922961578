import { AfterViewInit, Directive, Input, OnChanges, OnDestroy } from '@angular/core';
import { AbstractControl, FormGroupDirective } from '@angular/forms';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ErrorDetails, ErrorOptions } from './error.interface';
import { toArray } from './utils/toArray';

@Directive({
  selector: '[appErrors]',
  exportAs: 'appErrors',
})
export class ErrorsDirective implements OnChanges, OnDestroy, AfterViewInit {
  get errors() {
    if (!this.ready) {
      return;
    }

    return this.control.errors;
  }

  get hasErrors() {
    return !!this.errors;
  }

  @Input('appErrors')
  controlName: string | AbstractControl;

  subject = new BehaviorSubject<ErrorDetails>(null);

  control: AbstractControl;

  ready = false;

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(private form: FormGroupDirective) {}

  hasError(name: string, conditions: ErrorOptions): boolean {
    return this.checkPropState('invalid', name, conditions);
  }

  isValid(name: string, conditions: ErrorOptions): boolean {
    return this.checkPropState('valid', name, conditions);
  }

  getError(name: string) {
    if (!this.ready) {
      return;
    }

    return this.control.getError(name);
  }

  ngOnChanges(): void {
    if (typeof this.controlName === 'string') {
      this.control = this.form.control.get(this.controlName);
    } else {
      this.control = this.controlName;
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.checkStatus();
      this.control.statusChanges.pipe(takeUntil(this.onDestroy$)).subscribe(this.checkStatus.bind(this));
    });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.subject.unsubscribe();
  }

  private checkPropState(prop: string, name: string, conditions: ErrorOptions): boolean {
    if (!this.ready) {
      return;
    }
    const controlPropsState = !conditions || toArray(conditions).every((condition: string) => this.control[condition]);

    if (name.charAt(0) === '*') {
      return this.control[prop] && controlPropsState;
    }

    return prop === 'valid' ? !this.control.hasError(name) : this.control.hasError(name) && controlPropsState;
  }

  private checkStatus() {
    const control = this.control;
    const errors = control.errors;

    this.ready = true;

    if (!errors) {
      return;
    }
    for (const errorName of Object.keys(errors)) {
      this.subject.next({ control, errorName });
    }
  }
}
