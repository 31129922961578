export enum QuestionType {
  BOOLEAN = 'boolean',
  CHECKBOX = 'checkbox',
  RADIO_BUTTON = 'radio',
  SCALE = 'scale',
  FREE_TEXT = 'text',
}

export enum DemographicType {
  AGE_GROUP = 'AGE_GROUP',
  GENDER = 'GENDER',
}

export enum QuestionGendersType {
  UNKNOWN = '',
  MALE = 'Male',
  FEMALE = 'Female',
  OTHER = 'Other',
}
