import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FileItem, FileUploader } from 'ng2-file-upload';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { BillFolderBackendService } from '../../../../core/providers/backend/bill-folder-backend.service';
import { UploadService } from '../../../../core/providers/backend/upload.service';
import { NotificationService } from '../../../../core/providers/notification/notification.service';
import { CONSTANTS } from '../../../constants';
import { ImageUploadResponse } from '../../profile-avatar/profile-avatar.interface';

@Component({
  selector: 'app-branding-upload-logo',
  templateUrl: './branding-upload-logo.component.html',
  styleUrls: ['./branding-upload-logo.component.scss'],
})
export class BrandingUploadLogoComponent implements OnInit, OnChanges, OnDestroy {
  @Input() avatarURL: string = CONSTANTS.ASSETS_PATH.DEFAULT_BRANING_LOGO;
  @Output() readonly uploadedImage: EventEmitter<string> = new EventEmitter();
  @Output() readonly uploadingStatusChanged: EventEmitter<boolean> = new EventEmitter();
  @Output() readonly onImgError: EventEmitter<void> = new EventEmitter();
  @ViewChild('fileInput', { static: true }) fileInput: ElementRef;
  hasBaseDropZoneOver = false;
  loading = false;
  uploader: FileUploader;

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    private uploadService: UploadService,
    private notificationService: NotificationService,
    private billFolderBackendService: BillFolderBackendService
  ) {}

  fileOverBase(isOverBase: boolean): void {
    this.hasBaseDropZoneOver = isOverBase;
  }

  backToDefault(): void {
    this.avatarURL = CONSTANTS.ASSETS_PATH.DEFAULT_BRANING_LOGO;
    this.uploadedImage.emit(null);
    this.clearFileInput();
  }

  ngOnInit(): void {
    this.uploader = new FileUploader(
      this.uploadService.getUploaderOptions(CONSTANTS.IMAGES.MAX_SIZE_BRANDING, ['image/jpeg', 'image/png'])
    );

    this.uploader.onBeforeUploadItem = () => {
      this.loading = true;
      this.uploadingStatusChanged.emit(this.loading);
    };

    this.uploader.onWhenAddingFileFailed = error => {
      this.notificationService.errorWithTranslationKey('VENUE.FOLDER.BRANDING.MODALS.INCORRECT_FILE_ERROR');
      this.clearFileInput();
    };

    this.uploader.onCompleteItem = (item: FileItem, response: string) => {
      this.uploadingStatusChanged.emit(this.loading);
      const parsedResponse = JSON.parse(response) as ImageUploadResponse;
      const imageUuid = parsedResponse.uuid;
      const validationResponse = this.uploadService.validateBranding(parsedResponse.metadata);

      if (validationResponse.isValid) {
        if (validationResponse.isWarning) {
          this.notificationService
            .displayConfirmationPopupWithTranslationKey('SHARED.VALIDATION_MSG.IMAGES.TOO_NARROW_IMAGE')
            .pipe(take(1))
            .subscribe(() => {
              this.setAndPrepareAvatar(imageUuid);
            });
        } else {
          this.setAndPrepareAvatar(imageUuid);
        }
      } else {
        this.uploadService.displayError(validationResponse.validationTitle, validationResponse.validationBodyMsg);
      }

      this.clearFileInput();
    };
  }

  onBrandingImgLoading(event): void {
    this.loading = false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.avatarURL.previousValue) {
      this.loading = true;
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private setAndPrepareAvatar(imageUuid: string): void {
    this.billFolderBackendService
      .setFileResolutions(imageUuid, null, CONSTANTS.BRANDING_IMG_SIZE.LOGO_HEIGHT)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.uploadedImage.emit(imageUuid);
        this.avatarURL = this.billFolderBackendService.getFileUrl(imageUuid);
        this.loading = false;
      });
  }

  private clearFileInput(): void {
    this.fileInput.nativeElement.value = '';
  }
}
