<div class="ProfileAvatar">
  <span class="fb-text-info ProfileAvatar-instructions">{{ 'SHARED.COMPONENTS.PROFILE_AVATAR.INSTRUCTIONS' | translate }}</span>

  <div class="ProfileAvatar-controls">
    <div
      class="box-placeholder my-drop-zone"
      [ngClass]="{ 'nv-file-over': hasBaseDropZoneOver }"
      (fileOver)="onFileOverDropArea($event)"
      [uploader]="uploader"
      (onFileDrop)="onFileDrop($event)"
      ng2FileDrop>
      <span *ngIf="imageFromServer; else uploadAvatarHere">
        <img
          [src]="imageFromServer"
          [class.img-circle]="true"
          [width]="cropperSettings.croppedWidth"
          [height]="cropperSettings.croppedHeight"
          class="my-drop-zone-image" />
      </span>
      <ng-template #uploadAvatarHere> {{ 'SHARED.COMPONENTS.PROFILE_AVATAR.PLACEHOLDER' | translate }} </ng-template>
    </div>

    <div class="ProfileAvatar-buttons">
      <label for="file2" class="file-upload text-uppercase btn btn-outline btn-foodback">
        <em class="mdi mdi-pencil mdi-18px align-middle"></em>
        {{ 'SHARED.COMPONENTS.PROFILE_AVATAR.BUTTONS.BROWSE' | translate }}
        <input #fileInput id="file2" type="file" ng2FileSelect [uploader]="uploader" (change)="onFileChange($event)" />
      </label>

      <button type="button" class="file-upload text-uppercase btn btn-outline btn-foodback" *ngIf="imageFromServer" (click)="deleteImage()">
        <em class="mdi mdi-delete mdi-18px align-middle"></em>
        {{ 'SHARED.SYSTEM.DELETE' | translate }}
      </button>
    </div>
  </div>
</div>
