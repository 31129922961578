import { Injectable } from '@angular/core';
import { DEFAULT_BREAKPOINTS, MediaObserver } from '@angular/flex-layout';
import { Observable, from as observableFrom } from 'rxjs';
import { CONSTANTS } from '../../constants';
import { PartialCropperSettings } from './profile-avatar.interface';

const CANVAS_BREAKPOINTS: Record<string, PartialCropperSettings> = {
  xs: {
    canvasWidth: 300,
    canvasHeight: 261,
    croppedWidth: 300,
    croppedHeight: 300,
  },
  'gt-xs': {
    croppedWidth: 350,
    croppedHeight: 350,
    canvasWidth: 460,
    canvasHeight: 400,
  },
};

@Injectable()
export class ProfileAvatarService {
  constructor(private media: MediaObserver) {}

  // public getInitialCropperSettings(): PartialCropperSettings {
  //   const settings = new CropperSettings();
  //
  //   return Object.assign({}, settings, <CropperSettings>{
  //     noFileInput: true,
  //     width: 300,
  //     height: 300,
  //     minWidth: 100,
  //     minHeight: 100,
  //     cropperDrawSettings: {
  //       strokeColor: 'rgba(255,255,255,1)',
  //       strokeWidth: 2
  //     },
  //     rounded: true
  //   });
  // }

  getDynamicCropperSettings(): PartialCropperSettings {
    const reversedBreakpoints = [...DEFAULT_BREAKPOINTS].reverse();

    for (const breakpoint of reversedBreakpoints) {
      if (this.media.isActive(breakpoint.alias) && CANVAS_BREAKPOINTS[breakpoint.alias]) {
        return CANVAS_BREAKPOINTS[breakpoint.alias];
      }
    }

    return CANVAS_BREAKPOINTS.xs;
  }

  getDataURLFromBlob(file: Blob): Observable<string> {
    return observableFrom(
      new Promise<string>((resolve, reject) => {
        const fileReader = new FileReader();

        fileReader.onloadend = () => {
          resolve(fileReader.result as any);
        };

        fileReader.onerror = () => {
          reject(fileReader.error);
        };

        fileReader.readAsDataURL(file);
      })
    );
  }

  isFileCorrect(file: File): boolean {
    return CONSTANTS.IMAGES.TYPES.includes(file.type) && file.size <= CONSTANTS.IMAGES.MAX_SIZE_ADVERTISEMENT;
  }
}
