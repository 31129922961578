import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent, UrlSegment } from '@angular/router';
import { sortBy } from 'lodash';
import { Observable, Subject, combineLatest } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { ACCOUNT_SETTINGS_ROUTES_PATHS_FULL } from '../../../../components/account/settings/acc-settings-routes-paths';
import { VENUE_SETTINGS_ROUTES_PATHS_FULL } from '../../../../components/venue/settings/ven-settings-routes-paths';
import { FoodbackAccount } from '../../../../core/classes/account/account.class';
import { AccountService } from '../../../../core/providers/account/account.service';
import { RouteBuilderService } from '../../../../core/providers/backend/route-builder.service';
import { ContextService } from '../../../../core/providers/context/context.service';
import { CONSTANTS } from '../../../constants';
import { Dictionary } from '../../../interfaces/common.interface';
import { ContextObject } from '../../../interfaces/login-object';

@Component({
  selector: 'app-bill-folder-header',
  templateUrl: './bill-folder-header.component.html',
  styleUrls: ['./bill-folder-header.component.scss'],
})
export class BillFolderHeaderComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('billFolderHeader', { static: true }) billFolderHeader: ElementRef;
  uuid: string;
  headerItems: {
    title: string;
    url: string;
    icon: string;
    iconUrl?: string;
    order: number;
  }[] = [];
  routeParam: string;
  private ROUTES_PATHS: Dictionary<any> = {
    FOLDER: {},
  };
  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    private accountService: AccountService,
    private contextService: ContextService,
    private router: Router,
    private routeBuilderService: RouteBuilderService,
    private activatedRoute: ActivatedRoute
  ) {}

  @HostListener('wheel', ['$event'])
  onWheel(event: WheelEvent): void {
    this.billFolderHeader.nativeElement.scrollLeft += event.deltaY;
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.refreshScrollPosition());
    this.onNavigationEnd()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        setTimeout(() => this.refreshScrollPosition());
      });
  }

  ngOnInit(): void {
    combineLatest([this.contextService.getContext$(), this.accountService.getAccount$()])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(([context, account]: [ContextObject, FoodbackAccount]) => {
        const isVenueContext: boolean = ContextService.isVenueContext(context);
        const isSingleVenueAccount: boolean = account.isSingleVenueMode;

        this.uuid = context.uuid;
        this.ROUTES_PATHS = isVenueContext ? VENUE_SETTINGS_ROUTES_PATHS_FULL : ACCOUNT_SETTINGS_ROUTES_PATHS_FULL;
        this.routeParam = isVenueContext ? CONSTANTS.ROUTE_PARAMS.VENUE_ID : CONSTANTS.ROUTE_PARAMS.ACCOUNT_ID;
        this.setHeaderItems(isVenueContext, isSingleVenueAccount);

        if (!isVenueContext) {
          this.navigateToDefaultUrl(isSingleVenueAccount, account.uuid);
        }
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private navigateToDefaultUrl(isSingleVenueAccount: boolean, accountUuid: string): void {
    this.getUrl()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        // eslint-disable-next-line
        if (!this.activatedRoute.firstChild) {
          const path: string =
            isSingleVenueAccount ? this.ROUTES_PATHS.CONFIGURATION.BRANDING : this.ROUTES_PATHS.CONFIGURATION.QR_URLS;
          const url: string = this.routeBuilderService.getAbsoluteRoute(path, {
            [CONSTANTS.ROUTE_PARAMS.ACCOUNT_ID]: accountUuid,
          });

          this.router.navigate([url]);
        }
      });
  }

  private getUrl(): Observable<UrlSegment[]> {
    return this.activatedRoute.url;
  }

  private onNavigationEnd(): Observable<RouterEvent> {
    return this.router.events.pipe(filter((event: RouterEvent) => event instanceof NavigationEnd));
  }

  private refreshScrollPosition(): void {
    const activeLink: HTMLCollectionOf<Element> = document.getElementsByClassName('active-link');

    if (activeLink && activeLink.length > 0) {
      const x: ClientRect = document.getElementsByClassName('active-link')[0].getBoundingClientRect();

      this.billFolderHeader.nativeElement.scrollBy({
        // eslint-disable-next-line no-magic-numbers
        left: x.left - this.billFolderHeader.nativeElement.getBoundingClientRect().width / 2 + 62.5,
        behavior: 'smooth',
      });
    }
  }

  private setHeaderItems(isVenueContext: boolean, isSingleVenueAccount: boolean): void {
    const commonItems: {
      title: string;
      url: string;
      icon: string;
      order: number;
    }[] = [
      {
        title: 'SHARED.SYSTEM.BRANDING',
        url: this.ROUTES_PATHS.CONFIGURATION.BRANDING,
        icon: 'mdi-brush',
        // eslint-disable-next-line no-magic-numbers
        order: isSingleVenueAccount && !isVenueContext ? 1 : 2,
      },
      {
        title: 'SHARED.SYSTEM.DEMOGRAPHICS',
        url: this.ROUTES_PATHS.CONFIGURATION.DEMOGRAPHICS,
        icon: 'mdi-account-multiple',
        order: 3,
      },
      {
        title: 'SHARED.SYSTEM.ADVERTISEMENT',
        url: this.ROUTES_PATHS.CONFIGURATION.ADVERTISEMENT,
        icon: 'mdi-bullhorn',
        order: 5,
      },
      {
        title: 'SHARED.SYSTEM.REGIONAL_SETTINGS',
        url: this.ROUTES_PATHS.CONFIGURATION.REGIONAL_SETTINGS,
        icon: 'mdi-earth',
        order: 6,
      },
    ];
    const venueItems: {
      title: string;
      url: string;
      icon: string;
      order: number;
    }[] = [];

    const multiVenueItems: {
      title: string;
      url: string;
      icon: string;
      iconUrl?: string;
      order: number;
    }[] = [
      ...commonItems,
      {
        title: 'ACCOUNT.FOLDER.NAVIGATION_BAR.QRL_URLS',
        url: this.ROUTES_PATHS.CONFIGURATION.QR_URLS,
        icon: null,
        order: 1,
      },
    ];

    this.headerItems = sortBy(
      isVenueContext ? [...commonItems, ...venueItems] : isSingleVenueAccount ? commonItems : multiVenueItems,
      'order'
    );
  }
}
