import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'patternSort',
})
export class PatternSortPipe implements PipeTransform {
  private static sortFn(properOrder: string[], itemA: string, itemB: string): number {
    if (properOrder.includes(itemA) && properOrder.includes(itemB)) {
      return properOrder.indexOf(itemA) - properOrder.indexOf(itemB);
    }
    if (properOrder.includes(itemA) && !properOrder.includes(itemB)) {
      return -1;
    }
    if (!properOrder.includes(itemA) && properOrder.includes(itemB)) {
      return 1;
    }

    return 0;
  }

  transform(value: string[], sortPattern: string[]): string[] {
    return value.slice().sort((a, b) => PatternSortPipe.sortFn(sortPattern, a, b));
  }
}
