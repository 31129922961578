import { Router } from '@angular/router';
import { formatPattern } from 'url-matcher';
import { Dictionary } from '../../../shared/interfaces/common.interface';

/**
 * This is a custom router class that extends Angular Router. It is recommended to use it
 * everywhere instead of the original Router class.
 */
export class RouterUtilities extends Router {
  constructor(
  ) {
    super();
  }

  static getAbsoluteRoute(routePath: string, params: Dictionary<string> = {}): string {
    return formatPattern(routePath, params);
  }
}
