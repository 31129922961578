import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { PayloadForFoodbacks } from '../../../../core/interfaces/payload-for-foodbacks.interface';
import { VenueAnalyticsBackendService } from '../../../../core/providers/backend/venue-analytics-backend.service';
import { IVenueScores, QuestionDetails, QuestionFoodbacksFromBackend, QuestionSummary } from '../../../interfaces/analytics.interface';
import { DynamicDateRange } from '../../../interfaces/date-range.interface';
import { FoodbackOverviewService } from '../foodback-overview';
import { FoodbacksWithThisQuestionService } from '../foodbacks-with-this-question/foodbacks-with-this-question.service';

@Injectable()
export class QuestionDetailsService {
  private questionId: string;

  constructor(
    private foodbackOVerviewService: FoodbackOverviewService,
    private foodbacksWithThisQuestionService: FoodbacksWithThisQuestionService,
    private venueAnalyticsBackendService: VenueAnalyticsBackendService
  ) {}

  setQuestionId(id: string) {
    this.questionId = id;
  }

  getQuestionText$(defaultLanguage: string): Observable<string> {
    return this.venueAnalyticsBackendService
      .getQuestionDetails$(this.questionId)
      .pipe(
        switchMap((details: QuestionDetails) => this.foodbacksWithThisQuestionService.getQuestionTranslation(details, defaultLanguage))
      );
  }

  getQuestionSummary$(dateRange: DynamicDateRange, venueUuids: string[]): Observable<QuestionSummary> {
    return this.foodbackOVerviewService.isVenueContextOrSelectedVenue()
      ? this.foodbackOVerviewService
          .getDataProvider()
          .getQuestionSummary$(this.foodbackOVerviewService.getDataProviderUuid(), dateRange, this.questionId)
      : this.foodbackOVerviewService
          .getDataProvider()
          .getQuestionSummary$(this.foodbackOVerviewService.getDataProviderUuid(), dateRange, this.questionId, venueUuids);
  }

  getQuestionScores$(dateRange: DynamicDateRange, venueUuids: string[]): Observable<IVenueScores> {
    return this.foodbackOVerviewService.isVenueContextOrSelectedVenue()
      ? this.foodbackOVerviewService
          .getDataProvider()
          .getQuestionScores$(this.foodbackOVerviewService.getDataProviderUuid(), dateRange, this.questionId)
      : this.foodbackOVerviewService
          .getDataProvider()
          .getQuestionScores$(this.foodbackOVerviewService.getDataProviderUuid(), dateRange, this.questionId, venueUuids);
  }

  getFoodbacksWithQuestion$(payload: PayloadForFoodbacks, venueUuids: string[]): Observable<QuestionFoodbacksFromBackend> {
    return this.foodbackOVerviewService.isVenueContextOrSelectedVenue()
      ? this.foodbackOVerviewService.getDataProvider().getFoodbacksWithQuestion$(
          this.foodbackOVerviewService.getDataProviderUuid(),
          {
            ...payload,
          },
          this.questionId
        )
      : this.foodbackOVerviewService.getDataProvider().getFoodbacksWithQuestion$(
          this.foodbackOVerviewService.getDataProviderUuid(),
          {
            ...payload,
            venues: venueUuids,
          },
          this.questionId,
          venueUuids
        );
  }

  getDayByDayScoresForStatement$(dateRange: DynamicDateRange, venueUuids: string[]): Observable<any> {
    return this.foodbackOVerviewService.isVenueContextOrSelectedVenue()
      ? this.foodbackOVerviewService
          .getDataProvider()
          .getDayByDayScoresForStatement$(this.foodbackOVerviewService.getDataProviderUuid(), dateRange, this.questionId)
      : this.foodbackOVerviewService
          .getDataProvider()
          .getDayByDayScoresForStatement$(this.foodbackOVerviewService.getDataProviderUuid(), dateRange, this.questionId, venueUuids);
  }

  getHourByHourScoresForStatement$(dateRange: DynamicDateRange, venueUuids: string[]): Observable<any> {
    return this.foodbackOVerviewService.isVenueContextOrSelectedVenue()
      ? this.foodbackOVerviewService
          .getDataProvider()
          .getHourByHourScoresForStatement$(this.foodbackOVerviewService.getDataProviderUuid(), dateRange, this.questionId)
      : this.foodbackOVerviewService
          .getDataProvider()
          .getHourByHourScoresForStatement$(this.foodbackOVerviewService.getDataProviderUuid(), dateRange, this.questionId, venueUuids);
  }
}
