import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, ViewChild } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BillFolderBackendService } from '../../../core/providers/backend/bill-folder-backend.service';
import { UsersBackendService } from '../../../core/providers/backend/users-backend.service';
import { NotificationService } from '../../../core/providers/notification/notification.service';
import { DialogService } from '../dialog/dialog.service';
import { CropperData, PartialCropperSettings } from './profile-avatar.interface';
import { ProfileAvatarService } from './profile-avatar.service';

@Component({
  selector: 'app-profile-avatar',
  templateUrl: './profile-avatar.component.html',
  styleUrls: ['./profile-avatar.component.scss'],
  providers: [ProfileAvatarService],
})
export class ProfileAvatarComponent implements OnChanges, OnDestroy {
  @Output() readonly uploadedImage: EventEmitter<any> = new EventEmitter();
  @Input() imageUuid = '';
  @ViewChild('fileInput', { static: true }) fileInput;
  cropperData: CropperData = {};
  cropperSettings: PartialCropperSettings;
  hasBaseDropZoneOver = false;
  imageFromServer = '';
  uploader = new FileUploader({ autoUpload: false, url: '' });
  private onDestroy$ = new Subject<void>();

  constructor(
    private billFolderBackendService: BillFolderBackendService,
    private usersBackendService: UsersBackendService,
    private profileAvatarService: ProfileAvatarService,
    private notificationService: NotificationService,
    private dialogService: DialogService
  ) {
    this.cropperSettings = this.profileAvatarService.getDynamicCropperSettings();
  }

  onFileOverDropArea(isOverBase: boolean) {
    this.hasBaseDropZoneOver = isOverBase;
  }

  deleteImage() {
    this.imageFromServer = '';
    this.clearFileInput();
    this.uploadedImage.emit(null);
  }

  showCropperModal(dataURL: string) {
    this.dialogService
      .profileAvatarCropperDialog({
        dataURL,
        cropperData: this.cropperData,
        cropperSettings: this.cropperSettings,
      })
      .afterClosed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        if (result) {
          this.saveCroppedImage(result);
        }
      });
  }

  onFileChange($event: Event) {
    this.handleFileSelected(($event.target as HTMLInputElement).files[0]);
  }

  onFileDrop(files: FileList) {
    this.handleFileSelected(files[0]);
  }

  ngOnChanges(changes: any) {
    this.setImageFromServer(changes.imageUuid.currentValue);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private saveCroppedImage(imageDataForBackend) {
    this.usersBackendService
      .uploadImage$(imageDataForBackend)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(parsedResponse =>
        this.uploadedImage.emit({
          item: imageDataForBackend.image,
          response: parsedResponse,
        })
      );
  }

  private handleFileSelected(file: File) {
    if (!this.profileAvatarService.isFileCorrect(file)) {
      this.showIncorrectFileWarning();
      this.clearFileInput();

      return;
    }

    this.profileAvatarService
      .getDataURLFromBlob(file)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(dataURL => {
        this.clearFileInput();
        this.showCropperModal(dataURL);
      });
  }

  private setImageFromServer(imgUuid: string) {
    if (imgUuid) {
      this.imageFromServer = this.billFolderBackendService.getFileUrl(imgUuid);
    }
  }

  private clearFileInput() {
    this.fileInput.nativeElement.value = '';
  }

  private showIncorrectFileWarning() {
    this.notificationService.errorWithTranslationKey('SHARED.COMPONENTS.PROFILE_AVATAR.INCORRECT_FILE_ERROR');
  }
}
