import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import { filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { GroupsBackendService } from '../../../../../../core/providers/backend/groups-backend.service';
import { ContextService } from '../../../../../../core/providers/context/context.service';
import { NotificationService } from '../../../../../../core/providers/notification/notification.service';
import { DropdownUtil } from '../../../../../../core/utils/dropdown-util';
import { GroupsService } from '../../../../../../shared/components/groups/groups.service';
import { CONSTANTS } from '../../../../../../shared/constants';
import { IDropdownItem } from '../../../../../../shared/interfaces/dropdown/dropdown-item.interface';
import { ContextObject } from '../../../../../../shared/interfaces/login-object';
import { AccFolderQUrlsService } from '../acc-folder-q-urls.service';
import { IGroupCategory, IGroupChild, IGroupQr } from '../groups-list/group-qr.interface';

export const activateGroupDialogConfig: MatDialogConfig = {
  width: '600px',
  height: '400px',
  disableClose: false,
};

/**
 * Groups settings - Account context
 */
@Component({
  selector: 'app-activate-group-dialog',
  templateUrl: './activate-group-dialog.component.html',
  styleUrls: ['./activate-group-dialog.component.scss'],
})
export class ActivateGroupDialogComponent implements OnInit, OnDestroy {
  categoriesForDropdown: IDropdownItem[] = [];
  filteredGroups: IGroupChild[] = [];
  groups: IGroupCategory[] = [];
  activeItems: IDropdownItem[] = [];
  groupQrs: IGroupQr[] = [];
  selectAllCheckbox = false;

  private selectedCategoryUuid: string;
  private accountUuid: string;
  private onDestroy$ = new Subject<void>();

  constructor(
    private mdDialogRef: MatDialogRef<ActivateGroupDialogComponent>,
    private notificationService: NotificationService,
    private groupsBackendService: GroupsBackendService,
    private groupsService: GroupsService,
    private accFolderQUrlsService: AccFolderQUrlsService,
    private contextService: ContextService
  ) {}

  ngOnInit() {
    this.getGroups$()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(groups => {
        this.groups = groups;
        this.categoriesForDropdown = DropdownUtil.convertToDropdownItems(groups, ['uuid', 'name']);
      });
    this.getGroupsQRUrls$()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(groupQrs => (this.groupQrs = groupQrs));
    this.getContext$()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((context: ContextObject) => {
        this.accountUuid = context.uuid;
      });
  }

  hide() {
    this.mdDialogRef.close();
  }

  onChangeCategory(selectedValue: IDropdownItem) {
    this.resetCategories();
    this.selectedCategoryUuid = selectedValue.id;
    this.activeItems = [selectedValue];
    this.filteredGroups = this.groups.filter(category => category.uuid === selectedValue.id)[0].groups;
    this.filteredGroups.forEach(
      groupChild => (groupChild.isSelected = this.groupQrs.findIndex(groupQr => groupQr.uuid === groupChild.uuid) > 0)
    );
  }

  onSelectAllChange(value: boolean) {
    this.filteredGroups = this.filteredGroups.map(group => {
      group.isSelected = value;

      return group;
    });
  }

  deselectAll(isSelected: boolean) {
    if (!isSelected) {
      this.selectAllCheckbox = false;
    }
  }

  save() {
    const selectedGroup = this.filteredGroups.filter(group => group.isSelected);

    if (selectedGroup.length > 0) {
      const venueGroupChildren: string[] = selectedGroup.map(group => group.uuid);

      this.activateGroupsQR$(this.selectedCategoryUuid, venueGroupChildren)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(() => {
          this.notificationService.successWithTranslationKey('ACCOUNT.FOLDER.QR_URLS.DIALOGS.SUCCESS.GROUPS_QR_ACTIVATED');
          this.mdDialogRef.close(true);
        });
    } else {
      this.notificationService.errorWithTranslationKey('ACCOUNT.FOLDER.QR_URLS.DIALOGS.ERRORS.NO_GROUPS_SELECTED');
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private getGroupsQRUrls$(): Observable<IGroupQr[]> {
    return this.accFolderQUrlsService.getGroupsQrUrls$();
  }

  private activateGroupsQR$(venueGroupUuid: string, venueGroupChildren: string[]): Observable<any> {
    if (venueGroupUuid !== '' && venueGroupUuid !== CONSTANTS.GROUPS.NO_CATEGORY_UUID) {
      return this.accFolderQUrlsService.activateGroupsQR$(venueGroupUuid, venueGroupChildren);
    }

    return this.accFolderQUrlsService.activateGroupsQRByAccount$(this.accountUuid, venueGroupChildren);
  }

  private getContext$(): Observable<ContextObject> {
    return this.contextService.getContext$().pipe(filter((context: ContextObject) => context.type === CONSTANTS.CONTEXT.ACCOUNT));
  }

  private getGroups$(): Observable<IGroupCategory[]> {
    return this.groupsBackendService.getGroups$().pipe(
      tap(groups => this.groupsService.setGroups(groups)),
      switchMap(() => this.groupsService.getGroupsWithCategories$()),
      map(groups =>
        groups.map(group => {
          const groupCategory: IGroupCategory = {
            uuid: group.uuid,
            name: group.name,
            groups: [],
          };

          if (group.venueGroups && group.venueGroups.length > 0) {
            groupCategory.groups = group.venueGroups.map(subGroup => ({
              uuid: subGroup.uuid,
              name: subGroup.name,
              isSelected: false,
            }));
          }

          return groupCategory;
        })
      )
    );
  }

  private resetCategories() {
    this.groups = this.groups.map(category => {
      category.groups = category.groups.map(group => {
        group.isSelected = false;

        return group;
      });

      return category;
    });
    this.filteredGroups = [];
    this.activeItems = [];
  }
}
