import { Pipe, PipeTransform } from '@angular/core';
import { sortBy } from 'lodash';

@Pipe({
  name: 'inactiveStateOnEnd',
})
export class InactiveStateOnTheEndPipe implements PipeTransform {
  transform<T>(value: T[], arg: keyof T): T[] {
    return sortBy(value, (element: T) => !element[arg]);
  }
}
