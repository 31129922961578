import {
  Component,
  HostBinding,
  HostListener,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { Event, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Angulartics2GoogleAnalytics } from 'angulartics2';
import { debounce } from 'lodash';
import * as moment from 'moment';
import { Intercom } from 'ng-intercom';
import { LocalStorageService } from 'ngx-webstorage';
import { Observable, Subject, Subscription } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import * as smoothscroll from 'smoothscroll-polyfill';
import { ServerEnvironmentConfig } from './core/interfaces/server-environment.interface';
import { AccountAnalyticsBackendService } from './core/providers/backend/account-analytics-backend.service';
import { CategoriesBackendService } from './core/providers/backend/categories-backend.service';
import { GoogleAnalyticsService } from './core/providers/backend/google-analytics.service';
import { IntercomService } from './core/providers/backend/intercom.service';
import { UsersBackendService } from './core/providers/backend/users-backend.service';
import { VenueAnalyticsBackendService } from './core/providers/backend/venue-analytics-backend.service';
import { ContextService } from './core/providers/context/context.service';
import { ServerEnvironmentService } from './core/providers/server-environment/server-environment.service';
import { UserService } from './core/providers/user/user.service';
import { LayoutUtil } from './core/utils';
import { DateRangePickerService } from './shared/components/date-range-picker/date-range-picker.service';
import { QuestionDetailsService } from './shared/components/overview/question-details/question-details.service';
import { CONSTANTS } from './shared/constants';
import { ContextObject } from './shared/interfaces/login-object';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  @HostBinding('class.aside-collapsed') isCollapsed = false;
  @HostBinding('class.aside-toggled') isMobileSidebar = false;
  @HostBinding('class.aside-expanded') isExpanded = false;
  // eslint-disable-next-line no-magic-numbers
  @HostListener('window:resize') onResize = debounce(
    () => this.handleSidebarOnPageResize(),
    100
  );
  isLoading = true;

  private onDestroy$ = new Subject<void>();
  private userActivitySubscription: Subscription = new Subscription();

  constructor(
    private intercomService: IntercomService,
    private serverEnvironment: ServerEnvironmentService,
    private intercom: Intercom,
    private layoutUtil: LayoutUtil,
    private router: Router,
    private contextService: ContextService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics,
    private dateRangePickerService: DateRangePickerService,
    private accountAnalyticsBackendService: AccountAnalyticsBackendService,
    private venueAnalyticsBackendService: VenueAnalyticsBackendService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private titleService: Title,
    private categoriesBackendService: CategoriesBackendService,
    private translateService: TranslateService,
    private questionDetailsService: QuestionDetailsService,
    private usersBackendService: UsersBackendService,
    private localStorage: LocalStorageService,
    private userService: UserService,
  ) {
    this.isCollapsed = false;
    smoothscroll.polyfill();
    this.angulartics2GoogleAnalytics.startTracking();
  }

  ngOnInit(): void {
    this.initIntercom();
    this.loadIcons();
    this.handleRouterEvents();
    this.handleSidebarChanges();
    this.handleResponsiveStateEvents();
    this.googleAnalyticsService.injectGAScript();
    this.handleContextAndSetFirstFoodbackDate();
    this.handleAndSetEnvironmentData();
  }

  private handleAndSetEnvironmentData(): void {
    this.getServerEnvironmentConfig$()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((config) => {
        const app_id = config.intercom.appId;

        this.intercomService.updateIntercomData({
          app_id,
        });
      });
  }

  private handleContextAndSetFirstFoodbackDate(): void {
    this.getContext$()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((context: ContextObject) =>
        this.setInitialFirstFoodbackDate(context)
      );
  }

  private setInitialFirstFoodbackDate(context: ContextObject): void {
    const analyticsService =
      context.type === CONSTANTS.CONTEXT.ACCOUNT
        ? this.accountAnalyticsBackendService
        : this.venueAnalyticsBackendService;

    analyticsService.getFoodbackFirstDate$(context.uuid).subscribe((date) => {
      if (date.firstFoodbackDate) {
        this.setFirstFoodbackDate(date.firstFoodbackDate);
      }
    });
  }

  private handleResponsiveStateEvents(): void {
    this.getIsMobileDevice$()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((data: boolean) => (this.isMobileSidebar = data));
    this.getIsExpanded$()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((data: boolean) => (this.isExpanded = data));
  }

  private handleSidebarChanges(): void {
    this.getIsSidebarCollapsed$()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((data: boolean) => {
        this.isCollapsed = data;
        this.intercomService.updateIntercomData({
          hide_default_launcher: !data && this.isMobileSidebar,
        });
      });
  }

  private handleRouterEvents(): void {
    this.getRouterEvents$()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((data: Event) => {
        if (data instanceof NavigationEnd && this.isLoading) {
          this.isLoading = false;
        }
        if (data instanceof NavigationEnd && this.layoutUtil.isMobileDevice()) {
          this.layoutUtil.setIsCollapsed(true);
        }
        if (
          data instanceof NavigationEnd &&
          !data.urlAfterRedirects.includes(CONSTANTS.ROUTES.NOT_FOUND)
        ) {
          localStorage.setItem('lastUrl', data.url);
          this.setAndVerifyLastActivity();
        }
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private initIntercom(): void {
    this.intercom.boot({
      app_id: '',
      // Supports all optional configuration.
      widget: {
        activator: '#intercom',
      },
    });
    this.intercomService.initIntercom(this.intercom);
  }

  private setFirstFoodbackDate(date: string): void {
    this.dateRangePickerService.minDateRange = moment(date).toDate();
  }

  private getServerEnvironmentConfig$(): Observable<
    Readonly<ServerEnvironmentConfig>
  > {
    return this.serverEnvironment.getServerEnvironmentConfig$();
  }

  private getContext$(): Observable<ContextObject> {
    return this.contextService.getContext$();
  }

  private getIsMobileDevice$(): Observable<boolean> {
    return this.layoutUtil.getIsMobileDevice$();
  }

  private getRouterEvents$(): Observable<Event> {
    return this.router.events;
  }

  private getIsExpanded$(): Observable<boolean> {
    return this.layoutUtil.getIsSidebarExpanded$();
  }

  private getIsSidebarCollapsed$(): Observable<boolean> {
    return this.layoutUtil.getIsCollapsed$();
  }

  private handleSidebarOnPageResize(): void {
    const wasMobile: boolean = this.layoutUtil.isMobileDevice();
    let isDesktop: boolean;

    this.layoutUtil.setMobileDeviceState();
    isDesktop = !this.layoutUtil.isMobileDevice();

    if (this.isCollapsed && wasMobile && isDesktop) {
      this.layoutUtil.setIsCollapsed(false);
    } else if (!this.isCollapsed && !wasMobile && !isDesktop) {
      this.layoutUtil.setIsCollapsed(true);
    }
  }

  private loadIcons(): void {
    this.matIconRegistry.addSvgIcon(
      'foodback',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/foodback.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'fork',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/restaurant.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'location_on',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/place.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'virus',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/virus.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'taxi',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg/taxi.svg')
    );
  }

  private setAndVerifyLastActivity(): void {
    if (!this.userService.getUserUuid()) {
      return;
    }
    const lastActivity: number = this.localStorage.retrieve(
      CONSTANTS.SESSION.LAST_ACTIVITY
    );

    if (!lastActivity) {
      this.sendActivityRequest();
      this.storeNewLastActivity();
    } else {
      this.verifyLastActivity(lastActivity);
    }
  }

  private verifyLastActivity(lastActivity: number): void {
    const hourInMsSec = 3600000;
    const diffHours: number =
      Math.abs(new Date().getTime() - lastActivity) / hourInMsSec;

    if (diffHours >= 1) {
      this.sendActivityRequest();
      this.storeNewLastActivity();
    }
  }

  private sendActivityRequest(): void {
    if (this.userActivitySubscription) {
      this.userActivitySubscription.unsubscribe();
    }

    this.userActivitySubscription = this.usersBackendService
      .updateUserActivity()
      .subscribe();
  }

  private storeNewLastActivity(): void {
    this.localStorage.store(CONSTANTS.SESSION.LAST_ACTIVITY, new Date().getTime());
  }
}
