import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import {Observable, of,  } from 'rxjs';
import { ContextObject } from "./shared/interfaces/login-object";
import { ContextService } from "./core/providers/context/context.service";
import { CONSTANTS } from "./shared/constants";
import { SharedComponentViewType } from "./shared/interfaces/common.interface";
import { I18nBackendService } from "./core/providers/backend/i18n-backend.service";
import { LanguageService } from "./core/providers/backend/language.service";
import { map, mergeMap, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ContextResolver implements Resolve<boolean> {
  constructor(private contextService: ContextService,
              private i18nBackendService: I18nBackendService,
              private languageService: LanguageService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {

    return this.getContext$().pipe(
      take(1),
      mergeMap((context: ContextObject) => this.setAndGetDefaultLanguageForContext$(context))
    )
  }

  private getContext$(): Observable<ContextObject> {
    return this.contextService.getContext$();
  }

  private setAndGetDefaultLanguageForContext$(context: ContextObject): Observable<boolean> {
    return this.i18nBackendService.getDefaultLanguageForContext$(
      context.uuid,
      context.type === CONSTANTS.CONTEXT.ACCOUNT ? SharedComponentViewType.ACCOUNT : SharedComponentViewType.VENUE
    ).pipe(
      map((defaultLanguage) =>  {
        this.languageService.setDefaultLanguage(defaultLanguage);
        return true;
      })
    )
  }
}
