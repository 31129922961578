<div class="logo-wrapper">
  <div class="sidebar-logo-full" *ngIf="!isCollapsed"></div>
  <div class="sidebar-logo-small" *ngIf="isCollapsed"></div>
</div>
<div class="topLevelDash-wrapper" *ngIf="showTopLeveDashboardBtn">
  <button type="button" class="btn btn-foodback navBtn" (click)="onTopLevelDashClick.emit()">
    <ng-container *ngIf="!isCollapsed; else smallText">
      {{ 'SHARED.SYSTEM.TOP_LEVEL_DASHBOARD' | translate }}
    </ng-container>
    <ng-template #smallText>
      <span appTooltip [text]="'SHARED.SYSTEM.TOP_LEVEL_DASHBOARD' | translate">
         {{ ('SHARED.SYSTEM.TOP_LEVEL_DASHBOARD' | translate).match(reg).join('') }}
      </span>
    </ng-template>
  </button>
</div>
