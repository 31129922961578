import { Component, Input } from '@angular/core';
import { times } from 'lodash';
import { CONSTANTS } from '../../constants';
import { BillFolderDotsVariant } from './bill-folder-dots.interface';

@Component({
  selector: 'app-bill-folder-dots',
  templateUrl: './bill-folder-dots.component.html',
  styleUrls: ['./bill-folder-dots.component.scss'],
})
export class BillFolderDotsComponent {
  @Input() score: number;
  @Input() variant?: BillFolderDotsVariant = BillFolderDotsVariant.CATEGORY;
  scores: number[] = times(CONSTANTS.FOODBACK.RANK_SCALE);
  BillFolderDotsVariant: typeof BillFolderDotsVariant = BillFolderDotsVariant;
}
