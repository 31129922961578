<app-data-card-widget-panel class="DataCardWidgetDetails">
  <ng-container header>
    <div>
      <span class="align-middle" appTooltip
            [text]="title && title.length >= CONSTANTS.PAGE_DEFAULTS.MAX_TITLE_NAME ? title : null">
            {{ title | translate | ellipsis: [CONSTANTS.PAGE_DEFAULTS.MAX_TITLE_NAME] }}</span>
      <span class="align-middle DataCardWidgetDetails-venueCount" appTooltip
      [text]="'SHARED.SYSTEM.ALL_VENUES' | translate"> ({{venues}})</span>
    </div>
  </ng-container>
  <div content>
    <div>
      <div class="widget-content" fxFlex="row" fxLayoutAlign="start center">
        <app-percent-circle
          class="DataCardWidgetDetails-circle"
          [goal]="goal"
          [useCustomContent]="true"
          [percentValue]="percentValue"
          [variant]="PercentCircleVariant.BLUE">
          <div custom-content>
            <span
              class="h1">{{dataStatisticsSet?.overallExperienceAverage || 0 | number: CONSTANTS.NUMBER_PIPE.DEFAULT_FORMAT}}</span>
            <span class="h1" *ngIf="dataStatisticsSet?.prevOverallExperienceAverage">
              <ng-container [ngTemplateOutlet]="arrowIndicator"
                            [ngTemplateOutletContext]="{isHigher: dataStatisticsSet?.overallExperienceAverage > dataStatisticsSet?.prevOverallExperienceAverage}">
              </ng-container>
            </span>
            <br/>
            <span *ngIf="goal" class="h4 fb-text-info">
              {{ 'SHARED.SYSTEM.GOAL' | translate }} {{ goal | number: CONSTANTS.NUMBER_PIPE.DEFAULT_FORMAT }}
            </span>
            <!--            <span *ngIf="dataStatisticsSet?.prevOverallExperienceAverage" class="h4 fb-text-info">-->
            <!--              {{ 'SHARED.TOP_LEVEL_DASHBOARD.PREV_VALUE' | translate:{'0': (dataStatisticsSet?.prevOverallExperienceAverage | number: CONSTANTS.NUMBER_PIPE.DEFAULT_FORMAT)} }}-->
            <!--            </span>-->
          </div>
          <ng-template #arrowIndicator let-isHigher="isHigher">
            <i *ngIf="isHigher" class="mdi mdi-arrow-up DataCardWidgetDetails-arrow--up"></i>
            <i *ngIf="!isHigher" class="mdi mdi-arrow-down DataCardWidgetDetails-arrow--down"></i>
          </ng-template>
        </app-percent-circle>
        <div fxLayout="row wrap" fxLayoutAlign="start start" *ngIf="dataStatisticsSet">
          <div fxFlex="row" class="card-entry" style="flex-grow: 1"fxLayoutAlign="start center" *ngFor="let entry of dataStatisticsSet.entries">
            <!-- <div class="DataCardWidgetDetails-headerIcon"
                 [ngClass]="entry.iconClass"
                 [attr.title]="'SHARED.SYSTEM.COMMENTS' | translate"></div> -->
            <div class="DataCardWidgetDetails-statisticsEntry">
              <div class="DataCardWidgetDetails-statisticsEntry-number">
                <span>{{ entry.data }}</span>
              </div>
              <div class="DataCardWidgetDetails-statisticsEntry-text">
                {{ entry.title | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <ng-content select="[custom-content]"></ng-content>
    </div>
  </div>
  <div class="DataCardWidgetDetails-footer" fxFlex="row" fxLayoutAlign="center center" footer>
    <a
      class="btn btn-foodback btn-foodback--secondary text-uppercase"
      [routerLink]="ACCOUNT_ROUTES_PATHS_FULL.OVERVIEW.ACCOUNT | routerLink: [CONSTANTS.ROUTE_PARAMS.ACCOUNT_ID, uuid]"
      [queryParams]="queryParams"
      [attr.aria-label]="'SHARED.TOP_LEVEL_DASHBOARD.OPEN_DASHBOARD' | translate">
      {{'SHARED.TOP_LEVEL_DASHBOARD.OPEN_DASHBOARD' | translate}}
    </a>
  </div>
</app-data-card-widget-panel>
