import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { find } from 'lodash';
import {
  I18nAvailableLanguage,
  I18nSupportedLanguage,
  I18nValidtionCoverage
} from '../../../shared/interfaces/i18n.interface';
import { StatementTranslationFromBackend } from '../../../shared/interfaces/statements.interface';
import { NotificationService } from '../notification/notification.service';

export interface IVenueLinkDetails {
  venueUuid: string;
  venueName: string;
}

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  private defaultLanguage: I18nAvailableLanguage;

  constructor(private translateService: TranslateService, private notificationService: NotificationService) {
  }

  showMissingTranslationInAccount(accountUuid: string, lang: I18nAvailableLanguage): void {
    this.showErrorPopup(
      this.translateService.instant('SHARED.LANGUAGES.CANNOT_ACTIVATE_MISSING_TRANSLATION'),
      `${this.translateService.instant('SHARED.LANGUAGES.MISSING_TRANSLATION_INFO', {
        languageName: lang.localizedLanguageName,
      })} ${this.getAccountPagesLinkList(accountUuid, lang.validationState.coverage)}`
    );
  }

  showMissingTranslationInVenue(venueUuid: string, lang: I18nAvailableLanguage): void {
    this.showErrorPopup(
      this.translateService.instant('SHARED.LANGUAGES.CANNOT_ACTIVATE_MISSING_TRANSLATION'),
      `${this.translateService.instant('SHARED.LANGUAGES.SECTION_ACTIVE_TEXT_MISSING_TRANS', {
        languageName: lang.localizedLanguageName,
      })} ${this.getVenuePagesLinkList(venueUuid, lang.validationState.coverage)}`
    );
  }

  showCannotDeactivateLanguage(venues: IVenueLinkDetails[], lang: I18nAvailableLanguage): void {
    this.showErrorPopup(
      this.translateService.instant('SHARED.LANGUAGES.CANNOT_DEACTIVATE_HEADER'),
      `${this.translateService.instant('SHARED.LANGUAGES.CANNOT_DEACTIVATE_TEXT', {
        languageName: lang.localizedLanguageName,
      })} ${this.getVenueConfPagesLinkList(venues)}`
    );
  }

  getDefaultLanguage(): I18nSupportedLanguage {
    return this.defaultLanguage;
  }

 setDefaultLanguage(language: I18nSupportedLanguage): void {
    this.defaultLanguage = language;
  }

  getTranslationObject(translations: StatementTranslationFromBackend[], lang: string): StatementTranslationFromBackend {
    if (!translations || (translations && translations.length === 0)) {
      return null;
    }
    const selectedTranslation: StatementTranslationFromBackend = find(translations, {lang});

    return selectedTranslation ? selectedTranslation : null;
  }

  getTranslation(translations: StatementTranslationFromBackend[], lang: string): string {
    const selectedTranslation: StatementTranslationFromBackend = this.getTranslationObject(translations, lang);

    return selectedTranslation ? selectedTranslation.value : '';
  }

  private getVenuePagesLinkList(venueUid: string, coverage: I18nValidtionCoverage): string {
    let content = '<ul>';

    if (!coverage.rewards.isTranslated) {
      content += `<li>${this.getAlertItemLink(
        `/venue/${venueUid}/settings/rewards`,
        this.translateService.instant('SHARED.SYSTEM.REWARDS')
      )}</li>`;
    }
    if (!coverage.portal.isTranslated) {
      content += `<li>${this.getAlertItemLink(
        `/venue/${venueUid}/settings/foodback-portal`,
        this.translateService.instant('SHARED.SYSTEM.QR_LANDING')
      )}</li>`;
    }

    content += '</ul>';

    return content;
  }

  private getAccountPagesLinkList(accountUuid: string, coverage: I18nValidtionCoverage): string {
    let content = '<ul class="Alert-itemList">';

    if (!coverage.foodbackQuestion.isTranslated) {
      content += `<li>${this.getAlertItemLink(
        `/account/${accountUuid}/settings/foodback-questions`,
        this.translateService.instant('SHARED.SYSTEM.FOODBACK_QUESTIONS')
      )}</li>`;
    }
    if (!coverage.oneMoreQuestion.isTranslated) {
      content += `<li>${this.getAlertItemLink(
        `/account/${accountUuid}/settings/one-more-question`,
        this.translateService.instant('SHARED.SYSTEM.ONE_MORE_QUESTION')
      )}</li>`;
    }
    if (!coverage.campaign.isTranslated) {
      content += `<li>${this.getAlertItemLink(
        `/account/${accountUuid}/settings/campaigns`,
        this.translateService.instant('SHARED.SYSTEM.CAMPAIGNS')
      )}</li>`;
    }

    content += '</ul>';

    return content;
  }

  private getVenueConfPagesLinkList(venues: IVenueLinkDetails[]): string {
    let content = '<ul class="Alert-itemList">';

    venues.forEach(
      (venue: IVenueLinkDetails) =>
        (content += `<li>${this.getAlertItemLink(
          `venue/${venue.venueUuid}/settings/configuration/regional-settings`,
          venue.venueName
        )}</li>`)
    );
    content += '</ul>';

    return content;
  }

  private getAlertItemLink(link: string, transKeyHeader: string): string {
    return `<a href="${link}" target="_blank" rel="noopener noreferrer">${this.translateService.instant(transKeyHeader)}</a>`;
  }

  private getAlertListItem(content: string): string {
    return `<li>${content}</li>`;
  }

  private showErrorPopup(header: string, content: string): void {
    this.notificationService.displaySwalPopup(header, `<div class="Alert-content"><br>${content}</div>`, 'error');
  }
}
