import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ContextService } from '../context/context.service';

@Injectable()
export class FallbackContextGuardService implements CanActivate, CanActivateChild {
  constructor(private contextService: ContextService) {}

  canActivate(): Observable<boolean> {
    return this.contextService.switchToFallbackContext$().pipe(map(() => true));
  }

  canActivateChild(): Observable<boolean> {
    return this.canActivate();
  }
}
