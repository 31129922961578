import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { zip } from 'lodash';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IFusionChartCategories, IFusionChartDataSet } from '../../../../../vendor/types/fusionCharts';
import { FoodbackAccount } from '../../../../core/classes/account/account.class';
import { AccountService } from '../../../../core/providers/account/account.service';
import { ChartUtilsService } from '../../../../core/utils';
import { CONSTANTS } from '../../../constants';
import { PeriodPerformanceChartInput } from './period-performance-chart.interface';

@Component({
  selector: 'app-period-performance-chart',
  templateUrl: './period-performance-chart.component.html',
  styleUrls: ['./period-performance-chart.component.scss'],
})
export class PeriodPerformanceChartComponent implements OnInit, OnDestroy {
  @Input() set translateKey(value: string) {
    this.translateKeValue = value;
    this.setChartData(this.dataValue);
  }

  @Input() set data(data: PeriodPerformanceChartInput) {
    this.dataValue = data;

    if (this.translateKeValue) {
      this.setChartData(this.dataValue);
    }
  }

  @Input() set goal(goal: number) {
    this.goalValue = goal;
    this.setGoalOnChart();
  }

  @Input() set printMode(printMode: boolean) {
    if (printMode) {
      this.width = '500';
    }
  }

  @ViewChild('chartContainer') chartContainer: ElementRef;

  isDataLoaded = false;
  isGoalsLoaded = false;

  chartCategories: IFusionChartCategories[];
  chartDataSet: IFusionChartDataSet[];
  dataSource;
  width = "100%";

  private account: FoodbackAccount = new FoodbackAccount(null);
  private goalValue: number = null;
  private translateKeValue: string;
  private dataValue: PeriodPerformanceChartInput = {
    currentCount: [],
    currentScores: [],
  };
  private onDestroy$ = new Subject<void>();

  constructor(
    private accountService: AccountService,
    private translateService: TranslateService,
    private chartUtilsService: ChartUtilsService
  ) {}

  ngOnInit() {
    this.initChart();
    // this.setChartOptions();
    this.getAccount$()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((data: FoodbackAccount) => {
        this.account = data;
        this.setGoalOnChart();
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private getAccount$(): Observable<FoodbackAccount> {
    return this.accountService.getAccount$();
  }

  private setGoalOnChart() {
    if (this.account.hasAccessTo().goals && this.goalValue) {
      this.isGoalsLoaded = true;
    }
  }

  private setChartData(data: PeriodPerformanceChartInput = { currentCount: [], currentScores: [] }) {
    const mappedData = zip(data.currentCount, data.currentScores).map(([count, score]) => ({
      rangeName: score.rangeName,
      count: count.value,
      score: score.value,
    }));

    this.translateService
      .get(this.translateKeValue)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(({ SCORES_NAME, FOODBACKS }) => {
        this.chartDataSet = [
          {
            seriesName: SCORES_NAME,
            renderAs: 'spline',
            anchorBgColor: CONSTANTS.CHART_COLORS.FOODBACKS.SCORE_THIS_PERIOD.backgroundColor,
            color: CONSTANTS.CHART_COLORS.FOODBACKS.SCORE_THIS_PERIOD.borderColor,
            drawAnchors: true,
            data: mappedData.map(value => ({
              value: value && value.score ? value.score.toString() : '',
              toolText: this.chartUtilsService.getTooltipTextForFirstItem(),
            })),
          },
          {
            seriesName: FOODBACKS,
            renderAs: 'column2d',
            color: CONSTANTS.CHART_COLORS.FOODBACKS.FOODBACKS_COUNT.borderColor,
            parentYAxis: 'S',
            data: mappedData.map(value => ({
              value: value && value.count ? value.count.toString() : '',
              toolText: this.chartUtilsService.getTooltipTextForItem(),
            })),
          },
        ];
      });
    this.chartCategories = [
      {
        category: mappedData.map(val => ({ label: val.rangeName })),
      },
    ];
    this.initChart();
    this.dataSource.categories = this.chartCategories;
    this.dataSource.dataset = this.chartDataSet;

    if (this.chartDataSet && this.chartDataSet[0] && this.chartDataSet[0] && this.chartDataSet[0].data.length > 0) {
      this.isDataLoaded = true;
    }
  }

  private initChart() {
    const additionalOptions = {
      yaxisname: this.translateService.instant('HOME.OVERVIEW.PERFORMANCE_CHART.AXES.SCORES_NAME'),
      sYAxisName: this.translateService.instant('HOME.OVERVIEW.PERFORMANCE_CHART.AXES.FOODBACKS'),
      pYAxisMaxValue: '6.0',
      labelDisplay: 'rotate',
      slantLabel: '1',
      baseFontSize: '12'
    };

    this.dataSource = this.chartUtilsService.initChartWithDataSetAndCategories(additionalOptions);
  }
}
