<div class="InputWithCounter">
  <mat-form-field class="InputWithCounter-input">
    <mat-label>{{ label }}</mat-label>
    <input
      #inputRef="ngModel"
      matInput
      autocomplete="off"
      list="autocompleteOff"
      [required]="required"
      (blur)="onBlur()"
      (focus)="onFocus()"
      [ngClass]="className"
      [placeholder]="placeholder"
      [attr.maxlength]="maxLength"
      [style.minHeight.px]="minHeight"
      [attr.disabled]="disabled ? true : null"
      [disabled]="disabled"
      [ngModel]="value"
      (ngModelChange)="onChange($event)" />
    <mat-icon matSuffix [ngClass]="iconClass">{{ iconName }}</mat-icon>
    <mat-hint>
      <ng-content></ng-content>
      <span>{{ hint }}</span>
      <span>{{ counter }}/{{ maxLength }}</span>
    </mat-hint>
  </mat-form-field>
</div>
