import { Injectable } from '@angular/core';
import { ceil, round } from 'lodash';
import { CONSTANTS } from '../constants';

@Injectable()
export class TableProgressBarService {
  convertRatingToPercent(rating: number): number {
    // eslint-disable-next-line no-magic-numbers
    return Math.min(ceil((rating / CONSTANTS.FOODBACK.RANK_SCALE) * 100), 100);
  }

  getWidthClassForRating(rating: number): string {
    return `${CONSTANTS.CSS_PREFIX_CLASSES.PROGRESS}${rating}`;
  }

  getColorClassForRating(rating: number, goal: number = CONSTANTS.FOODBACK.DEFAULT_GOAL): string {
    const classNameForColor = CONSTANTS.CSS_PREFIX_CLASSES.PROGRESS_BAR;

    return classNameForColor + this.getColorPostfix(rating, goal);
  }

  getTextColorForRating(rating: number, goal: number = CONSTANTS.FOODBACK.DEFAULT_GOAL): string {
    const classNameForColor = CONSTANTS.CSS_PREFIX_CLASSES.FB_TEXT;

    return classNameForColor + this.getColorPostfix(rating, goal);
  }

  getColorPostfix(rating: number, goal: number = CONSTANTS.FOODBACK.DEFAULT_GOAL): string {
    const roundedRating = round(rating, 1);

    if (roundedRating >= goal) {
      return CONSTANTS.CSS_POSTFIX_CLASSES.SUCCESS;
    }
    if (roundedRating >= CONSTANTS.FOODBACK.MID_RANK) {
      return CONSTANTS.CSS_POSTFIX_CLASSES.WARNING;
    }

    return CONSTANTS.CSS_POSTFIX_CLASSES.DANGER;
  }
}
