import { ChangeDetectorRef, Component, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent {
  @Input() text: string;
  @Input() contentTemplate: TemplateRef<any>;
  @Input() templateData: {} | null;

  @Input() set showAboveTheParent(value) {
    this.showAbove = value;
    this.changeDetectionRef.detectChanges();
  }

  showAbove = false;

  constructor(private changeDetectionRef: ChangeDetectorRef) {}
}
