import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';

@Pipe({
  name: 'datex',
})
export class DatexPipePipe implements PipeTransform {
  transform(value: any, format = '', timeZone?: string): string {
    const momentDate = timeZone ? moment.utc(value).tz(timeZone) : moment.utc(value);

    if (!momentDate.isValid()) {
      return value;
    }

    return momentDate.format(format);
  }
}
