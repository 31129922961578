<layout-content class="AccAdvertisement">
  <!--TODO: In the future, a single venue may have an access to both: bill folder and take away -->
  <!--<app-advertisement-category-switcher (onSelect)="switchAdvertisementCategory($event)"></app-advertisement-category-switcher>-->
  <!--<app-message-bar *ngIf="!account.hasVenueType().restaurant"-->
  <!--[type]="messageBarTypes.INFO">-->
  <!--{{'ADMIN.SURVEYS.DEMOGRAPHICS.BILL_FOLDER_MESSAGE_BAR' | translate}}-->
  <!--</app-message-bar>-->
  <div aboveContainer *ngIf="isEnforcementVisible() || (!isEnforcementVisible() && advertisementEnforcement)">
    <app-acc-enforcement-switcher
      (enforcementStatusChanged)="saveEnforcementState($event)"
      [isActivated]="advertisementEnforcement"
      [isLocked]="!isEnforcementVisible()"
      class="AccAdvertisement-enforcement">
      <span enforcement-locked>{{ 'VENUE.FOLDER.ADVERTISEMENT.ENFORCEMENT_LOCKED' | translate }}</span>
      <span enforcement-configurable>{{ 'ACCOUNT.FOLDER.ADVERTISEMENT.CONFIGURE_ENFORCEMENT' | translate: { childrenName: accountChildrenNamePlural }  }}</span>
    </app-acc-enforcement-switcher>
  </div>
  <div
    class="AccAdvertisement-container"
    [ngClass]="{
      'AccAdvertisement-container--disabled': isAdvertisementDisabled()
    }">
    <app-advertisement-form
      (onBackToDefault)="backToDefault()"
      (onFileOverBase)="fileOverBase($event)"
      (onToggleURL)="setAdvertisementURLState($event)"
      (onURLChange)="setAdvertisementURL($event)"
      [advertisementURL]="initState?.url"
      [uploader]="uploader"
      [avatarURL]="avatarURL"
      [timezone]="timezone"
      [imageLoading]="imageLoading"
      [hasBaseDropZoneOver]="hasBaseDropZoneOver"
      [advertisementLastChangeData]="advertisementLastChange"
      (onDisableSubmitButton)="onDisableSubmitButton($event)"></app-advertisement-form>
  </div>
</layout-content>

<div class="submit-panel">
  <button class="btn btn-foodback text-uppercase" [disabled]="isPublishDisabled" (click)="publishAd()">
    {{ 'SHARED.SYSTEM.PUBLISH_CHANGES' | translate }}
  </button>
</div>
