import { Injectable } from '@angular/core';
import { AbstractControl, ValidatorFn } from '@angular/forms';
import { BehaviorSubject, Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { CONSTANTS } from '../../../shared/constants';
import { WheelOfFortuneBackend, WheelOfFortuneBackendPayload } from '../../../shared/form-models-interfaces';
import { ApiResponse } from '../../../shared/interfaces/api-response.interface';
import { VenueDemographicsConfigurationVariant } from '../../../shared/interfaces/bill-folders';
import { ApiService } from '../api/api.service';
import { ValidationService } from './validation.service';

@Injectable()
export class WheelOfFortuneBackendService {
  private venuesPath = `${environment.apiBaseUrl.handbook}/fbi/venues`;
  private accountsPath = `${environment.apiBaseUrl.handbook}/fbi/accounts`;
  private isWheelEnable: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private apiService: ApiService, private validationService: ValidationService) {}

  checkIsWheelEnable(): BehaviorSubject<boolean> {
    return this.isWheelEnable;
  }

  getWheelOfFortuneSettings$(context: string, uuid: string): Observable<WheelOfFortuneBackend> {
    const requestPath = `${context === CONSTANTS.CONTEXT.ACCOUNT ? this.accountsPath : this.venuesPath}/${uuid}/${
      CONSTANTS.ENDPOINTS.WHEEL_OF_FORTUNE
    }`;

    return this.apiService.get$(requestPath).pipe(
      map(response => response.content),
      tap(response => {
        this.isWheelEnable.next(response.isWheelEnabled);
      })
    );
  }

  updateWheelOfFortuneSettings$(
    context: string,
    uuid: string,
    wheelOfFortuneSettings: WheelOfFortuneBackendPayload
  ): Observable<ApiResponse> {
    const requestPath = `${context === CONSTANTS.CONTEXT.ACCOUNT ? this.accountsPath : this.venuesPath}/${uuid}/${
      CONSTANTS.ENDPOINTS.WHEEL_OF_FORTUNE
    }`;

    return this.apiService
      .put$(requestPath, wheelOfFortuneSettings)
      .pipe(
        tap(() => {
          this.isWheelEnable.next(wheelOfFortuneSettings.isWheelEnabled);
        })
      )
      .pipe(
        catchError((error): Observable<ApiResponse> => {
          this.validationService.displayNotification(error);

          return observableThrowError('Error with "update Wheel of fortune settings" request', error);
        })
      );
  }

  setFormControlValidator(control: AbstractControl, newValidator: ValidatorFn | ValidatorFn[] | null) {
    control.setValidators(newValidator);
    control.updateValueAndValidity();
  }

  resetFormControl(control: AbstractControl) {
    control.clearValidators();
    control.updateValueAndValidity();
    control.reset();
  }

  areDemographicsActive(demographicsVariant: VenueDemographicsConfigurationVariant): boolean {
    return demographicsVariant === VenueDemographicsConfigurationVariant.ALL;
  }
}
