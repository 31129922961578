<table class="VenuesList table table-striped table-hover fb-table-management-list table-fixed">
  <thead>
    <tr>
      <th [style.width.px]="200">{{ 'SHARED.SYSTEM.NAME' | translate }}</th>
      <th>{{ 'SHARED.SYSTEM.GROUPS' | translate }}</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>
        <mat-form-field>
          <mat-label>{{ 'SHARED.SYSTEM.FILTER' | translate }}</mat-label>
          <input
            #venueNameInput
            matInput
            autocomplete="off"
            list="autocompleteOff"
            (input)="updateVenueNameFilterCriteria(venueNameInput.value)"
            [placeholder]="'SHARED.SYSTEM.FILTER' | translate" />
        </mat-form-field>
      </td>
      <td>
        <app-select
          class="mr-sm FilterPanel-filterItem"
          [formControl]="filterGroupsCtrl"
          [items]="groupsDropdownItems"
          [placeholder]="'SHARED.SYSTEM.FILTER' | translate"
          [label]="'SHARED.SYSTEM.FILTER' | translate"
          [isMultiModeEnable]="true"></app-select>
      </td>
    </tr>
    <tr *ngFor="let venue of filterCriteria.items; trackBy: trackVenue">
      <td>{{ venue.brandName }}</td>
      <td>
        <div class="VenuesList__groups">
          <app-multi-select-tag
            (onSelectItem)="assignGroup($event, venue)"
            (onDeselectItem)="unassignVenueFromGroup($event, venue)"
            [addOnDemandMode]="true"
            [editMode]="isVenueFocused(venue)"
            [tags]="venue.venueGroupsDropdown"
            [items]="groupsDropdownItems"
            [placeholder]="'ACCOUNT.GROUPS.LISTS.VENUES.MULTI_SELECT_TAG.PLACEHOLDER' | translate"
            [noTagsPlaceholder]="'ACCOUNT.GROUPS.LISTS.VENUES.MULTI_SELECT_TAG.NO_TAGS_PLACEHOLDER' | translate"
            [noResultsPlaceholder]="
              'ACCOUNT.GROUPS.LISTS.VENUES.MULTI_SELECT_TAG.NO_RESULTS_PLACEHOLDER' | translate
            "></app-multi-select-tag>
        </div>
      </td>
    </tr>
  </tbody>
</table>

<div *ngIf="!filterCriteria.items.length">{{ 'SHARED.SYSTEM.NO_RESULTS' | translate }}</div>
