import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { IamBackendService } from '../../core/providers/backend/iam-backend.service';
import { PermissionsBackendService } from '../../core/providers/backend/permissions-backend.service';
import { NavigationService } from '../../core/providers/navigation/navigation.service';
import { NotificationService } from '../../core/providers/notification/notification.service';
import { ContextUtil } from '../../core/utils';
import { ContextObject } from '../../shared/interfaces/login-object';

@Injectable()
export class HasNoAccessToFbiGuard implements CanActivate {
  constructor(
    private permissionsBackendService: PermissionsBackendService,
    private navigationService: NavigationService,
    private notificationService: NotificationService,
    private iamBackendService: IamBackendService,
    private contextUtil: ContextUtil
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    // if a user that has access tries to reach page /no-access, this guard will send them to 404 page
    return this.permissionsBackendService.hasAccess$().pipe(
      mergeMap((hasAccess: boolean) => {
        if (hasAccess) {
          return this.iamBackendService.getUserRedirectInfo().pipe(
            mergeMap((response: ContextObject) => {
              if (response && response.uuid) {
                this.redirectUserToProperPage(response);
              } else {
                this.navigationService.goToInner404();
              }

              this.notificationService.hideLoadingPopup();

              return of(false);
            })
          );
        }

        this.notificationService.hideLoadingPopup();

        return of(!hasAccess);
      })
    );
  }

  private redirectUserToProperPage(context: ContextObject): void {
    if (this.contextUtil.isVenueContext(context.type)) {
      this.navigationService.goToVenueMainScreen(context.uuid);
    } else if (this.contextUtil.isAccountContext(context.type)) {
      this.navigationService.goToScoreboards(context.uuid);
    }
  }
}
