import { ConnectedPosition, ConnectionPositionPair, Overlay, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ComponentRef, Directive, ElementRef, HostListener, Input, OnDestroy, TemplateRef } from '@angular/core';
import { Subscription, fromEvent } from 'rxjs';
import { TooltipComponent } from './tooltip.component';

@Directive({
  selector: '[appTooltip]',
})
export class TooltipDirective implements OnDestroy {
  @Input() showToolTip = true;

  @Input() text: string;

  @Input() contentTemplate: TemplateRef<any>;
  @Input() templateData: {} | null;
  @Input() panelClass: string;
  @Input() positionSet: ConnectedPosition[];

  private overlayRef: OverlayRef;
  private showAboveTheParent = false;
  private tooltipRef: ComponentRef<TooltipComponent>;
  private subscriptions: Subscription = new Subscription(null);

  constructor(private overlay: Overlay, private overlayPositionBuilder: OverlayPositionBuilder, private elementRef: ElementRef) {}

  @HostListener('mouseenter')
  show() {
    this.createOverlay();

    if (this.overlayRef && !this.overlayRef.hasAttached()) {
      this.tooltipRef = this.overlayRef.attach(new ComponentPortal(TooltipComponent));
      this.tooltipRef.instance.text = this.text;
      this.tooltipRef.instance.contentTemplate = this.contentTemplate;
      this.tooltipRef.instance.templateData = this.templateData;
    }
  }

  @HostListener('mouseleave')
  @HostListener('touchstart')
  @HostListener('focusout')
  @HostListener('window:scroll', ['$event'])
  hide() {
    this.closeToolTip();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.closeToolTip();
  }

  private createOverlay() {
    if (!this.showToolTip) {
      return;
    }
    const positions = [
      new ConnectionPositionPair(
        { originX: 'center', originY: 'bottom' },
        {
          overlayX: 'center',
          overlayY: 'top',
        }
      ),
      new ConnectionPositionPair({ originX: 'center', originY: 'top' }, { overlayX: 'center', overlayY: 'bottom' }),
    ];
    const positionStrategy = this.overlayPositionBuilder
      .flexibleConnectedTo(this.elementRef)
      .withPositions(this.positionSet ? this.positionSet : positions);

    this.overlayRef = this.overlay.create({
      positionStrategy,
      panelClass: this.panelClass,
    });
    this.subscriptions.add(
      positionStrategy.positionChanges.subscribe(pos => {
        this.showAboveTheParent = pos.connectionPair.originY === 'top';

        if (this.tooltipRef) {
          this.tooltipRef.instance.showAboveTheParent = this.showAboveTheParent;
        }
      })
    );
    this.subscriptions.add(fromEvent(window, 'scroll', { capture: true }).subscribe(() => this.closeToolTip()));
  }

  private closeToolTip() {
    if (this.overlayRef) {
      this.overlayRef.detach();
    }
  }
}
