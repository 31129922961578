import { Injectable } from '@angular/core';
import { map as _map } from 'lodash';
import { Observable, of } from 'rxjs';
import { map, mergeMap, share, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { CONSTANTS } from '../../../shared/constants';
import { IDropdownItem } from '../../../shared/interfaces/dropdown/dropdown-item.interface';
import { PermissionsEntry, VenuePermissionsEntry } from '../../../shared/interfaces/permissions.interface';
import { ApiService } from '../api/api.service';
import { VenuesService } from '../venues/venues.service';

@Injectable()
export class PermissionsBackendService {
  private readonly path = `${environment.apiBaseUrl.handbook}/fbi/accounts`;
  private readonly venuePermissionsCache: Map<string, VenuePermissionsEntry[]> = new Map();
  private cachedPermissions: PermissionsEntry[] = null;
  private permissionRequest$: Observable<PermissionsEntry[]>;
  private isRedirected = false;

  constructor(private apiService: ApiService, private venuesService: VenuesService) {}

  hasAccess$(): Observable<boolean> {
    return this.getPermissions$().pipe(mergeMap(permissions => of(!!permissions.length)));
  }

  getPermissions$(): Observable<PermissionsEntry[]> {
    if (this.cachedPermissions) {
      return of(this.cachedPermissions);
    }
    if (this.permissionRequest$) {
      return this.permissionRequest$;
    }

    this.permissionRequest$ = this.apiService.get$(`${this.path}/${CONSTANTS.ENDPOINTS.PERMISSIONS_LIST}`).pipe(
      map(response => response.content),
      tap((permissions: PermissionsEntry[]) => {
        this.cachedPermissions = permissions.slice();
      }),
      share()
    );

    return this.permissionRequest$;
  }

  getVenuePermissions$(accountUuid: string): Observable<VenuePermissionsEntry[]> {
    if (this.venuePermissionsCache.has(accountUuid)) {
      return of(this.venuePermissionsCache.get(accountUuid));
    }

    return this.apiService.get$(`${this.path}/${accountUuid}/${CONSTANTS.ENDPOINTS.VENUE_PERMISSIONS_LIST}`).pipe(
      map(response => response.content.sort((a, b) => a.venueName.toLowerCase().localeCompare(b.venueName.toLowerCase()))),
      tap((permissions: VenuePermissionsEntry[]) => {
        this.venuePermissionsCache.set(accountUuid, permissions);
        this.venuesService.setVenuesPermissions(accountUuid, permissions);
      }),
      share()
    );
  }

  getAccountsSignedToUser$(): Observable<IDropdownItem[]> {
    return this.getPermissions$().pipe(
      mergeMap((response: PermissionsEntry[]) =>
        of(
          _map(response, (element: PermissionsEntry) => ({
            id: element.accountUuid,
            text: element.accountName,
            value: null,
            additionalInfo: element.venueType,
          }))
        )
      )
    );
  }

  invalidateVenuesCacheForAccount(accountUuid: string): void {
    this.venuePermissionsCache.delete(accountUuid);
  }

  invalidateVenuesCacheForAllAccounts(): void {
    this.venuePermissionsCache.clear();
  }

  invalidateAccountsCache(): void {
    this.cachedPermissions = null;
  }

  setRedirectStatus(status: boolean): void {
    this.isRedirected = status;
  }

  getIsRedirected(): boolean {
    return this.isRedirected;
  }
}
