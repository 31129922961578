import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CONSTANTS } from '../../shared/constants';
import { UserForm } from '../../shared/form-models-interfaces';
import { InputTextMask } from '../../shared/interfaces/common.interface';

interface IPlaceholder {
  ZIP: string;
  CITY: string;
  BANK_ACCOUNT: string;
  PHONE: string;
}

@Injectable()
export class FormsUtil {
  masks = {
    NOR: {
      prefix: CONSTANTS.PREFIXES.NOR,
      zipCodeMask: [/\d/, /\d/, /\d/, /\d/],
      zipCodeShortMask: '"TKCA 1ZZ"',
      bankAccountNumberMask: [/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/],
    },
    SWE: {
      prefix: CONSTANTS.PREFIXES.SWE,
      zipCodeShortMask: '000 00',
      zipCodeMask: [/\d/, /\d/, /\d/, ' ', /\d/, /\d/],
    },
    DNK: {
      prefix: CONSTANTS.PREFIXES.DNK,
      zipCodeShortMask: '0000',
      zipCodeMask: [/\d/, /\d/, /\d/, /\d/],
    },
  };

  static removeControl(form: FormGroup, path: string) {
    const controlName: string = path.split('.').pop();
    const controlParent: FormGroup = form.get(path).parent as FormGroup;

    controlParent.removeControl(controlName);
  }

  getZipMaskByCountry(country: string): InputTextMask {
    if (this.masks[country]) {
      return this.masks[country].zipCodeMask || false;
    }

    return false;
  }

  getShortZipMaskByCountry(country: string) {
    if (this.masks[country]) {
      return { mask: this.masks[country].zipCodeShortMask };
    }

    return null;
  }

  getBankAccountNumberMask(country: string): InputTextMask {
    if (this.masks[country]) {
      return this.masks[country].bankAccountNumberMask;
    }

    return false;
  }

  getCorrectPhoneNumber(userObject: UserForm): string {
    return userObject.phone ? userObject.phone.replace(/ /g, '') : null;
  }

  getZipPlaceholder(country: string): string {
    return this.getPlaceholderByCountry(country).ZIP;
  }

  getPhonePlaceholder(country: string): string {
    return this.getPlaceholderByCountry(country).PHONE;
  }

  getCityPlaceholder(country: string): string {
    return this.getPlaceholderByCountry(country).CITY;
  }

  getPlaceholderByCountry(country: string): IPlaceholder {
    switch (country) {
      case CONSTANTS.COUNTRIES.ALFA3_CODES.SWEDEN:
        return CONSTANTS.COUNTRIES.PLACEHOLDERS.SWEDEN;
      case CONSTANTS.COUNTRIES.ALFA3_CODES.DENMARK:
        return CONSTANTS.COUNTRIES.PLACEHOLDERS.DENMARK;
      case CONSTANTS.COUNTRIES.ALFA3_CODES.GREAT_BRITAIN:
        return CONSTANTS.COUNTRIES.PLACEHOLDERS.GREAT_BRITAIN;
      case CONSTANTS.COUNTRIES.ALFA3_CODES.UNITED_STATES:
        return CONSTANTS.COUNTRIES.PLACEHOLDERS.UNITED_STATES;
      case CONSTANTS.COUNTRIES.ALFA3_CODES.GERMANY:
        return CONSTANTS.COUNTRIES.PLACEHOLDERS.GERMANY;
      case CONSTANTS.COUNTRIES.ALFA3_CODES.CHINA:
        return CONSTANTS.COUNTRIES.PLACEHOLDERS.CHINA;
      case CONSTANTS.COUNTRIES.ALFA3_CODES.PORTUGAL:
        return CONSTANTS.COUNTRIES.PLACEHOLDERS.PORTUGAL;
      case CONSTANTS.COUNTRIES.ALFA3_CODES.FINLAND:
        return CONSTANTS.COUNTRIES.PLACEHOLDERS.FINLAND;
      case CONSTANTS.COUNTRIES.ALFA3_CODES.SPAIN:
        return CONSTANTS.COUNTRIES.PLACEHOLDERS.SPAIN;
      case CONSTANTS.COUNTRIES.ALFA3_CODES.FRANCE:
        return CONSTANTS.COUNTRIES.PLACEHOLDERS.FRANCE;
      case CONSTANTS.COUNTRIES.ALFA3_CODES.NORWAY:
      default:
        return CONSTANTS.COUNTRIES.PLACEHOLDERS.NORWAY;
    }
  }
}
