import { Injectable } from '@angular/core';
import { CONSTANTS } from '../../shared/constants';

@Injectable()
export class ContextUtil {
  isAccountContext(userContext: string): boolean {
    return userContext === CONSTANTS.CONTEXT.ACCOUNT;
  }

  isVenueContext(userContext: string): boolean {
    return userContext === CONSTANTS.CONTEXT.VENUE;
  }
}
