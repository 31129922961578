import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-folder-branding-logo',
  templateUrl: './folder-branding-logo.component.html',
  styleUrls: ['./folder-branding-logo.component.scss'],
})
export class FolderBrandingLogoComponent {
  @Input() logoUrl: string;
  @Output() readonly uploadedImage: EventEmitter<string> = new EventEmitter();
  @Output() readonly uploadingStatusChanged: EventEmitter<boolean> = new EventEmitter();
  @Output() readonly onLogoImgError: EventEmitter<void> = new EventEmitter();

  sendUploadedImageToParent(imageUuid: string): void {
    this.uploadedImage.emit(imageUuid);
  }

  handleUploadingStatusChanged(isUploading: boolean): void {
    this.uploadingStatusChanged.emit(isUploading);
  }
}
