import { NotificationTriggerType } from '../../components/venue/venue-notifications/venue-notifications.interface';
import { UserForBackend } from '../form-models-interfaces';
import { Observable } from "rxjs";

export interface NotificationRecipient {
  userUuid?: string;
  uuid: string;
}

interface NewNotificationCategoryTriggerConfiguration {
  categoryUuid: string;
  answerValues: number[];
}

interface NewNotificationQuestionTriggerConfiguration {
  questionUuid: string;
  answerValues: number[];
}

interface NewNotificationAnyQuestionTriggerConfiguration {
  answerValues: number[];
}

export interface NewNotificationTrigger {
  triggerType: string;
  configuration:
    | NewNotificationAnyQuestionTriggerConfiguration
    | NewNotificationCategoryTriggerConfiguration
    | NewNotificationQuestionTriggerConfiguration;
}

export interface NewNotificationRequestBody {
  notifyWhen: string;
  name: string;
  isCommentRequired: boolean;
  isFollowUpEnabled: boolean;
  isPermissionToContactRequired: boolean;
  recipients: string[];
  triggers: NewNotificationTrigger[];
}

export interface NotificationResponseCategoryTriggerConfiguration {
  category: {
    uuid: string;
  };
  answerValues: number[];
}

export interface NotificationResponseQuestionTriggerConfiguration {
  question: {
    uuid: string;
  };
  answerValues: number[];
}

export interface NotificationResponseTrigger {
  triggerType: NotificationTriggerType;
  configuration:
    | NotificationResponseCategoryTriggerConfiguration
    | NotificationResponseQuestionTriggerConfiguration;
}

export interface NotificationsListResponseEntry {
  uuid: string;
  venueUuid: string;
  name: string;
  isEnabled: boolean;
  notifyWhen: string;
  isCommentRequired: boolean;
  isFollowUpEnabled: boolean;
  isPermissionToContactRequired: boolean;
  triggers: NotificationResponseTrigger[]; // @todo add correct typing when implementing edit
  recipients$?: Observable<string>;
}

export type NotificationsListResponse = NotificationsListResponseEntry[];

export interface IRecipientsResponse {
  recipients: UserForBackend[];
}

export interface IRecipient extends UserForBackend {
  header?: string;
}

export enum RecipientType {
  ACCOUNT = 'account_user',
  VENUE = 'venue_user',
  HEADER = 'header',
}
