import { Component, Input } from '@angular/core';
import { ACCOUNT_ROUTES_PATHS_FULL } from 'app/components/account/account-routes-paths';
import { IDataCardWidgetScaffold } from '../data-card-widget-scaffold.interface';
import { ContextService } from 'app/core/providers/context/context.service';

@Component({
  selector: 'app-data-card-widget-details',
  templateUrl: './data-card-widget-details.component.html',
  styleUrls: ['./data-card-widget-details.component.scss'],
})
export class DataCardWidgetDetailsComponent extends IDataCardWidgetScaffold {
  @Input() venues: number;
  @Input() uuid: string;
  @Input() queryParams;

  readonly ACCOUNT_ROUTES_PATHS_FULL = ACCOUNT_ROUTES_PATHS_FULL;
}
