import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { get, toNumber } from 'lodash';
import { Observable, Subject, forkJoin } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { TopLowPerformingQuestionsBackendResponse } from '../../../../components/venue/dashboard/top-low-performing-questions/top-low-performing-questions.interface';
import { VENUE_ROUTES_PATHS_FULL } from '../../../../components/venue/venue-routes-paths';
import { DashboardDatesService } from '../../../../components/venue/venue-shared/dashboard-dates.service';
import { FoodbackAccount } from '../../../../core/classes/account/account.class';
import { DashboardQueryParams } from '../../../../core/interfaces/dashboard-query-params.interface';
import { DashboardQueryParamsService } from '../../../../core/providers/backend/dashboard-query-params.service';
import { CONSTANTS } from '../../../constants';
import { AccountPackagePermissions } from '../../../interfaces/account-packages-permissions.interface';
import {
  AccountScores,
  IVenueScores,
  VenueCategoryAverageEntry,
  VenueStatistics,
} from '../../../interfaces/analytics.interface';
import { SharedComponentViewType } from '../../../interfaces/common.interface';
import { DynamicDateRange } from '../../../interfaces/date-range.interface';
import {
  GoalsResponseEntry,
  GoalsResponseMapped,
} from '../../../interfaces/goals-backend.interface';
import {
  IDataCardWidgetEntryStatistics,
  IDataCardWidgetStatistics,
} from '../../../interfaces/widgets/data-card-widget-statistics.interface';
import { PreloaderType } from '../../preloader/preloader.enum';
import { DayByDayChartInput } from '../day-by-day-chart/day-by-day-chart.interface';
import { HourByHourChartInput } from '../hour-by-hour-chart/hour-by-hour-chart.interface';
import {
  CategoryData,
  CategoryWidgetDetails,
  OverallExperienceData,
} from './foodback-overview.interface';
import { FoodbackOverviewService } from './foodback-overview.service';
import { IDataFilter } from "../../questions-stats/interfaces/questions-stats-details.interface";

@Component({
  selector: 'app-foodback-overview',
  templateUrl: './foodback-overview.component.html',
  styleUrls: ['./foodback-overview.component.scss'],
})
export class FoodbackOverviewComponent implements OnInit, OnDestroy {
  account: FoodbackAccount = new FoodbackAccount(null);
  drillDownQueryParams: DashboardQueryParams;
  foodbackScores: IVenueScores;
  isSelectedDateRangeAtLeastOneWeek = false;
  dateRangeForChart: DynamicDateRange;
  dailyPerformance: DayByDayChartInput;
  hourlyPerformance: HourByHourChartInput;
  categoriesData: Map<string, CategoryData> = new Map<string, CategoryData>();
  categories: CategoryData[] = [];
  overallExperienceData: OverallExperienceData = {};
  accountScores: AccountScores;
  loading: any = {
    accountScores: false,
    venueScores: false,
    categories: false,
    dayByDay: false,
    hourByHour: false,
  };
  isAccountContext: boolean;
  statistics: IDataCardWidgetStatistics;
  contextUuid: string;
  viewType: SharedComponentViewType = SharedComponentViewType.ACCOUNT;

  readonly PreloaderType: typeof PreloaderType = PreloaderType;
  readonly CONSTANTS: typeof CONSTANTS = CONSTANTS;
  readonly VENUE_ROUTES_PATHS_FULL: typeof VENUE_ROUTES_PATHS_FULL =
    VENUE_ROUTES_PATHS_FULL;

  private onDestroy$: Subject<void> = new Subject<void>();
  private filterValue: IDataFilter = null;

  constructor(
    private activatedRoute: ActivatedRoute,
    private dashboardDatesService: DashboardDatesService,
    private dashboardQueryParamsService: DashboardQueryParamsService,
    private foodbackOverviewService: FoodbackOverviewService,
    private cdf: ChangeDetectorRef
  ) {
    this.setContext();
  }

  ngOnInit(): void {
    this.activatedRoute.params
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((queryParams) => {
        this.drillDownQueryParams = queryParams as DashboardQueryParams;
        this.setContext();
        this.getData();
      });
  }

  onFiltersChanged(value: IDataFilter): void {
    this.filterValue = value;
    this.getData();
  }

  trackCategories(index: number, item: CategoryData): string {
    return item.details.uuid;
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private setContext(): void {
    this.account = this.activatedRoute.snapshot.data.resolveData;
    this.foodbackOverviewService.setAccount(this.account);
    this.contextUuid =
      this.activatedRoute.snapshot.params.accountId ||
      this.activatedRoute.snapshot.params.venueId;
    this.isAccountContext = !!this.activatedRoute.snapshot.params.accountId;
    this.viewType = this.isAccountContext
      ? SharedComponentViewType.ACCOUNT
      : SharedComponentViewType.VENUE;
    this.foodbackOverviewService.setDataProvider(
      this.isAccountContext,
      this.contextUuid
    );
  }

  private getOverallStatistics(): IDataCardWidgetStatistics {
    return {
      overallExperienceAverage: get(
        this.overallExperienceData,
        'statistics.overallExperienceAverage',
        0
      ),
      entries: [
        this.getCardWidgetEntry(
          'SHARED.SYSTEM.FOODBACKS',
          'statistics.foodbacksCount',
          this.foodbackOverviewService.getAllFoodbacksUrlWithParams(
            this.contextUuid,
            this.drillDownQueryParams
          )
        ),
        this.getCardWidgetEntry(
          'SHARED.SYSTEM.COMMENTS',
          'statistics.commentsCount',
          this.foodbackOverviewService.getAllFoodbacksUrlWithComment(
            this.contextUuid,
            this.drillDownQueryParams
          )
        ),
        this.getCardWidgetEntry(
          'SHARED.SYSTEM.EMAILS',
          'statistics.emailsCount',
          this.foodbackOverviewService.getAllFoodbacksUrlWithEmail(
            this.contextUuid,
            this.drillDownQueryParams
          )
        ),
      ],
    };
  }

  private getCardWidgetEntry(
    title: string,
    path: string,
    url: string
  ): IDataCardWidgetEntryStatistics {
    return {
      title,
      data: get(this.overallExperienceData, path, 0),
      mainUrl: {
        url,
      },
    };
  }

  private getData(): void {
    if (this.filterValue) {
      this.setBasicParameters();
      this.setData();
    }
  }

  private setBasicParameters(): void {
    const daysInWeek = 7;

    this.dateRangeForChart = {
      start: new Date(
        this.filterValue.dateFrom + CONSTANTS.DATE_TIME_VALUES.MIDNIGHT
      ),
      end: new Date(
        this.filterValue.dateTo + CONSTANTS.DATE_TIME_VALUES.MIDNIGHT
      ),
    };
    this.isSelectedDateRangeAtLeastOneWeek =
      this.dashboardDatesService.getRangeLengthInDaysFormDates(
        this.filterValue.dateFrom,
        this.filterValue.dateTo
      ) >= daysInWeek;
    this.foodbackOverviewService.setSelectedVenueUuids(this.filterValue.venues);
  }

  private setData(): void {
    this.setCategoriesData();
    this.setAnalyticsData();
  }

  private setAnalyticsData(): void {
    const dateRangeForRequest: DynamicDateRange = {
      start: new Date(
        this.filterValue.dateFrom + CONSTANTS.DATE_TIME_VALUES.MIDNIGHT
      ),
      end: new Date(
        this.filterValue.dateTo + CONSTANTS.DATE_TIME_VALUES.MIDNIGHT
      ),
    };
    const accountPermissions: AccountPackagePermissions =
      this.account.hasAccessTo();

    this.setLoadingState(true, 'venueScores');
    this.foodbackOverviewService
      .getOverallExperienceScores$(dateRangeForRequest)
      .pipe(
        finalize(() => this.setLoadingState(false, 'venueScores')),
        takeUntil(this.onDestroy$)
      )
      .subscribe((response: IVenueScores) => {
        this.foodbackScores = response;
      });

    if (accountPermissions.dailyPerformanceOverview) {
      this.setLoadingState(true, 'dayByDay');
      this.foodbackOverviewService
        .getDayByDayOverallExperienceScores$(dateRangeForRequest)
        .pipe(
          finalize(() => this.setLoadingState(false, 'dayByDay')),
          takeUntil(this.onDestroy$)
        )
        .subscribe(
          (response: DayByDayChartInput) => (this.dailyPerformance = response)
        );
    }
    if (accountPermissions.hourlyPerformanceOverview) {
      this.setLoadingState(true, 'hourByHour');
      this.foodbackOverviewService
        .getHourByHourOverallExperienceScores$(dateRangeForRequest)
        .pipe(
          finalize(() => this.setLoadingState(false, 'hourByHour')),
          takeUntil(this.onDestroy$)
        )
        .subscribe(
          (response: HourByHourChartInput) =>
            (this.hourlyPerformance = response)
        );
    }

    this.foodbackOverviewService
      .getOverallExperienceStatistics$(dateRangeForRequest)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((response: VenueStatistics) => {
        this.overallExperienceData.statistics = response;
        this.statistics = this.getOverallStatistics();
      });
    this.setLoadingState(true, 'accountScores');
    this.foodbackOverviewService
      .getAccountScores$(dateRangeForRequest, this.account.uuid)
      .pipe(
        finalize(() => this.setLoadingState(false, 'accountScores')),
        takeUntil(this.onDestroy$)
      )
      .subscribe((response: IVenueScores) => (this.accountScores = response));
  }

  private setLoadingState(isLoading: boolean, loadingType: string): void {
    this.loading[loadingType] = isLoading;
    this.cdf.detectChanges();
  }

  private setCategoriesData(): void {
    this.loadCategoriesData();
  }

  private loadCategoriesData(): void {
    const dateRangeForRequest: DynamicDateRange = {
      start: new Date(
        this.filterValue.dateFrom + CONSTANTS.DATE_TIME_VALUES.MIDNIGHT
      ),
      end: new Date(
        this.filterValue.dateTo + CONSTANTS.DATE_TIME_VALUES.MIDNIGHT
      ),
    };
    const categoriesRequests: Observable<any>[] = [
      this.foodbackOverviewService.getCategoryAverages$(dateRangeForRequest),
      this.foodbackOverviewService.getTopLowPerformingQuestions$(
        dateRangeForRequest
      ),
    ];

    this.loading.categories = true;
    this.cdf.detectChanges();

    if (this.account.hasAccessTo().goals) {
      categoriesRequests.push(
        this.foodbackOverviewService.getGoals$(
          this.contextUuid,
          this.isAccountContext
        )
      );
    }

    forkJoin(categoriesRequests)
      .pipe(
        finalize(() => {
          this.loading.categories = false;
          this.cdf.detectChanges();
        }),
        takeUntil(this.onDestroy$)
      )
      .subscribe(
        ([
          categoryAveragesResponse,
          topLowPerformingQuestionsResponse,
          goalsResponse,
        ]: [
          VenueCategoryAverageEntry[],
          TopLowPerformingQuestionsBackendResponse[],
          GoalsResponseMapped
        ]) => {
          this.categoriesData.clear();
          this.categories = [];
          categoryAveragesResponse.forEach(
            (categoryAverage: VenueCategoryAverageEntry) => {
              const categoryDetails: CategoryWidgetDetails = {
                name: categoryAverage.name,
                flexibleName: categoryAverage.flexibleName,
                color: '717171',
                uuid: categoryAverage.uuid,
                value: toNumber(categoryAverage.value),
              };

              this.setCategoryData(
                categoryAverage.uuid,
                'details',
                categoryDetails
              );
            }
          );

          if (goalsResponse && goalsResponse.categories) {
            goalsResponse.categories.forEach(
              (goalResponse: GoalsResponseEntry) => {
                this.setCategoryData(
                  goalResponse.category.uuid,
                  'goal',
                  goalResponse.targetAverage
                );
              }
            );
            // overallExperience is undefined if the account has no categories yet
            this.overallExperienceData.goal = get(
              goalsResponse.overallExperience,
              'targetAverage'
            );
          }

          topLowPerformingQuestionsResponse.forEach(
            (
              topLowPerformingQuestionResponse: TopLowPerformingQuestionsBackendResponse
            ) => {
              if (
                !topLowPerformingQuestionResponse.category.overallExperience
              ) {
                this.setCategoryData(
                  topLowPerformingQuestionResponse.category.uuid,
                  'topLowQuestions',
                  {
                    top: topLowPerformingQuestionResponse.top,
                    low: topLowPerformingQuestionResponse.low,
                  }
                );
              }
            }
          );
        }
      );
  }

  private setCategoryData<T extends keyof CategoryData>(
    categoryUuid: string,
    dataKey: T,
    data: CategoryData[T]
  ): void {
    if (!this.categoriesData.has(categoryUuid)) {
      this.categoriesData.set(categoryUuid, {});
    }
    const newCategoryData: CategoryData = {
      ...this.categoriesData.get(categoryUuid),
      [dataKey]: data,
    };

    this.categoriesData.set(categoryUuid, newCategoryData);
    this.categories = Array.from(this.categoriesData.values());
  }
}
