<div>
  <div
    id="header"
    class="content-heading"
    [ngClass]="{ 'FilterPanel-noFiltersHeader': !showFilters }"
  >
    <div fxLayout="row" fxLayoutAlign="space-between">
      <div fxFlex>
        <ng-container *ngIf="showFilters; else onlyHeader">
          <app-date-range-picker
            [minDate]="minDate"
            [selectedDateRange]="selectedDateRange"
            [isLoading]="loading"
            (selectedDateRangeChange)="updateSelectedDateRange($event)"
          >
            <ng-container
              datepicker-header
              [ngTemplateOutlet]="onlyHeader"
            ></ng-container>
            <ng-container datepicker-additionalHeader>
              <ng-content select="[buttonsContainer]"></ng-content>
            </ng-container>
            <ng-container datepicker-additionalContent>
              <button class="btn btn-foodback text-uppercase mr-lg"
                *ngIf="showPrintPage"
                [disabled]="showPrintPageLoading"
                (click)="printDialog()">
                  {{ 'SHARED.SYSTEM.PRINT_PAGE' | translate }}
              </button>
              <button *ngIf="isExportButtonVisible" class="btn btn-foodback text-uppercase pull-right" (click)="emitExport()">
                {{ 'SHARED.SYSTEM.EXPORT_EXCEL' | translate }}
              </button>
            </ng-container>
          </app-date-range-picker>
        </ng-container>
      </div>

    </div>
    <ng-template #onlyHeader>
      <div class="FilterPanel-headerContainer" fxLayout="row" fxLayoutAlign="space-between center">
        <div>
          <span
            class="heading-text mb0"
            appTooltip
            [contentTemplate]="headerTranslationKey?.length > CONSTANTS.FILTER.MAX_HEADER_CHARACTERS
                              ? hintHeaderTooltip : null" [templateData]="{ text: headerTranslationKey }">
              {{headerTranslationKey | ellipsis: [CONSTANTS.FILTER.MAX_HEADER_CHARACTERS]}}
               <ng-template #hintHeaderTooltip let-text="text"><span>{{ text }}</span></ng-template>
          </span>
          <ng-content select="[header]"></ng-content>
        </div>
        <div *ngIf="!showFilters">
          <ng-content select="[buttonsContainer]"></ng-content>
        </div>
      </div>
    </ng-template>
  </div>
  <layout-content>
    <div fxLayout="column" aboveContainer *ngIf="showFilters">
      <ng-content select="[additionalFilter]"></ng-content>
      <div class="FilterPanel" *ngIf="isAccountMode">
        <div class="FilterPanel-filters">
          <div class="FilterPanel-filtersContainer">
            <app-groups-and-venues-select
              [ngClass]="{
                'mr-lg': !isCommentFilterEnabled && !isDemographicFilterEnabled
              }"
              [groupsAccess]="true"
              [groups]="groups"
              [venues]="venues"
              [isLoading]="loading"
              [selectedVenuesUuids]="selectedVenuesUuids"
              [selectedGroupsUuids]="selectedGroupsUuids"
              (onVenuesSelect)="onVenuesSelect($event)"
              (onVenueItemSetChange)="onVenueItemSetChange($event)"
              (onGroupsSelect)="onGroupsSelect($event)"
            >
            </app-groups-and-venues-select>
            <div class="FilterPanel-border" *ngIf="isCommentFilterEnabled">
              &nbsp;
            </div>
            <app-data-filter-comments
              *ngIf="isCommentFilterEnabled"
              [ngClass]="{ 'mr-lg': !isDemographicFilterEnabled }"
              (onCommentFilterChanged)="onCommentFilterChanged($event)"
            ></app-data-filter-comments>
            <div class="FilterPanel-border" *ngIf="isDemographicFilterEnabled">
              &nbsp;
            </div>
            <app-data-filter-demographics
              *ngIf="isDemographicFilterEnabled"
              class="mr-lg"
              [availableLanguages]="allSupportedLanguages$ | async"
              (onDemographicFilterChanged)="onDemographicFilterChanged($event)"
            ></app-data-filter-demographics>
            <button
              class="btn btn-foodback text-uppercase mt"
              [disabled]="isFilterBtnDisabled || loading"
              (click)="onFilterBtnClick()"
            >
              {{ 'SHARED.SYSTEM.APPLY' | translate }}
            </button>
            <div class="FilterPanel-border" *ngIf="hasAdditionalOptions">
              &nbsp;
            </div>
            <ng-content select="[additionalOptions]"></ng-content>
          </div>
        </div>
      </div>
      <app-breadcrumbs
        *ngIf="breadcrumbs?.length > 0"
        [breadcrumbs]="breadcrumbs"
      ></app-breadcrumbs>
    </div>
    <div>
      <ng-content></ng-content>
    </div>
  </layout-content>
</div>
