import { ACCOUNT_ROUTES_PATHS_FULL } from '../../components/account/account-routes-paths';
import {
  ACCOUNT_CAMPAIGNS_ROUTES_PATHS_FULL
} from '../../components/account/settings/acc-campaigns/acc-campaigns-routes-paths';
import { ACCOUNT_SETTINGS_ROUTES_PATHS_FULL } from '../../components/account/settings/acc-settings-routes-paths';
import { VENUE_SETTINGS_ROUTES_PATHS_FULL } from '../../components/venue/settings/ven-settings-routes-paths';
import { VENUE_ROUTES_PATHS_FULL } from '../../components/venue/venue-routes-paths';
import { CONSTANTS } from '../../shared/constants';
import { SidebarAnalyticsMenus } from '../../shared/enums/sidebar/sidebar-analytics-menus.enum';
import { SidebarSettingsMenus } from '../../shared/enums/sidebar/sidebar-settings-menus.enum';
import { SidebarMenu } from '../../shared/interfaces/sidebar/sidebar-menu.interface';

export class SidebarConstants {
  static SETTINGS = class {
    static OPTIONAL_SUBMENUS = [
      {
        order: 2,
        title: 'SHARED.SYSTEM.VENUES',
        type: SidebarSettingsMenus.VENUE_MANAGEMENT,
        submenu: [],
      },
      {
        order: 5,
        title: 'SHARED.SYSTEM.GOALS',
        type: SidebarSettingsMenus.GOALS,
        submenu: [],
      },
      {
        order: 3,
        title: 'SHARED.SYSTEM.GROUPS',
        type: SidebarSettingsMenus.GROUPS,
        submenu: [],
      },
    ];
    static SIDEBAR = {
      title: 'SHARED.SYSTEM.SETTINGS',
      icon: 'mdi mdi-18px mdi-settings',
      isExpanded: false,
      isHovered: false,
      submenu: [
        {
          order: 1,
          title: 'SHARED.SYSTEM.DETAILS',
          type: SidebarSettingsMenus.DETAILS,
          submenu: [],
        },
        {
          order: 4,
          title: 'SIDEBAR.MENU.USER_MANAGEMENT',
          type: SidebarSettingsMenus.USER_MANAGEMENT,
          submenu: [],
        },
        {
          order: 6,
          title: 'SHARED.SYSTEM.CONFIGURATION',
          type: SidebarSettingsMenus.CONFIGURATION,
          submenu: [],
        },
        {
          order: 7,
          title: 'SHARED.SYSTEM.FOODBACK_QUESTIONS',
          type: SidebarSettingsMenus.QUESTIONS,
          submenu: [],
        },
        {
          order: 8,
          title: 'SHARED.SYSTEM.ONE_MORE_QUESTION',
          type: SidebarSettingsMenus.ONE_MORE_QUESTION,
          submenu: [],
        },
      ],
    };

    static CAMPAIGNS_SETTNGS = [
      {
        order: 9,
        title: 'SHARED.SYSTEM.CAMPAIGNS',
        type: SidebarSettingsMenus.CAMPAIGNS,
        submenu: [],
      },
    ];

    static TAKEAWAY_SETTINGS = [
      {
        order: 10,
        title: 'SHARED.SYSTEM.QR_LANDING',
        type: SidebarSettingsMenus.FOODBACK_PORTAL,
        submenu: [],
      },

      {
        order: 11,
        title: 'SHARED.SYSTEM.REWARDS',
        type: SidebarSettingsMenus.WHEEL_OF_FORTUNE,
        submenu: [],
      },
    ];
    static ACCOUNT_URLS = {
      CONFIGURATION: ACCOUNT_SETTINGS_ROUTES_PATHS_FULL.CONFIGURATION.BASE_PATH,
      DETAILS: ACCOUNT_SETTINGS_ROUTES_PATHS_FULL.DETAILS,
      QUESTIONS: ACCOUNT_SETTINGS_ROUTES_PATHS_FULL.FOODBACK_QUESTIONS,
      GOALS: ACCOUNT_SETTINGS_ROUTES_PATHS_FULL.GOALS,
      GROUPS: ACCOUNT_SETTINGS_ROUTES_PATHS_FULL.GROUPS,
      USER_MANAGEMENT: ACCOUNT_SETTINGS_ROUTES_PATHS_FULL.USERS.BASE_PATH,
      VENUE_MANAGEMENT: ACCOUNT_SETTINGS_ROUTES_PATHS_FULL.VENUES.LIST,
      WHEEL_OF_FORTUNE: ACCOUNT_SETTINGS_ROUTES_PATHS_FULL.WHEEL_OF_FORTUNE,
      ONE_MORE_QUESTION:
      ACCOUNT_SETTINGS_ROUTES_PATHS_FULL.ONE_MORE_QUESTION.BASE_PATH,
      CAMPAIGNS: ACCOUNT_SETTINGS_ROUTES_PATHS_FULL.CAMPAIGNS.BASE_PATH,
    };

    static VENUE_URLS = {
      CONFIGURATION: VENUE_SETTINGS_ROUTES_PATHS_FULL.CONFIGURATION.BASE_PATH,
      DETAILS: VENUE_SETTINGS_ROUTES_PATHS_FULL.DETAILS,
      QUESTIONS: VENUE_SETTINGS_ROUTES_PATHS_FULL.FOODBACK_QUESTIONS,
      GOALS: VENUE_SETTINGS_ROUTES_PATHS_FULL.GOALS,
      USER_MANAGEMENT: VENUE_SETTINGS_ROUTES_PATHS_FULL.USERS.BASE_PATH,
      WHEEL_OF_FORTUNE: VENUE_SETTINGS_ROUTES_PATHS_FULL.WHEEL_OF_FORTUNE,
      FOODBACK_PORTAL: VENUE_SETTINGS_ROUTES_PATHS_FULL.FOODBACK_PORTAL,
      ONE_MORE_QUESTION:
      VENUE_SETTINGS_ROUTES_PATHS_FULL.ONE_MORE_QUESTION.BASE_PATH,
      CAMPAIGNS: VENUE_SETTINGS_ROUTES_PATHS_FULL.CAMPAIGNS.BASE_PATH,
    };
  };
  static ANALYTICS_ACCOUNT = class {
    static SIDEBAR: SidebarMenu[] = [
      {
        title: 'SHARED.SYSTEM.DASHBOARD',
        icon: 'mdi mdi-18px mdi-finance',
        isExpanded: false,
        isHovered: false,
        submenu: [],
        order: 1,
        link: ACCOUNT_ROUTES_PATHS_FULL.OVERVIEW.ACCOUNT,
        type: SidebarAnalyticsMenus.DASHBOARD,
      },
      {
        title: 'SHARED.SYSTEM.VENUE_SCOREBOARD',
        icon: 'mdi mdi-18px mdi-emoticon',
        isExpanded: false,
        submenu: [],
        order: 2,
        link: ACCOUNT_ROUTES_PATHS_FULL.SCOREBOARD,
        type: SidebarAnalyticsMenus.VENUE_SCOREBOARD,
      },
      {
        title: 'SHARED.SYSTEM.ALL_FOODBACKS',
        icon: 'fb-font fb-font-18px fb-font-smiley',
        isExpanded: false,
        submenu: [],
        order: 3,
        link: ACCOUNT_ROUTES_PATHS_FULL.ALL_FOODBACKS,
        type: SidebarAnalyticsMenus.FOODBACKS_ALL_FOODBACKS,
      },
      {
        title: 'SHARED.SYSTEM.ONE_MORE_QUESTION',
        icon: 'mdi mdi-18px mdi-comment-question-outline',
        isExpanded: false,
        submenu: [],
        order: 3,
        link: ACCOUNT_ROUTES_PATHS_FULL.ONE_MORE_QUESTION.OVERVIEW,
        type: SidebarAnalyticsMenus.ONE_MORE_QUESTION,
      },
      {
        title: 'SHARED.SYSTEM.CAMPAIGNS',
        icon: 'mdi mdi-18px mdi-gender-male-female',
        isExpanded: false,
        submenu: [],
        order: 3,
        link: ACCOUNT_ROUTES_PATHS_FULL.CAMPAIGNS.OVERVIEW,
        type: SidebarAnalyticsMenus.CAMPAIGNS,
      }
    ];
  };
  static ANALYTICS_VENUE = class {
    static OPTIONAL_SIDEBARS = [
      {
        title: 'SHARED.SYSTEM.DASHBOARD',
        icon: 'mdi mdi-18px mdi-view-dashboard',
        isExpanded: false,
        submenu: [],
        order: 1,
        link: VENUE_ROUTES_PATHS_FULL.DASHBOARD,
        type: SidebarAnalyticsMenus.DASHBOARD,
      },
    ];

    static RESTAURANT_SIDEBARS = [
      {
        title: 'SHARED.SYSTEM.DEMOGRAPHICS',
        icon: 'mdi mdi-18px mdi-account-multiple-outline',
        isExpanded: false,
        submenu: [],
        order: 4,
        link: VENUE_ROUTES_PATHS_FULL.DEMOGRAPHICS,
        type: SidebarAnalyticsMenus.DEMOGRAPHICS,
      },
    ];

    static SIDEBAR: SidebarMenu[] = [
      {
        title: 'SHARED.SYSTEM.FOODBACKS',
        icon: 'fb-font fb-font-18px fb-font-smiley',
        isExpanded: false,
        order: 2,
        submenu: [
          {
            order: 1,
            title: 'SHARED.SYSTEM.OVERVIEW',
            type: SidebarAnalyticsMenus.FOODBACKS_OVERVIEW,
            link: VENUE_ROUTES_PATHS_FULL.OVERVIEW.VENUE,
            submenu: [],
          },
          {
            order: 2,
            title: 'SHARED.SYSTEM.ALL_FOODBACKS',
            type: SidebarAnalyticsMenus.FOODBACKS_ALL_FOODBACKS,
            link: VENUE_ROUTES_PATHS_FULL.ALL_FOODBACKS,
            submenu: [],
          },
        ],
      },
      {
        title: 'SHARED.SYSTEM.LIVE_NOTIFICATIONS',
        icon: 'mdi mdi-18px mdi-bell-ring',
        isExpanded: false,
        submenu: [],
        order: 5,
        link: VENUE_ROUTES_PATHS_FULL.NOTIFICATIONS.LIST,
        type: SidebarAnalyticsMenus.LIVE_NOTIFICATION,
      },
      {
        title: 'SHARED.SYSTEM.ONE_MORE_QUESTION',
        icon: 'mdi mdi-18px mdi-comment-question-outline',
        isExpanded: false,
        submenu: [],
        order: 7,
        link: VENUE_ROUTES_PATHS_FULL.ONE_MORE_QUESTION.OVERVIEW,
        type: SidebarAnalyticsMenus.ONE_MORE_QUESTION,
      },
      {
        title: 'SHARED.SYSTEM.CAMPAIGNS',
        icon: 'mdi mdi-18px mdi-gender-male-female',
        isExpanded: false,
        submenu: [],
        order: 8,
        link: VENUE_ROUTES_PATHS_FULL.CAMPAIGNS.OVERVIEW,
        type: SidebarAnalyticsMenus.CAMPAIGNS,
      }
    ];
    static REWARDS: SidebarMenu = {
      title: 'SHARED.SYSTEM.REWARDS',
      icon: 'mdi mdi-18px mdi-ticket-percent',
      isExpanded: false,
      submenu: [],
      order: 6,
      link: VENUE_ROUTES_PATHS_FULL.WINNER_STATISTIC,
      type: SidebarAnalyticsMenus.REWARDS,
    };
  };

  static ADMIN = class {
    static SIDEBAR: SidebarMenu[] = [
      {
        title: 'SHARED.SYSTEM.USER_MANAGEMENT',
        link: `/${CONSTANTS.ROUTES.ADMIN}/${CONSTANTS.ROUTES.USERS}`,
        icon: 'mdi mdi-18px mdi-account-multiple-outline',
        submenu: [],
      },
      {
        title: 'ADMIN.ACCOUNTS.LIST.HEADER',
        link: `/${CONSTANTS.ROUTES.ADMIN}/${CONSTANTS.ROUTES.ACCOUNT_MANAGEMENT}`,
        icon: 'fb-font fb-font-account-white fb-font-18px',
        submenu: [],
      },
      // @link https://foodback.atlassian.net/wiki/spaces/FBI/pages/870121513/Multiple+Venue+Types+take-away+canteen+breakfast+etc.
      // Daniel Chaibi: This page can be retired now. Zoho Reports will take over from here.
      // The code will stay here as a backup
      // {
      //   title: 'ADMIN.SURVEYS.HEADER',
      //   link: `/${CONSTANTS.ROUTES.ADMIN}/${CONSTANTS.ROUTES.SURVEY}`,
      //   icon: 'mdi mdi-18px mdi-format-list-bulleted',
      //   submenu: []
      // },
      {
        title: 'SHARED.SYSTEM.DYNAMIC_QR_URLS',
        link: `/${CONSTANTS.ROUTES.ADMIN}/${CONSTANTS.ROUTES.DYNAMIC_QR}`,
        icon: 'mdi mdi-18px mdi-qrcode-scan',
        submenu: [],
      },
    ];
  };
}
