<ng-container>
  <span
    *ngFor="let error of errorsList; let i = index"
    [appError]="error.name"
    [errorRef]="error"
    (onErrorHidden)="setHiddenFlag($event)"
    [when]="error?.rules"
    [whenNeighbor]="errorsList.length > i + 1 ? errorsList[i + 1]?.rules : []">
    <span *ngIf="(error.text || error.translateKey) && (i === 0 || (i > 0 && !isManyErrors))">{{
      error.text || error.translateKey | translate: error.translateParams  || {} | upperFirstLetter
    }}</span>
    <span *ngIf="(error.text || error.translateKey) && i > 0 && isManyErrors">{{
      error.text || error.translateKey | translate: error.translateParams  || {} | lowercase
    }}</span>
    <span *ngIf="!error.isLastVisibleError">, </span>
  </span>
</ng-container>
