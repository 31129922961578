import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { QuestionDetails, QuestionFoodbacksFromBackend } from '../../../interfaces/analytics.interface';
import { GetNativeLanguageTranslationOrFirstPipe } from '../../../pipes/get-native-language-translation-or-first.pipe';
import { FoodbacksWithThisQuestionEntry } from './foodbacks-with-this-question.interface';
import * as moment from 'moment';
import { CONSTANTS } from 'app/shared/constants';

@Injectable()
export class FoodbacksWithThisQuestionService {
  private getNativeLanguageTranslationOrFirst: GetNativeLanguageTranslationOrFirstPipe;

  constructor(private translateService: TranslateService) {
    this.getNativeLanguageTranslationOrFirst = new GetNativeLanguageTranslationOrFirstPipe();
  }

  getQuestionTranslation(question: QuestionDetails, defaultLanguage: string): Observable<string> {
    if (!question.translations || !question.translations.length) {
      return this.translateService.get('SHARED.SYSTEM.MISSING_TRANSLATION');
    }

    return of(this.getNativeLanguageTranslationOrFirst.transform(question.translations, defaultLanguage));
  }

  mapQuestionFoodbacksResponse(questionFoodbacksResponse: QuestionFoodbacksFromBackend): FoodbacksWithThisQuestionEntry[] {
    return questionFoodbacksResponse.items.map(item => {
      const baseInfo: FoodbacksWithThisQuestionEntry = {
        date: moment(item.lastModified).format(CONSTANTS.DATES_FORMAT.DATE_TIME),
        value: item.statement.value,
        comment: item.comment,
        email: item.email,
        otherAnswers: item.answers,
        voucher: item.voucher,
      };

      if (item.venue) {
        baseInfo.venueInfo = item.venue;
      }

      return baseInfo;
    });
  }
}
