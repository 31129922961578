<app-dialog>
  <app-dialog-title>{{ 'ONE_MORE_QUESTION.TRANSLATE_QUESTION' | translate }}</app-dialog-title>
  <app-dialog-content>
    <label class="c-checkbox checkbox mb-lg mt0 clickable checkbox-inline">
      <input type="checkbox" [(ngModel)]="showInactiveLanguages" (ngModelChange)="showInactiveLanguagesChange()" />
      <span class="fa fa-check fb-checkbox"></span>
      {{ 'SHARED.FOODBACK_STATEMENTS.MAIN.TOP_BAR.SHOW_INACTIVE_LANGUAGES' | translate }}
    </label>
    <h4 class="CampaignQuestionLangDialog__mainHeader page-header mt0">{{ 'SHARED.SYSTEM.QUESTION' | translate }}</h4>
    <form [formGroup]="langForm" fxLayout="row">
      <div [formGroup]="langForm.controls.activeLangForm">
        <ng-container
          *ngTemplateOutlet="
            singleTranslationForm;
            context: {
              translationForm: langForm.controls.activeLangForm['controls'][0],
              langLabel: 'SHARED.SYSTEM.DEFAULT_LANG',
              required: true,
              isFirstElement: true
            }
          ">
        </ng-container>
      </div>
      <div class="CampaignQuestionLangDialog__formsContainer" fxLayout="row" fxLayoutAlign="space-between center">
        <ng-container [formGroup]="langForm.controls.activeLangForm">
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <ng-container
              *ngFor="let translationForm of langForm.controls.activeLangForm['controls']; let langIndex = index; trackBy: trackItems">
              <ng-container *ngIf="langIndex > 0">
                <ng-container
                  *ngTemplateOutlet="
                    singleTranslationForm;
                    context: {
                      translationForm: translationForm,
                      langLabel: 'SHARED.SYSTEM.OTHER_ACTIVE_LANG',
                      required: false,
                      isFirstElement: false
                    }
                  ">
                </ng-container>
              </ng-container>
            </ng-container>
          </div>
        </ng-container>
        <ng-container *ngIf="showInactiveLanguages">
          <ng-container [formGroup]="langForm.controls.inactiveLangForm">
            <div fxLayout="row" fxLayoutAlign="space-between center">
              <ng-container
                *ngFor="let translationForm of langForm.controls.inactiveLangForm['controls']; let langIndex = index; trackBy: trackItems">
                <ng-container
                  *ngTemplateOutlet="
                    singleTranslationForm;
                    context: {
                      translationForm: translationForm,
                      langLabel: 'ACCOUNT.FOODBACK.MODAL.INACTIVE_LANGUAGE'
                    }
                  ">
                </ng-container>
              </ng-container>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </form>
  </app-dialog-content>
  <app-dialog-actions>
    <button type="button" class="btn btn-foodback btn-foodback--secondary text-uppercase mr-sm" matDialogClose>
      {{ 'ACCOUNT.GROUPS.BUTTONS.CANCEL' | translate }}
    </button>
    <button type="button" class="btn btn-foodback text-uppercase" data-dismiss="modal" (click)="onSubmit()">
      {{ 'SHARED.SYSTEM.SAVE' | translate }}
    </button>
  </app-dialog-actions>
</app-dialog>
<ng-template
  #singleTranslationForm
  let-translationForm="translationForm"
  let-langLabel="langLabel"
  let-required="required"
  let-isFirstElement="isFirstElement">
  <div
    class="CampaignQuestionLangDialog__dynamicLangForm"
    [ngClass]="{
      CampaignQuestionLangDialog__defaultForm: isFirstElement,
      CampaignQuestionLangDialog__dynamicForm: !isFirstElement
    }">
    <ng-container [formGroup]="translationForm">
      <span class="CampaignQuestionLangDialog__langInfo">{{ langLabel | translate | uppercase }}</span>
      <div class="CampaignQuestionLangDialog__langName">
        <span class="flag-icon flag-icon-round" [ngClass]="'flag-icon-' + translationForm.language.code"></span>
        <span>{{ translationForm.language.name }}</span>
      </div>
      <app-input-with-counter
        formControlName="question"
        [label]="'SHARED.SYSTEM.QUESTION' | translate"
        [required]="required"
        [placeholder]="'SHARED.SYSTEM.QUESTION' | translate"
        [maxLength]="CONSTANTS.VALIDATION.CAMPAIGNS.QUESTION_MAX_LENGTH"
        [hostTouched]="translationForm.get('question').touched">
        <mat-error appErrors="question" [errors]="errors"></mat-error>
      </app-input-with-counter>
      <ng-container formArrayName="alternatives">
        <ng-container *ngFor="let option of translationForm.get('alternatives')['controls']; let group = index; trackBy: trackItems">
          <app-input-with-counter
            [formControlName]="group"
            [label]="'SHARED.SYSTEM.OPTION_NUMB' | translate: { '0': group + 1 }"
            [placeholder]="'SHARED.SYSTEM.OPTION_NUMB' | translate: { '0': group + 1 }"
            [required]="required"
            [maxLength]="CONSTANTS.VALIDATION.CAMPAIGNS.FORMAT_OPTIONS_LENGTH"
            [hostTouched]="translationForm.get('question').touched">
            <mat-error [appErrors]="'alternatives.' + group" [errors]="errors"></mat-error>
          </app-input-with-counter>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</ng-template>
