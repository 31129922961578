import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { LocalStorageService } from 'ngx-webstorage';
import { BehaviorSubject, Observable } from 'rxjs';
import { CONSTANTS } from '../../../shared/constants';
import { AuthorizedData, AuthorizedUser } from '../../../shared/interfaces/authorized-user.interface';
import { LoginData, TokenData, UserData } from '../../../shared/interfaces/login-object';
import { UsersBackendService } from '../backend/users-backend.service';

@Injectable()
export class UserService {
  isLoggedIn$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isNewFoodbackUser$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  authorizedUserData: AuthorizedUser = null;
  jwtHelper: JwtHelperService = new JwtHelperService();
  userData$: BehaviorSubject<UserData> = new BehaviorSubject(null);
  loginData$: BehaviorSubject<LoginData> = new BehaviorSubject<LoginData>(null);

  constructor(private localStorage: LocalStorageService) {
    this.setAuthorizedData();
  }

  setLoggedIn(value: boolean) {
    this.isLoggedIn$.next(value);
  }

  setAuthorizedData() {
    const authorizedUserData = this.localStorage.retrieve(CONSTANTS.AUTH.ID_TOKEN);

    if (authorizedUserData) {
      this.authorizedUserData = { idToken: authorizedUserData };
    }
  }

  setIsNewFoodbackUser(val: boolean) {
    this.isNewFoodbackUser$.next(val);
  }

  getUserDataForRequest(): AuthorizedData {
    const { idToken } = this.authorizedUserData;

    return { idToken };
  }

  getUserUuid(): string {
    const decodedObj = this.getDecodedTokenData();

    return decodedObj ? decodedObj.userUuid : null;
  }

  isLoggedUser(userUuid: string): boolean {
    const decodedObj = this.getDecodedTokenData();

    return userUuid === decodedObj.userUuid || false;
  }

  getUserEmail(): string {
    const decodedObj = this.getDecodedTokenData();

    return decodedObj.email;
  }

  setUserData(userData: UserData): void {
    this.localStorage.store(CONSTANTS.SESSION.USER_DATA, userData);
    this.userData$.next(userData);
  }

  getUserData$(): Observable<UserData> {
    if (this.userData$.getValue() === null) {
      this.userData$.next(this.localStorage.retrieve(CONSTANTS.SESSION.USER_DATA));
    }

    return this.userData$;
  }

  markUserAsNotNewFoodbackUser(): void {
    this.isNewFoodbackUser$.next(false);
  }

  setLoginData(loginData: LoginData): void {
    this.localStorage.store(CONSTANTS.SESSION.LOGIN_DATA, loginData);
    this.loginData$.next(loginData);
  }

  getLoginData$(): Observable<LoginData> {
    if (this.loginData$.getValue() === null) {
      this.loginData$.next(this.localStorage.retrieve(CONSTANTS.SESSION.LOGIN_DATA));
    }

    return this.loginData$.asObservable();
  }

  private getDecodedTokenData(): TokenData {
    const authorizedUserData = this.localStorage.retrieve(CONSTANTS.AUTH.ID_TOKEN);

    return this.jwtHelper.decodeToken(authorizedUserData);
  }
}
