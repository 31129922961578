import { Injectable } from '@angular/core';
import { chain } from 'lodash';
import { UserForBackend } from '../../../shared/form-models-interfaces';
import { UserStatistics, UserWithStatistics } from '../../../shared/interfaces/analytics.interface';

@Injectable()
export class VenueUsersService {
  mergeUsersDetailsAndStatistics(usersDetails: UserForBackend[], usersStatistics: UserStatistics[]): UserWithStatistics[] {
    return chain([...usersDetails, ...usersStatistics])
      .groupBy((x: UserForBackend | UserStatistics) => (x as UserForBackend).uuid || (x as UserStatistics).userUuid)
      .map(values => Object.assign({}, ...values))
      .value();
  }
}
