import { Directive, Input, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { CONSTANTS } from '../../constants';
import { IDataCardWidgetStatistics } from '../../interfaces/widgets/data-card-widget-statistics.interface';
import { PercentCircleVariant } from '../percent-circle/percent-circle.component.interface';

@Directive()
export class IDataCardWidgetScaffold implements OnDestroy {
  @Input() title: string;
  @Input() goal: number;
  @Input() set statistics(value: IDataCardWidgetStatistics) {
    if (value) {
      this._setStatistics(value);
    }
  }
  dataStatisticsSet: IDataCardWidgetStatistics;
  percentValue = 0;
  readonly PercentCircleVariant: typeof PercentCircleVariant =
    PercentCircleVariant;
  readonly CONSTANTS: typeof CONSTANTS = CONSTANTS;
  protected _onDestroy$: Subject<void> = new Subject<void>();

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  protected _setStatistics(value: IDataCardWidgetStatistics): void {
    this.dataStatisticsSet = value;
    this.percentValue = this.getCategoryPercentValue(
      value.overallExperienceAverage
    );
  }

  private getCategoryPercentValue(value: number): number {
    if (!value) {
      return 0;
    }

    return (Number(value) / CONSTANTS.FOODBACK.RANK_SCALE) * 100;
  }
}
