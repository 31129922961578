import { Injectable } from '@angular/core';
import { get } from 'lodash';
import {
  DashboardQueryParams, IDemographicFiltersParams,
  VenuesQueryParams,
} from '../../core/interfaces/dashboard-query-params.interface';
import { UtilsService } from '../../core/utils/utils.service';
import { CONSTANTS } from '../../shared/constants';
import { VenueFromBackend } from '../../shared/form-models-interfaces';
import { IDropdownItem } from '../../shared/interfaces/dropdown/dropdown-item.interface';
import { IGroup } from '../../shared/interfaces/groups/group.interface';
import { AllFoodbacksQueryParams } from '../venue/foodbacks/all-foodbacks/foodback.interface';
import { IDemographicFilters } from './data-filter-demographics/data-filter-demographics.component';

@Injectable()
export class DataFilterService {
  private comment;
  private hasComment = false;
  private demographicFilters: IDemographicFilters = null;
  private demographicsQueryParams: IDemographicFiltersParams;

  constructor(private utilsService: UtilsService) {
  }

  setDemographicsQueryParams(queryParams: IDemographicFiltersParams): void {
    this.demographicsQueryParams = queryParams;
  }

  getDemographicQueryParams(): IDemographicFiltersParams {
    return this.demographicsQueryParams;
  }

  setHasComment(value: boolean): void {
    this.hasComment = value;
  }

  getHasComment(): boolean {
    return this.hasComment;
  }

  getComment(): string {
    return this.comment;
  }

  setComment(value: string): void {
    this.comment = value;
  }

  storeCommentFilter(): void {
    if (this.hasComment) {
      sessionStorage.setItem(
        CONSTANTS.LOCAL_STORAGE_KEYS.COMMENT,
        this.comment
      );
    } else {
      sessionStorage.removeItem(CONSTANTS.LOCAL_STORAGE_KEYS.COMMENT);
    }
  }

  setDemographicFilters(filtersValue: IDemographicFilters): void {
    this.demographicFilters = filtersValue;
  }

  getDemographicsFilters(): IDemographicFilters {
    return this.demographicFilters;
  }

  setCommentFromStorage(): void {
    const comment = sessionStorage.getItem(
      CONSTANTS.LOCAL_STORAGE_KEYS.COMMENT
    );

    if (comment) {
      this.setComment(comment);
      this.setHasComment(true);
    } else {
      this.setHasComment(false);
    }
  }

  setAndStoreDemographicValue(): void {
    if (this.demographicFilters) {
      sessionStorage.setItem(
        CONSTANTS.LOCAL_STORAGE_KEYS.DEMOGRAPHICS,
        JSON.stringify(this.demographicFilters)
      );
      sessionStorage.setItem(
        CONSTANTS.LOCAL_STORAGE_KEYS.DEMOGRAPHICS_HASH,
        this.utilsService.getHashCode(JSON.stringify(this.demographicFilters)).toString()
      );
    } else {
      sessionStorage.removeItem(CONSTANTS.LOCAL_STORAGE_KEYS.DEMOGRAPHICS_HASH);
      sessionStorage.removeItem(CONSTANTS.LOCAL_STORAGE_KEYS.DEMOGRAPHICS);
    }

  }

  hasDemographicValueChanged(): boolean {
    const demographicsHash: string = sessionStorage.getItem(
      CONSTANTS.LOCAL_STORAGE_KEYS.DEMOGRAPHICS_HASH
    );

    return (
      this.demographicFilters &&
      this.utilsService
        .getHashCode(JSON.stringify(this.demographicFilters))
        .toString() !== demographicsHash
    );
  }

  hasCommentChanged(): boolean {
    const commentFromStorage = sessionStorage.getItem(
      CONSTANTS.LOCAL_STORAGE_KEYS.COMMENT
    );

    return this.comment && this.comment !== commentFromStorage;
  }

  isGroupWithUuidExists(uuid: string, groups: IGroup[]): boolean {
    let isExists = false;

    for (let i = 0; i < groups.length; i++) {
      if (groups[i].uuid === uuid) {
        isExists = true;
        break;
      }
      if (groups[i].venueGroups) {
        isExists = this.isGroupWithUuidExists(uuid, groups[i].venueGroups);

        if (isExists) {
          break;
        }
      }
    }

    return isExists;
  }

  getVenuesQueryParams(
    selectedVenuesUuids: string[],
    venues: VenueFromBackend[],
    filteredVenues: IDropdownItem[]
  ): VenuesQueryParams {
    if (selectedVenuesUuids.length === venues.length) {
      return {isVenuesExcluded: false, venues: []};
    }
    const unSelectedVenuesUuids: string[] = filteredVenues
      .filter(
        (venue: IDropdownItem) =>
          !selectedVenuesUuids.find(
            (selectedVenueUuid: string) => selectedVenueUuid === venue.id
          )
      )
      .map((resultVenue: IDropdownItem) => resultVenue.id);

    if (
      unSelectedVenuesUuids.length > 0 &&
      selectedVenuesUuids.length > unSelectedVenuesUuids.length
    ) {
      return {isVenuesExcluded: true, venues: unSelectedVenuesUuids};
    }

    return {isVenuesExcluded: false, venues: selectedVenuesUuids};
  }

  getSelectedVenuesFromUrl(
    routeParams: DashboardQueryParams | AllFoodbacksQueryParams,
    venues: VenueFromBackend[]
  ): string[] {
    const isVenuesExcluded: boolean | string = get(
      routeParams,
      'isVenuesExcluded'
    );
    let selectedVenueUuids: string[] = get(routeParams, 'venues');

    if (
      (typeof isVenuesExcluded === 'string' && isVenuesExcluded === 'true') ||
      (typeof isVenuesExcluded === 'boolean' && isVenuesExcluded)
    ) {
      selectedVenueUuids = venues
        .filter(
          (venue: VenueFromBackend) =>
            !selectedVenueUuids.find(
              (selectedVenueUuid: string) => selectedVenueUuid === venue.uuid
            )
        )
        .map((venue: VenueFromBackend) => venue.uuid);
    }

    selectedVenueUuids =
      typeof selectedVenueUuids === 'string'
        ? [selectedVenueUuids]
        : selectedVenueUuids;

    return selectedVenueUuids && selectedVenueUuids.length > 0
      ? selectedVenueUuids
      : [];
  }
}
