import { Component, EventEmitter, Injector, Input, Output, ViewChild, ViewEncapsulation, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { KeyboardStatusService } from '../../../core/providers/keyboard-status/keyboard-status.service';

@Component({
  selector: 'app-textarea-with-counter',
  templateUrl: './textarea-with-counter.component.html',
  styleUrls: ['./textarea-with-counter.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextareaWithCounterComponent),
      multi: true,
    },
  ],
  encapsulation: ViewEncapsulation.None,
})
export class TextareaWithCounterComponent implements ControlValueAccessor {
  @Input() maxLength: number;
  @Input() minHeight: number;
  @Input() placeholder?: string = '';
  @Input() hint?: string = '';
  @Input() label?: string = '';
  @Input() className?: string = '';
  @Input() iconClass: string;
  @Input() iconName: string;
  @Input() required = false;
  @Input() set hostTouched(value: boolean) {
    this.updatedTouched();
  }
  // eslint-disable-next-line @angular-eslint/no-output-native
  @ViewChild('inputRef') inputRef;
  @Output() readonly keydown: EventEmitter<KeyboardEvent> = new EventEmitter<KeyboardEvent>();
  @Output() readonly blur: EventEmitter<void> = new EventEmitter();

  value: string;
  counter = 0;
  disabled = false;
  hostControl: NgControl;
  // Function.prototype is a safe reference that will always point to an empty function. This is only initial, to prevent errors
  private propagateChange: Function = Function.prototype;
  private propagateTouched: Function = Function.prototype;

  constructor(private keyboardStatusService: KeyboardStatusService, private inj: Injector) {}

  ngAfterViewInit(): void {
    this.hostControl = this.inj.get(NgControl);
    this.updatedTouched();
  }

  onChange(newValue: string) {
    this.value = newValue;
    this.updateCounter(newValue);
    this.propagateChange(newValue);
  }

  updateCounter(newValue: string) {
    this.counter = newValue.length;
  }

  writeValue(value: any): void {
    this.value = value ? String(value).slice(0, this.maxLength) : '';
    this.updateCounter(this.value);
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {}

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  handleKeydown(event: KeyboardEvent): void {
    this.keydown.next(event);
  }

  markAsTouched(): void {
    this.keyboardStatusService.setEscModalStatus(true);
    this.propagateTouched();
  }

  onBlur(): void {
    this.markAsTouched();
    this.blur.emit();
  }

  private updatedTouched(): void {
    if (this.hostControl && this.hostControl.touched && this.inputRef) {
      this.inputRef.control.markAllAsTouched();
    }
  }
}
