import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { AbstractControlOptions, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { CONSTANTS } from '../../../../constants';
import { GeneralQuestionsError } from '../../../../form-models';
import { I18nAvailableLanguage } from '../../../../interfaces/i18n.interface';
import { StatementTranslationFromBackend } from '../../../../interfaces/statements.interface';
import {
  IOneMoreFollowQuestion,
  IOneMoreQuestionGeneralBody,
  IOneMoreQuestionOptionBackend,
  IOneMoreQuestionTransResponseDialog,
  IOneMoreQuestionTranslationDialogData,
  IOneMoreQuestionTranslationFromBackend,
  IQuestionFormatOption,
} from '../../one-more-question/one-more-question.interface';
import { OneMoreQuestionTransService } from '../../services/one-more-question-trans.service';
import { OneMoreQuestionService } from '../../services/one-more-question.service';

export const oneMoreQuestionLanguagesDialogConfig: MatDialogConfig = {
  maxWidth: '90vw',
  maxHeight: '90vh',
  minHeight: '400px',
  disableClose: true,
  panelClass: 'full-screen-modal',
};
export class LanguageFormGroup extends FormGroup {
  language?: I18nAvailableLanguage;
}
export class OptionControl extends FormControl {
  id: string;
}
export interface IQuestionTranslation {
  questionTrans: IOneMoreQuestionTranslationFromBackend;
  optionsTrans: IOneMoreQuestionTranslationFromBackend[];
}
@Component({
  selector: 'app-one-more-question-languages-dialog',
  templateUrl: './one-more-question-languages-dialog.component.html',
  styleUrls: ['./one-more-question-languages-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OneMoreQuestionLanguagesDialogComponent implements OnInit {
  langForm: FormGroup;
  inactiveLangForm: FormGroup;
  errors: any = GeneralQuestionsError;
  readonly CONSTANTS: any = CONSTANTS;
  showInactiveLanguages = false;
  showInfoBox = false;
  responseCount = 0;

  constructor(
    private mdDialogRef: MatDialogRef<OneMoreQuestionLanguagesDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public dataDialog: IOneMoreQuestionTranslationDialogData,
    private formBuilder: FormBuilder,
    private oneMoreQuestionService: OneMoreQuestionService,
    private oneMoeQuestionTransService: OneMoreQuestionTransService
  ) {}

  ngOnInit(): void {
    this.langForm = this.formBuilder.group({
      activeLangForm: this.getLangForm(this.dataDialog.allActiveLanguages),
    });
    this.inactiveLangForm = this.getLangForm(this.getInactiveLanguages(), false);
    this.showInfoBox = this.dataDialog.allowToChangeExistingTranslations;
    this.responseCount = this.dataDialog.responseCount;
  }

  onSubmit(): void {
    this.langForm.markAllAsTouched();
    this.langForm.markAsDirty();

    if (this.langForm.valid) {
      this.mdDialogRef.close(this.prepareDialogData());
    }
  }

  showInactiveLanguagesChange(): void {
    if (this.showInactiveLanguages) {
      this.langForm.addControl('inactiveLangForm', this.inactiveLangForm, {
        emitEvent: false,
      });
    } else {
      this.langForm.removeControl('inactiveLangForm', { emitEvent: false });
    }
  }

  trackByForGroup(index: number, formGroup: LanguageFormGroup): string {
    return formGroup.language.code;
  }

  trackByForControl(index: number, control: OptionControl): string {
    return control.id;
  }

  private prepareDialogData(): IOneMoreQuestionTransResponseDialog {
    const payload: IOneMoreQuestionTransResponseDialog = this.prepareTranslationDataSet(
      this.langForm.controls.activeLangForm as LanguageFormGroup
    );

    if (this.showInactiveLanguages) {
      const inactiveLangsPayload: IOneMoreQuestionTransResponseDialog = this.prepareTranslationDataSet(
        this.langForm.controls.inactiveLangForm as LanguageFormGroup
      );

      payload.questionOptionsTranslations.forEach((optionTranslations: IQuestionFormatOption, index: number) => {
        optionTranslations.optionTranslations = [
          ...optionTranslations.optionTranslations,
          ...inactiveLangsPayload.questionOptionsTranslations[index].optionTranslations,
        ];
      });
      payload.followQuestionOptionsTranslations.forEach((optionTranslations: IQuestionFormatOption, index: number) => {
        optionTranslations.optionTranslations = [
          ...optionTranslations.optionTranslations,
          ...inactiveLangsPayload.followQuestionOptionsTranslations[index].optionTranslations,
        ];
      });

      return {
        questionTranslations: [...payload.questionTranslations, ...inactiveLangsPayload.questionTranslations],
        questionOptionsTranslations: payload.questionOptionsTranslations,
        ...inactiveLangsPayload.questionOptionsTranslations,
        followQuestionTranslations: [...payload.followQuestionTranslations, ...inactiveLangsPayload.followQuestionTranslations],
        followQuestionOptionsTranslations: payload.followQuestionOptionsTranslations,
      };
    }

    return payload;
  }

  private prepareTranslationDataSet(langForm: LanguageFormGroup): IOneMoreQuestionTransResponseDialog {
    const payload: IOneMoreQuestionTransResponseDialog = {
      questionTranslations: [],
      questionOptionsTranslations: [],
      followQuestionTranslations: [],
      followQuestionOptionsTranslations: [],
    };

    (langForm.controls.translationForms as FormArray).controls.forEach((control: LanguageFormGroup) => {
      const langCode: string = control.language.code;
      const questionTranslation: IQuestionTranslation = this.getTranslationForQuestion(
        langCode,
        control.controls.mainQuestionForm.value.question,
        control.controls.mainQuestionForm.value.options,
        !control.controls.mainQuestionForm.disabled
      );

      payload.questionTranslations.push(questionTranslation.questionTrans);
      payload.questionOptionsTranslations = this.prepareTranslationOptions(
        payload.questionOptionsTranslations,
        questionTranslation.optionsTrans
      );

      if (control.value.followQuestionForm) {
        const followQuestionTranslation: IQuestionTranslation = this.getTranslationForQuestion(
          langCode,
          control.controls.followQuestionForm.value.question,
          control.controls.followQuestionForm.value.options,
          !control.controls.followQuestionForm.disabled
        );

        payload.followQuestionTranslations.push(followQuestionTranslation.questionTrans);
        payload.followQuestionOptionsTranslations = this.prepareTranslationOptions(
          payload.followQuestionOptionsTranslations,
          followQuestionTranslation.optionsTrans
        );
      }
    });

    return payload;
  }

  private prepareTranslationOptions(
    translationOptions: IQuestionFormatOption[],
    translationFormForm: StatementTranslationFromBackend[]
  ): IQuestionFormatOption[] {
    let translations: IQuestionFormatOption[] = translationOptions;

    if (translationOptions.length !== translationFormForm.length) {
      translations = Array(translationFormForm.length);
    }
    for (let i = 0; i < translations.length; i++) {
      if (!translations[i]) {
        translations[i] = {
          optionTranslations: [translationFormForm[i]],
        };
      } else {
        translations[i].optionTranslations.push(translationFormForm[i]);
      }
    }

    return translations;
  }

  private getTranslationForQuestion(
    langCode: string,
    question: string,
    options: string[],
    isNewTranslation: boolean
  ): IQuestionTranslation {
    return {
      questionTrans: {
        ...this.oneMoreQuestionService.prepareTranslationItem(langCode, question),
        isNewTranslation,
      },
      optionsTrans: options ? this.getTranslationArrayFromFormOptions(langCode, options, isNewTranslation) : [],
    };
  }

  private getTranslationArrayFromFormOptions(
    langCode: string,
    options: string[],
    isNewTranslation: boolean
  ): IOneMoreQuestionTranslationFromBackend[] {
    const translations: IOneMoreQuestionTranslationFromBackend[] = [];

    options.forEach((option: string) => {
      translations.push({
        ...this.oneMoreQuestionService.prepareTranslationItem(langCode, option),
        isNewTranslation,
      });
    });

    return translations;
  }

  private getLangForm(langSet: I18nAvailableLanguage[], shouldAddDefaultLanguage = true): FormGroup {
    const langForm: FormGroup = this.formBuilder.group({
      translationForms: this.formBuilder.array([]),
    });

    if (shouldAddDefaultLanguage) {
      // Add default language form;
      (langForm.controls.translationForms as FormArray).push(
        this.generateAndReturnFullQuestionForm(this.dataDialog.defaultLanguage, true),
        { emitEvent: false }
      );
    }

    // Add other active language
    langSet.forEach((lang: I18nAvailableLanguage) => {
      if (lang.code !== this.dataDialog.defaultLanguage.code) {
        (langForm.controls.translationForms as FormArray).push(this.generateAndReturnFullQuestionForm(lang, false), { emitEvent: false });
      }
    });

    return langForm;
  }

  private generateAndReturnFullQuestionForm(language: I18nAvailableLanguage, isRequired: boolean): LanguageFormGroup {
    const mainQuestionForm: FormGroup = this.generateAndReturnSingleQuestionForm(this.dataDialog.data, language.code, isRequired);

    if (this.dataDialog.data.followUpQuestions && this.dataDialog.data.followUpQuestions.length > 0) {
      const followQuestionForm: FormGroup = this.generateAndReturnSingleQuestionForm(
        this.dataDialog.data.followUpQuestions[0],
        language.code,
        isRequired,
        true
      );

      return this.createFormGroup({ mainQuestionForm, followQuestionForm }, language);
    }

    return this.createFormGroup({ mainQuestionForm }, language);
  }

  private createFormGroup(
    controlsConfig: {
      [key: string]: any;
      options?: AbstractControlOptions | null;
    },
    language: I18nAvailableLanguage
  ): LanguageFormGroup {
    const formGroup: LanguageFormGroup = this.formBuilder.group(controlsConfig) as LanguageFormGroup;

    formGroup.language = language;

    return formGroup;
  }

  private generateAndReturnSingleQuestionForm(
    question: IOneMoreQuestionGeneralBody | IOneMoreFollowQuestion,
    languageCode: string,
    isRequired = true,
    isFollowQuestion = false
  ): FormGroup {
    const questionForm: FormGroup = this.createQuestionLangForm(isRequired);
    const questionTranslation: IOneMoreQuestionTranslationFromBackend = this.oneMoeQuestionTransService.getQuestionTranslationObject(
      question.translations,
      languageCode
    );

    questionForm.controls.question.setValue(questionTranslation ? questionTranslation.value : '');

    if (
      this.dataDialog.allowToChangeExistingTranslations &&
      questionTranslation &&
      questionTranslation.value !== '' &&
      !questionTranslation.isNewTranslation
    ) {
      questionForm.controls.question.disable();
      questionForm.controls.options.disable();
    }
    if (question.options) {
      question.options.forEach((option: IOneMoreQuestionOptionBackend, index: number) => {
        const optionTranslation: IOneMoreQuestionTranslationFromBackend = this.oneMoeQuestionTransService.getQuestionTranslationObject(
          option.translations,
          languageCode
        );
        const optionControl: OptionControl = new OptionControl(
          {
            value: optionTranslation ? optionTranslation.value : '',
            disabled:
              this.dataDialog.allowToChangeExistingTranslations &&
              optionTranslation &&
              optionTranslation.value !== '' &&
              !optionTranslation.isNewTranslation,
          },
          isRequired ? [Validators.required] : []
        );

        optionControl.id = `${index}${languageCode}${isFollowQuestion ? 'f' : 'm'}`;
        (questionForm.controls.options as FormArray).push(optionControl, {
          emitEvent: false,
        });
      });
    }

    return questionForm;
  }

  private createQuestionLangForm(isRequired: boolean): FormGroup {
    return this.formBuilder.group({
      question: ['', isRequired ? [Validators.required] : []],
      options: this.formBuilder.array([]),
    });
  }

  private getInactiveLanguages(): I18nAvailableLanguage[] {
    return this.dataDialog.allLanguages.filter(
      (lang: I18nAvailableLanguage) =>
        !this.dataDialog.allActiveLanguages.find((activeLang: I18nAvailableLanguage) => activeLang.code === lang.code)
    );
  }
}
