import { Component, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { isNumber } from 'lodash';

@Component({
  selector: 'app-goal-slider',
  templateUrl: './goal-slider.component.html',
  styleUrls: ['./goal-slider.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GoalSliderComponent),
      multi: true,
    },
  ],
})
export class GoalSliderComponent implements ControlValueAccessor {
  readonly MIN_VALUE = 1;
  readonly MAX_VALUE = 6;
  readonly STEP = 0.1;
  value: number;
  // Function.prototype is a safe reference that will always point to an empty function. This is only initial, to prevent errors
  private propagateChange: Function = Function.prototype;

  onChange(newValue: number) {
    this.value = newValue;
    this.propagateChange(newValue);
  }

  writeValue(value: any): void {
    this.value = isNumber(value) ? value : 0;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {}
}
