import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'upperFirstLetter',
})
export class UpperFirstLetterPipe implements PipeTransform {
  transform(value: string, ...args: unknown[]): unknown {
    return value ? value.charAt(0).toUpperCase() + value.slice(1) : value;
  }
}
