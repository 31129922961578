import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router, RouterEvent } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { TranslateService } from '@ngx-translate/core';
import { default as Auth0Lock, Auth0LockConstructorOptions, Auth0LockShowOptions, Auth0LockStatic, AuthResult } from 'auth0-lock';
import * as moment from 'moment';
import { LocalStorageService } from 'ngx-webstorage';
import { Observable, ReplaySubject, Subject, throwError as observableThrowError } from 'rxjs';
import { filter, first, map, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { ServerEnvironmentConfig } from '../../core/interfaces/server-environment.interface';
import { IamBackendService } from '../../core/providers/backend/iam-backend.service';
import { ContextService } from '../../core/providers/context/context.service';
import { NavigationService } from '../../core/providers/navigation/navigation.service';
import { AvailableLanguages } from '../../core/providers/translator/language.contants';
import { CONSTANTS } from '../../shared/constants';
import { ContextObject, LoginObject, LoginPayload } from '../../shared/interfaces/login-object';
import { ContextUtil } from '../../core/utils';
import { NotificationService } from '../../core/providers/notification/notification.service';
import { PermissionsBackendService } from '../../core/providers/backend/permissions-backend.service';
import { RolesService } from '../../core/providers/backend/roles.service';
import { ServerEnvironmentService } from '../../core/providers/server-environment/server-environment.service';
import { UserService } from '../../core/providers/user/user.service';
import { authConfig } from './auth.config';

@Injectable()
export class AuthService implements OnDestroy {
  private jwtHelper: JwtHelperService = new JwtHelperService();
  private serverEnvironmentConfig$: ReplaySubject<Readonly<ServerEnvironmentConfig>> = new ReplaySubject<
    Readonly<ServerEnvironmentConfig>
  >();
  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    private userService: UserService,
    private notificationService: NotificationService,
    private contextService: ContextService,
    private localStorage: LocalStorageService,
    private contextUtil: ContextUtil,
    private permissionsBackendService: PermissionsBackendService,
    private environmentService: ServerEnvironmentService,
    private navigationService: NavigationService,
    private activatedRoute: ActivatedRoute,
    private rolesService: RolesService,
    private iamBackendService: IamBackendService,
    private router: Router,
    private ngZone: NgZone,
    private translateService: TranslateService
  ) {
    this.environmentService
      .getServerEnvironmentConfig$()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((serverEnvironment: ServerEnvironmentConfig) => {
        this.serverEnvironmentConfig$.next(serverEnvironment);
      });
  }

  logIn$(): Observable<Auth0LockStatic> {
    const lockShowOptions: Auth0LockShowOptions = {
      allowLogin: true,
      allowSignUp: false,
    };
    const lockConstructorOptions: Auth0LockConstructorOptions = {};

    return this.serverEnvironmentConfig$.pipe(
      first(),
      map(
        (data: Readonly<ServerEnvironmentConfig>) =>
          new Auth0Lock(data.auth0.clientId, data.auth0.domain, {
            ...authConfig.options,
            ...lockConstructorOptions,
            languageDictionary: {
              title: this.translateService.instant('LOGIN.TITLE'),
              signupTitle: this.translateService.instant('LOGIN.SIGN_UP_TITLE'),
              signUpSubmitLabel: this.translateService.instant('LOGIN.SIGN_UP_SUB_TITLE'),
              passwordInputPlaceholder: this.translateService.instant('LOGIN.SET_PASSWORD'),
            },
            language: this.getLangForAuthComponent(),
          })
      ),
      tap((authLock: Auth0LockStatic) => {
        // has to unmount before mounting again
        this.router.events
          .pipe(filter((event: RouterEvent) => event instanceof NavigationStart))
          .pipe(first(), takeUntil(this.onDestroy$))
          .subscribe(() => authLock.hide());
        authLock.show(lockShowOptions);
      })
    );
  }

  authenticated(): boolean {
    if (
      this.jwtHelper.getTokenExpirationDate(localStorage.getItem(CONSTANTS.AUTH.ID_TOKEN)) &&
      this.jwtHelper.isTokenExpired(localStorage.getItem(CONSTANTS.AUTH.ID_TOKEN))
    ) {
      this.displaySessionExpiredAuthPopup();
    }

    return !this.jwtHelper.isTokenExpired(localStorage.getItem(CONSTANTS.AUTH.ID_TOKEN));
  }

  handleAuthenticated(authResult: AuthResult): void {
    this.contextService.clearContext();
    this.localStorage.clear();
    this.getInternalToken$(authResult)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (response: LoginObject) => {
          this.initTokenData(response);
          this.saveUserLanguage(response);
          this.checkPermissions(response);
        },
        (error: any) => this.handleError$(error)
      );
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  setUserLanguage(): void {
    const language: string = this.isCorrectLangInLocalStorage() ? localStorage.getItem(CONSTANTS.AUTH.LANGUAGE) : 'en-GB';

    moment.locale(language ? language.split('-')[0] : 'en');
    this.translateService.setDefaultLang(language);
    this.translateService.use(language);
  }

  isCorrectLangInLocalStorage(): boolean {
    switch (localStorage.getItem(CONSTANTS.AUTH.LANGUAGE)) {
      case AvailableLanguages.britishEnglish:
        return true;
      case AvailableLanguages.spanish:
        return true;
      case AvailableLanguages.german:
        return true;
      case AvailableLanguages.french:
        return true;
      case AvailableLanguages.italian:
        return true;
      default:
        return false;
    }
  }

  private displaySessionExpiredAuthPopup(): void {
    if (this.translateService.getDefaultLang() === undefined) {
      this.translateService.use(CONSTANTS.COUNTRIES.ALFA3_TO_LANGUAGE.GBR);
    }

    this.notificationService
      .displayPopupWithTranslationKey('SHARED.NOTIFICATIONS.SESSION_EXPIRED', 'warning')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.removeCookieByName('auth0.ssodata');
        this.localStorage.clear();
        this.navigationService.goToLogin();
      });
  }

  private handleRedirect(loginResponse: LoginObject): void {
    const redirectUrl: string = this.activatedRoute.snapshot.queryParams.redirectUrl;
    const { isNewFoodbackUser, defaultItem: currentContext }: any = loginResponse;

    if (isNewFoodbackUser) {
      this.navigationService.goToEditProfile();
    } else if (redirectUrl) {
      this.navigationService.goToUrl(redirectUrl);
    } else if (this.rolesService.isAdmin()) {
      this.navigationService.goToAdminAccountManagement();
    } else if (this.contextUtil.isVenueContext(currentContext.type)) {
      this.navigationService.goToVenueMainScreen(currentContext.uuid);
    } else if (this.contextUtil.isAccountContext(currentContext.type)) {
      this.navigationService.goToScoreboards(currentContext.uuid);
    } else {
      // fallback that should never happen
      this.navigationService.goToEditProfile();
    }
  }

  private initTokenData(result: LoginObject): void {
    localStorage.setItem(CONSTANTS.AUTH.ID_TOKEN, result.foodbackToken);
    this.localStorage.store(CONSTANTS.AUTH.ID_TOKEN, result.foodbackToken);
    this.userService.setAuthorizedData();
  }

  private initContextData(loginResponse: LoginObject): void {
    this.contextService.clearContext();
    this.userService.setUserData(loginResponse.user);
    this.contextService.setFallbackContext(loginResponse.defaultItem);

    if (loginResponse.roles.includes(CONSTANTS.ROLES.ADMIN)) {
      this.createContextFromLoginObject$(loginResponse);
    } else {
      // do nothing, as we expect the guards to create the proper context
    }

    this.userService.setIsNewFoodbackUser(loginResponse.isNewFoodbackUser);
    this.userService.setLoggedIn(true);
    this.userService.setLoginData({
      intercom: loginResponse.intercom,
    });
    this.handleLoginLanding(loginResponse);
  }

  private getInternalToken$(authResult: AuthResult): Observable<LoginObject> {
    const jwtObj: LoginPayload = {
      jwt: authResult.idToken,
    };

    return this.iamBackendService.loginToIam(jwtObj);
  }

  private handleError$(error: Response): Observable<Response> {
    if (!error.ok) {
      const errorMessage: string = (error as any).message || 'Login error';

      this.notificationService.error(errorMessage);
    }

    return observableThrowError(error);
  }

  private handleLoginLanding(loginResponse: LoginObject): void {
    // this is an attempt at a hacky fix for a weird bug that sometimes happens and prevents the content from being visible
    // when it occurs, it looks like Angular doesn't kick the change detection after the NavigationEnd event (I have no idea WHY)
    // tried inspecting it for a few days, didn't succeed, so here's a pseudo fix. Let's hope it works
    this.ngZone.run(() => {
      this.handleRedirect(loginResponse);
    });
  }

  private createContextFromLoginObject$(loginObject: LoginObject): Observable<void> {
    this.rolesService.setRoles(loginObject.roles);

    return this.contextService.convertDefaultItemToContext$(loginObject.defaultItem).pipe(
      mergeMap((context: ContextObject) => {
        this.contextService.setContext(context);

        return new Observable<void>();
      })
    );
  }

  private checkPermissions(loginObject: LoginObject): void {
    this.permissionsBackendService
      .hasAccess$()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((hasLoggedUserAccess: boolean) => {
        if (hasLoggedUserAccess) {
          this.initContextData(loginObject);
        } else {
          // another hacky fix for similar that is related to the comment underneath - for some reason Angular doesn't trigger change
          // detection, leaving the app half-rendered
          this.ngZone.run(() => {
            this.navigationService.goToNoAccess();
          });
        }
      });
  }

  private saveUserLanguage(result: LoginObject): void {
    moment.locale(result.user.language ? result.user.language.split('-')[0] : 'en');
    localStorage.setItem(CONSTANTS.AUTH.LANGUAGE, result.user.language);
    this.setUserLanguage();
  }

  private getLangForAuthComponent(): string {
    return this.isCorrectLangInLocalStorage() ? localStorage.getItem(CONSTANTS.AUTH.LANGUAGE).split('-')[0] : 'en';
  }

  private removeCookieByName(name: string): void {
    document.cookie = `${name} =; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
  }
}
