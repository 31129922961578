import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { RolesService } from '../../core/providers/backend/roles.service';
import { NavigationService } from '../../core/providers/navigation/navigation.service';
import { LayoutUtil } from '../../core/utils/layout-util';

@Injectable()
export class FoodbackAdminGuard implements CanActivate {
  constructor(private navigationService: NavigationService, private rolesService: RolesService, private layoutUtil: LayoutUtil) {}

  canActivate(): boolean {
    if (this.rolesService.isAdmin()) {
      this.layoutUtil.showAdminPanel();

      return true;
    }

    this.navigationService.goToInner404();

    return false;
  }
}
