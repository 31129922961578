import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild } from '@angular/router';
import { find } from 'lodash';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { CONSTANTS } from '../../../shared/constants';
import { PermissionsEntry, VenuePermissionsEntry } from '../../../shared/interfaces/permissions.interface';
import { PermissionsBackendService } from '../backend/permissions-backend.service';
import { RolesService } from '../backend/roles.service';
import { NavigationService } from '../navigation/navigation.service';

@Injectable()
export class SingleVenueModeGuardService implements CanActivate, CanActivateChild {
  constructor(
    private permissionsBackendService: PermissionsBackendService,
    private rolesService: RolesService,
    private navigationService: NavigationService
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    if (this.rolesService.isAdmin()) {
      return of(true);
    }
    const { [CONSTANTS.ROUTE_PARAMS.ACCOUNT_ID]: accountUuid } = route.params;

    return this.permissionsBackendService.getPermissions$().pipe(
      mergeMap((permissions: PermissionsEntry[]) => this.canChangeToAccount(accountUuid, permissions)),
      mergeMap((canChangeToAccount: boolean) => {
        if (!canChangeToAccount) {
          return this.navigateToVenueDashboard(accountUuid);
        }

        return of(true);
      })
    );
  }

  canActivateChild(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.canActivate(route);
  }

  private navigateToVenueDashboard(accountUuid: string): Observable<boolean> {
    return this.permissionsBackendService.getVenuePermissions$(accountUuid).pipe(
      mergeMap((venuesPermissions: VenuePermissionsEntry[]) => {
        // in single venue mode, the response should contain only a single venue
        const { venueUuid } = venuesPermissions[0];

        this.navigationService.goToVenueMainScreen(venueUuid);

        return of(true);
      })
    );
  }

  private canChangeToAccount(accountUuid: string, permissions: PermissionsEntry[]): Observable<boolean> {
    const accountPermissions = find(permissions, { accountUuid });

    if (!accountPermissions) {
      return of(true);
    }

    return of(!accountPermissions.isSingleVenueMode);
  }
}
