<mat-form-field [ngClass]="{ 'mat-multi-select': isMultiModeEnable }">
  <mat-label *ngIf="label">{{ label }}</mat-label>
  <mat-select
    [formControl]="selectCtrl"
    [placeholder]="placeholder"
    #select
    disableOptionCentering
    [multiple]="isMultiModeEnable || isSingleAndAllModeEnable"
    [compareWith]="compareObjects"
    (openedChange)="open($event)"
    ngDefaultControl
    [required]="required">
    <mat-select-trigger *ngIf="!isMultiModeEnable && showAdditionalInfo">
      <div [ngClass]="{ 'Select-iconLabelContainer': selectCtrl?.value?.icon }" fxLayout="row"
           fxLayoutAlign="start center">
        <mat-icon
          [ngClass]="{
            'Select-iconLabelCircle': selectCtrl?.value?.isCircleIcon
          }"
          *ngIf="selectCtrl?.value?.icon"
          [color]="selectCtrl.value.iconColor"
          [svgIcon]="selectCtrl.value.icon"></mat-icon>
        <div>
          {{
          selectCtrl?.value?.translationKey
            ? (selectCtrl?.value?.translationKey | translate) + selectCtrl?.value?.additionalTranslationText
            : selectCtrl?.value?.text
          }}
        </div>
      </div>
    </mat-select-trigger>
    <app-select-search
      #selectSearch
      [formControl]="searchCtrl"
      (selectAllBooleanEmitter)="toggleAll($event)"
      [placeholderLabel]="searchBoxPlaceholder"
      [noEntriesFoundLabel]="notFoundMessage"
      [panelClass]="panelClass"
      [isSingleAndAllModeEnable]="isSingleAndAllModeEnable"
      ngDefaultControl></app-select-search>
    <ng-container *ngFor="let item of filteredItemList | async as items; let index = index">
      <mat-option *ngIf="!item.subItems && !item.hidden" [value]="item" [disabled]="item.disabled || item.isHeader" (click)="onOptionClick(item)"
                  [ngClass]="{ 'pl-sm': item.icon, 'Select-header': item.isHeader }">
        <div [ngClass]="{ 'Select-iconLabelContainer': item.icon }" fxLayout="row" fxLayoutAlign="space-between center">
          <mat-icon
            [ngClass]="{ 'Select-iconLabelCircle': item.isCircleIcon }"
            *ngIf="item.icon"
            [color]="item.iconColor"
            [svgIcon]="item.icon"></mat-icon>
          <ng-container [ngTemplateOutlet]="itemTextTranslatedLabel"
                        [ngTemplateOutletContext]="{ item: item }"></ng-container>
        </div>
      </mat-option>
      <ng-container *ngIf="item.subItems">
        <mat-option
          *ngFor="let subItem of item.subItems; let subIndex = index"
          [ngClass]="{
            'border-top': subIndex === 0,
            'border-bottom': subIndex === item.subItems.length - 1 && items[index + 1] && !items[index + 1].subItems
          }"
          [value]="subItem"
          (click)="onOptionClick(subItem)">
          <span
            [innerHTML]="
              (subItem.translationKey ? (subItem.translationKey | translate: subItem.interpolateParams) : subItem.text) | sanitizeHtml
            "></span>
        </mat-option>
      </ng-container>
      <ng-template #itemTextTranslatedLabel let-item="item">
        <ng-container *ngIf="item.translationKey">
          <ng-container
            [ngTemplateOutlet]="itemTextLabel"
            [ngTemplateOutletContext]="{
              text: (item.translationKey | translate: item.interpolateParams),
              additionalInfo: item.additionalInfo
            }">
          </ng-container>
        </ng-container>
        <ng-container *ngIf="!item.translationKey">
          <ng-container
            [ngTemplateOutlet]="itemTextLabel"
            [ngTemplateOutletContext]="{
              text: item.text,
              additionalInfo: item.additionalInfo
            }">
          </ng-container>
        </ng-container>
      </ng-template>
      <ng-template #itemTextLabel let-text="text" let-additionalInfo="additionalInfo">
        <div
          #itemTextContainerText
          class="Select-itemText"
          [innerHTML]="text | sanitizeHtml"
          appTooltip
          [contentTemplate]="itemTextContainerText.offsetWidth < itemTextContainerText.scrollWidth ? tooltipText : null"
          [templateData]="{ textInfo: text }"></div>
        <span *ngIf="additionalInfo" class="Select-additionalInfo" [innerHTML]="additionalInfo | sanitizeHtml"></span>
        <ng-template #tooltipText let-textInfo="textInfo"><span [innerHTML]="textInfo | sanitizeHtml"></span>
        </ng-template>
      </ng-template>
    </ng-container>
  </mat-select>
  <mat-error *ngIf="errors" [appErrors]="selectCtrl" [errors]="errors"></mat-error>
</mat-form-field>
