import { Component, Input } from '@angular/core';
import { round } from 'lodash';
import { CONSTANTS } from '../../constants';
import { PercentCircleVariant } from './percent-circle.component.interface';

@Component({
  selector: 'app-percent-circle',
  templateUrl: './percent-circle.component.html',
  styleUrls: ['./percent-circle.component.scss'],
})
export class PercentCircleComponent {
  @Input() goal: number;
  @Input() percentValue = 0;
  @Input() useCustomContent?: boolean = false;
  @Input() variant?: PercentCircleVariant = PercentCircleVariant.DEFAULT;
  readonly CONSTANTS = CONSTANTS;
  readonly PercentCircleVariant = PercentCircleVariant;

  getPercentageValue(value: number): number {
    // eslint-disable-next-line no-magic-numbers
    return (value / CONSTANTS.FOODBACK.RANK_SCALE) * 100;
  }

  getColorClassForRating(): string {
    return this.getColorPostfix();
  }

  getColorPostfix(): string {
    if (this.goal === undefined) {
      return '';
    }

    const roundedRating = this.getRoundedRatingValue();
    const roundedGoal = this.getRoundedGoalValue();

    if (roundedRating >= roundedGoal) {
      return 'PercentCircleWrapper-success';
    }
    if (roundedRating >= CONSTANTS.FOODBACK.MID_RANK) {
      return 'PercentCircleWrapper-warning';
    }

    return this.goal ? 'PercentCircleWrapper-danger' : '';
  }

  getRoundedRatingValue(): number {
    // eslint-disable-next-line no-magic-numbers
    return round((this.percentValue * CONSTANTS.FOODBACK.RANK_SCALE) / 100, 1);
  }

  getRoundedGoalValue(): number {
    return round(this.goal, 1);
  }
}
