<div class="Branding-logo">
  <h4 class="page-header mt0">{{ 'SHARED.COMPONENTS.BRANDING_LOGO.HEADER' | translate }}</h4>

  <p class="upload-requirements">
    {{ 'SHARED.COMPONENTS.BRANDING_LOGO.DESCRIPTION.BASE' | translate }}:<br /><br />
    <strong>{{ 'SHARED.COMPONENTS.BRANDING_LOGO.DESCRIPTION.STRONG_WORDS.RESOLUTION' | translate }}</strong
    >: {{ 'SHARED.COMPONENTS.BRANDING_LOGO.DESCRIPTION.REQUIREMENTS.RESOLUTION' | translate }}<br />
    <strong>{{ 'SHARED.COMPONENTS.BRANDING_LOGO.DESCRIPTION.STRONG_WORDS.SIZE' | translate }}</strong
    >: {{ 'SHARED.COMPONENTS.BRANDING_LOGO.DESCRIPTION.REQUIREMENTS.SIZE' | translate }}
    <strong>{{ 'SHARED.COMPONENTS.BRANDING_LOGO.DESCRIPTION.STRONG_WORDS.FORMAT' | translate }}</strong
    >: {{ 'SHARED.COMPONENTS.BRANDING_LOGO.DESCRIPTION.REQUIREMENTS.FORMAT' | translate }}
  </p>
  <app-branding-upload-logo
    class="logo-component"
    (uploadedImage)="sendUploadedImageToParent($event)"
    (uploadingStatusChanged)="handleUploadingStatusChanged($event)"
    (onImgError)="onLogoImgError.emit()"
    [avatarURL]="logoUrl">
  </app-branding-upload-logo>
</div>
