import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { cloneDeep } from 'lodash';
import { Observable, Subject, combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { KeyboardStatusService } from '../../../../core/providers/keyboard-status/keyboard-status.service';
import { DropdownUtil } from '../../../../core/utils/dropdown-util';
import { KeyboardUtils } from '../../../../core/utils/events/keyboard.utils';
import { CONSTANTS } from '../../../constants';
import { FormModels } from '../../../form-models';
import { IDropdownItem } from '../../../interfaces/dropdown/dropdown-item.interface';
import { IGroupPayload } from '../../../interfaces/groups/add-group-payload.interface';
import { IGroup } from '../../../interfaces/groups/group.interface';
import { GroupsService } from '../groups.service';

export const newCategoryDialogConfig: MatDialogConfig = {
  width: '600px',
  height: '420px',
  disableClose: true,
};
@Component({
  selector: 'app-new-category-dialog',
  templateUrl: './new-category-dialog.component.html',
  styleUrls: ['./new-category-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewCategoryDialogComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('nameInput', { static: true }) nameInputRef: ElementRef;
  categories: IGroup[] = [];
  categoryForm: FormGroup;
  selectedGroups: IGroup[] = [];
  errors = FormModels.AccountAccount.CategoryGroupErrors;
  isDisabledDropdown = false;
  filteredGroups: IGroup[] = [];
  groupsAsDropdownItems: IDropdownItem[] = [];
  selectedGroupsAsDropdownItems: IDropdownItem[] = [];

  private selectedCategory: IGroup = null;
  private escModalStatus = false;
  private onDestroy$ = new Subject<void>();

  constructor(
    private mdDialogRef: MatDialogRef<NewCategoryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IGroup,
    private groupsService: GroupsService,
    private formBuilder: FormBuilder,
    private keyboardStatusService: KeyboardStatusService,
    private changeDetectionRef: ChangeDetectorRef
  ) {}

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (KeyboardUtils.pressedEscape(event) && this.escModalStatus) {
      this.mdDialogRef.close();
    }
  }

  ngOnInit() {
    const minLength = 2;

    this.categoryForm = this.formBuilder.group({
      name: new FormControl('', [Validators.required, Validators.minLength(minLength), this.isUniqueCategoryName.bind(this)]),
    });
    combineLatest([this.getGroupsWithCategories(), this.getGroups()])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(([categories, groups]) => {
        this.categories = categories;
        this.filteredGroups = groups.filter(group => !group.isCategory);
        this.setForm();
        this.setDropdownItems();
        this.changeDetectionRef.detectChanges();
      });
    this.getEscModalStatus()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(status => {
        this.escModalStatus = status;
      });
  }

  resetFormDialog() {
    this.categoryForm.reset();
    this.categoryForm.controls.name.enable();
    this.isDisabledDropdown = false;
  }

  assignGroup(item: IDropdownItem) {
    this.addGroup(item);
  }

  unassignVenueFromGroup(item: IDropdownItem) {
    this.selectedGroups = this.selectedGroups.filter(group => group.uuid !== item.id);
    this.setDropdownItems();
    this.changeDetectionRef.detectChanges();
  }

  save() {
    if (this.categoryForm.valid) {
      this.isDisabledDropdown = true;
      this.categoryForm.controls.name.disable();

      if (this.selectedCategory) {
        this.updateExisting();
      } else {
        this.saveNew();
      }
    } else {
      this.categoryForm.get('name').markAsTouched();
      this.categoryForm.get('name').markAsDirty();
    }
  }

  ngAfterViewInit(): void {
    this.autofocusName();
  }

  getGroupsWithCategories(): Observable<IGroup[]> {
    return this.groupsService.getGroupsWithCategories$();
  }

  getGroups(): Observable<IGroup[]> {
    return this.groupsService.getGroups$();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private setDropdownItems() {
    this.groupsAsDropdownItems = this.getGroupsAsDropdownItems(this.filteredGroups);
    this.selectedGroupsAsDropdownItems = this.getGroupsAsDropdownItems(this.selectedGroups);
  }

  private getGroupsAsDropdownItems(groups: IGroup[]): IDropdownItem[] {
    return cloneDeep(DropdownUtil.convertToDropdownItems(groups, ['uuid', 'name']));
  }

  private autofocusName() {
    if (this.nameInputRef) {
      setTimeout(() => {
        this.nameInputRef.nativeElement.focus();
      }, CONSTANTS.TIME_IN_MS.TIME_250);
    }
  }

  private isUniqueCategoryName(control: AbstractControl) {
    const allGroup = [...this.categories, ...this.filteredGroups];
    const categories = this.selectedCategory ? allGroup.filter(category => category.uuid !== this.selectedCategory.uuid) : allGroup;
    const isExists = !!categories.find(category => category.name === control.value);

    return isExists ? { isUnique: {} } : null;
  }

  private updateExisting() {
    const { name, venueGroups } = this.getFormData();
    const payload: IGroupPayload = {
      name,
      venueGroups,
      isCategory: this.selectedCategory.isCategory,
      category: null,
      venues: [],
    };

    this.updateGroupOrCategory(this.selectedCategory.uuid, payload)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(updatedCategory => this.handleResponseData(updatedCategory));
  }

  private saveNew() {
    const { name, venueGroups } = this.getFormData();
    const payload: IGroupPayload = {
      name,
      venueGroups,
      isCategory: true,
      category: null,
      venues: [],
    };

    this.saveNewGroupOrCategory(payload)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(newCategory => this.handleResponseData(newCategory));
  }

  private handleResponseData(data: IGroup) {
    if (data && data.uuid) {
      this.mdDialogRef.close(true);
    } else {
      this.categoryForm.controls.name.enable();
      this.isDisabledDropdown = false;
    }
  }

  private getFormData(): { name: string; venueGroups: string[] } {
    const name: string = this.categoryForm.controls.name.value;
    const venueGroups = this.selectedGroups.map(group => group.uuid);

    return { name, venueGroups };
  }

  private addGroup(item: IDropdownItem) {
    const group: IGroup = this.filteredGroups.find(g => g.uuid === item.id);

    if (group) {
      this.selectedGroups.push(group);
      this.setDropdownItems();
    } else {
      this.addNewGroup(item.text);
    }
  }

  private addNewGroup(name: string) {
    const payload: IGroupPayload = {
      name,
      isCategory: false,
      category: null,
      venues: [],
      venueGroups: [],
    };

    this.saveNewGroupOrCategory(payload)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(newGroup => {
        if (newGroup) {
          this.selectedGroups.push(newGroup);
          this.setDropdownItems();
          this.changeDetectionRef.detectChanges();
        }
      });
  }

  private saveNewGroupOrCategory(data: IGroupPayload): Observable<any> {
    return this.groupsService.addGroup$(data);
  }

  private updateGroupOrCategory(uuid: string, data: IGroupPayload) {
    return this.groupsService.updateGroup$(uuid, data);
  }

  private getEscModalStatus(): Observable<boolean> {
    return this.keyboardStatusService.getEscModalStatus();
  }

  private setData() {
    this.categoryForm.controls.name.setValue(this.selectedCategory.name);
    this.selectedGroups = [...this.selectedGroups, ...this.selectedCategory.venueGroups];
  }

  private setForm() {
    if (this.data) {
      this.selectedCategory = this.data;
      this.resetFormDialog();
      this.setData();
    }
  }
}
