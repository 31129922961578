<span
  class="BillFolderDots"
  [ngClass]="{
    'BillFolderDots--overallExperience': variant === BillFolderDotsVariant.OVERALL_EXPERIENCE
  }">
  <span
    class="BillFolderDots-dot"
    *ngFor="let s of scores; last as isLast; first as isFirst"
    [ngClass]="{
      'BillFolderDots-dot--filled': s < score,
      'BillFolderDots-dot--great': isLast && score === scores.length,
      'BillFolderDots-dot--awful': isFirst && score !== null && score <= 1
    }">
  </span>
</span>
