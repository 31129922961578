import { ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { I18nBackendService } from 'app/core/providers/backend/i18n-backend.service';
import { LanguageService } from 'app/core/providers/backend/language.service';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ACCOUNT_ROUTES_PATHS_FULL } from '../../../../components/account/account-routes-paths';
import { VENUE_ROUTES_PATHS_FULL } from '../../../../components/venue/venue-routes-paths';
import { DashboardQueryParams } from '../../../../core/interfaces/dashboard-query-params.interface';
import { RouteBuilderService } from '../../../../core/providers/backend/route-builder.service';
import { CONSTANTS } from '../../../constants';
import { SharedComponentViewType } from '../../../interfaces/common.interface';
import { I18nSupportedLanguage } from '../../../interfaces/i18n.interface';
import { RouterLinkPipe } from '../../../pipes/router-link.pipe';
import { PercentCircleVariant } from '../../percent-circle/percent-circle.component.interface';
import { CategoryOverviewCardInput, CategoryOverviewCardTopLowQuestionsInput } from './category-overview-card.interface';

@Component({
  selector: 'app-category-overview-card',
  templateUrl: './category-overview-card.component.html',
  styleUrls: ['./category-overview-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CategoryOverviewCardComponent implements OnInit, OnChanges, OnDestroy {
  @Input() categoryDetails: CategoryOverviewCardInput;
  @Input() goal: number;
  @Input() topLowPerformingQuestions: CategoryOverviewCardTopLowQuestionsInput;
  @Input() linkQueryParams: DashboardQueryParams;
  @Input() contextId: string;
  @Input() viewType: SharedComponentViewType;
  CONSTANTS = CONSTANTS;
  PercentCircleVariant = PercentCircleVariant;
  nativeLang: string;
  categoryLink: string;
  questionLinkLow: string;
  questionLinkTop: string;
  private routes: typeof VENUE_ROUTES_PATHS_FULL | typeof ACCOUNT_ROUTES_PATHS_FULL;
  private routerLinkPipe: RouterLinkPipe;
  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    private routeBuilderService: RouteBuilderService,
    private i18nBackendService: I18nBackendService,
    private languageService: LanguageService
  ) {
    this.routerLinkPipe = new RouterLinkPipe(this.routeBuilderService);
  }

  ngOnInit() {
    this.setLanguage();
    this.setRoutes();
    this.setCategoryRedirectionLink();
  }

  getCategoryPercentValue(): number {
    const fullPercent = 100;

    return (Number(this.categoryDetails.value) / CONSTANTS.FOODBACK.RANK_SCALE) * fullPercent;
  }

  getCategoryLink(): string {
    return this.routerLinkPipe.transform(this.routes.OVERVIEW.CATEGORY, {
      [this.getContextIdRouteParamName()]: this.contextId,
      [CONSTANTS.ROUTE_PARAMS.CATEGORY_ID]: this.categoryDetails.uuid,
    });
  }

  getQuestionLink(questionUuid: string): string {
    return this.routerLinkPipe.transform(this.routes.OVERVIEW.QUESTION, {
      [this.getContextIdRouteParamName()]: this.contextId,
      [CONSTANTS.ROUTE_PARAMS.CATEGORY_ID]: this.categoryDetails.uuid,
      [CONSTANTS.ROUTE_PARAMS.QUESTION_ID]: questionUuid,
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.topLowPerformingQuestions && changes.topLowPerformingQuestions.currentValue) {
      this.setQuestionRedirectionLink();
    }
  }

  private setRoutes() {
    this.routes = this.viewType === SharedComponentViewType.ACCOUNT ? ACCOUNT_ROUTES_PATHS_FULL : VENUE_ROUTES_PATHS_FULL;
  }

  private setCategoryRedirectionLink() {
    this.categoryLink = this.getCategoryLink();
  }

  private setQuestionRedirectionLink() {
    this.setRoutes();

    if (this.topLowPerformingQuestions && this.topLowPerformingQuestions.top && this.topLowPerformingQuestions.top.surveyQuestion) {
      this.questionLinkTop = this.getQuestionLink(this.topLowPerformingQuestions.top.surveyQuestion.uuid);
    }
    if (this.topLowPerformingQuestions && this.topLowPerformingQuestions.low && this.topLowPerformingQuestions.low.surveyQuestion) {
      this.questionLinkLow = this.getQuestionLink(this.topLowPerformingQuestions.low.surveyQuestion.uuid);
    }
  }

  private getContextIdRouteParamName(): string {
    return this.viewType === SharedComponentViewType.ACCOUNT ? CONSTANTS.ROUTE_PARAMS.ACCOUNT_ID : CONSTANTS.ROUTE_PARAMS.VENUE_ID;
  }

  private setLanguage(): void {
    this.nativeLang = this.languageService.getDefaultLanguage()?.code;

    if (!this.nativeLang) {
      this.nativeLang = this.languageService.getDefaultLanguage()?.code;
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
