import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class MaterialLocaleService {
  constructor(private translateService: TranslateService) {}

  getLanguage(): string {
    // @ts-ignore
    return this.translateService.getDefaultLang() || navigator.language || navigator.userLanguage;
  }
}
