import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { IamBackendService } from '../../core/providers/backend/iam-backend.service';
import { NavigationService } from '../../core/providers/navigation/navigation.service';
import { NotificationService } from '../../core/providers/notification/notification.service';
import { ContextUtil } from '../../core/utils';
import { ContextObject } from '../../shared/interfaces/login-object';
import { AuthService } from './auth.service';
import { LogoutService } from './logout.service';

@Injectable({
  providedIn: 'root',
})
export class LoginGuard implements CanActivate {
  constructor(
    private logoutService: LogoutService,
    private authService: AuthService,
    private navigationService: NavigationService,
    private notificationService: NotificationService,
    private iamBackendService: IamBackendService,
    private contextUtil: ContextUtil
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    if (this.authService.authenticated()) {
      return this.iamBackendService.getUserRedirectInfo().pipe(
        mergeMap((response: ContextObject) => {
          if (response && response.uuid) {
            this.redirectUserToProperPage(response);
          } else {
            this.logoutService.logOut();
          }

          this.notificationService.hideLoadingPopup();

          return of(false);
        })
      );
    }

    return of(true);
  }

  private redirectUserToProperPage(context: ContextObject): void {
    if (this.contextUtil.isVenueContext(context.type)) {
      this.navigationService.goToVenueMainScreen(context.uuid);
    } else if (this.contextUtil.isAccountContext(context.type)) {
      this.navigationService.goToScoreboards(context.uuid);
    }
  }
}
