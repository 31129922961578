import { StaticDateRangePeriod } from '../../interfaces/date-range.interface';

export enum DateGroupPickerValue {
  YEAR = 'year',
  MONTH = 'month',
  WEEK = 'week',
  DAY = 'day',
  CUSTOM = 'custom',
  STATIC = 'static',
}
export interface DynamicPickerEntry {
  name: string;
  value: DateGroupPickerValue;
}
export interface StaticPickerEntry {
  name: string;
  value: DateGroupPickerValue.STATIC;
  period: StaticDateRangePeriod;
  count: number;
}
