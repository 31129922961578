import { Component, Input } from '@angular/core';
import { PreloaderType } from './preloader.enum';

@Component({
  selector: 'app-preloader',
  templateUrl: './preloader.component.html',
  styleUrls: ['./preloader.component.scss'],
})
export class PreloaderComponent {
  @Input() isSingleMode = true;
  @Input() type: PreloaderType = PreloaderType.DEFAULT;

  readonly PreloaderType: typeof PreloaderType = PreloaderType;
}
