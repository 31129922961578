import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject, forkJoin, throwError as observableThrowError, of, throwError } from 'rxjs';
import { catchError, map, mergeMap, takeUntil } from 'rxjs/operators';
import { I18nBackendService } from '../../../core/providers/backend/i18n-backend.service';
import { IVenueLinkDetails, LanguageService } from '../../../core/providers/backend/language.service';
import { UsersBackendService } from '../../../core/providers/backend/users-backend.service';
import { NotificationService } from '../../../core/providers/notification/notification.service';
import { CONSTANTS } from '../../constants';
import { AccountForBackend, VenueFromBackend } from '../../form-models-interfaces';
import { I18nAvailableLanguage, I18nSupportedLanguage, I18nValidtion } from '../../interfaces/i18n.interface';
import { ContextService } from 'app/core/providers/context/context.service';

@Component({
  selector: 'app-languages-form',
  templateUrl: './languages-form.component.html',
  styleUrls: ['./languages-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguagesFormComponent implements OnInit, OnDestroy {
  @Input() contextType: string;
  @Input() contextUuid: string;
  @Input() venueName: string;

  @Output() readonly onLoading: EventEmitter<boolean> = new EventEmitter<boolean>();

  languagesList: I18nAvailableLanguage[] = [];
  defaultLanguageCode: string;
  accountChildrenNameSingular: string;
  accountChildrenNamePlural: string;
  readonly CONSTANTS: typeof CONSTANTS = CONSTANTS;
  private contextParentUuid: string;
  private onDestroy$: Subject<void> = new Subject<void>();
  private accountUuid: string;

  constructor(
    private i18nBackendService: I18nBackendService,
    private translateService: TranslateService,
    private notificationService: NotificationService,
    private languageService: LanguageService,
    private usersBackendService: UsersBackendService,
    private cdf: ChangeDetectorRef,
    private contextService: ContextService
  ) {}

  ngOnInit(): void {
    if (this.contextType === CONSTANTS.CONTEXT.ACCOUNT) {
      this.loadAvailableLanguages();
    } else {
      this.loadAccountLanguagesAndData();
    }
    this.accountChildrenNameSingular = this.contextService.getAccountChildrenNameTranslation(true);
    this.accountChildrenNamePlural = this.contextService.getAccountChildrenNameTranslation(false);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  onEnforceStatusChanged(lang: I18nAvailableLanguage): void {
    this.onLoading.emit(true);
    this.i18nBackendService
      .enforceOrUnforceLanguage$(this.contextUuid, lang.code, lang.isEnforced)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => this.handleLanguageEnforcement(lang));
  }

  onDefaultLangChange(lang: I18nAvailableLanguage): void {
    this.onLoading.emit(true);
    this.i18nBackendService
      .setDefaultLanguage$(this.contextUuid, this.contextType, lang.code)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => this.handleLanguageDefaultStateChange(lang));
  }

  toggleLanguageActiveness(lang: I18nAvailableLanguage): void {
    if (lang.isActive && !lang.validationState.isEverythingCorrect) {
      this.showMissingTranslationInAccount(lang);

      return;
    }

    this.onLoading.emit(true);
    this.i18nBackendService
      .activateOrDeactivateLanguage$(this.contextUuid, this.contextType, lang.code, lang.isActive)
      .pipe(
        takeUntil(this.onDestroy$),
        catchError((error: any) =>
          error.status === CONSTANTS.REQUEST_ERRORS.HTTP_400 ? this.handleDeactivateError(error, lang) : this.showGeneralErrorMsg(error)
        )
      )
      .subscribe(() => this.handleLanguageActivation(lang));
  }

  trackByForLanguage(index: number, language: I18nAvailableLanguage): string {
    return language.code;
  }

  private handleDeactivateError(err: any, lang: I18nAvailableLanguage): Observable<any> {
    return this.usersBackendService.getVenuesList$(this.contextUuid).pipe(
      mergeMap((venues: VenueFromBackend[]) => {
        const venueLinks: IVenueLinkDetails[] = err.error.errorDetails.map((uuid: string) => {
          const venue: VenueFromBackend = venues.find((v: VenueFromBackend) => v.uuid === uuid);

          return {
            venueUuid: uuid,
            venueName: venue.brandName,
          };
        });

        this.languageService.showCannotDeactivateLanguage(venueLinks, lang);
        lang.isActive = true;
        this.onLoading.emit(false);

        return observableThrowError(err);
      })
    );
  }

  private showMissingTranslationInAccount(lang: I18nAvailableLanguage): void {
    const hostUuid: string = this.contextType === CONSTANTS.CONTEXT.ACCOUNT ? this.contextUuid : this.accountUuid;

    setTimeout(() => {
      lang.isActive = false;
      this.cdf.detectChanges();
    }, CONSTANTS.TIME_IN_MS.TIME_500);
    this.languageService.showMissingTranslationInAccount(hostUuid, lang);
  }

  private handleLanguageEnforcement(lang: I18nAvailableLanguage): void {
    this.showSuccessMessage(`ACCOUNT.FOLDER.LANGUAGES.MODALS.${lang.isEnforced ? 'RESULT_SET_ENFORCED' : 'RESULT_SET_NOT_ENFORCED'}`, {
      language: lang.localizedLanguageName,
    });
    this.onLoading.emit(false);
  }

  private handleLanguageDefaultStateChange(lang: I18nAvailableLanguage): void {
    this.showSuccessMessage('VENUE.FOLDER.LANGUAGES.MODALS.RESULT_SET_DEFAULT', { language: lang.localizedLanguageName });
    this.isResetDefaultLanguage();
    lang.isDefault = true;
    this.onLoading.emit(false);
    this.languageService.setDefaultLanguage(lang);
  }

  private handleLanguageActivation(lang: I18nAvailableLanguage): void {
    this.showSuccessMessage(`${this.contextType.toUpperCase()}.FOLDER.LANGUAGES.MODALS.${lang.isActive ? 'RESULT_ENABLE' : 'RESULT_DISABLE'}`, {
      language: lang.localizedLanguageName,
      venueName: this.venueName
    });

    if (this.isOneActiveLanguage()) {
      this.disableLastActiveLanguage();
    } else {
      this.enableAllLanguages();
    }

    this.handleEnforceAfterDeactivation(lang);
    this.onLoading.emit(false);
  }

  private handleEnforceAfterDeactivation(lang: I18nAvailableLanguage): void {
    if (!lang.isActive && lang.isEnforced && this.contextType === CONSTANTS.CONTEXT.ACCOUNT) {
      lang.isEnforced = false;
      this.handleLanguageEnforcement(lang);
    }
  }

  private loadAccountLanguagesAndData(): void {
    this.getAccountInfo$(this.contextUuid)
      .pipe(
        mergeMap((accountData: Pick<AccountForBackend, 'uuid'>) => {
          this.contextParentUuid = accountData.uuid;
          forkJoin(this.getAllContextLanguages());

          return forkJoin([
            ...this.getAllContextLanguages(),
            this.i18nBackendService.getSupportedLanguages$(this.contextParentUuid, CONSTANTS.CONTEXT.ACCOUNT),
          ]);
        })
      )
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        ([allSupportedLanguages, availableLanguagesForAccount, translationValidation, parentLanguagesForAccount]: [
          I18nSupportedLanguage[],
          I18nSupportedLanguage[],
          I18nValidtion[],
          I18nSupportedLanguage[]
        ]) => {
          this.setLanguageList(allSupportedLanguages, availableLanguagesForAccount, translationValidation, parentLanguagesForAccount);
        }
      );
  }

  private getAccountInfo$(venueUuid: string): Observable<Pick<AccountForBackend, 'uuid'>> {
    return this.usersBackendService.getSingleVenue$(venueUuid).pipe(
      mergeMap((venueResponse: VenueFromBackend) => {
        this.accountUuid = venueResponse.accountUuid;

        return this.usersBackendService.getSingleAccount$(venueResponse.accountUuid);
      }),
      map((accountResponse: AccountForBackend) => ({
        uuid: accountResponse.uuid,
      }))
    );
  }

  private loadAvailableLanguages(): void {
    this.onLoading.emit(true);
    forkJoin(this.getAllContextLanguages())
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        ([allSupportedLanguages, availableLanguagesForAccount, translationStatus]: [
          I18nSupportedLanguage[],
          I18nSupportedLanguage[],
          I18nValidtion[]
        ]) => {
          this.setLanguageList(allSupportedLanguages, availableLanguagesForAccount, translationStatus);
        }
      );
  }

  private getAllContextLanguages(): [
    Observable<I18nSupportedLanguage[]>,
    Observable<I18nSupportedLanguage[]>,
    Observable<I18nValidtion[]>
  ] {
    return [
      this.i18nBackendService.getAllSupportedLanguages$(),
      this.i18nBackendService.getSupportedLanguages$(this.contextUuid, this.contextType),
      this.i18nBackendService.getLanguageValidationStatus$(this.contextUuid, this.contextType),
    ];
  }

  private setLanguageList(
    allSupportedLanguages: I18nSupportedLanguage[],
    availableLanguagesForAccount: I18nSupportedLanguage[],
    translationStatus: I18nValidtion[],
    parentLanguages?: I18nSupportedLanguage[]
  ): void {
    this.languagesList = allSupportedLanguages
      .map((supportedLang: I18nSupportedLanguage) => {
        const savedLanguage: I18nSupportedLanguage = availableLanguagesForAccount.find(
          (language: I18nSupportedLanguage) => language.code === supportedLang.code
        );
        const validationState: I18nValidtion = translationStatus.find(
          (langValidation: I18nValidtion) => langValidation.language === supportedLang.code
        );

        validationState.isEverythingCorrect = this.isEverythingTranslated(validationState);

        return {
          validationState,
          code: supportedLang.code,
          localizedLanguageName: this.i18nBackendService.getTranslatedLanguageName(supportedLang.code),
          isActive: savedLanguage ? (savedLanguage.isEnforced ? true : savedLanguage.isActive) : false,
          isDefault: savedLanguage ? savedLanguage.isDefault : false,
          isEnforced: savedLanguage ? savedLanguage.isEnforced : false,
          isBlocked: parentLanguages ? this.isVenueLanguageDisable(supportedLang, parentLanguages) : false,
        };
      })
      .sort((a: I18nSupportedLanguage, b: I18nSupportedLanguage) =>
        a.localizedLanguageName > b.localizedLanguageName ? 1 : b.localizedLanguageName > a.localizedLanguageName ? -1 : 0
      );

    try {
      this.defaultLanguageCode = this.languageService.getDefaultLanguage().code;
    } catch (e) {
      console.error('Missing isDefault settings flag!');
    }

    this.onLoading.emit(false);
  }

  private isEverythingTranslated(validationState: I18nValidtion): boolean {
    return (
      validationState.coverage.foodbackQuestion.isTranslated &&
      validationState.coverage.oneMoreQuestion.isTranslated &&
      validationState.coverage.campaign.isTranslated
    );
  }

  private isVenueLanguageDisable(selectedLang: I18nAvailableLanguage, parentLanguages: I18nSupportedLanguage[]): boolean {
    const parentLang: I18nSupportedLanguage = parentLanguages.find(
      (language: I18nSupportedLanguage) => language.code === selectedLang.code
    );

    return !parentLang || (parentLang && parentLang.isEnforced) || (parentLang && !parentLang.isActive);
  }

  private enableAllLanguages(): void {
    this.languagesList.forEach((language: I18nAvailableLanguage) => (language.isDisabled = false));
  }

  private disableLastActiveLanguage(): void {
    const activeLang: I18nAvailableLanguage = this.languagesList.find((lang: I18nAvailableLanguage) => lang.isActive);

    activeLang.isDisabled = true;
  }

  private isOneActiveLanguage(): boolean {
    return this.languagesList.filter((lang: I18nAvailableLanguage) => lang.isActive).length === 1;
  }

  private isResetDefaultLanguage(): void {
    this.languagesList.forEach((lang: I18nAvailableLanguage) => (lang.isDefault = false));
  }

  private showSuccessMessage(translationKey: string, params?: any): void {
    this.notificationService.successWithTranslationKey(translationKey, params);
  }

  private showGeneralErrorMsg(error: any): Observable<any> {
    this.notificationService.errorWithTranslationKey('VENUE.FOLDER.PAYMENT.ORDER.ALIPAY.RESULT_FAIL');

    return observableThrowError(error);
  }
}
