<table class="GroupsList table table-striped table-hover fb-table-management-list table-fixed">
  <thead>
    <tr>
      <th>{{ 'SHARED.SYSTEM.CATEGORY' | translate }}</th>
      <th>{{ 'SHARED.SYSTEM.GROUPS' | translate }}</th>
      <th>
        <span>{{ 'SHARED.SYSTEM.CHILDREN_NAME_PLURAL' | translate: { childrenName: accountChildrenNamePlural } }}</span>
      </th>
      <th [style.width.px]="70"></th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let category of getGroupsWithCategories$() | async; trackBy: trackGroup">
      <tr>
        <td>
          <b>{{ category.name }}</b>
        </td>
        <td></td>
        <td class="text-right">
          <b>{{ category.venuesCount | number: CONSTANTS.NUMBER_PIPE.INTEGERS }}</b>
        </td>
        <td class="text-right">
          <ng-container *ngIf="category.uuid && category.uuid !== CONSTANTS.GROUPS.NO_CATEGORY_UUID">
            <i (click)="onSelectedGroup.emit(category)" class="mdi mdi-18px mdi-pencil clickable-icon"></i>
            <i (click)="removeGroup(category)" class="mdi mdi-18px mdi-delete clickable-icon"></i>
          </ng-container>
        </td>
      </tr>
      <ng-container *ngIf="category.venueGroups">
        <tr *ngFor="let group of category.venueGroups | sortByField: 'name':true">
          <td></td>
          <td>{{ group.name }}</td>
          <td class="text-right">{{ group.venuesCount | number: CONSTANTS.NUMBER_PIPE.INTEGERS }}</td>
          <td class="text-right">
            <i (click)="onSelectedGroup.emit(group)" class="mdi mdi-18px mdi-pencil clickable-icon"></i>
            <i (click)="removeGroup(group)" class="mdi mdi-18px mdi-delete clickable-icon"></i>
          </td>
        </tr>
      </ng-container>
    </ng-container>
  </tbody>
</table>

<div *ngIf="!(getGroupsWithCategories$() | async)?.length">{{ 'SHARED.SYSTEM.NO_RESULTS' | translate }}</div>
