import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { VENUE_SETTINGS_ROUTES_PATHS_FULL } from '../../../components/venue/settings/ven-settings-routes-paths';
import { VENUE_ROUTES_PATHS_FULL } from '../../../components/venue/venue-routes-paths';
import { CONSTANTS } from '../../../shared/constants';
import { Dictionary } from '../../../shared/interfaces/common.interface';
import { RouteBuilderService } from '../backend/route-builder.service';

@Injectable()
export class VenueNavigationService {
  constructor(private router: Router, private routeBuilderService: RouteBuilderService) {}

  goToSettingsDetails(venueUuid: string): Promise<any> {
    return this.navigateWithVenueUuid(VENUE_SETTINGS_ROUTES_PATHS_FULL.DETAILS, venueUuid);
  }

  goToAddUserForm(venueUuid: string): Promise<any> {
    return this.navigateWithVenueUuid(VENUE_SETTINGS_ROUTES_PATHS_FULL.USERS.CREATE, venueUuid);
  }

  goToUsersList(venueUuid: string): Promise<any> {
    return this.navigateWithVenueUuid(VENUE_SETTINGS_ROUTES_PATHS_FULL.USERS.LIST, venueUuid);
  }

  goToNotificationsList(venueUuid: string): Promise<any> {
    return this.navigateWithVenueUuid(VENUE_ROUTES_PATHS_FULL.NOTIFICATIONS.LIST, venueUuid);
  }

  private navigateWithVenueUuid(route: string, venueUuid: string): Promise<any> {
    return this.navigateWithParams(route, {
      [CONSTANTS.ROUTE_PARAMS.VENUE_ID]: venueUuid,
    });
  }

  private navigateWithParams(route: string, params: Dictionary<string>): Promise<any> {
    return this.router.navigate([this.routeBuilderService.getAbsoluteRoute(route, params)]);
  }
}
