import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment-timezone';
import { FileUploader } from 'ng2-file-upload';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AvailableLanguages } from '../../../../core/providers/translator/language.contants';
import { CONSTANTS } from '../../../constants';
import { CustomValidators } from '../../../custom-validators';
import { GeneralErrors } from '../../../form-models';

@Component({
  selector: 'app-advertisement-form',
  templateUrl: './advertisement-form.component.html',
  styleUrls: ['./advertisement-form.component.scss'],
})
export class AdvertisementFormComponent implements OnInit, OnDestroy {
  @Input() set advertisementURL(value: string) {
    if (value) {
      this.advertisementForm.controls.advertisementURLCtrl.setValue(!value ? '' : value);
      this.advertisementForm.controls.isAdvertisementEnabled.setValue(true);
    }
  }

  @Input() uploader: FileUploader;
  @Input() avatarURL: string;
  @Input() imageLoading: boolean;
  @Input() hasBaseDropZoneOver: boolean;
  @Input() advertisementLastChangeData: string;
  @Input() timezone: string;
  @Output() readonly onToggleURL: EventEmitter<boolean> = new EventEmitter();
  @Output() readonly onBackToDefault: EventEmitter<null> = new EventEmitter();
  @Output() readonly onFileOverBase: EventEmitter<any> = new EventEmitter();
  @Output() readonly onURLChange: EventEmitter<string> = new EventEmitter();
  @Output() readonly onDisableSubmitButton: EventEmitter<boolean> = new EventEmitter();
  errors = GeneralErrors;
  @ViewChild('selectedPicture', { static: true }) selectedPicture: any;
  advertisementForm: FormGroup;
  language: string;
  private onDestroy = new Subject<void>();

  constructor(private formBuilder: FormBuilder, private translateService: TranslateService) {}

  ngOnInit(): void {
    this.language = this.translateService.getDefaultLang();
    this.advertisementForm = this.formBuilder.group({
      advertisementURLCtrl: new FormControl({ value: '', disabled: true }, [CustomValidators.url]),
      isAdvertisementEnabled: new FormControl(false),
    });
    this.advertisementForm.controls.advertisementURLCtrl.valueChanges.pipe(takeUntil(this.onDestroy)).subscribe((value: string) => {
      this.onDisableSubmitButton.emit(!this.advertisementForm.controls.advertisementURLCtrl.valid);

      if (value && value !== '' && this.advertisementForm.controls.advertisementURLCtrl.valid) {
        this.onURLChange.emit(value);
      }
    });
    this.advertisementForm.controls.isAdvertisementEnabled.valueChanges.pipe(takeUntil(this.onDestroy)).subscribe((value: boolean) => {
      if (value) {
        this.advertisementForm.controls.advertisementURLCtrl.enable();
      } else {
        this.advertisementForm.controls.advertisementURLCtrl.disable();
      }

      this.onToggleURL.emit(value);
    });
  }

  backToDefault() {
    this.selectedPicture.nativeElement.value = '';
    this.advertisementLastChangeData = null;
    this.onBackToDefault.emit(null);
    this.advertisementForm.controls.isAdvertisementEnabled.setValue(true);
    this.advertisementForm.controls.advertisementURLCtrl.setValue('https://www.foodback.com');
  }

  getAdvertisementLastChangeDataFormat(): string {
    if (!this.advertisementLastChangeData) {
      return null;
    }
    const lastChangeDate: Date = new Date(this.advertisementLastChangeData);

    return moment.utc(lastChangeDate).tz(this.timezone).format(CONSTANTS.DATES_FORMAT.QUERY_PARAM_DATE);
  }

  getAdvertisementLastChangeHourFormat(): string {
    if (!this.advertisementLastChangeData) {
      return null;
    }
    const lastChangeDate: Date = new Date(this.advertisementLastChangeData);

    return moment(lastChangeDate).tz(this.timezone).format('HH:mm');
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }
}
