import { OverlayRef } from '@angular/cdk/overlay';
import { TemplateRef, Type } from '@angular/core';
import { Subject } from 'rxjs';
import { IMultiSelectTagItem } from '../../../../core/interfaces/multi-select-tag-item.interface';
import { CONSTANTS } from '../../../constants';

export interface MultiSelectTagListEvent<T = any> {
  type: 'backdropClick' | 'close';
}
export type MultiSelectTagListContent = TemplateRef<any> | Type<any> | string;
export class MultiSelectTagListRef<T = any> {
  afterClosed$;
  afterSelected$;
  private afterClosed = new Subject<MultiSelectTagListEvent<T>>();
  private afterSelected = new Subject<IMultiSelectTagItem>();

  constructor(public overlay: OverlayRef, public content: MultiSelectTagListContent) {
    this.afterClosed$ = this.afterClosed.asObservable();
    this.afterSelected$ = this.afterSelected.asObservable();
    overlay.backdropClick().subscribe(() => {
      this._close('backdropClick');
    });
  }

  select(item: IMultiSelectTagItem) {
    this.afterSelected.next(item);
    this.updatePosition(CONSTANTS.TIME_IN_MS.TIME_500);
  }

  updatePosition(delay = 100) {
    setTimeout(() => {
      this.overlay.updatePosition();
    }, delay);
  }

  close() {
    this._close('close');
  }

  private _close(type: MultiSelectTagListEvent['type']) {
    this.overlay.dispose();
    this.afterClosed.next({
      type,
    });
    this.afterClosed.complete();
  }
}
