<div class="login" [style.background-image]="backgroundUrl">
  <div
    class="login-photoAuthor"
    *ngIf="backgroundPhotoAuthorName"
    [innerHTML]="
      'LOGIN.UNSPLASH_ATTRIBUTION'
        | translate
          : {
              link: backgroundPhotoAuthorLink + '?utm_source=Foodback&utm_medium=referral',
              name: backgroundPhotoAuthorName
            }
    "></div>
  <div id="login-container">
    <!--
        Auth-lock is looking for a container to be placed in. In case there is no container in auth options,
        auth adds automatically 'auth0-lock-container' container to the DOM. I used a new id to have at least control
        where the container physically is to place expire message above it.
      -->
    <div id="login-auth-container"></div>
  </div>
</div>
