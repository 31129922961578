import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { DateRange as MomentDateRange } from 'moment-range';
import { DateGroupPickerValue } from '../../../shared/components/date-range-picker';
import { DynamicDateRange } from '../../../shared/interfaces/date-range.interface';
import { ChartAllTimeRangeService } from './chart-all-time-range.service';
import { ChartHourRangeService } from './chart-hour-range.service';
import { ChartMonthDayRangeService } from './chart-month-day-range.service';
import { ChartRangeGenerator } from './chart-range-generator.interface';

@Injectable()
export class ChartCustomRangeService {
  private static readonly BREAKPOINTS = {
    DAY_MAX: 1,
    MONTH_MAX: 90,
  };

  static createRangeGenerator(range: MomentDateRange): ChartRangeGenerator {
    const days = Math.abs(range.start.diff(range.end, 'day'));

    if (days <= ChartCustomRangeService.BREAKPOINTS.DAY_MAX) {
      return new ChartHourRangeService(range);
    }
    if (days > ChartCustomRangeService.BREAKPOINTS.DAY_MAX && days <= ChartCustomRangeService.BREAKPOINTS.MONTH_MAX) {
      return new ChartMonthDayRangeService(range);
    }

    return new ChartAllTimeRangeService(range);
  }

  static getNarrowedDateRange(day: Date, dateRange: DynamicDateRange): DynamicDateRange {
    const days = Math.abs(moment(dateRange.start).diff(dateRange.end, 'day'));
    const interval = days > ChartCustomRangeService.BREAKPOINTS.MONTH_MAX ? DateGroupPickerValue.MONTH : DateGroupPickerValue.DAY;

    return {
      start: moment(day).startOf(interval).toDate(),
      end: moment(day).endOf(interval).toDate(),
      groupBy: interval,
    };
  }
}
