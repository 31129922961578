import { Injectable } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { ACCOUNT_SETTINGS_ROUTES_PATHS_FULL } from '../../../../components/account/settings/acc-settings-routes-paths';
import { RouteBuilderService } from '../../../../core/providers/backend/route-builder.service';
import { UtilsService } from '../../../../core/utils/utils.service';
import { CONSTANTS } from '../../../constants';
import { CustomValidators } from '../../../custom-validators';
import { IDropdownItem } from '../../../interfaces/dropdown/dropdown-item.interface';
import { StatementTranslationFromBackend } from '../../../interfaces/statements.interface';
import { QuestionType } from '../../../interfaces/survey.interface';
import { IOneMoreQuestionOptionFormat } from '../one-more-question-edit/one-more-question-format/one-more-question.format.interface';
import { OneMoreQuestionValidator } from '../one-more-question-edit/one-more-question-validator';
import {
  OneMoreQuestionTimePeriod,
  OneMoreQuestionWhenToAsk,
  OneMoreQuestionWhenToAskUnit,
} from '../one-more-question-edit/one-more-question.interface';
import {
  IOneMoreBasic,
  IOneMoreFollowQuestion,
  IOneMoreQuestionFormValue,
  IOneMoreQuestionGeneralBody,
  IOneMoreQuestionOptionBackend,
  IOneMoreQuestionReconfiguration,
  IOneMoreQuestionReconfigurationTranslation,
  IOneMoreQuestionTransResponseDialog,
  IOneMoreQuestionTranslation,
  IOneMoreQuestionTranslationFromBackend,
  IQuestionFormatRawValue,
} from '../one-more-question/one-more-question.interface';

const staticOptionCountBoolean = 2;
const staticOptionCountScale = 6;

@Injectable()
export class OneMoreQuestionService {
  onOneMoreQuestionListChange$: Subject<void> = new Subject<void>();
  onSubmit$: Subject<void> = new Subject<void>();

  constructor(
    private router: Router,
    private routeBuilderService: RouteBuilderService,
    private translateService: TranslateService,
    private formBuilder: FormBuilder,
    private utilsService: UtilsService
  ) {}

  /**
   * Get url to question's edit mode page
   * @param contextUuid
   * @param questionUuid
   */
  getEditUrl(contextUuid: string, questionUuid: string): void {
    const path: string =
      ACCOUNT_SETTINGS_ROUTES_PATHS_FULL.ONE_MORE_QUESTION.EDIT;
    const contextId: string = CONSTANTS.ROUTE_PARAMS.ACCOUNT_ID;

    this.router.navigate([
      this.routeBuilderService.getAbsoluteRoute(
        `${path}/:${CONSTANTS.ROUTE_PARAMS.QUESTION_ID}`,
        {
          [contextId]: contextUuid,
          [CONSTANTS.ROUTE_PARAMS.QUESTION_ID]: questionUuid,
        }
      ),
    ]);
  }

  /**
   * Return all types of time period - when to ask value
   */
  getWhenToAskItems(): IDropdownItem[] {
    return [
      {
        id: OneMoreQuestionWhenToAsk.EVERY_TIME,
        text: this.translateService.instant('SHARED.SYSTEM.EVERY_TIME'),
      },
      {
        id: OneMoreQuestionWhenToAsk.ONLY_X_TIME_WEEKS,
        text: this.translateService.instant('SHARED.SYSTEM.ONLY_X_TIME_WEEKS'),
      },
      {
        id: OneMoreQuestionWhenToAsk.ONLY_X_TIME_MONTHS,
        text: this.translateService.instant('SHARED.SYSTEM.ONLY_X_TIME_MONTHS'),
      },
      {
        id: OneMoreQuestionWhenToAsk.ONLY_ONCE,
        text: this.translateService.instant('SHARED.SYSTEM.ONLY_ONCE'),
      },
    ];
  }

  getTimePeriodItems(): IDropdownItem[] {
    return [
      {
        id: OneMoreQuestionTimePeriod.ONGOING,
        text: this.translateService.instant('SHARED.SYSTEM.ONGOING'),
      },
      {
        id: OneMoreQuestionTimePeriod.TIME_FRAME,
        text: this.translateService.instant(
          'SHARED.COMPONENTS.DATE_PICKER.TIME_FRAME'
        ),
      },
    ];
  }

  getOneMoreQuestionForm(): FormGroup {
    const maxWhenToAskCount = 5;

    return this.formBuilder.group({
      isActive: [false, [Validators.required]],
      isEnforced: [false],
      question: [
        '',
        [
          Validators.required,
          Validators.maxLength(
            CONSTANTS.VALIDATION.ONE_MORE_QUESTION.QUESTION_MAX_LENGTH
          ),
          CustomValidators.noWhitespaceValidator,
        ],
      ],
      whenToAsk: [null, [Validators.required]],
      whenToAskValue: [
        null,
        [Validators.min(1), Validators.max(maxWhenToAskCount)],
      ],
      activePeriod: [null, [Validators.required]],
      between: this.formBuilder.group({
        dateFrom: [null],
        dateTo: [null],
      }),
      questionFormat: [
        null,
        [
          Validators.required,
          Validators.maxLength(
            CONSTANTS.VALIDATION.ONE_MORE_QUESTION.QUESTION_MAX_LENGTH
          ),
          OneMoreQuestionValidator.questionFormat,
        ],
      ],
      askFollowQuestion: [false, [Validators.required]],
      followQuestion: [''],
      isTriggeredAlways: [true],
      triggerStaticNumber: [0],
      triggerOptions: this.formBuilder.array([]),
      followQuestionFormat: [null],
    });
  }

  preparePayload(
    formValue: IOneMoreQuestionFormValue,
    uuid: string,
    lang: string
  ): IOneMoreQuestionGeneralBody {
    return {
      uuid,
      type: formValue.questionFormat
        ? formValue.questionFormat.formatType
        : null,
      isActive: formValue.isActive,
      isEnforced: formValue.isEnforced,
      activeFrom: formValue.between.dateFrom
        ? this.utilsService.prepareDateForRequest(
            new Date(formValue.between.dateFrom)
          )
        : null,
      activeTo: formValue.between.dateTo
        ? this.utilsService.prepareDateForRequest(
            new Date(formValue.between.dateTo)
          )
        : null,
      whenToAsk:
        formValue.whenToAsk &&
        formValue.whenToAskValue &&
        (formValue.whenToAsk.id ===
          OneMoreQuestionWhenToAsk.ONLY_X_TIME_WEEKS ||
          formValue.whenToAsk.id ===
            OneMoreQuestionWhenToAsk.ONLY_X_TIME_MONTHS)
          ? this.getWhenToAskPayloadValue(
              formValue.whenToAskValue,
              formValue.whenToAsk.id as OneMoreQuestionWhenToAsk
            )
          : formValue.whenToAsk &&
            formValue.whenToAsk.id === OneMoreQuestionWhenToAsk.ONLY_ONCE
          ? '1'
          : null,
      options: formValue.questionFormat
        ? this.getFormatOptionsFromForm(
            formValue.questionFormat.options,
            lang
          ).map((option: IOneMoreQuestionOptionBackend, index: number) => ({
            ...option,
            isTrigger: formValue.triggerOptions[index],
          }))
        : [],
      translations:
        formValue.question !== ''
          ? [this.prepareTranslationItem(lang, formValue.question)]
          : [],
      followUpQuestions: formValue.askFollowQuestion
        ? [
            {
              type: formValue.followQuestionFormat
                ? formValue.followQuestionFormat.formatType
                : null,
              isEnabled: formValue.askFollowQuestion,
              options: formValue.followQuestionFormat
                ? this.getFormatOptionsFromForm(
                    formValue.followQuestionFormat.options,
                    lang
                  )
                : null,
              translations:
                formValue.askFollowQuestion && formValue.followQuestion !== ''
                  ? [
                      this.prepareTranslationItem(
                        lang,
                        formValue.followQuestion
                      ),
                    ]
                  : [],
            },
          ]
        : [],
    };
  }

  prepareReconfigurationPayload(
    questionBody: IOneMoreQuestionGeneralBody,
    formValue: IOneMoreQuestionFormValue
  ): IOneMoreQuestionReconfiguration {
    let translations: IOneMoreQuestionReconfigurationTranslation[] = [];

    translations = [
      ...translations,
      ...this.getTranslationSetReconfigFromQuestion(questionBody),
    ];

    if (
      questionBody.followUpQuestions &&
      questionBody.followUpQuestions.length > 0
    ) {
      questionBody.followUpQuestions.forEach(
        (followQuestion: IOneMoreFollowQuestion) => {
          translations = [
            ...translations,
            ...this.getTranslationSetReconfigFromQuestion(followQuestion),
          ];
        }
      );
    }

    return {
      translations,
      isActive: formValue.isActive,
      activeFrom: formValue.between.dateFrom
        ? this.utilsService.prepareDateForRequest(
            new Date(formValue.between.dateFrom)
          )
        : null,
      activeTo: formValue.between.dateTo
        ? this.utilsService.prepareDateForRequest(
            new Date(formValue.between.dateTo)
          )
        : null,
      whenToAsk:
        formValue.whenToAsk &&
        formValue.whenToAskValue &&
        (formValue.whenToAsk.id ===
          OneMoreQuestionWhenToAsk.ONLY_X_TIME_WEEKS ||
          formValue.whenToAsk.id ===
            OneMoreQuestionWhenToAsk.ONLY_X_TIME_MONTHS)
          ? this.getWhenToAskPayloadValue(
              formValue.whenToAskValue,
              formValue.whenToAsk.id as OneMoreQuestionWhenToAsk
            )
          : formValue.whenToAsk &&
            formValue.whenToAsk.id === OneMoreQuestionWhenToAsk.ONLY_ONCE
          ? '1'
          : null,
    };
  }

  setTimeUnitsValidators(
    formGroup: FormGroup,
    validators: ValidatorFn | ValidatorFn[]
  ): void {
    this.updateValidator(formGroup, 'whenToAskValue', validators);
  }

  setTimeRangeValidators(formGroup: FormGroup, validator: ValidatorFn): void {
    this.updateValidator(
      formGroup,
      'between',
      validator
        ? [
            validator,
            CustomValidators.dateRange,
            CustomValidators.dateRangeValue,
          ]
        : null
    );
  }

  setFollowQuestionValidators(
    formGroup: FormGroup,
    validators: ValidatorFn[]
  ): void {
    this.updateValidator(
      formGroup,
      'followQuestion',
      validators ? [...validators, CustomValidators.noWhitespaceValidator] : []
    );
    this.updateValidator(
      formGroup,
      'followQuestionFormat',
      validators ? [...validators, OneMoreQuestionValidator.questionFormat] : []
    );
  }

  updateValidator(
    formGroup: FormGroup,
    controlName: string,
    validators: ValidatorFn | ValidatorFn[]
  ): void {
    formGroup.get(controlName).setValidators(validators);
    formGroup.get(controlName).updateValueAndValidity();
  }

  getWhenToAskFormValue(
    question: IOneMoreQuestionGeneralBody
  ): OneMoreQuestionWhenToAsk {
    if (question.whenToAsk) {
      return question.whenToAsk === '1'
        ? OneMoreQuestionWhenToAsk.ONLY_ONCE
        : this.getWhenToAskUnit(question.whenToAsk) ===
          OneMoreQuestionWhenToAskUnit.WEEKS
        ? OneMoreQuestionWhenToAsk.ONLY_X_TIME_WEEKS
        : OneMoreQuestionWhenToAsk.ONLY_X_TIME_MONTHS;
    }

    return OneMoreQuestionWhenToAsk.EVERY_TIME;
  }

  getQuestionFormatRawValue(
    questionForm: IOneMoreQuestionGeneralBody | IOneMoreFollowQuestion,
    lang: string
  ): IQuestionFormatRawValue {
    const options: IOneMoreQuestionOptionFormat[] = [];

    if (
      questionForm.type !== QuestionType.BOOLEAN &&
      questionForm.type !== QuestionType.SCALE &&
      questionForm.options &&
      questionForm.options.length > 0
    ) {
      questionForm.options.forEach((option: IOneMoreQuestionOptionBackend) =>
        options.push({
          optionValue: option.translations.find(
            (translation: StatementTranslationFromBackend) =>
              translation.lang === lang
          ).value,
        })
      );
    }

    return {
      options,
      formatType: questionForm.type,
    };
  }

  prepareTranslationItem(
    lang: string,
    value: string
  ): StatementTranslationFromBackend {
    return { lang, value };
  }

  checkAndUpdateTranslationPayload(
    payload: IOneMoreQuestionGeneralBody,
    translations: IOneMoreQuestionTransResponseDialog
  ): IOneMoreQuestionGeneralBody {
    if (payload && translations) {
      payload.translations = translations.questionTranslations;

      if (payload.options) {
        payload.options.forEach(
          (option: IOneMoreQuestionOptionBackend, index: number) => {
            option.translations =
              translations.questionOptionsTranslations[
                index
              ].optionTranslations;
          }
        );
      }
      if (payload.followUpQuestions && payload.followUpQuestions.length > 0) {
        payload.followUpQuestions[0].translations =
          translations.followQuestionTranslations;

        if (
          payload.followUpQuestions[0] &&
          payload.followUpQuestions[0].options
        ) {
          payload.followUpQuestions[0].options.forEach(
            (option: IOneMoreQuestionOptionBackend, index: number) => {
              option.translations =
                translations.followQuestionOptionsTranslations[
                  index
                ].optionTranslations;
            }
          );
        }
      }
    }

    return payload;
  }

  getWhenToAskPayloadValue(
    whenToAskValue: number,
    whenToAskUnit: OneMoreQuestionWhenToAsk
  ): string {
    return `${whenToAskValue}${
      whenToAskUnit === OneMoreQuestionWhenToAsk.ONLY_X_TIME_WEEKS ? 'W' : 'M'
    }`;
  }

  getWhenToAskUnit(whenToAsk: string): OneMoreQuestionWhenToAskUnit {
    return whenToAsk.slice(-1) as OneMoreQuestionWhenToAskUnit;
  }

  getWhenToAskValue(whenToAsk: string): number {
    return +whenToAsk.slice(0, -1);
  }

  getTriggerValues(options: IOneMoreQuestionOptionBackend[]): boolean[] {
    return options.map(
      (option: IOneMoreQuestionOptionBackend) => option.isTrigger
    );
  }

  getTriggerStaticValues(question: IOneMoreQuestionGeneralBody): number {
    return question.options.findIndex(
      (option: IOneMoreQuestionOptionBackend, index) =>
        question.followUpQuestions.length === 0 ? index === 0 : option.isTrigger
    );
  }

  isTriggeredAlways(options: IOneMoreQuestionOptionBackend[]): boolean {
    return this.getTriggerValues(options).every(
      (optionValue: boolean) => optionValue === true
    );
  }

  getFormatOptionsFromForm(
    options: IOneMoreQuestionOptionFormat[],
    lang: string
  ): IOneMoreQuestionOptionBackend[] {
    return options.map((option: IOneMoreQuestionOptionFormat) => ({
      translations:
        option.optionValue === '' ? [] : [{ lang, value: option.optionValue }],
    }));
  }

  blockInputsWhenAreAnswers(form: FormGroup): void {
    form.disable();
    form.controls.whenToAsk.enable();
    form.controls.whenToAskValue.enable();
    form.controls.activePeriod.enable();
    form.controls.between.enable();
  }

  goToPageList(accountUuid: string): void {
    this.router.navigate([
      this.routeBuilderService.getAbsoluteRoute(
        ACCOUNT_SETTINGS_ROUTES_PATHS_FULL.ONE_MORE_QUESTION.BASE_PATH,
        {
          [CONSTANTS.ROUTE_PARAMS.ACCOUNT_ID]: accountUuid,
        }
      ),
    ]);
  }

  createTriggerOptions(form: FormGroup): boolean {
    const formatValue: {
      formatType: QuestionType;
      options: IOneMoreQuestionOptionFormat[];
    } = form.controls.questionFormat.value;
    const triggerOptions: FormArray = form.controls.triggerOptions as FormArray;
    const triggerOptionsValues: boolean[] = triggerOptions.value;

    triggerOptions.controls = [];
    triggerOptions.setValue([], { emitEvent: false });

    if (
      formatValue.formatType === QuestionType.BOOLEAN ||
      formatValue.formatType === QuestionType.SCALE
    ) {
      this.setStaticTriggerOptions(triggerOptions, formatValue.formatType);

      return true;
    }
    if (formatValue.options && formatValue.options.length > 0) {
      formatValue.options.forEach(
        (val: IOneMoreQuestionOptionFormat, index: number) =>
          triggerOptions.push(
            new FormControl(
              triggerOptionsValues.length > index
                ? triggerOptionsValues[index]
                : false
            )
          )
      );
    }
    if (formatValue.formatType === QuestionType.FREE_TEXT) {
      form.controls.askFollowQuestion.setValue(false);
    }

    return false;
  }

  createTriggerOptionsFromValue(
    form: FormGroup,
    formValue: IOneMoreQuestionFormValue
  ): void {
    if (formValue.triggerOptions && formValue.triggerOptions.length > 0) {
      for (let i = 0; i < formValue.triggerOptions.length; i++) {
        (form.controls.triggerOptions as FormArray).push(new FormControl());
      }
    }
  }

  getOnlyNewTranslation(
    translation: IOneMoreQuestionTranslationFromBackend[]
  ): IOneMoreQuestionTranslationFromBackend[] {
    return translation.filter(
      (trans: IOneMoreQuestionTranslationFromBackend) => trans.isNewTranslation
    );
  }

  private getTranslationSetReconfigFromQuestion(
    question: IOneMoreBasic
  ): IOneMoreQuestionReconfigurationTranslation[] {
    const payloadTranslations: IOneMoreQuestionReconfigurationTranslation[] =
      [];

    payloadTranslations.push(this.getTranslationForReconfiguration(question));

    if (question.options && question.options.length > 0) {
      question.options.forEach((option: IOneMoreQuestionOptionBackend) =>
        payloadTranslations.push(this.getTranslationForReconfiguration(option))
      );
    }

    return payloadTranslations.filter(
      (questionTrans: IOneMoreQuestionReconfigurationTranslation) =>
        questionTrans.translations.length > 0
    );
  }

  private getTranslationForReconfiguration(
    translation: IOneMoreQuestionTranslation
  ): IOneMoreQuestionReconfigurationTranslation {
    return {
      key: translation.uuid,
      translations: translation.translations
        .filter(
          (trans: IOneMoreQuestionTranslationFromBackend) =>
            trans.isNewTranslation
        )
        .map((trans: IOneMoreQuestionTranslationFromBackend) => ({
          lang: trans.lang,
          value: trans.value,
        })),
    };
  }

  private setStaticTriggerOptions(
    triggerOptions: FormArray,
    formatType: QuestionType
  ): void {
    const optionCount: number =
      formatType === QuestionType.BOOLEAN
        ? staticOptionCountBoolean
        : staticOptionCountScale;

    for (let i = 0; i < optionCount; i++) {
      triggerOptions.push(new FormControl());
    }
  }
}
