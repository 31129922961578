import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PeriodPerformanceChartInput } from '../period-performance-chart/period-performance-chart.interface';
import { DayByDayChartInput } from './day-by-day-chart.interface';

@Component({
  selector: 'app-day-by-day-chart',
  templateUrl: './day-by-day-chart.component.html',
  styleUrls: ['./day-by-day-chart.component.scss'],
})
export class DayByDayChartComponent {
  @Input() set data(val: DayByDayChartInput) {
    if (val) {
      this.dataForChart = {
        currentScores: val.currentScores.map(score => ({
          value: score.value,
          rangeName: this.translate.instant(score.dayOfWeek),
        })),
        currentCount: val.currentCount.map(count => ({
          value: count.value,
          rangeName: this.translate.instant(count.dayOfWeek),
        })),
      };
    }
  }

  @Input() goal: number;
  @Input() printMode: boolean;
  @Input() translateKey?: string = 'HOME.OVERVIEW.PERFORMANCE_CHART.SERIES';
  dataForChart: PeriodPerformanceChartInput;

  constructor(private translate: TranslateService) {}
}
