import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CONSTANTS } from '../../constants';
import { RadioObject } from './radio-object.interface';

@Component({
  selector: 'app-folder-branding-colour',
  templateUrl: './folder-branding-color.component.html',
  styleUrls: ['./folder-branding-color.component.scss'],
})
export class FolderBrandingColourComponent {
  @Input() set chosenColor(value: string) {
    if (value) {
      this.setColor(value);
    } else {
      this.chosenBrandingColor = CONSTANTS.BRANDING_COLORS.FOODBACK_RED;
    }
  }

  @Output() readonly uploadedColor: EventEmitter<string> = new EventEmitter();
  radioList: RadioObject[] = this.getDefaultColorPalette();
  customColor: string = null;
  chosenBrandingColor: string = null;
  togglePicker = false;
  selectedValue = '';

  onColorType(value: string) {
    if (this.isColor(value)) {
      this.changeColorPicker(value);
    }
  }

  chooseColor(colorTint: string) {
    this.customColor = null;
    this.uploadedColor.emit(colorTint);
  }

  changeColorPicker(colorTint: string) {
    this.selectedValue = colorTint;
    this.uploadedColor.emit(colorTint.substr(1, colorTint.length));
    this.chosenBrandingColor = null;
  }

  private isColor(color: string) {
    // eslint-disable-next-line no-magic-numbers
    return /^#[0-9a-f]{3}([0-9a-f]{3})?$/i.test(color) && color.length === 7;
  }

  private setColor(value: string) {
    if (this.checkIfDefault(value)) {
      this.chosenBrandingColor = value;
      this.customColor = null;
    } else {
      this.customColor = `#${value}`;
      this.chosenBrandingColor = null;
    }

    this.selectedValue = this.customColor;
  }

  private checkIfDefault(color: string): boolean {
    return Object.keys(CONSTANTS.BRANDING_COLORS).some(colorKey => CONSTANTS.BRANDING_COLORS[colorKey] === color);
  }

  private getDefaultColorPalette(): RadioObject[] {
    return [
      {
        className: 'btn-foodback-red',
        btnRadio: CONSTANTS.BRANDING_COLORS.FOODBACK_RED,
      },
      {
        className: 'btn-foodback-yellow',
        btnRadio: CONSTANTS.BRANDING_COLORS.YELLOW,
      },
      {
        className: 'btn-foodback-yellow-sun',
        btnRadio: CONSTANTS.BRANDING_COLORS.YELLOW_SUN,
      },
      {
        className: 'btn-foodback-green',
        btnRadio: CONSTANTS.BRANDING_COLORS.GREEN,
      },
      {
        className: 'btn-foodback-blue',
        btnRadio: CONSTANTS.BRANDING_COLORS.BLUE,
      },
      {
        className: 'btn-foodback-light-blue',
        btnRadio: CONSTANTS.BRANDING_COLORS.LIGHT_BLUE,
      },
      {
        className: 'btn-foodback-violet',
        btnRadio: CONSTANTS.BRANDING_COLORS.VIOLET,
      },
      {
        className: 'btn-foodback-pink',
        btnRadio: CONSTANTS.BRANDING_COLORS.PINK,
      },
    ];
  }
}
