import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { I18nBackendService } from '../../../../../core/providers/backend/i18n-backend.service';
import { LanguageService } from '../../../../../core/providers/backend/language.service';
import { ContextService } from '../../../../../core/providers/context/context.service';
import { SharedComponentViewType } from '../../../../interfaces/common.interface';
import { I18nSupportedLanguage } from '../../../../interfaces/i18n.interface';
import { ContextObject } from '../../../../interfaces/login-object';

@Component({
  selector: 'app-foodback-overview-question-drilldown',
  templateUrl: './foodback-overview-question-drilldown.component.html',
  styleUrls: ['./foodback-overview-question-drilldown.component.scss'],
})
export class FoodbackOverviewQuestionDrilldownComponent implements OnInit, OnDestroy {
  viewType: SharedComponentViewType;
  defaultLanguage: string;
  private onDestroy$ = new Subject<void>();

  constructor(
    private contextService: ContextService,
    private i18nBackendService: I18nBackendService,
    private languageService: LanguageService
  ) {}

  ngOnInit() {
    this.contextService
      .getContext$()
      .pipe(first(), takeUntil(this.onDestroy$))
      .subscribe((context: ContextObject) => {
        this.viewType = ContextService.isAccountContext(context) ? SharedComponentViewType.ACCOUNT : SharedComponentViewType.VENUE;
        this.setLanguage();
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private setLanguage(): void {
    this.defaultLanguage = this.languageService.getDefaultLanguage()?.code;
  }
}
