import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { first, map, mergeMap, share, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { ApiResponse } from '../../../shared/interfaces/api-response.interface';
import { IGroupPayload } from '../../../shared/interfaces/groups/add-group-payload.interface';
import { IGroupResponse } from '../../../shared/interfaces/groups/add-group-response.interface';
import { FoodbackAccount } from '../../classes/account/account.class';
import { AccountService } from '../account/account.service';
import { ApiService } from '../api/api.service';

@Injectable()
export class GroupsBackendService {
  private readonly url = `${environment.apiBaseUrl.handbook}/fbi`;
  private readonly urlWithAnalytics = `${environment.apiBaseUrl.handbook}/surveys/analytics/accounts`;
  private cachedVenueGroups: any;
  private venueGroupsRequest$: Observable<ApiResponse<any>>;
  private cachedAccountUuid: string;
  private venueGroupsWithFoodbackRequest$: Observable<ApiResponse<any>>;
  private cachedVenueGroupsWidthFoodbacks: ApiResponse<any>;
  private cachedVenueGroupForDropdowns;
  private venueGroupsForDropdownsRequest$: any;

  constructor(private accountService: AccountService, private apiService: ApiService) {}

  unassignVenueFromGroup$(venueUuid: string, groupUuid: string): Observable<string> {
    return this.apiService.delete$(`${this.url}/venuegroupvenue/${venueUuid}/${groupUuid}`).pipe(map(response => response.content.uuid));
  }

  assignGroupToVenue$(venueUuid: string, venueGroupUuid: string): Observable<string> {
    return this.apiService.post$(`${this.url}/venuegroupvenue`, { venueUuid, venueGroupUuid }).pipe(map(response => response.content.uuid));
  }

  getGroups$(): Observable<any> {
    return this.callAfter$((data: FoodbackAccount) => {
      if (this.cachedVenueGroups && this.cachedAccountUuid === data.uuid) {
        return of(this.cachedVenueGroups);
      }
      if (this.venueGroupsRequest$ && this.cachedAccountUuid === data.uuid) {
        return this.venueGroupsRequest$;
      }

      this.venueGroupsRequest$ = this.apiService.get$(`${this.url}/venuegroups/${data.uuid}`).pipe(
        tap(response => {
          this.removeCachedGroup();
          this.cachedVenueGroups = response;
          this.cachedAccountUuid = data.uuid;
        }),
        share()
      );

      return this.venueGroupsRequest$;
    });
  }

  getGroupsForDropdown$(): Observable<any> {
    return this.callAfter$((data: FoodbackAccount) => {
      if (this.cachedVenueGroupForDropdowns && this.cachedAccountUuid === data.uuid) {
        return of(this.cachedVenueGroupForDropdowns);
      }
      if (this.venueGroupsForDropdownsRequest$ && this.cachedAccountUuid === data.uuid) {
        return this.venueGroupsForDropdownsRequest$;
      }

      this.venueGroupsForDropdownsRequest$ = this.apiService.get$(`${this.url}/venuegroups/${data.uuid}/dropdowns`).pipe(
        tap(response => {
          this.removeCachedGroup();
          this.cachedVenueGroupForDropdowns = response;
          this.cachedAccountUuid = data.uuid;
        }),
        share()
      );

      return this.venueGroupsForDropdownsRequest$;
    });
  }

  getVenueGroupsWithFoodbacks$(): Observable<any> {
    return this.callAfter$((data: FoodbackAccount) => {
      if (this.cachedVenueGroupsWidthFoodbacks && this.cachedAccountUuid === data.uuid) {
        return of(this.cachedVenueGroupsWidthFoodbacks);
      }
      if (this.venueGroupsWithFoodbackRequest$ && this.cachedAccountUuid === data.uuid) {
        return this.venueGroupsWithFoodbackRequest$;
      }

      this.venueGroupsWithFoodbackRequest$ = this.apiService.get$(`${this.urlWithAnalytics}/${data.uuid}/venueGroupsWithFoodbacks`).pipe(
        tap(response => {
          this.removeCachedGroup();
          this.cachedVenueGroupsWidthFoodbacks = response;
          this.cachedAccountUuid = data.uuid;
        }),
        share()
      );

      return this.venueGroupsWithFoodbackRequest$;
    });
  }

  addGroup$(data: IGroupPayload): Observable<IGroupResponse> {
    this.removeCachedGroup();

    return this.apiService.post$(`${this.url}/venuegroups`, data).pipe(map((response: ApiResponse<IGroupResponse>) => response.content));
  }

  updateGroup$(uuid: string, data: IGroupPayload): Observable<IGroupResponse> {
    this.removeCachedGroup();

    return this.apiService
      .put$(`${this.url}/venuegroups/${uuid}`, data)
      .pipe(map((response: ApiResponse<IGroupResponse>) => response.content));
  }

  removeGroup$(uuid: string): Observable<IGroupResponse> {
    this.removeCachedGroup();

    return this.apiService.delete$(`${this.url}/venuegroups/${uuid}`).pipe(map(response => response.content));
  }

  private removeCachedGroup() {
    this.cachedVenueGroups = null;
    this.cachedVenueGroupsWidthFoodbacks = null;
    this.cachedVenueGroupForDropdowns = null;
    this.cachedAccountUuid = null;
    this.venueGroupsRequest$ = null;
    this.venueGroupsForDropdownsRequest$ = null;
    this.venueGroupsWithFoodbackRequest$ = null;
  }

  private callAfter$(apiFunction: (args?: any) => Observable<any>): Observable<any> {
    return this.accountService.getAccount$().pipe(
      first(),
      mergeMap((account: FoodbackAccount) => apiFunction(account)),
      map((response: ApiResponse<any>) => response.content)
    );
  }
}
