import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class KeyboardStatusService {
  private lockEscModal: BehaviorSubject<boolean> = new BehaviorSubject(true);

  getEscModalStatus(): Observable<boolean> {
    return this.lockEscModal.asObservable();
  }

  setEscModalStatus(isEscEnable: boolean) {
    this.lockEscModal.next(isEscEnable);
  }
}
