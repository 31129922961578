import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IDropdownItem } from 'app/shared/interfaces/dropdown/dropdown-item.interface';
import { Observable, combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { CONSTANTS } from '../../shared/constants';
import { NotificationService } from '../providers/notification/notification.service';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  // eslint-disable-next-line @typescript-eslint/ban-types
  private cachedCountriesAndCodes: [Object, Object];
  // eslint-disable-next-line @typescript-eslint/ban-types
  private cachedPhoneCodes: [Object, Object];

  constructor(private http: HttpClient, private notificationService: NotificationService) {}

  prepareDateForRequest(date: Date): string {
    const day = date.getDate();
    const month = date.getMonth() + 1;

    return `${date.getFullYear()}-${this.prepareDayOrMonthString(month)}-${this.prepareDayOrMonthString(day)}`;
  }

  getHashCode(value: string) {
    if (!value) {
      console.error('Wrong data format!');

      return null;
    }

    return value.split('').reduce((a, b) => {
      // eslint-disable-next-line no-bitwise, no-magic-numbers
      a = (a << 5) - a + b.charCodeAt(0);

      // eslint-disable-next-line no-bitwise
      return a & a;
    }, 0);
  }

  isHashKeyCorrect(objectKey: string, hashKey: string): boolean {
    const objStr = sessionStorage.getItem(objectKey);
    const objHash = sessionStorage.getItem(hashKey);

    if (!objStr) {
      return false;
    }

    return objHash === this.getHashCode(objStr).toString();
  }

  getPhoneCodeByCountryISOCode(countryISOCode: string): Observable<string> {
    const jsonPhoneCodesFile = `assets/collections/phone-codes.json`;
    const jsonCountiesISOCodesFile = `assets/collections/countries-iso-codes.json`;

    if (this.cachedPhoneCodes) {
      return of(this.getPhoneCodeByCountry(countryISOCode));
    }

    return combineLatest([
      this.http.get(jsonPhoneCodesFile),
      this.http.get(jsonCountiesISOCodesFile),
      // eslint-disable-next-line @typescript-eslint/ban-types
    ]).pipe(
      map((res: [Object, Object]) => {
        this.cachedPhoneCodes = res;

        return this.getPhoneCodeByCountry(countryISOCode);
      })
    );
  }

  getPostalCodeByCountryISOCode(countryISOCode: string): Observable<string> {
    const jsonPostalCodesCodesFile = `assets/collections/postal-codes.json`;
    const jsonCountiesISOCodesFile = `assets/collections/countries-iso-codes.json`;

    if (this.cachedCountriesAndCodes) {
      return of(this.getZipCodeByCountry(countryISOCode));
    }

    return combineLatest([
      this.http.get(jsonPostalCodesCodesFile),
      this.http.get(jsonCountiesISOCodesFile),
      // eslint-disable-next-line @typescript-eslint/ban-types
    ]).pipe(
      map((res: [Object, Object]) => {
        this.cachedCountriesAndCodes = res;

        return this.getZipCodeByCountry(countryISOCode);
      })
    );
  }

  getZipCodeMask(zipCode: string): string {
    let i: number = zipCode.length;
    let zipMask: string = zipCode;

    while (i--) {
      if (this.isLetter(zipMask[i])) {
        zipMask = this.replaceAt(zipMask, i, 'a');
      }
    }

    return zipMask;
  }

  replaceAt(str, index, replacement): string {
    return str.substr(0, index) + replacement + str.substr(index + replacement.length);
  }

  copyTextToClipboard(val: string) {
    if (typeof navigator !== "undefined" && typeof navigator.clipboard !== "undefined") {
          navigator.clipboard.writeText(val)
              .then(() => {
                this.notificationService.successWithTranslationKey('ACCOUNT.FOLDER.QR_URLS.DIALOGS.SUCCESS.URL_SUCCESSFUL_COPIED');
            })
            .catch(() => {
              this.notificationService.addSnackBarMsg(val, 'success', 40000);
            });
    } else {
      this.notificationService.addSnackBarMsg(val, 'success', 40000);
    }
  }

  goToLink(url: string): void {
    window.open(url, '_blank');
  }

  isSelectedItemsChanged(selectedItems: IDropdownItem[], itemsCount: number): boolean {
    return (
      selectedItems.length === 0 ||
      this.getSelectedItemsHashCode(selectedItems, itemsCount) ===
        +sessionStorage.getItem(CONSTANTS.LOCAL_STORAGE_KEYS.SELECTED_VENUES_HASH)
    );
  }

  getSelectedItemsHashCode(selectedItems: IDropdownItem[], itemsCount: number): number {
    return this.getHashCode(JSON.stringify(selectedItems.length === itemsCount ? [] : selectedItems));
  }

  getSelectedVenuesUuids(items: IDropdownItem[]): string[] {
    return items.filter((item: IDropdownItem) => item.id !== CONSTANTS.SELECT_ITEM_NAMES.ALL).map((item: IDropdownItem) => item.id);
  }

  titleCaseWord(word: string): string {
    if (!word) {
      return word;
    }

    return word[0].toUpperCase() + word.substr(1);
  }

  swapElement(array: any[], indexA: number, indexB: number): void {
    const tmp: any = array[indexA];

    array[indexA] = array[indexB];
    array[indexB] = tmp;
  }

  private getPhoneCodeByCountry(countryISOCode: string): string {
    const phoneCodesIndex = 0;
    const countriesISOIndex = 1;
    const countryCode: string = Object.keys(this.cachedPhoneCodes[countriesISOIndex]).find(
      (key: string) => this.cachedPhoneCodes[countriesISOIndex][key] === countryISOCode
    );

    return `+${this.cachedPhoneCodes[phoneCodesIndex][countryCode]}`;
  }

  private getZipCodeByCountry(countryISOCode: string): string {
    const postalCodesIndex = 0;
    const countriesISOIndex = 1;
    const countryCode: string = Object.keys(this.cachedCountriesAndCodes[countriesISOIndex]).find(
      (key: string) => this.cachedCountriesAndCodes[countriesISOIndex][key] === countryISOCode
    );

    return this.cachedCountriesAndCodes[postalCodesIndex][countryCode];
  }

  private prepareDayOrMonthString(value: number): string {
    // eslint-disable-next-line no-magic-numbers
    return `${value < 10 ? `0${value}` : value}`;
  }

  private isLetter(str: string): boolean {
    return str.length === 1 && !!str.toUpperCase().match(/[a-z]/i);
  }
}
