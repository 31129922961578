import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import { Observable, map } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { CONSTANTS } from '../../../shared/constants';
import { PayloadForFoodbacks } from '../../interfaces/payload-for-foodbacks.interface';
import { ApiService } from '../api/api.service';

@Injectable()
export class FileSaverService {
  private exportPath = `${environment.apiBaseUrl.handbook}/surveys/exports/venues`;
  private accountExportPath = `${environment.apiBaseUrl.handbook}/surveys/exports/accounts`;
  private oneMoreQuestionExportPath = `${environment.apiBaseUrl.handbook}/surveys/exports/one-more-question`;
  private defaultFoodbacksBody: PayloadForFoodbacks = {
    page: 1,
    limit: 10,
    hasComment: null,
    hasEmail: null,
    isContactAllowed: null,
    commentContains: null,
    dateFrom: null,
    dateTo: null,
  };

  private 

  constructor(private apiService: ApiService) { }

  fetchXLSXFile(data: {}, fileName = 'Foodbacks-Demographics.xlsx', fileType = 'application/vnd.ms-excel'): void {
    const blob = new Blob([data as any], {
      type: fileType,
    });

    FileSaver.saveAs(blob, fileName);
  }

  sendEmailWithExportedExcelFoodbacks$(uuid: string, contextType: string, restrictions?: PayloadForFoodbacks): Observable<any> {
    const objToSend: PayloadForFoodbacks = restrictions || this.defaultFoodbacksBody;

    if (objToSend.sort) {
      delete objToSend.sort;
    }
    const endpointPath: string = contextType === CONSTANTS.CONTEXT.ACCOUNT ? this.accountExportPath : this.exportPath;

    return this.apiService.post$(`${endpointPath}/${uuid}/spreadsheet/${CONSTANTS.ENDPOINTS.FOODBACKS}`, objToSend);
  }

  sendEmailWithExportedExcelOMQ$(uuid: string, restrictions?: any): Observable<any> {
    const objToSend: any = restrictions;

    const endpointPath: string = this.oneMoreQuestionExportPath;

    return this.apiService.post$(`${endpointPath}/${uuid}/spreadsheet`, objToSend).pipe(map((response: any) => response.content));
  }

  sendEmailWithExportedExcelVenueOMQ$(questionUuid: string, venueUuid: string, restrictions?: any): Observable<any> {
    const objToSend: any = restrictions;

    const endpointPath: string = this.oneMoreQuestionExportPath;

    return this.apiService.post$(`${endpointPath}/${questionUuid}/venues/${venueUuid}/spreadsheet`, objToSend).pipe(map((response: any) => response.content));
  }
}
