<div class="CardWidget">
  <div class="row row-table">
    <div class="col-xs-4 text-center h1">
      <div class="CardWidget-icon">
        <ng-content select="[card-widget-icon]"></ng-content>
      </div>
    </div>
    <div class="col-xs-8 CardWidget-contents">
      <ng-content select="[card-widget-contents]"></ng-content>
    </div>
  </div>
</div>
