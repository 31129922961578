<div dropdown class="Dropdown" #dropdownReference>
  <mat-form-field class="Dropdown-selectedItemInput" (click)="showDropdown()">
    <mat-label>{{ label }}</mat-label>
    <input
      class="Dropdown-selectedItemInput"
      matInput
      autocomplete="off"
      list="autocompleteOff"
      [disabled]="disabled"
      [value]="displayActiveItem ? selectedText || placeholder : placeholder"
      readonly />
    <mat-icon matSuffix>arrow_drop_down</mat-icon>
  </mat-form-field>
  <app-nested-dropdown [reference]="dropdownReference">
    <div class="Dropdown__bodyWrapper" [style.width.px]="dropdownReference.offsetWidth">
      <div class="Dropdown__bodyWrapper-list">
        <div class="Dropdown__bodyWrapper-list-item Dropdown__bodyWrapper-list-item--menu" *ngIf="filteredItems">
          <mat-checkbox class="Dropdown__selectAll" color="primary" [formControl]="selectAllCheckbox"></mat-checkbox>
          <div  class="Dropdown__inputContainer" fxLayout="row">
            <input
              autocomplete="off"
              list="autocompleteOff"
              class="Dropdown-search-box"
              [(ngModel)]="searchBoxValue"
              (input)="onSearch($event)"
              [placeholder]="searchPlaceholder ? searchPlaceholder : ('SHARED.COMPONENTS.NESTED_DROPDOWN_FILTER.SEARCH' | translate)" />
            <button
              *ngIf="searchBoxValue"
              mat-icon-button
              aria-label="Clear"
              (click)="resetSearchInput($event)"
              class="mat-select-search-clear">
              <mat-icon>close</mat-icon>
            </button>
          </div>
        </div>
        <div class="Dropdown__bodyWrapper-list-item Dropdown__bodyWrapper-list-content" *ngIf="filteredItems && filteredItems.length > 0">
          <div *ngFor="let item of filteredItems; trackBy: trackByIndex">
            <div class="Dropdown__bodyWrapper-list-item-content">
              <mat-checkbox color="primary" [ngModel]="item.isSelected" (ngModelChange)="select(item, true)"
                            appTooltip [text]="item.text && item.text.length >= CONSTANTS.PAGE_DEFAULTS.GROUPS_NAME_LENGTH? item.text : null">
                {{ item.text | ellipsis: [CONSTANTS.PAGE_DEFAULTS.GROUPS_NAME_LENGTH] }}
              </mat-checkbox>
            </div>
            <div class="pl-lg" *ngFor="let childItem of item.children | sortByField: 'text':true; trackBy: trackByIndex"
                 appTooltip [text]="childItem.text && childItem.text.length >= CONSTANTS.PAGE_DEFAULTS.GROUPS_NAME_LENGTH? childItem.text : null">
              <div class="Dropdown__bodyWrapper-list-item-content">
                <mat-checkbox color="primary" [ngModel]="childItem.isSelected" (ngModelChange)="select(childItem)">
                  {{ childItem.text | ellipsis: [CONSTANTS.PAGE_DEFAULTS.GROUPS_NAME_LENGTH] }}
                </mat-checkbox>
              </div>
            </div>
          </div>
        </div>
        <div
          class="Dropdown__bodyWrapper-list-item Dropdown__bodyWrapper-list-content"
          *ngIf="filteredItems && filteredItems.length === 0"></div>
        <div class="Dropdown__bodyWrapper-list-item Dropdown__bodyWrapper-list-content" *ngIf="!filteredItems"></div>
      </div>
    </div>
  </app-nested-dropdown>
</div>
