import { CONSTANTS } from '../../shared/constants';

export const ACCOUNT_ROUTES_PATHS_PARTIALS = {
  BASE_PATH: `${CONSTANTS.ROUTES.ACCOUNT}/:${CONSTANTS.ROUTE_PARAMS.ACCOUNT_ID}`,
  SCOREBOARD: CONSTANTS.ROUTES.SCOREBOARD,
  OVERVIEW: {
    ACCOUNT: CONSTANTS.ROUTES.DASHBOARD, // in account, overview will be called "dashboard", so this should be reflected in URL and menu
    CATEGORY: `${CONSTANTS.ROUTES.DASHBOARD}/${CONSTANTS.ROUTES.CATEGORY}/:${CONSTANTS.ROUTE_PARAMS.CATEGORY_ID}`,
    QUESTION:
      `${CONSTANTS.ROUTES.DASHBOARD}/${CONSTANTS.ROUTES.CATEGORY}` +
      `/:${CONSTANTS.ROUTE_PARAMS.CATEGORY_ID}/${CONSTANTS.ROUTES.QUESTION}/:${CONSTANTS.ROUTE_PARAMS.QUESTION_ID}`,
  },
  ALL_FOODBACKS: CONSTANTS.ROUTES.ALL_FOODBACKS,
  ONE_MORE_QUESTION: {
    OVERVIEW: CONSTANTS.ROUTES.ONE_MORE_QUESTION,
    DETAILS: `${CONSTANTS.ROUTES.ONE_MORE_QUESTION}/${CONSTANTS.ROUTES.QUESTION}/:${CONSTANTS.ROUTE_PARAMS.QUESTION_ID}`,
  },
  CAMPAIGNS: {
    OVERVIEW: CONSTANTS.ROUTES.CAMPAIGNS,
    DETAILS: `${CONSTANTS.ROUTES.CAMPAIGNS}/:${CONSTANTS.ROUTE_PARAMS.CAMPAIGN_ID}`,
    QUESTION_DETAILS: `${CONSTANTS.ROUTES.CAMPAIGNS}/:${CONSTANTS.ROUTE_PARAMS.CAMPAIGN_ID}/${CONSTANTS.ROUTES.QUESTION}/:${CONSTANTS.ROUTE_PARAMS.QUESTION_ID}`,
  },
};
export const ACCOUNT_ROUTES_PATHS_FULL = {
  SCOREBOARD: `${ACCOUNT_ROUTES_PATHS_PARTIALS.BASE_PATH}/${ACCOUNT_ROUTES_PATHS_PARTIALS.SCOREBOARD}`,
  OVERVIEW: {
    ACCOUNT: `${ACCOUNT_ROUTES_PATHS_PARTIALS.BASE_PATH}/${ACCOUNT_ROUTES_PATHS_PARTIALS.OVERVIEW.ACCOUNT}`,
    CATEGORY: `${ACCOUNT_ROUTES_PATHS_PARTIALS.BASE_PATH}/${ACCOUNT_ROUTES_PATHS_PARTIALS.OVERVIEW.CATEGORY}`,
    QUESTION: `${ACCOUNT_ROUTES_PATHS_PARTIALS.BASE_PATH}/${ACCOUNT_ROUTES_PATHS_PARTIALS.OVERVIEW.QUESTION}`,
  },
  ALL_FOODBACKS: `${ACCOUNT_ROUTES_PATHS_PARTIALS.BASE_PATH}/${ACCOUNT_ROUTES_PATHS_PARTIALS.ALL_FOODBACKS}`,
  ONE_MORE_QUESTION: {
    OVERVIEW: `${ACCOUNT_ROUTES_PATHS_PARTIALS.BASE_PATH}/${ACCOUNT_ROUTES_PATHS_PARTIALS.ONE_MORE_QUESTION.OVERVIEW}`,
    DETAILS: `${ACCOUNT_ROUTES_PATHS_PARTIALS.BASE_PATH}/${ACCOUNT_ROUTES_PATHS_PARTIALS.ONE_MORE_QUESTION.DETAILS}`,
  },
  CAMPAIGNS: {
    OVERVIEW: `${ACCOUNT_ROUTES_PATHS_PARTIALS.BASE_PATH}/${ACCOUNT_ROUTES_PATHS_PARTIALS.CAMPAIGNS.OVERVIEW}`,
    DETAILS: `${ACCOUNT_ROUTES_PATHS_PARTIALS.BASE_PATH}/${ACCOUNT_ROUTES_PATHS_PARTIALS.CAMPAIGNS.DETAILS}`,
    QUESTION_DETAILS: `${ACCOUNT_ROUTES_PATHS_PARTIALS.BASE_PATH}/${ACCOUNT_ROUTES_PATHS_PARTIALS.CAMPAIGNS.QUESTION_DETAILS}`,
  },
};
