import { ConnectionPositionPair, Overlay, OverlayConfig, PositionStrategy } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { ElementRef, Injectable, Injector } from '@angular/core';
import { MultiSelectTagListContent, MultiSelectTagListRef } from './multi-select-tag-list-ref';
import { MultiSelectTagListComponent } from './multi-select-tag-list.component';

export interface MultiSelectTagListParams<T> {
  width?: string | number;
  height?: string | number;
  origin: ElementRef;
  content: MultiSelectTagListContent;
  data?: T;
}
@Injectable()
export class MultiSelectTagListService {
  MAX_LIST_HEIGHT_PX = 400;
  ABOVE_PARENT_MAX_VALUE = -340;

  private showAboveTheParent = false;

  constructor(private overlay: Overlay, private injector: Injector) {}

  open<T>({ origin, content, width, height }: MultiSelectTagListParams<T>): MultiSelectTagListRef<T> {
    this.checkAndSetPositionOnTheScreenFlag(origin);
    const overlayRef = this.overlay.create(this.getOverlayConfig(origin, width, height));
    const multiSelectTagListRef = new MultiSelectTagListRef<T>(overlayRef, content);
    const injector = this.createInjector(multiSelectTagListRef, this.injector);

    overlayRef.attach(new ComponentPortal(MultiSelectTagListComponent, null, injector));

    return multiSelectTagListRef;
  }

  createInjector(multiSelectTagListRef: MultiSelectTagListRef, injector: Injector) {
    const tokens = new WeakMap([[MultiSelectTagListRef, multiSelectTagListRef]]);

    return new PortalInjector(injector, tokens);
  }

  private checkAndSetPositionOnTheScreenFlag(origin: ElementRef) {
    const boundingClientRect = origin.nativeElement.getBoundingClientRect();

    this.showAboveTheParent = document.body.clientHeight - this.MAX_LIST_HEIGHT_PX < boundingClientRect.y;
  }

  private getOverlayConfig(origin: ElementRef, width: number | string, height: number | string): OverlayConfig {
    return new OverlayConfig({
      width,
      height,
      hasBackdrop: true,
      backdropClass: 'MultiSelectTagList-backdrop',
      panelClass: this.showAboveTheParent ? 'MultiSelectTagList__backdrop-top' : 'MultiSelectTagList__backdrop-bottom',
      positionStrategy: this.getOverlayPosition(origin),
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
    });
  }

  private getOverlayPosition(origin: ElementRef): PositionStrategy {
    return this.overlay.position().flexibleConnectedTo(origin).withPositions(this.getPositions()).withFlexibleDimensions(false);
  }

  private getPositions(): ConnectionPositionPair[] {
    return [
      {
        offsetY: this.showAboveTheParent ? this.ABOVE_PARENT_MAX_VALUE : 0,
        originX: 'start',
        originY: 'bottom',
        overlayX: 'start',
        overlayY: 'bottom',
      },
    ];
  }
}
