import { Injectable } from '@angular/core';
import { isNil } from 'lodash';
import { Observable, ReplaySubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { FoodbackAccount } from '../../classes/account/account.class';
import { IFoodbackAccount } from '../../interfaces/account/account.interface';

@Injectable()
export class AccountService {
  private account: ReplaySubject<FoodbackAccount> = new ReplaySubject(1);

  getAccount$(): Observable<FoodbackAccount> {
    return this.account.pipe(filter((account: FoodbackAccount) => !isNil(account)));
  }

  setAccount(account: IFoodbackAccount) {
    this.account.next(new FoodbackAccount(account));
  }
}
