import { Component, Input, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  PeriodPerformanceChartInput,
  PeriodPerformanceChartInputEntry,
} from '../period-performance-chart/period-performance-chart.interface';
import { HourByHourChartInput, HourByHourChartInputEntry } from './hour-by-hour-chart.interface';

@Component({
  selector: 'app-hour-by-hour-chart',
  templateUrl: './hour-by-hour-chart.component.html',
  styleUrls: ['./hour-by-hour-chart.component.scss'],
})
export class HourByHourChartComponent implements OnDestroy {
  @Input() set data(val: HourByHourChartInput) {
    if (val) {
      this.translateService
        .get('HOME.OVERVIEW.HOURLY_PERFORMANCE.LABELS')
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(labels => {
          this.dataForChart = {
            currentScores: val.currentScores.map(score => this.mapValueEntryToPerformanceEntry(score, labels)),
            currentCount: val.currentCount.map(count => this.mapValueEntryToPerformanceEntry(count, labels)),
          };
        });
    }
  }
  @Input() set printMode(printMode: boolean) {
    if (printMode) {
      this.printModeState = printMode;
    }
  }
  @Input() goal: number;
  @Input() translateKey?: string = 'HOME.OVERVIEW.PERFORMANCE_CHART.SERIES';
  dataForChart: PeriodPerformanceChartInput;
  printModeState: boolean = false;
  private onDestroy$ = new Subject<void>();

  constructor(private translateService: TranslateService) {}

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private mapValueEntryToPerformanceEntry(entry: HourByHourChartInputEntry, labels: string[]): PeriodPerformanceChartInputEntry {
    return {
      value: entry.value,
      rangeName: labels[entry.range.toUpperCase()],
    };
  }
}
