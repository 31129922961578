<div
  [ngClass]="{
    'MessageBar--info': type === messageBarTypes.INFO,
    'MessageBar--warning': type === messageBarTypes.WARNING
  }"
  class="MessageBar">
  <div class="MessageBar__content">
    <ng-content></ng-content>
  </div>
</div>
