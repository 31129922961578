import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { ServerEnvironmentService } from '../server-environment/server-environment.service';

@Injectable()
export class GoogleAnalyticsService {
  private hasGAScriptBeenInjected = false;

  constructor(private serverEnvironment: ServerEnvironmentService, @Inject(DOCUMENT) private document: Document) {}

  injectGAScript(): void {
    if (this.hasGAScriptBeenInjected) {
      this.warnAboutDoubleInjection();
    }

    this.serverEnvironment.getServerEnvironmentConfig$().subscribe(serverEnvironment => {
      this.createAndInjectGAScriptNode(serverEnvironment?.google?.googleAnalyticsId);
    });
  }

  private createAndInjectGAScriptNode(googleAnalyticsId: string): void {
    if (!googleAnalyticsId) {
      // this might happen on dev/QA environments
      return;
    }
    const tagContents = `
      (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
      (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
      m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
      })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

      ga('create', '${googleAnalyticsId}', 'auto');
    `;
    const tag = document.createElement('script');

    tag.innerHTML = tagContents;
    this.document.getElementsByTagName('script')[0].parentElement.appendChild(tag);
    this.hasGAScriptBeenInjected = true;
  }

  private warnAboutDoubleInjection(): void {
    console.warn('Analytics requested to be injected more than once');
  }
}
