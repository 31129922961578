import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { mergeMap, takeUntil } from 'rxjs/operators';
import { FoodbackAccount } from '../../../core/classes/account/account.class';
import { AccountService } from '../../../core/providers/account/account.service';
import { GroupsBackendService } from '../../../core/providers/backend/groups-backend.service';
import { UsersBackendService } from '../../../core/providers/backend/users-backend.service';
import { IGroup } from '../../interfaces/groups/group.interface';
import { DialogService } from '../dialog/dialog.service';
import { GroupsListComponent } from './groups-list/groups-list.component';
import { GroupsService } from './groups.service';
import { CONSTANTS } from 'app/shared/constants';
import { LocalStorageService } from 'ngx-webstorage';
import { ContextService } from 'app/core/providers/context/context.service';

/**
 * There is a suspicion that the groups component can be used in venue context if we are in single venue mode.
 * That's the reason this component is placed in 'Shared' directory. Otherwise it's gonna be moved to the account one.
 */
@Component({
  selector: 'app-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupsComponent implements OnInit, OnDestroy {
  @ViewChild('groupsListComponent', { static: true })
  groupsListComponentRef: GroupsListComponent;
  selectedGroup: IGroup;
  accountChildrenNameSingular: string;
  accountChildrenNamePlural: string;
  private onDestroy$ = new Subject<void>();

  constructor(
    private accountService: AccountService,
    private groupsBackendService: GroupsBackendService,
    private groupsService: GroupsService,
    private usersBackendService: UsersBackendService,
    private dialogService: DialogService,
    private contextService: ContextService
  ) {}

  onSelectedGroup(selectedGroup: IGroup) {
    this.selectedGroup = selectedGroup;

    if (selectedGroup.isCategory) {
      this.showAddCategoryDialog(selectedGroup);
    } else {
      this.showAddGroup(selectedGroup);
    }
  }

  showAddGroup(selectedGroup: IGroup) {
    const dialogRef = this.dialogService.newGroup(selectedGroup);

    dialogRef
      .backdropClick()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => dialogRef.close());
  }

  showAddCategoryDialog(selectedGroup: IGroup) {
    const dialogRef = this.dialogService.newGroupCategory(selectedGroup);

    dialogRef
      .backdropClick()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => dialogRef.close());
  }

  ngOnInit() {
    this.groupsBackendService.getGroups$().pipe(takeUntil(this.onDestroy$)).subscribe(this.groupsService.setGroups);
    this.getAccount$()
      .pipe(
        mergeMap(({ uuid }: FoodbackAccount) => this.usersBackendService.getVenuesList$(uuid)),
        takeUntil(this.onDestroy$)
      )
      .subscribe(this.groupsService.setVenues);
    this.accountChildrenNameSingular = this.contextService.getAccountChildrenNameTranslation(true);
    this.accountChildrenNamePlural = this.contextService.getAccountChildrenNameTranslation(false);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private getAccount$(): Observable<FoodbackAccount> {
    return this.accountService.getAccount$();
  }
}
