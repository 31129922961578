import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FallbackContextGuardService } from '../../core/providers/context-guards/fallback-context-guard.service';
import { CONSTANTS } from '../../shared/constants';
import { HasAccessToFbiGuard } from '../login/has-access-to-fbi-guard.service';
import { PageNotFoundComponent } from './page-not-found.component';

const pageNotFoundRoutes: Routes = [
  {
    path: '',
    canActivate: [HasAccessToFbiGuard, FallbackContextGuardService],
    component: PageNotFoundComponent,
  },
  { path: CONSTANTS.ROUTES.NOT_FOUND, component: PageNotFoundComponent },
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(pageNotFoundRoutes)],
  exports: [RouterModule],
})
export class PageNotFoundModule {}
