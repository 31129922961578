import { CONSTANTS } from '../../../../shared/constants';

export const VENUE_CONFIGURATION_ROUTES_PATHS_PARTIALS = {
  BASE_PATH: CONSTANTS.ROUTES.CONFIGURATION,
  BRANDING: CONSTANTS.ROUTES.BRANDING,
  ADVERTISEMENT: CONSTANTS.ROUTES.ADVERTISEMENT,
  DEMOGRAPHICS: CONSTANTS.ROUTES.DEMOGRAPHICS,
  REGIONAL_SETTINGS: CONSTANTS.ROUTES.REGIONAL_SETTINGS,
};
export const VENUE_CONFIGURATION_ROUTES_PATHS_FULL = {
  BRANDING: `${VENUE_CONFIGURATION_ROUTES_PATHS_PARTIALS.BASE_PATH}/${VENUE_CONFIGURATION_ROUTES_PATHS_PARTIALS.BRANDING}`,
  ADVERTISEMENT: `${VENUE_CONFIGURATION_ROUTES_PATHS_PARTIALS.BASE_PATH}/${VENUE_CONFIGURATION_ROUTES_PATHS_PARTIALS.ADVERTISEMENT}`,
  DEMOGRAPHICS: `${VENUE_CONFIGURATION_ROUTES_PATHS_PARTIALS.BASE_PATH}/${VENUE_CONFIGURATION_ROUTES_PATHS_PARTIALS.DEMOGRAPHICS}`,
  REGIONAL_SETTINGS: `${VENUE_CONFIGURATION_ROUTES_PATHS_PARTIALS.BASE_PATH}/${VENUE_CONFIGURATION_ROUTES_PATHS_PARTIALS.REGIONAL_SETTINGS}`,
};
