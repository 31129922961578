import { Injectable } from '@angular/core';
import { map as _map, toNumber } from 'lodash';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import {
  FoodbackListFromBackend,
  FoodbackStatistic,
  IFoodbackContactStatus,
} from '../../../../components/venue/foodbacks/all-foodbacks/foodback-statistic';
import { CONSTANTS } from '../../../../shared/constants';
import {
  VenueScore,
  VenueScoreFromBackend,
} from '../../../../shared/interfaces/analytics.interface';
import { ApiResponse } from '../../../../shared/interfaces/api-response.interface';
import { Dictionary } from '../../../../shared/interfaces/common.interface';
import { PayloadForFoodbacks } from '../../../interfaces/payload-for-foodbacks.interface';
import { ApiService } from '../../api/api.service';
import { NotificationService } from '../../notification/notification.service';

@Injectable()
export class AnalyticsBackendService {
  private analyticsPath = `${environment.apiBaseUrl.handbook}/surveys/analytics/venues`;
  private accountAnalyticsPath = `${environment.apiBaseUrl.handbook}/surveys/analytics/accounts`;
  private analyticsContactPat = `${environment.apiBaseUrl.handbook}/surveys/analytics/foodback-customer-info`;
  private defaultFoodbacksBody: PayloadForFoodbacks = {
    page: 1,
    limit: 10,
    hasComment: null,
    hasEmail: null,
    isContactAllowed: null,
    commentContains: null,
    dateFrom: null,
    dateTo: null,
  };

  constructor(
    private apiService: ApiService,
    private notificationService: NotificationService
  ) {}

  static mapScoreFromBackendToScore(score: VenueScoreFromBackend): VenueScore {
    return {
      date: score.date,
      value: toNumber(score.value),
    };
  }

  static keysToUpperCase(obj: Dictionary<any>): Dictionary<any> {
    return Object.keys(obj).reduce((reduced, keyName) => {
      reduced[keyName.toUpperCase()] = obj[keyName];

      return reduced;
    }, {});
  }

  getFoodbacksList$(
    uuid: string,
    contextType: string,
    restrictions?: PayloadForFoodbacks
  ): Observable<FoodbackListFromBackend> {
    const objToSend: PayloadForFoodbacks =
      restrictions || this.defaultFoodbacksBody;
    const endpointPath: string =
      contextType === CONSTANTS.CONTEXT.ACCOUNT
        ? this.accountAnalyticsPath
        : this.analyticsPath;

    return this.apiService
      .post$(
        `${endpointPath}/${uuid}/${CONSTANTS.ENDPOINTS.FOODBACKS}`,
        objToSend
      )
      .pipe(
        map((response: ApiResponse): FoodbackListFromBackend[] => {
          const JSONresponse = response.content;

          JSONresponse.items = _map(
            JSONresponse.items,
            (item: FoodbackStatistic) => {
              const uselessSignCountLimit = 2;
              const ageGroupWithSignsCharLength = 4;

              item.gender = item.gender
                ? item.gender.toUpperCase()
                : item.gender;
              item.ageGroup =
                item.ageGroup &&
                item.ageGroup.length === ageGroupWithSignsCharLength
                  ? item.ageGroup.slice(
                      uselessSignCountLimit,
                      item.ageGroup.length
                    )
                  : item.ageGroup;

              return item;
            }
          );

          return JSONresponse;
        }),
        catchError((error): Observable<any> => {
          this.notificationService.error(error.errorMessage);

          return observableThrowError('Error with "foodbacks" request', error);
        })
      );
  }

  updateContactStatus(
    foodbackUuid: string,
    payload: IFoodbackContactStatus
  ): Observable<any> {
    return this.apiService
      .put$(`${this.analyticsContactPat}/${foodbackUuid}`, payload)
      .pipe(
        tap(() =>
          this.notificationService.successWithTranslationKey(
            'HOME.FOODBACKS.CONTACTED.STATUS_UPDATED'
          )
        ),
        catchError((error): Observable<any> => {
          this.notificationService.error(error.errorMessage);

          return observableThrowError('Error with "foodbacks" request', error);
        })
      );
  }
}
