import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'prettyNumbers',
})
export class PrettyNumbersPipe implements PipeTransform {
  transform(value: string | number): string {
    if (!value) {
      return '0';
    }

    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  }
}
