import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { CONSTANTS } from '../../../shared/constants';
import {
  AdminAnalyticsFunctionParams,
  AdminAnalyticsPayload,
  AdminAnalyticsResponse,
  AdminSurveyVenueResponse,
} from '../../../shared/interfaces/admin-analytics.interface';
import { DynamicDateRange } from '../../../shared/interfaces/date-range.interface';
import { UtilsService } from '../../utils/utils.service';
import { ApiService } from '../api/api.service';

@Injectable()
export class AdminAnalyticsBackendService {
  private surveysPath = `${environment.apiBaseUrl.handbook}/surveys`;
  private fbiPath = `${environment.apiBaseUrl.handbook}/fbi`;

  constructor(private apiService: ApiService, private utilsService: UtilsService) {}

  getAnalyticsInfoForAdmin(accounts: string[], venues: string[], range: DynamicDateRange): Observable<AdminAnalyticsResponse> {
    const payload: AdminAnalyticsPayload = {
      accounts,
      venues,
      dateFrom: this.utilsService.prepareDateForRequest(range.start),
      dateTo: this.utilsService.prepareDateForRequest(range.end),
    };

    return this.apiService
      .post$(
        `${this.surveysPath}/${CONSTANTS.ENDPOINTS.ANALYTICS}/${CONSTANTS.ENDPOINTS.FOODBACK_SURVEYS}/${CONSTANTS.ENDPOINTS.DEMOGRAPHICS}`,
        payload
      )
      .pipe(map(response => response.content));
  }

  getVenuesSurveyList(params: AdminAnalyticsFunctionParams): Observable<AdminSurveyVenueResponse> {
    const payload = this.getRequestPayload(params);

    return this.apiService
      .post$(
        `${this.fbiPath}/${CONSTANTS.ENDPOINTS.ANALYTICS}/${CONSTANTS.ENDPOINTS.SURVEYS}/${CONSTANTS.ENDPOINTS.STATISTICS_FOR_EACH_VENUE}`,
        payload
      )
      .pipe(map(response => response.content));
  }

  private getRequestPayload({ accounts, venues, range, page }: AdminAnalyticsFunctionParams): AdminAnalyticsPayload {
    return {
      accounts,
      venues,
      page,
      dateFrom: this.utilsService.prepareDateForRequest(range.start),
      dateTo: this.utilsService.prepareDateForRequest(range.end),
      limit: CONSTANTS.PAGINATION_DEFAULTS.ITEMS_PER_PAGE,
    };
  }
}
