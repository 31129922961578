import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { CONSTANTS } from 'app/shared/constants';
import { chain, cloneDeep, get } from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { INestedDropdownChildItem } from '../../interfaces/nested-dropdown-filter/nested-dropdown-filter-child.interface';
import { INestedDropdownItem } from '../../interfaces/nested-dropdown-filter/nested-dropdown-filter.interfaces';
import { EllipsisPipe } from '../../pipes/ellipsis.pipe';
import { NestedDropdownComponent } from './nested-dropdown/nested-dropdown.component';

const MAX_LABEL_TEXT_COUNT = 22;

@Component({
  selector: 'app-nested-dropdown-filter',
  templateUrl: './nested-dropdown-filter.component.html',
  styleUrls: ['./nested-dropdown-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NestedDropdownFilterComponent implements OnInit, OnDestroy {
  @Input('items') set setItems(data: INestedDropdownItem[]) {
    this.items = cloneDeep(data);
    this.filteredItems = cloneDeep(data);
    this.setButtonText();
    this.onAssign.emit(this.getSelectedItems());
  }

  /**
   * Text to display when no item is selected. May be a translation key.s
   */
  @Input() placeholder: string = this.translate.instant('SHARED.SYSTEM.SELECT_ITEM');
  @Input() label: string = this.translate.instant('SHARED.SYSTEM.SELECT_ITEM');
  @Input() displayActiveItem = true;
  /**
   * Selected options to be displayed in dropdown box.
   */
  @Input() displayActiveItemAmount = 4;
  /**
   *  Text to be displayed when all options are selected.
   */
  @Input() allSelectedText = '';
  @Input() disabled: boolean;
  @Input() searchPlaceholder: string;
  @Output() readonly onSelect: EventEmitter<INestedDropdownChildItem | INestedDropdownChildItem[]> = new EventEmitter();
  @Output() readonly onAssign: EventEmitter<INestedDropdownChildItem | INestedDropdownChildItem[]> = new EventEmitter();
  @ViewChild(NestedDropdownComponent) dropdown: NestedDropdownComponent;

  searchBoxValue = '';
  items: INestedDropdownItem[];
  filteredItems: INestedDropdownItem[];
  selectedText: string = null;
  selectAllCheckbox: FormControl = new FormControl();
  readonly CONSTANTS = CONSTANTS;

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(private translate: TranslateService, private ellipsisPipe: EllipsisPipe) {}

  selectAll(): void {
    if (!this.disabled) {
      this.setSelectedStatus(true);
      this.selectedText = this.allSelectedText;
    }
  }

  deselectAll(): void {
    if (!this.disabled) {
      this.setSelectedStatus(false);
      this.selectedText = null;
    }
  }

  showDropdown(): void {
    this.dropdown.show();
  }

  select(item: INestedDropdownItem | INestedDropdownChildItem, isMainCategory = false): void {
    // event.stopPropagation();
    item.isSelected = !item.isSelected;
    const selectedItem: INestedDropdownChildItem[] = this.getAndSetSelectedItems(item);

    this.setButtonText();
    // if (isMainCategory && item.isSelected) {
    //   selectedItem.push(item);
    // }
    this.onSelect.emit(selectedItem);
  }

  clearAndSelectAll(): void {
    this.searchBoxValue = '';
    this.filteredItems = cloneDeep(this.items);
    this.selectAll();
  }

  onSearch(event: Event): void {
    event.stopPropagation();
    this.filteredItems = this.filterData(cloneDeep(this.items), this.searchBoxValue);
    this.selectAllCheckbox.setValue(false);
    this.onSelect.emit([]);
  }

  trackByIndex(index: number, obj: any): any {
    return index;
  }

  resetSearchInput(event: Event): void {
    event.stopPropagation();
    this.searchBoxValue = '';
    this.filteredItems = cloneDeep(this.items);
    this.selectAllCheckbox.setValue(true);
  }

  ngOnInit(): void {
    this.selectAllCheckbox.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe((response: boolean) => {
      response ? this.selectAll() : this.deselectAll();
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private filterData(data: INestedDropdownItem[], text: string): INestedDropdownItem[] {
    return data.filter((item: INestedDropdownItem) => {
      const isIncluded: boolean = item.text.toLowerCase().includes(text.toLowerCase());

      if (!isIncluded && item.children && item.children.length > 0) {
        item.children = item.children.filter((child: INestedDropdownChildItem) => child.text.toLowerCase().includes(text.toLowerCase()));
      }

      return isIncluded || (item.children && item.children.length > 0);
    });
  }

  private getAndSetSelectedItems(item: INestedDropdownItem | INestedDropdownChildItem): INestedDropdownChildItem[] {
    const mainItem: INestedDropdownItem = item as INestedDropdownItem;

    if (mainItem.children && mainItem.children.length > 0) {
      mainItem.children = mainItem.children.map((child: INestedDropdownChildItem) => {
        child.isSelected = mainItem.isSelected;

        return child;
      });
    }

    return this.getSelectedItems();
  }

  private getSelectedItems(): INestedDropdownChildItem[] {
    let isAllSelected = true;
    let selectedItems: INestedDropdownChildItem[] = [];

    this.filteredItems.forEach((nestedItem: INestedDropdownItem) => {
      if (nestedItem.children && nestedItem.children.length > 0) {
        const selectedChildren: INestedDropdownChildItem[] = nestedItem.children.filter(
          (child: INestedDropdownChildItem) => child.isSelected
        );

        selectedItems = [...selectedItems, ...selectedChildren];
        nestedItem.isSelected = selectedChildren.length === nestedItem.children.length;
      } else if (!nestedItem.children && nestedItem.isSelected) {
        selectedItems.push(nestedItem);
      } else if (!nestedItem.children && !nestedItem.isSelected) {
        isAllSelected = false;
      }
      if (!nestedItem.isSelected) {
        isAllSelected = false;
      }
    });
    this.selectAllCheckbox.setValue(isAllSelected, { emitEvent: false });

    return this.checkIfEmptyItemIsSelected(selectedItems);
  }

  private setButtonText(): void {
    const selectedChildren: INestedDropdownChildItem[] = [];
    let childrenCount = 0;

    this.filteredItems.forEach((mainItem: INestedDropdownItem) => {
      if (mainItem.children && mainItem.children.length > 0) {
        childrenCount += mainItem.children.length;
        mainItem.children.forEach((child: INestedDropdownChildItem) => {
          if (child.isSelected) {
            selectedChildren.push(child);
          }
        });
      } else if (mainItem.isSelected) {
        childrenCount++;
        selectedChildren.push(mainItem);
      }
    });

    if (selectedChildren.length === childrenCount) {
      this.selectedText = this.allSelectedText;
    } else if (selectedChildren.length > 1 && selectedChildren.length < childrenCount) {
      const activeItemsToDisplay: string = chain(selectedChildren).slice(0, this.displayActiveItemAmount).map('text').join(', ').value();

      this.selectedText = this.ellipsisPipe.transform(activeItemsToDisplay, [MAX_LABEL_TEXT_COUNT]);
    } else if (selectedChildren.length === 1) {
      this.selectedText = get(selectedChildren[0], 'text', this.placeholder);
    } else if (selectedChildren.length === 0) {
      this.selectedText = null;
    }
  }

  private setSelectedStatus(value: boolean): void {
    const selectedChildren: INestedDropdownChildItem[] = [];

    this.filteredItems.forEach((mainItem: INestedDropdownItem) => {
      mainItem.isSelected = value;

      if (mainItem.children && mainItem.children.length > 0) {
        mainItem.children.forEach((child: INestedDropdownItem) => {
          child.isSelected = value;

          if (value) {
            selectedChildren.push(child);
          }
        });
      } else if (!mainItem.children) {
        selectedChildren.push(mainItem);
      }
    });
    const selectedItems: INestedDropdownChildItem[] = this.checkIfEmptyItemIsSelected(value ? selectedChildren : []);

    this.onSelect.emit(selectedItems);
  }

  private checkIfEmptyItemIsSelected(selectedItems: INestedDropdownItem[] | INestedDropdownChildItem[]): INestedDropdownChildItem[] {
    this.filteredItems.forEach((mainItem: INestedDropdownItem) => {
      if (mainItem.isEmptyItem && mainItem.isSelected) {
        selectedItems.push(mainItem);
      }
    });

    return selectedItems;
  }
}
