import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { IVenueTypeMethods } from '../../../shared/interfaces/venue-type-methods.interface';
import { IVenueType } from '../../../shared/interfaces/venue-type.interface';
import { ApiService } from '../api/api.service';

@Injectable()
export class VenueTypesBackendService {
  constructor(private apiService: ApiService) {}

  getVenueTypes(): Observable<IVenueType[]> {
    return this.apiService.get$(`${environment.apiBaseUrl.handbook}/fbi/venuetypes`).pipe(map(response => response.content.venueTypes));
  }

  getVenueTypesMethods(): Observable<IVenueTypeMethods[]> {
    return this.apiService
      .get$(`${environment.apiBaseUrl.handbook}/fbi/venuetypemethods`)
      .pipe(map(response => response.content.venueTypeMethods));
  }

  getAccountSegments(): Observable<IVenueTypeMethods[]> {
    return this.apiService
      .get$(`${environment.apiBaseUrl.handbook}/fbi/segments`)
      .pipe(map(response => response.content.segments));
  }

  getAccountChildrenNames(): Observable<IVenueTypeMethods[]> {
    return this.apiService
      .get$(`${environment.apiBaseUrl.handbook}/fbi/account-children-names`)
      .pipe(map(response => response.content.account_children_names));
  }

  getAccountLocationNames(): Observable<IVenueTypeMethods[]> {
    return this.apiService
      .get$(`${environment.apiBaseUrl.handbook}/fbi/emplacements`)
      .pipe(map(response => response.content.emplacements));
  }
}
