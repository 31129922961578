import { Injectable } from '@angular/core';
import { IFusionChartDataSet } from '../../../../../vendor/types/fusionCharts';
import { ChartRangeGenerator } from '../../../../core/providers/charts';
import { ChartUtilsService } from '../../../../core/utils';
import { CONSTANTS } from '../../../constants';
import { VenueScore } from '../../../interfaces/analytics.interface';
import { Dictionary } from '../../../interfaces/common.interface';
import { DynamicDateRange } from '../../../interfaces/date-range.interface';
import { DateGroupPickerValue } from '../../date-range-picker';
import { DateRangePickerService } from '../../date-range-picker/date-range-picker.service';
import { FoodbacksScoreChartRawData } from './foodbacks-score-chart.interface';

@Injectable()
export class FoodbacksScoreChartService {
  static readonly AXES_ID = {
    SCORES: 'scores',
    FOODBACKS: 'foodbacks',
  };

  static readonly COLORS = [
    CONSTANTS.CHART_COLORS.FOODBACKS.SCORE_THIS_PERIOD,
    CONSTANTS.CHART_COLORS.FOODBACKS.SCORE_PREVIOUS_PERIOD,
    CONSTANTS.CHART_COLORS.FOODBACKS.FOODBACKS_COUNT,
  ];

  private readonly ACCOUNT_AVG_SCORES_DATASET_INDEX_IF_ADDED = 0;

  constructor(private dateRangePickerService: DateRangePickerService, private chartUtilsService: ChartUtilsService) {}

  getChartDataForCurrentScores(scores: VenueScore[], dateRange: DynamicDateRange): number[] {
    const chartRangeGenerator = this.chartUtilsService.getChartRangeGenerator(dateRange);

    return this.mapScoresToChartData(scores, chartRangeGenerator);
  }

  getChartDataForPreviousScores(scores: VenueScore[], dateRange: DynamicDateRange): number[] {
    const chartRangeGenerator = this.chartUtilsService.getChartRangeGenerator(dateRange);

    return this.mapPreviousScoresToChartData(scores, chartRangeGenerator);
  }

  getSameDayPreviousPeriod(date: Date, period: DateGroupPickerValue): Date {
    return this.dateRangePickerService.subtractOneUnitFromDate(date, period);
  }

  getChartDataSetFromRawData(rawData: FoodbacksScoreChartRawData, labels: Dictionary<string>): IFusionChartDataSet[] {
    const chartData: IFusionChartDataSet[] = [
      {
        seriesName: labels.AVG_SCORE_THIS_PERIOD,
        renderAs: 'spline',
        anchorBgColor: CONSTANTS.CHART_COLORS.FOODBACKS.SCORE_THIS_PERIOD.backgroundColor,
        color: CONSTANTS.CHART_COLORS.FOODBACKS.SCORE_THIS_PERIOD.borderColor,
        drawAnchors: true,
        data: rawData.currentScores.map(value => ({
          value: value ? value.toString() : '',
          toolText: rawData.accountAvgScores
            ? this.chartUtilsService.getTooltipTextForItem()
            : this.chartUtilsService.getTooltipTextForFirstItem(),
        })),
      },
      {
        seriesName: labels.AVG_SCORE_PREVIOUS_PERIOD,
        renderAs: 'line',
        dashed: '1',
        color: CONSTANTS.CHART_COLORS.FOODBACKS.SCORE_PREVIOUS_PERIOD.borderColor,
        drawAnchors: true,
        data: rawData.previousScores.map(value => ({
          value: value ? value.toString() : '',
          toolText: this.chartUtilsService.getTooltipTextForItem(),
        })),
      },
      {
        seriesName: labels.NUMBER_OF_FOODBACKS,
        renderAs: 'splinearea',
        anchorBgColor: CONSTANTS.CHART_COLORS.FOODBACKS.FOODBACKS_COUNT.backgroundColor,
        color: CONSTANTS.CHART_COLORS.FOODBACKS.FOODBACKS_COUNT.borderColor,
        parentYAxis: 'S',
        drawAnchors: true,
        data: rawData.currentAnswersCount.map(value => ({
          value: value ? value.toString() : '',
          toolText: this.chartUtilsService.getTooltipTextForItem(),
        })),
      },
    ];

    if (rawData.accountAvgScores) {
      chartData.splice(this.ACCOUNT_AVG_SCORES_DATASET_INDEX_IF_ADDED, 0, {
        seriesName: labels.ACCOUNT_AVERAGE,
        renderAs: 'spline',
        anchorBgColor: CONSTANTS.CHART_COLORS.FOODBACKS.ACCOUNT_AVG.backgroundColor,
        color: CONSTANTS.CHART_COLORS.FOODBACKS.ACCOUNT_AVG.borderColor,
        drawAnchors: true,
        data: rawData.accountAvgScores.map(value => ({
          value: value ? value.toString() : '',
          toolText: this.chartUtilsService.getTooltipTextForFirstItem(),
        })),
      });
    }

    return chartData;
  }

  private mapScoresToChartData(scores: VenueScore[], chartRangeGenerator: ChartRangeGenerator): number[] {
    return this.chartUtilsService.mapScoresToChartData(scores, chartRangeGenerator).map(data => (data ? data.value : null));
  }

  private mapPreviousScoresToChartData(scores: VenueScore[], chartRangeGenerator: ChartRangeGenerator): number[] {
    return this.chartUtilsService.mapPreviousScoresToChartData(scores, chartRangeGenerator).map(data => (data ? data.value : null));
  }
}
