<app-dialog>
  <app-dialog-title>{{ 'ONE_MORE_QUESTION.TRANSLATE_QUESTION' | translate }}</app-dialog-title>
  <app-dialog-content>
    <div class="mb-lg" *ngIf="showInfoBox">
      <app-info-form-box>{{ 'ONE_MORE_QUESTION.TRANSLATE_DIALOG_INFO' | translate: {'0': responseCount} }}</app-info-form-box>
    </div>
    <label class="c-checkbox checkbox mb-lg mt0 clickable checkbox-inline">
      <input type="checkbox" [(ngModel)]="showInactiveLanguages" (ngModelChange)="showInactiveLanguagesChange()"/>
      <span class="fa fa-check fb-checkbox"></span>
      {{ 'SHARED.FOODBACK_STATEMENTS.MAIN.TOP_BAR.SHOW_INACTIVE_LANGUAGES' | translate }}
    </label>
    <h4
      class="OneMoreQuestionLangDialog__mainHeader page-header mt0">{{ 'SHARED.SYSTEM.MAIN_QUESTION' | translate }}</h4>
    <form [formGroup]="langForm" fxLayout="row">
      <div [formGroup]="langForm.controls.activeLangForm">
        <ng-container
          *ngTemplateOutlet="
            singleTranslationForm;
            context: {
              translationForm: langForm.controls.activeLangForm.get('translationForms')['controls'][0],
              langLabel: 'SHARED.SYSTEM.DEFAULT_LANG',
              showFollowQuestionHeader: true,
              required: true
            }
          ">
        </ng-container>
      </div>
      <div class="OneMoreQuestionLangDialog__formsContainer" fxLayout="row" fxLayoutAlign="space-between center">
        <ng-container [formGroup]="langForm.controls.activeLangForm">
          <div formArrayName="translationForms" fxLayout="row" fxLayoutAlign="space-between center">
            <ng-container
              *ngFor="
                let translationForm of langForm.controls.activeLangForm.get('translationForms')['controls'];
                let langIndex = index;
                trackBy: trackByForGroup
              ">
              <ng-container *ngIf="langIndex > 0">
                <ng-container
                  *ngTemplateOutlet="
                    singleTranslationForm;
                    context: {
                      translationForm: translationForm,
                      langLabel: 'SHARED.SYSTEM.OTHER_ACTIVE_LANG',
                      showFollowQuestionHeader: false,
                      required: false
                    }
                  ">
                </ng-container>
              </ng-container>
            </ng-container>
          </div>
        </ng-container>
        <ng-container *ngIf="showInactiveLanguages">
          <ng-container [formGroup]="langForm.controls.inactiveLangForm">
            <div formArrayName="translationForms" fxLayout="row" fxLayoutAlign="space-between center">
              <ng-container
                *ngFor="
                  let translationForm of langForm.controls.inactiveLangForm.get('translationForms')['controls'];
                  let langIndex = index;
                  trackBy: trackByForGroup
                ">
                <ng-container
                  *ngTemplateOutlet="
                    singleTranslationForm;
                    context: {
                      translationForm: translationForm,
                      langLabel: 'ACCOUNT.FOODBACK.MODAL.INACTIVE_LANGUAGE',
                      showFollowQuestionHeader: false
                    }
                  ">
                </ng-container>
              </ng-container>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </form>
  </app-dialog-content>
  <app-dialog-actions>
    <button type="button" class="btn btn-foodback btn-foodback--secondary text-uppercase mr-sm" matDialogClose>
      {{ 'ACCOUNT.GROUPS.BUTTONS.CANCEL' | translate }}
    </button>
    <button type="button" class="btn btn-foodback text-uppercase" data-dismiss="modal" (click)="onSubmit()">
      {{ 'SHARED.SYSTEM.SAVE' | translate }}
    </button>
  </app-dialog-actions>
</app-dialog>

<ng-template
  #singleTranslationForm
  let-translationForm="translationForm"
  let-langLabel="langLabel"
  let-showFollowQuestionHeader="showFollowQuestionHeader"
  let-required="required">
  <div
    [ngClass]="{
      OneMoreQuestionLangDialog__langForm: showFollowQuestionHeader,
      OneMoreQuestionLangDialog__dynamicLangForm: !showFollowQuestionHeader
    }">
    <ng-container [formGroup]="translationForm">
      <ng-container
        *ngTemplateOutlet="
          singleQuestionTranslation;
          context: {
            translationForm: translationForm,
            singleQuestionFormName: 'mainQuestionForm',
            mainPlaceholderTransCode: 'SHARED.SYSTEM.MAIN_QUESTION',
            langLabel: langLabel,
            isFirstElement: showFollowQuestionHeader,
            required: required
          }
        ">
      </ng-container>
      <ng-container *ngIf="translationForm.controls.followQuestionForm">
        <h4 class="OneMoreQuestionLangDialog__mainHeader OneMoreQuestionLangDialog__followHeader page-header mt0">
          <ng-container
            *ngIf="showFollowQuestionHeader"> {{ 'SHARED.SYSTEM.FOLLOW_QUESTION' | translate }} </ng-container>
        </h4>
        <ng-container
          *ngTemplateOutlet="
            singleQuestionTranslation;
            context: {
              translationForm: translationForm,
              singleQuestionFormName: 'followQuestionForm',
              mainPlaceholderTransCode: 'SHARED.SYSTEM.FOLLOW_QUESTION',
              langLabel: langLabel,
              isFirstElement: showFollowQuestionHeader,
              required: required
            }
          ">
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</ng-template>
<ng-template
  #singleQuestionTranslation
  let-translationForm="translationForm"
  let-singleQuestionFormName="singleQuestionFormName"
  let-mainPlaceholderTransCode="mainPlaceholderTransCode"
  let-langLabel="langLabel"
  let-isFirstElement="isFirstElement"
  let-required="required">
  <div
    class="OneMoreQuestionLangDialog__questionForm"
    [ngClass]="{
      OneMoreQuestionLangDialog__defaultForm: isFirstElement,
      OneMoreQuestionLangDialog__dynamicForm: !isFirstElement
    }"
    [formGroup]="translationForm.get(singleQuestionFormName)">
    <span class="OneMoreQuestionLangDialog__langInfo">{{ langLabel | translate | uppercase }}</span>
    <div class="OneMoreQuestionLangDialog__langName">
      <span class="flag-icon flag-icon-round" [ngClass]="'flag-icon-' + translationForm.language.code"></span>
      <span>{{ translationForm.language.localizedLanguageName }}</span>
    </div>
    <app-input-with-counter
      formControlName="question"
      [label]="mainPlaceholderTransCode | translate"
      [required]="required"
      [placeholder]="mainPlaceholderTransCode | translate"
      [maxLength]="CONSTANTS.VALIDATION.ONE_MORE_QUESTION.QUESTION_MAX_LENGTH">
      <span
        *ngIf="
          translationForm.get(singleQuestionFormName).controls.question.hasError('required') &&
          translationForm.get(singleQuestionFormName).controls.question.touched
        "
        class="OneMoreQuestionLangDialog__error"
      >{{ 'SHARED.VALIDATION_MSG.REQUIRED' | translate }}</span
      >
    </app-input-with-counter>
    <ng-container formArrayName="options">
      <ng-container
        *ngFor="
          let option of translationForm.get(singleQuestionFormName).get('options')['controls'];
          let group = index;
          trackBy: trackByForControl
        ">
        <app-input-with-counter
          [formControlName]="group"
          [label]="'SHARED.SYSTEM.OPTION_NUMB' | translate: { '0': group + 1 }"
          [required]="required"
          [placeholder]="'SHARED.SYSTEM.OPTION_NUMB' | translate: { '0': group + 1 }"
          [maxLength]="CONSTANTS.VALIDATION.ONE_MORE_QUESTION.FORMAT_OPTIONS_LENGTH">
          <span *ngIf="option.hasError('required') && option.touched" class="OneMoreQuestionLangDialog__error">{{
            'SHARED.VALIDATION_MSG.REQUIRED' | translate
            }}</span>
        </app-input-with-counter>
      </ng-container>
    </ng-container>
  </div>
</ng-template>
