import { Component, ContentChildren, Directive, ElementRef, Input, OnInit, QueryList } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'app-dialog-title',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'app-dialog-title' },
})
export class DialogTitleDirective {}
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'app-dialog-content',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'app-dialog-content' },
})
export class DialogContentDirective {
  constructor(public el: ElementRef) {}
}
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'app-dialog-actions',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'app-dialog-actions' },
})
export class DialogActionsDirective {}
@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent implements OnInit {
  @Input() disableClose = false;
  @Input() color: 'primary' | 'accent' | 'warn' | null = 'primary';

  @ContentChildren(DialogTitleDirective)
  dialogTitle: QueryList<DialogTitleDirective>;
  @ContentChildren(DialogContentDirective)
  dialogContent: QueryList<DialogContentDirective>;
  @ContentChildren(DialogActionsDirective)
  dialogActions: QueryList<DialogActionsDirective>;

  ngOnInit() {}
}
