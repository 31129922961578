import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../material/material.module';
import { SharedModule } from '../shared/shared.module';
import { LayoutComponent } from './layout.component';
import * as Sidebar from './sidebar';
import { SettingsComponent } from './sidebar/settings/settings.component';
import { SidebarSettingsService } from './sidebar/settings/sidebar-settings.service';
import { SidebarAdminComponent } from './sidebar/sidebar-admin/sidebar-admin.component';
import { SidebarService } from './sidebar/sidebar.service';
import { SidebarAnalyticsService } from './sidebar/stats-nav/sidebar-analytics.service';
import { UserblockComponent } from './sidebar/userblock/userblock.component';
import { UserblockService } from './sidebar/userblock/userblock.service';

@NgModule({
  imports: [SharedModule, MaterialModule],
  exports: [RouterModule, LayoutComponent],
  declarations: [
    LayoutComponent,
    SettingsComponent,
    Sidebar.ContextPickerComponent,
    Sidebar.FooterComponent,
    Sidebar.MainLogoComponent,
    Sidebar.SidebarComponent,
    Sidebar.StatsNavComponent,
    UserblockComponent,
    SidebarAdminComponent,
  ],
  providers: [UserblockService, SidebarService, SidebarSettingsService, SidebarAnalyticsService],
})
export class LayoutModule {}
