import { Component, Input, OnInit } from '@angular/core';
import { MessageBarTypes } from './message-bar-types.enum';

@Component({
  selector: 'app-message-bar',
  templateUrl: './message-bar.component.html',
  styleUrls: ['./message-bar.component.scss'],
})
export class MessageBarComponent implements OnInit {
  @Input() type: MessageBarTypes;
  readonly messageBarTypes = MessageBarTypes;

  constructor() {}

  ngOnInit() {}
}
