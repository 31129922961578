import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { filter, find, intersection, map } from 'lodash';
import { DropdownUtil } from '../../../../core/utils/dropdown-util';
import { CONSTANTS } from '../../../constants';
import { VenueFromBackend } from '../../../form-models-interfaces';
import { IDropdownItem } from '../../../interfaces/dropdown/dropdown-item.interface';
import { IGroup } from '../../../interfaces/groups/group.interface';
import { INestedDropdownChildItem } from '../../../interfaces/nested-dropdown-filter/nested-dropdown-filter-child.interface';
import { INestedDropdownItem } from '../../../interfaces/nested-dropdown-filter/nested-dropdown-filter.interfaces';
import { GroupsService } from '../groups.service';
import { LocalStorageService } from 'ngx-webstorage';
import { ContextService } from 'app/core/providers/context/context.service';

@Component({
  selector: 'app-groups-and-venues-select',
  templateUrl: './groups-and-venues-select.component.html',
  styleUrls: ['./groups-and-venues-select.component.scss'],
})
export class GroupsAndVenuesSelectComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() set selectedVenuesUuids(value: string[]) {
    this.activeVenuesUuids = value;
    this.isDisableSelectedVenueUuidOverride = true;
  }

  @Input() set selectedGroupsUuids(value: string[]) {
    this.activeGroupsUuds = value;
    this.setActiveGroups();
  }

  @Input() groupsAccess: boolean;
  @Input() groups: IGroup[] = [];

  @Input() set venues(value: VenueFromBackend[]) {
    value.map((venue: VenueFromBackend) => {
      venue.venueGroups =
        venue.venueGroups.length === 0
          ? [this.groupsService.getEmptyGroup()]
          : venue.venueGroups;
      return venue;
    });
    value.sort((a, b) => a.brandName.toUpperCase() > b.brandName.toUpperCase() ? 1 : -1);
    this.venuesFromBackend = value;
  }

  @Input() isLoading: boolean;

  @Input() isDisableSelectedVenueUuidOverride = false;
  @Input() isVenueDropdownVisible = true;
  @Output() readonly onGroupsSelect: EventEmitter<INestedDropdownItem[]> =
    new EventEmitter();
  @Output() readonly onVenuesSelect: EventEmitter<IDropdownItem[]> =
    new EventEmitter();
  @Output() readonly onVenueItemSetChange: EventEmitter<IDropdownItem[]> =
    new EventEmitter();

  venuesItems: IDropdownItem[] = [];
  groupsItems: INestedDropdownItem[] = [];
  activeVenues: IDropdownItem[];
  activeVenuesUuids: string[];
  venuesFromBackend: VenueFromBackend[] = [];
  accountChildrenNameSingular: string;
  accountChildrenNamePlural: string;
  private activeGroupsUuds: string[];

  constructor(private groupsService: GroupsService, private contextService: ContextService) {}

  ngOnInit(): void {
    this.accountChildrenNameSingular = this.contextService.getAccountChildrenNameTranslation(true);
    this.accountChildrenNamePlural = this.contextService.getAccountChildrenNameTranslation(false);
  }

  handleGroupsSelect(selectedItems: INestedDropdownItem[]): void {
    this.isDisableSelectedVenueUuidOverride = false;
    this.selectGroups(selectedItems);
    this.onVenueItemSetChange.emit(this.venuesItems);
    this.onGroupsSelect.emit(selectedItems);
  }

  handleVenueSelect(selectItem: IDropdownItem[]): void {
    this.onVenuesSelect.emit(selectItem);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.groups) {
      this.groupsItems = DropdownUtil.getNestedDropdownItems(
        this.groups,
        ['uuid', 'name', 'venueGroups'],
        false
      );
      this.setActiveGroups();
      // this.groupsItems = this.getCategoriesGroupDropdownItems(this.groups);
      this.checkIfHideNoGroups();
      this.filterVenuesByGroups(this.activeGroupsUuds);

      if (
        this.groupsItems.length > 0 &&
        this.groups.length > 0 &&
        this.groups[0].uuid === CONSTANTS.GROUPS.NO_GROUPS_UUID
      ) {
        this.groupsItems[0].isEmptyItem = true;
      }
    }
    if (
      changes.selectedVenuesUuids &&
      changes.selectedVenuesUuids.currentValue &&
      changes.selectedVenuesUuids.currentValue.length > 0
    ) {
      this.setActiveVenues();
    }
  }

  ngOnDestroy(): void {
    this.activeVenues = [];
  }

  private setActiveGroups(): void {
    if (this.activeGroupsUuds.length > 0) {
      this.setGroupsDataSate();
    } else {
      this.setDefaultGroupsState();
    }
  }

  private setActiveVenues(): void {
    const dropdownItemOfGivenUuid: IDropdownItem[] = [];

    this.activeVenues = [];

    if (this.activeVenuesUuids?.length > 0) {
      this.activeVenuesUuids.forEach((activeVenueUuid: string) => {
        const activeVenueItem: IDropdownItem = this.venuesItems.find(
          (venue: IDropdownItem) => venue.id === activeVenueUuid
        );

        if (activeVenueItem) {
          dropdownItemOfGivenUuid.push(activeVenueItem);
        }
      });
      this.activeVenues = dropdownItemOfGivenUuid
        ? dropdownItemOfGivenUuid
        : this.venuesItems;
    } else if (this.activeVenuesUuids) {
      this.activeVenues = this.venuesItems;
    }
  }

  private setGroupsDataSate(): void {
    this.groupsItems = map(
      this.groupsItems,
      (groupItem: INestedDropdownItem) => {
        groupItem.isSelected = !!find(
          this.activeGroupsUuds,
          (groupId: string) => groupId === groupItem.id
        );

        if (groupItem.children && !groupItem.isSelected) {
          groupItem.children = groupItem.children.map(
            (groupChild: INestedDropdownChildItem) => {
              groupChild.isSelected = !!find(
                this.activeGroupsUuds,
                (groupId: string) => groupId === groupChild.id
              );

              return groupChild;
            }
          );
          groupItem.isSelected =
            groupItem.children.filter(
              (groupChild: INestedDropdownChildItem) => groupChild.isSelected
            ).length === groupItem.children.length &&
            groupItem.id !== CONSTANTS.GROUPS.NO_GROUPS_UUID;
        } else if (groupItem.children && groupItem.isSelected) {
          groupItem.children = groupItem.children.map(
            (groupChild: INestedDropdownChildItem) => {
              groupChild.isSelected = true;

              return groupChild;
            }
          );
        }

        return groupItem;
      }
    );
  }

  private setDefaultGroupsState(): void {
    this.groupsItems = map(
      this.groupsItems,
      (groupItem: INestedDropdownItem) => {
        if (groupItem.children) {
          groupItem.isSelected = true;
          groupItem.children = map(
            groupItem.children,
            (groupChild: INestedDropdownItem) => {
              groupChild.isSelected = true;

              return groupChild;
            }
          );
        }

        return groupItem;
      }
    );
  }

  private setVenuesDropdownItems(data: VenueFromBackend[]): void {
    this.venuesItems = DropdownUtil.convertToDropdownItems(data, [
      'uuid',
      'brandName',
    ]);
  }

  private filterVenuesByGroups(selectedGroupsUuids: string[]): void {
    let venuesWithGroup: VenueFromBackend[];

    if (selectedGroupsUuids.length) {
      venuesWithGroup = filter(
        this.venuesFromBackend,
        (venue: VenueFromBackend) =>
          intersection(map(venue.venueGroups, 'uuid'), selectedGroupsUuids)
            .length > 0
      );
    } else {
      venuesWithGroup = this.venuesFromBackend;
    }

    this.setVenues(venuesWithGroup);
  }

  private setVenues(venuesWithGroup: VenueFromBackend[]): void {
    this.setVenuesDropdownItems(venuesWithGroup);

    if (!this.isDisableSelectedVenueUuidOverride) {
      this.activeVenuesUuids = venuesWithGroup.map(
        (venue: VenueFromBackend) => venue.uuid
      );
    }

    this.setActiveVenues();
  }

  private getCategoriesGroupDropdownItems(
    groups: IGroup[]
  ): INestedDropdownItem[] {
    const allGroups: INestedDropdownItem[] =
      DropdownUtil.getNestedDropdownItems(
        groups,
        ['uuid', 'name', 'venueGroups'],
        true
      );

    if (this.groupsService.getSelectedGroupsFromSessionStorage().length > 0) {
      this.selectGroups(
        this.groupsService.getSelectedGroupsFromSessionStorage()
      );
    }

    return this.groupsService.getSavedSelectedGroupsFromSessionStorage(
      allGroups
    );
  }

  private selectGroups(selectedItems: INestedDropdownItem[]): void {
    if (selectedItems.length > 0) {
      this.activeGroupsUuds =
        this.groupsService.getSelectedUuids(selectedItems);
      this.filterVenuesByGroups(this.activeGroupsUuds);
    } else {
      this.setVenues([]);
    }
  }

  private checkIfHideNoGroups(): void {
    let isVenueWithNoGroups = false;

    for (let i = 0; i < this.venuesFromBackend.length; i++) {
      if (
        this.venuesFromBackend[i].venueGroups.length === 1 &&
        this.venuesFromBackend[i].venueGroups[0].uuid ===
          CONSTANTS.GROUPS.NO_GROUPS_UUID
      ) {
        isVenueWithNoGroups = true;
        break;
      }
    }
    if (!isVenueWithNoGroups) {
      this.groupsItems = this.groupsItems.filter(
        (item: INestedDropdownItem) =>
          item.id !== CONSTANTS.GROUPS.NO_GROUPS_UUID
      );
    }
  }
}
