import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { CONSTANTS } from 'app/shared/constants';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { UsersBackendService } from '../../../../../../core/providers/backend/users-backend.service';
import { IDropdownItem } from '../../../../../../shared/interfaces/dropdown/dropdown-item.interface';
import { FoodbackStatistic } from '../../foodback-statistic';

export const customContactDialogConfig: MatDialogConfig = {
  width: '550px',
  height: '340px',
  disableClose: false,
};

export enum ContactedStatus {
  ALL_STATUS = 'all',
  CONTACTED = 'contacted',
  NOT_CONTACTED = '!contacted',
}

@Component({
  selector: 'app-foodback-update-contact',
  templateUrl: './foodback-update-contact.component.html',
  styleUrls: ['./foodback-update-contact.component.scss'],
})
export class FoodbackUpdateContactComponent implements OnInit, OnDestroy {
  form: FormGroup;
  contactStatuses: IDropdownItem[] = [
    {
      id: ContactedStatus.CONTACTED,
      translationKey: 'HOME.FOODBACKS.CONTACTED.STATUS_CONTACTED',
    },
    {
      id: ContactedStatus.NOT_CONTACTED,
      translationKey: 'HOME.FOODBACKS.CONTACTED.STATUS_NOT_CONTACTED',
    },
  ];
  readonly CONSTANTS: any = CONSTANTS;
  users: IDropdownItem[] = [];
  private onDestroy$ = new Subject<void>();

  constructor(
    private formBuilder: FormBuilder,
    private mdDialogRef: MatDialogRef<FoodbackUpdateContactComponent>,
    private usersBackendService: UsersBackendService,
    @Inject(MAT_DIALOG_DATA) private dataDialog: FoodbackStatistic
  ) {}

  ngOnInit(): void {
    this.createForm();
    this.getAndSetOwners(this.dataDialog.venueUuid);
  }

  hide() {
    this.mdDialogRef.close();
  }

  save() {
    this.form.markAllAsTouched();

    if (this.form.valid) {
      this.mdDialogRef.close({
        ownerUuid: this.form.value.ownerUuid.id,
        note: this.form.value.note === '' ? null : this.form.value.note,
        flags:
          this.form.value.isContacted.id === ContactedStatus.CONTACTED
            ? [ContactedStatus.CONTACTED]
            : [],
      });
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private createForm() {
    this.form = this.getContactedForm();
  }

  private getContactedForm(): FormGroup {
    return this.formBuilder.group({
      isContacted: [this.contactStatuses[0], [Validators.required]],
      ownerUuid: [null, [Validators.required]],
      note: [''],
    });
  }

  private getAndSetOwners(venueUuid: string) {
    this.usersBackendService
      .getListOfPotentialRecipients$(venueUuid)
      .pipe(
        takeUntil(this.onDestroy$),
        map(res =>
          res.recipients
            .map(user => ({
              id: user.uuid,
              text: user.name ? user.name : user.email,
            }))
            .sort((a, b) =>
              a.text.toLowerCase().localeCompare(b.text.toLowerCase())
            )
        )
      )
      .subscribe(res => {
        this.users = res;
        this.setFormValue();
      });
  }

  private setFormValue() {
    this.form.controls.isContacted.setValue(
      this.dataDialog.flags.length > 0
        ? this.contactStatuses[0]
        : this.contactStatuses[1]
    );
    this.form.controls.note.setValue(this.dataDialog.note);

    if (this.dataDialog.ownerUuid) {
      const user = this.users.find(usr => usr.id === this.dataDialog.ownerUuid);

      this.form.controls.ownerUuid.setValue(user);
    }
  }
}
