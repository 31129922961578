import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AccountAdminGuard } from './account-admin-guard.service';
import { AuthGuard } from './auth-guard.service';
import { AuthService } from './auth.service';
import { FoodbackAdminGuard } from './foodback-admin-guard.service';
import { HasAccessToFbiGuard } from './has-access-to-fbi-guard.service';
import { HasNoAccessToFbiGuard } from './has-no-access-to-fbi-guard.service';
import { LoginRoutes } from './login.routes';
import { LogoutService } from './logout.service';
import { VenueAdminGuard } from './venue-admin-guard.service';

@NgModule({
  providers: [
    AccountAdminGuard,
    AuthService,
    AuthGuard,
    FoodbackAdminGuard,
    HasAccessToFbiGuard,
    HasNoAccessToFbiGuard,
    LogoutService,
    VenueAdminGuard,
  ],
  imports: [RouterModule.forChild(LoginRoutes)],
  exports: [RouterModule],
})
export class LoginModule {}
