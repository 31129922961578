export enum SidebarAnalyticsMenus {
  DASHBOARD = 'DASHBOARD',
  DEMOGRAPHICS = 'DEMOGRAPHICS',
  FOODBACKS_ALL_FOODBACKS = 'FOODBACKS_ALL_FOODBACKS',
  FOODBACKS_OVERVIEW = 'FOODBACKS_OVERVIEW',
  LIVE_NOTIFICATION = 'LIVE_NOTIFICATION',
  VENUE_SCOREBOARD = 'VENUE_SCOREBOARD',
  WINNER_STATISTIC = 'WINNER_STATISTIC',
  REWARDS = 'REWARDS',
  ONE_MORE_QUESTION = 'ONE_MORE_QUESTION',
  CAMPAIGNS = 'CAMPAIGNS'
}
