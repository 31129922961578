<app-dialog>
  <app-dialog-title>
    <span *ngIf="isEdit; else headerAdd">{{ 'SHARED.QUESTIONS.EDIT_QUESTION' | translate }}</span>
    <ng-template #headerAdd>
      <span>{{ 'SHARED.QUESTIONS.ADD_QUESTION' | translate }}</span>
    </ng-template>
  </app-dialog-title>
  <app-dialog-content>
    <form *ngIf="accStatementsForm" [formGroup]="accStatementsForm">
      <div class="ModalBody col">
        <p class="ModalBody-description" [innerHTML]="'SHARED.FOODBACK_STATEMENTS.MAIN.ADD_QUESTION_MODAL.DESCRIPTION' | translate"></p>

        <app-select
          [items]="questionCategories"
          formControlName="category"
          [placeholder]="'SHARED.SYSTEM.CHOOSE_CATEGORY' | translate"
          [label]="'SHARED.SYSTEM.CHOOSE_CATEGORY' | translate"
          [isSearchModeEnable]="false"
          class="FolderPaymentConfig-tip mb-lg">
        </app-select>

        <div formGroupName="translations">
          <div class="ModalBody-languageLabel pt-lg">
            <span *ngIf="activeLanguages && activeLanguages.length > 0">
              <b>{{ 'SHARED.LANGUAGES.ACTIVE_LANGUAGES' | translate }}:</b>
            </span>
            <ng-container [ngTemplateOutlet]="questionsLanguages" [ngTemplateOutletContext]="{ languages: activeLanguages }"></ng-container>
            <span *ngIf="inActiveLanguages && inActiveLanguages.length > 0">
              <b>{{ 'ACCOUNT.FOODBACK.MODAL.INACTIVE_LANGUAGES' | translate }}:</b>
            </span>
            <ng-container
              [ngTemplateOutlet]="questionsLanguages"
              [ngTemplateOutletContext]="{ languages: inActiveLanguages }"></ng-container>
          </div>
          <ng-template #questionsLanguages let-languages="languages">
            <div class="mb-lg" *ngIf="languages && languages.length > 0">
              <div *ngFor="let language of languages">
                <app-input-with-counter
                  class="ModalBody-questionField"
                  [label]="'SHARED.LANGUAGES.' + language.code | uppercase | translate"
                  [required]="language.code === defaultLanguage.code"
                  [placeholder]="'SHARED.FOODBACK_STATEMENTS.EXAMPLES_SHORT.' + language.code | uppercase | translate"
                  [formControlName]="language.code"
                  [iconClass]="'flag-icon flag-icon-round flag-icon-bigger flag-icon-' + language.code"
                  [maxLength]="50"
                  [hostTouched]="accStatementsForm.get('translations').get(language.code).touched">
                  <mat-error [appErrors]="'translations.' + language.code" [errors]="errors"> </mat-error>
                </app-input-with-counter>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
    </form>
  </app-dialog-content>
  <app-dialog-actions>
    <button type="button" class="btn btn-foodback btn-foodback--secondary text-uppercase mr-sm" (click)="cancel()">
      {{ 'SHARED.SYSTEM.CANCEL' | translate }}
    </button>

    <button
      class="btn btn-foodback text-uppercase"
      *ngIf="isEdit; else buttonForCreate"
      (click)="onSubmit(accStatementsForm)"
      [disabled]="isDisabledButton || accStatementsForm.disabled">
      {{ 'SHARED.SYSTEM.SAVE_CHANGES' | translate }}
    </button>
    <ng-template #buttonForCreate>
      <button
        class="btn btn-foodback pull-right text-uppercase"
        type="submit"
        (click)="onSubmit(accStatementsForm)"
        [disabled]="isDisabledButton">
        {{ 'SHARED.QUESTIONS.ADD_QUESTION' | translate }}
      </button>
    </ng-template>
  </app-dialog-actions>
</app-dialog>
