import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { LogoutService } from '../../../components/login/logout.service';
import { RolesService } from '../../../core/providers/backend/roles.service';
import { UserService } from '../../../core/providers/user/user.service';
import { LayoutUtil } from '../../../core/utils';
import { CONSTANTS } from '../../../shared/constants';
import { UserData } from '../../../shared/interfaces/login-object';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  // eslint-disable-next-line @angular-eslint/use-component-view-encapsulation
  encapsulation: ViewEncapsulation.None,
})
export class FooterComponent implements OnInit, OnDestroy {
  isAdminPanel: boolean;
  isCollapsed = false;
  subscriptions: Subscription = new Subscription(null);
  hasAdminPermission: boolean;
  userData: UserData = null;
  userEmail = '';
  ROUTES = CONSTANTS.ROUTES;
  CONSTANTS = CONSTANTS;

  private basePath = environment.apiBaseUrl.handbook;

  constructor(
    private layoutUtil: LayoutUtil,
    private rolesService: RolesService,
    private logoutService: LogoutService,
    private userService: UserService
  ) {}

  showAdminPanel(): void {
    this.layoutUtil.showAdminPanel();
  }

  hideAdminPanel(): void {
    this.layoutUtil.hideAdminPanel();
  }

  logOut(): void {
    this.logoutService.logOut();
  }

  getProfilePhoto(): string {
    return this.userData.picture.children && this.userData.picture.children.length > 0
      ? `${this.basePath}/storage/files/${this.userData.picture.children[0].uuid}`
      : '';
  }

  ngOnInit() {
    this.subscriptions.add(
      this.layoutUtil.getIsCollapsed$().subscribe(isSidebarCollapsed => {
        this.isCollapsed = isSidebarCollapsed;
      })
    );
    this.subscriptions.add(
      this.layoutUtil.getIsAdminPanel$().subscribe(isAdminPanelActive => {
        this.isAdminPanel = isAdminPanelActive;
      })
    );
    this.subscriptions.add(
      this.userService.getUserData$().subscribe(userData => {
        this.userData = userData;
        this.userEmail = this.userService.getUserEmail();
      })
    );
    this.hasAdminPermission = this.rolesService.isAdmin();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
