import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { chain, every, filter, find } from 'lodash';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { IMultiSelectTagItem } from '../../../../core/interfaces/multi-select-tag-item.interface';
import { Debounce } from '../../../../core/utils/decorators';
import { CONSTANTS } from '../../../constants';
import { VenueFromBackend } from '../../../form-models-interfaces';
import { IDropdownItem } from '../../../interfaces/dropdown/dropdown-item.interface';
import { IGroup } from '../../../interfaces/groups/group.interface';
import { GroupsService } from '../groups.service';

@Component({
  selector: 'app-venues-list',
  templateUrl: './venues-list.component.html',
  styleUrls: ['./venues-list.component.scss'],
})
export class VenuesListComponent implements OnInit, OnDestroy {
  venues: VenueFromBackend[] = [];
  filterGroupsCtrl = new FormControl();
  groupsDropdownItems: IDropdownItem[];
  readonly filterCriteria = {
    items: [],
    venueName: '',
    venueGroups: [],
  };
  private focusedVenueId: string;
  private groups: IGroup[] = [];
  private onDestroy$ = new Subject<void>();

  constructor(private groupsService: GroupsService, private changeDetectionRef: ChangeDetectorRef) {}

  trackVenue(index: number, venue: VenueFromBackend) {
    return venue.uuid;
  }

  @Debounce(CONSTANTS.TIME_IN_MS.TIME_500)
  updateItemsFilterCriteria() {
    this.filterCriteria.items = filter(this.venues, (venue: VenueFromBackend) => {
      const matchesName: boolean = venue.brandName.toLowerCase().includes(this.filterCriteria.venueName.toLowerCase());
      const matchesGroups: boolean = every(
        this.filterCriteria.venueGroups,
        (group: IMultiSelectTagItem) => !!find(venue.venueGroups, { name: group.text })
      );

      return matchesName && matchesGroups;
    });
    this.changeDetectionRef.detectChanges();
  }

  updateVenueNameFilterCriteria(value: string) {
    this.filterCriteria.venueName = value;
    this.updateItemsFilterCriteria();
  }

  @Debounce(CONSTANTS.TIME_IN_MS.TIME_100)
  unassignVenueFromGroup(item: IDropdownItem, venue: VenueFromBackend) {
    this.groupsService.unassignVenueFromGroup$(venue, item.id).pipe(takeUntil(this.onDestroy$)).subscribe();
    this.changeDetectionRef.detectChanges();
  }

  assignGroup(item: IDropdownItem, venue: VenueFromBackend) {
    this.groupsService.assignGroupToVenue$(venue, item.id).pipe(takeUntil(this.onDestroy$)).subscribe();
  }

  isVenueFocused({ uuid }: VenueFromBackend): boolean {
    return this.focusedVenueId === uuid;
  }

  ngOnInit() {
    this.getVenues()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((venues: VenueFromBackend[]) => {
        this.venues = chain(venues).filter('isEnabled').value();
        this.updateItemsFilterCriteria();
      });
    this.getGroups()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(val => {
        this.groups = val;
        this.groupsDropdownItems = this.groupsService.getGroupsAsDropdownItems(this.groups);
        this.updateFilteredGroups();
      });
    this.filterGroupsCtrl.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe((value: IDropdownItem[]) => {
      this.filterCriteria.venueGroups = value;
      this.updateItemsFilterCriteria();
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private updateFilteredGroups() {
    // eslint-disable-next-line
    if (this.filterCriteria.venueGroups && this.filterCriteria.venueGroups.length > 0) {
      for (let i = 0; i < this.filterCriteria.venueGroups.length; i++) {
        const filteredGroup = this.filterCriteria.venueGroups[i];
        const group = this.groups.find(g => g.uuid === filteredGroup.id);

        filteredGroup.text = group.name;
      }
    }
  }

  private getVenues(): Observable<VenueFromBackend[]> {
    return this.groupsService.getVenues$();
  }

  private getGroups(): Observable<IGroup[]> {
    return this.groupsService.getGroups$().pipe(map(items => items.filter(item => !item.isEmptyGroup)));
  }
}
