import { Injectable } from '@angular/core';
import { find } from 'lodash';
import {
  FoodbackDetails,
  FoodbackDetailsQuestions,
} from '../../../components/venue/foodbacks/all-foodbacks/foodback-statistic';
import { LanguageService } from './language.service';

@Injectable()
export class TooltipsHelperService {
  private readonly defaultLanguage: string = 'en-GB';

  constructor(private languageService: LanguageService) {
    this.defaultLanguage = this.languageService.getDefaultLanguage() ? this.languageService.getDefaultLanguage().code : null;
  }

  getProperDescription(category: FoodbackDetails): string {
    let translation = find(category.translations, this.callbackForFind.bind(this));
    if (translation === undefined) {
      translation = find(category.translations, this.callbackForFindMatching.bind(this));
    }

    return translation ? translation.value : category.translations[0].value;
  }

  private callbackForFind(question: FoodbackDetailsQuestions): boolean {
    if (question.lang === this.defaultLanguage) {
      return true;
    }
    return false;
  }

  private callbackForFindMatching(question: FoodbackDetailsQuestions): boolean {
    return this.isMatchInLanguageFallbacks(question.lang);
  }

  private isMatchInLanguageFallbacks(questionLang: string): boolean {
    const fallbackGroups: string[][] = [
      ['en-AU', 'en-CA', 'en-GB', 'en-US'],
      ['fr-FR', 'fr-CA'],
      ['pt-PT', 'pt-BR'],
    ];

    for (let gix = 0; gix < fallbackGroups.length; gix++) {
      if (
        fallbackGroups[gix].includes(questionLang) &&
        fallbackGroups[gix].includes(this.defaultLanguage)
      ) {
        return true;
      }
    }

    return false;
  }
}
