import { map as _map, map } from 'lodash';
import { IDropdownItem } from '../../shared/interfaces/dropdown/dropdown-item.interface';
import { INestedDropdownChildItem } from '../../shared/interfaces/nested-dropdown-filter/nested-dropdown-filter-child.interface';
import { INestedDropdownItem } from '../../shared/interfaces/nested-dropdown-filter/nested-dropdown-filter.interfaces';

export class DropdownUtil {
  static convertToDropdownItems<T>(items: T[], keys: [keyof T, keyof T]): IDropdownItem[] {
    return map(items, (item: T) => ({
      id: item[keys[0]].toString(),
      text: item[keys[1]].toString(),
    }));
  }

  static getNestedDropdownItems<T>(items: T[], keys: [keyof T, keyof T, keyof T], isSelected: boolean): INestedDropdownItem[] {
    return [
      ..._map(items, (item: T) => {
        // eslint-disable-next-line no-magic-numbers
        const children: any = item[keys[2]];

        return {
          isSelected,
          id: item[keys[0]].toString(),
          text: item[keys[1]].toString(),
          // eslint-disable-next-line
          children: children !== null ? DropdownUtil.getNestedChildItems(children, [keys[0], keys[1]], isSelected) : null,
        };
      }),
    ];
  }

  static getNestedChildItems<T>(items: T[], keys: [keyof T, keyof T], isSelected: boolean): INestedDropdownChildItem[] {
    return [
      ..._map(items, (item: T) => ({
        isSelected,
        id: item[keys[0]].toString(),
        text: item[keys[1]].toString(),
      })),
    ];
  }
}
