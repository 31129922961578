import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FoodbackAccount } from '../../../core/classes/account/account.class';
import { AccountService } from '../../../core/providers/account/account.service';
import { NavigationService } from '../../../core/providers/navigation/navigation.service';

@Injectable()
export class VenueDashboardGuard implements CanActivate {
  constructor(private accountService: AccountService, private venueNavigationService: NavigationService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.accountService.getAccount$().pipe(
      map((data: FoodbackAccount) => {
        if (data.hasAccessTo().venueDashboard) {
          return true;
        }

        this.venueNavigationService.goToVenueOverview(route.params.venueId);

        return false;
      })
    );
  }
}
