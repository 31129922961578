import { Pipe, PipeTransform } from '@angular/core';
import { toNumber } from 'lodash';

@Pipe({
  name: 'currencyDenominator',
})
export class CurrencyDenominatorPipe implements PipeTransform {
  private static readonly DENOMINATOR_DIVIDER = 100;

  transform(value: string | number): number {
    return toNumber(value) / CurrencyDenominatorPipe.DENOMINATOR_DIVIDER;
  }
}
