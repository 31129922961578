import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { DateRange as MomentDateRange } from 'moment-range';
import { ChartRangeGenerator, ChartTooltipIntervalAndFormat } from './chart-range-generator.interface';

@Injectable()
export class ChartWeekDayRangeService implements ChartRangeGenerator {
  private static interval: moment.unitOfTime.Base = 'day';

  constructor(private range: MomentDateRange) {}

  getDates(): Date[] {
    return Array.from(this.range.by(ChartWeekDayRangeService.interval), date => date.toDate());
  }

  getChartLabels(): string[] {
    return Array.from(this.range.by(ChartWeekDayRangeService.interval), date => date.format('ddd'));
  }

  shouldDateDisplayAtSameIndex(a: Date, b: Date): boolean {
    return moment(a).isSame(moment(b), ChartWeekDayRangeService.interval);
  }

  shouldPreviousDateDisplayAtSameIndex(a: Date, b: Date): boolean {
    const interval = ChartWeekDayRangeService.interval;
    const rangeLength = Math.abs(this.range.start.diff(this.range.end, interval));

    return moment(b).isSame(moment(a).subtract(rangeLength, interval), interval);
  }

  getIntervalAndFormatForTooltip(): ChartTooltipIntervalAndFormat {
    return {
      interval: ChartWeekDayRangeService.interval,
      format: 'ddd, MMM D',
    };
  }
}
