import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CONSTANTS } from '../../../shared/constants';
import { ApiResponse } from '../../../shared/interfaces/api-response.interface';
import { NotificationService } from '../notification/notification.service';

@Injectable()
export class GenericErrorHandlerService {
  constructor(private notificationService: NotificationService, private translate: TranslateService) {}

  handleApiError(error: ApiResponse): void {
    if (
      error.status === CONSTANTS.REQUEST_ERRORS.HTTP_400 ||
      error.status === CONSTANTS.REQUEST_ERRORS.HTTP_404 ||
      error.status === CONSTANTS.REQUEST_ERRORS.HTTP_409 ||
      error.status === CONSTANTS.REQUEST_ERRORS.HTTP_402 ||
      error.status === CONSTANTS.REQUEST_ERRORS.HTTP_403
    ) {
      // such cases are not considered errors:
      // 400 usually means that the form was incorrectly filled
      // 402 means feature is not enabled in current account package
      // 404 means REST lack of resource
      // 409 is REST "duplicate" resource
      return;
    }

    this.defaultErrorHandler();
  }

  private defaultErrorHandler() {
    this.translate.get('SHARED.NOTIFICATIONS.GENERAL_ERROR').subscribe(({ TEXT }) => {
      this.notificationService.error(TEXT);
    });
  }
}
