<div #multiselect [ngClass]="{ 'MultiSelectTag-edit-mode form-control': isEditMode() }" class="MultiSelectTag">
  <div #tagsContainer class="MultiSelectTag-tags-container" *ngIf="tags.length; else noTags">
    <div *ngFor="let tag of tags" class="MultiSelectTag-single-tag">
      <span class="MultiSelectTag-single-tag-text" [ngClass]="{ 'MultiSelectTag-single-tag-text-edit': isEditMode() }"
            appTooltip [text]="tag.text && tag.text.length >= CONSTANTS.PAGE_DEFAULTS.GROUPS_NAME_LENGTH? tag.text : null">
        {{ tag.text | ellipsis: [CONSTANTS.PAGE_DEFAULTS.GROUPS_NAME_LENGTH] }}
      </span>
      <i *ngIf="isEditMode() && !disable" (click)="removeTag(tag, $event)" class="mdi mdi-close"></i>
    </div>
  </div>

  <ng-template #noTags>
    <span *ngIf="!isEditMode()">{{ noTagsPlaceholder | translate }}</span>
  </ng-template>

  <ng-container *ngIf="isEditMode()">
    <input
      #input
      (focus)="handleInputFocus($event)"
      (keyup)="handleInputKeyup($event, input.value)"
      [disabled]="disable"
      [placeholder]="placeholder | translate" />
  </ng-container>

  <i
    *ngIf="!readonlyMode"
    (click)="enableEditMode()"
    [id]="componentId"
    [hidden]="isEditMode()"
    class="MultiSelectTag-mode-switcher mdi mdi-18px mdi-pencil clickable-icon"></i>

  <div *ngIf="editMode && !permanentEditMode" class="MultiSelectTagOptions">
    <div (click)="disableEditMode()">
      <i class="mdi mdi-close"></i>
    </div>
  </div>
</div>

<ng-template #multiSelectTagListRef let-select="select" let-close="close">
  <div class="MultiSelectTagDropdown" #multiSelectListContainerRef>
    <div class="MultiSelectTagDropdownBody" #multiSelectBody>
      <div
        *ngFor="let item of filteredItems | sortByField: 'text':true"
        [class.MultiSelectTagDropdownBody__selected]="item.isSelected"
        class="MultiSelectTagDropdownBody__item">
        <mat-checkbox color="primary" [(ngModel)]="item.isAdded" (ngModelChange)="select(item)"> {{ item.text }} </mat-checkbox>
      </div>

      <div *ngIf="!filteredItems.length" class="MultiSelectTagDropdownBody__item">
        {{ noResultsPlaceholder | translate }} <b>{{ input.value }}</b>
      </div>
    </div>
  </div>
</ng-template>
