<!-- ROUND BUTTON FOR COLLAPSING SIDEBAR-->
<button class="btn-collapse" (click)="toggleSidebar()">
  <i [ngClass]="arrowButtonConditions()" class="mdi"></i>
</button>
<!-- /ROUND BUTTON FOR COLLAPSING SIDEBAR-->

<div class="aside-inner">
  <!-- FOODBACK LOGO -->
  <app-main-logo [isCollapsed]="isCollapsed" [showTopLeveDashboardBtn]="!isTopLevelDashboard && (isAllowedToAccessTopLevelDashboard$ | async)" (onTopLevelDashClick)="goToTopLevelDashboard()"></app-main-logo>
  <!-- /FOODBACK LOGO -->

  <!-- NAV MENU WITH CONTEXT, SETTINGS, STATISTICS -->
  <nav class="sidebar">
    <!-- ADMIN INFO FOR ADMINISTRATOR PANEL-->
    <ng-container *ngIf="isAdminPanel; else notAdmin">
      <app-userblock></app-userblock>
      <app-sidebar-admin [isSidebarCollapsed]="isCollapsed"></app-sidebar-admin>
    </ng-container>
    <!-- /ADMIN INFO FOR ADMINISTRATOR PANEL-->

    <!-- CONTEXT CHANGER PANEL FOR VENUE/GROUP-->
    <ng-template #notAdmin>
      <app-context-picker [isTopLevelDashboard]="isTopLevelDashboard" (onToggleAside)="toggleSidebar()" [isMobileDevice]="isMobileDevice"></app-context-picker>

      <!-- SETTINGS NAVIGATION, DEPENDS ON VENUE/GROUP CONTEXT-->
      <app-settings *ngIf="canSeeSettings && !isTopLevelDashboard" [isSidebarCollapsed]="isCollapsed"></app-settings>
      <!-- /SETTINGS NAVIGATION, DEPENDS ON VENUE/GROUP CONTEXT-->

      <!-- STATIC MENU ABOUT STATS, ADMIN NAVIGATION-->
      <app-stats-nav *ngIf="!isTopLevelDashboard" [isSidebarCollapsed]="isCollapsed"></app-stats-nav>
      <!-- /STATIC MENU ABOUT STATS, ADMIN NAVIGATION-->
    </ng-template>
    <!-- /CONTEXT CHANGER PANEL FOR VENUE/GROUP-->

    <div *ngIf="isAdminPanel && !isCollapsed" class="sidebar-version">
      {{ 'VENUE.FOLDER.BILL_FOLDERS.LIST.HEADERS.VERSION' | translate }} {{ info.version }}.{{ info.buildNumber }}
      <br />
      {{ info.buildDate }}
    </div>

    <div *ngIf="isTopLevelDashboard" class="sidebar-top-level-dash-info">
      <p>{{'SHARED.TOP_LEVEL_DASHBOARD.SIDEBAR_INFO_1' | translate}}</p>
      <p>{{'SHARED.TOP_LEVEL_DASHBOARD.SIDEBAR_INFO_2' | translate}}</p>
    </div>
  </nav>
  <!-- /NAV MENU WITH CONTEXT, SETTINGS, STATISTICS -->
  <!-- SIDEBAR FOOTER WITH USER LOG SETTINGS -->
  <app-footer></app-footer>
  <!-- SIDEBAR FOOTER WITH USER LOG SETTINGS -->
</div>

<nav class="nav sidebar-nav nav-floating sidebar-floating" *ngIf="isExpanded">
  <button class="btn close-sidebar" (click)="closeExpandedSidebar()">
    <i class="icon-close"></i>
  </button>
  <div class="sidebar-floating-contents">
    <div class="sidebar-section sidebar-section--account">
      <div>
        <span class="sidebar-info-header sidebar-section-name text-thin mb-xl"><b>{{'SHARED.SYSTEM.WHERE_TO_NEXT' | translate}}</b></span>
        <span class="sidebar-section-name text-thin"><b>{{ 'SHARED.SYSTEM.SELECT_ACCOUNT_VENUE' | translate: { childrenName: accountChildrenNameSingular} }}</b></span>
      </div>
      <div class="account-select-container">
        <app-select
          [formControl]="accountCtrl"
          [items]="accountsList"
          [showAdditionalInfo]="true"
          [label]="('SHARED.SYSTEM.SEARCH_ACCOUNT' | translate) + '...'"
          (onSelectOpen)="onSelectOpen($event)"></app-select>
        <ng-container *ngIf="!isTopLevelDashboard">
          <span *ngIf="isBackToAccountLinkVisible()" class="account-info-label">{{'SHARED.SYSTEM.GO_TO_ACCOUNT' | translate | uppercase}}</span>
          <a
            *ngIf="isBackToAccountLinkVisible(); else staticAccountName"
            [routerLink]="ACCOUNT_ROUTES_PATHS_FULL.SCOREBOARD | routerLink: [CONSTANTS.ROUTE_PARAMS.ACCOUNT_ID, account.uuid]"
            class="account-name account-name--clickable">
            <span *ngIf="accountsList?.length > 1"> {{ 'SIDEBAR.BACK_TO' | translate }} </span>
            <span appTooltip [text]="account.name && account.name.length > CONSTANTS.PAGE_DEFAULTS.MAX_NAME_LENGTH ? account.name : null">{{
              account.name
              }}</span>
          </a>
        </ng-container>
        <ng-template #staticAccountName>
          <span
            appTooltip
            [text]="account.name && account.name.length > CONSTANTS.PAGE_DEFAULTS.MAX_NAME_LENGTH ? account.name : null"
            class="account-name">
            {{ account.name }}
          </span>
        </ng-template>
      </div>
    </div>
    <div class="sidebar-section sidebar-section--venues" *ngIf="venues.length && !isTopLevelDashboard">
      <div class="sidebar-section-title ml-xl mb-sm">
        <mat-icon>location_on</mat-icon>
        <span class="sidebar-section-name text-thin">{{ 'SHARED.SYSTEM.AVAILABLE_VENUES' | translate: { childrenName: accountChildrenNamePlural} }}</span>
      </div>
      <div class="venue-filter" *ngIf="venues.length > 20">
        <mat-form-field>
          <mat-label>{{ 'SHARED.SYSTEM.SEARCH_VENUES' | translate }}</mat-label>
          <input matInput autocomplete="off" list="autocompleteOff" [formControl]="venueFilterCtrl" />
        </mat-form-field>
      </div>
      <ul class="nav venues-list">
        <li *ngFor="let venue of filteredVenues | ascending: 'brandName'">
          <a
            *ngIf="!isContextSetAsCurrentContext(CONSTANTS.CONTEXT.VENUE, venue.uuid); else staticVenueName"
            [routerLink]="VENUE_ROUTES_PATHS_FULL.OVERVIEW.VENUE | routerLink: [CONSTANTS.ROUTE_PARAMS.VENUE_ID, venue.uuid]"
            appTooltip
            [text]="venue.brandName && venue.brandName.length > CONSTANTS.PAGE_DEFAULTS.MAX_NAME_LENGTH ? venue.brandName : null"
            class="venue-link">
            <span>{{ venue.brandName }}</span>
          </a>
          <ng-template #staticVenueName>
            <span
              appTooltip
              [text]="venue.brandName && venue.brandName.length > CONSTANTS.PAGE_DEFAULTS.MAX_NAME_LENGTH ? venue.brandName : null"
              class="venue-link venue-link--disabled"
              >{{ venue.brandName }}</span
            >
          </ng-template>
        </li>
      </ul>
    </div>
  </div>
</nav>
<div *ngIf="isExpanded" class="sidebar-backdrop" (click)="closeExpandedSidebar()"></div>
