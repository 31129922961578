import { Pipe, PipeTransform } from '@angular/core';
import { sortBy } from 'lodash';

@Pipe({
  name: 'ascendingDate',
})
export class AscendingDatePipe implements PipeTransform {
  transform<T>(value: T[], arg?: string): T[] {
    return sortBy(value, (element: T) => Date.parse(element[arg]));
  }
}
