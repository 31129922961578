import { CONSTANTS } from '../../../../shared/constants';

export const ACCOUNT_ONE_MORE_QUESTION_ROUTES_PATHS_PARTIALS: {
  BASE_PATH: string;
  CREATE: string;
  EDIT: string;
} = {
  BASE_PATH: CONSTANTS.ROUTES.ONE_MORE_QUESTION,
  CREATE: CONSTANTS.ROUTES.CREATE,
  EDIT: CONSTANTS.ROUTES.EDIT,
};
export const ACCOUNT_ONE_MORE_QUESTION_ROUTES_PATHS_FULL: {
  CREATE: string;
  EDIT: string;
} = {
  CREATE: `${ACCOUNT_ONE_MORE_QUESTION_ROUTES_PATHS_PARTIALS.BASE_PATH}/${ACCOUNT_ONE_MORE_QUESTION_ROUTES_PATHS_PARTIALS.CREATE}`,
  EDIT: `${ACCOUNT_ONE_MORE_QUESTION_ROUTES_PATHS_PARTIALS.BASE_PATH}/${ACCOUNT_ONE_MORE_QUESTION_ROUTES_PATHS_PARTIALS.EDIT}`,
};
