import { CONSTANTS } from '../../../../shared/constants';

export const VENUE_USERS_ROUTES_PATHS_PARTIALS = {
  BASE_PATH: CONSTANTS.ROUTES.USERS,
  CREATE: CONSTANTS.ROUTES.CREATE,
  LIST: CONSTANTS.ROUTES.LIST,
};
export const VENUE_USERS_ROUTES_PATHS_FULL = {
  CREATE: `${VENUE_USERS_ROUTES_PATHS_PARTIALS.BASE_PATH}/${VENUE_USERS_ROUTES_PATHS_PARTIALS.CREATE}`,
  LIST: `${VENUE_USERS_ROUTES_PATHS_PARTIALS.BASE_PATH}/${VENUE_USERS_ROUTES_PATHS_PARTIALS.LIST}`,
};
