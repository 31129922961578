<div class="LanguageForm">
  <h4 class="page-header mt0">{{ 'SHARED.LANGUAGES.ACTIVE_LANGUAGES' | translate }}</h4>
  <p>{{ 'SHARED.LANGUAGES.LANG_FORM_DESC' | translate }}</p>
  <div class="mb-xl">
    <ul *ngIf="contextType">
      <li
        *ngFor="
          let text of (contextType === CONSTANTS.CONTEXT.ACCOUNT ? 'SHARED.LANGUAGES.BULLETS_ACCOUNT' : 'SHARED.LANGUAGES.BULLETS_VENUE')
            | translate: { childrenName: accountChildrenNameSingular } 
        "
        [innerHTML]="text"></li>
    </ul>
  </div>
  <div class="table-responsive">
    <table class="table">
      <thead>
        <tr>
          <th>{{ 'SHARED.SYSTEM.LANGUAGE' | translate }}</th>
          <th>{{ 'SHARED.SYSTEM.ACTIVE' | translate  }}</th>
          <th *ngIf="contextType === CONSTANTS.CONTEXT.ACCOUNT">{{ 'SHARED.SYSTEM.ENFORCED' | translate }}</th>
          <th>{{ 'SHARED.SYSTEM.DEFAULT' | translate }}</th>
          <th>{{ 'SHARED.LANGUAGES.TRANSLATION_STATUS' | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let language of languagesList; trackBy: trackByForLanguage">
          <td>
            <span class="LanguageForm-flag flag-icon flag-icon-round" [ngClass]="'flag-icon-' + language.code"></span>
            <span class="LanguageForm-name">{{ language.localizedLanguageName }}</span>
          </td>
          <td>
            <label
              class="switch switch-lg LanguageForm-switch mr"
              placement="right"
              appTooltip
              [text]="
                language.isEnforced && contextType === CONSTANTS.CONTEXT.VENUE
                  ? ('SHARED.LANGUAGES.BLOCKED_BY_ENFORCEMENT' | translate)
                  : language.isDefault
                  ? ('SHARED.LANGUAGES.BLOCKED_BY_DEFAULT' | translate)
                  : null
              ">
              <input
                type="checkbox"
                [disabled]="language.isDisabled || language.isDefault || language.isBlocked"
                [(ngModel)]="language.isActive"
                (ngModelChange)="toggleLanguageActiveness(language)" />
              <span></span>
            </label>
          </td>
          <td *ngIf="contextType === CONSTANTS.CONTEXT.ACCOUNT">
            <label class="switch switch-lg LanguageForm-switch mr" placement="right">
              <input
                type="checkbox"
                [disabled]="!language.isActive || language.isDisabled || language.isBlocked"
                [(ngModel)]="language.isEnforced"
                (ngModelChange)="onEnforceStatusChanged(language)" />
              <span></span>
            </label>
          </td>
          <td>
            <div class="radio c-radio">
              <label class="mr-sm">
                <input
                  type="radio"
                  [disabled]="!language.isActive"
                  [value]="language.code"
                  [(ngModel)]="defaultLanguageCode"
                  (ngModelChange)="onDefaultLangChange(language)" />
                <span class="fa fa-circle"></span>
                <span></span>
              </label>
            </div>
          </td>
          <td>
            <ng-container *ngIf="language.validationState">
              <ng-container *ngIf="language.validationState.isEverythingCorrect; else wrongTranslations">
                <ng-container [ngTemplateOutlet]="translationsCorrect"></ng-container>
              </ng-container>
            </ng-container>

            <ng-template #wrongTranslations>
              <ng-container *ngTemplateOutlet="missingTranslations; context: { language: language }"></ng-container>
            </ng-template>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <ng-template #translationsCorrect>
    <span class="LanguageForm-langGreenIcon mdi mdi-check-circle mdi-18px mr-sm"></span>
    <span>{{ 'SHARED.LANGUAGES.EVERYTHING_TRANSLATED' | translate }}</span>
  </ng-template>
  <ng-template #missingTranslations let-language="language">
    <span class="LanguageForm-langOrangeIcon mdi mdi-message-alert mdi-18px mr-sm"></span>
    <span>{{ 'SHARED.LANGUAGES.MISSING_TRANSLATION' | translate }}</span>
    <span
      class="mdi mdi-information-outline mdi-18px ml-sm"
      appTooltip
      [contentTemplate]="missingTranslationsInfo"
      [templateData]="{ language: language }"></span>
  </ng-template>
  <ng-template #missingTranslationsInfo let-language="language">
    <div>
      {{
        'SHARED.LANGUAGES.MISSING_TRANSLATION_INFO'
          | translate: { languageName: language.localizedLanguageName, languageCode: language.code }
      }}
      <ul>
        <li *ngIf="!language.validationState.coverage.foodbackQuestion.isTranslated">
          {{ 'SHARED.SYSTEM.FOODBACK_QUESTIONS' | translate }}
        </li>
        <li *ngIf="!language.validationState.coverage.oneMoreQuestion.isTranslated">
          {{ 'SHARED.SYSTEM.ONE_MORE_QUESTION' | translate }}
        </li>
        <li *ngIf="!language.validationState.coverage.campaign.isTranslated">{{ 'SHARED.SYSTEM.CAMPAIGNS' | translate }}</li>
        <!--        <li>{{'SHARED.SYSTEM.PORTAL' | translate}}</li>-->
        <!--        <li>{{'SHARED.SYSTEM.REWARDS' | translate}}</li>-->
      </ul>
    </div>
  </ng-template>
</div>
