import { IDropdownItem } from './dropdown/dropdown-item.interface';

export interface StatementTranslationFromBackend {
  lang: string;
  value: string;
}
export interface StatementAbstract {
  isOnlyStatementWithFullTranslations: boolean;
  answersCount?: number;
  averageScore?: string;
  isEnabled?: boolean;
  isEnforced?: boolean;
  isAccountEnforced?: boolean;
  type?: StatementType;
  uuid: string;
  groupUuid?: string;
  groupName?: string;
  flexibleName?: string;
}
export interface StatementFromBackend extends StatementAbstract {
  uuid: string;
  groupUuid: string;
  groupName: string;
  translations: StatementTranslationFromBackend[];
}
export type StatementsFromBackend = Record<string, StatementFromBackend[]>;
export type StatementTranslation = Record<string, string>;
export interface StatementGroup {
  groupName: string;
  groupUuid: string;
  statements: Statement[];
}
export interface Statement extends StatementAbstract {
  translations: StatementTranslation;
}
export interface NewStatementForm {
  groupName?: any;
  category?: IDropdownItem;
  translations: StatementTranslation;
}
export interface NewStatementFormForBackend {
  categoryUuid?: string;
  translations: StatementTranslationFromBackend[];
}
export interface ModifiedStatementFormForBackend extends NewStatementFormForBackend {
  accountUuid: string;
}
export enum StatementOwnerType {
  ACCOUNT = 'account',
  VENUE = 'venue',
}
export enum StatementType {
  STANDARD = 'standard',
  CUSTOM = 'custom',
}
export interface StatementSavedStatus {
  addStatement: boolean;
  missingTranslationsForLanguages: string[];
  statementActive: boolean;
}
export interface StatementModifiedStatus {
  editionComplete: boolean;
  missingTranslationsForLanguages: string[];
  isOnlyStatementWithFullTranslations: boolean;
  questionCopy: ModifiedStatementFormForBackend;
}
export interface StatementActivationChangeStatus {
  isOnlyStatementInCategory: boolean;
  isOnlyStatementWithFullTranslations: boolean;
  missingTranslationsForLanguages: string[];
  statementActive: boolean;
}
export enum StatementPopupType {
  ACTIVATION = 'activation',
  ENFORCEMENT = 'enforcement',
}
