import { CONSTANTS } from '../../../shared/constants';

export class KeyboardUtils {
  static pressedEnter(event: KeyboardEvent): boolean {
    return event.keyCode === CONSTANTS.KEY_CODES.KEY_13;
  }

  static pressedEscape(event: KeyboardEvent): boolean {
    return event.keyCode === CONSTANTS.KEY_CODES.KEY_27;
  }

  static pressedDown(event: KeyboardEvent): boolean {
    return event.keyCode === CONSTANTS.KEY_CODES.KEY_40;
  }

  static pressedUp(event: KeyboardEvent): boolean {
    return event.keyCode === CONSTANTS.KEY_CODES.KEY_38;
  }
}
