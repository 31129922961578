import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { assignIn, filter, flatten, get, intersection, map } from 'lodash';
import { Observable, Subject, Subscription } from 'rxjs';
import { mergeMap, take, takeUntil, tap } from 'rxjs/operators';
import {
  DashboardQueryParams,
  IDemographicFiltersParams,
  VenuesQueryParams,
} from '../../../core/interfaces/dashboard-query-params.interface';
import { DashboardQueryParamsService } from '../../../core/providers/backend/dashboard-query-params.service';
import { GroupsBackendService } from '../../../core/providers/backend/groups-backend.service';
import { I18nBackendService } from '../../../core/providers/backend/i18n-backend.service';
import { UsersBackendService } from '../../../core/providers/backend/users-backend.service';
import { NavigationService } from '../../../core/providers/navigation/navigation.service';
import { DropdownUtil } from '../../../core/utils/dropdown-util';
import { UtilsService } from '../../../core/utils/utils.service';
import { DateGroupPickerValue } from '../../../shared/components';
import { Breadcrumb } from '../../../shared/components/breadcrumbs/breadcrumbs.interface';
import { DateRangePickerService } from '../../../shared/components/date-range-picker/date-range-picker.service';
import { GroupsService } from '../../../shared/components/groups/groups.service';
import {
  IDataDemographicsFilter,
  IDataFilter,
} from '../../../shared/components/questions-stats/interfaces/questions-stats-details.interface';
import { CONSTANTS } from '../../../shared/constants';
import { VenueFromBackend } from '../../../shared/form-models-interfaces';
import { Dictionary } from '../../../shared/interfaces/common.interface';
import {
  DateRange,
  DynamicDateRange,
  StaticDateRange,
  StaticDateRangePeriod,
} from '../../../shared/interfaces/date-range.interface';
import { IDropdownItem } from '../../../shared/interfaces/dropdown/dropdown-item.interface';
import { IGroup } from '../../../shared/interfaces/groups/group.interface';
import {
  I18nSupportedCountry,
  I18nSupportedLanguage,
} from '../../../shared/interfaces/i18n.interface';
import { INestedDropdownChildItem } from '../../../shared/interfaces/nested-dropdown-filter/nested-dropdown-filter-child.interface';
import { INestedDropdownItem } from '../../../shared/interfaces/nested-dropdown-filter/nested-dropdown-filter.interfaces';
import { DashboardDatesService } from '../../venue/venue-shared/dashboard-dates.service';
import { IDemographicFilters } from '../data-filter-demographics/data-filter-demographics.component';
import { DataFilterService } from '../data-filter.service';
import * as moment from 'moment';

@Component({
  selector: 'app-data-filter',
  templateUrl: './data-filter.component.html',
  styleUrls: ['./data-filter.component.scss'],
})
export class DataFilterComponent implements OnInit, OnDestroy {
  @Input() headerTranslationKey: string;
  @Input() isAccountMode = true;
  @Input() showFilters = true;
  @Input() breadcrumbs: Breadcrumb[] = [];
  @Input() isCommentFilterEnabled = false;
  @Input() isDemographicFilterEnabled = false;
  @Input() hasAdditionalOptions = false;
  @Input() ignoreFirstFoodbackDate = false;
  @Input() isExportButtonVisible = false;
  @Input() showPrintPage = false;
  @Input() showPrintPageLoading = false;
  @Output() readonly onFiltersChanged: EventEmitter<IDataFilter> =
    new EventEmitter();

  @Output() readonly onExportTrigger: EventEmitter<IDataFilter> =
    new EventEmitter();

  minDate: Date = CONSTANTS.DASHBOARD.MIN_DATE_FOR_RANGE;
  loading = false;
  groups: IGroup[] = [];
  venues: VenueFromBackend[] = [];
  selectedDateRange: DateRange;
  selectedVenuesUuids: string[] = [];
  selectedGroupsUuids: string[] = [];
  isFilterBtnDisabled = true;
  allSupportedLanguages$: Observable<I18nSupportedLanguage[]>;
  CONSTANTS: typeof CONSTANTS = CONSTANTS;
  private selectedGroups: INestedDropdownChildItem[] = [];
  private selectedVenuesItems: IDropdownItem[];
  private filteredVenueItemSet: IDropdownItem[];
  private filteredVenues: IDropdownItem[] = [];
  private venueAndGroupsSubscription: Subscription;
  private onDestroy$: Subject<void> = new Subject<void>();
  private systemDateRange: DateRange;
  private routeParams: DashboardQueryParams = null;
  private isInitialLoading = true;
  private paramsChangedInitially = true;
  private accountUuid: string;
  private hasDemographicValueChanged: boolean;
  private hasCommentChanged = false;
  private hasVenuesChanged = true;

  constructor(
    private router: Router,
    private utilsService: UtilsService,
    private groupsService: GroupsService,
    private groupsBackendService: GroupsBackendService,
    private usersBackendService: UsersBackendService,
    private dashboardQueryParamsService: DashboardQueryParamsService,
    private dashboardDatesService: DashboardDatesService,
    private activatedRoute: ActivatedRoute,
    private navigationService: NavigationService,
    private dateRangePickerService: DateRangePickerService,
    private dataFilterService: DataFilterService,
    private i18nBackendService: I18nBackendService
  ) {}

  ngOnInit(): void {
    this.allSupportedLanguages$ =
      this.i18nBackendService.getAllSupportedLanguages$();
    this.activatedRoute.queryParams
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((params: DashboardQueryParams) => {
        if (!this.isInitialLoading && params.refresh) {
          this.routeParams = this.activatedRoute.snapshot
            .queryParams as DashboardQueryParams;
          this.setDataFromUrl();
        }
      });
  }

  updateSelectedDateRange(dateRange: DateRange): void {
    this.systemDateRange = dateRange;

    if (this.isInitialLoading) {
      this.routeParams = this.activatedRoute.snapshot
        .queryParams as DashboardQueryParams;
      this.setAdditionalFilters();

      if (this.isAccountMode) {
        this.setVenuesAndGroups(this.activatedRoute.snapshot.params.accountId);
      } else {
        this.routeParams = this.activatedRoute.snapshot
          .queryParams as DashboardQueryParams;
        this.setDateRanges();
        this.refreshQueryParams();
        this.emitDataChange();
        this.storeFilteredData();
        this.isInitialLoading = false;
      }
    } else {
      this.emitDataChange();
      this.refreshQueryParams();
    }

    this.setFilteredVenueItemSet();
    this.refreshQueryParams();
  }

  onVenuesSelect(items: IDropdownItem[]): void {
    if (!this.isInitialLoading) {
      this.setVenuesUuids(items);
    }
  }

  onVenueItemSetChange(items: IDropdownItem[]): void {
    this.filteredVenues = items;
    this.setVenuesUuids(items);
  }

  onGroupsSelect(items: INestedDropdownChildItem[]): void {
    this.selectedGroups = items;
  }

  onFilterBtnClick(): void {
    this.storeFilteredData();
    this.emitDataChange();
    this.setFilteredVenueItemSet();
    this.refreshQueryParams();
    this.isFilterBtnDisabled = true;
  }

  onCommentFilterChanged(hasCommentChanged: boolean): void {
    this.hasCommentChanged = hasCommentChanged;
    this.setFilterBtnIfDisabled();
  }

  onDemographicFilterChanged(hasDemographicValueChanged: boolean): void {
    this.hasDemographicValueChanged = hasDemographicValueChanged;
    this.setFilterBtnIfDisabled();
  }

  ngOnDestroy(): void {
    this.isInitialLoading = true;
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  printDialog(): void {
    window.print();
  }

  private setVenuesUuids(items: IDropdownItem[]): void {
    this.hasVenuesChanged = this.utilsService.isSelectedItemsChanged(
      items,
      this.venues.length
    );
    this.setFilterBtnIfDisabled();
    this.selectedVenuesUuids = items
      .filter(
        (venue: IDropdownItem) => venue.id !== CONSTANTS.SELECT_ITEM_NAMES.ALL
      )
      .map((venue: IDropdownItem) => venue.id);
    this.setSelectedVenuesItems();
  }

  private setFilterBtnIfDisabled(): void {
    this.isFilterBtnDisabled = this.hasVenuesChanged;

    if (this.isCommentFilterEnabled) {
      this.isFilterBtnDisabled =
        this.isFilterBtnDisabled && !this.hasCommentChanged;
    }
    if (this.isDemographicFilterEnabled) {
      this.isFilterBtnDisabled =
        this.isFilterBtnDisabled && !this.hasDemographicValueChanged;
    }
  }

  private refreshQueryParams(): void {
    this.setRouteQueryParams(
      this.systemDateRange,
      this.dataFilterService.getVenuesQueryParams(
        this.selectedVenuesUuids,
        this.venues,
        this.filteredVenueItemSet
      ),
      this.selectedGroupsUuids
    );
  }

  private storeFilteredData(): void {
    this.groupsService.saveSelectedVenuesFilteredByGroupsInSessionStore(
      this.selectedVenuesItems &&
        this.selectedVenuesItems.length !== this.venues.length
        ? this.selectedVenuesItems
        : []
    );
    this.groupsService.saveVenuesFilteredByGroupsInSessionStore(
      this.filteredVenues
    );
    this.groupsService.saveSelectedGroupsInSessionStore(this.selectedGroups);
    this.dataFilterService.storeCommentFilter();
    this.dataFilterService.setAndStoreDemographicValue();
  }

  private emitDataChange(): void {
    this.setSelectedVenuesItems();
    this.onFiltersChanged.emit(this.getPayload());
  }

  emitExport(): void {
    this.onExportTrigger.emit();
  }

  private getPayload(): IDataFilter {
    // this.ignoreFirstFoodbackDate && this.systemDateRange.groupBy === DateGroupPickerValue.STATIC &&
    // this.systemDateRange as StaticDateRange.period == StaticDateRangePeriod.ALL_TIME;

    const dateRangeForRequest: DynamicDateRange =
      this.dashboardDatesService.getDateRangeForRequest(this.systemDateRange);
    let payload: IDataFilter = {
      dateFrom: `${this.dashboardDatesService.prepareDateForRequest(
        dateRangeForRequest.start
      )}`,
      dateTo: `${this.dashboardDatesService.prepareDateForRequest(
        dateRangeForRequest.end
      )}`,
      venues:
        this.selectedVenuesUuids.length === 0 && this.selectedVenuesItems
          ? this.selectedVenuesItems.map((item: IDropdownItem) => item.id)
          : this.selectedVenuesUuids,
    };

    if (this.isDemographicFilterEnabled) {
      payload = {
        ...payload,
        demographics: this.getDemographicFilterDataPayload(),
      };
    }
    if (this.isAllTime() && this.ignoreFirstFoodbackDate) {
      payload.dateFrom = null;
    }

    return payload;
  }

  private isAllTime(): boolean {
    return (
      this.systemDateRange.groupBy === DateGroupPickerValue.STATIC &&
      (this.systemDateRange as StaticDateRange).period ===
        StaticDateRangePeriod.ALL_TIME
    );
  }

  private getDemographicFilterDataPayload(): IDataDemographicsFilter {
    const filters: IDemographicFilters =
      this.dataFilterService.getDemographicsFilters();

    return {
      lang:
        (filters && filters.language && filters.language.id === 'any') ||
        !filters
          ? []
          : [filters.language.id],
      ageGroup:
        (filters && filters.age && filters.age.id === 'any') || !filters
          ? []
          : [filters.age.id],
      gender:
        (filters && filters.gender && filters.gender.id === 'any') || !filters
          ? []
          : [filters.gender.text],
    };
  }

  private setVenuesAndGroups(accountUuid: string): void {
    this.accountUuid = accountUuid;

    if (this.venueAndGroupsSubscription) {
      this.venueAndGroupsSubscription.unsubscribe();
    }

    this.venueAndGroupsSubscription = this.usersBackendService
      .getVenuesList$(accountUuid)
      .pipe(
        tap((venues: VenueFromBackend[]) => this.handleVenuesListLoad(venues)),
        mergeMap(() => this.groupsBackendService.getGroupsForDropdown$()),
        tap((groups: IGroup[]) => this.groupsService.setGroups(groups)),
        mergeMap(() => this.groupsService.getGroupsWithCategories$()),
        take(1)
      )
      .subscribe((groups: IGroup[]) => {
        this.groups = groups;
        this.setDataFromUrl();
        this.isInitialLoading = false;
      });
  }

  private setDataFromUrl(): void {
    this.setDateRanges();
    this.dateRangePickerService.storeAllFoodbackDateRange(moment(this.routeParams.dateFrom), moment(this.routeParams.dateTo));
    this.setAdditionalFilters();
    this.selectedGroupsUuids = this.getSelectedVenueGroups();

    if (this.selectedGroupsUuids.length !== this.selectedGroups.length) {
      this.selectedGroups = this.groupsService.getGroupsByUuids(
        this.selectedGroupsUuids,
        this.groups
      );
    }

    this.setFilteredVenueItemSet();
    this.selectedVenuesUuids = this.getSelectedVenueUuids();

    if (this.selectedVenuesUuids) {
      this.setSelectedVenuesItems();

      if (!this.paramsChangedInitially && this.systemDateRange) {
        this.setVenuesByQueryParams(this.routeParams);
      }

      this.paramsChangedInitially = false;

      if (this.venueAndGroupsSubscription) {
        this.venueAndGroupsSubscription.unsubscribe();
      }

      this.refreshQueryParams();
      this.emitDataChange();
      this.storeFilteredData();
    }
  }

  private setSelectedVenuesItems(): void {
    this.selectedVenuesItems =
      this.selectedVenuesUuids.length === 0
        ? this.filteredVenueItemSet
        : this.getSelectedVenues();
  }

  private setDateRanges(): void {
    if (
      this.dashboardQueryParamsService.isCompleteDashboardQueryParams(
        this.routeParams
      )
    ) {
      this.setDateRangesValue(
        this.dashboardQueryParamsService.getDateRangeFromQueryParams(
          this.routeParams
        )
      );
    }
  }

  private setDateRangesValue(dateRange: DateRange): void {
    this.systemDateRange = dateRange;
    this.selectedDateRange = dateRange;
  }

  private setFilteredVenueItemSet(): void {
    this.filteredVenueItemSet = DropdownUtil.convertToDropdownItems(
      this.getFilterVenuesByGroups(),
      ['uuid', 'brandName']
    );
  }

  private handleVenuesListLoad(venues: VenueFromBackend[]): void {
    this.venues = venues.map((venue: VenueFromBackend) => {
      venue.venueGroups =
        venue.venueGroups.length === 0
          ? [this.groupsService.getEmptyGroup()]
          : venue.venueGroups;

      return venue;
    });
    this.onVenuesSelect(
      this.groupsService.getSavedSelectedVenuesFromSessionStore()
    );
  }

  private setRouteQueryParams(
    dateRange: DateRange,
    venueQueryParams: VenuesQueryParams,
    selectedGroupsUuids: string[]
  ): void {
    this.navigationService.changeParams({
      queryParams: {
        ...this.dashboardQueryParamsService.getQueryParamsFromDateRange(
          dateRange
        ),
        'venueGroups[]': selectedGroupsUuids,
        venues: venueQueryParams.venues,
        isVenuesExcluded: venueQueryParams.isVenuesExcluded,
        ...this.getCommentFilterData(),
        ...this.getDemographicFilterData(),
      },
    });
  }

  private getCommentFilterData(): { hasComment?: string } {
    if (this.dataFilterService.getHasComment()) {
      return { hasComment: this.dataFilterService.getComment() };
    }

    return {};
  }

  private getDemographicFilterData(): IDemographicFiltersParams {
    const demographicsData: IDemographicFilters =
      this.dataFilterService.getDemographicsFilters();

    if (demographicsData) {
      return {
        lang: demographicsData.language.id,
        age: demographicsData.age.id,
        gender: demographicsData.gender.id,
      };
    }

    return {};
  }

  private setVenuesByQueryParams(queryParams: DashboardQueryParams): void {
    const venuesParams: string[] =
      typeof queryParams.venues === 'string'
        ? [queryParams.venues]
        : queryParams.venues;

    // @ts-ignore
    if (queryParams.isVenuesExcluded === 'true') {
      this.selectedVenuesUuids = this.filteredVenueItemSet
        .filter(
          (venue: IDropdownItem) =>
            !venuesParams.find(
              (venuesParam: string) => venuesParam === venue.id
            )
        )
        .map((venue: IDropdownItem) => venue.id);
    } else {
      this.selectedVenuesUuids = venuesParams;
    }

    this.setSelectedVenuesItems();
  }

  private getSelectedVenues(): IDropdownItem[] {
    return this.filteredVenueItemSet.filter((venue: IDropdownItem) =>
      this.selectedVenuesUuids.find(
        (venueUuid: string) => venue.id === venueUuid
      )
    );
  }

  private getSelectedVenueUuids(): string[] {
    const isVenuesExcluded: boolean | string = get(
      this.routeParams,
      'isVenuesExcluded'
    );
    let selectedVenueUuids: string[] = get(this.routeParams, 'venues');

    const isVenuesSelected: boolean =
      selectedVenueUuids && selectedVenueUuids.length > 0;

    // if there is only one selected venue group, query params return string instead of array of strings.
    // flattening resolves the problem by changing 1 and [1] to [1]
    if (!selectedVenueUuids) {
      selectedVenueUuids = this.groupsService
        .getSavedSelectedVenuesFromSessionStore()
        .map((venue: IDropdownItem) => venue.id);
      const venueQueryParams: VenuesQueryParams =
        this.dataFilterService.getVenuesQueryParams(
          selectedVenueUuids,
          this.venues,
          this.groupsService.getSavedFilteredVenuesFromSessionStore()
        );

      this.setRouteParams({
        venues: venueQueryParams.venues,
        isVenuesExcluded: venueQueryParams.isVenuesExcluded,
      });
    }

    selectedVenueUuids = selectedVenueUuids
      ? flatten([selectedVenueUuids])
      : [];

    if (
      (typeof isVenuesExcluded === 'string' && isVenuesExcluded === 'true') ||
      (typeof isVenuesExcluded === 'boolean' && isVenuesExcluded)
    ) {
      selectedVenueUuids = this.filteredVenueItemSet
        .filter(
          (venue: IDropdownItem) =>
            !selectedVenueUuids.find(
              (selectedVenueUuid: string) => selectedVenueUuid === venue.id
            )
        )
        .map((venue: IDropdownItem) => venue.id);
    }
    const selectedVenuesUuidsResult: string[] = selectedVenueUuids.filter(
      (uuid: string) =>
        this.venues.find((venue: VenueFromBackend) => venue.uuid === uuid)
    );

    if (isVenuesSelected && selectedVenuesUuidsResult.length === 0) {
      return null;
    }

    return selectedVenuesUuidsResult;
  }

  private getSelectedVenueGroups(): string[] {
    let selectedGroupsUuids: string[] = get(this.routeParams, 'venueGroups[]');

    selectedGroupsUuids =
      typeof selectedGroupsUuids === 'string'
        ? [selectedGroupsUuids]
        : selectedGroupsUuids;

    // if there is only one selected venue group, query params return string instead of array of strings.
    // flattening resolves the problem by changing 1 and [1] to [1]
    if (!selectedGroupsUuids) {
      this.selectedGroups =
        this.groupsService.getSelectedGroupsFromSessionStorage();
      selectedGroupsUuids = this.groupsService.getSelectedUuids(
        this.selectedGroups as INestedDropdownItem[]
      );
      this.setRouteParams({
        'venueGroups[]': selectedGroupsUuids,
      });
    }

    selectedGroupsUuids =
      typeof selectedGroupsUuids === 'string'
        ? [selectedGroupsUuids]
        : selectedGroupsUuids;
    selectedGroupsUuids = selectedGroupsUuids
      ? selectedGroupsUuids.filter((uuid: string) =>
          this.dataFilterService.isGroupWithUuidExists(uuid, this.groups)
        )
      : [];

    return selectedGroupsUuids ? flatten([selectedGroupsUuids]) : [];
  }

  private setRouteParams(
    params: Partial<Dictionary<any> & DashboardQueryParams>
  ): void {
    this.routeParams = assignIn({}, this.routeParams, params);
  }

  private getFilterVenuesByGroups(): VenueFromBackend[] {
    let venuesWithGroup: VenueFromBackend[];

    if (this.selectedGroupsUuids.length) {
      venuesWithGroup = filter(
        this.venues,
        (venue: VenueFromBackend) =>
          intersection(map(venue.venueGroups, 'uuid'), this.selectedGroupsUuids)
            .length > 0
      );
    } else {
      venuesWithGroup = this.venues;
    }

    return venuesWithGroup;
  }

  private setAdditionalFilters(): void {
    if (!!this.routeParams.hasComment && this.routeParams.hasComment !== '') {
      this.dataFilterService.setHasComment(true);
      this.dataFilterService.setComment(this.routeParams.hasComment);
    } else {
      this.dataFilterService.setCommentFromStorage();
    }
    if (
      this.routeParams.lang &&
      this.routeParams.gender &&
      this.routeParams.age
    ) {
      const { lang, age, gender } = this.routeParams;

      this.dataFilterService.setDemographicsQueryParams({ lang, age, gender });
    }
  }
}
