<div class="EnforcementSwitcher">
  <div *ngIf="isLocked; else unlockedSwitcher" class="EnforcementSwitcher-textPositioner">
    <i class="fa fa-lock mr-lg"></i>
    <span class="EnforcementSwitcher-description">
      <ng-content select="[enforcement-locked]"></ng-content>
    </span>
  </div>
</div>

<ng-template #unlockedSwitcher>
  <label class="switch switch-lg">
    <input type="checkbox" [(ngModel)]="isActivated" (ngModelChange)="sendEnforcementStateToParent($event)" />
    <span></span>
  </label>
  <div class="EnforcementSwitcher-state">
    <span *ngIf="isActivated; else offSwitcher"> {{ 'ACCOUNT.FOLDER.BRANDING.ENFORCEMENT.ON' | translate }} </span>
    <ng-template #offSwitcher> {{ 'ACCOUNT.FOLDER.BRANDING.ENFORCEMENT.OFF' | translate }} </ng-template>
  </div>
  <span class="EnforcementSwitcher-description">
    <ng-content select="[enforcement-configurable]"></ng-content>
  </span>
</ng-template>
