<div [ngClass]="{'SmallStatsChart': height === defaultHeight}">
  <fusioncharts
    *ngIf="this.dataSource?.dataset.length > 0"
    type="mscombidy2d"
    [width]="width"
    [height]="height"
    [dataSource]="dataSource"
  >
  </fusioncharts>
</div>
