import { Component, ElementRef, EventEmitter, OnDestroy, Output, ViewChild } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter, map, mergeMap, switchMap, takeUntil } from 'rxjs/operators';
import { NotificationService } from '../../../../core/providers/notification/notification.service';
import { CONSTANTS } from '../../../constants';
import { IGroup } from '../../../interfaces/groups/group.interface';
import { GroupsService } from '../groups.service';
import { LocalStorageService } from 'ngx-webstorage';
import { ContextService } from 'app/core/providers/context/context.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-groups-list',
  templateUrl: './groups-list.component.html',
  styleUrls: ['./groups-list.component.scss'],
})
export class GroupsListComponent implements OnDestroy {
  @ViewChild('addGroupInput') addGroupInputRef: ElementRef;
  @ViewChild('editGroupInput') editGroupInputRef: ElementRef;
  @Output() readonly onSelectedGroup: EventEmitter<IGroup> = new EventEmitter();
  @Output() readonly onRemoveGroup: EventEmitter<IGroup> = new EventEmitter();
  accountChildrenNameSingular: string;
  accountChildrenNamePlural: string;
  readonly CONSTANTS = CONSTANTS;
  private onDestroy$ = new Subject<void>();

  constructor(private notificationService: NotificationService, private groupsService: GroupsService, private contextService: ContextService, private translateService: TranslateService) {}

  ngOnInit() {
    this.accountChildrenNameSingular = this.contextService.getAccountChildrenNameTranslation(true);
    this.accountChildrenNamePlural = this.contextService.getAccountChildrenNameTranslation(false);
  }

  removeGroup(group: IGroup) {
    this.displayRemoveConfirmPopup()
      .pipe(
        filter((response: boolean) => response),
        mergeMap(() => this.groupsService.removeGroup$(group.uuid)),
        takeUntil(this.onDestroy$)
      )
      .subscribe(() => {});
  }

  private displayRemoveConfirmPopup(): Observable<boolean> {
    return this.translateService.get('ACCOUNT.GROUPS.MODALS.REMOVE_GROUP').pipe(
      switchMap((DATA: { TITLE: string; TEXT: string; BUTTONS: string }) => {
        const { TITLE, TEXT, BUTTONS } = DATA;
        const interpolatedTitle: string = this.translateService.instant(TITLE);
        const interpolatedText: string = this.translateService.instant(TEXT).replace('{{ childrenName }}', this.accountChildrenNamePlural);

        return this.notificationService.displayConfirmationPopup(interpolatedTitle, interpolatedText, BUTTONS, 'warning');
      })
    );
  }

  trackGroup(index: number, group: IGroup) {
    return group.uuid;
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  getGroupsWithCategories$(): Observable<IGroup[]> {
    return this.groupsService.getGroupsWithCategories$().pipe(map(items => items.filter(item => !item.isEmptyGroup)));
  }
}
