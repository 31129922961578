export enum OneMoreQuestionWhenToAsk {
  EVERY_TIME = 'EVERY_TIME',
  ONLY_ONCE = 'ONLY_ONCE',
  ONLY_X_TIME_WEEKS = 'ONLY_X_TIME_WEEKS',
  ONLY_X_TIME_MONTHS = 'ONLY_X_TIME_MONTHS',
}
export enum OneMoreQuestionTimePeriod {
  ONGOING = 'ONGOING',
  TIME_FRAME = 'TIME_FRAME',
}
export enum OneMoreQuestionWhenToAskUnit {
  WEEKS = 'W',
  MONTHS = 'M',
}
