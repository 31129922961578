import { Injectable, OnDestroy } from '@angular/core';
import { cloneDeep, find } from 'lodash';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  Subscription,
  combineLatest as observableCombineLatest,
} from 'rxjs';
import { filter } from 'rxjs/operators';
import { VENUE_ROUTES_PATHS_FULL } from '../../../components/venue/venue-routes-paths';
import { FoodbackAccount } from '../../../core/classes/account/account.class';
import { AccountService } from '../../../core/providers/account/account.service';
import { RouteBuilderService } from '../../../core/providers/backend/route-builder.service';
import { WheelOfFortuneBackendService } from '../../../core/providers/backend/wheel-of-fortune-backend.service';
import { ContextService } from '../../../core/providers/context/context.service';
import { CONSTANTS } from '../../../shared/constants';
import { SidebarAnalyticsMenus } from '../../../shared/enums/sidebar/sidebar-analytics-menus.enum';
import { WheelOfFortuneBackend } from '../../../shared/form-models-interfaces';
import { ContextObject } from '../../../shared/interfaces/login-object';
import { SidebarMenu } from '../../../shared/interfaces/sidebar/sidebar-menu.interface';
import { SidebarConstants } from '../sidebar.constants';
import { SidebarService } from '../sidebar.service';
import { LocalStorageService } from 'ngx-webstorage';

@Injectable()
export class SidebarAnalyticsService implements OnDestroy {

  account: FoodbackAccount;
  accountChildrenName: string = 'venue';
  private sidebars: ReplaySubject<SidebarMenu[]> = new ReplaySubject(1);
  private context: ContextObject;
  private subscriptions: Subscription = new Subscription(null);

  constructor(
    private accountService: AccountService,
    private contextService: ContextService,
    private routeBuilderService: RouteBuilderService,
    private wheelOfFortuneBackendService: WheelOfFortuneBackendService,
    private sidebarService: SidebarService,
    private localStorage: LocalStorageService
  ) {
    this.updateSidebarMenus();
  }

  getSidebarMenus$(): Observable<SidebarMenu[]> {
    return this.sidebars.pipe(
      filter((sidebars: SidebarMenu[]) => sidebars !== null)
    );
  }

  prepareSidebarMenus(
    account: FoodbackAccount,
    context: ContextObject,
    isAccountContext: boolean,
    uuid: string
  ): void {
    let sidebars: SidebarMenu[] = cloneDeep(
      isAccountContext
        ? SidebarConstants.ANALYTICS_ACCOUNT.SIDEBAR
        : SidebarConstants.ANALYTICS_VENUE.SIDEBAR
    );

    this.account = account;
    this.accountChildrenName = this.account.accountChildrenNameId;
    this.localStorage.store(CONSTANTS.LOCAL_STORAGE_KEYS.CHILDREN_CONTEXT, this.accountChildrenName);

    this.addRestaurantMenus(sidebars, account, isAccountContext);
    this.addOptionalMenus(sidebars, account, isAccountContext);
    sidebars = this.getCampaignMenus(sidebars, account);
    this.setRewardsMenus(sidebars, isAccountContext, uuid);
    this.setSidebarMenus(sidebars, isAccountContext, uuid, account);
  }

  checkIsWheelEnable$(): BehaviorSubject<boolean> {
    return this.wheelOfFortuneBackendService.checkIsWheelEnable();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private setSidebarMenus(
    sidebarMenus: SidebarMenu[],
    isAccountContext: boolean,
    uuid: string,
    account?: FoodbackAccount
  ): void {
    let accountChildrenNameId = account ? account.accountChildrenNameId : this.accountChildrenName;
    this.localStorage.store(CONSTANTS.LOCAL_STORAGE_KEYS.CHILDREN_CONTEXT, accountChildrenNameId);
    let sidebarMenuArray = this.sidebarService.getSidebarMenu(sidebarMenus, isAccountContext, uuid);

    sidebarMenuArray.forEach((element) => {
      if (element.title.toLowerCase().includes('venue')) {
        if (this.accountChildrenName.toLowerCase() !== 'venue') {
          element.title = element.title.replace('VENUE', this.accountChildrenName.toUpperCase());
        }
      }
    });

    this.sidebars.next(
      sidebarMenuArray
    );
  }

  private updateSidebarMenus(): void {
    observableCombineLatest([
      this.accountService.getAccount$(),
      this.contextService.getContext$(),
    ]).subscribe(([account, context]: [FoodbackAccount, ContextObject]) => {
      this.context = context;
      this.prepareSidebarMenus(
        account,
        context,
        ContextService.isAccountContext(context),
        context.uuid
      );
    });
  }

  private addOptionalMenus(
    sidebars: SidebarMenu[],
    account: FoodbackAccount,
    isAccountContext: boolean
  ): void {
    if (!isAccountContext && account.hasAccessTo().venueDashboard) {
      const dashboardMenu: SidebarMenu = cloneDeep(
        find(SidebarConstants.ANALYTICS_VENUE.OPTIONAL_SIDEBARS, {
          type: SidebarAnalyticsMenus.DASHBOARD,
        })
      );

      sidebars.push(dashboardMenu);
    }
  }

  private addRestaurantMenus(
    sidebars: SidebarMenu[],
    account: FoodbackAccount,
    isAccountContext: boolean
  ): void {
    if (!isAccountContext) {
      const demographicsMenu: SidebarMenu = cloneDeep(
        find(SidebarConstants.ANALYTICS_VENUE.RESTAURANT_SIDEBARS, {
          type: SidebarAnalyticsMenus.DEMOGRAPHICS,
        })
      );

      sidebars.push(demographicsMenu);
    }
  }

  private getCampaignMenus(
    sidebars: SidebarMenu[],
    account: FoodbackAccount
  ): SidebarMenu[] {
    if (this.sidebarService.isAccountWithCampaign(account.uuid)) {
      return sidebars;
    }

    return sidebars.filter(
      (sidebar: SidebarMenu) => sidebar.type !== SidebarAnalyticsMenus.CAMPAIGNS
    );
  }

  private setRewardsMenus(
    sidebars: SidebarMenu[],
    isAccountContext: boolean,
    uuid: string
  ) {
    if (!isAccountContext) {
      this.subscriptions.add(this.getWheelOfFortuneSettings$(uuid).subscribe());
      this.subscriptions.add(
        this.getIsWheelEnableSubscription$(sidebars, uuid)
      );
    } else {
      this.removeRewardsFromAccountSidebar(sidebars);
    }
  }

  private removeRewardsFromAccountSidebar(sidebars: SidebarMenu[]) {
    if (
      sidebars.find(sidebar => sidebar.type === SidebarAnalyticsMenus.REWARDS)
    ) {
      sidebars = this.removeRewardsSidebar(sidebars);
    }
  }

  private getIsWheelEnableSubscription$(
    sidebars: SidebarMenu[],
    uuid: string
  ): Subscription {
    return this.checkIsWheelEnable$().subscribe(isWheelEnabled => {
      if (isWheelEnabled) {
        this.updateRewardsSidebar(sidebars, uuid);
      } else {
        sidebars = this.removeRewardsSidebar(sidebars);
      }
      if (this.context.type === CONSTANTS.CONTEXT.VENUE) {
        this.setSidebarMenus(sidebars, false, uuid, this.account);
      }
    });
  }

  private removeRewardsSidebar(sidebars: SidebarMenu[]): SidebarMenu[] {
    return sidebars.filter(
      sidebar => sidebar.type !== SidebarAnalyticsMenus.REWARDS
    );
  }

  private updateRewardsSidebar(sidebars: SidebarMenu[], uuid: string) {
    const newSidebarUrl: string = this.routeBuilderService.getAbsoluteRoute(
      VENUE_ROUTES_PATHS_FULL.WINNER_STATISTIC,
      {
        [CONSTANTS.ROUTE_PARAMS.VENUE_ID]: uuid,
      }
    );

    if (
      !sidebars.find(sidebar => sidebar.type === SidebarAnalyticsMenus.REWARDS)
    ) {
      const sidebarMenu: SidebarMenu = SidebarConstants.ANALYTICS_VENUE.REWARDS;

      sidebarMenu.link = newSidebarUrl;
      sidebars.push(sidebarMenu);
    } else {
      const sidebar = sidebars.find(
        s => s.type === SidebarAnalyticsMenus.REWARDS
      );

      sidebar.link = newSidebarUrl;
    }
  }

  private getWheelOfFortuneSettings$(
    uuid: string
  ): Observable<WheelOfFortuneBackend> {
    return this.wheelOfFortuneBackendService.getWheelOfFortuneSettings$(
      CONSTANTS.CONTEXT.VENUE,
      uuid
    );
  }
}
