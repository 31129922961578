import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { first, map, mergeMap, tap } from 'rxjs/operators';
import { environment } from '../../../../../../environments/environment';
import { FoodbackAccount } from '../../../../../core/classes/account/account.class';
import { AccountService } from '../../../../../core/providers/account/account.service';
import { ApiService } from '../../../../../core/providers/api/api.service';
import Bind from '../../../../../core/utils/decorators/bind.decorator';
import { ApiResponse } from '../../../../../shared/interfaces/api-response.interface';
import { IGroupQr } from './groups-list/group-qr.interface';
import { IVenueQr } from './venues-list/venue-qr.interface';

@Injectable()
export class AccFolderQUrlsService {
  private readonly url: string = `${environment.apiBaseUrl.handbook}/fbi`;
  private groups$: BehaviorSubject<IGroupQr[]> = new BehaviorSubject([]);

  constructor(private accountService: AccountService, private apiService: ApiService) {}

  @Bind
  setGroups(data: IGroupQr[]) {
    this.groups$.next(data);
  }

  getGroups$(): Observable<IGroupQr[]> {
    return this.groups$;
  }

  getVenuesQrUrls$(): Observable<IVenueQr[]> {
    return this.callAfter$((data: FoodbackAccount) => this.apiService.get$(`${this.url}/accounts/${data.uuid}/venuesFixedUrls`));
  }

  getGroupsQrUrls$(): Observable<IGroupQr[]> {
    return this.callAfter$((data: FoodbackAccount) =>
      this.apiService.get$(`${this.url}/accounts/${data.uuid}/venueGroupsActiveFixedUrls`)
    ).pipe(tap(groups => this.setGroups(groups)));
  }

  activateGroupsQR$(venueGroupUuid: string, venueGroupChildren: string[]): Observable<any> {
    return this.apiService.put$(`${this.url}/venuegroups/${venueGroupUuid}/toggleVenueGroupChildrenActivity`, { venueGroupChildren });
  }

  activateGroupsQRByAccount$(accountId: string, venueGroups: string[]): Observable<any> {
    return this.apiService.put$(`${this.url}/venuegroups/${accountId}/toggleVenueGroupsActivity`, { venueGroups });
  }

  toggleVenueGroupChildrenActivity$(venueGroupUuid: string): Observable<any> {
    return this.apiService.put$(`${this.url}/venuegroups/${venueGroupUuid}/toggleVenueGroupActivity`, null);
  }

  toggleAccountFixedUrl$(): Observable<IGroupQr> {
    return this.callAfter$((data: FoodbackAccount) =>
      this.apiService.put$(`${this.url}/accounts/${data.uuid}/toggleAccountFixedUrl`, null)
    );
  }

  private callAfter$(apiFunction: (args?: any) => Observable<any>): Observable<any> {
    return this.accountService.getAccount$().pipe(
      first(),
      mergeMap((account: FoodbackAccount) => apiFunction(account)),
      map((response: ApiResponse<any>) => response.content)
    );
  }
}
