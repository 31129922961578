<form *ngIf="formGroup" class="DataFilterDemographics" [formGroup]="formGroup" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
  <app-select
    formControlName="language"
    [items]="languages"
    [placeholder]="'SHARED.SYSTEM.LANGUAGE' | translate"
    [label]="'SHARED.SYSTEM.LANGUAGE' | translate"
    [isSearchModeEnable]="false"
  >
  </app-select>
  <app-select
    formControlName="age"
    [items]="ages"
    [placeholder]="'HOME.FOODBACKS.TABLE.AGE' | translate"
    [label]="'HOME.FOODBACKS.TABLE.AGE' | translate"
    [isSearchModeEnable]="false"
  >
  </app-select>
  <app-select
    formControlName="gender"
    [items]="genders"
    [placeholder]="'SHARED.SYSTEM.GENDER' | translate"
    [label]="'SHARED.SYSTEM.GENDER' | translate"
    [isSearchModeEnable]="false"
  >
  </app-select>
</form>
