import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControlOptions, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { CONSTANTS } from '../../../constants';
import { I18nAvailableLanguage } from '../../../interfaces/i18n.interface';
import { StatementTranslationFromBackend } from '../../../interfaces/statements.interface';
import { LanguageFormGroup } from '../../one-more-question/one-more-question-edit/one-more-question-languages-dialog/one-more-question-languages-dialog.component';
import { CampaignTranslationService } from '../campaign-translation.service';
import { ICampaignWelcomeScreenTranslateInput, ICampaignWelcomeScreenTranslateInputOutput } from '../campaigns.interface';
import { AccountErrors, GeneralErrors, GeneralQuestionsError } from "../../../form-models";

export const campaignWelcomeScreenLanguagesDialogConfig: MatDialogConfig = {
  maxWidth: '90vw',
  maxHeight: '90vh',
  minHeight: '400px',
  disableClose: true,
  panelClass: 'full-screen-modal',
};
@Component({
  selector: 'app-campaign-welcome-screen-translate-dialog',
  templateUrl: './campaign-welcome-screen-translate-dialog.component.html',
  styleUrls: ['./campaign-welcome-screen-translate-dialog.component.scss'],
})
export class CampaignWelcomeScreenTranslateDialogComponent implements OnInit {
  langForm: FormGroup;
  inactiveLangForm: FormArray;
  showInactiveLanguages = false;
  errors = AccountErrors;
  readonly CONSTANTS: any = CONSTANTS;

  constructor(
    private mdDialogRef: MatDialogRef<CampaignWelcomeScreenTranslateDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public dataDialog: ICampaignWelcomeScreenTranslateInput,
    private formBuilder: FormBuilder,
    private campaignTranslationService: CampaignTranslationService
  ) {}

  ngOnInit(): void {
    this.langForm = this.formBuilder.group({
      activeLangForm: this.campaignTranslationService.getLanguagesForm({
        languageSet: this.dataDialog.allActiveLanguages,
        defaultLanguage: this.dataDialog.defaultLanguage,
        isQuestionDialog: false,
        shouldAddDefaultLanguage: true,
        translations: {
          title: this.dataDialog.title,
          subtitle: this.dataDialog.subtitle,
        },
      }),
    });
    this.inactiveLangForm = this.campaignTranslationService.getLanguagesForm({
      languageSet: this.getInactiveLanguages(),
      defaultLanguage: this.dataDialog.defaultLanguage,
      isQuestionDialog: false,
      shouldAddDefaultLanguage: false,
      translations: {
        title: this.dataDialog.title,
        subtitle: this.dataDialog.subtitle,
      },
    });
  }

  showInactiveLanguagesChange(): void {
    if (this.showInactiveLanguages) {
      this.langForm.addControl('inactiveLangForm', this.inactiveLangForm, {
        emitEvent: false,
      });
    } else {
      this.langForm.removeControl('inactiveLangForm', { emitEvent: false });
    }
  }

  onSubmit(): void {
    this.langForm.markAllAsTouched();
    this.langForm.markAsDirty();
    if (this.langForm.valid) {
      this.mdDialogRef.close(this.prepareDialogData());
    }
  }

  trackItems(item: FormGroup, index: number): number {
    return index;
  }

  private prepareDialogData(): ICampaignWelcomeScreenTranslateInputOutput {
    let title: StatementTranslationFromBackend[] = [];
    let subTitle: StatementTranslationFromBackend[] = [];

    // Get data from active languages
    const activeFormOutput: ICampaignWelcomeScreenTranslateInputOutput = this.prepareTranslationDataSet(
      this.langForm.controls.activeLangForm as FormArray
    );

    title = [...activeFormOutput.title];
    subTitle = [...activeFormOutput.subtitle];

    if (this.showInactiveLanguages) {
      // Get data from inactive languages
      const inactiveFormOutput: ICampaignWelcomeScreenTranslateInputOutput = this.prepareTranslationDataSet(
        this.langForm.controls.inactiveLangForm as FormArray
      );

      title = [...title, ...inactiveFormOutput.title];
      subTitle = [...subTitle, ...inactiveFormOutput.subtitle];
    }

    return { title, subtitle: subTitle };
  }

  private prepareTranslationDataSet(langFormArray: FormArray): ICampaignWelcomeScreenTranslateInputOutput {
    const title: StatementTranslationFromBackend[] = [];
    const subTitle: StatementTranslationFromBackend[] = [];

    // Get data from active languages
    langFormArray.controls.forEach((form: LanguageFormGroup) => {
      title.push({
        lang: form.language.code,
        value: form.value.title,
      });
      subTitle.push({
        lang: form.language.code,
        value: form.value.subTitle,
      });
    });

    return { title, subtitle: subTitle };
  }

  private getInactiveLanguages(): I18nAvailableLanguage[] {
    return this.dataDialog.allLanguages.filter(
      (lang: I18nAvailableLanguage) =>
        !this.dataDialog.allActiveLanguages.find((activeLang: I18nAvailableLanguage) => activeLang.code === lang.code)
    );
  }
}
