<app-dialog>
  <app-dialog-title>{{ (!data ? 'SHARED.GROUPS.NEW_GROUP' : 'ACCOUNT.GROUPS.DIALOG.EDIT_GROUP_TITLE') | translate }}</app-dialog-title>
  <app-dialog-content>
    <form [formGroup]="groupForm">
      <div class="form-group">
        <mat-form-field>
          <mat-label>{{ 'SHARED.SYSTEM.NAME' | translate }}</mat-label>
          <input matInput autocomplete="off" list="autocompleteOff" required #nameInput name="name" autofocus formControlName="name" />
          <mat-error appErrors="name" [errors]="errors"> </mat-error>
        </mat-form-field>
        <div>
          <app-dropdown
            class="NewGroup__CategoryDropdown"
            (onSelect)="onCategorySelect($event)"
            [placeholder]="'ACCOUNT.GROUPS.DIALOG.SELECT_CATEGORY' | translate"
            [label]="'SHARED.SYSTEM.CATEGORY' | translate"
            [items]="categoriesDropdown"
            [activeItems]="venuesDropdownItems"></app-dropdown>
        </div>
        <label>
          <span>{{ 'ACCOUNT.GROUPS.MODALS.REMOVE_GROUP.ASSIGN_VENUES' | translate: { childrenName: accountChildrenNamePlural } }}</span>
        </label>
        <div class="NewGroup__groups">
          <app-multi-select-tag
            (onSelectItem)="assignVenue($event)"
            (onDeselectItem)="unassignVenueFromGroup($event)"
            [addOnDemandMode]="true"
            [permanentEditMode]="true"
            [tags]="getVenuesAsDropdownItems(selectedVenues)"
            [items]="getVenuesAsDropdownItems(venues)"
            [disable]="isDisabledDropdown"
            [placeholder]="'SHARED.SYSTEM.FILTER' | translate"
            [noTagsPlaceholder]="'ACCOUNT.GROUPS.LISTS.VENUES.MULTI_SELECT_TAG.NO_RESULTS_PLACEHOLDER' | translate"
            [noResultsPlaceholder]="
              'ACCOUNT.GROUPS.LISTS.VENUES.MULTI_SELECT_TAG.NO_RESULTS_PLACEHOLDER' | translate
            "></app-multi-select-tag>
        </div>
      </div>
    </form>
  </app-dialog-content>
  <app-dialog-actions>
    <button type="button" class="btn btn-foodback btn-foodback--secondary text-uppercase mr-sm" matDialogClose>
      {{ 'ACCOUNT.GROUPS.BUTTONS.CANCEL' | translate }}
    </button>
    <button type="button" class="btn btn-foodback text-uppercase" data-dismiss="modal" (click)="save()">
      {{ 'SHARED.SYSTEM.SAVE' | translate }}
    </button>
  </app-dialog-actions>
</app-dialog>
