import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AccountSingleVenueBackendService } from '../../../core/providers/backend/account-single-venue-backend.service';
import { ContextService } from '../../../core/providers/context/context.service';
import { LayoutUtil } from '../../../core/utils';
import { CONSTANTS } from '../../../shared/constants';
import { ContextObject } from '../../../shared/interfaces/login-object';
import { LocalStorageService } from 'ngx-webstorage';

@Component({
  selector: 'app-context-picker',
  templateUrl: './context-picker.component.html',
  styleUrls: ['./context-picker.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ContextPickerComponent implements OnInit, OnDestroy {
  @Input() isTopLevelDashboard;
  @Input() isMobileDevice = false;
  @Output() readonly onToggleAside: EventEmitter<any> = new EventEmitter();
  textForTranslation = {
    ACCOUNT_NAME: '',
    CONTEXT_FLAG: '',
    VENUE_NAME: '',
    CONTEXT_TEXT: '',
  };
  CONSTANTS = CONSTANTS;
  contextType = '';
  sidebarExpanded = false;
  isSingleVenueAccount = false;
  accountClassBasedOnContext;
  accountChildrenNameSingular: string;
  accountChildrenNamePlural: string;
  private onDestroy$ = new Subject<void>();

  constructor(
    private accountSingleRestaurantBackendService: AccountSingleVenueBackendService,
    private contextService: ContextService,
    private layoutUtil: LayoutUtil,
    private translate: TranslateService,
    private cdf: ChangeDetectorRef,
    private localStorage: LocalStorageService
  ) {}

  toggleAside(event: MouseEvent) {
    this.onToggleAside.emit();
    event.stopPropagation();
  }

  toggleSidebar(event) {
    event.stopPropagation();
    this.layoutUtil.setIsSidebarExpanded(!this.sidebarExpanded);
  }

  getAccountClassBasedOnContext(): string {
    const isRestaurantContext = this.textForTranslation.CONTEXT_FLAG.toLowerCase() === CONSTANTS.CONTEXT.RESTAURANT.toLowerCase();

    return isRestaurantContext ? 'fb-semiTransparent text-sm' : '';
  }

  ngOnInit() {
    this.getContext()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((contextObject: ContextObject) => {
        this.setContextInformation(contextObject);
        this.accountClassBasedOnContext = this.getAccountClassBasedOnContext();
        this.cdf.detectChanges();
      });
    this.getSingleVenueModeState()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((isSingleVenueAccount: boolean) => (this.isSingleVenueAccount = isSingleVenueAccount));
    this.getIsSidebarExpanded()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((isSidebarExpanded: boolean) => (this.sidebarExpanded = isSidebarExpanded));
      this.accountChildrenNameSingular = this.contextService.getAccountChildrenNameTranslation(true);
    this.accountChildrenNamePlural = this.contextService.getAccountChildrenNameTranslation(false);

  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private getSingleVenueModeState(): Observable<boolean> {
    return this.accountSingleRestaurantBackendService.isSingleVenueMode();
  }

  private getContext(): Observable<ContextObject> {
    return this.contextService.getContext$();
  }

  private getIsSidebarExpanded(): Observable<boolean> {
    return this.layoutUtil.getIsSidebarExpanded$();
  }

  private setContextInformation(contextObject: ContextObject) {
    this.textForTranslation.ACCOUNT_NAME = contextObject.accountName;
    this.textForTranslation.CONTEXT_FLAG = this.textForTranslation.CONTEXT_FLAG =
      contextObject.type === CONSTANTS.CONTEXT.VENUE ? 'Restaurant' : contextObject.type;
    this.textForTranslation.CONTEXT_TEXT =
      contextObject.type === CONSTANTS.CONTEXT.VENUE
        ? this.translate.instant('SHARED.BREADCRUMBS.STATEMENT_CATEGORY.RESTAURANT')
        : contextObject.venueType;
    this.textForTranslation.VENUE_NAME = contextObject.venueName;
    this.contextType = contextObject.type;
  }
}
