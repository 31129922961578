import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { NavigationService } from '../../core/providers/navigation/navigation.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class LastPageGuard implements CanActivate {
  constructor(private navigationService: NavigationService, private authService: AuthService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const url = localStorage.getItem('lastUrl');

    if ((!this.authService.authenticated() && !url) || url === '' || url === '/') {
      this.navigationService.goToLogin();
    } else if ((this.authService.authenticated() && !url) || url === '' || url === '/') {
      this.navigationService.goToLogin();
    } else if (url && url !== '' && url !== '/') {
      this.navigationService.goToUrl(url);
    }

    return true;
  }
}
