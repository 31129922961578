import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, zip } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { AccountSingleVenueBackendService } from '../../../core/providers/backend/account-single-venue-backend.service';
import { ContextService } from '../../../core/providers/context/context.service';
import { ContextObject } from '../../interfaces/login-object';

@Injectable()
export class QrUrlsGuard implements CanActivate {
  constructor(
    private accountSingleVenueBackendService: AccountSingleVenueBackendService,
    private contextService: ContextService,
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return zip(
      this.accountSingleVenueBackendService.isSingleVenueMode().pipe(first()),
      this.contextService.getContext$()
    ).pipe(
      map(([singleVenueMode, context]: [boolean, ContextObject]) => {
        const canActivate: boolean = ContextService.isAccountContext(context) && !singleVenueMode;

        if (!canActivate) {
          this.router.navigate(['/']);
        }

        return canActivate;
      })
    );
  }
}
