<ul
  #el
  *ngFor="let sidebar of sidebarMenus"
  (mouseover)="sidebar.submenu.length ? onSidebarHover(sidebar, $event) : $event.preventDefault()"
  (mouseout)="onSidebarBlur(sidebar, $event)"
  class="nav">
  <li [class.active]="sidebar.isActive">
    <a *ngIf="sidebar.submenu && sidebar.submenu.length > 0; else normalLink" (click)="toggleSidebarMenu(sidebar)">
      <em [ngClass]="sidebar.icon"></em>
      <span>{{ sidebar.title | translate | titlecase }}</span>
    </a>
    <ng-template #normalLink>
      <a [routerLink]="sidebar.link">
        <em [ngClass]="sidebar.icon"></em>
        <span>{{ sidebar.title | translate | titlecase }}</span>
      </a>
    </ng-template>

    <ul
      [ngClass]="{
        'expanded-submenu': (sidebar.isExpanded && !isSidebarCollapsed) || (isSidebarCollapsed && sidebar.isHovered),
        opening: (sidebar.isExpanded && !isSidebarCollapsed) || (isSidebarCollapsed && sidebar.isHovered)
      }"
      class="nav sidebar-subnav">
      <li *ngFor="let submenu of sidebar.submenu">
        <a [routerLink]="submenu.link" [class.sub-active]="submenu.isActive">
          <span>{{ submenu.title | translate | titlecase }}</span>
        </a>
      </li>
    </ul>
  </li>
</ul>
