<div class="Breadcrumbs">
  <span *ngFor="let breadcrumb of breadcrumbs; last as isLast; first as isFirst" class="Breadcrumb" [ngClass]="{ 'Breadcrumb--last': isLast }">
    <a [routerLink]="breadcrumb.routerLink" [queryParams]="breadcrumb.params" *ngIf="isFirst || isLast" class="Breadcrumb-link">
      <span *ngTemplateOutlet="breadcrumbText"></span>
    </a>
    <a [routerLink]="breadcrumb.routerLink" [queryParams]="breadcrumb.params" *ngIf="!isLast && !isFirst" class="Breadcrumb-link">
      <span *ngTemplateOutlet="breadcrumbBackendLabel"></span>
    </a>
    <ng-template #breadcrumbText>
      {{
        breadcrumb.text ||
          (breadcrumb.translateKey ? ('SHARED.BREADCRUMBS.' + breadcrumb.translateKey | uppercase) : 'SHARED.BREADCRUMBS.MISSING')
          | translate
      }}
    </ng-template>
    <ng-template #breadcrumbBackendLabel>
      {{
        breadcrumb.text ||
          (breadcrumb.translateKey ? ('SHARED.BACKEND_LABELS.' + breadcrumb.translateKey | uppercase) : 'SHARED.BREADCRUMBS.MISSING')
          | translate
      }}
    </ng-template>
  </span>
</div>
