<div class="item user-block" *ngIf="userBlockIsVisible()">
  <div class="icon-close user-block-icon" (click)="toggleAdminPanel()"></div>
  <!-- User picture-->
  <div class="user-block-picture">
    <div class="user-block-status img-thumbnail img-circle p0">
      <img *ngIf="userData?.picture?.url; else icon" class="fw fh" [src]="userData?.picture?.url" alt="Avatar" />
      <ng-template #icon>
        <i class="fa fa-3x fa-user"></i>
      </ng-template>
    </div>
  </div>
  <!-- Name and Job-->
  <div class="user-block-info">
    <span class="user-block-role"> Admin </span>
    <span class="user-block-name"> {{ userData?.name || userEmail | ellipsis: [CONSTANTS.PAGE_DEFAULTS.MAX_USER_NAME] }} </span>
  </div>
</div>
