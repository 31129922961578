import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { AuthService } from '../components/login/auth.service';
import { IntercomService } from '../core/providers/backend/intercom.service';
import { UserService } from '../core/providers/user/user.service';
import { LayoutUtil } from '../core/utils';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LayoutComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();

  constructor(
    private layoutUtil: LayoutUtil,
    private intercomService: IntercomService,
    private userService: UserService,
    private authService: AuthService
  ) {}

  routeChanged() {
    this.intercomService.updateIntercom();
    this.layoutUtil.setIsSidebarExpanded(false);
  }

  ngOnInit(): void {
    if (this.authService.authenticated()) {
      this.userService.setLoggedIn(true);
    }

    this.updateIntercom();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private updateIntercom() {
    this.subscriptions.add(
      this.userService.isLoggedIn$.pipe(distinctUntilChanged()).subscribe(isLoggedIn => {
        // eslint-disable-next-line
        if (isLoggedIn) {
          this.intercomService.updateIntercomData({
            user_id: this.userService.getUserUuid(),
            email: this.userService.getUserEmail(),
          });
        } else {
          this.intercomService.shutdownIntercom();
        }
      })
    );
  }
}
