import { Injectable, OnDestroy } from '@angular/core';
import { isNil } from 'lodash';
import { Intercom } from 'ng-intercom';
import { ReplaySubject, Subject } from 'rxjs';
import { filter, first, map, mergeMap, takeUntil } from 'rxjs/operators';
import { Dictionary } from '../../../shared/interfaces/common.interface';
import { ContextService } from '../context/context.service';
import { UserService } from '../user/user.service';
import { RolesService } from './roles.service';

@Injectable()
export class IntercomService implements OnDestroy {
  private isIntercomInit = false;
  private userHash: ReplaySubject<string> = new ReplaySubject<string>();
  private intercom: Intercom;
  private onDestroy$ = new Subject<void>();

  constructor(private userService: UserService, private rolesService: RolesService, private contextService: ContextService) {
    this.userService
      .getLoginData$()
      .pipe(filter(loginData => !isNil(loginData)))
      .subscribe(loginData => {
        this.userHash.next(loginData.intercom.userHash);
      });
  }

  updateIntercomData(data: Dictionary<any>) {
    this.userHash.pipe(first()).subscribe(userHash => {
      const hashedData: Dictionary<any> = {
        ...data,
        user_hash: userHash,
      };

      if (this.rolesService.isAdmin()) {
        // Foodback admin activity should not be logged, but Intercom still should be visible
        delete hashedData.companies;
      }
      if (this.isIntercomInit) {
        this.updateIntercom(hashedData);
      }
    });
  }

  updateIntercom(intercomData?: any) {
    this.contextService
      .getContext$()
      .pipe(
        mergeMap(context =>
          this.userService.getUserData$().pipe(
            map(userData => ({
              contextUuid: context.uuid,
              companies: userData.companies,
            })),
            first(),
            takeUntil(this.onDestroy$)
          )
        ),
        first(),
        takeUntil(this.onDestroy$)
      )
      .subscribe(data => {
        const uuid = this.checkAndTakeCompanies(data.companies, data.contextUuid);

        intercomData = uuid
          ? {
              ...intercomData,
              companies: [
                {
                  company_id: this.checkAndTakeCompanies(data.companies, data.contextUuid),
                },
              ],
            }
          : intercomData;
        this.intercom.update(intercomData);
      });
  }

  shutdownIntercom() {
    if (this.isIntercomInit) {
      this.intercom.shutdown();
    }
  }

  showIntercom(): void {
    if (this.isIntercomInit) {
      this.intercom.show();
    }
  }

  initIntercom(intercomObj: Intercom) {
    this.intercom = intercomObj;
    this.isIntercomInit = true;
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private checkAndTakeCompanies(companies: string[], contextUuid: string) {
    const uuid = companies.find(companyUuid => companyUuid === contextUuid);

    return uuid ? uuid : null;
  }
}
