<div class="context-picker">
  <div class="context-picker-content" (click)="toggleSidebar($event)">
    <div class="content-container">
      <div class="context-feedback">
        <ng-container *ngIf="!isTopLevelDashboard; else topLevelDashboard">
          <div
            class="venue-name"
            *ngIf="textForTranslation.VENUE_NAME"
            appTooltip
            [text]="textForTranslation.VENUE_NAME.length >= CONSTANTS.PAGE_DEFAULTS.MAX_NAME_LENGTH ? textForTranslation.VENUE_NAME : null">
            <span> {{ textForTranslation.VENUE_NAME | ellipsis: [CONSTANTS.PAGE_DEFAULTS.MAX_NAME_LENGTH] }} </span>
          </div>
          <div
            class="account-name"
            *ngIf="contextType === CONSTANTS.CONTEXT.ACCOUNT  && textForTranslation.ACCOUNT_NAME"
            appTooltip
            [text]="
            textForTranslation.ACCOUNT_NAME.length > CONSTANTS.PAGE_DEFAULTS.MAX_NAME_LENGTH ? textForTranslation.ACCOUNT_NAME : null
          ">
            <span> {{ textForTranslation.ACCOUNT_NAME | ellipsis: [CONSTANTS.PAGE_DEFAULTS.MAX_NAME_LENGTH] }} </span>
          </div>
          <div
            class="context-type-container context-flag fb-semiTransparent text-sm text-uppercase">
            {{ (contextType === CONSTANTS.CONTEXT.ACCOUNT ? 'SHARED.SYSTEM.ACCOUNT' : accountChildrenNameSingular) | translate }}
          </div>
        </ng-container>
        <ng-template #topLevelDashboard>
          <div class="tdl-name">
            <span>{{'SHARED.SYSTEM.TOP_LEVEL_DASHBOARD' | translate}}</span>
          </div>
        </ng-template>
      </div>
    </div>
    <div class="context-picker-arrow-container">
      <i class="context-picker-arrow icon-arrow-right"></i>
    </div>

    <!--<button *ngIf="isMobileDevice"-->
    <!--(click)="toggleAside($event)"-->
    <!--class="btn close-aside">-->
    <!--<i class="icon-close"></i>-->
    <!--</button>-->
  </div>
</div>
