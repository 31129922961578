<div class="AccountGoalsWrapper">
  <div id="header" class="content-heading empty-list-heading">
    <p class="heading-text">{{ 'SHARED.SYSTEM.GOALS' | translate }}</p>
  </div>

  <layout-content>
    <form class="AccountGoals" *ngIf="goalsForm; else loading" [formGroup]="goalsForm" (ngSubmit)="onSubmit(goalsForm)">
      <div>
        <h4 class="page-header mt0">{{ 'ACCOUNT.GOALS.SUBHEADERS.GENERAL' | translate }}</h4>
        <span class="AccountGoals-description"> {{ 'ACCOUNT.GOALS.DESCRIPTION' | translate: { childrenName: accountChildrenNamePlural } }} </span>
      </div>

      <div class="mt-xl">
        <h4 class="page-header mt0">{{ 'SHARED.SYSTEM.OVERALL_EXPERIENCE' | translate }}</h4>
        <div>
          <app-goal-slider
            formControlName="overallExperience"
            class="AccountGoalsCategory-slider"
            [ngClass]="'AccountGoalsCategory-slider--' + goals.overallExperience.category.name"></app-goal-slider>
        </div>
      </div>

      <div class="mt-xl">
        <h4 class="page-header mt0">{{ 'SHARED.SYSTEM.CATEGORIES' | translate }}</h4>
        <div *ngFor="let category of categoriesFormControls; let i = index" class="AccountGoalsCategory">
          <div class="AccountGoalsCategoryDetails">
            <div
              class="AccountGoalsCategoryDetails-icon"
              [ngClass]="'AccountGoalsCategoryDetails-icon--' + goals.categories[i].category.name"></div>
            <span class="AccountGoalsCategoryDetails-name">
              <ng-container *ngIf="goals.categories[i].category.flexibleName; else normalHeader">
                {{ goals.categories[i].category.flexibleName }}
              </ng-container>
              <ng-template #normalHeader>
                {{ 'SHARED.BACKEND_LABELS.' + goals.categories[i].category.name | uppercase | translate }}
              </ng-template>
            </span>
            <div
              class="AccountGoalsCategoryDetails-tooltip"
              appTooltip
              [contentTemplate]="activeQuestionsInCategory"
              containerClass="AccountGoalsCategoryDetailsTooltip"
              placement="right">
              <span class="mdi mdi-information-outline mdi-18px ml-sm"></span>
            </div>
            <ng-template #activeQuestionsInCategory>
              <div
                class="AccountGoalsCategoryDetailsTooltip-header"
                [innerHTML]="
                  'ACCOUNT.GOALS.TOOLTIP_HEADER'
                    | translate
                      : {
                          categoryName: goals.categories[i].category.flexibleName
                            ? goals.categories[i].category.flexibleName
                            : ('SHARED.BACKEND_LABELS.' + goals.categories[i].category.name | uppercase | translate)
                        }
                "></div>
              <div class="AccountGoalsCategoryDetailsTooltip-question">
                {{ 'SHARED.FOODBACK_STATEMENTS.EXAMPLES_SHORT.EN-GB' | translate }}
              </div>
              <ol class="AccountGoalsCategoryDetailsTooltip-list">
                <li *ngFor="let question of questionsInCategories[i]">{{ question }}</li>
              </ol>
            </ng-template>
          </div>
          <app-goal-slider
            [formControl]="category"
            class="AccountGoalsCategory-slider"
            [ngClass]="'AccountGoalsCategory-slider--' + goals.categories[i].category.name"></app-goal-slider>
        </div>
      </div>

      <div class="submit-panel-spacer"></div>
      <div class="submit-panel text-uppercase">
        <button class="btn btn-foodback" [attr.disabled]="isSaveButtonDisabled || saving ? true : null">
          <ng-container *ngIf="saving; else save">{{ 'ACCOUNT.GOALS.BUTTONS.SAVING' | translate }}</ng-container>
          <ng-template #save>{{ 'SHARED.SYSTEM.SAVE' | translate }}</ng-template>
        </button>
      </div>
    </form>

    <ng-template #loading>
      <app-preloader></app-preloader>
    </ng-template>
  </layout-content>
</div>
