import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NavigationStart, Router } from '@angular/router';
import { every } from 'lodash';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, map, mergeMap, take, takeUntil } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ACCOUNT_ROUTES_PATHS_FULL } from '../../components/account/account-routes-paths';
import { VENUE_ROUTES_PATHS_FULL } from '../../components/venue/venue-routes-paths';
import { AccountSingleVenueBackendService } from '../../core/providers/backend/account-single-venue-backend.service';
import { GoalsBackendService } from '../../core/providers/backend/goals-backend.service';
import { IamBackendService } from '../../core/providers/backend/iam-backend.service';
import { IntercomService } from '../../core/providers/backend/intercom.service';
import { PermissionsBackendService } from '../../core/providers/backend/permissions-backend.service';
import { RolesService } from '../../core/providers/backend/roles.service';
import { ContextService } from '../../core/providers/context/context.service';
import { NavigationService } from '../../core/providers/navigation/navigation.service';
import { UserService } from '../../core/providers/user/user.service';
import { ContextUtil, LayoutUtil } from '../../core/utils';
import { CONSTANTS } from '../../shared/constants';
import { VenueFromBackend } from '../../shared/form-models-interfaces';
import { IDropdownItem } from '../../shared/interfaces/dropdown/dropdown-item.interface';
import { ContextObject } from '../../shared/interfaces/login-object';
import { SidebarAccountPermissions } from './sidebar.interface';
import { SidebarService } from './sidebar.service';
import { LocalStorageService } from 'ngx-webstorage';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnDestroy, OnInit {
  account: { uuid: string; name: string } = {
    uuid: '',
    name: '',
  };
  accountChildrenNameSingular: string;
  accountChildrenNamePlural: string;
  CONSTANTS = CONSTANTS;
  contextType = '';
  contextUuid = '';
  canSeeSettings = false;
  info = {
    version: environment.version,
    buildNumber: environment.buildNumber,
    buildDate: environment.buildDate,
  };
  isAdminPanel = false;
  buildDate = new Date(this.info.buildDate);
  isCollapsed = false;
  isExpanded = false;
  isMobileDevice: boolean = this.layoutUtil.isMobileDevice();
  isSidebarHide = false;
  accountPermissions: SidebarAccountPermissions = {};
  resizeEvent$: Subject<Event> = new Subject<Event>();
  venues: Partial<VenueFromBackend>[] = [];
  venueUuid = '';
  isSingleVenueAccount: boolean;
  accountsList: IDropdownItem[] = [];
  selectedAccount: IDropdownItem;
  isAccountSwitcherDisabled = null;
  accountCtrl: FormControl = new FormControl();
  venueFilterCtrl: FormControl = new FormControl('');
  filteredVenues: Partial<VenueFromBackend>[];
  isTopLevelDashboard = false;
  isAllowedToAccessTopLevelDashboard$: Observable<boolean>;
  readonly VENUE_ROUTES_PATHS_FULL = VENUE_ROUTES_PATHS_FULL;
  readonly ACCOUNT_ROUTES_PATHS_FULL = ACCOUNT_ROUTES_PATHS_FULL;
  private boundResize = this.onResize.bind(this);
  private isSelectAccountOpen = false;
  private onDestroy$ = new Subject<void>();

  constructor(
    private accountSingleRestaurantBackendService: AccountSingleVenueBackendService,
    private layoutUtil: LayoutUtil,
    private router: Router,
    private contextService: ContextService,
    private contextUtil: ContextUtil,
    private permissionsBackend: PermissionsBackendService,
    private rolesService: RolesService,
    private navigationService: NavigationService,
    private sidebarService: SidebarService,
    private iamBackendService: IamBackendService,
    private intercomService: IntercomService,
    private userService: UserService,
    private goalsBackendService: GoalsBackendService,
    private zone: NgZone,
    private localStorage: LocalStorageService
  ) {}

  isBackToAccountLinkVisible(): boolean {
    return this.isSingleVenueAccount
      ? this.rolesService.isAdmin()
      : this.isTopLevelDashboard ||
          every([
            !this.isContextSetAsCurrentContext(
              CONSTANTS.CONTEXT.ACCOUNT,
              this.account?.uuid
            ),
            this.canChangeToAccount(this.account?.uuid),
          ]);
  }

  switchAccountContext(newAccount: IDropdownItem) {
    this.goalsBackendService.clearGoals();

    if (this.canChangeToAccount(newAccount.id)) {
      this.switchContext(CONSTANTS.CONTEXT.ACCOUNT, newAccount.id);
    } else {
      this.updateVenueList(newAccount.id);
    }

    this.selectedAccount = newAccount;
    this.accountChildrenNameSingular = this.contextService.getAccountChildrenNameTranslation(true);
    this.accountChildrenNamePlural = this.contextService.getAccountChildrenNameTranslation(false);
  }

  switchVenueContext(venueUuid: string, event: MouseEvent) {
    event.preventDefault();
    event.stopImmediatePropagation();
    this.switchContext(CONSTANTS.CONTEXT.VENUE, venueUuid);
  }

  toggleSidebar() {
    this.layoutUtil.toggleIsCollapsed();
    this.layoutUtil.toggleIsMobileSidebar();
  }

  arrowButtonConditions() {
    const conditionsForMobile = {
      'mdi-menu': this.isSidebarHide,
      'mdi-chevron-left': !this.isSidebarHide,
    };
    const conditionsForDesktop = {
      'mdi-menu': this.isCollapsed,
      'mdi-chevron-left': !this.isCollapsed,
    };

    return this.isMobileDevice ? conditionsForMobile : conditionsForDesktop;
  }

  closeExpandedSidebar() {
    if (!this.isSelectAccountOpen) {
      this.layoutUtil.setIsSidebarExpanded(false);
    }
  }

  canChangeToAccount(accountUuid: string): boolean {
    if (!this.accountPermissions[accountUuid]) {
      return false;
    }

    return this.accountPermissions[accountUuid].canChangeToAccount;
  }

  isContextSetAsCurrentContext(contextType: string, uuid: string): boolean {
    return this.contextType === contextType && this.contextUuid === uuid;
  }

  onResize(event: Event) {
    this.resizeEvent$.next(event);
  }

  onSelectOpen(status: boolean) {
    this.isSelectAccountOpen = status;
  }

  goToTopLevelDashboard(): void {
    this.navigationService.goToTopLevelDashboard();
  }

  ngOnInit() {
    this.getIsMobile$()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((data: boolean) => {
        this.isMobileDevice = data;
      });
    this.accountCtrl.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(selectedAccount => {
        if (this.account?.uuid !== selectedAccount.id) {
          this.switchAccountContext(selectedAccount);
        }
      });
    this.venueFilterCtrl.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(value => {
        if (this.venues.length > 0 && value !== '') {
          this.filteredVenues = this.venues.filter(venue =>
            venue.brandName.toLowerCase().includes(value.toLowerCase())
          );
        } else {
          this.filteredVenues = this.venues;
        }
      });
    this.onNavigationStartEvent$()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        window.scrollTo(0, 0);
      });
    this.getIsLayoutCollapsed$()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(isSidebarCollapsed =>
        this.setLayoutCollapsedStatus(isSidebarCollapsed)
      );
    this.getContext$()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(contextObject => {
        this.layoutUtil.setIsSidebarExpanded(false);
        this.handleContextChanged(contextObject);
      });
    this.getIsSidebarExpanded$()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(isSidebarExpanded => {
        this.isExpanded = isSidebarExpanded;
      });
    this.getSingleVenueModeState$()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((isSingleVenueAccount: boolean) => {
        this.isSingleVenueAccount = isSingleVenueAccount;
      });
    this.getIsAdminPanel$()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((isAdminPanel: boolean) => (this.isAdminPanel = isAdminPanel));
    this.getTopLevelDashboard$()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((isTopLevelDashboard: boolean) => {
        this.isTopLevelDashboard = isTopLevelDashboard;

        if (this.isTopLevelDashboard) {
          this.accountCtrl.setValue(null, { emitEvent: false });
          this.account.uuid = null;
        }
      });
    this.zone.runOutsideAngular(() => {
      window.addEventListener('resize', this.boundResize);
    });

    this.isAllowedToAccessTopLevelDashboard$ = this.iamBackendService.isAllowedToAccessTopLevelDashboard(this.userService.getUserUuid());

    this.accountChildrenNameSingular = this.contextService.getAccountChildrenNameTranslation(true);
    this.accountChildrenNamePlural = this.contextService.getAccountChildrenNameTranslation(false);
  }

  ngOnDestroy() {
    // https://github.com/angular/angular/issues/16023#issuecomment-294611118
    this.onDestroy$.next();
    this.onDestroy$.complete();
    window.removeEventListener('resize', this.boundResize);
  }

  private getIsMobile$(): Observable<boolean> {
    return this.layoutUtil.getIsMobileDevice$();
  }

  private getSingleVenueModeState$(): Observable<boolean> {
    return this.accountSingleRestaurantBackendService.isSingleVenueMode();
  }

  private fetchContextData(contextObject: ContextObject) {
    if (this.contextUtil.isAccountContext(this.contextType)) {
      this.loadAccountContextData(contextObject);
    } else {
      this.loadVenueContextData(contextObject);
    }
  }

  private switchContext(contextType: string, uuid: string) {
    const currentUserUuid: string = this.userService.getUserUuid();

    this.closeExpandedSidebar();
    this.intercomService.updateIntercomData({
      user_id: currentUserUuid,
    });
    this.iamBackendService
      .updateCurrentContext(currentUserUuid, { uuid, type: contextType })
      .pipe(
        mergeMap(() => this.contextService.switchContext$(contextType, uuid))
      )
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(newContext => {
        switch (newContext.type) {
          case CONSTANTS.CONTEXT.ACCOUNT:
            this.navigationService.goToScoreboards(newContext.uuid);
            break;
          case CONSTANTS.CONTEXT.VENUE:
            this.navigationService.goToVenueMainScreen(newContext.uuid);
            break;
          default:
            break;
        }
      });
  }

  private loadVenueContextData(venueContext: ContextObject) {
    this.venueUuid = venueContext.uuid;
    this.canSeeSettings = venueContext.isUserVenueAdmin;
    this.sidebarService
      .getAccountDataFromVenueUuid$(this.venueUuid)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((accountData: ContextObject) => {
        this.updateAccountContext(accountData);
        this.updateVenueList(accountData?.uuid);
      });
  }

  private loadAccountContextData(contextObject: ContextObject) {
    this.updateAccountContext(contextObject);
    this.updateVenueList(contextObject.uuid);
    this.canSeeSettings = contextObject.isUserAccountAdmin;
  }

  private getListOfAccountsForSignedUser() {
    this.permissionsBackend
      .getAccountsSignedToUser$()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(accountsList => {
        this.accountsList = accountsList;
        this.setActiveAccount();

        // TODO Remove this ugly hack
        if (
          this.userService.getUserUuid() ===
          '3f32bb6f-937c-4b65-b908-08d13a35b95c'
        ) {
          this.accountsList = this.accountsList.filter(
            item => item.id !== 'bcac19cb-aa38-49a0-bbb9-edba26c35993'
          );
        }

        this.isAccountSwitcherDisabled =
          this.accountsList.length <= 1 ? true : null;
      });
  }

  private updateAccountContext(account: ContextObject) {
    this.account.uuid = account?.uuid;
    this.account.name = account?.accountName;
    this.selectedAccount = { id: account?.uuid, text: account?.accountName };
    this.setActiveAccount();
  }

  private setActiveAccount() {
    // eslint-disable-next-line
    if (this.selectedAccount) {
      const activeAccount: IDropdownItem = this.accountsList.find(
        a => a.id === this.selectedAccount.id
      );

      if (activeAccount) {
        this.accountCtrl.setValue(activeAccount);
      }
    }
  }

  private updateVenueList(accountUuid: string) {
    this.sidebarService
      .getVenueListForAccount$(accountUuid)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(venues => {
        this.venues = venues;
        this.filteredVenues = [...this.venues];
      });
  }

  private getContext$(): Observable<ContextObject> {
    return this.contextService.getContext$();
  }

  private getIsLayoutCollapsed$(): Observable<boolean> {
    return this.layoutUtil.getIsCollapsed$();
  }

  private onNavigationStartEvent$(): Observable<any> {
    return this.router.events.pipe(
      filter(event => event instanceof NavigationStart)
    );
  }

  private getIsSidebarExpanded$(): Observable<boolean> {
    return this.layoutUtil.getIsSidebarExpanded$();
  }

  private getIsAdminPanel$() {
    return this.layoutUtil.getIsAdminPanel$();
  }

  private getTopLevelDashboard$(): BehaviorSubject<boolean> {
    return this.layoutUtil.getIsTopLevelDashboard$();
  }

  private handleContextChanged(contextObject: ContextObject) {
    this.contextType = contextObject.type;
    this.contextUuid = contextObject.uuid;
    this.fetchContextData(contextObject);
    this.getListOfAccountsForSignedUser();
    this.getAccountPermissions$()
      .pipe(take(1), takeUntil(this.onDestroy$))
      .subscribe(accountPermissions => {
        this.accountPermissions = accountPermissions;
      });
  }

  private getAccountPermissions$(): Observable<SidebarAccountPermissions> {
    return this.permissionsBackend
      .getPermissions$()
      .pipe(
        map(accountPermissions =>
          this.sidebarService.simplifyAccountPermissionsFromBackend(
            accountPermissions
          )
        )
      );
  }

  private setLayoutCollapsedStatus(value: boolean) {
    this.isCollapsed = value;
    this.isSidebarHide = value;
    this.layoutUtil.setIsSidebarExpanded(false);
  }
}
