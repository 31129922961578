import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FusionChartsModule } from 'angular-fusioncharts';
import { IMaskModule } from 'angular-imask';
import * as FusionCharts from 'fusioncharts';
import * as Components from './components';
import * as CommonDialog from './components/dialog/dialogs';
import * as FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import { BrandingUploadLogoComponent } from './components/folder-branding-logo/branding-upload-logo/branding-upload-logo.component';
import { AdjustPositionDirective } from './directives/adjust-position/adjust-position.directive';
import * as Guards from './guards';
import * as charts from 'fusioncharts/fusioncharts.charts';
import { AccEnforcementSwitcherComponent } from './components/acc-enforcement-switcher/acc-enforcement-switcher.component';
import { AccGoalsComponent } from '../components/account/settings/acc-goals/acc-goals.component';
import { AscendingDatePipe } from './pipes/ascending-date.pipe';
import { AscendingPipe } from './pipes/ascending.pipe';
import { BillFolderDotsComponent } from './components/bill-folder-dots/bill-folder-dots.component';
import { BillFolderHeaderComponent } from './components/bill-folder/bill-folder-header/bill-folder-header.component';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { ColorPickerModule } from 'ngx-color-picker';
import { CommaDecimalSeparatorPipe } from './pipes/comma-decimal-separator.pipe';
import { ContentComponent } from './components/layout/content/content.component';
import { ContentService } from './components/layout/content/content.service';
import { CurrencyDenominatorPipe } from './pipes/currency-denominator.pipe';
import { DataCardWidgetComponent } from './components/widgets/data-card-widget/data-card-widget.component';
import { DatePickerHeaderMobileComponent } from './components/date-picker-header-mobile/date-picker-header-mobile.component';
import { DatexPipePipe } from './pipes/datex-pipe.pipe';
import { DescendingPipe } from './pipes/descending.pipe';
import {
  DialogActionsDirective,
  DialogComponent,
  DialogContentDirective,
  DialogTitleDirective,
} from './components/dialog/dialog.component';
import { DialogService } from './components/dialog/dialog.service';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { EllipsisPipe } from './pipes/ellipsis.pipe';
import { ErrorDirective } from './directives/error/error.directive';
import { ErrorsDirective } from './directives/error/errors.directive';
import { FileDragNDropDirective } from './directives/file-drag-n-drop/file-drag-n-drop.directive';
import { FileUploadModule } from 'ng2-file-upload';
import { FolderBrandingColourComponent } from './components/folder-branding-color/folder-branding-color.component';
import { FolderBrandingExampleComponent } from './components/folder-branding-example/folder-branding-example.component';
import { FolderBrandingLogoComponent } from './components/folder-branding-logo/folder-branding-logo.component';
import { FormPanelComponent } from './components/form-panel/form-panel.component';
import { GetNativeLanguageTranslationOrFirstPipe } from './pipes/get-native-language-translation-or-first.pipe';
import { GoalSliderComponent } from './components/goal-slider/goal-slider.component';
import { GroupsAndVenuesSelectComponent } from './components/groups/groups-and-venues-select/groups-and-venues-select.component';
import { GroupsComponent } from './components/groups/groups.component';
import { GroupsListComponent } from './components/groups/groups-list/groups-list.component';
import { GroupsService } from './components/groups/groups.service';
import { ImageCropperModule } from 'ngx-image-cropper';
import { InactiveStateOnTheEndPipe } from '../components/account/settings/acc-foodback/show-inactive-statements.pipe';
import { InfoFormBoxComponent } from './components/info-form-box/info-form-box.component';
import { InputWithCounterComponent } from './components/input-with-counter/input-with-counter.component';
import { LanguagesFormComponent } from './components/languages-form/languages-form.component';
import { MaterialModule } from '../material/material.module';
import { MessageBarComponent } from './components/message-bar/message-bar.component';
import { MultiSelectTagComponent } from './components/multi-select-tag/multi-select-tag.component';
import { MultiSelectTagListComponent } from './components/multi-select-tag/multi-select-tag-list/multi-select-tag-list.component';
import { MultiSelectTagListService } from './components/multi-select-tag/multi-select-tag-list/multi-select-tag-list.service';
import { NestedDropdownComponent } from './components/nested-dropdown-filter/nested-dropdown/nested-dropdown.component';
import { NestedDropdownFilterComponent } from './components/nested-dropdown-filter/nested-dropdown-filter.component';
import { NewCategoryDialogComponent } from './components/groups/new-category-dialog/new-category-dialog.component';
import { NewGroupDialogComponent } from './components/groups/new-group-dialog/new-group-dialog.component';
import { Ng2PageScrollModule } from 'ng2-page-scroll';
import { NouisliderModule } from 'ng2-nouislider';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { PatternSortPipe } from './pipes/pattern-sort.pipe';
import { PercentCircleComponent } from './components/percent-circle/percent-circle.component';
import { PreloaderComponent } from './components/preloader/preloader.component';
import { PrettyDateRangePipe } from './pipes/pretty-date-range.pipe';
import { PrettyNumbersPipe } from './pipes/pretty-numbers.pipe';
import { RouterLinkPipe } from './pipes/router-link.pipe';
import { SanitizeHtmlPipe } from './pipes/sanitize-html.pipe';
import { ScrollHintComponent } from './components/scroll-hint/scroll-hint.component';
import { SelectComponent } from './components/select/select.component';
import { SelectSearchComponent } from './components/select/select-search/select-search.component';
import { SnackbarComponent } from './components/snackbar/snackbar.component';
import { SortByFieldPipe } from './pipes/sort-by-field.pipe';
import { StandardStatementsFirstPipe } from './pipes/standard-statements-first.pipe';
import { SwiperModule } from 'swiper/angular';
import { SwitcherLargeComponent } from './components/switcher-large/switcher-large.component';
import { TableProgressBarComponent } from './components/table-progress-bar/table-progress-bar.component';
import { TextareaWithCounterComponent } from './components/textarea-with-counter/textarea-with-counter.component';
import { TooltipComponent } from './components/tooltip/tooltip.component';
import { TooltipDirective } from './components/tooltip/tooltip.directive';
import { UpperFirstLetterPipe } from './pipes/upper-first-letter.pipe';
import { VenAddComponent } from './components/ven-add/ven-add.component';
import { VenuesListComponent } from './components/groups/venues-list/venues-list.component';
import { SmallTrendlineComponent } from './components/small-trendline/small-trendline.component';
import { DataCardWidgetPanelComponent } from './components/widgets/data-card-widget-panel/data-card-widget-panel.component';
import { DataCardWidgetDetailsComponent } from './components/widgets/data-card-widget-details/data-card-widget-details.component';
import { DataCardWidgetOverallComponent } from './components/widgets/data-card-widget-overall/data-card-widget-overall.component';
import { SmallTrendlineTopDashboardComponent } from './components/small-trendline-top-dashboard/small-trendline-top-dashboard.component';
import { TrendlineChartTopLevelComponent } from './components/trendline-chart-top-level/trendline-chart-top-level.component';

FusionChartsModule.fcRoot(FusionCharts, charts, FusionTheme);

@NgModule({
  imports: [
    ButtonsModule.forRoot(),
    FusionChartsModule,
    CommonModule,
    FileUploadModule,
    FormsModule,
    Ng2PageScrollModule.forRoot(),
    NouisliderModule,
    PaginationModule.forRoot(),
    ReactiveFormsModule,
    RouterModule,
    SwiperModule,
    IMaskModule,
    BsDropdownModule.forRoot(),
    TranslateModule,
    ColorPickerModule,
    MaterialModule,
    ImageCropperModule,
    FlexModule,
  ],
  declarations: [
    AccEnforcementSwitcherComponent,
    AccGoalsComponent,
    AdjustPositionDirective,
    AscendingDatePipe,
    AscendingPipe,
    BillFolderDotsComponent,
    BillFolderHeaderComponent,
    BrandingUploadLogoComponent,
    BreadcrumbsComponent,
    CommaDecimalSeparatorPipe,
    Components.AdvertisementCategorySwitcherComponent,
    Components.AdvertisementComponent,
    Components.AdvertisementFormComponent,
    Components.CardWidgetComponent,
    Components.DateRangePickerComponent,
    Components.EmptyListComponent,
    Components.ProfileAvatarComponent,
    Components.ProfileAvatarCropperDialogComponent,
    Components.RolesSwitcherComponent,
    Components.ValidationErrorsComponent,
    ContentComponent,
    CurrencyDenominatorPipe,
    DataCardWidgetComponent,
    DatexPipePipe,
    DropdownComponent,
    FolderBrandingColourComponent,
    FolderBrandingExampleComponent,
    FolderBrandingLogoComponent,
    GetNativeLanguageTranslationOrFirstPipe,
    GoalSliderComponent,
    GroupsComponent,
    GroupsListComponent,
    InactiveStateOnTheEndPipe,
    InputWithCounterComponent,
    MessageBarComponent,
    MultiSelectTagComponent,
    PatternSortPipe,
    PercentCircleComponent,
    PreloaderComponent,
    PrettyDateRangePipe,
    PrettyNumbersPipe,
    RouterLinkPipe,
    SortByFieldPipe,
    StandardStatementsFirstPipe,
    SwitcherLargeComponent,
    TableProgressBarComponent,
    TextareaWithCounterComponent,
    VenAddComponent,
    VenuesListComponent,
    NewCategoryDialogComponent,
    NestedDropdownFilterComponent,
    NewGroupDialogComponent,
    DialogComponent,
    DialogContentDirective,
    DialogTitleDirective,
    DialogActionsDirective,
    MultiSelectTagListComponent,
    TooltipComponent,
    TooltipDirective,
    CommonDialog.ConfirmDialogComponent,
    SelectComponent,
    SelectSearchComponent,
    DatePickerHeaderMobileComponent,
    DescendingPipe,
    InfoFormBoxComponent,
    SnackbarComponent,
    ErrorDirective,
    ErrorsDirective,
    EllipsisPipe,
    UpperFirstLetterPipe,
    GroupsAndVenuesSelectComponent,
    SanitizeHtmlPipe,
    NestedDropdownComponent,
    ScrollHintComponent,
    LanguagesFormComponent,
    FormPanelComponent,
    FileDragNDropDirective,
    SmallTrendlineComponent,
    SmallTrendlineTopDashboardComponent,
    TrendlineChartTopLevelComponent,
    DataCardWidgetPanelComponent,
    DataCardWidgetDetailsComponent,
    DataCardWidgetOverallComponent,
  ],
  providers: [
    CommaDecimalSeparatorPipe,
    Guards.GoalsGuard,
    Guards.VenueDashboardGuard,
    Guards.GroupsGuard,
    Guards.QrUrlsGuard,
    GroupsService,
    DialogService,
    MultiSelectTagListService,
    ContentService,
    EllipsisPipe,
  ],
  exports: [
    AccEnforcementSwitcherComponent,
    AccGoalsComponent,
    AdjustPositionDirective,
    AscendingDatePipe,
    AscendingPipe,
    BillFolderDotsComponent,
    BillFolderHeaderComponent,
    BrandingUploadLogoComponent,
    BreadcrumbsComponent,
    BsDropdownModule,
    FusionChartsModule,
    CommaDecimalSeparatorPipe,
    CommonModule,
    ButtonsModule,
    Components.AdvertisementCategorySwitcherComponent,
    Components.AdvertisementComponent,
    Components.AdvertisementFormComponent,
    Components.CardWidgetComponent,
    Components.DateRangePickerComponent,
    Components.EmptyListComponent,
    Components.ProfileAvatarComponent,
    Components.RolesSwitcherComponent,
    Components.ValidationErrorsComponent,
    ContentComponent,
    CurrencyDenominatorPipe,
    DataCardWidgetComponent,
    DatexPipePipe,
    DescendingPipe,
    DropdownComponent,
    FileUploadModule,
    FolderBrandingColourComponent,
    FolderBrandingExampleComponent,
    FolderBrandingLogoComponent,
    FormsModule,
    GetNativeLanguageTranslationOrFirstPipe,
    GoalSliderComponent,
    GroupsComponent,
    InactiveStateOnTheEndPipe,
    InputWithCounterComponent,
    MessageBarComponent,
    Ng2PageScrollModule,
    NouisliderModule,
    PaginationModule,
    PatternSortPipe,
    PercentCircleComponent,
    PreloaderComponent,
    PrettyDateRangePipe,
    PrettyNumbersPipe,
    ReactiveFormsModule,
    RouterLinkPipe,
    RouterModule,
    SortByFieldPipe,
    StandardStatementsFirstPipe,
    SwiperModule,
    SwitcherLargeComponent,
    TableProgressBarComponent,
    TextareaWithCounterComponent,
    IMaskModule,
    TranslateModule,
    VenAddComponent,
    NestedDropdownFilterComponent,
    NestedDropdownComponent,
    VenAddComponent,
    MultiSelectTagComponent,
    MultiSelectTagListComponent,
    TooltipComponent,
    TooltipDirective,
    DialogComponent,
    DialogContentDirective,
    DialogTitleDirective,
    DialogActionsDirective,
    SelectComponent,
    MaterialModule,
    DatePickerHeaderMobileComponent,
    InfoFormBoxComponent,
    ImageCropperModule,
    ErrorDirective,
    ErrorsDirective,
    EllipsisPipe,
    UpperFirstLetterPipe,
    GroupsAndVenuesSelectComponent,
    SanitizeHtmlPipe,
    ScrollHintComponent,
    LanguagesFormComponent,
    FormPanelComponent,
    FileDragNDropDirective,
    SmallTrendlineComponent,
    SmallTrendlineTopDashboardComponent,
    TrendlineChartTopLevelComponent,
    DataCardWidgetDetailsComponent,
    DataCardWidgetOverallComponent,
  ],
})
export class SharedModule {}
