import { Injectable } from '@angular/core';
import { fromPairs } from 'lodash';
import { formatPattern } from 'url-matcher';
import { Dictionary } from '../../../shared/interfaces/common.interface';

@Injectable()
export class RouteBuilderService {
  getAbsoluteRoute(routePath: string, params: Dictionary<string> = {}): string {
    return formatPattern(routePath, params);
  }

  getUrlWithParams(routePath: string, params: [string, string][] | [string, string]): string {
    let paramsAsDictionary: Dictionary<string>;

    if (this.assertShortFormParams(params)) {
      paramsAsDictionary = { [params[0]]: params[1] };
    } else {
      paramsAsDictionary = fromPairs(params);
    }

    return this.getAbsoluteRoute(routePath, paramsAsDictionary);
  }

  getUrlWithQueryParams(params: any, path: string): string {
    const esc = encodeURIComponent;
    const queryParams: string = Object.keys(params)
      .map(k => {
        if (Array.isArray(params[k])) {
          return params[k].map(item => `${esc(k)}=${esc(item)}`).join('&');
        }

        return `${esc(k)}=${esc(params[k])}`;
      })
      .join('&');

    return queryParams ? `${path}?${queryParams}` : path;
  }

  private assertShortFormParams(params: [string, string][] | [string, string]): params is [string, string] {
    // eslint-disable-next-line no-magic-numbers
    return params.length === 2 && typeof params[0] === 'string' && typeof params[1] === 'string';
  }
}
