import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { ImageUploadResponse } from '../../../shared/components/profile-avatar/profile-avatar.interface';
import { CONSTANTS } from '../../../shared/constants';
import { ApiResponse } from '../../../shared/interfaces/api-response.interface';
import {
  ActivationCodeFromBackend,
  AdvertisementForBackend,
  BillFolderFromBackend,
  BrandingForBackend,
  VenueDemographicsConfiguration,
} from '../../../shared/interfaces/bill-folders';
import { IAdvertisement } from '../../interfaces/backend/advertisement.interface';
import { ApiService } from '../api/api.service';
import { GenericErrorHandlerService } from '../generic-error-handler/generic-error-handler.service';
import { NotificationService } from '../notification/notification.service';
import { RouteBuilderService } from './route-builder.service';

@Injectable()
export class BillFolderBackendService {
  private basePath = environment.apiBaseUrl.handbook;
  private venuesPath = `${environment.apiBaseUrl.handbook}/fbi/venues`;
  private accountsPath = `${environment.apiBaseUrl.handbook}/fbi/accounts`;

  constructor(
    private apiService: ApiService,
    private genericErrorHandlerService: GenericErrorHandlerService,
    private notificationService: NotificationService,
    private routeBuilderService: RouteBuilderService
  ) {}

  getActivationCodeForVenue$(venueUuid: string): Observable<ActivationCodeFromBackend> {
    return this.apiService
      .get$(`${this.venuesPath}/${venueUuid}/${CONSTANTS.ENDPOINTS.ACTIVATION_CODE}`)
      .pipe(map(response => response.content));
  }

  getFileInfo$(fileUuid: string): Observable<ImageUploadResponse> {
    return this.apiService.get$(`${this.basePath}/storage/files/${fileUuid}`).pipe(map(response => response.content));
  }

  getFileUrl(ileUuid: string, width?: number, height?: number, maxWidth?: number, maxHeight?: number): string {
    const params: any = {
      ...(width && { width }),
      ...(height && { height }),
      ...(maxWidth && { maxWidth }),
      ...(maxHeight && { maxHeight }),
    };

    return this.routeBuilderService.getUrlWithQueryParams(params, `${this.basePath}/storage/files/${ileUuid}`);
  }

  setFileResolutions(ileUuid: string, width?: number, height?: number, maxWidth?: number, maxHeight?: number): Observable<any> {
    const params: any = {
      ...(width && { width }),
      ...(height && { height }),
      ...(maxWidth && { maxWidth }),
      ...(maxHeight && { maxHeight }),
    };

    return this.apiService.post$(`${this.basePath}/storage/files/${ileUuid}/thumbnail`, params);
  }

  getDemographicsConfiguration$(context: string, uuid: string): Observable<VenueDemographicsConfiguration> {
    const requestPath = `${context === CONSTANTS.CONTEXT.ACCOUNT ? this.accountsPath : this.venuesPath}/${uuid}/${
      CONSTANTS.ENDPOINTS.DEMOGRAPHICS_CONFIG
    }`;

    return this.apiService.get$(requestPath).pipe(map(response => response.content));
  }

  updateDemographicsConfiguration$(
    context: string,
    uuid: string,
    questionsObject: VenueDemographicsConfiguration
  ): Observable<ApiResponse> {
    const requestPath = `${context === CONSTANTS.CONTEXT.ACCOUNT ? this.accountsPath : this.venuesPath}/${uuid}/${
      CONSTANTS.ENDPOINTS.DEMOGRAPHICS_CONFIG
    }`;

    return this.apiService.put$(requestPath, questionsObject).pipe(map(response => response.content));
  }

  updateAdvertisement$(context: string, uuid: string, advertisementDetails: AdvertisementForBackend): Observable<ApiResponse> {
    const requestPath = `${context === CONSTANTS.CONTEXT.ACCOUNT ? this.accountsPath : this.venuesPath}/${uuid}/${
      CONSTANTS.ENDPOINTS.ADVERTISEMENT
    }`;
    const onError: (error: ApiResponse) => any = (error: ApiResponse) => {
      // 429- too many requests which means that user reached the year limit of changing the advertisement.
      if (error.status === CONSTANTS.REQUEST_ERRORS.HTTP_429) {
        this.notificationService.displayPopupWithTranslationKey('SHARED.MODALS.BILL_FOLDER_ADVERTS', 'error').subscribe();
      } else {
        this.genericErrorHandlerService.handleApiError(error);
      }
    };

    return this.apiService.put$(requestPath, advertisementDetails, {}, onError).pipe(map((response: ApiResponse) => response.content));
  }

  getAdvertisementInfo$(context: string, uuid: string, mobile?: boolean): Observable<IAdvertisement> {
    const requestPath = `${context === CONSTANTS.CONTEXT.ACCOUNT ? this.accountsPath : this.venuesPath}/${uuid}/${
      CONSTANTS.ENDPOINTS.ADVERTISEMENT
    }`;

    return this.apiService.get$(requestPath, { params: { mobile } }).pipe(map((response: ApiResponse) => response.content));
  }

  updateBranding$(context: string, uuid: string, brandingDetails: BrandingForBackend): Observable<ApiResponse> {
    const requestPath = `${context === CONSTANTS.CONTEXT.ACCOUNT ? this.accountsPath : this.venuesPath}/${uuid}/${
      CONSTANTS.ENDPOINTS.BRANDING
    }`;

    return this.apiService.put$(requestPath, brandingDetails).pipe(map(response => response.content));
  }

  getBrandingInfo$(context: string, uuid: string) {
    const requestPath = `${context === CONSTANTS.CONTEXT.ACCOUNT ? this.accountsPath : this.venuesPath}/${uuid}/${
      CONSTANTS.ENDPOINTS.BRANDING
    }`;

    return this.apiService.get$(requestPath).pipe(map(response => response.content));
  }
}
