import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { keys, last, split, values } from 'lodash';
import { NotificationService } from '../notification/notification.service';

@Injectable()
export class ValidationService {
  // todo: Grupowanie bledow i wyswietlanie w oddzielnych komunikatach.
  private FORM_FIELDS = {
    name: 'Name',
    mail: 'Email',
    email: 'Email',
    phone: 'Phone number',
    number: 'Phone number',
    companyName: 'Legal Company Name',
    brandName: 'Venue Name',
    street: 'Street Address',
    city: 'City',
    isAdmin: 'Role',
  };

  constructor(private notificationService: NotificationService, private translateService: TranslateService) {}

  displayNotification(error: ValidationErrorFromBackend): void {
    // eslint-disable-next-line
    if (error.errorMessage) {
      const errorMessages = error.errorMessage;
      const fields: string = this.getFields(errorMessages);
      const messageToDisplay: string = this.getErrorMessage(errorMessages);

      this.notificationService.error(`${messageToDisplay}: ${fields}`);
    } else {
      // todo: keep it, or force backend for consistent object with error response.
      // this.notificationService.error(this.translateService.instant('SHARED.VALIDATION_MSG.USER_EXISTS'));
    }
  }

  getFields(error: ErrorMessageBackend): string {
    return `${keys(error.validationErrors).map(this.getSingleField.bind(this)).join(', ')}.`;
  }

  getErrorMessage(error: ErrorMessageBackend): string {
    const messages = {
      'validation.required': this.translateService.instant('SHARED.VALIDATION_MSG.REQUIRED'),
      'validation.type.email': this.translateService.instant('SHARED.VALIDATION_MSG.EMAIL'),
      'validation.duplicated': this.translateService.instant('SHARED.VALIDATION_MSG.DUPLICATED'),
    };
    const errorType = this.getErrorType(error.validationErrors);

    return messages[errorType] || this.translateService.instant('SHARED.VALIDATION_MSG.UNEXPECTED');
  }

  private getSingleField(fieldName: string): string {
    const field: string = last(split(fieldName, '.'));

    return this.FORM_FIELDS[field];
  }

  private getErrorType(validationErrors: ErrorMessageBackend): any {
    const errors = values(validationErrors);

    return errors[0];
  }
}
export interface ValidationErrorFromBackend {
  errorMessage: ErrorMessageBackend;
}
export interface ErrorMessageBackend {
  validationErrors: any;
}
