import { ChangeDetectionStrategy, Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { ImageCropperInterface } from '../../../../interfaces/image-cropper.interface';

export const profileAvatarCropperDialogConfig: MatDialogConfig = {
  width: '600px',
  height: '720px',
  disableClose: false,
};
@Component({
  selector: 'app-profile-avatar-cropper-dialog',
  templateUrl: './profile-avatar-cropper-dialog.component.html',
  styleUrls: ['./profile-avatar-cropper-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileAvatarCropperDialogComponent {
  @ViewChild('cropper', { static: true }) cropper;
  croppedImage: any = '';

  constructor(
    private mdDialogRef: MatDialogRef<ProfileAvatarCropperDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ImageCropperInterface
  ) {}

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  saveCroppedImage() {
    const imageDataForBackend = {
      image: this.croppedImage,
    };

    this.mdDialogRef.close(imageDataForBackend);
  }
}
