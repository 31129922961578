<div class="GroupsAndVenues">
  <ng-container *ngIf="groupsAccess">
    <app-nested-dropdown-filter
      class="GroupsAndVenues-filterItem"
      [ngClass]="{ 'mr-sm': !isVenueDropdownVisible }"
      [label]="'SHARED.SYSTEM.GROUPS' | translate"
      [searchPlaceholder]="'ACCOUNT.DASHBOARD.SUBHEADER.DROPDOWN.LABELS.SEARCH_GROUPS' | translate"
      [allSelectedText]="'SHARED.SYSTEM.ALL_GROUPS' | translate"
      (onSelect)="handleGroupsSelect($event)"
      [disabled]="isLoading"
      [items]="groupsItems"></app-nested-dropdown-filter>
  </ng-container>
  <mat-icon *ngIf="isVenueDropdownVisible" class="Filter-dividerIcon" color="accent">arrow_right</mat-icon>
  <app-dropdown
    class="GroupsAndVenues-filterItem"
    *ngIf="isVenueDropdownVisible"
    [isSearchEnabled]="true"
    [disabled]="isLoading"
    [items]="venuesItems"
    [activeItems]="activeVenues"
    (onSelect)="handleVenueSelect($event)"
    multipleChoice="true"
    [label]="'SHARED.SYSTEM.VENUES_DROPDOWN' | translate: {param: accountChildrenNameSingular}"
    [allSelectedText]="'SHARED.SYSTEM.ALL_VENUES' | translate"></app-dropdown>
</div>
