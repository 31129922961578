import { Injectable } from '@angular/core';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiResponse } from '../../../shared/interfaces/api-response.interface';
import { AuthorizedHttp } from '../authorized-http/authorized-http.service';
import { GenericErrorHandlerService } from '../generic-error-handler/generic-error-handler.service';

@Injectable()
export class ApiService {
  constructor(private authorizedHttp: AuthorizedHttp, private genericErrorHandler: GenericErrorHandlerService) {}

  static getCreatedResourceId(headers: Headers): number {
    let createdId: number = null;

    if (headers.has('Location')) {
      createdId = parseInt(headers.get('Location').split('/').slice(-1)[0], 10);
    }

    return createdId;
  }

  static mapResponse(response: Response): ApiResponse {
    return {
      content: response,
    };
  }

  get$(url: string, options?: any): Observable<ApiResponse> {
    return this.authorizedHttp.get(url, options).pipe(
      map(ApiService.mapResponse),
      catchError((error: ApiResponse) => {
        this.genericErrorHandler.handleApiError(error);

        return observableThrowError(error);
      })
    );
  }

  post$(url: string, body: any, options?: any): Observable<ApiResponse> {
    return this.authorizedHttp.post(url, body, options).pipe(
      map(ApiService.mapResponse),
      catchError((error: ApiResponse) => {
        this.genericErrorHandler.handleApiError(error);

        return observableThrowError(error);
      })
    );
  }

  delete$(url: string, options?: any): Observable<ApiResponse> {
    return this.authorizedHttp.delete(url, options).pipe(
      map(ApiService.mapResponse),
      catchError((error: ApiResponse) => {
        this.genericErrorHandler.handleApiError(error);

        return observableThrowError(error);
      })
    );
  }

  put$(url: string, body: any, options?: any, onError?: (error: ApiResponse) => any): Observable<ApiResponse> {
    return this.authorizedHttp.put(url, body, options).pipe(
      map(ApiService.mapResponse),
      catchError((error: ApiResponse) => {
        if (onError) {
          onError(error);
        } else {
          this.genericErrorHandler.handleApiError(error);
        }

        return observableThrowError(error);
      })
    );
  }

  patch$(url: string, body: any, options?: any, onError?: (error: ApiResponse) => any): Observable<ApiResponse> {
    return this.authorizedHttp.patch$(url, body, options).pipe(
      map(ApiService.mapResponse),
      catchError((error: ApiResponse) => {
        if (onError) {
          onError(error);
        } else {
          this.genericErrorHandler.handleApiError(error);
        }

        return observableThrowError(error);
      })
    );
  }
}
