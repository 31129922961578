import { Injectable } from '@angular/core';
import { NavigationExtras, Params, Router } from '@angular/router';
import { ACCOUNT_ROUTES_PATHS_FULL } from '../../../components/account/account-routes-paths';
import { CONSTANTS } from '../../../shared/constants';
import { RouteBuilderService } from '../backend/route-builder.service';

@Injectable()
export class NavigationService {
  constructor(private router: Router, private routeBuilderService: RouteBuilderService) {}

  changeParams(extras: NavigationExtras) {
    this.router.navigate([], extras);
  }

  goToLogin(redirectUrl: string = null) {
    this.router.navigate([CONSTANTS.ROUTES.LOGIN], {
      queryParams: { redirectUrl },
    });
  }

  goToInner404() {
    this.router.navigate([CONSTANTS.ROUTES.HOME, CONSTANTS.ROUTES.NOT_FOUND]);
  }

  goToOuter404() {
    this.router.navigate([CONSTANTS.ROUTES.NOT_FOUND]);
  }

  goToAccountDashboard(accountUuid: string, params: Params = {}) {
    this.router.navigate([CONSTANTS.ROUTES.ACCOUNT, accountUuid, CONSTANTS.ROUTES.DASHBOARD], {
      queryParams: params,
    });
  }

  goToVenueMainScreen(uuid: string, params: {} = {}) {
    this.router.navigate([CONSTANTS.ROUTES.VENUE, uuid, CONSTANTS.ROUTES.DASHBOARD], {
      queryParams: params,
    });
  }

  goToVenueOverview(venueUuid: string, params: {} = {}) {
    this.goToUrlWithParams(`${CONSTANTS.ROUTES.VENUE}/${venueUuid}/${CONSTANTS.ROUTES.OVERVIEW}`, params);
  }

  goToAccountGroups(accountUuid: string) {
    this.goToUrl(`${CONSTANTS.ROUTES.ACCOUNT}/${accountUuid}/${CONSTANTS.ROUTES.SETTINGS}/${CONSTANTS.ROUTES.GROUPS}`);
  }

  goToAccountOverviewCategory(accountUuid: string, venueUuid: string, categoryUuid: string, params: {} = {}) {
    this.goToUrlWithParams(
      `${CONSTANTS.ROUTES.ACCOUNT}/${accountUuid}/${CONSTANTS.ROUTES.DASHBOARD}/${CONSTANTS.ROUTES.CATEGORY}/${categoryUuid}`,
      params
    );
  }

  goToVenueOverviewCategory(venueUuid: string, categoryUuid: string, params: {} = {}) {
    this.goToUrlWithParams(
      `${CONSTANTS.ROUTES.VENUE}/${venueUuid}/${CONSTANTS.ROUTES.OVERVIEW}/${CONSTANTS.ROUTES.CATEGORY}/${categoryUuid}`,
      params
    );
  }

  goToAllFoodbacks(uuid: string, params: {} = {}) {
    this.goToUrlWithParams(`${CONSTANTS.ROUTES.VENUE}/${uuid}/${CONSTANTS.ROUTES.ALL_FOODBACKS}`, params);
  }

  goToVenueDemographics(uuid: string) {
    this.router.navigate([CONSTANTS.ROUTES.VENUE, uuid, CONSTANTS.ROUTES.DEMOGRAPHICS]);
  }

  goToScoreboards(uuid: string) {
    this.router.navigate([
      this.routeBuilderService.getAbsoluteRoute(ACCOUNT_ROUTES_PATHS_FULL.SCOREBOARD, {
        [CONSTANTS.ROUTE_PARAMS.ACCOUNT_ID]: uuid,
      }),
    ]);
  }

  goToAdminFoodbacksStatistics() {
    this.router.navigate([CONSTANTS.ROUTES.ADMIN, CONSTANTS.ROUTES.SURVEY]);
  }

  goToAdminAccountManagement() {
    this.router.navigate([CONSTANTS.ROUTES.ADMIN, CONSTANTS.ROUTES.ACCOUNT_MANAGEMENT]);
  }

  goToEditProfile() {
    this.router.navigate([CONSTANTS.ROUTES.PROFILE]);
  }

  goToNoAccess() {
    this.router.navigate([CONSTANTS.ROUTES.NO_ACCESS]);
  }

  goToUrl(url: string): void {
    this.router.navigateByUrl(url);
  }

  goToTopLevelDashboard(): void {
    this.router.navigate([CONSTANTS.ROUTES.TOP_LEVEL_DASHBOARD]);
  }

  private goToUrlWithParams(url: string, params: {} = {}): void {
    this.router.navigateByUrl(this.router.createUrlTree([url], { queryParams: params }));
  }
}
