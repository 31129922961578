import { assignIn } from 'lodash';
import { AccountPackages } from '../../../shared/enums/account-packages.enum';
import { VenueTypes } from '../../../shared/enums/venue-types.enum';
import { AccountPackagePermissions } from '../../../shared/interfaces/account-packages-permissions.interface';
import { IVenueType } from '../../../shared/interfaces/venue-type.interface';
import { IAccountVenuePermissions } from '../../interfaces/account/account-venue-permissions.interface';
import { IFoodbackAccount } from '../../interfaces/account/account.interface';

/**
 * This class stores all permissions for account basing on active package (basic, pro, enterprise)
 * and venue type (bill folder, take away, canteen, breakfast)
 */
export abstract class FoodbackAccountPermissions {
  venueType: IVenueType = {
    uuid: '',
    name: VenueTypes.RESTAURANT,
  };

  protected constructor(data: IFoodbackAccount) {
    assignIn(this, data);
  }

  // eslint-disable-next-line
  /**
   * Returns matrix of settings which speak if the user of given account type can go to a particular part of the system.
   */
  hasAccessTo(): AccountPackagePermissions {
    const venueType: IAccountVenuePermissions = this.hasVenueType();

    return {
      addingFoodbackQuestions: true,
      dailyPerformanceOverview: true,
      goals: true,
      groups: true,
      hourlyPerformanceOverview: true,
      userPermissions: true,
      venueDashboard: true,
      scoreboard: {
        responseRate: venueType.restaurant,
        averageAge: venueType.restaurant,
        emails: venueType.restaurant,
      },
    };
  }

  hasVenueType(): IAccountVenuePermissions {
    return {
      breakfast: this.venueType.name === VenueTypes.BREAKFAST,
      canteen: this.venueType.name === VenueTypes.CANTEEN,
      restaurant: this.venueType.name === VenueTypes.RESTAURANT,
      takeaway: this.venueType.name === VenueTypes.TAKEAWAY,
    };
  }
}
