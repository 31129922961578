import { Injectable, OnDestroy } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { IntercomService } from '../../core/providers/backend/intercom.service';
import { ServerEnvironmentService } from '../../core/providers/server-environment/server-environment.service';
import { UserService } from '../../core/providers/user/user.service';
import { CONSTANTS } from '../../shared/constants';

@Injectable()
export class LogoutService implements OnDestroy {
  private onDestroy$ = new Subject<void>();

  constructor(
    private userService: UserService,
    private localStorage: LocalStorageService,
    private intercomService: IntercomService,
    private serverEnvironmentService: ServerEnvironmentService
  ) {}

  logOut(): void {
    this.serverEnvironmentService
      .getServerEnvironmentConfig$()
      .pipe(first(), takeUntil(this.onDestroy$))
      .subscribe(config => {
        const desiredLocation = `${window.location.origin}/login`;
        const logOutPath = `https://${config.auth0.domain}/v2/logout?returnTo=${encodeURIComponent(`${desiredLocation}`)}`;

        localStorage.removeItem(CONSTANTS.AUTH.ID_TOKEN);
        this.localStorage.clear();
        sessionStorage.clear();
        this.userService.setLoggedIn(false);
        this.intercomService.shutdownIntercom();
        window.location.href = logOutPath;
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
