import { Injectable } from '@angular/core';
import { partition } from 'lodash';
import { EMPTY, Observable, combineLatest as observableCombineLatest, of } from 'rxjs';
import { first, map, mergeMap, share, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { GoalsResponse, GoalsResponseMapped, GoalsUpdateEntry } from '../../../shared/interfaces/goals-backend.interface';
import { FoodbackAccount } from '../../classes/account/account.class';
import { AccountService } from '../account/account.service';
import { AuthorizedHttp } from '../authorized-http/authorized-http.service';

@Injectable()
export class GoalsBackendService {
  private accountsRequestPath = `${environment.apiBaseUrl.handbook}/surveys/accounts`;
  private venuesRequestPath = `${environment.apiBaseUrl.handbook}/surveys/venues`;
  private cachedVenueUuid: string;
  private cachedGoalsForVenue;
  private goalsForVenueRequest$;
  private cachedGoalsForAccount;
  private cachedAccountUuid: string;
  private goalsForAccountRequest$;

  constructor(private accountService: AccountService, private authorizedHttp: AuthorizedHttp) {}

  getGoalsForAccount$(accountUuid: string): Observable<GoalsResponseMapped> {
    if (this.cachedGoalsForAccount && this.cachedAccountUuid === accountUuid) {
      return of(this.cachedGoalsForAccount);
    }
    if (this.goalsForAccountRequest$ && this.cachedAccountUuid === accountUuid) {
      return this.goalsForAccountRequest$;
    }

    this.goalsForAccountRequest$ = this.accountService.getAccount$().pipe(
      first(),
      mergeMap((data: FoodbackAccount) => (data.hasAccessTo().goals ? this.completeGetGoalsForAccount$(accountUuid) : EMPTY)),
      tap(response => {
        this.resetAllCachedData();
        this.cachedGoalsForAccount = response;
        this.cachedAccountUuid = accountUuid;
      }),
      share()
    );

    return this.goalsForAccountRequest$;
  }

  getGoalsForVenue$(venueUuid: string): Observable<GoalsResponseMapped> {
    if (this.cachedGoalsForVenue && this.cachedVenueUuid === venueUuid) {
      return of(this.cachedGoalsForVenue);
    }
    if (this.goalsForVenueRequest$ && this.cachedAccountUuid === venueUuid) {
      return this.goalsForVenueRequest$;
    }

    this.goalsForVenueRequest$ = this.accountService.getAccount$().pipe(
      first(),
      mergeMap((data: FoodbackAccount) => (data.hasAccessTo().goals ? this.completeGetGoalsForVenue$(venueUuid) : EMPTY)),
      tap(response => {
        this.resetAllCachedData();
        this.cachedGoalsForVenue = response;
        this.cachedVenueUuid = venueUuid;
      }),
      share()
    );

    return this.goalsForVenueRequest$;
  }

  updateGoalsForVenue$(venueUuid: string, goals: GoalsUpdateEntry[]): Observable<void> {
    this.cachedGoalsForVenue = null;

    return this.authorizedHttp.put(`${this.venuesRequestPath}/${venueUuid}/goals`, {
      goals,
    });
  }

  updateGoalsForAccount$(accountUuid: string, goals: GoalsUpdateEntry[]): Observable<void> {
    this.cachedGoalsForAccount = null;

    return this.authorizedHttp.put(`${this.accountsRequestPath}/${accountUuid}/goals`, {
      goals,
    });
  }

  clearGoals() {
    this.cachedGoalsForAccount = null;
  }

  resetAllCachedData() {
    this.cachedAccountUuid = null;
    this.cachedGoalsForAccount = null;
    this.cachedVenueUuid = null;
    this.goalsForVenueRequest$ = null;
    this.goalsForAccountRequest$ = null;
  }

  private completeGetGoalsForVenue$(venueUuid: string): Observable<GoalsResponseMapped> {
    return observableCombineLatest([
      this.accountService.getAccount$().pipe(first()),
      this.authorizedHttp.get(`${this.venuesRequestPath}/${venueUuid}/goals`),
    ]).pipe(map(([account, goals]: [FoodbackAccount, GoalsResponse]) => this.mapGoalsResponse$(account, goals)));
  }

  private completeGetGoalsForAccount$(accountUuid: string): Observable<GoalsResponseMapped> {
    return observableCombineLatest([
      this.accountService.getAccount$().pipe(first()),
      this.authorizedHttp.get(`${this.accountsRequestPath}/${accountUuid}/goals`),
    ]).pipe(map(([account, goals]: [FoodbackAccount, GoalsResponse]) => this.mapGoalsResponse$(account, goals)));
  }

  private mapGoalsResponse$(account: FoodbackAccount, response: GoalsResponse): GoalsResponseMapped {
    return this.mapResponseToNicerFormat(response.filter(goal => goal.category.categoryType === account.venueType.uuid));
  }

  private mapResponseToNicerFormat(response: GoalsResponse) {
    const [[overallExperience], categories] = partition(response, 'category.overallExperience');

    return {
      overallExperience,
      categories,
    };
  }
}
