import { Injectable } from '@angular/core';
import { isEqual } from 'lodash';
import { VenueDemographicsConfiguration } from '../../../../shared/interfaces/bill-folders';
import { InitialAdvertisementState } from '../acc-folder/initial-advertisement-state.interface';
import { InitialBrandingState } from '../acc-folder/initial-branding-state.interface';

@Injectable()
export class BillFolderService {
  isBrandingStateChanged(initialState: InitialBrandingState, changedState: InitialBrandingState): boolean {
    return this.isStateChanged(initialState, changedState);
  }

  isAdvertisementStateChanged(initialState: InitialAdvertisementState, changedState: InitialAdvertisementState): boolean {
    return this.isStateChanged(initialState, changedState);
  }

  isDemographicStateChanged(initialState: VenueDemographicsConfiguration, changedState: VenueDemographicsConfiguration): boolean {
    return this.isStateChanged(initialState, changedState);
  }

  private isStateChanged(
    initialState: InitialBrandingState | InitialAdvertisementState | VenueDemographicsConfiguration,
    changedState: InitialBrandingState | InitialAdvertisementState | VenueDemographicsConfiguration
  ): boolean {
    return !isEqual(initialState, changedState);
  }
}
