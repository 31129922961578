import { CONSTANTS } from '../../../../shared/constants';

export const ACCOUNT_CONFIGURATION_ROUTES_PATHS_PARTIALS = {
  BASE_PATH: CONSTANTS.ROUTES.CONFIGURATION,
  BRANDING: CONSTANTS.ROUTES.BRANDING,
  ADVERTISEMENT: CONSTANTS.ROUTES.ADVERTISEMENT,
  DEMOGRAPHICS: CONSTANTS.ROUTES.DEMOGRAPHICS,
  REGIONAL_SETTINGS: CONSTANTS.ROUTES.REGIONAL_SETTINGS,
  QR_URLS: CONSTANTS.ROUTES.QR_URLS,
};
export const ACCOUNT_CONFIGURATION_ROUTES_PATHS_FULL = {
  BRANDING: `${ACCOUNT_CONFIGURATION_ROUTES_PATHS_PARTIALS.BASE_PATH}/${ACCOUNT_CONFIGURATION_ROUTES_PATHS_PARTIALS.BRANDING}`,
  ADVERTISEMENT: `${ACCOUNT_CONFIGURATION_ROUTES_PATHS_PARTIALS.BASE_PATH}/${ACCOUNT_CONFIGURATION_ROUTES_PATHS_PARTIALS.ADVERTISEMENT}`,
  DEMOGRAPHICS: `${ACCOUNT_CONFIGURATION_ROUTES_PATHS_PARTIALS.BASE_PATH}/${ACCOUNT_CONFIGURATION_ROUTES_PATHS_PARTIALS.DEMOGRAPHICS}`,
  REGIONAL_SETTINGS: `${ACCOUNT_CONFIGURATION_ROUTES_PATHS_PARTIALS.BASE_PATH}/${ACCOUNT_CONFIGURATION_ROUTES_PATHS_PARTIALS.REGIONAL_SETTINGS}`,
  QR_URLS: `${ACCOUNT_CONFIGURATION_ROUTES_PATHS_PARTIALS.BASE_PATH}/${ACCOUNT_CONFIGURATION_ROUTES_PATHS_PARTIALS.QR_URLS}`,
};
