<div class="DateRangePicker" #container>
  <div *ngIf="showAdditionalHeader" class="DateRangePicker-flexFixerRow DateRangePicker-header DateRangePicker-mobileHeader"
       [class.DateRangePicker-fullHeader]="ref.children.length">
    <ng-content class="m0" select="[datepicker-header]"></ng-content>
    <div #ref>
      <ng-content class="m0" select="[datepicker-additionalHeader]"></ng-content>
    </div>
  </div>
  <ng-content class="m0" select="[datepicker-additionalContent]"></ng-content>
  <div class="DateRangePicker-flexFixer mr-lg" *ngIf="selectedDateRangeType === DateRangeType.DYNAMIC; else staticRangeCaption">
    <div
      class="SelectedDateRange"
      [ngClass]="{
        'SelectedDateRange--small': selectedDateRange.groupBy === DateGroupPickerValue.YEAR,
        'SelectedDateRange--medium':
          selectedDateRange.groupBy === DateGroupPickerValue.MONTH || selectedDateRange.groupBy === DateGroupPickerValue.WEEK
      }"
      *ngIf="selectedDateRange && selectedDateRange.groupBy !== DateGroupPickerValue.CUSTOM; else customRangePicker">
      <em
        class="SelectedDateRange-arrow icon-arrow-left"
        [class.SelectedDateRange-arrow--disabled]="isLoading"
        (click)="previousRange()"></em>
      <span class="SelectedDateRange-caption"> {{ selectedDateRange | prettyDateRange: selectedDateRange.groupBy }} </span>
      <em class="SelectedDateRange-arrow icon-arrow-right" [class.SelectedDateRange-arrow--disabled]="isLoading" (click)="nextRange()"></em>
    </div>
  </div>
  <div class="DateRangePicker-flexFixer">
    <app-select
      class="DateRangePicker-timeFramePicker"
      [formControl]="timeFrameCtrl"
      [items]="timeFrames"
      [placeholder]="'SHARED.COMPONENTS.DATE_PICKER.TIME_FRAME' | translate"
      [label]="'SHARED.COMPONENTS.DATE_PICKER.TIME_FRAME' | translate"
      [panelClass]="
        selectedDateRangeType === DateRangeType.DYNAMIC ? 'DateRangePicker-listOfItemsDynamicMode' : 'DateRangePicker-listOfItems'
      "
      [isSearchModeEnable]="false"></app-select>
  </div>
  <ng-content select="[extraFeatures]"></ng-content>
  <ng-template #staticRangeCaption>
    <div *ngIf="showStaticRangeCaption" class="DateRangePicker-flexFixer">
      <span class="SelectedDateRange-caption SelectedDateRange-caption--static">
        <ng-container *ngTemplateOutlet="dateRangeName"></ng-container>
      </span>
    </div>
  </ng-template>
  <ng-template #customRangePicker>
    <div class="CustomDateRangePicker">
      <mat-form-field appearance="fill">
        <mat-label>{{ 'SHARED.SYSTEM.CHOOSE_A_DATE' | translate }}</mat-label>
        <mat-date-range-input [formGroup]="dateRangeFromGroup" [rangePicker]="picker" [min]="minDate" [max]="maxDate">
          <input matStartDate formControlName="start" [placeholder]="'HOME.FOODBACKS.DATE_FROM' | translate" />
          <input matEndDate formControlName="end" (dateChange)="dateRangeChange()" [placeholder]="'HOME.FOODBACKS.DATE_TO' | translate" />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>
    </div>
  </ng-template>
  <ng-template #dateRangeName>
    <ng-container *ngIf="selectedDateRange">
      {{
        'SHARED.SYSTEM.' + getRangeName(selectedDateRange)
          | uppercase
          | translate: getTranslateArgForStaticDateRangeValue(selectedDateRange)
      }}
    </ng-container>
  </ng-template>
</div>
