import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Auth0User } from 'auth0-lock';
import { isNil, some, values } from 'lodash';
import {
  Observable,
  ReplaySubject,
  Subject,
  forkJoin,
  throwError as observableThrowError,
  of,
} from 'rxjs';
import {
  catchError,
  finalize,
  first,
  map,
  mergeMap,
  share,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { ImageUploadResponse } from '../../../shared/components/profile-avatar/profile-avatar.interface';
import { BusinessHoursType } from '../../../shared/components/ven-add/business-hours-types.enum';
import { CONSTANTS } from '../../../shared/constants';
import { AccountUserSelectedVenues } from '../../../shared/form-models';
import {
  AccountForBackend,
  AccountForm,
  AccountUserForm,
  IBusinessDayForBackend,
  IVenueBusinessHourForBackend,
  IVenueFormBusinessHour,
  IVenueUserStatus,
  MyProfileForBackend,
  UserForBackend,
  UserForm,
  UserPhoneNumber,
  VenueForBackend,
  VenueForm,
  VenueFromBackend,
} from '../../../shared/form-models-interfaces';
import { ApiResponse } from '../../../shared/interfaces/api-response.interface';
import { IRecipientsResponse } from '../../../shared/interfaces/notifications-backend.interface';
import {
  AccountUser,
  VenueUser,
} from '../../../shared/interfaces/venue-user.interface';
import { IFoodbackAccount } from '../../interfaces/account/account.interface';
import { ServerEnvironmentConfig } from '../../interfaces/server-environment.interface';
import { FormsUtil } from '../../utils';
import { ApiService } from '../api/api.service';
import { NotificationService } from '../notification/notification.service';
import { ServerEnvironmentService } from '../server-environment/server-environment.service';
import { VenuesService } from '../venues/venues.service';
import { ValidationService } from './validation.service';

@Injectable()
export class UsersBackendService implements OnDestroy {
  private fbiPrefix = '/fbi/';
  private iamPrefix = '/iam/';
  private accountsPath = `${
    environment.apiBaseUrl.handbook + this.fbiPrefix
  }accounts`;
  private venuesPath = `${
    environment.apiBaseUrl.handbook + this.fbiPrefix
  }venues`;
  private adminsPath = `${
    environment.apiBaseUrl.handbook + this.fbiPrefix
  }admins`;
  private usersPath = `${
    environment.apiBaseUrl.handbook + this.iamPrefix
  }users`;
  private accountWithAnalyticsPath = `${environment.apiBaseUrl.handbook}/surveys/analytics/accounts`;
  private userIamPath = `${environment.apiBaseUrl.handbook}/iam/users/live-notification/recipients/venue`;
  private cachedSingleAccount: IFoodbackAccount = null;
  private cachedSingleVenue: VenueFromBackend = null;
  private cachedVenueList: VenueFromBackend[];
  private venueListRequest$: Observable<VenueFromBackend[]>;
  private cachedAccountId: string;
  private cachedAccountIdForVenueList: string;
  private venueWithFoodbackRequest$: Observable<VenueFromBackend[]>;
  private cachedVenuesWithFoodback: VenueFromBackend[];
  private serverEnvironmentConfig$: ReplaySubject<
    Readonly<ServerEnvironmentConfig>
  > = new ReplaySubject<Readonly<ServerEnvironmentConfig>>();
  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    private apiService: ApiService,
    private notificationService: NotificationService,
    private validationService: ValidationService,
    private venuesService: VenuesService,
    private formUtil: FormsUtil,
    private serverEnvironment: ServerEnvironmentService,
    private http: HttpClient,
    private translateService: TranslateService
  ) {
    this.serverEnvironment
      .getServerEnvironmentConfig$()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((config: ServerEnvironmentConfig) => {
        this.serverEnvironmentConfig$.next(config);
      });
  }

  getMyProfile$(UserUuid: string): Observable<MyProfileForBackend> {
    const basePath: string = environment.apiBaseUrl.handbook;

    return this.apiService
      .get$(`${basePath}/fbi/users/${UserUuid}`)
      .pipe(map(response => response.content));
  }

  getVenuesList$(accountUuid: string): Observable<VenueFromBackend[]> {
    // if (
    //   this.cachedVenueList &&
    //   this.cachedAccountIdForVenueList === accountUuid
    // ) {
    //   return of(this.cachedVenueList);
    // }
    if (
      this.venueListRequest$ &&
      this.cachedAccountIdForVenueList === accountUuid
    ) {
      return this.venueListRequest$;
    }

    this.venueListRequest$ = this.apiService
      .get$(`${this.accountsPath}/${accountUuid}/activeVenues`)
      .pipe(
        map(response => response.content),
        tap((response: VenueFromBackend[]) => {
          this.clearCache();
          this.cachedVenueList = response;
          this.cachedAccountIdForVenueList = accountUuid;
          this.venuesService.setVenues(accountUuid, response);
        }),
        share()
      );

    return this.venueListRequest$;
  }

  getVenuesWithFoodbackList$(
    accountUuid: string
  ): Observable<VenueFromBackend[]> {
    if (this.cachedVenuesWithFoodback && this.cachedAccountId === accountUuid) {
      return of(this.cachedVenuesWithFoodback);
    }
    if (
      this.venueWithFoodbackRequest$ &&
      this.cachedAccountId === accountUuid
    ) {
      return this.venueWithFoodbackRequest$;
    }

    this.venueWithFoodbackRequest$ = this.apiService
      .get$(
        `${this.accountWithAnalyticsPath}/${accountUuid}/venuesWithFoodbacks`
      )
      .pipe(
        map(response => response.content),
        tap((response: VenueFromBackend[]) => {
          this.clearCache();
          this.cachedVenuesWithFoodback = response;
          this.cachedAccountId = accountUuid;
          this.venuesService.setVenues(accountUuid, response);
        }),
        share()
      );

    return this.venueWithFoodbackRequest$;
  }

  getAllVenuesList$(accountUuid: string): Observable<VenueFromBackend[]> {
    return forkJoin([
      this.apiService
        .get$(`${this.accountsPath}/${accountUuid}/venues`)
        .pipe(map((response: ApiResponse<any>) => response.content)),
      this.getAllVenuesUsersStatusList$(accountUuid),
    ]).pipe(
      map(([venues, userStatus]: [VenueFromBackend[], IVenueUserStatus[]]) =>
        venues.map((venue: VenueFromBackend) =>
          userStatus[venue.uuid]
            ? {
                ...venue,
                lastActiveUserTime: userStatus[venue.uuid].lastActiveAt,
                lastActiveUserName: userStatus[venue.uuid].name,
              }
            : venue
        )
      ),
      tap((response: VenueFromBackend[]) =>
        this.venuesService.setVenues(accountUuid, response)
      )
    );
  }

  getAllVenuesUsersStatusList$(
    accountUuid: string
  ): Observable<IVenueUserStatus[]> {
    return this.apiService
      .get$(
        `${this.usersPath}/last-active-at/accounts/${accountUuid}`
      )
      .pipe(map((response: ApiResponse<any>) => response.content));
  }

  getAccountsList$(): Observable<IFoodbackAccount[]> {
    return this.apiService
      .get$(this.accountsPath)
      .pipe(map(response => response.content));
  }

  getListOfUsersForVenue$(VenueUuid: string): Observable<UserForBackend[]> {
    return this.apiService
      .get$(`${this.venuesPath}/${VenueUuid}/users`)
      .pipe(
        map(response =>
          response.content?.sort((a, b) =>
            a.name?.toLowerCase().localeCompare(b.name?.toLowerCase())
          )
        )
      );
  }

  getListOfPotentialRecipients$(
    VenueUuid: string
  ): Observable<IRecipientsResponse> {
    return this.apiService
      .get$(`${this.userIamPath}/${VenueUuid}`)
      .pipe(map(response => response.content));
  }

  getListOfUsersForAccount$(accountUuid: string): Observable<UserForBackend[]> {
    return this.apiService
      .get$(`${this.accountsPath}/${accountUuid}/users`)
      .pipe(
        map(response => response.content?.sort((a, b) =>{
          if (a.name) {
            if (b.name) {
              return a.name.toLowerCase().localeCompare(b.name.toLowerCase())
            } else {
              return a.name.toLowerCase().localeCompare(b.email.toLowerCase())
            }
          } else {
              if (b.name) {
                return a.email.toLowerCase().localeCompare(b.name.toLowerCase())
              } else {
              return a.email.toLowerCase().localeCompare(b.email.toLowerCase())
            }
          }
        }
        ))
      );
  }

  getListOfUsersForAdmin$(): Observable<UserForBackend[]> {
    return this.apiService
      .get$(`${this.adminsPath}`)
      .pipe(map(response => response.content?.sort((a, b) =>
        a.name?.toLowerCase().localeCompare(b.name?.toLowerCase())
      )));
  }

  getSingleVenue$(VenueUuid: string): Observable<VenueFromBackend> {
    if (this.cachedSingleVenue && this.cachedSingleVenue.uuid === VenueUuid) {
      return of(this.cachedSingleVenue);
    }

    return this.apiService.get$(`${this.venuesPath}/${VenueUuid}`).pipe(
      tap((response: ApiResponse<VenueFromBackend>) => {
        this.cachedSingleVenue = response.content;
      }),
      map(response => response.content),
      share()
    );
  }

  getSingleAccount$(AccountUuid: string): Observable<IFoodbackAccount> {
    if (
      this.cachedSingleAccount &&
      this.cachedSingleAccount.uuid === AccountUuid
    ) {
      return of(this.cachedSingleAccount);
    }

    return this.apiService.get$(`${this.accountsPath}/${AccountUuid}`).pipe(
      tap((response: ApiResponse<IFoodbackAccount>) => {
        this.cachedSingleAccount = response.content;
      }),
      map((response: ApiResponse<IFoodbackAccount>) => response.content),
      share()
    );
  }

  changeVenueStatus$(venueUuid: string, enabled: boolean): Observable<boolean> {
    const requestPath = `${this.venuesPath}/${venueUuid}/${CONSTANTS.ENDPOINTS.STATUS}`;

    return this.apiService
      .put$(requestPath, {
        isEnabled: enabled,
      })
      .pipe(map(response => true));
  }

  addUser$(context: string, userObj: UserForm): Observable<ApiResponse> {
    if (userObj.email) {
      return this.createUserWithAuth0$(userObj.email).pipe(
        mergeMap((res: string) => this.createNewUser(context, userObj, res)),
        catchError(() => this.createNewUser(context, userObj, null))
      );
    }

    return this.createNewUser(context, userObj, null);
  }

  createNewUser(
    context: string,
    userObj: UserForm,
    auth0Id: string
  ): Observable<ApiResponse> {
    const objToSend = this.prepareUserObject(context, userObj);
    let success = true;
    let error: any;

    return this.apiService
      .post$(
        this.getPathForUsers(context, userObj, CONSTANTS.USER_ACTIONS.CREATE),
        {
          ...objToSend,
          auth0Id,
        }
      )
      .pipe(
        finalize(() => {
          if (success) {
            this.notificationService.success(
              'ACCOUNT.FOODBACK.NOTIFICATIONS.ADD_USER_SUCCESS.TEXT'
            );
          } else {
            this.validationService.displayNotification(error);
          }
        }),
        catchError((response): Observable<ApiResponse> => {
          success = false;
          error = response;

          if (error.status === CONSTANTS.REQUEST_ERRORS.HTTP_400) {
            this.displayErrorUserExists();
          }

          return observableThrowError(null);
        })
      );
  }

  updateUser$(
    parentContext: string,
    userObj: UserForm
  ): Observable<ApiResponse> {
    const objToSend = this.prepareUserObject(parentContext, userObj);
    let success = true;
    let error: any;

    return this.apiService
      .put$(
        this.getPathForUsers(
          parentContext,
          userObj,
          CONSTANTS.USER_ACTIONS.EDIT
        ),
        objToSend
      )
      .pipe(
        finalize(() => {
          if (success) {
            this.notificationService.success(
              'ACCOUNT.FOODBACK.NOTIFICATIONS.EDIT_USER_SUCCESS.TEXT'
            );
          } else {
            this.validationService.displayNotification(error);
          }
        }),
        catchError((response): Observable<ApiResponse> => {
          success = false;
          error = response;

          return observableThrowError(
            'Error with "update User" request',
            response
          );
        })
      );
  }

  updateMyProfile$(
    UserUuid: string,
    changedUserObj: UserForm
  ): Observable<ApiResponse> {
    const basePath: string = environment.apiBaseUrl.handbook;
    const myProfileObj = this.prepareMyProfileObject(changedUserObj);

    return this.apiService
      .put$(`${basePath}/fbi/users/${UserUuid}`, myProfileObj)
      .pipe(
        catchError((error): Observable<ApiResponse> => {
          this.validationService.displayNotification(error);

          return observableThrowError(
            'Error with "update My Profile" request',
            error
          );
        })
      );
  }

  deleteUser(userObj: UserForm) {
    //  todo: not on this sprint
  }

  addVenue$(venueObj: VenueForm): Observable<ApiResponse> {
    this.clearCache();
    const objToSend = this.prepareVenueObject(venueObj);
    const shortPath = `${this.accountsPath}/${venueObj.uuid}/venues`;

    return this.apiService.post$(shortPath, objToSend).pipe(
      catchError((error): Observable<ApiResponse> => {
        if (error.status === CONSTANTS.REQUEST_ERRORS.HTTP_400) {
          this.displayInvalidZipCode();
        } else {
          this.validationService.displayNotification(error);
        }

        return observableThrowError({ errorCode: error.error.errorCode });
      })
    );
  }

  updateVenue$(venueObj: VenueForm): Observable<ApiResponse> {
    this.clearCache();
    const objToSend = this.prepareVenueObject(venueObj);
    const shortPath = `${this.venuesPath}/${venueObj.uuid}`;

    return this.apiService.put$(shortPath, objToSend).pipe(
      catchError((error): Observable<ApiResponse> => {
        if (error.status === CONSTANTS.REQUEST_ERRORS.HTTP_400) {
          this.displayInvalidZipCode();
        } else {
          this.validationService.displayNotification(error);
        }

        return observableThrowError({ errorCode: error.error.errorCode });
      })
    );
  }

  deleteVenue(venue: VenueForm, i: number) {
    //  todo: not on this sprint
  }

  addAccount$(userObj: AccountForm): Observable<AccountForBackend> {
    this.clearCache();
    const objToSend = this.prepareAccountObject(userObj);

    return this.apiService
      .post$(this.accountsPath, objToSend)
      .pipe(
        map((response: ApiResponse<AccountForBackend>) => response.content)
      );
  }

  updateAccount$(accountObj: AccountForm): Observable<AccountForBackend> {
    this.clearCache();
    const objToSend = this.prepareAccountObject(accountObj);
    const shortPath = `${this.accountsPath}/${accountObj.uuid}`;

    return this.apiService
      .put$(shortPath, objToSend)
      .pipe(
        map((response: ApiResponse<AccountForBackend>) => response.content)
      );
  }

  deleteAccount(accountObj: AccountForm) {
    //  todo: not on this sprint
  }

  changeAccountStatus$(
    accountUuid: string,
    enabled: boolean
  ): Observable<boolean> {
    this.clearCache();
    const requestPath = `${this.accountsPath}/${accountUuid}/${CONSTANTS.ENDPOINTS.STATUS}`;

    return this.apiService
      .put$(requestPath, {
        isEnabled: enabled,
      })
      .pipe(map(response => true));
  }

  getSingleVenueUser$(
    venueUuid: string,
    userUuid: string
  ): Observable<VenueUser> {
    return this.apiService
      .get$(`${this.venuesPath}/${venueUuid}/users/${userUuid}`)
      .pipe(map(response => response.content));
  }

  getSingleAccountUser$(
    accountUuid: string,
    userUuid: string
  ): Observable<AccountUser> {
    return this.apiService
      .get$(`${this.accountsPath}/${accountUuid}/users/${userUuid}`)
      .pipe(map(response => response.content));
  }

  uploadImage$(file: {
    image: string;
    original: HTMLImageElement;
  }): Observable<ImageUploadResponse> {
    this.clearCache();
    const formData: FormData = new FormData();

    formData.append(
      'file',
      this.dataURLtoBlob(file.image),
      String(Math.random()).split('.')[1]
    );

    return this.apiService
      .post$(environment.urlForUpload, formData)
      .pipe(map(response => response.content))
      .pipe(
        catchError((error): Observable<ApiResponse> => {
          this.validationService.displayNotification(error);

          return observableThrowError(
            'Error with "upload Image" request',
            error
          );
        })
      );
  }

  changeVenueUserStatus$(
    venueUuid: string,
    userUuid: string,
    enabled: boolean
  ): Observable<void> {
    this.clearCache();
    const requestPath = `${this.venuesPath}/${venueUuid}/${CONSTANTS.ENDPOINTS.USERS}/${userUuid}/${CONSTANTS.ENDPOINTS.STATUS}`;

    return this.apiService
      .put$(requestPath, {
        isEnabled: enabled,
      })
      .pipe(map(response => response.content));
  }

  changeAccountUserStatus$(
    accountUuid: string,
    userUuid: string,
    enabled: boolean
  ): Observable<void> {
    this.clearCache();
    const requestPath: string =
      `${this.accountsPath}/${accountUuid}/${CONSTANTS.ENDPOINTS.USERS}/` +
      `${userUuid}/${CONSTANTS.ENDPOINTS.STATUS}`;

    return this.apiService
      .put$(requestPath, {
        isEnabled: enabled,
      })
      .pipe(map(response => response.content));
  }

  getInvitationLink(
    userUuid: string,
    contextType: string,
    contextUuid: string
  ): Observable<string> {
    const prefix = `${
      contextType === CONSTANTS.CONTEXT.ACCOUNT
        ? CONSTANTS.ENDPOINTS.ACCOUNTS
        : CONSTANTS.ENDPOINTS.VENUE
    }/${contextUuid}`;
    const requestPath = `${environment.apiBaseUrl.handbook}/iam/users/${userUuid}/${prefix}/reset-password-url`;

    return this.apiService
      .get$(requestPath)
      .pipe(
        map((res: ApiResponse<any>) => (res.content ? res.content.url : null))
      ) as Observable<string>;
  }

  updateUserActivity(): Observable<any> {
    const requestPath = `${environment.apiBaseUrl.handbook}/iam/users/last-active-at`;

    return this.apiService
      .put$(requestPath, null)
      .pipe(
        map((res: ApiResponse<any>) => (res.content ? res.content.url : null))
      ) as Observable<string>;
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private getBusinessHoursPayload(
    venueObj: VenueForm
  ): IVenueBusinessHourForBackend {
    const openingHours: IBusinessDayForBackend[] = [];

    venueObj.businessHours.daysHours.forEach(
      (day: IVenueFormBusinessHour, index: number) => {
        const defaultHour = '00:00';

        openingHours.push({
          day: index + 1,
          hours: [
            [
              day.isClosed ? defaultHour : day.activeFrom.id,
              day.isClosed ? defaultHour : day.activeTo.id,
            ],
          ],
          isClosed: day.isClosed,
        });
      }
    );

    return {
      openingHours,
      feedbackHours: this.gertFeedbackHours(
        venueObj.businessHours.openingMode,
        venueObj.businessHours.closingAdditionHours
          ? venueObj.businessHours.closingAdditionHours.id
          : null
      ),
      isPortalClosedWhenVenueClosed: venueObj.businessHours.isPortalClosed,
    };
  }

  private dataURLtoBlob(dataurl: string): Blob {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;

    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new Blob([u8arr], { type: mime });
  }

  private getPathForUsers(
    endpoint: string,
    userObj: UserForm,
    action: string
  ): string {
    const basePath =
      environment.apiBaseUrl.handbook + this.fbiPrefix + endpoint;
    let properPath = '';

    const userUuid =
      action === CONSTANTS.USER_ACTIONS.EDIT ? `/${userObj.uuid}` : '';

    switch (endpoint) {
      case CONSTANTS.CONTEXT.ADMIN:
        properPath = `s${userUuid}`;
        break;
      case CONSTANTS.CONTEXT.ACCOUNT:
        properPath = `s/${userObj.accountUuid}/users${userUuid}`;
        break;
      case CONSTANTS.CONTEXT.VENUE:
        properPath = `s/${userObj.venueUuid}/users${userUuid}`;
        break;
      default:
        break;
    }

    return basePath + properPath;
  }

  private prepareAccountObject(userObj: AccountForm): AccountForBackend {
    return {
      companyName: userObj.details.name,
      brandName: userObj.details.brandName,
      organizationNumber:
        userObj.details.number === '' ? null : userObj.details.number,
      location: {
        city: userObj.details.city === '' ? null : userObj.details.city,
        zipCode: userObj.details.code === '' ? null : userObj.details.code,
        street: userObj.details.address === '' ? null : userObj.details.address,
        countryCode:
          userObj.details.country === '' ? null : userObj.details.country,
        timezone:
          userObj.details.timezone === '' ? null : userObj.details.timezone,
      },
      isSingleVenueMode: userObj.details.singleVenueMode,
      venueTypeUuid: userObj.details.venueTypeUuid,
      weeklyReportDay:
        userObj.details.weeklyReportDay,
      weeklyReportHour:
        userObj.details.weeklyReportHour,
      dailyReportDays:
        userObj.details.dailyReportDays,
      dailyReportHour:
        userObj.details.dailyReportHour,
      accountInternalStatus: userObj.details.accountInternalStatus,
      internalComment: userObj.details.internalComment,
      hubSpotDealId: userObj.details.hubSpotDealId,
      venueTypeMethods: userObj.venueTypeMethods,
      isTopLevelDashboardEnabled: userObj.details.isTopLevelDashboardEnabled,
      segmentUuid: userObj.details.segmentUuid,
      emplacementUuid: userObj.details.emplacementUuid,
      updateVenuesEmplacement: userObj.details.updateVenuesEmplacement,
      accountChildrenName: {
        id: userObj.details.accountChildrenName
      }
    };
  }

  private prepareVenueObject(
    venueObj: VenueForm | VenueForBackend
  ): VenueForBackend {
    if (this.isVenueForBackend(venueObj)) {
      return venueObj;
    }
    const convertedVenueObject: VenueForBackend = {
      companyName: venueObj.details.legalName,
      brandName: venueObj.details.brandName,
      organizationNumber:
        venueObj.details.number === '' ? null : venueObj.details.number,
      location: null,
      internalComment: venueObj.details.internalComment,
      openingHours: this.getBusinessHoursPayload(venueObj),
      emplacementUuid: venueObj.emplacementUuid
    };

    if (some(values(venueObj.location), item => item !== '' && !isNil(item))) {
      convertedVenueObject.location = {
        city: venueObj.location.city === '' ? null : venueObj.location.city,
        zipCode: venueObj.location.code === '' ? null : venueObj.location.code,
        street:
          venueObj.location.address === '' ? null : venueObj.location.address,
        countryCode:
          venueObj.location.country === '' ? null : venueObj.location.country,
        timezone:
          venueObj.location.timezone === '' ? null : venueObj.location.timezone,
      };
    }

    return convertedVenueObject;
  }

  private isVenueForBackend(
    venueObj: VenueForm | VenueForBackend
  ): venueObj is VenueForBackend {
    return venueObj.hasOwnProperty('advertisementFileUuid');
  }

  private prepareUserObject(
    context: string,
    userObj: UserForm | AccountUserForm
  ): UserForBackend {
    const properUserObj: UserForBackend = {
      email: userObj.email,
      name: userObj.name,
      profilePictureUuid: userObj.profilePictureUuid,
      phone: this.getPhoneNumberForBackend(userObj),
      accountUser: userObj.accountUser,
      venueUser: userObj.venueUser,
    };

    if (this.isAccountUserForm(userObj)) {
      properUserObj.accountUser.venues = this.getSelectedVenuesForUser(userObj);
    }

    return properUserObj;
  }

  private getSelectedVenuesForUser(userObj: AccountUserForm): string[] | null {
    if (
      userObj.accountUser.isAdmin ||
      userObj.selectedVenuesType === AccountUserSelectedVenues.ALL
    ) {
      return null;
    }

    return userObj.venues;
  }

  private prepareMyProfileObject(userObj: UserForm): MyProfileForBackend {
    return {
      name: userObj.name,
      profilePictureUuid: userObj.avatar,
      phone: this.getPhoneNumberForBackend(userObj),
      email: userObj.email,
      language: userObj.language,
      venueDailyReportSubscription: userObj.venueDailyReportSubscription,
      venueWeeklyReportSubscription:
        userObj.venueWeeklyReportSubscription,
      accountWeeklyReportSubscription:
        userObj.accountWeeklyReportSubscription,
      accountMonthlyReportSubscription:
        userObj.accountMonthlyReportSubscription,
    };
  }

  private getPhoneNumberForBackend(userObj: UserForm): UserPhoneNumber {
    const number = this.formUtil.getCorrectPhoneNumber(userObj);

    if (number) {
      return {
        number,
        prefix: userObj.prefix,
      };
    }

    return null;
  }

  private isAccountUserForm(
    userForm: UserForm | AccountUserForm
  ): userForm is AccountUserForm {
    return !!(userForm as AccountUserForm).venues;
  }

  private clearCache() {
    this.cachedVenuesWithFoodback = null;
    this.cachedAccountId = null;
    this.cachedSingleVenue = null;
    this.cachedSingleAccount = null;
    this.venueWithFoodbackRequest$ = null;
    this.venueListRequest$ = null;
    this.cachedAccountIdForVenueList = null;
  }

  private createUserWithAuth0$(email: string): Observable<string> {
    return this.serverEnvironmentConfig$.pipe(
      first(),
      mergeMap((data: Readonly<ServerEnvironmentConfig>) =>
        this.http
          .post(`https://${data.auth0.domain}/dbconnections/signup`, {
            email,
            client_id: data.auth0.clientId,
            password: this.generateRandomPassword(),
            connection: data.auth0.realm,
          })
          .pipe(
            catchError(error => {
              if (error.status !== CONSTANTS.REQUEST_ERRORS.HTTP_400) {
                this.displayErrorAuth0NewUserPopup();
              }

              return observableThrowError(error);
            }),
            map((res: Auth0User) => res._id)
          )
      )
    );
  }

  private displayErrorAuth0NewUserPopup(): void {
    this.translateService
      .get('SHARED.NOTIFICATIONS.ERROR_PROCESSING')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(({ TITLE, TEXT }) => {
        this.notificationService.displaySwalPopup(TITLE, TEXT, 'error');
      });
  }

  private displayErrorUserExists(): void {
    this.notificationService.error(
      this.translateService.instant('SHARED.VALIDATION_MSG.USER_EXISTS')
    );
  }

  private generateRandomPassword(): string {
    const smallLettersLength = 5;
    const charsetLetters = 'abcdefghijklmnopqrstuvwxyz';
    const charsetBigLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const charsetSpecial = '!@#$%^&*';
    const charsetNumbers = '0123456789';
    let retVal = '';

    retVal += this.getRandomCharacter(charsetBigLetters);

    for (let i = 0; i < smallLettersLength; ++i) {
      retVal += this.getRandomCharacter(charsetLetters);
    }

    retVal += this.getRandomCharacter(charsetSpecial);
    retVal += this.getRandomCharacter(charsetNumbers);

    return retVal;
  }

  private getRandomCharacter(characterSet: string): string {
    return characterSet.charAt(Math.floor(Math.random() * characterSet.length));
  }

  private displayInvalidZipCode(): void {
    this.notificationService.error(
      this.translateService.instant('SHARED.FORM_ERRORS.INVALID_ZIP')
    );
  }

  private gertFeedbackHours(
    openingMode: BusinessHoursType,
    closingAdditionHours: string
  ): number {
    switch (openingMode) {
      case BusinessHoursType.ALWAYS_OPEN:
        return null;
      case BusinessHoursType.SAME_OPENING_HOURS:
        return 0;
      default:
        return +closingAdditionHours;
    }
  }
}
