<div class="UploadLogo">
  <div
    class="box-placeholder my-drop-zone"
    [ngClass]="{ 'nv-file-over': hasBaseDropZoneOver }"
    (fileOver)="fileOverBase($event)"
    ng2FileDrop
    [uploader]="uploader">
    <img [src]="avatarURL" (error)="onImgError.emit()" class="my-drop-zone-image" (load)="onBrandingImgLoading($event)" />
    <div *ngIf="loading" class="UploadLogo-loader">
      <app-preloader></app-preloader>
    </div>
  </div>

  <div class="UploadLogo-buttonsContainer">
    <label for="file" class="file-upload btn btn-outline btn-foodback text-uppercase">
      {{ 'SHARED.SYSTEM.REPLACE_LOGO' | translate }}
      <input #fileInput id="file" type="file" ng2FileSelect [uploader]="uploader" />
    </label>
    <button class="btn btn-transparent text-uppercase" (click)="backToDefault()">{{ 'SHARED.SYSTEM.BACK_TO_DEFAULT' | translate }}</button>
  </div>
</div>
