import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControlOptions, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { CONSTANTS } from '../../../constants';
import { I18nAvailableLanguage } from '../../../interfaces/i18n.interface';
import { StatementTranslationFromBackend } from '../../../interfaces/statements.interface';
import { LanguageFormGroup } from '../../one-more-question/one-more-question-edit/one-more-question-languages-dialog/one-more-question-languages-dialog.component';
import { CampaignTranslationService } from '../campaign-translation.service';
import { ICampaignQuestionTranslateInput, ICampaignTranslationQuestionOutput } from '../campaigns.interface';
import { AccountErrors } from "../../../form-models";

export const campaignQuestionLanguagesDialogConfig: MatDialogConfig = {
  maxWidth: '90vw',
  maxHeight: '90vh',
  minHeight: '400px',
  disableClose: true,
  panelClass: 'full-screen-modal',
};
@Component({
  selector: 'app-campaign-question-translate-dialog',
  templateUrl: './campaign-question-translate-dialog.component.html',
  styleUrls: ['./campaign-question-translate-dialog.component.scss'],
})
export class CampaignQuestionTranslateDialogComponent implements OnInit {
  langForm: FormGroup;
  inactiveLangForm: FormArray;
  showInactiveLanguages = false;
  errors = AccountErrors;
  readonly CONSTANTS: any = CONSTANTS;

  constructor(
    private mdDialogRef: MatDialogRef<CampaignQuestionTranslateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dataDialog: ICampaignQuestionTranslateInput,
    private formBuilder: FormBuilder,
    private campaignTranslationService: CampaignTranslationService
  ) {}

  ngOnInit(): void {
    this.langForm = this.formBuilder.group({
      activeLangForm: this.campaignTranslationService.getLanguagesForm({
        languageSet: this.dataDialog.allActiveLanguages,
        defaultLanguage: this.dataDialog.defaultLanguage,
        isQuestionDialog: true,
        shouldAddDefaultLanguage: true,
        translations: {
          question: this.dataDialog.question,
          alternatives: this.dataDialog.alternatives,
        },
      }),
    });
    this.inactiveLangForm = this.campaignTranslationService.getLanguagesForm({
      languageSet: this.getInactiveLanguages(),
      defaultLanguage: this.dataDialog.defaultLanguage,
      isQuestionDialog: true,
      shouldAddDefaultLanguage: false,
      translations: {
        question: this.dataDialog.question,
        alternatives: this.dataDialog.alternatives,
      },
    });
  }

  showInactiveLanguagesChange(): void {
    if (this.showInactiveLanguages) {
      this.langForm.addControl('inactiveLangForm', this.inactiveLangForm, {
        emitEvent: false,
      });
    } else {
      this.langForm.removeControl('inactiveLangForm', { emitEvent: false });
    }
  }

  onSubmit(): void {
    this.langForm.markAllAsTouched();
    this.langForm.markAsDirty();

    if (this.langForm.valid) {
      this.mdDialogRef.close(this.prepareDialogData());
    }
  }

  trackItems(item: FormGroup, index: number): number {
    return index;
  }

  private prepareDialogData(): ICampaignTranslationQuestionOutput {
    let question: StatementTranslationFromBackend[] = [];
    let alternativeText: StatementTranslationFromBackend[][] = this.dataDialog.alternatives.map(() => []);

    // Get data from active languages
    const activeFormOutput: ICampaignTranslationQuestionOutput = this.prepareTranslationDataSet(
      this.langForm.controls.activeLangForm as FormArray
    );

    question = [...activeFormOutput.question];
    alternativeText = alternativeText.map((alternative: StatementTranslationFromBackend[], index: number) => [
      ...activeFormOutput.alternatives[index],
    ]);

    if (this.showInactiveLanguages) {
      // Get data from inactive languages
      const inactiveFormOutput: ICampaignTranslationQuestionOutput = this.prepareTranslationDataSet(
        this.langForm.controls.inactiveLangForm as FormArray
      );

      question = [...question, ...inactiveFormOutput.question];
      alternativeText = alternativeText.map((alternative: StatementTranslationFromBackend[], index: number) => [
        ...alternative,
        ...inactiveFormOutput.alternatives[index],
      ]);
    }

    return { question, alternatives: alternativeText };
  }

  private prepareTranslationDataSet(langFormArray: FormArray): ICampaignTranslationQuestionOutput {
    const question: StatementTranslationFromBackend[] = [];
    const alternativeText: StatementTranslationFromBackend[][] = this.dataDialog.alternatives.map(() => []);

    // Get data from active languages
    langFormArray.controls.forEach((form: LanguageFormGroup) => {
      question.push({
        lang: form.language.code,
        value: form.value.question,
      });
      form.value.alternatives.forEach((value: string, index: number) =>
        alternativeText[index].push({
          value,
          lang: form.language.code,
        })
      );
    });

    return { question, alternatives: alternativeText };
  }

  private getInactiveLanguages(): I18nAvailableLanguage[] {
    return this.dataDialog.allLanguages.filter(
      (lang: I18nAvailableLanguage) =>
        !this.dataDialog.allActiveLanguages.find((activeLang: I18nAvailableLanguage) => activeLang.code === lang.code)
    );
  }
}
