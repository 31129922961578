import { Pipe, PipeTransform } from '@angular/core';
import { CONSTANTS } from '../constants';

@Pipe({
  name: 'ellipsis',
})
export class EllipsisPipe implements PipeTransform {
  transform(value: string, args: any[]): string {
    const limit = args.length > 0 ? parseInt(args[0], 10) : CONSTANTS.PAGE_DEFAULTS.MAX_NAME_LENGTH;
    const trail = args.length > 1 ? args[1] : '...';

    return value && value.length > limit ? value.substring(0, limit) + trail : value;
  }
}
