import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { CONSTANTS } from '../../shared/constants';

@Injectable()
export class LayoutUtil {
  private mobileDevice$: BehaviorSubject<boolean>;
  private isCollapsed$: BehaviorSubject<boolean>;
  private isAdminPanel$: BehaviorSubject<boolean> = new BehaviorSubject(CONSTANTS.SIDEBAR.NOADMIN);
  private isTopLevelDashboard$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private isMobileSidebar$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private isSidebarExpanded$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor() {
    const isMobileDevice: boolean = window.innerWidth < CONSTANTS.RESPONSIVENESS.SIDEBAR_BREAKPOINT;

    this.mobileDevice$ = new BehaviorSubject(isMobileDevice);
    this.isCollapsed$ = new BehaviorSubject(isMobileDevice ? CONSTANTS.SIDEBAR.COLLAPSED : CONSTANTS.SIDEBAR.EXPANDED);
  }

  /*
   * Sets the new value of isCollapsed$ sidebar
   */
  isMobileDevice(): boolean {
    return this.mobileDevice$.getValue();
  }

  getIsMobileDevice$(): Observable<boolean> {
    return this.mobileDevice$.pipe(distinctUntilChanged());
  }

  setMobileDeviceState() {
    this.mobileDevice$.next(window.innerWidth < CONSTANTS.RESPONSIVENESS.SIDEBAR_BREAKPOINT);
  }

  /* todo: update
   * Sets the new value of isCollapsed$ sidebar
   */
  setIsMobileSidebar(newValue: boolean) {
    this.isMobileSidebar$.next(newValue);
  }

  /**
   * Toogle state of sidebar collapse
   */
  toggleIsMobileSidebar() {
    this.isMobileSidebar$.next(!this.isMobileSidebar$.value);
  }

  /** todo: update
   * Get observable object with a state of admin sidebar
   * @returns observable object with boolean means is admin panel active
   */
  getIsMobileSidebar$(): BehaviorSubject<boolean> {
    return this.isMobileSidebar$;
  }

  // eslint-disable-next-line
  /**
   * Sets the new value of isCollapsed$ sidebar
   */
  setIsCollapsed(newValue: boolean) {
    this.isCollapsed$.next(newValue);
  }

  /**
   * Toogle state of sidebar collapse
   */
  toggleIsCollapsed() {
    this.isCollapsed$.next(!this.isCollapsed$.value);
  }

  /**
   * Get observable object with a state of admin sidebar
   * @returns observable object with boolean means is admin panel active
   */
  getIsCollapsed$(): Observable<boolean> {
    return this.isCollapsed$.asObservable();
  }

  /**
   * Toogle state of sidebar collapse
   */
  toggleIsAdminPanel() {
    this.isAdminPanel$.next(!this.isAdminPanel$.value);
  }

  /**
   * Get observable object with a state of admin sidebar
   * @returns observable object with boolean means is admin panel active
   */
  getIsAdminPanel$(): BehaviorSubject<boolean> {
    return this.isAdminPanel$;
  }

  getIsTopLevelDashboard$(): BehaviorSubject<boolean> {
    return this.isTopLevelDashboard$;
  }

  showAdminPanel() {
    this.isAdminPanel$.next(true);
  }

  hideAdminPanel() {
    this.isAdminPanel$.next(false);
  }

  getIsSidebarExpanded$(): Observable<boolean> {
    return this.isSidebarExpanded$;
  }

  setIsSidebarExpanded(value: boolean) {
    this.isSidebarExpanded$.next(value);
  }

  showTopLevelDashboardMode(): void {
    this.isTopLevelDashboard$.next(true);
  }

  hideTopLevelDashboardMode(): void {
    this.isTopLevelDashboard$.next(false);
  }
}
