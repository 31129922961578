<app-dialog>
  <app-dialog-title> {{ 'ACCOUNT.FOLDER.QR_URLS.DIALOGS.HEADERS.ACTIVATE_GROUP' | translate }} </app-dialog-title>
  <app-dialog-content>
    <div>
      <label>{{ 'ACCOUNT.FOLDER.QR_URLS.DIALOGS.PLACEHOLDERS.GROUP_CATEGORY' | translate }}</label>
      <div>
        <app-dropdown
          class="ActivateGroups__dropdown"
          (onSelect)="onChangeCategory($event)"
          [placeholder]="'ACCOUNT.GROUPS.DIALOG.SELECT_CATEGORY' | translate"
          [activeItems]="activeItems"
          [items]="categoriesForDropdown"></app-dropdown>
      </div>
    </div>
    <div class="mt-lg">
      <label>{{ 'ACCOUNT.FOLDER.QR_URLS.DIALOGS.PLACEHOLDERS.SELECT_GROUP_QR_URLS' | translate }}</label>
      <div class="form-group ml20" *ngIf="filteredGroups?.length > 0">
        <label class="c-checkbox checkbox mb0 mr mt0 clickable">
          <input [(ngModel)]="selectAllCheckbox" type="checkbox" (ngModelChange)="onSelectAllChange(selectAllCheckbox)" />
          <span class="fa fa-check fb-checkbox"></span>
          {{ 'ACCOUNT.FOLDER.QR_URLS.DIALOGS.PLACEHOLDERS.SELECT_ALL' | translate }}
        </label>
        <div class="mt-lg ActivateGroups__groups-container">
          <label class="ActivateGroups__group-item-label c-checkbox checkbox mb-sm mr mt0 clickable" *ngFor="let group of filteredGroups">
            <input type="checkbox" [(ngModel)]="group.isSelected" (ngModelChange)="deselectAll(group.isSelected)" />
            <span class="fa fa-check fb-checkbox"></span>
            {{ group.name }}
          </label>
        </div>
      </div>
    </div>
  </app-dialog-content>
  <app-dialog-actions>
    <button type="button" class="btn btn-foodback btn-foodback--secondary text-uppercase mr-sm" (click)="hide()">
      {{ 'ACCOUNT.FOLDER.QR_URLS.BUTTONS.CANCEL' | translate }}
    </button>
    <button type="button" class="btn btn-foodback text-uppercase" data-dismiss="modal" (click)="save()">
      {{ 'SHARED.SYSTEM.SAVE' | translate }}
    </button>
  </app-dialog-actions>
</app-dialog>
