<app-data-filter
  [headerTranslationKey]="(isAccountContext ? 'SHARED.SYSTEM.DASHBOARD' : 'SHARED.SYSTEM.OVERVIEW') | translate"
  [showPrintPage]="true"
  [showPrintPageLoading]="loading.categories"
  [isAccountMode]="isAccountContext"
  (onFiltersChanged)="onFiltersChanged($event)">
  <div [class.mt-lg]="isAccountContext" class="VenueOverview">
    <div class="row row-equal">
      <div class="VenueOverview-print col-lg-3 col-sm-5 col-xs-12">
        <div class="panel preloader-wrapper">
          <app-preloader *ngIf="loading.categories" [isSingleMode]="false" [type]="PreloaderType.OVERLAY"></app-preloader>
          <app-data-card-widget
            [title]="'SHARED.SYSTEM.OVERALL_EXPERIENCE'"
            [isAccessToGoals]="account?.hasAccessTo().goals"
            [accountContext]="isAccountContext"
            [goal]="overallExperienceData.goal"
            [statistics]="statistics"></app-data-card-widget>
        </div>
      </div>

      <div class="foodbacksOverviewChart col-lg-9 col-sm-7 col-xs-12">
        <div class="panel">
          <app-preloader
            *ngIf="loading.accountScores || loading.venueScores"
            [isSingleMode]="false"
            [type]="PreloaderType.OVERLAY"></app-preloader>
          <div class="panel-heading">
            <span class="text-uppercase h4">{{ 'HOME.OVERVIEW.SHARED.FOODBACK_CHART.HEADER' | translate }}</span>
          </div>
          <div class="panel-body preloader-wrapper" style="margin-top: 40px;">
            <app-foodbacks-score-chart
              class="VenueOverview-chart"
              [data]="foodbackScores"
              [dateRange]="dateRangeForChart"
              [goal]="overallExperienceData.goal"
              [accountScores]="account?.isSingleVenueMode ? null : accountScores"></app-foodbacks-score-chart>
          </div>
        </div>
      </div>
    </div>
    <div class="pagebreak"> </div>
    <div class="row row-equal">
      <div class="FoodbackOverview__category col-xs-6 col-md-3" *ngFor="let categoryData of categories; trackBy: trackCategories">
        <div class="panel preloader-wrapper">
          <app-preloader *ngIf="loading.categories" [isSingleMode]="false" [type]="PreloaderType.OVERLAY"></app-preloader>
          <app-category-overview-card
            [categoryDetails]="categoryData.details"
            [goal]="categoryData.goal"
            [topLowPerformingQuestions]="categoryData.topLowQuestions"
            [linkQueryParams]="drillDownQueryParams"
            [contextId]="contextUuid"
            [viewType]="viewType"></app-category-overview-card>
        </div>
      </div>
    </div>
    <!-- <div class="pagebreak"> </div> -->
    <div class="PerformanceChartsDashboard row row-equal">
      <div *ngIf="account?.hasAccessTo().dailyPerformanceOverview" class="PerformanceChartContainer col-xs-12 col-lg-6">
        <div class="panel">
          <app-preloader *ngIf="loading.dayByDay" [isSingleMode]="false" [type]="PreloaderType.OVERLAY"></app-preloader>
          <div class="panel-heading">
            <span class="text-uppercase h4">{{ 'HOME.OVERVIEW.DAILY_PERFORMANCE.HEADER' | translate }}</span>
          </div>
          <div class="panel-body preloader-wrapper print-hide">
            <app-day-by-day-chart
              *ngIf="isSelectedDateRangeAtLeastOneWeek; else rangeTooShort"
              [data]="dailyPerformance"
              [printMode]="false"
              [goal]="overallExperienceData.goal"></app-day-by-day-chart>
            <ng-template #rangeTooShort>
              <div class="chart-panel--noData">{{ 'SHARED.STATISTICS.GRAPH_RANGE_TOO_SHORT' | translate }}</div>
            </ng-template>
          </div>
          <div class="panel-body preloader-wrapper print-visible" style="max-width: 550px; margin: 0 auto">
            <app-day-by-day-chart
              *ngIf="isSelectedDateRangeAtLeastOneWeek; else rangeTooShort"
              [data]="dailyPerformance"
              [printMode]="true"
              [goal]="overallExperienceData.goal"></app-day-by-day-chart>
            <ng-template #rangeTooShort>
              <div class="chart-panel--noData">{{ 'SHARED.STATISTICS.GRAPH_RANGE_TOO_SHORT' | translate }}</div>
            </ng-template>
          </div>
        </div>
      </div>
      <div *ngIf="account?.hasAccessTo().hourlyPerformanceOverview" class="PerformanceChartContainer col-xs-12 col-lg-6">
        <div class="panel" >
          <app-preloader *ngIf="loading.hourByHour" [isSingleMode]="false" [type]="PreloaderType.OVERLAY"></app-preloader>
          <div class="panel-heading">
            <span class="text-uppercase h4">{{ 'HOME.OVERVIEW.HOURLY_PERFORMANCE.HEADER' | translate }}</span>
          </div>
          <div class="panel-body preloader-wrapper print-hide" >
            <app-hour-by-hour-chart
              *ngIf="isSelectedDateRangeAtLeastOneWeek; else rangeTooShort"
              [data]="hourlyPerformance"
              [printMode]="false"
              [goal]="overallExperienceData.goal"></app-hour-by-hour-chart>
            <ng-template #rangeTooShort>
              <div class="chart-panel--noData">{{ 'SHARED.STATISTICS.GRAPH_RANGE_TOO_SHORT' | translate }}</div>
            </ng-template>
          </div>
          <div class="panel-body preloader-wrapper print-visible" style="max-width: 550px; margin: 0 auto" >
            <app-hour-by-hour-chart
              *ngIf="isSelectedDateRangeAtLeastOneWeek; else rangeTooShort"
              [data]="hourlyPerformance"
              [printMode]="true"
              [goal]="overallExperienceData.goal"></app-hour-by-hour-chart>
            <ng-template #rangeTooShort>
              <div class="chart-panel--noData">{{ 'SHARED.STATISTICS.GRAPH_RANGE_TOO_SHORT' | translate }}</div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-data-filter>
