import { Pipe, PipeTransform } from '@angular/core';
import { find } from 'lodash';
import { StatementTranslationFromBackend } from '../interfaces/statements.interface';

@Pipe({
  name: 'getNativeLanguageTranslationOrFirst',
})
export class GetNativeLanguageTranslationOrFirstPipe implements PipeTransform {
  transform(translations: StatementTranslationFromBackend[], nativeLanguage?: string): string {
    if (!translations) {
      return '';
    }
    const englishTranslation = find(translations, {
      lang: nativeLanguage ? nativeLanguage : 'en-GB',
    });

    return englishTranslation ? englishTranslation.value : translations[0].value;
  }
}
