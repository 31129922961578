import { Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { NewStatementForm, Statement, StatementGroup } from '../../../interfaces/statements.interface';
import { Observable, Subject, combineLatest as observableCombineLatest } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { chain, isEqual, last, values } from 'lodash';
import { takeUntil } from 'rxjs/operators';
import { I18nBackendService } from '../../../../core/providers/backend/i18n-backend.service';
import { KeyboardStatusService } from '../../../../core/providers/keyboard-status/keyboard-status.service';
import { KeyboardUtils } from '../../../../core/utils/events/keyboard.utils';
import { CONSTANTS } from '../../../constants';
import { FormModels } from '../../../form-models';
import { Dictionary } from '../../../interfaces/common.interface';
import { IDropdownItem } from '../../../interfaces/dropdown/dropdown-item.interface';
import { I18nAvailableLanguage, I18nSupportedLanguage } from '../../../interfaces/i18n.interface';
import { FoodbackQuestionsService } from '../foodback-questions.service';
import { AddQuestionModalInput, AddQuestionModalOutput } from './add-question-modal.interface';

export const addQuestionDialogConfig: MatDialogConfig = {
  width: '600px',
  height: '700px',
  disableClose: true,
};
@Component({
  selector: 'app-add-question-modal',
  templateUrl: './add-question-modal.component.html',
  styleUrls: ['./add-question-modal.component.scss'],
})
export class AddQuestionModalComponent implements OnDestroy, OnInit {
  onClose: Subject<AddQuestionModalOutput>;
  inputData: Subject<AddQuestionModalInput>;
  availableLanguages: I18nAvailableLanguage[];
  activeLanguages: I18nAvailableLanguage[];
  inActiveLanguages: I18nAvailableLanguage[];
  questionCategories: IDropdownItem[];
  defaultLanguage: I18nAvailableLanguage;
  accStatementsForm: FormGroup;
  isEdit = false;
  isDisabledButton = false;
  errors = FormModels.VenueAccount.VenueErrors.general;
  customQuestionPlaceholders: string[] = [
    'Custom question 1',
    'Custom question 2',
    'Custom question 3',
    'Custom question 4'
  ]
  private initialFormData;
  private questionUuid: string;
  private escModalStatus = false;
  private onDestroy$ = new Subject<void>();

  constructor(
    private mdDialogRef: MatDialogRef<AddQuestionModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AddQuestionModalInput,
    private formBuilder: FormBuilder,
    private foodbackQuestionsService: FoodbackQuestionsService,
    private keyboardStatusService: KeyboardStatusService,
    private i18nBackendService: I18nBackendService,
    private translateService: TranslateService
  ) {
    this.onClose = new Subject<AddQuestionModalOutput>();
    this.inputData = new Subject<AddQuestionModalInput>();
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (KeyboardUtils.pressedEscape(event) && this.escModalStatus) {
      this.mdDialogRef.close();
    }
  }

  cancel() {
    this.hideModal();
  }

  onSubmit({ value, valid }: { value: NewStatementForm; valid: boolean }) {
    this.accStatementsForm.controls.translations.markAllAsTouched();
    if (valid) {
      this.onClose.next(this.getFormValue(value));
      this.onClose.complete();
      this.hideModal();
    }
  }

  hideModal() {
    this.mdDialogRef.close();
  }

  ngOnInit() {
    this.getEscModalStatus()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(status => (this.escModalStatus = status));
    observableCombineLatest([this.getInputData(), this.i18nBackendService.getAllSupportedLanguages$()])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(([inputData, languages]) => {
        this.setAvailableLanguages(inputData.availableLanguages, languages);
        this.handleInputDataChange(inputData);
      });

  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private getInputData(): Observable<AddQuestionModalInput> {
    return this.inputData;
  }

  private handleInputDataChange(inputData: AddQuestionModalInput) {
    this.questionCategories = this.getSelectInputFromCategories(inputData.questionCategories);
    this.questionUuid = inputData.statement ? inputData.statement.uuid : null;
    this.isEdit = !!inputData.statement;
    this.defaultLanguage = inputData.defaultLanguage;
    this.createForm();
    this.accStatementsForm.valueChanges.subscribe(() => {
      this.isDisabledButton = this.accStatementsForm.invalid;
    })

    if (this.isEdit) {
      this.setDataToForm(inputData.statement);
      this.accStatementsForm.controls.category.disable();
    }
  }

  private setAvailableLanguages(availableLanguages: I18nAvailableLanguage[], allLanguages: I18nSupportedLanguage[]) {
    this.activeLanguages = availableLanguages.filter((language: I18nAvailableLanguage) => language.isActive);
    this.inActiveLanguages = allLanguages.filter(language => !this.activeLanguages.find(activeLang => activeLang.code === language.code));
    this.availableLanguages = this.activeLanguages.concat(this.inActiveLanguages);
  }

  private createForm() {
    this.accStatementsForm = this.formBuilder.group({
      translations: this.formBuilder.group(
        this.availableLanguages.reduce((reduced, currentLang) => {
          reduced[currentLang.code] = ['', []];

          return reduced;
        }, {}),
        {
          validators: (group: FormGroup) => this.validateTranslationsFormGroup(group),
        }
      ),
      category: this.formBuilder.control(this.questionCategories[0], Validators.required),
    });
    this.accStatementsForm.updateValueAndValidity();
  }

  private getSelectInputFromCategories(questionCategories: StatementGroup[]): IDropdownItem[] {
    return chain(questionCategories)
      .filter(
        (category: StatementGroup) =>
          category.groupName !== CONSTANTS.CATEGORIES.NAMES.OVERALL_EXPERIENCE &&
          category.groupName !== CONSTANTS.CATEGORIES.NAMES.TAKEAWAY_OVERALL_EXPERIENCE
      )
      .map((category: StatementGroup) => this.getSelectInputForCategory(category))
      .value();
  }

  private fetchGroupNameFromKey(groupName: string): string {
    const name = last(groupName.split('.'));

    return name.charAt(0).toUpperCase() + name.slice(1);
  }

  private getSelectInputForCategory(category: StatementGroup): IDropdownItem {
    return {
      id: category.groupUuid,
      text: category.statements[0].flexibleName
        ? category.statements[0].flexibleName
        : this.translateService.instant(
          `SHARED.BACKEND_LABELS.STATEMENT_CATEGORY.${this.fetchGroupNameFromKey(category.groupName).toUpperCase()}`
        ),
    };
  }

  private getFormValue(form: NewStatementForm) {
    return {
      translations: form.translations,
      categoryUuid: this.isEdit ? this.accStatementsForm.controls.category.value : form.category.id,
      questionUuid: this.questionUuid,
    };
  }

  private validateTranslationsFormGroup(group: FormGroup): Dictionary<any> {
    const controlsValues = values(group.controls).map((control: AbstractControl) => control.value);
    const isEveryValueEmpty = controlsValues.every((value: string) => !value);
    const isEmptyString = !!controlsValues.find(value => value === ' ');
    const isValueEqualToInitial = this.initialFormData ? isEqual(group.value, this.initialFormData.translations) : false;
    const isDefaultLanguage = group.get(this.defaultLanguage.code).value && group.get(this.defaultLanguage.code).value !== ' ';

    if (isEveryValueEmpty || isValueEqualToInitial || isEmptyString || !isDefaultLanguage) {
      return {
        required: {},
      };
    }

    return {};
  }

  private setDataToForm(statement: Statement) {
    const formObj: NewStatementForm = {
      translations: this.foodbackQuestionsService.getTranslationsForAccountStatementsForm(this.availableLanguages, statement.translations),
      category: this.questionCategories.find(category => category.id === statement.groupUuid),
    };

    this.initialFormData = formObj;
    this.accStatementsForm.setValue(formObj);
    this.blockInputIfLanguageNotAvailableOrNotEmpty(statement, formObj);
  }

  private blockInputIfLanguageNotAvailableOrNotEmpty(statement: Statement, formObj: NewStatementForm): void {
    this.availableLanguages.forEach(lang => {
      const control: AbstractControl = this.accStatementsForm.get(`translations.${lang.code}`);
      const placeholderControlValue = this.customQuestionPlaceholders.includes(control.value);
      if (((statement.answersCount > 0 && formObj.translations[lang.code]) || (statement.answersCount > 0 && control.value )) && !placeholderControlValue) {
        control.disable();
      } else {
        control.enable();
      }
    });
  }

  private getEscModalStatus(): Observable<boolean> {
    return this.keyboardStatusService.getEscModalStatus();
  }
}

