import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MenuItems } from './menu-items.interface';

@Injectable()
export class MenuService {
  menuItems$: BehaviorSubject<any[]> = new BehaviorSubject([]);
  settingsMenuItems$: BehaviorSubject<any[]> = new BehaviorSubject([]);

  constructor() {
    this.clearMenu();
  }

  addMenu(items: MenuItems[], menuType: string) {
    const menu = [];

    items.forEach(item => {
      menu.push(item);
    });
    this[menuType].next(menu);
  }

  getMenu$() {
    return this.menuItems$;
  }

  getSettings$() {
    return this.settingsMenuItems$;
  }

  private clearMenu() {
    this.menuItems$.next([]);
    this.settingsMenuItems$.next([]);
  }
}
