import { DateGroupPickerValue } from '../components/date-range-picker';

export interface DynamicDateRange {
  start: Date;
  end: Date;
  groupBy?: DateGroupPickerValue;
  precision?: DateGroupPickerValue;
}
export enum StaticDateRangePeriod {
  DAYS = 'days',
  ALL_TIME = 'allTime',
  TIMES = 'TIMES',
  MONTHS = 'months'
}
export interface StaticDateRange {
  period: StaticDateRangePeriod;
  count: number;
  groupBy: DateGroupPickerValue.STATIC;
}
export type DateRange = DynamicDateRange | StaticDateRange;
export enum DateRangeType {
  DYNAMIC,
  STATIC,
}
