<div class="PanelData" [ngClass]="{ 'PanelData-isDraggable': isDraggable }" fxLayout="column" fxLayoutAlign="space-around stretch">
  <div *ngIf="isDraggable" fxLayout="row" fxLayoutAlign="center center">
    <ng-content select="[dragHandler]"></ng-content>
  </div>
  <div fxFlex>
    <ng-container *ngTemplateOutlet="primaryHeaderTmpl"></ng-container>
  </div>
  <div class="PanelData-panelContent" fxFlex>
    <ng-container *ngTemplateOutlet="primaryContentTmpl"></ng-container>
  </div>
  <ng-content select="[footer]"></ng-content>
</div>

<ng-template #primaryHeaderTmpl>
  <ng-content select="[primaryHeader]"></ng-content>
</ng-template>
<ng-template #primaryContentTmpl>
  <ng-content select="[primaryContent]"></ng-content>
</ng-template>
