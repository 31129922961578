<fieldset class="RolesSwitcher" [formGroup]="userRole">
  <div class="form-group">
    <div class="radio c-radio" *ngFor="let role of roles">
      <label class="m0">
        <input type="radio" [value]="role.value" name="role" formControlName="role" />
        <span class="fa fa-circle"></span>
        <span class="RolesSwitcher-text" [innerHTML]="role.translationKey | translate"></span>
      </label>
    </div>
  </div>
</fieldset>
