import { Injectable } from '@angular/core';
import { VenueStatistics } from '../../../shared/interfaces/analytics.interface';

@Injectable()
export class DomainService {
  getResponseRate(statistics: VenueStatistics): number {
    if (statistics.notSkippedFoodbacksCount + statistics.skippedFoodbacksCount === 0) {
      return 0;
    }
    const fullPercent = 100;

    return (statistics.foodbacksCount / (statistics.notSkippedFoodbacksCount + statistics.skippedFoodbacksCount)) * fullPercent;
  }
}
