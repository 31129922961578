import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Observable, zip } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { FoodbackAccount } from '../../../core/classes/account/account.class';
import { AccountService } from '../../../core/providers/account/account.service';
import { AccountSingleVenueBackendService } from '../../../core/providers/backend/account-single-venue-backend.service';
import { ContextService } from '../../../core/providers/context/context.service';
import { ContextObject } from '../../interfaces/login-object';

@Injectable()
export class GoalsGuard implements CanActivate {
  constructor(
    private accountService: AccountService,
    private accountSingleVenueBackendService: AccountSingleVenueBackendService,
    private contextService: ContextService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return zip(
      this.accountService.getAccount$().pipe(first()),
      this.accountSingleVenueBackendService.isSingleVenueMode().pipe(first()),
      this.contextService.getContext$()
    ).pipe(
      map(
        ([account, singleVenueMode, context]: [FoodbackAccount, boolean, ContextObject]) =>
          ContextService.isAccountContext(context) || singleVenueMode
      )
    );
  }
}
