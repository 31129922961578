import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { find, without } from 'lodash';
import { Subscription, fromEvent } from 'rxjs';
import { IMultiSelectTagItem } from '../../../core/interfaces/multi-select-tag-item.interface';
import { KeyboardStatusService } from '../../../core/providers/keyboard-status/keyboard-status.service';
import { KeyboardUtils } from '../../../core/utils/events/keyboard.utils';
import { fadeSlideUpAnimation } from '../../animations/fade-slide-up.animation';
import { CONSTANTS } from '../../constants';
import { SortByFieldPipe } from '../../pipes/sort-by-field.pipe';
import { MultiSelectTagListRef } from './multi-select-tag-list/multi-select-tag-list-ref';
import { MultiSelectTagListService } from './multi-select-tag-list/multi-select-tag-list.service';

@Component({
  selector: 'app-multi-select-tag',
  templateUrl: './multi-select-tag.component.html',
  styleUrls: ['./multi-select-tag.component.scss'],
  animations: [fadeSlideUpAnimation],
})
export class MultiSelectTagComponent implements OnInit, OnChanges, OnDestroy {
  /**
   * Main input element reference where the written text comes from.
   */
  @ViewChild('input') input: ElementRef;

  /**
   * Main input element reference where the written text comes from.
   */
  @ViewChild('multiselect', { static: true }) multiselect: ElementRef;
  /**
   * A state when we can modify tags and items.
   */
  @Input() editMode = false;
  /**
   * Disable input
   */
  @Input() disable = false;
  /**
   * A state when we can do nothing.
   */
  @Input() readonlyMode = false;
  /**
   * As above despite the fact that we can't disable the edit mode.
   * Helpful when we want to use multi select tag input as a search or filter.
   */
  @Input() permanentEditMode = false;
  /**
   * While being in this mode and looking for an item that doesn't exist, we are able to instantly add it to the search pool.
   * This component won't directly modify the pool but it will emit an event to inform parent in which we can implement the 'add' method.
   */
  @Input() addOnDemandMode = false;
  /**
   * Added tags displayed next to each other.
   */
  @Input() tags: IMultiSelectTagItem[] = [];
  /**
   * A pool of items that are being displayed for search request. From this pool we can add new tag.
   */
  @Input() items: IMultiSelectTagItem[] = [];
  /**
   * Text displayed while the input is empty. This placeholder is visible when edit mode is enabled.
   */
  @Input() placeholder = 'SHARED.COMPONENTS.MULTI_SELECT_TAG.PLACEHOLDER';
  /**
   * Text displayed when tags collection is empty and edit mode is disabled.
   */
  @Input() noTagsPlaceholder = 'SHARED.COMPONENTS.MULTI_SELECT_TAG.NO_TAGS_PLACEHOLDER';
  /**
   * Text displayed in the dropdown when none of items matches input value.
   */
  @Input() noResultsPlaceholder = 'SHARED.COMPONENTS.MULTI_SELECT_TAG.NO_RESULTS_PLACEHOLDER';

  /**
   * Max Tags container height in px
   */
  @Input() maxTagsContainerHeight = 100;
  /**
   * Informs parent on change in tags collections.
   */
  @Output() readonly onTagsChange: EventEmitter<IMultiSelectTagItem[]> = new EventEmitter();
  @Output() readonly onSelectItem: EventEmitter<IMultiSelectTagItem> = new EventEmitter();
  @Output() readonly onDeselectItem: EventEmitter<IMultiSelectTagItem> = new EventEmitter();
  /**
   * While addOnDemandMode is enabled it will inform parent that we requested new item to be added to the items and tag pools.
   */
  @Output() readonly onAddItem: EventEmitter<IMultiSelectTagItem> = new EventEmitter();
  /**
   * Informs parent that tag has been removed.
   */
  @Output() readonly onRemoveTag: EventEmitter<string> = new EventEmitter();
  @ViewChild('multiSelectBody') multiSelectBody: ElementRef;
  @ViewChild('tagsContainer') tagsContainer: ElementRef;
  @ViewChild('multiSelectTagListRef', { static: true })
  multiSelectTagListTemplate: TemplateRef<any>;
  @ViewChild('multiSelectListContainerRef')
  multiSelectListContainerRef: ElementRef;
  filteredItems: IMultiSelectTagItem[] = [];
  inputFocused: boolean;
  // dropdownAnimationState: 'start' | 'done' = 'start';
  readonly componentId: string = new Date().toISOString();
  private counterSelectedValues = -1;
  private subscriptions: Subscription;
  private multiSelectTagListRef: MultiSelectTagListRef;
  private isSelectModeEnabled = true;
  readonly CONSTANTS = CONSTANTS;

  constructor(private keyboardStatusService: KeyboardStatusService, private multiSelectTagListService: MultiSelectTagListService) {}

  handleInputKeyup(event: KeyboardEvent, value: string) {
    if (KeyboardUtils.pressedEnter(event) && !this.canAddItem(value)) {
      this.onEnterEvent();
    }
    if (KeyboardUtils.pressedEnter(event) && this.canAddItem(value)) {
      /*
        When user types and presses enter
        1. There is no such item: adds the item
        2. There is more than one item: does nothing
        3. There is one item: adds the item
       */
      const selectedValue = this.getSelectedValue();

      if (selectedValue) {
        this.selectItem(selectedValue);
      } else {
        this.addNewItemByText(value);
      }
    } else if (KeyboardUtils.pressedEscape(event)) {
      this.disableEditMode();
    } else {
      this.filterItems(value);
    }
    if (!KeyboardUtils.pressedDown(event) && !KeyboardUtils.pressedUp(event)) {
      this.counterSelectedValues = this.filteredItems.findIndex(item => item.isSelected);
    }
    if (KeyboardUtils.pressedUp(event) && this.counterSelectedValues > 0) {
      this.scrollUpTheList();
      this.onUpKeyEvent();
    } else if (KeyboardUtils.pressedUp(event) && this.counterSelectedValues === 0) {
      this.scrollUpTheList();
      this.counterSelectedValues = -1;
    }
    if (KeyboardUtils.pressedDown(event) && this.counterSelectedValues < this.filteredItems.length - 1) {
      this.scrollDownTheList();
      this.onDownKeyEvent();
    } else if (KeyboardUtils.pressedDown(event) && this.counterSelectedValues === this.filteredItems.length - 1) {
      this.counterSelectedValues = this.filteredItems.length;
    }
  }

  handleInputFocus(event: Event) {
    event.preventDefault();
    this.inputFocused = true;
    this.setItems();

    if (!this.multiSelectTagListRef) {
      this.show(this.multiSelectTagListTemplate, this.input);
    }
    // Display the same results as before if user blurred the input with some value.
    if (this.input.nativeElement.value) {
      this.clearInput();
    }
  }

  isEditMode(): boolean {
    return this.editMode || this.permanentEditMode;
  }

  isTagAdded(text: string): boolean {
    return !!find(this.tags, { text });
  }

  isTagAddedById(id: string): boolean {
    return !!find(this.tags, { id });
  }

  selectItem(selectedItem: IMultiSelectTagItem) {
    if (!this.isSelectModeEnabled) {
      return;
    }
    if (selectedItem) {
      this.assignItemAndClearInput(selectedItem);
    }
  }

  removeTag(selectedItem: IMultiSelectTagItem, event?: Event) {
    // Click on tag is being detected as an outside click which triggers disabling edit mode. We want to avoid it.
    if (event) {
      event.stopPropagation();
    }

    this.onTagsChange.emit(without(this.tags, selectedItem));
    this.onDeselectItem.emit(selectedItem);
    this.onRemoveTag.emit(selectedItem.text);

    if (this.filteredItems && this.filteredItems.length === 0) {
      this.setItems();
    }

    this.filteredItems.find(item => item.id === selectedItem.id).isAdded = false;
  }

  enableEditMode() {
    this.editMode = true;
    setTimeout(() => this.input.nativeElement.focus(), CONSTANTS.TIME_IN_MS.TIME_100);
  }

  disableEditMode(event?: Event) {
    if (event) {
      event.preventDefault();
    }
    if (this.multiSelectTagListRef) {
      this.multiSelectTagListRef.close();
    }

    this.counterSelectedValues = -1;
    this.editMode = this.permanentEditMode;
    this.input.nativeElement.blur();
    this.clearInput();
    this.setEscModalStatus(true);
  }

  show(content: TemplateRef<any>, origin: ElementRef) {
    this.setEscModalStatus(false);
    this.multiSelectTagListRef = this.multiSelectTagListService.open<any>({
      content,
      origin,
      width: `${this.input.nativeElement.offsetWidth}px`,
    });
    this.setOutsideEventClick();
    this.subscriptions.add(this.multiSelectTagListRef.afterSelected$.subscribe(res => this.selectItem(res)));
    this.subscriptions.add(this.multiSelectTagListRef.afterClosed$.subscribe(() => this.setEscModalStatus(true)));
  }

  ngOnInit(): void {
    this.subscriptions = new Subscription();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.isTagsValueChanged(changes)) {
      this.updateMultiSelectTagListAfterTagsChanges();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private assignItemAndClearInput(selectedItem: IMultiSelectTagItem) {
    this.isSelectModeEnabled = false;

    if (selectedItem && this.isTagAddedById(selectedItem.id)) {
      // TODO: For now let's don't make users to remove item by clicking it when it's selected. The only way is to remove it by tag.
      this.removeTag(selectedItem);
    } else {
      this.scrollTagsToBottom();
      this.onTagsChange.emit([...this.tags, selectedItem]);
      this.onSelectItem.emit(selectedItem);
      selectedItem.isAdded = true;
    }

    this.clearInput();
  }

  private setEscModalStatus(value: boolean) {
    setTimeout(() => {
      this.keyboardStatusService.setEscModalStatus(value);

      if (value) {
        this.multiSelectTagListRef = null;
      }
    }, CONSTANTS.TIME_IN_MS.TIME_250);
  }

  private setOutsideEventClick() {
    this.subscriptions.add(
      fromEvent(document, 'click').subscribe((event: Event) => {
        if (this.multiSelectListContainerRef && !this.multiSelectListContainerRef.nativeElement.contains(event.target)) {
          this.multiSelectTagListRef = null;
          this.keyboardStatusService.setEscModalStatus(true);
        }
      })
    );
  }

  private scrollUpTheList() {
    if (this.multiSelectBody.nativeElement.scrollTop > 0) {
      const itemElement = this.multiSelectBody.nativeElement.children[this.counterSelectedValues];

      if (itemElement) {
        // eslint-disable-next-line no-magic-numbers
        this.multiSelectBody.nativeElement.scrollTop = itemElement.offsetTop - 50;
      }
    }
  }

  private scrollDownTheList() {
    // eslint-disable-next-line no-magic-numbers
    if (this.counterSelectedValues > 5) {
      const itemElement = this.multiSelectBody.nativeElement.children[this.counterSelectedValues];

      if (itemElement) {
        this.multiSelectBody.nativeElement.scrollTop = itemElement.offsetTop;
      }
    }
  }

  private addNewItemByText(value: string) {
    const itemToFind: IMultiSelectTagItem = find(this.items, (item: IMultiSelectTagItem) => item.text === value);
    const lastFiltered: boolean = this.filteredItems.length === 1;

    if (itemToFind && lastFiltered) {
      this.selectItem(itemToFind);
    } else if (!itemToFind) {
      this.addItem(value);
    }
  }

  private getSelectedValue(): IMultiSelectTagItem {
    const selectedItem: IMultiSelectTagItem = this.filteredItems.find(item => item.isSelected);
    const foundItem: IMultiSelectTagItem = this.filteredItems.find(item => !item.isAdded && item.text === this.input.nativeElement.value);

    return selectedItem ? selectedItem : foundItem;
  }

  private onEnterEvent() {
    if (this.getSelectedValue()) {
      this.filteredItems = new SortByFieldPipe().transform(this.filteredItems, 'text', true);
      const selectedItem = this.filteredItems[this.counterSelectedValues];

      this.selectItem(selectedItem);
      this.setItems();
    } else {
      this.disableEditMode();
    }
  }

  private onUpKeyEvent() {
    this.counterSelectedValues--;
    this.filteredItems = new SortByFieldPipe().transform(this.filteredItems, 'text', true);
    this.filteredItems[this.counterSelectedValues].isSelected = true;
  }

  private onDownKeyEvent() {
    this.counterSelectedValues++;
    this.filteredItems = new SortByFieldPipe().transform(this.filteredItems, 'text', true);
    this.filteredItems[this.counterSelectedValues].isSelected = true;
  }

  private filterItems(inputValue: string) {
    this.filteredItems = this.items
      .filter((item: IMultiSelectTagItem) => item.text.toLowerCase().includes(inputValue.toLowerCase()))
      .map(item => {
        item.isAdded = this.isTagAddedById(item.id);

        return item;
      });
  }

  private addItem(value: string) {
    this.input.nativeElement.value = '';
    this.clearInput();
    this.onAddItem.emit({
      id: null,
      text: value,
      isSelected: false,
      isAdded: true,
    });
    this.setItems();
  }

  /**
   * Clears input value which triggers displaying all items. Useful when we just added new item or tag.
   */
  private clearInput() {
    this.input.nativeElement.value = '';
  }

  private canAddItem(itemValue: string): boolean {
    const selectedItem: IMultiSelectTagItem = this.filteredItems.find(item => item.isSelected);

    return selectedItem ? true : this.addOnDemandMode && itemValue && !this.isTagAdded(itemValue);
  }

  private scrollTagsToBottom() {
    if (this.tagsContainer && this.tagsContainer.nativeElement.scrollHeight > this.maxTagsContainerHeight) {
      this.tagsContainer.nativeElement.scrollTop = this.tagsContainer.nativeElement.scrollHeight;
    }
  }

  private setItems() {
    this.filteredItems = this.items.map(item => {
      item.isAdded = this.isTagAddedById(item.id);

      return item;
    });
  }

  private updateMultiSelectTagListAfterTagsChanges() {
    this.isSelectModeEnabled = true;

    if (this.multiSelectTagListRef) {
      this.multiSelectTagListRef.updatePosition();
      setTimeout(() => {
        this.input.nativeElement.focus();
        this.setItems();
      }, CONSTANTS.TIME_IN_MS.TIME_500);
    }
  }

  private isTagsValueChanged(changes: SimpleChanges): boolean {
    return (
      changes.tags &&
      changes.tags.currentValue &&
      changes.tags.previousValue &&
      changes.tags.currentValue.length !== changes.tags.previousValue.length
    );
  }
}
