<div *ngIf="!isSingleMode" class="Preloader" [ngClass]="{ 'Preloader--overlay': type === PreloaderType.OVERLAY }">
  <mat-spinner diameter="100"></mat-spinner>
</div>
<div *ngIf="isSingleMode" class="Preloader Preloader-singleMode">
  <mat-spinner diameter="100"></mat-spinner>
  <div
    [ngClass]="{
      'Preloader--overlay Preloader-singleModeOverlay': type === PreloaderType.OVERLAY
    }"></div>
</div>
