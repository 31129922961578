import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { DashboardQueryParams } from '../../../core/interfaces/dashboard-query-params.interface';
import { UtilsService } from '../../../core/utils/utils.service';
import { DateRangePickerService } from '../../../shared/components/date-range-picker/date-range-picker.service';
import { DateGroupPickerValue } from '../../../shared/components/date-range-picker/index';
import { CONSTANTS } from '../../../shared/constants';
import {
  DateRange,
  DynamicDateRange,
  StaticDateRange,
  StaticDateRangePeriod,
} from '../../../shared/interfaces/date-range.interface';

@Injectable()
export class DashboardDatesService {
  constructor(
    private dateRangePickerService: DateRangePickerService,
    private utilsService: UtilsService
  ) {}

  getDateRangeForRequest(dateRange: DateRange): DynamicDateRange {
    // @todo hack for the problems with UTC handling. This will be handled by backend eventually, but for now keep as is
    if (this.dateRangePickerService.assertDateRangeTypeDynamic(dateRange)) {
      return {
        start: dateRange.start,
        end: dateRange.end,
        groupBy: dateRange.groupBy,
      };
    }

    return this.dateRangePickerService.convertStaticDateRangeToCustomDynamic(
      dateRange
    );
  }

  getDynamicDateRangeFromStatic(dateRange: DateRange): DynamicDateRange {
    if (this.dateRangePickerService.assertDateRangeTypeDynamic(dateRange)) {
      return dateRange;
    }

    return this.dateRangePickerService.convertStaticDateRangeToCustomDynamic(
      dateRange
    );
  }

  getLastXDaysDateRangeForRequest(days: number): DynamicDateRange {
    const lastXDaysDateRange = this.getLastXDaysDateRange(days);

    return {
      start: lastXDaysDateRange.start,
      end: lastXDaysDateRange.end,
      groupBy: lastXDaysDateRange.groupBy,
    };
  }

  getDefaultDateRange(): DateRange {
    return {
      period: StaticDateRangePeriod.DAYS,
      count: CONSTANTS.DASHBOARD.DEFAULT_LAST_X_DAYS_COUNT,
      groupBy: DateGroupPickerValue.STATIC,
    };
  }

  getAllDates(startDate: Date, stopDate: Date): string[] {
    const dateArray = [];
    let currentDate = startDate;

    while (currentDate <= stopDate) {
      dateArray.push(this.prepareDateForRequest(currentDate));
      currentDate = this.addDays(currentDate, 1);
    }

    return dateArray;
  }

  getLastXDaysDateRange(days: number): DynamicDateRange {
    const start = moment(
      this.dateRangePickerService.getCovertedDateWithTimezone(new Date())
    )
      .subtract(days, 'days')
      .toDate();
    const end = this.dateRangePickerService.getCovertedDateWithTimezone(
      new Date()
    );

    start.setHours(0, 0, 0);
    end.setHours(0, 0, 0);

    return {
      start,
      end,
      groupBy: DateGroupPickerValue.CUSTOM,
    };
  }

  getLastXWeeksDateRange(weeks: number): DynamicDateRange {
    const start = moment(
      this.dateRangePickerService.getCovertedDateWithTimezone(new Date())
    )
      .subtract(weeks, 'week')
      .toDate();
    const end = this.dateRangePickerService.getCovertedDateWithTimezone(
      new Date()
    );

    start.setHours(0, 0, 0);
    end.setHours(0, 0, 0);

    return {
      start,
      end,
      groupBy: DateGroupPickerValue.CUSTOM,
    };
  }

  getDefaultQueryParams(
    dateRange: DateRange | StaticDateRange
  ): Partial<DashboardQueryParams> {
    if (this.dateRangePickerService.assertDateRangeTypeDynamic(dateRange)) {
      return {
        dateFrom: moment(dateRange.start).format(
          CONSTANTS.DATES_FORMAT.QUERY_PARAM_DATE
        ),
        dateTo: moment(dateRange.end).format(
          CONSTANTS.DATES_FORMAT.QUERY_PARAM_DATE
        ),
        groupBy: dateRange.groupBy,
      };
    }

    return {
      staticFilterPeriod: dateRange.period,
      staticFilterCount: dateRange.count,
      groupBy: DateGroupPickerValue.STATIC,
    };
  }

  prepareDateForRequest(date: Date): string {
    return this.utilsService.prepareDateForRequest(date);
  }

  getRangeLengthInDays(dateRange: DateRange): number {
    if (this.dateRangePickerService.assertDateRangeTypeDynamic(dateRange)) {
      return Math.round(moment(dateRange.end).diff(dateRange.start, 'd', true));
    }

    return this.getStaticRangeLengthInDays(dateRange);
  }

  getRangeLengthInDaysFormDates(dateFrom: string, dateTo: string): number {
    return Math.round(
      moment(new Date(dateTo + CONSTANTS.DATE_TIME_VALUES.MIDNIGHT)).diff(
        new Date(dateFrom + CONSTANTS.DATE_TIME_VALUES.MIDNIGHT),
        'd',
        true
      )
    );
  }

  private getStaticRangeLengthInDays(dateRange: StaticDateRange): number {
    switch (dateRange.period) {
      case StaticDateRangePeriod.DAYS:
        return dateRange.count;
      case StaticDateRangePeriod.ALL_TIME:
      default:
        return Infinity;
    }
  }

  private addDays(date: Date, days: number): Date {
    const dat = new Date(date.valueOf());

    dat.setDate(dat.getDate() + days);

    return dat;
  }
}
