<div class="CategoryOverviewCard">
  <a [routerLink]="categoryLink" [queryParams]="linkQueryParams" class="link-unstyled">
    <div class="CategoryOverviewCard-header" [style.background]="'#' + categoryDetails.color">
      <span class="fb-category-icon align-middle" [ngClass]="'fb-category-icon--' + categoryDetails.name"></span>
      <div class="CategoryOverviewCard-categoryName">
        <span class="align-middle">{{
          categoryDetails.flexibleName
            ? categoryDetails.flexibleName
            : ('SHARED.BACKEND_LABELS.' + categoryDetails.name | uppercase | translate)
        }}</span>
      </div>
    </div>

    <app-percent-circle
      class="CategoryOverviewCard-circle"
      [goal]="goal"
      [useCustomContent]="true"
      [percentValue]="getCategoryPercentValue()"
      [variant]="PercentCircleVariant.BLUE">
      <div custom-content>
        <span class="h1">{{ categoryDetails.value | number: CONSTANTS.NUMBER_PIPE.DEFAULT_FORMAT }}</span>
        <br />
        <span class="h4 fb-text-info">
          {{ 'SHARED.SYSTEM.GOAL' | translate }} {{ goal | number: CONSTANTS.NUMBER_PIPE.DEFAULT_FORMAT }}
        </span>
      </div>
    </app-percent-circle>
  </a>

  <div *ngIf="topLowPerformingQuestions">
    <div class="CategoryOverviewCardQuestion bt">
      <a [routerLink]="questionLinkTop" [queryParams]="linkQueryParams" class="link-unstyled CategoryOverviewCardQuestion-link">
        <div class="CategoryOverviewCardQuestion-header">
          <span
            ><i class="mdi mdi-arrow-up mdi-18px align-middle CategoryOverviewCardQuestion-arrow--up"></i>
            {{ 'HOME.OVERVIEW.CATEGORY_CARD.TOP_QUESTION' | translate }}</span
          >
          <div class="CategoryOverviewCard-alignDigit">
            <span> {{ topLowPerformingQuestions.top?.average | number: CONSTANTS.NUMBER_PIPE.DEFAULT_FORMAT }} </span>
          </div>
        </div>
        <div class="CategoryOverviewCardQuestion-text">
          {{ topLowPerformingQuestions.top?.surveyQuestion.translations | getNativeLanguageTranslationOrFirst: nativeLang }}
        </div>
      </a>
    </div>
    <div class="CategoryOverviewCardQuestion bt">
      <a [routerLink]="questionLinkLow" [queryParams]="linkQueryParams" class="link-unstyled CategoryOverviewCardQuestion-link">
        <div class="CategoryOverviewCardQuestion-header">
          <span
            ><i class="mdi mdi-arrow-down mdi-18px align-middle CategoryOverviewCardQuestion-arrow--down"></i>
            {{ 'HOME.OVERVIEW.CATEGORY_CARD.LOW_QUESTION' | translate }}</span
          >
          <div class="CategoryOverviewCard-alignDigit">
            <span> {{ topLowPerformingQuestions.low?.average | number: CONSTANTS.NUMBER_PIPE.DEFAULT_FORMAT }} </span>
          </div>
        </div>
        <div class="CategoryOverviewCardQuestion-text">
          {{ topLowPerformingQuestions.low?.surveyQuestion.translations | getNativeLanguageTranslationOrFirst: nativeLang }}
        </div>
      </a>
    </div>
  </div>
</div>
