import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DataFilterService } from '../data-filter.service';

@Component({
  selector: 'app-data-filter-comments',
  templateUrl: './data-filter-comments.component.html',
  styleUrls: ['./data-filter-comments.component.scss'],
})
export class DataFilterCommentsComponent implements OnInit, OnDestroy {
  @Output() readonly onCommentFilterChanged = new EventEmitter();

  commentCtrl: FormControl = new FormControl({ value: '', disabled: true });
  hasComment = false;
  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(private dataFilterService: DataFilterService) {}

  ngOnInit(): void {
    if (this.dataFilterService.getHasComment()) {
      this.hasComment = true;
      this.onHasCommentChanged();
      this.commentCtrl.setValue(this.dataFilterService.getComment());
    }

    this.commentCtrl.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => this.setPathValue());
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  onHasCommentChanged() {
    if (this.hasComment) {
      this.commentCtrl.enable();
    } else {
      this.commentCtrl.disable();
    }
  }

  private setPathValue(): void {
    this.dataFilterService.setHasComment(this.hasComment);
    this.dataFilterService.setComment(
      this.hasComment ? this.commentCtrl.value : null
    );
    this.onCommentFilterChanged.emit(
      this.dataFilterService.hasCommentChanged()
    );
  }
}
