const AA_FIRST = 1, // aa is sorted to the beginning of the array, before a
  AA_LAST = 2, // the default - aa is sorted to the end of the array, after å
  AA_ARING = 3; // aa is considered equal with å

interface Array<T> {
  sortDa: (o: number, fieldName: keyof T) => T[];
}
if (!Array.prototype.sortDa) {
  Array.prototype.sortDa = function <T>(
    this: T[],
    aamode: number,
    fieldName: keyof T
  ) {
    this.sort((a, b) => {
      let d, e, f;

      aamode = typeof aamode === 'undefined' ? AA_LAST : aamode;

      function intArray(c) {
        const array = [];

        c = c.toLowerCase();

        for (let i = 0; i < c.length; i++) {
          if (c.substr(i, 2) === 'aa') {
            switch (aamode) {
              case AA_FIRST:
                array.push(0);
                break;
              case AA_ARING:
                array.push(299);
                break;
              default:
                array.push(300);
                break;
            }

            i = i + 1;
          } else {
            switch (c.charCodeAt(i)) {
              case 229: //å
                array.push(299);
                break;
              case 248: //ø
                array.push(298);
                break;
              case 230: //æ
                array.push(297);
                break;
              default:
                array.push(c.charCodeAt(i));
                break;
            }
          }
        }

        return array;
      }

      (d = intArray(getTextValue(a, fieldName))), (e = intArray(getTextValue(b, fieldName)));

      function getTextValue<T>(element: T, fieldName: keyof T) {
        return element[fieldName]
          ? (element[fieldName] as string).toLowerCase()
          : '';
      }
      for (f = 0; f < d.length; f++) {
        if (d[f] != e[f]) {
          if (f == e.length) {
            return 1; //d has more chars than e
          }

          return d[f] - e[f];
        }
      }

      return -1; //e has more chars than d
    });

    return this;
  };
}

// if (!Array.prototype.remove) {
//   Array.prototype.remove = function<T>(this: T[], elem: T): T[] {
//     return this.filter(e => e !== elem);
//   }
// }
//
// if (!Array.prototype.sortDa) {
//   Array.prototype.sortDa = (this: T[], aamode: number, fieldName: string) => {
//     console.log(this);
//
//     return this.sort((a, b) => {
//       let d, e, f;
//
//       aamode = typeof aamode === 'undefined' ? AA_LAST : aamode;
//
//       const intArray = c => {
//         const array = [];
//
//         c = c.toLowerCase();
//
//         for (let i = 0; i < c.length; i++) {
//           if (c.substr(i, 2) === 'aa') {
//             switch (aamode) {
//               case AA_FIRST:
//                 array.push(0);
//                 break;
//               case AA_ARING:
//                 array.push(299);
//                 break;
//               default:
//                 array.push(300);
//                 break;
//             }
//
//             i = i + 1;
//           } else {
//             switch (c.charCodeAt(i)) {
//               case 229: //å
//                 array.push(299);
//                 break;
//               case 248: //ø
//                 array.push(298);
//                 break;
//               case 230: //æ
//                 array.push(297);
//                 break;
//               default:
//                 array.push(c.charCodeAt(i));
//                 break;
//             }
//           }
//         }
//
//         return array;
//       };
//
//       (d = intArray(getTextValue(a, fieldName))),
//         (e = intArray(getTextValue(b, fieldName)));
//
//       for (f = 0; f < d.length; f++) {
//         if (d[f] !== e[f]) {
//           if (f === e.length) {
//             return 1; //d has more chars than e
//           }
//
//           return d[f] - e[f];
//         }
//       }
//
//       return -1; //e has more chars than d
//     });
//     const getTextValue = (element, fieldName) =>
//       element[fieldName] ? (element[fieldName] as string).toLowerCase() : '';
//   };
// }
