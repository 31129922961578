<div class="Branding-colors">
  <h4 class="page-header heading-text--mt">{{ 'SHARED.COMPONENTS.BRANDING_COLOR.HEADER' | translate }}</h4>

  <p class="color-descriptions">{{ 'SHARED.COMPONENTS.BRANDING_COLOR.DESCRIPTION' | translate }}</p>

  <label
    *ngFor="let radio of radioList"
    class="btn btn-outline"
    [ngClass]="radio.className"
    [(ngModel)]="chosenBrandingColor"
    name="brandingColor"
    ngDefaultControl
    [btnRadio]="radio.btnRadio"
    (click)="chooseColor(chosenBrandingColor)"></label>
  <br />
  <div class="mt-lg">
    <strong> {{ 'SHARED.COMPONENTS.BRANDING_COLOR.CUSTOM_HEADER' | translate }} </strong>

    <p class="color-descriptions">{{ 'SHARED.COMPONENTS.BRANDING_COLOR.CUSTOM_DESCRIPTION' | translate }}</p>
    <div>
      <mat-form-field class="Branding-colors-input mr-lg">
        <mat-label>{{ 'SHARED.COMPONENTS.BRANDING_COLOR.CUSTOM_HEADER' | translate }}</mat-label>
        <input
          matInput
          autocomplete="off"
          list="autocompleteOff"
          class="btn-color-picker"
          [value]="selectedValue"
          (input)="onColorType($event.target.value)" />
      </mat-form-field>

      <input
        class="btn btn-outline btn-outline-border fake-input-color"
        (input)="onColorType($event.target.value)"
        [cpPosition]="'top'"
        [(cpToggle)]="togglePicker"
        [cpDisableInput]="true"
        [cpAlphaChannel]="'disabled'"
        [style.background]="customColor"
        [(colorPicker)]="customColor"
        (colorPickerChange)="changeColorPicker($event)"
        onkeypress="return false;" />
    </div>
  </div>
</div>
