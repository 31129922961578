<app-dialog>
  <app-dialog-title>
    <span>{{ 'SHARED.QUESTIONS.RENAME_CATEGORIES' | translate }}</span>
  </app-dialog-title>
  <app-dialog-content>
    <form *ngIf="renameCategoriesForm" [formGroup]="renameCategoriesForm">
      <div formArrayName="categories" fxLayout="column" fxLayoutAlign="space-around stretch">
        <ng-container *ngFor="let item of renameCategoriesForm.get('categories')['controls']; let category = index">
          <mat-form-field [formGroupName]="category">
            <mat-label>{{ 'SHARED.SYSTEM.CATEGORY' | translate }} {{ category + 1 }}</mat-label>
            <input
              matInput
              autocomplete="off"
              list="autocompleteOff"
              [placeholder]="'SHARED.SYSTEM.NAME' | translate"
              name="category"
              required
              formControlName="categoryName" />
            <mat-error [appErrors]="'categories.' + category + '.categoryName'" [errors]="errors"> </mat-error>
          </mat-form-field>
        </ng-container>
      </div>
    </form>
  </app-dialog-content>
  <app-dialog-actions>
    <button type="button" class="btn btn-foodback btn-foodback--secondary text-uppercase mr-sm" (click)="cancel()">
      {{ 'SHARED.SYSTEM.CANCEL' | translate }}
    </button>

    <button class="btn btn-foodback text-uppercase" (click)="submit()" [disabled]="isDisabledButton">
      {{ 'SHARED.SYSTEM.SAVE_CHANGES' | translate }}
    </button>
    <!--    <ng-template #buttonForCreate>-->
    <!--      <button class="btn btn-foodback pull-right text-uppercase"-->
    <!--              type="submit"-->
    <!--              (click)="onSubmit(accStatementsForm)"-->
    <!--              [disabled]="isDisabledButton">-->
    <!--        {{'SHARED.QUESTIONS.ADD_QUESTION' | translate}}-->
    <!--      </button>-->
    <!--    </ng-template>-->
  </app-dialog-actions>
</app-dialog>
