import { CONSTANTS } from '../../../shared/constants';
import { VENUE_USERS_ROUTES_PATHS_FULL, VENUE_USERS_ROUTES_PATHS_PARTIALS } from './users/ven-users-routes-paths';
import { VENUE_CAMPAIGNS_ROUTES_PATHS_PARTIALS } from './ven-campaigns/ven-campaigns-routes-paths';
import {
  VENUE_CONFIGURATION_ROUTES_PATHS_FULL,
  VENUE_CONFIGURATION_ROUTES_PATHS_PARTIALS,
} from './ven-configuration/ven-configuration-routes-paths';
import { VENUE_ONE_MORE_QUESTION_ROUTES_PATHS_PARTIALS } from './ven-one-more-question/ven-one-more-question-routes-paths';

export const VENUE_SETTINGS_ROUTES_PATHS_PARTIALS = {
  BASE_PATH: `${CONSTANTS.ROUTES.VENUE}/:${CONSTANTS.ROUTE_PARAMS.VENUE_ID}/${CONSTANTS.ROUTES.SETTINGS}`,
  CONFIGURATION: CONSTANTS.ROUTES.CONFIGURATION,
  DETAILS: CONSTANTS.ROUTES.VENUE_DETAILS,
  FOODBACK_QUESTIONS: CONSTANTS.ROUTES.FOODBACK_QUESTIONS,
  FOLDER: VENUE_CONFIGURATION_ROUTES_PATHS_PARTIALS.BASE_PATH,
  USERS: VENUE_USERS_ROUTES_PATHS_PARTIALS.BASE_PATH,
  GOALS: CONSTANTS.ROUTES.GOALS,
  WHEEL_OF_FORTUNE: CONSTANTS.ROUTES.WHEEL_OF_FORTUNE,
  FOODBACK_PORTAL: CONSTANTS.ROUTES.FOODBACK_PORTAL,
  ONE_MORE_QUESTION: VENUE_ONE_MORE_QUESTION_ROUTES_PATHS_PARTIALS.BASE_PATH,
  CAMPAIGNS: VENUE_CAMPAIGNS_ROUTES_PATHS_PARTIALS.BASE_PATH,
};
export const VENUE_SETTINGS_ROUTES_PATHS_FULL = {
  DETAILS: `${VENUE_SETTINGS_ROUTES_PATHS_PARTIALS.BASE_PATH}/${VENUE_SETTINGS_ROUTES_PATHS_PARTIALS.DETAILS}`,
  FOODBACK_QUESTIONS: `${VENUE_SETTINGS_ROUTES_PATHS_PARTIALS.BASE_PATH}/${VENUE_SETTINGS_ROUTES_PATHS_PARTIALS.FOODBACK_QUESTIONS}`,
  ONE_MORE_QUESTION: {
    BASE_PATH: `${VENUE_SETTINGS_ROUTES_PATHS_PARTIALS.BASE_PATH}/${VENUE_SETTINGS_ROUTES_PATHS_PARTIALS.ONE_MORE_QUESTION}`,
  },
  CAMPAIGNS: {
    BASE_PATH: `${VENUE_SETTINGS_ROUTES_PATHS_PARTIALS.BASE_PATH}/${VENUE_SETTINGS_ROUTES_PATHS_PARTIALS.CAMPAIGNS}`,
  },
  CONFIGURATION: {
    BASE_PATH: `${VENUE_SETTINGS_ROUTES_PATHS_PARTIALS.BASE_PATH}/${VENUE_SETTINGS_ROUTES_PATHS_PARTIALS.CONFIGURATION}`,
    BRANDING: `${VENUE_SETTINGS_ROUTES_PATHS_PARTIALS.BASE_PATH}/${VENUE_CONFIGURATION_ROUTES_PATHS_FULL.BRANDING}`,
    ADVERTISEMENT: `${VENUE_SETTINGS_ROUTES_PATHS_PARTIALS.BASE_PATH}/${VENUE_CONFIGURATION_ROUTES_PATHS_FULL.ADVERTISEMENT}`,
    DEMOGRAPHICS: `${VENUE_SETTINGS_ROUTES_PATHS_PARTIALS.BASE_PATH}/${VENUE_CONFIGURATION_ROUTES_PATHS_FULL.DEMOGRAPHICS}`,
    REGIONAL_SETTINGS: `${VENUE_SETTINGS_ROUTES_PATHS_PARTIALS.BASE_PATH}/${VENUE_CONFIGURATION_ROUTES_PATHS_FULL.REGIONAL_SETTINGS}`,
  },
  USERS: {
    BASE_PATH: `${VENUE_SETTINGS_ROUTES_PATHS_PARTIALS.BASE_PATH}/${VENUE_SETTINGS_ROUTES_PATHS_PARTIALS.USERS}`,
    CREATE: `${VENUE_SETTINGS_ROUTES_PATHS_PARTIALS.BASE_PATH}/${VENUE_USERS_ROUTES_PATHS_FULL.CREATE}`,
    LIST: `${VENUE_SETTINGS_ROUTES_PATHS_PARTIALS.BASE_PATH}/${VENUE_USERS_ROUTES_PATHS_FULL.LIST}`,
  },
  GOALS: `${VENUE_SETTINGS_ROUTES_PATHS_PARTIALS.BASE_PATH}/${VENUE_SETTINGS_ROUTES_PATHS_PARTIALS.GOALS}`,
  WHEEL_OF_FORTUNE: `${VENUE_SETTINGS_ROUTES_PATHS_PARTIALS.BASE_PATH}/${VENUE_SETTINGS_ROUTES_PATHS_PARTIALS.WHEEL_OF_FORTUNE}`,
  FOODBACK_PORTAL: `${VENUE_SETTINGS_ROUTES_PATHS_PARTIALS.BASE_PATH}/${VENUE_SETTINGS_ROUTES_PATHS_PARTIALS.FOODBACK_PORTAL}`,
};
