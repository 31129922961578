export class CONSTANTS {
  static ALL_QUESTIONS_CHART = {
    THRESHOLDS: {
      LOW: 3,
      HIGH: 5,
    },
    DEFAULT_LANGUAGE: 'en-GB',
    BAR_HEIGHT: 30,
  };

  static ALL_FOODBACKS_FIELDS = {
    AGE_GROUP: 'ageGroup',
    COMMENT: 'comment',
    EMAIL: 'email',
    GENDER: 'gender',
    LANG: 'lang',
    LAST_MODIFIED: 'lastModified',
    OVERALL_EXPERIENCE: 'overallExperienceAverage',
    ZIP_CODE: 'zipCode',
    VENUE: 'venue',
    VOUCHER_EMAIL: 'voucherEmail',
    ROOM: 'room',
  };

  static MAX_ALL_FOODBACKS_EXPORT_COUNT = 50000;

  static ASSETS_PATH = {
    DEFAULT_AD: './assets/img/last-view.jpg',
    DEFAULT_BRANING_LOGO: './assets/svg/foodback-horizontal-logo.svg',
    SVG_DIR: './assets/svg',
  };

  static AUTH = {
    ID_TOKEN: 'id_token',
    FOODBACK_TOKEN: 'foodbackToken',
    METADATA: 'authorizedUserData',
    ADMIN_PATH: '/admin',
    LANGUAGE: 'usr_lang',
  };

  static BRANDING_COLORS = {
    BLUE: '0288d1',
    FOODBACK_RED: 'e85e6c',
    GREEN: '689f38',
    LIGHT_BLUE: '0097a7',
    PINK: 'f06292',
    VIOLET: '9575cd',
    YELLOW: 'f28500',
    YELLOW_SUN: 'faac18',
  };

  static BRANDING_IMG_SIZE = {
    LOGO_HEIGHT: 50,
    ADV_WIDTH: 560,
  };

  static CATEGORIES = {
    NAMES: {
      MEAL: 'statement_category.meal',
      SERVICE: 'statement_category.service',
      ATMOSPHERE: 'statement_category.atmosphere',
      RESTAURANT: 'statement_category.restaurant',
      OVERALL_EXPERIENCE: 'statement_category.overall_experience',
      TAKEAWAY_OVERALL_EXPERIENCE:
        'statement_category.takeaway.overall_experience',
      ORDER: 'statement_category.order',
      PICKUP: 'statement_category.pickup',
      CLEANLINESS: 'statement_category.cleanliness',
      CANTEEN: 'statement_category.canteen',
      FLEXIBLE_1: 'statement_category.flexible_category_1',
      FLEXIBLE_2: 'statement_category.flexible_category_2',
      FLEXIBLE_3: 'statement_category.flexible_category_3',
      FLEXIBLE_4: 'statement_category.flexible_category_4',
    },
  };

  static CHART_COLORS = {
    FOODBACKS: {
      SCORE_THIS_PERIOD: {
        backgroundColor: '#e85e6c',
        borderColor: '#e85e6c',
      },
      SCORE_PREVIOUS_PERIOD: {
        backgroundColor: 'transparent',
        borderColor: '#aaaaaa',
      },
      FOODBACKS_COUNT: {
        backgroundColor: '#e2e2e2',
        borderColor: '#e2e2e2',
      },
      ACCOUNT_AVG: {
        backgroundColor: '#008cf6',
        borderColor: '#008cf6',
      },
    },
    AGE_DISTRIBUTION: {
      TICKS: '#adadad',
    },
    PAY_ALL_VS_SPLIT: {
      PAY_ALL: '#ff6384',
      SPLIT: '#717171',
    },
    PAYMENTS_TRENDLINE: {
      TOTAL_PAYMENTS: {
        backgroundColor: '#e85e6c',
        borderColor: '#e85e6c',
      },
      TOTAL_TIP: {
        backgroundColor: '#717171',
        borderColor: '#717171',
      },
      NUMBER_OF_PAYMENTS: {
        backgroundColor: '#e2e2e2',
        borderColor: '#e2e2e2',
      },
    },
    ONE_MORE_QUESTION: {
      primaryColor: '#e85e6c',
      secondaryColor: '#717171',
      accentColor: '#E2E2E2',
    },
    SCORE_DISTRIBUTION: {
      backgroundColor: '#e85e6c',
    },
    CAMPAIGNS: {
      primaryColor: '#e85e6c',
      secondaryColor: '#717171',
      accentColor: '#E2E2E2',
      CHART_TRENDLINE: {
        backgroundColor: '#b3b3b3',
      },
    },
    LANGUAGE_DISTRIBUTION: {
      'EN-GB': '#2b59a2',
      'NB-NO': '#e85e6c',
      'SV-SE': '#f4e666',
      'DE-DE': '#d29af9',
      'ZH-HANS': '#5be52d',
      'PT-BR': '#6de0f2',
    },
    GENDER_DISTRIBUTION: {
      MALE: '#95d8eb',
      FEMALE: '#a997c4',
      UNKNOWN: '#c6c6c6',
      OTHER: '#7f6e8f',
    },
    CATEGORY_QUESTIONS: '#fff',
    GOAL: '#e85e6c',
  };

  static CHECKPOINTS = {
    WIDGETS: {
      BIG_NUMBERS: 999,
      LARGE_NUMBERS: 9999,
    },
  };

  static CONTEXT = {
    ACCOUNT: 'account',
    ACCOUNT_LIST: 'accountList',
    ACCOUNT_USERS: 'accountUsers',
    ACCOUNT_USERS_LIST: 'accountUserList',
    ACCOUNT_STATS: 'accountStats',
    ADMIN: 'admin',
    ADMIN_LIST: 'adminList',
    ADMIN_USERS: 'adminUsers',
    ADMIN_USERS_LIST: 'adminUserList',
    STATS: 'stats',
    USERS: 'users',
    VENUE: 'venue',
    VENUE_LIST: 'venueList',
    VENUE_USERS: 'venueUsers',
    VENUE_USERS_LIST: 'venueUserList',
    VENUE_STATS: 'venueStats',
    RESTAURANT: 'restaurant',
  };

  static COUNTRIES = {
    // @todo when the list grows, we should move with some stuff to use contry-data package
    ALFA3_CODES: {
      NORWAY: 'NOR',
      SWEDEN: 'SWE',
      DENMARK: 'DNK',
      FINLAND: 'FIN',
      SPAIN: 'SPA',
      FRANCE: 'FRA',
      GREAT_BRITAIN: 'GBR',
      UNITED_STATES: 'USA',
      GERMANY: 'DEU',
      CHINA: 'CHN',
      PORTUGAL: 'PRT',
    },
    ALFA3_TO_LANGUAGE: {
      NOR: 'nb-NO',
      SWE: 'sv-SE',
      EN: 'en-GB',
      DNK: 'da-DK',
      GE: 'de-DE',
      ES: 'es-ES',
      FI: 'fi-FI',
      FR: 'fr-FR',
      PT: 'pt-BR',
      SE: 'sv-SE',
      ZH: 'zh-HANS',
      GBR: 'en-GB',
    },

    PLACEHOLDERS: {
      NORWAY: {
        ZIP: '4004',
        CITY: 'Stavanger',
        BANK_ACCOUNT: 'eg. 3250 12 34567',
        PHONE: '972 417 920',
      },
      SWEDEN: {
        ZIP: '114 55',
        CITY: 'Stockholm',
        BANK_ACCOUNT: '',
        PHONE: '123456-7890',
      },
      DENMARK: {
        ZIP: '1145',
        CITY: 'Copenhagen',
        BANK_ACCOUNT: '',
        PHONE: '36729929',
      },
      GREAT_BRITAIN: {
        ZIP: 'W2 2SZ',
        CITY: 'London',
        BANK_ACCOUNT: 'eg. 3250 12 34567',
        PHONE: '07911 123456',
      },
      FRANCE: {
        ZIP: '75000',
        CITY: 'Paris',
        BANK_ACCOUNT: 'eg. 3250 12 34567',
        PHONE: '+33-655-5131-51',
      },
      UNITED_STATES: {
        ZIP: '10001',
        CITY: 'New York',
        BANK_ACCOUNT: 'eg. 3250 12 34567',
        PHONE: '202-555-0171',
      },
      GERMANY: {
        ZIP: '25952',
        CITY: 'Norddorf',
        BANK_ACCOUNT: 'eg. 3250 12 34567',
        PHONE: '04682 35 12 82',
      },
      SPAIN: {
        ZIP: '23750',
        CITY: 'Arjonilla',
        BANK_ACCOUNT: 'eg. 3250 12 34567',
        PHONE: '792 056 013',
      },
      FINLAND: {
        ZIP: '28370',
        CITY: 'Pori',
        BANK_ACCOUNT: 'eg. 3250 12 34567',
        PHONE: '050 491 7612',
      },
      CHINA: {
        ZIP: '242300',
        CITY: '宣城市',
        BANK_ACCOUNT: 'eg. 3250 12 34567',
        PHONE: '13151176857',
      },
      PORTUGAL: {
        ZIP: '8600-339',
        CITY: 'Lisbon',
        BANK_ACCOUNT: 'eg. 3250 12 34567',
        PHONE: '21 289 938 6703',
      },
    },
  };

  static CSS_PREFIX_CLASSES = {
    PROGRESS: 'progress-',
    PROGRESS_BAR: 'progress-bar-',
    FB_TEXT: 'fb-text-',
  };

  static CSS_POSTFIX_CLASSES = {
    SUCCESS: 'success',
    WARNING: 'warning',
    DANGER: 'danger',
  };

  // @todo add better handling for this when there are other locales in the system as well
  static CURRENCIES = {
    DEFAULT: 'kr',
  };

  static DASHBOARD = {
    // eslint-disable-next-line no-magic-numbers
    MIN_DATE_FOR_RANGE: new Date(2017, 0, 1),
    DEFAULT_LAST_X_DAYS_COUNT: 28,
    DEFAULT_SETTLEMENT_TIME: '00:00',
    LAST_X_DAYS: {
      RESPONSE_RATE: 28,
      OVERALL_EXPERIENCE: 28,
      AVERAGE_RATINGS: 28,
      FEATURED_FOODBACKS: 14,
      TOP_LOW_PERFORMING_QUESTIONS: 28,
      FOODBACKS_COUNT_MIN: 14,
      FOODBACKS_COUNT_MAX: 28,
      DAYS_WITH_GOAL_ACHIEVED: 8,
    },
    LAST_X_WEEKS: {
      UNHAPPY_CUSTOMERS: 4,
    },
    FEATURED_FOODBACKS_COUNT: 5,
  };

  static DATES_FORMAT = {
    DATE: 'DD.MM.YY', // 14.03.17
    DATE_FULL: 'DD.MM.YYYY',
    DATE_TIME: 'DD.MM.YY - HH:mm', // 14.03.17 - 12:00
    DATE_TIME_FULL_YEAR: 'DD.MM.YYYY - HH:mm', // 14.03.17 - 12:00
    DATE_TIME_NO_YEAR: 'DD.MM - HH:mm', // 14.03 - 12:00
    QUERY_PARAM_DATE: 'YYYY-MM-DD', // 2017-14-03
    DATE_PICKER_INPUT: 'dd.mm.yyyy', // 14.03.2017
    SHORT_WEEK_DAYS: 'ddd', // Tue
    FULL_WEEK_DAYS: 'dddd', // Tuesday
    EVEN_SHORTER_WEEK_DAYS: 'dd', // Tu
    WEEK_NUMBER: 'W', // 12,
    ACTIVITY: 'ddd DD.MM', // Tue 12.03
    HOURS_FULL: 'HH:mm:ss',
    HOURS: 'HH:mm',
  };

  static DATE_TIME_VALUES = {
    MIDNIGHT: 'T00:00:00.000',
  };

  static EMPTY_LIST_TYPE = {
    ACCOUNT_USER_LIST: 'ACCOUNT_USER_LIST',
    ACCOUNT_VENUE_LIST: 'ACCOUNT_VENUE_LIST',
    ACCOUNT_VENUE_LIST_NO_PERMISSIONS: 'ACCOUNT_VENUE_LIST_NO_PERMISSIONS',
    ADMIN_ACCOUNT_LIST: 'ADMIN_ACCOUNT_LIST',
    ADMIN_USER_LIST: 'ADMIN_USER_LIST',
    ALL_FOODBACKS_LIST: 'ALL_FOODBACKS_LIST',
    VENUE_NOTIFICATIONS_LIST: 'VENUE_NOTIFICATIONS_LIST',
    VENUE_USER_LIST: 'VENUE_USER_LIST',
    SCOREBOARD_LIST: 'SCOREBOARD_LIST',
    ADMIN_DYNAMIC_QR_LIST: 'ADMIN_DYNAMIC_QR_LIST',
    ACCOUNT_ONE_MORE_QUESTION_LIST: 'ACCOUNT_ONE_MORE_QUESTION_LIST',
    VENUE_ONE_MORE_QUESTION_LIST: 'VENUE_ONE_MORE_QUESTION_LIST',
    ACCOUNT_CAMPAIGN_LIST: 'ACCOUNT_CAMPAIGN_LIST',
    VENUE_CAMPAIGN_LIST: 'VENUE_CAMPAIGN_LIST',
    ACCOUNT_CAMPAIGN_ANALYTICS_LIST: 'ACCOUNT_CAMPAIGN_ANALYTICS_LIST',
    VENUE_CAMPAIGN_ANALYTICS_LIST: 'VENUE_CAMPAIGN_ANALYTICS_LIST',
    TOP_LEVEL_DASHBOARD_LIST: 'TOP_LEVEL_DASHBOARD_LIST',
  };

  static ENDPOINTS = {
    ACCOUNTS: 'accounts',
    ACTIVATION_CODE: 'deviceActivationCode',
    ADVERTISEMENT: 'advertisement',
    ANALYTICS: 'analytics',
    ASSIGNMENTS: 'assignments',
    BRANDING: 'branding',
    CATEGORY: 'category',
    CATEGORY_AVERAGES: 'categoryAverages',
    CONFIGURATION: 'configuration',
    COUNTRIES: 'countries',
    DEMOGRAPHICS: 'demographics',
    DEMOGRAPHICS_CONFIG: 'demographicsConfiguration',
    DISTRIBUTIONS: 'distributions',
    FEATURED_FOODBACKS: 'featuredFoodbacks',
    FOODBACKS: 'foodbacks',
    FOODBACK_SURVEYS: 'foodbackSurveys',
    LANGUAGES: 'languages',
    TURN_ON_LANGUAGES: 'turnOnLanguage',
    METADATA: 'authorizedUserData',
    ORDER: 'order',
    PERFORMANCE: 'performance',
    PERMISSIONS_LIST: 'permissionsList',
    QUESTIONS: 'questions',
    RESET_PIN: 'resetPin',
    SCORES: 'scores',
    STATEMENTS: 'statements',
    STATISTICS: 'statistics',
    STATISTICS_FOR_EACH_VENUE: 'statisticsForEachVenue',
    STATUS: 'status',
    SUMMARY: 'summary',
    SURVEYS: 'surveys',
    TRENDLINE: 'trendLine',
    UNHAPPY_CUSTOMER: 'unhappyCustomer',
    USERS: 'users',
    VENUE: 'venues',
    VENUE_PERMISSIONS_LIST: 'venuesPermissionsList',
    VENUES_STATISTICS: 'venuesStatistics',
    WHEEL_OF_FORTUNE: 'wheelConfiguration',
    REWARDS: 'rewards',
    FIRST_FOODBACK_DATE: 'firstFoodbackDate',
    VENUES_GROUPS_STATISTICS: 'venueGroupsStatistics',
    FOODBACK_PORTAL: 'foodback-portal',
    QR_LANDING: 'qr-landing',
    SCOREBOARD: 'venue-scoreboard',
    TRANSLATION_COVERAGE: 'translation-coverage',
    ONE_MORE_QUESTION: 'one-more-question',
    CAMPAIGN: 'campaign',
    QUESTION: 'question',
    SPREADSHEET: 'spreadsheet',
  };

  static ERRORS = {
    NOT_FOR_ACCOUNT: 'This feature should be reached only from Venue context.',
    NOT_FOR_VENUE: 'This feature should be reached only from Account context.',
  };

  static FOODBACK = {
    MAIN_COLOR: '#e85e6c',
    MID_RANK: 4,
    RANK_SCALE: 6,
    RANK_SCALE_LOW: 1,
    DEFAULT_GOAL: 5.2,
  };

  static IMAGES = {
    ADVERTISEMENT_HEIGHT: 1200,
    ADVERTISEMENT_WIDTH: 1200,
    BRANDING_MIN_HEIGHT: 288,
    BRANDING_MIN_WIDTH: 800,
    MAX_SIZE_BRANDING: 1000000,
    MAX_SIZE_ADVERTISEMENT: 100000000,
    TYPES: ['image/jpeg', 'image/png', 'image/gif'],
    MIN_ASPECT_RADIO: 1.5,
  };

  static LOGIN = {
    SIGN_IN: '/iam/auth/signin',
  };

  static MENU = {
    STATISTICS: 'menuItems',
    SETTINGS: 'settingsMenuItems',
  };

  static PAGINATION_DEFAULTS = {
    MAX_PAGES: 5,
    ITEMS_PER_PAGE: 50,
    QUESTION_DETAILS: {
      MAX_PAGES: 5,
      ITEMS_PER_PAGE: 50,
    },
  };

  static PAGE_DEFAULTS = {
    MAX_NAME_LENGTH: 50,
    MAX_SUBTITLE_LENGTH: 100,
    MAX_USER_NAME: 20,
    GROUPS_NAME_LENGTH: 20,
    SHORT_ANSWER_LENGTH: 100,
    MAX_TITLE_NAME: 25,
    SHORT_USER_NAME: 15,
    MAX_NAME_TOOLTIP: 32
  };

  static NUMBER_PIPE = {
    DEFAULT_FORMAT: '1.1-1',
    CURRENCY: '1.0-2',
    PERCENT: '1.0-0',
    INTEGERS: '1.0-0',
  };

  static RESPONSIVENESS = {
    SIDEBAR_BREAKPOINT: 768,
  };

  static ROLES = {
    ADMIN: 'foodback_admin',
    LIST: 'role_list',
    USER_ADMIN: 'Admin',
    USER_REGULAR: 'Regular',
  };

  static ROUTES = {
    ACCOUNT: 'account',
    ACCOUNT_MANAGEMENT: 'account-management',
    ADD: 'add',
    ALL_FOODBACKS: 'foodbacks/all-foodbacks',
    ADMIN: 'admin',
    ADVERTISEMENT: 'advertisement',
    BRANDING: 'branding',
    CATEGORY: 'category',
    CREATE: 'add',
    CONFIGURATION: 'configuration',
    DASHBOARD: 'dashboard',
    DEMOGRAPHICS: 'demographics',
    ACCOUNT_DETAILS: 'account-details',
    VENUE_DETAILS: 'venue-details',
    EDIT: 'edit',
    FOLDER: 'bill-folders',
    FOODBACK_QUESTIONS: 'foodback-questions',
    GOALS: 'goals',
    GROUPS: 'groups',
    HOME: 'home',
    LIST: 'list',
    LOGIN: 'login',
    NO_ACCESS: 'no-access',
    NOT_FOUND: 'page-not-found',
    NOTIFICATIONS: 'live-notifications',
    OVERVIEW: 'foodbacks/overview',
    PROFILE: 'edit-my-profile',
    REGIONAL_SETTINGS: 'regional-settings',
    QUESTION: 'question',
    SETTINGS: 'settings',
    SCOREBOARD: 'venue-scoreboard',
    SURVEY: 'foodback-statistics',
    USERS: 'user-management',
    VENUE: 'venue',
    VENUES: 'venue-management',
    WHEEL_OF_FORTUNE: 'rewards',
    QR_URLS: 'qr-urls',
    WINNER_STATISTIC: 'rewards',
    DYNAMIC_QR: 'dynamic-qr',
    QR_LANDING: 'qr-landing',
    FOODBACK_PORTAL: 'foodback-portal',
    ONE_MORE_QUESTION: 'one-more-question',
    CAMPAIGNS: 'campaigns',
    TOP_LEVEL_DASHBOARD: 'top-level-dashboard',
    REPORT_ARCHIVE: 'archive-reports'
  };

  static ROUTE_PARAMS = {
    ACCOUNT_ID: 'accountId',
    ACCOUNT_UUID: 'accountUuid',
    BOARDING_TOKEN: 'boardingToken',
    CATEGORY_ID: 'categoryId',
    NOTIFICATION_ID: 'notificationId',
    QUESTION_ID: 'questionId',
    VENUE_ID: 'venueId',
    CAMPAIGN_ID: 'campaignId',
  };

  static PREFIXES = {
    NOR: '+47',
    SWE: '+46',
    DNK: '+45',
    GBR: '+44',
  };

  static RATING_COLORS = {
    BAD: '#d50000',
    GOOD: '#009624',
    OKAY: '#ff8800',
  };

  static SESSION = {
    CONTEXT: 'contextObject',
    FALLBACK_CONTEXT: 'fallbackContext',
    USER_DATA: 'userData',
    LOGIN_DATA: 'loginData',
    LAST_ACTIVITY: 'l_a_s',
    CHILDREN_CONTEXT: 'childrencontext'
  };

  static SIDEBAR = {
    ADMIN: true,
    COLLAPSED: true,
    EXPANDED: false,
    NOADMIN: false,
    MOBILE_HIDE: false,
    MOBILE_SHOW: true,
  };

  static STORAGE = {
    ROLES: 'role_list',
  };

  static TIPS = {
    NUMBER_OF_STEPS: 9,
    STEP_SIZE: 2.5,
    BACKEND_TIP_MULTIPLIER: 10,
  };

  static USER_ACTIONS = {
    CREATE: 'create',
    EDIT: 'edit',
  };

  static VALIDATION = {
    ADDRESS_MIN_LENGTH: 4,
    COMPANY_NUMBER_LENGTH: 9,
    MAX_LENGTH: 255,
    MAX_CODE_LENGTH: 20,
    MIN_LENGTH: 2,
    MAX_STATEMENT_LENGTH: 55,
    POSTAL_CODE_LENGTH: 4,
    PHONE_NUMBER_LENGTH: 8,
    SSN_LENGTH: 11,
    NOTIFICATIONS: {
      NAME_MAX_LENGTH: 50,
      NAME_MIN_LENGTH: 2,
      TRIGGERS_MIN_LENGTH: 1,
      RECIPIENTS_MIN_LENGTH: 1,
    },
    PRIZE: {
      TEXT_MAX_LENGTH: 50,
      BAR_CODE_MAX_LENGTH: 13,
      BAR_CODE_MIN_LENGTH: 13,
    },
    DYNAMIC_QR: {
      VENUES_SCHEDULED_MIN_LENGTH: 1,
    },
    CATEGORY_NAME: {
      MIN_LENGTH: 1,
      MAX_LENGTH: 30,
    },
    ONE_MORE_QUESTION: {
      QUESTION_MAX_LENGTH: 100,
      FORMAT_OPTIONS_LENGTH: 35,
      FORMAT_OPTION_COUNT: 8,
      WHEN_TO_ASK_MAX_WEEKS: 52,
      WHEN_TO_ASK_MAX_MONTHS: 12,
      SCALE_GOAL: 6,
    },
    CAMPAIGNS: {
      QUESTION_MAX_LENGTH: 100,
      DESCRIPTION_MAX_LENGTH: 200,
      FORMAT_OPTIONS_LENGTH: 35,
      DEFAULT_OPTION_COUNT: 2,
      MAX_OPTION_COUNT: 8,
      SCALE_GOAL: 6,
    },
    CONTACT: {
      NOTE_MAX_LENGTH: 255,
    }
  };

  static FOODBACK_ADMIN_STATUSES = {
    PILOT: 'Pilot',
    PAYING: 'Paying',
    TEST_ACCOUNT: 'Test Account',
  };

  static ADMIN_DYNAMIC_QR_FIELDS = {
    NAME: 'name',
    OWNER_NAME: 'ownerName',
    URL: 'url',
    TIME_SPANS: 'timeSpans',
    CREATED: 'createdAt',
    ACTIVE: 'isActive',
  };

  static ADMIN_ACCOUNTS_LIST_FIELDS = {
    INDEX: 'index',
    BRAND_NAME: 'brandName',
    LEGAL_NAME: 'companyName',
    STATUS: 'accountInternalStatus',
    ACTIVE: 'isEnabled',
    VENUE_TYPE: 'venueTypeName',
    IS_SINGLE: 'multiSingle',
    VENUES_COUNT: 'venuesCount',
    TOP_LEVEL_DASHBOARD: 'isTopLevelDashboardEnabled',
  };

  static VENUE_MANAGEMENT_LIST_FIELDS = {
    INDEX: 'index',
    BRAND_NAME: 'brandName',
    DEMOGRAPHICS: 'isDemographicsActive',
    REWARDS: 'isRewardActive',
    PORTAL: 'isQrLandingActive',
    LAST_FOODBACK: 'lastActivity',
    LAST_USER_ACTIVITY: 'lastActiveUserTime',
    IS_ACTIVE: 'isEnabled',
    GROUPS: 'inRange',
  };

  static TIME_IN_MS = {
    TIME_10: 10,
    TIME_100: 100,
    TIME_200: 200,
    TIME_250: 250,
    TIME_500: 500,
    TIME_1000: 1000,
    TIME_3000: 3000,
  };

  static DEBOUNCE_IN_SECOND = {
    TIME_1000: 1000,
    TIME_200: 200,
  };

  static REQUEST_ERRORS = {
    HTTP_400: 400,
    HTTP_401: 401,
    HTTP_402: 402,
    HTTP_403: 403,
    HTTP_404: 404,
    HTTP_409: 409,
    HTTP_410: 410,
    HTTP_412: 412,
    HTTP_429: 429,
    HTTP_500: 500,
  };

  static VALIDATION_STATUS_ERROR = {
    TIME_OVERLAPPING: 4400,
    INVALID_PHONE_NUMBER: 5400,
    ATTEMPTS_LIMIT_REACHED: 6400,
    DISABLED_VENUE: 2403
  };

  static RESOLUTIONS_BREAKPOINTS = {
    XS: 0,
    SM: 900,
    XL: 1374,
    XXL: 1500,
  };

  static KEY_CODES = {
    KEY_13: 13,
    KEY_27: 27,
    KEY_40: 40,
    KEY_38: 38,
    KEY_32: 32,
  };

  static LOCAL_STORAGE_KEYS = {
    SELECTED_TIME_FRAME: 'sel_tf',
    SELECTED_DATE_RANGE: 'sel_dr',
    SELECTED_TIME_FRAME_HASH: 'sel_tf_h',
    SELECTED_DATE_RANGE_HASH: 'sel_dr_h',
    SELECTED_GROUP: 'sel_gr',
    SELECTED_GROUP_HASH: 'sel_gr_h',
    SELECTED_VENUES: 'sel_v',
    SELECTED_VENUES_HASH: 'sel_v_h',
    FILTERED_VENUES: 'f_v',
    FILTERED_VENUES_HASH: 'f_v_h',
    CONTEXT_UUID: 'ctx_uuid',
    CONTEXT_TYPE: 'ctx_type',
    COMMENT: 'cmt_v',
    DEMOGRAPHICS: 'dmg',
    DEMOGRAPHICS_HASH: 'dmg_h',
    CONTACT_STAUTS: 'cs',
    CONTACT_STAUTS_H: 'cs_h',
    CHILDREN_CONTEXT: 'childrencontext',
  };

  static SELECT_ITEM_NAMES = {
    ALL: 'all',
  };

  static GROUPS = {
    NO_GROUPS_UUID: 'no_groups',
    NO_CATEGORY_UUID: 'no_cats',
  };

  static FILTER = {
    MAX_HEADER_CHARACTERS: 30,
  };

  static URLS = {
    HELP_CENTER:
      'http://help.foodback.com/en/articles/5789342-one-more-question',
  };

  static INTERNAL = {
    MINUTES_30: 30,
  };

  static ELEMENTS_HEIGHT = {
    PX_70: 70,
  };

  static TEXT_EDITOR = {
    SETTINGS: {
      toolbar: [
        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['clean'], // remove formatting button
        ['link'], // link and image, video
      ],
    },
    FORMATS: [
      'bold',
      'code',
      'italic',
      'link',
      'size',
      'strike',
      'underline',
      'list',
      'direction',
      'code-block',
      // 'image'
      // 'video'
    ],
    MAX_CHARACTERS: 1000,
    DISABLE_CLASS: 'editorDisabled',
  };
  static ACCOUNT_CHILDREN_NAME: {
    SINGLE: [
      VENUE: [
        EN_GB: 'Venue',
        DE_DE: 'Venue',
        ES_ES: 'Venue',
        FR_FR: 'Venue',
        IT_IT: 'Venue',
      ],
      ROOMS: [
        EN_GB: 'Room',
        DE_DE: 'Room',
        ES_ES: 'Room',
        FR_FR: 'Room',
        IT_IT: 'Room',
      ],
      PRODUCT: [
        EN_GB: 'Product',
        DE_DE: 'Product',
        ES_ES: 'Product',
        FR_FR: 'Product',
        IT_IT: 'Product',
      ]
    ],
    PLURAL: [
      VENUE: [
        EN_GB: 'Venues',
        DE_DE: 'Venues',
        ES_ES: 'Venues',
        FR_FR: 'Venues',
        IT_IT: 'Venues'
      ],
      ROOM: [
        EN_GB: 'Rooms',
        DE_DE: 'Rooms',
        ES_ES: 'Rooms',
        FR_FR: 'Rooms',
        IT_IT: 'Rooms',
      ],
      PRODUCT: [
        EN_GB: 'Products',
        DE_DE: 'Products',
        ES_ES: 'Products',
        FR_FR: 'Products',
        IT_IT: 'Products',
      ]
    ],
  }
}
