import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { GuardsUtilsService } from '../../core/providers/context-guards/guards-utils.service';
import { LayoutUtil } from '../../core/utils';

@Injectable()
export class TopLevelDashboardGuard implements CanActivate {
  constructor(
    private guardsUtils: GuardsUtilsService,
    private layoutUtil: LayoutUtil
  ) {}

  canActivate(): Observable<boolean> {
    this.layoutUtil.showTopLevelDashboardMode();

    return this.guardsUtils.ensureFallbackContextIsSetIfNoCurrentContext$();
  }
}
