import { CONSTANTS } from '../../../../shared/constants';

export const ACCOUNT_VENUES_ROUTES_PATHS_PARTIALS = {
  BASE_PATH: CONSTANTS.ROUTES.VENUES,
  CREATE: CONSTANTS.ROUTES.CREATE,
};
export const ACCOUNT_VENUES_ROUTES_PATHS_FULL = {
  CREATE: `${ACCOUNT_VENUES_ROUTES_PATHS_PARTIALS.BASE_PATH}/${ACCOUNT_VENUES_ROUTES_PATHS_PARTIALS.CREATE}`,
  LIST: `${ACCOUNT_VENUES_ROUTES_PATHS_PARTIALS.BASE_PATH}`,
};
