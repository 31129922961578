import { Pipe, PipeTransform } from '@angular/core';
import { get, sortBy } from 'lodash';

@Pipe({
  name: 'ascending',
})
export class AscendingPipe implements PipeTransform {
  transform<T>(value: T[], arg?: string): T[] {
    return sortBy<T>(value, (element: T) => {
      const val = get(element, arg.split('.'), '');

      return val ? val.toString().toLowerCase() : '';
    });
  }
}
