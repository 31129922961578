// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function Debounce(delay = 300): MethodDecorator {
  // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
  return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {
    let timeout = null;

    const original = descriptor.value;

    descriptor.value = function (...args) {
      clearTimeout(timeout);
      // eslint-disable-next-line no-invalid-this
      timeout = setTimeout(() => original.apply(this, args), delay);
    };

    return descriptor;
  };
}
