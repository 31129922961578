import {
  Directive,
  DoCheck,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  forwardRef,
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import {
  Observable,
  Subject,
  Subscription,
  combineLatest as observableCombineLatest,
} from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { ErrorDetails, ErrorOptions } from './error.interface';
import { ErrorsDirective } from './errors.directive';
import { toArray } from './utils/toArray';

@Directive({
  selector: '[appError]',
})
export class ErrorDirective implements OnInit, OnDestroy, DoCheck {
  @Input() set appError(value: ErrorOptions) {
    this.errorNames = toArray(value);
  }

  @Input() set when(value: ErrorOptions) {
    this.rules = toArray(value);
  }

  @Input() set whenNeighbor(value: ErrorOptions) {
    this.neighborRules = toArray(value);
  }

  @Input() errorRef;

  @Output() readonly onErrorHidden: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  @HostBinding('hidden')
  hidden = true;

  rules: string[] = [];

  errorNames: string[] = [];

  subscription: Subscription;

  _states$: Subject<string[]>;

  states$: Observable<string[]>;

  private neighborRules: string[] = [];

  constructor(
    @Inject(forwardRef(() => ErrorsDirective))
    private appErrors: ErrorsDirective
  ) {}

  ngOnInit() {
    this._states$ = new Subject<string[]>();
    this.states$ = this._states$.asObservable().pipe(distinctUntilChanged());
    const errors$ = this.appErrors.subject.pipe(
      filter(Boolean),
      // eslint-disable-next-line no-bitwise
      filter((obj: ErrorDetails) => !!~this.errorNames.indexOf(obj.errorName))
    );
    const states$ = this.states$
      .pipe
      // eslint-disable-next-line no-bitwise
      // map(state => this.rules.every(rule => !!~state.indexOf(rule)))
      ();

    this.subscription = observableCombineLatest([states$, errors$]).subscribe(
      ([state, err]) => {
        this.hidden = (!state || state && state.length === 0) || !err.control.hasError(err.errorName);

        if (this.errorRef) {
          this.errorRef.isHidden = this.hidden;

          if (err.control.errors && Object.keys(err.control.errors)) {
            const errorIndex: number = Object.keys(
              err.control.errors
            ).findIndex(name => name === err.errorName);

            this.errorRef.isLastVisibleError =
              Object.values(err.control.errors).length > errorIndex &&
              !Object.values(err.control.errors)[errorIndex + 1];
            // this.errorRef.isLastVisibleError =
            //   errorIndex + 1 === Object.keys(err.control.errors).length || this.isNextHidden(errorIndex, err.control);
          }
          if (
            err.control.errors &&
            Object.keys(err.control.errors).length > 0
          ) {
            this.onErrorHidden.emit(
              err.control.errors && Object.keys(err.control.errors).length > 1
            );
          }
        }
      }
    );
  }

  ngDoCheck() {
    this._states$.next(this.getFilteredRules(this.rules));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private getFilteredRules(rules: string[]): string[] {
    return rules.filter(
      (rule: string) => (this.appErrors.control as any)[rule]
    );
  }

  private isNextHidden(index: number, control: AbstractControl): boolean {
    if (Object.keys(control.errors).length > index + 1) {
      const nextElementState: boolean = this.neighborRules.every(
        (rule: string) =>
          !!~this.getFilteredRules(this.neighborRules).indexOf(rule)
      );
      const nextErrorName: string = Object.keys(control.errors)[index + 1];

      return !(nextElementState && control.hasError(nextErrorName));
    }

    return true;
  }
}
