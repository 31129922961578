<app-dialog>
  <app-dialog-title> {{ 'SHARED.COMPONENTS.PROFILE_AVATAR.CROPPER_DIALOG_HEADER' | translate }} </app-dialog-title>
  <app-dialog-content>
    <image-cropper #cropper class="imageCropper" [imageURL]="data.dataURL" [roundCropper]="true" (imageCropped)="imageCropped($event)">
    </image-cropper>
    <span *ngIf="croppedImage">
      <img
        [src]="croppedImage"
        class="img-circle imageCropper-preview"
        [width]="data.cropperSettings.croppedWidth"
        [height]="data.cropperSettings.croppedHeight" />
    </span>
  </app-dialog-content>
  <app-dialog-actions>
    <button
      type="button"
      class="btn btn-foodback"
      [attr.aria-label]="'SHARED.SYSTEM.SAVE_CHANGES' | translate"
      (click)="saveCroppedImage()">
      {{ 'SHARED.SYSTEM.SAVE_CHANGES' | translate }}
    </button>
  </app-dialog-actions>
</app-dialog>
