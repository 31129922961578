import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { CONSTANTS } from 'app/shared/constants';
import * as moment from 'moment';
import { DateGroupPickerValue } from '../../../shared/components/date-range-picker';
import { DateRangePickerService } from '../../../shared/components/date-range-picker/date-range-picker.service';
import { DateRange, DynamicDateRange, StaticDateRange } from '../../../shared/interfaces/date-range.interface';
import { DashboardQueryParams } from '../../interfaces/dashboard-query-params.interface';
import { UtilsService } from '../../utils/utils.service';
import { ChartCustomRangeService } from '../charts/chart-custom-range.service';
import { ChartStaticRangeService } from '../charts/chart-static-range.service';

@Injectable()
export class DashboardQueryParamsService {
  constructor(private dateRangePickerService: DateRangePickerService, private utilsService: UtilsService) {}

  getQueryParamsFromDateRange(dateRange: DateRange): Partial<DashboardQueryParams> {
    if (this.dateRangePickerService.assertDateRangeTypeDynamic(dateRange)) {
      return {
        dateFrom: this.utilsService.prepareDateForRequest(dateRange.start),
        dateTo: this.utilsService.prepareDateForRequest(dateRange.end),
        groupBy: dateRange.groupBy,
      };
    }

    return {
      staticFilterCount: dateRange.count,
      staticFilterPeriod: dateRange.period,
      groupBy: DateGroupPickerValue.STATIC,
    };
  }

  getDateRangeFromQueryParams(queryParams: DashboardQueryParams): DateRange {
    if (queryParams.groupBy === DateGroupPickerValue.STATIC) {
      return {
        period: queryParams.staticFilterPeriod,
        count: queryParams.staticFilterCount,
        groupBy: DateGroupPickerValue.STATIC,
      };
    }
    {
      const start = new Date(queryParams.dateFrom + CONSTANTS.DATE_TIME_VALUES.MIDNIGHT);
      const end = new Date(queryParams.dateTo + CONSTANTS.DATE_TIME_VALUES.MIDNIGHT);

      start.setHours(0, 0, 0);
      // eslint-disable-next-line no-magic-numbers
      end.setHours(23, 59, 59);

      return {
        start,
        end,
        groupBy: queryParams.groupBy || DateGroupPickerValue.MONTH,
      };
    }
  }

  isCompleteDashboardQueryParams(queryParams: Params): queryParams is DashboardQueryParams {
    return (
      (queryParams.dateFrom && queryParams.dateTo && queryParams.groupBy) ||
      (queryParams.staticFilterPeriod && queryParams.staticFilterCount)
    );
  }

  getNarrowedDateRange(day: Date, dateRange: DateRange): DynamicDateRange {
    switch (dateRange.groupBy) {
      case DateGroupPickerValue.YEAR:
        return {
          start: moment(day).startOf('month').toDate(),
          end: moment(day).endOf('month').toDate(),
          groupBy: DateGroupPickerValue.MONTH,
        };
      case DateGroupPickerValue.CUSTOM:
        return ChartCustomRangeService.getNarrowedDateRange(day, dateRange);
      case DateGroupPickerValue.STATIC:
        return ChartStaticRangeService.getNarrowedDateRange(day, dateRange as StaticDateRange);
      case DateGroupPickerValue.MONTH:
      case DateGroupPickerValue.WEEK: // falls through
      default: // falls through
        return {
          start: moment(day).startOf('day').toDate(),
          end: moment(day).endOf('day').toDate(),
          groupBy: DateGroupPickerValue.DAY,
        };
    }
  }
}
