import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { AdminDynamicQr, AdminDynamicQrListResponse } from '../../../shared/interfaces/admin-dynamic-qr.interface';
import { ApiResponse } from '../../../shared/interfaces/api-response.interface';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root',
})
export class DynamicQrService {
  private fbiPrefix = '/fbi/';
  private dynamicQrPath = `${environment.apiBaseUrl.handbook + this.fbiPrefix}account-qr-codes`;

  constructor(private apiService: ApiService) {}

  getDynamicQrs$(): Observable<AdminDynamicQrListResponse> {
    return this.apiService
      .get$(`${this.dynamicQrPath}/list`)
      .pipe(map((response: ApiResponse<AdminDynamicQrListResponse>) => response.content));
  }

  getDynamicQr$(dynamicQrUuid: string): Observable<AdminDynamicQr> {
    return this.apiService
      .get$(`${this.dynamicQrPath}/${dynamicQrUuid}`)
      .pipe(map((response: ApiResponse<AdminDynamicQr>) => response.content));
  }

  addDynamicQr$(dynamicQr: AdminDynamicQr): Observable<any> {
    return this.apiService.post$(this.dynamicQrPath, dynamicQr).pipe(map((response: ApiResponse<any>) => response.content));
  }

  editDynamicQr$(dynamicQrUuid: string, dynamicQr: AdminDynamicQr): Observable<any> {
    return this.apiService
      .put$(`${this.dynamicQrPath}/${dynamicQrUuid}`, dynamicQr)
      .pipe(map((response: ApiResponse<any>) => response.content));
  }

  changeStatusDynamicQr$(dynamicQrUuid: string, isActive: boolean): Observable<any> {
    return this.apiService
      .put$(`${this.dynamicQrPath}/${dynamicQrUuid}/status`, { isActive })
      .pipe(map((response: ApiResponse<any>) => response.content));
  }
}
