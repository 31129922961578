import { Injectable } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ACCOUNT_ROUTES_PATHS_FULL } from '../../../../components/account/account-routes-paths';
import { TopLowPerformingQuestionsBackendResponse } from '../../../../components/venue/dashboard/top-low-performing-questions/top-low-performing-questions.interface';
import { VENUE_ROUTES_PATHS_FULL } from '../../../../components/venue/venue-routes-paths';
import { FoodbackAccount } from '../../../../core/classes/account/account.class';
import { AnalyticsProvider } from '../../../../core/interfaces/analytics-payload.interface';
import { DashboardQueryParams } from '../../../../core/interfaces/dashboard-query-params.interface';
import { AccountAnalyticsBackendService } from '../../../../core/providers/backend/account-analytics-backend.service';
import { GoalsBackendService } from '../../../../core/providers/backend/goals-backend.service';
import { RouteBuilderService } from '../../../../core/providers/backend/route-builder.service';
import { VenueAnalyticsBackendService } from '../../../../core/providers/backend/venue-analytics-backend.service';
import { CategoriesUtils } from '../../../../core/utils/categories/categories.utils';
import { UtilsService } from '../../../../core/utils/utils.service';
import { CONSTANTS } from '../../../constants';
import {
  IVenueScores,
  VenueCategoryAverageEntry,
  VenueStatistics,
} from '../../../interfaces/analytics.interface';
import {
  DateRange,
  DynamicDateRange,
  StaticDateRange,
} from '../../../interfaces/date-range.interface';
import { IDropdownItem } from '../../../interfaces/dropdown/dropdown-item.interface';
import { GoalsResponseMapped } from '../../../interfaces/goals-backend.interface';
import { DateGroupPickerValue } from '../../date-range-picker';
import { DayByDayChartInput } from '../day-by-day-chart/day-by-day-chart.interface';
import { HourByHourChartInput } from '../hour-by-hour-chart/hour-by-hour-chart.interface';

@Injectable()
export class FoodbackOverviewService {
  readonly CONSTANTS: typeof CONSTANTS = CONSTANTS;
  readonly VENUE_ROUTES_PATHS_FULL: typeof VENUE_ROUTES_PATHS_FULL =
    VENUE_ROUTES_PATHS_FULL;
  readonly ACCOUNT_ROUTES_PATHS_FULL: typeof ACCOUNT_ROUTES_PATHS_FULL =
    ACCOUNT_ROUTES_PATHS_FULL;
  private account: FoodbackAccount;
  private dataProvider: AnalyticsProvider;
  // dependently on the context, it may be account o venue id
  private dataProviderUuid: string;
  private isVenueContext = false;
  private filteredVenueItemSet: IDropdownItem[];
  private selectedVenueUuids: string[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private accountAnalyticsBackendService: AccountAnalyticsBackendService,
    private venueAnalyticsBackendService: VenueAnalyticsBackendService,
    private goalsBackendService: GoalsBackendService,
    private routeBuilderService: RouteBuilderService,
    private utilsService: UtilsService
  ) {}

  isVenueContextOrSelectedVenue(): boolean {
    return this.isVenueContext;
  }

  setSelectedVenueUuids(uuids: string[]): void {
    this.selectedVenueUuids = uuids;
  }

  getDataProviderUuid(): string {
    return this.dataProviderUuid;
  }

  setDataProvider(isAccountContext: boolean, contextUuid: string): void {
    this.dataProvider = !isAccountContext
      ? this.venueAnalyticsBackendService
      : this.accountAnalyticsBackendService;
    this.dataProviderUuid = contextUuid;
    this.isVenueContext = !isAccountContext;
  }

  getOverallExperienceStatistics$(
    dateRange: DynamicDateRange
  ): Observable<VenueStatistics> {
    return this.isVenueContextOrSelectedVenue()
      ? this.getDataProvider().getStatistics$(this.dataProviderUuid, dateRange)
      : this.getDataProvider().getStatistics$(
          this.dataProviderUuid,
          dateRange,
          this.selectedVenueUuids
        );
  }

  getOverallExperienceScores$(
    dateRange: DynamicDateRange
  ): Observable<IVenueScores> {
    return this.isVenueContextOrSelectedVenue()
      ? this.getDataProvider().getOverallExperienceScores$(
          this.dataProviderUuid,
          this.getContextType(),
          dateRange
        )
      : this.getDataProvider().getOverallExperienceScores$(
          this.dataProviderUuid,
          this.getContextType(),
          dateRange,
          this.selectedVenueUuids
        );
  }

  getGoals$(
    uuid: string,
    isAccountContext: boolean
  ): Observable<GoalsResponseMapped> {
    return !isAccountContext
      ? this.goalsBackendService.getGoalsForVenue$(uuid)
      : this.goalsBackendService.getGoalsForAccount$(uuid);
  }

  getTopLowPerformingQuestions$(
    dateRange: DynamicDateRange
  ): Observable<TopLowPerformingQuestionsBackendResponse[]> {
    return this.isVenueContextOrSelectedVenue()
      ? this.venueAnalyticsBackendService.getVenueTopLowPerformingQuestions$(
          this.dataProviderUuid,
          dateRange
        )
      : this.accountAnalyticsBackendService.getAccountTopLowPerformingQuestions$(
          this.dataProviderUuid,
          dateRange,
          this.selectedVenueUuids
        );
  }

  getCategoryAverages$(
    dateRange: DynamicDateRange
  ): Observable<VenueCategoryAverageEntry[]> {
    return (
      this.isVenueContextOrSelectedVenue()
        ? this.venueAnalyticsBackendService.getCategoryAverages$(
            this.dataProviderUuid,
            dateRange
          )
        : this.accountAnalyticsBackendService.getCategoryAveragesForAccount$(
            this.dataProviderUuid,
            dateRange,
            this.selectedVenueUuids
          )
    ).pipe(
      map((categories: VenueCategoryAverageEntry[]) =>
        CategoriesUtils.filterCategoriesAveragesByAccount(
          this.account,
          categories
        )
      )
    );
  }

  getDayByDayOverallExperienceScores$(
    dateRange: DynamicDateRange
  ): Observable<DayByDayChartInput> {
    return this.isVenueContextOrSelectedVenue()
      ? this.getDataProvider().getDayByDayOverallExperienceScores$(
          this.dataProviderUuid,
          this.getContextType(),
          dateRange
        )
      : this.getDataProvider().getDayByDayOverallExperienceScores$(
          this.dataProviderUuid,
          this.getContextType(),
          dateRange,
          this.selectedVenueUuids
        );
  }

  getHourByHourOverallExperienceScores$(
    dateRange: DynamicDateRange
  ): Observable<HourByHourChartInput> {
    return this.isVenueContextOrSelectedVenue()
      ? this.getDataProvider().getHourByHourOverallExperienceScores$(
          this.dataProviderUuid,
          this.getContextType(),
          dateRange
        )
      : this.getDataProvider().getHourByHourOverallExperienceScores$(
          this.dataProviderUuid,
          this.getContextType(),
          dateRange,
          this.selectedVenueUuids
        );
  }

  getAccountScores$(
    dateRange: DynamicDateRange,
    accountUuid: string
  ): Observable<IVenueScores> {
    // dataProviderUuid may be an account id but also it may not. To be sure I'm getting the account here cause this is what I must have.
    return this.isVenueContextOrSelectedVenue()
      ? this.venueAnalyticsBackendService.getScores$(
          this.dataProviderUuid,
          dateRange
        )
      : this.accountAnalyticsBackendService.getScores$(
          accountUuid,
          dateRange,
          this.selectedVenueUuids
        );
  }

  getDataProvider(): AnalyticsProvider {
    return this.dataProvider;
  }

  getAllFoodbacksUrlWithComment(
    uuid: string,
    drillDownQueryParams: DashboardQueryParams
  ): string {
    return this.prepareUrlWithQueryParams(
      this.getAllFoodbacksUrl(uuid),
      this.getAllFoodbacksQueryParamsWithComments(drillDownQueryParams)
    );
  }

  getAllFoodbacksUrlWithEmail(
    uuid: string,
    drillDownQueryParams: DashboardQueryParams
  ): string {
    return this.prepareUrlWithQueryParams(
      this.getAllFoodbacksUrl(uuid),
      this.getAllFoodbacksQueryParamsWithEmail(drillDownQueryParams)
    );
  }

  getAllFoodbacksUrlWithParams(
    uuid: string,
    drillDownQueryParams: DashboardQueryParams
  ): string {
    return this.prepareUrlWithQueryParams(
      this.getAllFoodbacksUrl(uuid),
      drillDownQueryParams
    );
  }

  getAllFoodbacksUrlByContext(uuid: string): string {
    return this.isVenueContext
      ? this.getAllFoodbacksVenueUrl(uuid)
      : this.getAllFoodbacksAccountUrl(uuid);
  }

  getAllFoodbacksAccountUrl(uuid: string): string {
    return this.routeBuilderService.getUrlWithParams(
      this.ACCOUNT_ROUTES_PATHS_FULL.ALL_FOODBACKS,
      [this.CONSTANTS.ROUTE_PARAMS.ACCOUNT_ID, uuid]
    );
  }

  getAllFoodbacksVenueUrl(uuid: string): string {
    return this.routeBuilderService.getUrlWithParams(
      this.VENUE_ROUTES_PATHS_FULL.ALL_FOODBACKS,
      [this.CONSTANTS.ROUTE_PARAMS.VENUE_ID, uuid]
    );
  }

  getFoodbacksParamDateRange(dateRangeForChart: DynamicDateRange): Params {
    const dateFrom: string = this.utilsService.prepareDateForRequest(
      dateRangeForChart ? dateRangeForChart.start : new Date()
    );
    const dateTo: string = this.utilsService.prepareDateForRequest(
      dateRangeForChart ? dateRangeForChart.end : new Date()
    );

    return { dateFrom, dateTo };
  }

  setFilteredVenueItemSet(filteredVenueItemSet: IDropdownItem[]): void {
    this.filteredVenueItemSet = filteredVenueItemSet;
  }

  compareDateRange(
    firstDateRange: DateRange,
    secondDateRange: DateRange
  ): boolean {
    if (
      firstDateRange.groupBy === DateGroupPickerValue.STATIC &&
      secondDateRange.groupBy === DateGroupPickerValue.STATIC
    ) {
      return (
        (firstDateRange as StaticDateRange).count ===
        (secondDateRange as StaticDateRange).count
      );
    }

    return (
      (firstDateRange as DynamicDateRange).start.getTime() ===
        (secondDateRange as DynamicDateRange).start.getTime() &&
      (firstDateRange as DynamicDateRange).end.getTime() ===
        (secondDateRange as DynamicDateRange).end.getTime()
    );
  }

  setAccount(account: FoodbackAccount): void {
    this.account = account;
  }

  private getAllFoodbacksQueryParamsWithComments(
    drillDownQueryParams: DashboardQueryParams
  ): DashboardQueryParams {
    return {
      ...drillDownQueryParams,
      hasComment: 'true',
    };
  }

  private getAllFoodbacksQueryParamsWithEmail(
    drillDownQueryParams: DashboardQueryParams
  ): DashboardQueryParams {
    return {
      ...drillDownQueryParams,
      hasEmail: 'true',
    };
  }

  private getAllFoodbacksUrl(uuid: string): string {
    return uuid ? this.getAllFoodbacksUrlByContext(uuid) : null;
  }

  private prepareUrlWithQueryParams(
    url: string,
    queryParamsObject: any
  ): string {
    let queryParams = '';

    for (const key of Object.keys(queryParamsObject)) {
      if (queryParams !== '') {
        queryParams += '&';
      }
      if (typeof queryParamsObject[key] === 'string') {
        queryParams += `${key}=${encodeURIComponent(queryParamsObject[key])}`;
      } else {
        for (let i = 0; i < queryParamsObject[key].length; i++) {
          queryParams += `${key}=${encodeURIComponent(
            queryParamsObject[key][i]
          )}`;

          if (i < queryParamsObject[key].length) {
            queryParams += '&';
          }
        }
      }
    }

    return `${url}?${queryParams}`;
  }

  private getAllFoodbacksQueryParams(dateFrom: string, dateTo: string): any {
    return {
      dateFrom,
      dateTo,
      page: 1,
      column: 'lastModified',
      ascending: false,
    };
  }

  private getContextType(): string {
    return this.isVenueContext
      ? CONSTANTS.CONTEXT.VENUE
      : CONSTANTS.CONTEXT.ACCOUNT;
  }
}
