import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { RolesSwitcher } from '../roles-switcher';

@Component({
  selector: 'app-roles-switcher',
  templateUrl: './roles-switcher.component.html',
  styleUrls: ['./roles-switcher.component.scss'],
})
export class RolesSwitcherComponent {
  @Input() userRole: FormGroup;
  @Input() roles: RolesSwitcher[] = [
    {
      name: 'Admin',
      translationKey: 'SHARED.COMPONENTS.ROLES_SWITCHER.RADIO.ADMIN',
      value: 'Admin',
    },
    {
      name: 'Regular',
      translationKey: 'SHARED.COMPONENTS.ROLES_SWITCHER.RADIO.REGULAR',
      value: 'Regular',
    },
  ];
  @Input() set isDisabled(val: boolean) {
    if (val) {
      this.userRole.controls.role.disable();
    }
  }
}
