import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  IFusionChartCategory,
  IFusionChartData,
  IFusionChartDataSet,
} from '../../../../vendor/types/fusionCharts';
import { DashboardDatesService } from '../../../components/venue/venue-shared/dashboard-dates.service';
import { ChartUtilsService } from '../../../core/utils';
import { CONSTANTS } from '../../constants';
import { IQuestionTrendlineDataSet } from '../questions-stats/interfaces/questions-stats.interface';
import { ISmallTrendlineTopDashboardDataSetItem } from './small-trendline-top-dashboard.interface';
import { DashboardQueryParamsService } from 'app/core/providers/backend/dashboard-query-params.service';
import { ActivatedRoute } from '@angular/router';
import { DashboardQueryParams } from 'app/core/interfaces/dashboard-query-params.interface';
import * as moment from 'moment';
import { values } from 'lodash';

const defaultWidth = '80';
const defaultHeight = '60';

@Component({
  selector: 'app-small-trendline-top-dashboard',
  templateUrl: './small-trendline-top-dashboard.component.html',
  styleUrls: ['./small-trendline-top-dashboard.component.scss'],
})
export class SmallTrendlineTopDashboardComponent {
  date: any;
  trendlineData: any;
  todayDate = new Date();
  @Input() set chartData(values: ISmallTrendlineTopDashboardDataSetItem[]) {
    this.trendlineData = values ? values : [];
    if (values && !!this.startDate) {
      this.initChart(values);
    }
  }

  @Input() dateRange: any;
  @Input() startDate: any;
  @Input() endDate: any;
  @Input() showLabels = false;
  @Input() width = defaultWidth;
  @Input() height = defaultHeight;
  @Input() showLegend = true;


  ngOnChanges(changes) {
    if (changes.startDate && this.trendlineData) {
      this.initChart(this.trendlineData);
    }
  }

  dataSource;
  defaultHeight = defaultHeight;
  isDayChart: boolean = false;

  private chartCategories: IFusionChartCategory[] = [];

  constructor(
    private dashboardDatesService: DashboardDatesService,
    private chartUtilsService: ChartUtilsService,
    private translateService: TranslateService,
  ) {
  }

  private initChart(value: ISmallTrendlineTopDashboardDataSetItem[]): void {
    const answerChartData = this.getChartData(value);

    const chartDataSet: IFusionChartDataSet = value
      ? {
          seriesName: this.translateService.instant('SHARED.SYSTEM.ANSWERS'),
          renderAs: 'column',
          parentYAxis: 'S',
          color:
            CONSTANTS.CHART_COLORS.CAMPAIGNS.CHART_TRENDLINE.backgroundColor,
          data: answerChartData,
        }
      : {
          renderAs: 'splinearea',
          drawAnchors: false,
          plotBorderAlpha: 0,
          parentYAxis: 'S',
          color:
            CONSTANTS.CHART_COLORS.CAMPAIGNS.CHART_TRENDLINE.backgroundColor,
          data: answerChartData,
        };
    const dataset: IFusionChartDataSet[] = [chartDataSet];

    if (value) {
      dataset.push({
        seriesName: this.translateService.instant('SHARED.TOP_LEVEL_DASHBOARD.AVG_OVERALL_SCORE'),
        renderAs: 'spline',
        drawAnchors: false,
        plotBorderAlpha: 0,
        color: CONSTANTS.CHART_COLORS.CAMPAIGNS.primaryColor,
        data: this.getChartDataScore(value),
      });
    }

    this.initChartWithConfig();
    this.dataSource.dataset = dataset;
    this.dataSource.categories = [
      {
        category: this.chartCategories,
      },
    ];
  }

  private initChartWithConfig(): void {
    const defaultMargin = 20;
    const additionalOptions: any = {
      anchorRadius: 3,
      pYAxisMaxValue: 7.0,
      sYAxisMaxValue: 100,
      forceYAxisValueDecimals: 0,
      forceSYAxisValueDecimals: 0,
      forceTickValueDecimals: 0,
      tickValueDecimals: 0,
      setAdaptiveYMin: 0,
      showXAxisLine: false,
      showYAxisLine: false,
      showLabels: false,
      showToolTip: true,
      fontSize: 5,
      showYAxisValues: false,
      showDivLineValues: this.showLabels,
      showDivLineSecondaryValue: this.showLabels,
      showVLineLabelBorder: false,
      showPlotBorder: false,
      showcanvasborder: false,
      divLineAlpha: 0,
      chartLeftMargin: this.showLabels ? defaultMargin : 0,
      chartRightMargin: this.showLabels ? defaultMargin : 0,
      chartTopMargin: 0,
      chartBottomMargin: 0,
      legendPadding: 0,
      canvasPadding: 0,
      legendItemFontSize: 8,
      yAxisMinValue: 0,
      showLegend: this.showLegend,
      minimizeTendency: '1',
      decimals: 0,
      baseFontSize: 10
    };

    this.dataSource =
      this.chartUtilsService.initChartWithDataSetAndCategories(
        additionalOptions
      );
  }

  private removeDuplicates(data) {
    return data.filter((value, index) => data.indexOf(value) === index );
  }

  private getDateRangeArray() {
    return this.dashboardDatesService.getAllDates(
      this.startDate,
      this.endDate
    );
    
  }

  private formatDateRangeArray(values, dateRangeArray) {
    if (values && values[0] && values[0].date.length === 7) {
      dateRangeArray =  dateRangeArray.map((date) => {
        return moment(date).format('YYYY-MM')
      });
    
  } else if (values && values[0] && values[0].date.length === 10) {
    dateRangeArray =  dateRangeArray.map((date) => {
      return moment(date).format('YYYY-MM-DD')
    });

}

  return this.removeDuplicates(dateRangeArray);
}

  private getChartData(
    values: any[]
  ): IFusionChartData[] {
    var dateRangeArray: string[] = this.getDateRangeArray();

    if (dateRangeArray.length !== 1) {
      this.isDayChart = false;
      dateRangeArray = this.formatDateRangeArray(values, dateRangeArray);
    } else {
      this.isDayChart = true;
      dateRangeArray = this.chartUtilsService.getHoursForDay();
    }
    this.chartCategories = dateRangeArray.map((date: string) => ({
      label: date,
    }));
    return dateRangeArray.map((date: string) => {
      var valueAtDate;
      if (this.isDayChart) {
        valueAtDate = values.find(value => {
          var dateToCompareBeforeConvert =  moment.utc(value.date, 'yyyy-mm-dd hh:mm:ss a').format("hh:mm a");
          return this.convertTo24HourFormat(dateToCompareBeforeConvert) === date;})
      } else {
        valueAtDate = values.length > 0 && values.find(value => this.isDateEqual(value, date));
      }

      return {
        value: valueAtDate ? valueAtDate.foodbacks.toString() : '0',
        color: CONSTANTS.CHART_COLORS.CAMPAIGNS.CHART_TRENDLINE.backgroundColor,
        toolText: '<span style="font-size: 10px">' + this.chartUtilsService.getTooltipTextForItem() + '</span>',
      };
    });
  }

  private isDateEqual(value: any, date: any): boolean {
    if (value.date.length === 7) {
      return value.date === moment(date).format('YYYY-MM')
    } else if (value.date.length === 10) {
      return value.date === moment(date).format('YYYY-MM-DD')
    }
    return value.date === date
  }

  private getChartDataScore(
    values: any[]
  ): IFusionChartData[] {

    var dateRangeArray: string[] = this.getDateRangeArray();

    if (dateRangeArray.length !== 1) {
      this.isDayChart = false;
      dateRangeArray = this.formatDateRangeArray(values, dateRangeArray);
    } else {
      this.isDayChart = true;
      dateRangeArray = this.chartUtilsService.getHoursForDay();
    }
    this.chartCategories = dateRangeArray.map((date: string) => ({
      label: date,
    }));
    return dateRangeArray.map((date: string) => {
      var valueAtDate;
      if (this.isDayChart) {
        valueAtDate = values.find(value => {
          var dateToCompareBeforeConvert =  moment.utc(value.date, 'yyyy-mm-dd hh:mm:ss a').format("hh:mm a");
          return this.convertTo24HourFormat(dateToCompareBeforeConvert) === date;})
      } else {
        valueAtDate = values.length > 0 && values.find(value => this.isDateEqual(value, date));
      }

      return {
        value: valueAtDate ? valueAtDate.score.toString() : '0',
        color: CONSTANTS.CHART_COLORS.CAMPAIGNS.primaryColor,
        toolText: '<span style="font-size: 10px">' + this.chartUtilsService.getTooltipTextForItem() + '</span><br><span style="font-size: 10px">' + date + '</span>',
      };
    });
  }

  private convertTo24HourFormat(timeString) { 
    const [time, period] = timeString.split(' '); 
    const [hour, minute] = time.split(':'); 
    let formattedHour = hour; 
  
    if (period.toUpperCase() == 'PM' && hour != 12) { 
      formattedHour = parseInt(formattedHour) + 12;
    } else if (period.toUpperCase() == 'AM' && hour == 12) {
      formattedHour = '00';
    }
  
  
    return `${formattedHour}:${minute}`; 
  } 
}
