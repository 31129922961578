import { Component, OnDestroy, OnInit } from '@angular/core';
import { FileItem, FileUploader } from 'ng2-file-upload';
import { ImageUploadResponse } from '../profile-avatar/profile-avatar.interface';
import {
  InitialAdvertisementState
} from '../../../components/account/settings/acc-folder/initial-advertisement-state.interface';
import { MessageBarTypes } from '../message-bar/message-bar-types.enum';
import { NotificationService } from '../../../core/providers/notification/notification.service';
import { Observable, Subject } from 'rxjs';
import { UploadService } from '../../../core/providers/backend/upload.service';
import { first, mergeMap, switchMap, takeUntil } from 'rxjs/operators';
import { BillFolderService } from '../../../components/account/settings/account-shared/bill-folder.service';
import { FoodbackAccount } from '../../../core/classes/account/account.class';
import { IAdvertisement } from '../../../core/interfaces/backend/advertisement.interface';
import { BillFolderBackendService } from '../../../core/providers/backend/bill-folder-backend.service';
import { ContextService } from '../../../core/providers/context/context.service';
import { CONSTANTS } from '../../constants';
import { AdvertisementForBackend } from '../../interfaces/bill-folders';
import { ContextObject } from '../../interfaces/login-object';
import { AdvertisementCategories } from './advertisement-category-switcher/advertisement-categories.enum';
import { AdvertisementService } from './advertisement.service';
import { LocalStorageService } from 'ngx-webstorage';
import { TranslateService } from '@ngx-translate/core';

// @ts-ignore
@Component({
  selector: 'app-advert',
  templateUrl: './advertisement.component.html',
  styleUrls: ['./advertisement.component.scss'],
  providers: [AdvertisementService],
})
export class AdvertisementComponent implements OnInit, OnDestroy {
  advertisement: IAdvertisement;
  advertisementURL: string;
  advertisementURLon: boolean;
  account: FoodbackAccount = new FoodbackAccount(null);
  advertisementEnforcement: boolean;
  advertisementCategory: AdvertisementCategories = AdvertisementCategories.BILL_FOLDER;
  advertisementFileUuid: string = null;
  advertisementLastChange: string = null;
  avatarURL: string;
  context: ContextObject;
  hasBaseDropZoneOver = false;
  imageLoading = false;
  isPublishDisabled = true;
  uploader: FileUploader;
  initState: InitialAdvertisementState = {
    advertisementEnforcement: null,
    advert: null,
    avatarURL: '',
    url: '',
  };
  timezone: string;
  accountChildrenNameSingular: string;
  accountChildrenNamePlural: string;
  readonly messageBarTypes = MessageBarTypes;
  private onDestroy$ = new Subject<void>();

  constructor(
    private advertisementService: AdvertisementService,
    private uploadService: UploadService,
    private contextService: ContextService,
    private billFolderService: BillFolderService,
    private billFolderBackendService: BillFolderBackendService,
    private notificationService: NotificationService,
    private localStorage: LocalStorageService,
    private translateService: TranslateService
  ) {
  }

  setAdvertisementURL(url: string) {
    this.advertisementURL = url;

    if (url !== this.advertisement.advertisement.url) {
      this.enablePublishButton();
    }
  }

  setAdvertisementURLState(on: boolean) {
    this.advertisementURLon = on;
    this.enablePublishButton();
  }

  isBillFolderAdvertisementCategoryActive(): boolean {
    return this.advertisementCategory === AdvertisementCategories.BILL_FOLDER;
  }

  // TODO: In the future, a single venue may have an access to both: bill folder and take away
  switchAdvertisementCategory(switchItem: AdvertisementCategories) {
    this.advertisementCategory = switchItem;
    this.advertisementService.setAdvertisementImageRequirements();
  }

  isAdvertisementDisabled(): boolean {
    return this.isEnforcementVisible() ? !this.advertisementEnforcement : this.advertisementEnforcement;
  }

  isEnforcementVisible(): boolean {
    return ContextService.isAccountContext(this.context);
  }

  saveEnforcementState(state: boolean) {
    this.advertisementEnforcement = state;
    // TODO: Should be reset?
    // this.resetChanges();
    this.adjustPublishAccessibility(
      this.billFolderService.isAdvertisementStateChanged(this.initState, {
        advertisementEnforcement: this.advertisementEnforcement,
        advert: this.advertisementFileUuid,
      })
    );
  }

  fileOverBase(isOverBase: boolean) {
    this.hasBaseDropZoneOver = isOverBase;
  }

  backToDefault() {
    this.avatarURL = null;
    this.advertisementFileUuid = null;
    this.adjustPublishAccessibility(
      this.billFolderService.isAdvertisementStateChanged(this.initState, {
        advertisementEnforcement: this.advertisementEnforcement,
        advert: this.advertisementFileUuid,
      })
    );
  }

  publishAd() {
    this.displayConfirmPopup()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((value: boolean) => {
        if (value) {
          this.makePublishAdRequest();
        }
      });
  }

  onFileComplete(file: FileItem, response: string) {
    const parsedResponse = JSON.parse(response) as ImageUploadResponse;
    const imageUuid = parsedResponse.uuid;
    const validationResponse = this.uploadService.validateStrictMinimumResolution(
      parsedResponse.metadata,
      this.advertisementService.getAdvertisementImageRequirements()
    );

    if (validationResponse.isValid) {
      this.setAndPrepareAvatar(imageUuid);
    } else {
      this.uploadService.displayError(validationResponse.validationTitle, validationResponse.validationBodyMsg);
      this.adjustPublishAccessibility(
        this.billFolderService.isAdvertisementStateChanged(this.initState, {
          advertisementEnforcement: this.advertisementEnforcement,
          advert: this.advertisementFileUuid,
        })
      );
      this.imageLoading = false;
    }
  }

  onDisableSubmitButton(value: boolean) {
    this.isPublishDisabled = value;
  }

  ngOnInit() {
    this.getContext()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(context => {
        this.context = context;
        this.timezone = this.context.timezone;
      });
    this.setUploader();
    this.setAdvertisement();
    this.accountChildrenNameSingular = this.contextService.getAccountChildrenNameTranslation(true);
    this.accountChildrenNamePlural = this.contextService.getAccountChildrenNameTranslation(false);
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private setAndPrepareAvatar(imageUuid: string): void {
    this.billFolderBackendService
      .setFileResolutions(imageUuid, CONSTANTS.BRANDING_IMG_SIZE.ADV_WIDTH)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.advertisementFileUuid = imageUuid;
        this.avatarURL = this.billFolderBackendService.getFileUrl(imageUuid);
        this.imageLoading = false;
        this.enablePublishButton();
      });
  }

  private getContext(): Observable<ContextObject> {
    return this.contextService.getContext$();
  }

  private setUploader() {
    this.uploader = new FileUploader(this.uploadService.getUploaderOptions(CONSTANTS.IMAGES.MAX_SIZE_ADVERTISEMENT));

    this.uploader.onProgressAll = () => {
      this.imageLoading = true;
      this.disablePublishButton();
    };

    this.uploader.onWhenAddingFileFailed = () => {
      this.notificationService.errorWithTranslationKey('VENUE.FOLDER.ADVERTISEMENT.MODALS.INCORRECT_FILE_ADVERTISEMENT_ERROR');
    };

    this.uploader.onCompleteItem = (item: FileItem, response: string) => {
      this.onFileComplete(item, response);
    };
  }

  private setAdvertisement() {
    this.imageLoading = true;
    this.getContext()
      .pipe(
        first(),
        mergeMap(context => this.billFolderBackendService.getAdvertisementInfo$(context.type, context.uuid)),
        takeUntil(this.onDestroy$)
      )
      .subscribe(advertisement => {
        this.advertisement = advertisement;
        this.setAdvertisementLastChange(advertisement);
        this.advertisementEnforcement = advertisement.advertisementEnforcement;
        this.setAdvertisementURLState(!!advertisement.advertisement.url);
        this.setAdvertisementURL(advertisement.advertisement.url);
        this.setImageFromServer(advertisement.advertisement.advertisementFileUuid);
        this.imageLoading = false;
        this.saveLoadedData();
      });
  }

  private saveLoadedData() {
    this.initState = {
      advertisementEnforcement: this.advertisementEnforcement,
      advert: this.advertisementFileUuid,
      avatarURL: this.avatarURL,
      url: this.advertisement.advertisement.url,
    };
  }

  private displayResultPopup() {
    this.notificationService.successWithTranslationKey('ACCOUNT.FOLDER.ADVERTISEMENT.MODALS.RESULT');
  }

  private displayConfirmPopup(): Observable<boolean> {
    return this.translateService.get(this.getConfirmationMsg()).pipe(
      switchMap((DATA: { TITLE: string; TEXT: string; BUTTONS: string }) => {
        const { TITLE, TEXT, BUTTONS } = DATA;
        const interpolatedTitle: string = this.translateService.instant(TITLE);
        const interpolatedText: string = this.translateService.instant(TEXT).replace('{{ childrenName }}', this.accountChildrenNamePlural);

        return this.notificationService.displayConfirmationPopup(interpolatedTitle, interpolatedText, BUTTONS, 'warning');
      })
    );
  }

  private getConfirmationMsg() {
    if (this.context.type === CONSTANTS.CONTEXT.ACCOUNT) {
      return this.getConfirmationMsgForAccount();
    }

    return this.getConfirmationMsgForVenue();
  }

  private makePublishAdRequest() {
    const payload: AdvertisementForBackend = {
      advertisementFileUuid: this.advertisementFileUuid,
      advertisementEnforcement: this.advertisementEnforcement,
      mobile: !this.isBillFolderAdvertisementCategoryActive(),
      url: this.advertisementURLon ? this.advertisementURL : null,
    };

    this.advertisementService
      .updateAdvertisement(payload)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.disablePublishButton();
        this.displayResultPopup();
        this.saveLoadedData();
      });
    this.getContext()
      .pipe(
        first(),
        mergeMap(context => this.billFolderBackendService.getAdvertisementInfo$(context.type, context.uuid)),
        takeUntil(this.onDestroy$)
      )
      .subscribe(advertisement => {
        this.setAdvertisementLastChange(advertisement);
      });
  }

  private setAdvertisementLastChange(advertisement: IAdvertisement) {
    this.advertisementLastChange = null;

    if (advertisement.advertisement.advertisementFileUuid) {
      this.advertisementLastChange = advertisement.advertisement.advertisementLastChange;
    }
  }

  private enablePublishButton() {
    this.isPublishDisabled = false;
  }

  private disablePublishButton() {
    this.isPublishDisabled = true;
  }

  private setImageFromServer(imgUuid: string) {
    // eslint-disable-next-line
    if (imgUuid) {
      const url: string = this.billFolderBackendService.getFileUrl(imgUuid);

      this.initState.avatarURL = url || null;
      this.avatarURL = this.initState.avatarURL;
      this.advertisementFileUuid = imgUuid;
      this.saveLoadedData();
    }
  }

  private adjustPublishAccessibility(condition: boolean) {
    if (condition) {
      this.enablePublishButton();
    } else {
      this.disablePublishButton();
    }
  }

  private getConfirmationMsgForAccount() {
    return `ACCOUNT.FOLDER.ADVERTISEMENT.MODALS.${this.advertisementFileUuid ? 'CONFIRMATION_ACCOUNT' : 'CONFIRMATION_ACCOUNT_DEFAULT'}`;
  }

  private getConfirmationMsgForVenue() {
    return `ACCOUNT.FOLDER.ADVERTISEMENT.MODALS.${this.advertisementFileUuid ? 'CONFIRMATION_VENUE' : 'CONFIRMATION_VENUE_DEFAULT'}`;
  }
}
