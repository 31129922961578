<div class="bfheaderwrapper">
  <div #billFolderHeader id="header">
    <ul class="FolderNavigation empty-list-heading">
      <li
        *ngFor="let item of headerItems"
        [routerLink]="item.url | routerLink: [routeParam, uuid]"
        routerLinkActive="active-link"
        [routerLinkActiveOptions]="{ exact: true }"
        class="FolderNavigation-element">
        <a class="single-tab">
          <div class="single-tab-icon">
            <i *ngIf="item.icon" [ngClass]="item.icon" class="mdi"></i>
            <img class="custom-icon" *ngIf="!item.icon" src="/assets/svg/qrcode-scan.svg" alt="" />
            <img class="custom-icon-active" *ngIf="!item.icon" src="/assets/svg/qrcode-scan_active.svg" alt="" />
          </div>
          <p class="single-tab-text">{{ item.title | translate }}</p>
        </a>
      </li>
    </ul>
    <hr />
  </div>
</div>
