import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'app-main-logo',
  templateUrl: './main-logo.component.html',
  styleUrls: ['./main-logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainLogoComponent {
  @Input() isCollapsed = false;
  @Input() showTopLeveDashboardBtn = false;
  @Output() readonly onTopLevelDashClick: EventEmitter<void> =
    new EventEmitter<void>();

  reg = /\b(\w)/g;
}
