import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserService } from '../user/user.service';

@Injectable()
export class AuthorizedHttp {
  constructor(private http: HttpClient, private userService: UserService) {}

  get(url: string, options?: any): Observable<any> {
    options = options || {};
    options.headers = this.createAuthorizationHeader();

    return this.http.get(url, options);
  }

  post(url: string, body: any | null, options?: any): Observable<any> {
    options = options || {};
    options.headers = this.createAuthorizationHeader();

    return this.http.post(url, body, options);
  }

  put(url: string, body: any | null, options?: any): Observable<any> {
    options = options || {};
    options.headers = this.createAuthorizationHeader();

    return this.http.put(url, body, options);
  }

  patch$(url: string, body: any | null, options?: any): Observable<any> {
    options = options || {};
    options.headers = this.createAuthorizationHeader();

    return this.http.patch(url, body, options);
  }

  delete(url: string, options?: any): Observable<any> {
    options = options || {};
    options.headers = this.createAuthorizationHeader();

    return this.http.delete(url, options);
  }

  createAuthorizationHeader(): HttpHeaders {
    const { idToken } = this.userService.getUserDataForRequest();

    return new HttpHeaders({
      Authorization: `Bearer ${idToken}`,
    });
  }
}
