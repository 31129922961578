import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ACCOUNT_ROUTES_PATHS_PARTIALS } from './components/account/account-routes-paths';
import { AuthGuard } from './components/login/auth-guard.service';
import { HasAccessToFbiGuard } from './components/login/has-access-to-fbi-guard.service';
import { LastPageGuard } from './components/login/last-page.guard';
import { LoginComponent } from './components/login/login.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { TopLevelDashboardComponent } from './components/top-level-dashboard/top-level-dashboard.component';
import { VENUE_ROUTES_PATHS_PARTIALS } from './components/venue/venue-routes-paths';
import { ContextResolver } from './context.resolver';
import { LayoutComponent } from './layout/layout.component';
import { CONSTANTS } from './shared/constants';

export const AppRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        canActivate: [LastPageGuard],
        component: LoginComponent,
        pathMatch: 'full',
        loadChildren: () =>
          import('./components/login/login.module').then(m => m.LoginModule),
      },
      {
        path: CONSTANTS.ROUTES.ADMIN,
        canActivate: [AuthGuard],
        component: LayoutComponent,
        loadChildren: () =>
          import('./components/admin/admin.module').then(m => m.AdminModule),
      },
      {
        path: ACCOUNT_ROUTES_PATHS_PARTIALS.BASE_PATH,
        canActivate: [AuthGuard],
        component: LayoutComponent,
        resolve: {
          resolveData: ContextResolver,
        },
        loadChildren: () =>
          import('./components/account/account.module').then(
            m => m.AccountModule
          ),
      },
      {
        path: VENUE_ROUTES_PATHS_PARTIALS.BASE_PATH,
        canActivate: [AuthGuard],
        component: LayoutComponent,
        resolve: {
          resolveData: ContextResolver,
        },
        loadChildren: () =>
          import('./components/venue/venue.module').then(m => m.VenueModule),
      },
      {
        path: CONSTANTS.ROUTES.PROFILE,
        canActivate: [AuthGuard, HasAccessToFbiGuard],
        component: LayoutComponent,
        loadChildren: () =>
          import('./components/user-profile/user-profile.module').then(
            m => m.UserProfileModule
          ),
      },
      {
        path: CONSTANTS.ROUTES.TOP_LEVEL_DASHBOARD,
        canActivate: [AuthGuard, HasAccessToFbiGuard],
        component: LayoutComponent,
        loadChildren: () =>
          import(
            './components/top-level-dashboard/top-level-dashboard.module'
          ).then(m => m.TopLevelDashboardModule),
      },
      {
        // I have no better idea how to distinguish "outer" 404 (covering the whole app) from "inner" 404 (with sidebar)
        // so given that once upon a time there was a "home" component and routes starting with "/home"...
        path: `${CONSTANTS.ROUTES.HOME}/${CONSTANTS.ROUTES.NOT_FOUND}`,
        component: LayoutComponent,
        loadChildren: () =>
          import('./components/page-not-found/page-not-found.module').then(
            m => m.PageNotFoundModule
          ),
      },
      {
        path: `${CONSTANTS.ROUTES.NOT_FOUND}`,
        component: PageNotFoundComponent,
        loadChildren: () =>
          import('./components/page-not-found/page-not-found.module').then(
            m => m.PageNotFoundModule
          ),
      },
      {
        path: '**',
        redirectTo: `${CONSTANTS.ROUTES.NOT_FOUND}`,
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(AppRoutes, {
      paramsInheritanceStrategy: 'always',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
