import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { find } from 'lodash';
import { OneMoreQuestionWhenToAskUnit } from '../../../shared/components/one-more-question/one-more-question-edit/one-more-question.interface';
import { StatementTranslationFromBackend } from '../../../shared/interfaces/statements.interface';
import { QuestionType } from '../../../shared/interfaces/survey.interface';

@Injectable()
export class QuestionsTransService {
  constructor(private translateService: TranslateService) {}

  getQuestionTranslation(
    translations: StatementTranslationFromBackend[],
    lang: string,
    hideMissingTranslation?: boolean,
    isOther?: boolean
  ): string {
    if (isOther) {
      return this.translateService.instant('SHARED.SYSTEM.OTHER');
    }
    const selectedTranslation: StatementTranslationFromBackend =
      this.getQuestionTranslationObject(translations, lang);

    return selectedTranslation
      ? selectedTranslation.value
      : hideMissingTranslation
      ? '&nbsp;'
      : this.translateService.instant('SHARED.LANGUAGES.MISSING_TRANSLATION');
  }

  getQuestionTranslationObject(
    translations: StatementTranslationFromBackend[],
    lang: string
  ): StatementTranslationFromBackend {
    if (!translations || (translations && translations.length === 0)) {
      return null;
    }
    const selectedTranslation: StatementTranslationFromBackend = find(
      translations,
      { lang }
    );

    return selectedTranslation ? selectedTranslation : null;
  }

  getQuestionTypeName(questionType: QuestionType): string {
    switch (questionType) {
      case QuestionType.BOOLEAN:
        return this.translateService.instant('SHARED.FORMAT_TYPES.YES_NO');
      case QuestionType.CHECKBOX:
        return this.translateService.instant('SHARED.FORMAT_TYPES.CHECKBOX');
      case QuestionType.RADIO_BUTTON:
        return this.translateService.instant(
          'SHARED.FORMAT_TYPES.RADIO_BUTTON'
        );
      case QuestionType.SCALE:
        return this.translateService.instant('SHARED.FORMAT_TYPES.SCALE');
      case QuestionType.FREE_TEXT:
        return this.translateService.instant('SHARED.FORMAT_TYPES.FREE_TEXT');
      default:
        return '';
    }
  }

  getWhenToAskLabel(whenToAsk: string): string {
    if (!whenToAsk) {
      return this.translateService.instant('SHARED.SYSTEM.EVERY_TIME');
    }
    if (whenToAsk === '1') {
      return this.translateService.instant('SHARED.SYSTEM.ONLY_ONCE');
    }
    const translationKey = `SHARED.SYSTEM.${
      this.getWhenToAskUnit(whenToAsk) === OneMoreQuestionWhenToAskUnit.WEEKS
        ? 'EVERY_X_TIME_WEEKS'
        : 'EVERY_X_TIME_MONTHS'
    }`;

    return this.translateService.instant(translationKey, {
      0: this.getWhenToAskValue(whenToAsk),
    });
  }

  getWhenToAskUnit(whenToAsk: string): OneMoreQuestionWhenToAskUnit {
    return whenToAsk.slice(-1) as OneMoreQuestionWhenToAskUnit;
  }

  getWhenToAskValue(whenToAsk: string): number {
    return +whenToAsk.slice(0, -1);
  }
}
