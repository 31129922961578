<app-dialog>
  <app-dialog-title>{{
    (!data ? 'ACCOUNT.GROUPS.DIALOG.ADD_CATEGORY_TITLE' : 'ACCOUNT.GROUPS.DIALOG.EDIT_CATEGORY_TITLE') | translate
  }}</app-dialog-title>
  <app-dialog-content>
    <form [formGroup]="categoryForm">
      <div class="form-group">
        <mat-form-field>
          <mat-label>{{ 'SHARED.SYSTEM.NAME' | translate }}</mat-label>
          <input matInput autocomplete="off" list="autocompleteOff" required name="name" autofocus #nameInput formControlName="name" />
          <mat-error appErrors="name" [errors]="errors"> </mat-error>
        </mat-form-field>
        <label>{{ 'ACCOUNT.GROUPS.MODALS.REMOVE_GROUP.ASSIGN_GROUPS' | translate }}</label>
        <div class="NewCategory__groups">
          <app-multi-select-tag
            (onAddItem)="assignGroup($event)"
            (onSelectItem)="assignGroup($event)"
            (onDeselectItem)="unassignVenueFromGroup($event)"
            [addOnDemandMode]="true"
            [permanentEditMode]="true"
            [tags]="selectedGroupsAsDropdownItems"
            [items]="groupsAsDropdownItems"
            [disable]="isDisabledDropdown"
            [placeholder]="'ACCOUNT.GROUPS.LISTS.VENUES.MULTI_SELECT_TAG.PLACEHOLDER' | translate"
            [noTagsPlaceholder]="'ACCOUNT.GROUPS.LISTS.VENUES.MULTI_SELECT_TAG.NO_TAGS_PLACEHOLDER' | translate"
            [noResultsPlaceholder]="
              'ACCOUNT.GROUPS.LISTS.VENUES.MULTI_SELECT_TAG.NO_RESULTS_PLACEHOLDER_ENTER' | translate
            "></app-multi-select-tag>
        </div>
      </div>
    </form>
  </app-dialog-content>
  <app-dialog-actions>
    <button type="button" class="btn btn-foodback btn-foodback--secondary text-uppercase mr-sm" matDialogClose>
      {{ 'ACCOUNT.GROUPS.BUTTONS.CANCEL' | translate }}
    </button>
    <button type="button" class="btn btn-foodback text-uppercase"  data-dismiss="modal" (click)="save()">
      {{ 'SHARED.SYSTEM.SAVE' | translate }}
    </button>
  </app-dialog-actions>
</app-dialog>
