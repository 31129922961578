import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { I18nBackendService } from '../../../core/providers/backend/i18n-backend.service';
import { IDropdownItem } from '../../../shared/interfaces/dropdown/dropdown-item.interface';
import { I18nSupportedLanguage } from '../../../shared/interfaces/i18n.interface';
import { DataFilterService } from '../data-filter.service';

export interface IDemographicFilters {
  language: IDropdownItem;
  age: IDropdownItem;
  gender: IDropdownItem;
}
@Component({
  selector: 'app-data-filter-demographics',
  templateUrl: './data-filter-demographics.component.html',
  styleUrls: ['./data-filter-demographics.component.scss'],
})
export class DataFilterDemographicsComponent implements OnDestroy {
  @Input() set availableLanguages(value: I18nSupportedLanguage[]) {
    if (value) {
      this.setFormValues(value);
    }
  }

  @Output() readonly onDemographicFilterChanged = new EventEmitter();

  languages: IDropdownItem[] = [
    {
      id: 'any',
      text: this.translateService.instant('SHARED.SYSTEM.ANY_LANG'),
    },
  ];
  ages: IDropdownItem[] = [
    { id: 'any', text: this.translateService.instant('SHARED.SYSTEM.ANY_AGE') },
  ];
  genders: IDropdownItem[] = [
    {
      id: 'any',
      text: this.translateService.instant('SHARED.SYSTEM.ANY_GENDER'),
    },
    { id: 'male', text: this.translateService.instant('SHARED.SYSTEM.MALE') },
    {
      id: 'female',
      text: this.translateService.instant('SHARED.SYSTEM.FEMALE'),
    },
    { id: 'other', text: this.translateService.instant('SHARED.SYSTEM.OTHER') },
  ];
  formGroup: FormGroup;
  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    private i18nBackendService: I18nBackendService,
    private dataFilterService: DataFilterService,
    private translateService: TranslateService
  ) {}

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private setFormValues(value: I18nSupportedLanguage[]): void {
    this.setLanguages(value);
    this.translateService
      .get('HOME.DEMOGRAPHICS.AGE_GROUPS')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(ageGroups => {
        this.setAges(ageGroups);
        this.createFormGroup();
        this.formGroup.valueChanges
          .pipe(takeUntil(this.onDestroy$))
          .subscribe((value: IDemographicFilters) => this.setValue(value));
      });
  }

  private setAges(ageGroups: any): void {
    this.ages = [
      ...this.ages,
      ...Object.keys(ageGroups).map((ageKey: string) => ({
        id: ageKey,
        text: ageGroups[ageKey],
      })),
    ];
  }

  private setValue(value: IDemographicFilters): void {
    this.dataFilterService.setDemographicFilters(value);
    this.onDemographicFilterChanged.emit(
      this.dataFilterService.hasDemographicValueChanged()
    );
  }

  private createFormGroup(): void {
    const demographicsQueryParams =
      this.dataFilterService.getDemographicQueryParams();

    this.formGroup = new FormGroup({
      language: new FormControl(
        demographicsQueryParams
          ? this.getItemById(demographicsQueryParams.lang, this.languages)
          : this.languages[0]
      ),
      age: new FormControl(
        demographicsQueryParams
          ? this.getItemById(demographicsQueryParams.age, this.ages)
          : this.ages[0]
      ),
      gender: new FormControl(
        demographicsQueryParams
          ? this.getItemById(demographicsQueryParams.gender, this.genders)
          : this.genders[0]
      ),
    });
    this.dataFilterService.setDemographicFilters(this.formGroup.value);
  }

  private getItemById(childId: string, items: IDropdownItem[]): IDropdownItem {
    return items.find((child: IDropdownItem) => child.id === childId);
  }

  private setLanguages(value: I18nSupportedLanguage[]): void {
    this.languages = [
      ...this.languages,
      ...value
        .map((language: I18nSupportedLanguage) => ({
          id: language.code,
          text: this.getTranslatedLanguageName(language.code),
        }))
        .sort((firstLanguage: IDropdownItem, secondLanguage: IDropdownItem) =>
          firstLanguage.text
            .toLowerCase()
            .localeCompare(secondLanguage.text.toLowerCase())
        ),
    ];
  }

  private getTranslatedLanguageName(langCode: string): string {
    return this.translateService.instant(
      `SHARED.LANGUAGES.${langCode.toUpperCase()}`
    );
  }
}
