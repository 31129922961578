import { CONSTANTS } from '../../shared/constants';

export const VENUE_ROUTES_PATHS_PARTIALS = {
  BASE_PATH: `${CONSTANTS.ROUTES.VENUE}/:${CONSTANTS.ROUTE_PARAMS.VENUE_ID}`,
  OVERVIEW: {
    VENUE: CONSTANTS.ROUTES.OVERVIEW,
    CATEGORY: `${CONSTANTS.ROUTES.OVERVIEW}/${CONSTANTS.ROUTES.CATEGORY}/:${CONSTANTS.ROUTE_PARAMS.CATEGORY_ID}`,
    QUESTION:
      `${CONSTANTS.ROUTES.OVERVIEW}/${CONSTANTS.ROUTES.CATEGORY}` +
      `/:${CONSTANTS.ROUTE_PARAMS.CATEGORY_ID}/${CONSTANTS.ROUTES.QUESTION}/:${CONSTANTS.ROUTE_PARAMS.QUESTION_ID}`,
  },
  DEMOGRAPHICS: CONSTANTS.ROUTES.DEMOGRAPHICS,
  ALL_FOODBACKS: CONSTANTS.ROUTES.ALL_FOODBACKS,
  DASHBOARD: CONSTANTS.ROUTES.DASHBOARD,
  NOTIFICATIONS: {
    LIST: CONSTANTS.ROUTES.NOTIFICATIONS,
    ADD: `${CONSTANTS.ROUTES.NOTIFICATIONS}/${CONSTANTS.ROUTES.ADD}`,
    EDIT: `${CONSTANTS.ROUTES.NOTIFICATIONS}/:${CONSTANTS.ROUTE_PARAMS.NOTIFICATION_ID}/${CONSTANTS.ROUTES.EDIT}`,
  },
  WINNER_STATISTIC: CONSTANTS.ROUTES.WINNER_STATISTIC,
  ONE_MORE_QUESTION: {
    OVERVIEW: CONSTANTS.ROUTES.ONE_MORE_QUESTION,
    DETAILS: `${CONSTANTS.ROUTES.ONE_MORE_QUESTION}/${CONSTANTS.ROUTES.QUESTION}/:${CONSTANTS.ROUTE_PARAMS.QUESTION_ID}`,
  },
  CAMPAIGNS: {
    OVERVIEW: CONSTANTS.ROUTES.CAMPAIGNS,
    DETAILS: `${CONSTANTS.ROUTES.CAMPAIGNS}/:${CONSTANTS.ROUTE_PARAMS.CAMPAIGN_ID}`,
    QUESTION_DETAILS: `${CONSTANTS.ROUTES.CAMPAIGNS}/:${CONSTANTS.ROUTE_PARAMS.CAMPAIGN_ID}/${CONSTANTS.ROUTES.QUESTION}/:${CONSTANTS.ROUTE_PARAMS.QUESTION_ID}`,
  },
};
export const VENUE_ROUTES_PATHS_FULL = {
  OVERVIEW: {
    VENUE: `${VENUE_ROUTES_PATHS_PARTIALS.BASE_PATH}/${VENUE_ROUTES_PATHS_PARTIALS.OVERVIEW.VENUE}`,
    CATEGORY: `${VENUE_ROUTES_PATHS_PARTIALS.BASE_PATH}/${VENUE_ROUTES_PATHS_PARTIALS.OVERVIEW.CATEGORY}`,
    QUESTION: `${VENUE_ROUTES_PATHS_PARTIALS.BASE_PATH}/${VENUE_ROUTES_PATHS_PARTIALS.OVERVIEW.QUESTION}`,
  },
  DEMOGRAPHICS: `${VENUE_ROUTES_PATHS_PARTIALS.BASE_PATH}/${VENUE_ROUTES_PATHS_PARTIALS.DEMOGRAPHICS}`,
  ALL_FOODBACKS: `${VENUE_ROUTES_PATHS_PARTIALS.BASE_PATH}/${VENUE_ROUTES_PATHS_PARTIALS.ALL_FOODBACKS}`,
  DASHBOARD: `${VENUE_ROUTES_PATHS_PARTIALS.BASE_PATH}/${VENUE_ROUTES_PATHS_PARTIALS.DASHBOARD}`,
  NOTIFICATIONS: {
    LIST: `${VENUE_ROUTES_PATHS_PARTIALS.BASE_PATH}/${VENUE_ROUTES_PATHS_PARTIALS.NOTIFICATIONS.LIST}`,
    ADD: `${VENUE_ROUTES_PATHS_PARTIALS.BASE_PATH}/${VENUE_ROUTES_PATHS_PARTIALS.NOTIFICATIONS.ADD}`,
    EDIT: `${VENUE_ROUTES_PATHS_PARTIALS.BASE_PATH}/${VENUE_ROUTES_PATHS_PARTIALS.NOTIFICATIONS.EDIT}`,
  },
  WINNER_STATISTIC: `${VENUE_ROUTES_PATHS_PARTIALS.BASE_PATH}/${VENUE_ROUTES_PATHS_PARTIALS.WINNER_STATISTIC}`,
  ONE_MORE_QUESTION: {
    OVERVIEW: `${VENUE_ROUTES_PATHS_PARTIALS.BASE_PATH}/${VENUE_ROUTES_PATHS_PARTIALS.ONE_MORE_QUESTION.OVERVIEW}`,
    DETAILS: `${VENUE_ROUTES_PATHS_PARTIALS.BASE_PATH}/${VENUE_ROUTES_PATHS_PARTIALS.ONE_MORE_QUESTION.DETAILS}`,
  },
  CAMPAIGNS: {
    OVERVIEW: `${VENUE_ROUTES_PATHS_PARTIALS.BASE_PATH}/${VENUE_ROUTES_PATHS_PARTIALS.CAMPAIGNS.OVERVIEW}`,
    DETAILS: `${VENUE_ROUTES_PATHS_PARTIALS.BASE_PATH}/${VENUE_ROUTES_PATHS_PARTIALS.CAMPAIGNS.DETAILS}`,
    QUESTION_DETAILS: `${VENUE_ROUTES_PATHS_PARTIALS.BASE_PATH}/${VENUE_ROUTES_PATHS_PARTIALS.CAMPAIGNS.QUESTION_DETAILS}`,
  },
};
