import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChange, SimpleChanges } from '@angular/core';
import { Event, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { find } from 'lodash';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { SidebarMenu } from '../../../shared/interfaces/sidebar/sidebar-menu.interface';
import { SidebarService } from '../sidebar.service';
import { SidebarAnalyticsService } from './sidebar-analytics.service';

@Component({
  selector: 'app-stats-nav',
  templateUrl: './stats-nav.component.html',
})
export class StatsNavComponent implements OnChanges, OnInit, OnDestroy {
  @Input() isSidebarCollapsed: boolean;
  subscriptions: Subscription = new Subscription(null);
  sidebarMenus: SidebarMenu[];

  constructor(private router: Router, private sidebarService: SidebarService, private sidebarAnalyticsService: SidebarAnalyticsService) {}

  onSidebarBlur(sidebar: SidebarMenu, mouseEvent: MouseEvent) {
    mouseEvent.preventDefault();

    if (this.isSidebarCollapsed) {
      sidebar.isHovered = false;
    }
  }

  onSidebarHover(sidebar: SidebarMenu, mouseEvent: MouseEvent) {
    mouseEvent.preventDefault();

    if (this.isSidebarCollapsed) {
      sidebar.isHovered = true;
    }
  }

  toggleSidebarMenu(targetedMenu: SidebarMenu) {
    const currentlyActiveMenu: SidebarMenu = find(this.sidebarMenus, 'isActive');
    const hasSubmenu: boolean = this.sidebarService.doesMenuHaveSubmenu(targetedMenu);

    if (currentlyActiveMenu) {
      if (currentlyActiveMenu === targetedMenu) {
        currentlyActiveMenu.isExpanded = !currentlyActiveMenu.isExpanded;
        currentlyActiveMenu.isActive = this.sidebarService.isAnySubmenuActive(currentlyActiveMenu) || !hasSubmenu;
      } else {
        currentlyActiveMenu.isExpanded = false;
        currentlyActiveMenu.isActive = hasSubmenu;
        targetedMenu.isExpanded = !targetedMenu.isExpanded;
      }
    } else {
      targetedMenu.isExpanded = !targetedMenu.isExpanded;
    }
    if (!hasSubmenu) {
      this.router.navigateByUrl(targetedMenu.link);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    const isSidebarCollapsedChange: SimpleChange = changes.isSidebarCollapsed;

    // eslint-disable-next-line
    if (isSidebarCollapsedChange.currentValue !== isSidebarCollapsedChange.previousValue && !isSidebarCollapsedChange.firstChange) {
      this.sidebarMenus.map((sidebarMenu: SidebarMenu) => ({
        ...sidebarMenu,
        isHovered: false,
      }));
    }
  }

  ngOnInit() {
    this.getAnalyticsSidebarMenus().subscribe((sidebars: SidebarMenu[]) => {
      this.sidebarMenus = sidebars;
      this.getRouterEvents().subscribe(() => {
        this.sidebarMenus = this.sidebarService.setActiveSidebarMenu(this.sidebarMenus);
        this.sidebarMenus.forEach((sidebarMenu: SidebarMenu) => {
          // If sidebar is collapsed then submenus are in a 'hover mode'. Clicking one of those items should hide the submenu.
          if (this.isSidebarCollapsed && sidebarMenu.isExpanded) {
            sidebarMenu.isHovered = false;
          }
        });
      });
    });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  private getAnalyticsSidebarMenus(): Observable<SidebarMenu[]> {
    return this.sidebarAnalyticsService.getSidebarMenus$();
  }

  private getRouterEvents(): Observable<Event> {
    return this.router.events.pipe(filter((event: RouterEvent) => event instanceof NavigationEnd));
  }
}
