import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from 'ngx-webstorage';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { PermissionsBackendService } from './core/providers/backend/permissions-backend.service';
import { NavigationService } from './core/providers/navigation/navigation.service';
import { NotificationService } from './core/providers/notification/notification.service';
import { CONSTANTS } from './shared/constants';

@Injectable()
export class FbiInterceptor implements HttpInterceptor {
  constructor(
    private navigationService: NavigationService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private permissionsBackendService: PermissionsBackendService,
    private localStorage: LocalStorageService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: { status: number }) => {
        if (error.status === CONSTANTS.REQUEST_ERRORS.HTTP_403 && !this.permissionsBackendService.getIsRedirected()) {
          const { TITLE, TEXT } = this.translateService.instant('SHARED.WARNINGS.NO_ACCESS');

          this.notificationService.displaySwalPopupWithSpinner(TITLE, TEXT, 'error');
          this.permissionsBackendService.setRedirectStatus(true);
          this.permissionsBackendService.invalidateAccountsCache();
          this.permissionsBackendService.invalidateVenuesCacheForAllAccounts();
          this.navigationService.goToNoAccess();
        } else if (error.status === CONSTANTS.REQUEST_ERRORS.HTTP_401) {
          this.clearSessionAndRedirectToLogin();
        }

        return observableThrowError(error);
      })
    );
  }

  private clearSessionAndRedirectToLogin(): void {
    localStorage.removeItem(CONSTANTS.AUTH.ID_TOKEN);
    this.localStorage.clear();
    sessionStorage.clear();
    document.cookie = `auth0.ssodata =; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
    this.navigationService.goToLogin();
  }
}
