<ul
  *ngIf="sidebar"
  (mouseover)="sidebar.submenu.length ? onSidebarHover($event) : $event.preventDefault()"
  (mouseout)="onSidebarBlur($event)"
  class="nav">
  <li [class.active]="sidebar.isActive">
    <a (click)="toggleSidebar()">
      <em [ngClass]="sidebar.icon"></em>
      <span>{{ sidebar.title | translate | titlecase }}</span>
    </a>

    <ul
      [ngClass]="{
        'expanded-submenu': (sidebar.isExpanded && !isSidebarCollapsed) || (isSidebarCollapsed && sidebar.isHovered),
        opening: (sidebar.isExpanded && !isSidebarCollapsed) || (isSidebarCollapsed && sidebar.isHovered)
      }"
      class="nav sidebar-subnav">
      <li *ngFor="let submenu of sidebar.submenu">
        <a
          [routerLink]="submenu.link || null"
          [routerLinkActive]="submenu.link ? 'sub-active' : ''"
          [routerLinkActiveOptions]="{ exact: false }">
          <span>{{ submenu.title | translate | titlecase }}</span>
        </a>
      </li>
    </ul>
  </li>
</ul>
