import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { map as _map, cloneDeep, find, sortBy, uniqBy } from 'lodash';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, distinctUntilChanged, map, tap } from 'rxjs/operators';
import { FoodbackAccount } from '../../../core/classes/account/account.class';
import { AccountService } from '../../../core/providers/account/account.service';
import { GroupsBackendService } from '../../../core/providers/backend/groups-backend.service';
import { ContextService } from '../../../core/providers/context/context.service';
import { NotificationService } from '../../../core/providers/notification/notification.service';
import Bind from '../../../core/utils/decorators/bind.decorator';
import { DropdownUtil } from '../../../core/utils/dropdown-util';
import { UtilsService } from '../../../core/utils/utils.service';
import { CONSTANTS } from '../../constants';
import { VenueFromBackend } from '../../form-models-interfaces';
import { IDropdownItem } from '../../interfaces/dropdown/dropdown-item.interface';
import { IGroupPayload } from '../../interfaces/groups/add-group-payload.interface';
import { IGroupResponse } from '../../interfaces/groups/add-group-response.interface';
import { IGroup } from '../../interfaces/groups/group.interface';
import { INestedDropdownChildItem } from '../../interfaces/nested-dropdown-filter/nested-dropdown-filter-child.interface';
import { INestedDropdownItem } from '../../interfaces/nested-dropdown-filter/nested-dropdown-filter.interfaces';
import { SortByFieldPipe } from '../../pipes/sort-by-field.pipe';

// TODO refactor this ugly service
@Injectable()
export class GroupsService {
  private accountUuid: string;
  private groups$: BehaviorSubject<IGroup[]> = new BehaviorSubject([]);
  private venues$: BehaviorSubject<VenueFromBackend[]> = new BehaviorSubject(
    []
  );
  private groupsCopy: IGroup[];
  private venuesCopy: VenueFromBackend[];

  constructor(
    private accountService: AccountService,
    private groupsBackendService: GroupsBackendService,
    private notificationService: NotificationService,
    private utilsService: UtilsService,
    private contextService: ContextService,
    private translateService: TranslateService
  ) {
    this.accountService
      .getAccount$()
      .subscribe(({ uuid }: FoodbackAccount) => (this.accountUuid = uuid));
    this.contextService.onSessionContextUuidChange$.subscribe(() =>
      this.removeSessionStorage()
    );
  }

  @Bind
  setGroups(data: IGroup[]): void {
    this.groupsCopy = new SortByFieldPipe().transform(data, 'name', true);
    this.groupsCopy = this.groupsCopy.map((group: IGroup) => {
      if (group.isCategory) {
        group.venuesCount = this.getVenuesCount(group);
      }

      return group;
    });
    this.groupsCopy = sortBy(this.groupsCopy, 'name');
    this.groups$.next(this.groupsCopy);
  }

  getGroups$(): Observable<IGroup[]> {
    return this.groups$.pipe(
      distinctUntilChanged(),
      map((groups: IGroup[]) => {
        let groupsList: IGroup[] = [];

        groups.forEach((group) => {
          if (group.isCategory && group.venueGroups) {
            groupsList = [...groupsList, ...group.venueGroups];
          }
          if (!group.isCategory) {
            groupsList.push(group);
          }
        });

        return groupsList;
      })
    );
  }

  getGroupsWithCategories$(): Observable<IGroup[]> {
    return this.groups$.pipe(
      distinctUntilChanged(),
      map((groups: IGroup[]) => {
        let groupsList: IGroup[] = [];

        const noCategoryGroups: IGroup = this.getEmptyGroupCategory();

        groups.forEach((group) => {
          if (group.isCategory) {
            if (group.venueGroups) {
              group.venueGroups = sortBy(group.venueGroups, 'name');
            }

            groupsList.push(group);
          } else {
            noCategoryGroups.venueGroups.push(group);
            noCategoryGroups.venuesCount = noCategoryGroups.venueGroups.reduce(
              (acc, g) => acc + g.venuesCount,
              0
            );
          }
        });
        groupsList = sortBy(groupsList, 'name');

        if (noCategoryGroups.venueGroups.length > 0) {
          groupsList.push(noCategoryGroups);
        }

        groupsList.splice(0, 0, this.getEmptyGroup());

        return groupsList;
      })
    );
  }

  @Bind
  setVenues(data: VenueFromBackend[]) {
    this.venuesCopy = data;
    this.venuesCopy.map((item) => {
      item.venueGroupsDropdown = this.getGroupsAsDropdownItems(
        item.venueGroups
      );
    });
    this.venues$.next(this.venuesCopy);
  }

  getVenues$(): Observable<VenueFromBackend[]> {
    return this.venues$.pipe(distinctUntilChanged());
  }

  getGroupsAsDropdownItems(groups: IGroup[]): IDropdownItem[] {
    const validGroups = groups.filter((item) => !item.isEmptyGroup);

    return (
      cloneDeep(
        DropdownUtil.convertToDropdownItems(validGroups, ['uuid', 'name'])
      ).sort((a, b) =>
        a.text.toLowerCase().localeCompare(b.text.toLowerCase())
      ) || []
    );
  }

  assignGroupToVenue$(
    venue: VenueFromBackend,
    groupUuid: string
  ): Observable<any> {
    return this.groupsBackendService
      .assignGroupToVenue$(venue.uuid, groupUuid)
      .pipe(
        tap((venueId) => this.updateGroupsAndVenues(venueId, groupUuid, true))
      );
  }

  unassignVenueFromGroup$(
    venue: VenueFromBackend,
    groupUuid: string
  ): Observable<any> {
    return this.groupsBackendService
      .unassignVenueFromGroup$(venue.uuid, groupUuid)
      .pipe(
        tap((venueId) => this.updateGroupsAndVenues(venueId, groupUuid, false))
      );
  }

  addGroup$(data: IGroupPayload): Observable<any> {
    data.accountUuid = this.accountUuid;

    return this.groupsBackendService.addGroup$(data).pipe(
      map((group) => {
        const newGroup: IGroup = this.getNewGroupObject(group);

        if (data.isCategory) {
          this.saveNewCategory(newGroup);
          this.notificationService.successWithTranslationKey(
            'SHARED.GROUPS.CATEGORY_ADDED',
            { name }
          );
        } else {
          this.saveNewGroupAndUpdateVenues(newGroup);
          this.notificationService.successWithTranslationKey(
            'SHARED.GROUPS.GROUP_ADDED',
            { name }
          );
        }

        return newGroup;
      }),
      catchError((error) => {
        switch (error.status) {
          case CONSTANTS.REQUEST_ERRORS.HTTP_409: {
            this.notificationService.errorWithTranslationKey(
              'SHARED.GROUPS.USER_UNIQUE_CAT'
            );

            return of(null);
          }
          default:
            break;
        }

        return error;
      })
    );
  }

  removeGroup$(uuid: string): Observable<any> {
    return this.groupsBackendService.removeGroup$(uuid).pipe(
      tap((removedGroup) => {
        if (removedGroup.isCategory) {
          this.removeExistingCategory(removedGroup);
        } else {
          this.removeExistingGroup(removedGroup);
        }

        this.setGroups(this.groupsCopy);
      }),
      catchError((error) => {
        switch (error.status) {
          case CONSTANTS.REQUEST_ERRORS.HTTP_409: {
            this.notificationService.errorWithTranslationKey(
              'ACCOUNT.GROUPS.DELETE_GROUP_ERROR'
            );

            return of(null);
          }
          default:
            break;
        }

        return error;
      })
    );
  }

  updateGroup$(uuid: string, data: IGroupPayload): Observable<any> {
    return this.groupsBackendService.updateGroup$(uuid, data).pipe(
      map((group) => {
        if (group.isCategory) {
          this.updateCategory(group);
        } else {
          this.updateGroupAndUpdateVenues(group);
        }

        return group;
      }),
      catchError((error) => {
        switch (error.status) {
          case CONSTANTS.REQUEST_ERRORS.HTTP_409: {
            this.notificationService.errorWithTranslationKey(
              'SHARED.GROUPS.USER_UNIQUE_NAME'
            );

            return of(null);
          }
          default:
            break;
        }

        return error;
      })
    );
  }

  saveSelectedGroupsInSessionStore(
    selectedGroups: INestedDropdownChildItem | INestedDropdownChildItem[]
  ) {
    const data = JSON.stringify(selectedGroups);

    if (data) {
      sessionStorage.setItem(
        CONSTANTS.LOCAL_STORAGE_KEYS.SELECTED_GROUP_HASH,
        this.utilsService.getHashCode(data).toString()
      );
      sessionStorage.setItem(CONSTANTS.LOCAL_STORAGE_KEYS.SELECTED_GROUP, data);
    }
  }

  saveSelectedVenuesFilteredByGroupsInSessionStore(
    selectedVenues: IDropdownItem[]
  ) {
    const data = JSON.stringify(selectedVenues);

    if (data) {
      sessionStorage.setItem(
        CONSTANTS.LOCAL_STORAGE_KEYS.SELECTED_VENUES_HASH,
        this.utilsService.getHashCode(data).toString()
      );
      sessionStorage.setItem(
        CONSTANTS.LOCAL_STORAGE_KEYS.SELECTED_VENUES,
        data
      );
    }
  }

  saveVenuesFilteredByGroupsInSessionStore(filteredVenues: IDropdownItem[]) {
    const data = JSON.stringify(filteredVenues);

    if (data) {
      sessionStorage.setItem(
        CONSTANTS.LOCAL_STORAGE_KEYS.FILTERED_VENUES_HASH,
        this.utilsService.getHashCode(data).toString()
      );
      sessionStorage.setItem(
        CONSTANTS.LOCAL_STORAGE_KEYS.FILTERED_VENUES,
        data
      );
    }
  }

  removeSelectedVenuesFilteredByGroupsInSessionStore() {
    sessionStorage.removeItem(CONSTANTS.LOCAL_STORAGE_KEYS.SELECTED_VENUES);
    sessionStorage.removeItem(
      CONSTANTS.LOCAL_STORAGE_KEYS.SELECTED_VENUES_HASH
    );
  }

  removeVenuesFilteredByGroupsInSessionStore() {
    sessionStorage.removeItem(CONSTANTS.LOCAL_STORAGE_KEYS.FILTERED_VENUES);
    sessionStorage.removeItem(
      CONSTANTS.LOCAL_STORAGE_KEYS.FILTERED_VENUES_HASH
    );
  }

  removeSelectedGroupsInSessionStore() {
    sessionStorage.removeItem(CONSTANTS.LOCAL_STORAGE_KEYS.SELECTED_GROUP);
    sessionStorage.removeItem(CONSTANTS.LOCAL_STORAGE_KEYS.SELECTED_GROUP_HASH);
  }

  getSelectedGroupsFromSessionStorage(): INestedDropdownItem[] {
    let selectedGroups = null;

    try {
      selectedGroups = JSON.parse(
        sessionStorage.getItem(CONSTANTS.LOCAL_STORAGE_KEYS.SELECTED_GROUP)
      );
    } catch (e) {
      console.error('Incorrect data in session storage');
    }
    if (
      !selectedGroups ||
      selectedGroups.length === 0 ||
      (!this.utilsService.isHashKeyCorrect(
        CONSTANTS.LOCAL_STORAGE_KEYS.SELECTED_GROUP,
        CONSTANTS.LOCAL_STORAGE_KEYS.SELECTED_GROUP_HASH
      ) &&
        !this.utilsService.isHashKeyCorrect(
          CONSTANTS.LOCAL_STORAGE_KEYS.SELECTED_VENUES,
          CONSTANTS.LOCAL_STORAGE_KEYS.SELECTED_VENUES_HASH
        ))
    ) {
      return [];
    }

    return selectedGroups;
  }

  getSavedSelectedGroupsFromSessionStorage(
    groupsItems: INestedDropdownItem[]
  ): INestedDropdownItem[] {
    let selectedGroupsUuids = null;

    try {
      selectedGroupsUuids = JSON.parse(
        sessionStorage.getItem(CONSTANTS.LOCAL_STORAGE_KEYS.SELECTED_GROUP)
      );
    } catch (e) {
      console.error('Incorrect data in session storage');
    }
    if (
      !selectedGroupsUuids ||
      selectedGroupsUuids.length === 0 ||
      (!this.utilsService.isHashKeyCorrect(
        CONSTANTS.LOCAL_STORAGE_KEYS.SELECTED_GROUP,
        CONSTANTS.LOCAL_STORAGE_KEYS.SELECTED_GROUP_HASH
      ) &&
        !this.utilsService.isHashKeyCorrect(
          CONSTANTS.LOCAL_STORAGE_KEYS.SELECTED_VENUES,
          CONSTANTS.LOCAL_STORAGE_KEYS.SELECTED_VENUES_HASH
        ))
    ) {
      return groupsItems;
    }

    return _map(groupsItems, (groupItem: INestedDropdownItem) => {
      groupItem.isSelected = !!find(
        selectedGroupsUuids,
        (group: INestedDropdownItem) => group.id === groupItem.id
      );

      if (groupItem.children && !groupItem.isSelected) {
        groupItem.children = groupItem.children.map((groupChild) => {
          groupChild.isSelected = !!find(
            selectedGroupsUuids,
            (group: INestedDropdownItem) => group.id === groupChild.id
          );

          return groupChild;
        });
        groupItem.isSelected =
          groupItem.id !== CONSTANTS.GROUPS.NO_GROUPS_UUID &&
          groupItem.children.filter((groupChild) => groupChild.isSelected)
            .length === groupItem.children.length;
      } else if (groupItem.children && groupItem.isSelected) {
        groupItem.children = groupItem.children.map((groupChild) => {
          groupChild.isSelected = true;

          return groupChild;
        });
      }

      return groupItem;
    });
  }

  getSavedSelectedVenuesFromSessionStore(): IDropdownItem[] {
    let selectedVenues = null;

    try {
      selectedVenues = JSON.parse(
        sessionStorage.getItem(CONSTANTS.LOCAL_STORAGE_KEYS.SELECTED_VENUES)
      );
    } catch (e) {
      console.error('Incorrect data in session storage');
    }

    return selectedVenues &&
      selectedVenues.length > 0 &&
      this.utilsService.isHashKeyCorrect(
        CONSTANTS.LOCAL_STORAGE_KEYS.SELECTED_VENUES,
        CONSTANTS.LOCAL_STORAGE_KEYS.SELECTED_VENUES_HASH
      ) &&
      this.utilsService.isHashKeyCorrect(
        CONSTANTS.LOCAL_STORAGE_KEYS.SELECTED_GROUP,
        CONSTANTS.LOCAL_STORAGE_KEYS.SELECTED_GROUP_HASH
      )
      ? selectedVenues
      : [];
  }

  getSavedFilteredVenuesFromSessionStore(): IDropdownItem[] {
    let filteredVenues = null;

    try {
      filteredVenues = JSON.parse(
        sessionStorage.getItem(CONSTANTS.LOCAL_STORAGE_KEYS.FILTERED_VENUES)
      );
    } catch (e) {
      console.error('Incorrect data in session storage');
    }

    return filteredVenues &&
      filteredVenues.length > 0 &&
      this.utilsService.isHashKeyCorrect(
        CONSTANTS.LOCAL_STORAGE_KEYS.FILTERED_VENUES,
        CONSTANTS.LOCAL_STORAGE_KEYS.FILTERED_VENUES_HASH
      ) &&
      this.utilsService.isHashKeyCorrect(
        CONSTANTS.LOCAL_STORAGE_KEYS.SELECTED_VENUES,
        CONSTANTS.LOCAL_STORAGE_KEYS.SELECTED_VENUES_HASH
      ) &&
      this.utilsService.isHashKeyCorrect(
        CONSTANTS.LOCAL_STORAGE_KEYS.SELECTED_GROUP,
        CONSTANTS.LOCAL_STORAGE_KEYS.SELECTED_GROUP_HASH
      )
      ? filteredVenues
      : [];
  }

  getEmptyGroup(): IGroup {
    return {
      uuid: CONSTANTS.GROUPS.NO_GROUPS_UUID,
      name: this.translateService.instant('SHARED.SYSTEM.NO_ASSIGNED_GROUPS'),
      venuesCount: 0,
      isCategory: false,
      venueGroups: [],
      isEmptyGroup: true,
    };
  }

  getGroupsByUuids(
    selectedGroupsUuids: string[],
    groups: IGroup[]
  ): INestedDropdownItem[] {
    const groupState: INestedDropdownItem[] =
      DropdownUtil.getNestedDropdownItems(
        groups,
        ['uuid', 'name', 'venueGroups'],
        false
      ).map((groupItem) => {
        groupItem.isSelected = !!find(
          selectedGroupsUuids,
          (groupId: string) => groupId === groupItem.id
        );

        if (groupItem.children && !groupItem.isSelected) {
          groupItem.children = groupItem.children.map(
            (groupChild: INestedDropdownChildItem) => {
              groupChild.isSelected = !!find(
                selectedGroupsUuids,
                (groupId: string) => groupId === groupChild.id
              );

              return groupChild;
            }
          );
          groupItem.isSelected =
            groupItem.children.filter(
              (groupChild: INestedDropdownChildItem) => groupChild.isSelected
            ).length === groupItem.children.length &&
            groupItem.id !== CONSTANTS.GROUPS.NO_GROUPS_UUID;
        } else if (groupItem.children && groupItem.isSelected) {
          groupItem.children = groupItem.children.map(
            (groupChild: INestedDropdownChildItem) => {
              groupChild.isSelected = true;

              return groupChild;
            }
          );
        }

        return groupItem;
      });
    const selectedGroups = [];

    groupState.forEach((groupItem) => {
      if (
        !groupItem.isSelected &&
        groupItem.children &&
        groupItem.children.length > 0
      ) {
        groupItem.children.forEach((groupChild) => {
          if (groupChild.isSelected) {
            selectedGroups.push(groupChild);
          }
        });
      } else if (groupItem.isSelected) {
        selectedGroups.push(groupItem);
      }
    });

    return selectedGroups;
  }

  getSelectedUuids(selectedGroups: INestedDropdownItem[]): string[] {
    let selectedGroupsUuids = [];

    selectedGroups.forEach((group) => {
      if (group.children?.length > 0) {
        selectedGroupsUuids = [
          ...selectedGroupsUuids,
          ...group.children.map((child) => child.id),
        ];
      } else {
        selectedGroupsUuids.push(group.id);
      }
    });

    return selectedGroupsUuids;
  }

  private removeExistingCategory(removedGroup: IGroupResponse) {
    const categoryGroups = this.groupsCopy.find(
      (group) => group.uuid === removedGroup.uuid
    ).venueGroups;

    this.groupsCopy = this.groupsCopy.filter(
      (group) => group.uuid !== removedGroup.uuid
    );
    this.groupsCopy = [...this.groupsCopy, ...categoryGroups];
    this.notificationService.successWithTranslationKey(
      'SHARED.GROUPS.CATEGORY_REMOVED',
      { name }
    );
  }

  private removeExistingGroup(removedGroup: IGroupResponse) {
    this.removeGroupAndUpdateVenues(removedGroup.uuid, removedGroup.venues);
    this.groupsCopy = this.groupsCopy.filter(
      (group) => group.uuid !== removedGroup.uuid
    );
    this.groupsCopy.forEach((group) => {
      if (group.isCategory) {
        group.venueGroups = group.venueGroups.filter(
          (subGroup) => subGroup.uuid !== removedGroup.uuid
        );
      }
    });
  }

  private getEmptyGroupCategory(): IGroup {
    return {
      uuid: CONSTANTS.GROUPS.NO_CATEGORY_UUID,
      name: this.translateService.instant('SHARED.SYSTEM.NO_CATEGORY'),
      venuesCount: 0,
      isCategory: true,
      venueGroups: [],
    };
  }

  private getNewGroupObject(group: IGroupResponse): IGroup {
    const venueGroups: IGroup[] = group.venueGroups as any[];

    return {
      venueGroups,
      venuesCount: group.venuesCount,
      uuid: group.uuid,
      name: group.name,
      isCategory: group.isCategory,
      venues: group.venues,
      category: group.category,
    };
  }

  private updateCategory(data: IGroupResponse) {
    const selectedCategory = this.groupsCopy.find(
      (group) => group.uuid === data.uuid
    );

    selectedCategory.name = data.name;
    const categoryGroups: IGroup[] = data.venueGroups.map((group) => ({
      ...group,
    }));
    const filteredGroups = this.unassignGroupsFromCategories(categoryGroups);

    filteredGroups.find((group) => group.uuid === data.uuid).venueGroups =
      data.venueGroups;
    categoryGroups.forEach((group) => {
      const assignedGroup = data.venueGroups.find(
        (venueGroup) => venueGroup.uuid === group.uuid
      );

      if (!assignedGroup) {
        filteredGroups.push(group);
      }
    });
    this.notificationService.successWithTranslationKey(
      'SHARED.GROUPS.CATEGORY_UPDATED',
      { name }
    );
    this.setGroups(filteredGroups);
  }

  private unassignGroupsFromCategories(groups: IGroup[]): IGroup[] {
    let filteredGroup: IGroup[] = this.groupsCopy;

    groups.forEach((group) => {
      filteredGroup = filteredGroup.filter((mainGroup) => {
        if (mainGroup.isCategory) {
          mainGroup.venueGroups = mainGroup.venueGroups.filter(
            (subGroup) => subGroup.uuid !== group.uuid
          );
        }

        return mainGroup.uuid !== group.uuid;
      });
    });

    return filteredGroup;
  }

  private removeGroupAndUpdateVenues(uuid: string, venues: { uuid: string }[]) {
    const updatedGroup: IGroup = this.getUpdatedGroupReference(uuid);

    if (updatedGroup) {
      this.groupsCopy = this.groupsCopy.filter((group) => group.uuid !== uuid);
      this.setGroups(this.groupsCopy);

      if (venues && venues.length > 0) {
        for (let i = 0; i < venues.length; i++) {
          const assignedVenue = this.venuesCopy.find(
            (venue) => venue.uuid === venues[i].uuid
          );

          if (assignedVenue) {
            assignedVenue.venueGroups = assignedVenue.venueGroups.filter(
              (group) => group.uuid !== uuid
            );
          }
        }
      }

      this.notificationService.successWithTranslationKey(
        'SHARED.GROUPS.GROUP_DELETED',
        { name }
      );
    }
  }

  private saveNewCategory(category: IGroup) {
    const filteredGroups = this.unassignGroupsFromCategories(
      category.venueGroups
    );

    filteredGroups.push(category);
    this.setGroups(filteredGroups);
  }

  private saveNewGroupAndUpdateVenues(group: IGroup) {
    if (group.venues && group.venues.length > 0) {
      this.setGroupInVenues(group);
    }
    if (group.category) {
      this.setGroupInCategory(group);
    } else {
      this.groupsCopy.push(group);
    }

    this.setGroups(this.groupsCopy);
  }

  private setGroupInVenues(group: IGroup) {
    for (let i = 0; i < group.venues.length; i++) {
      const assignedVenue = this.venuesCopy.find(
        (venue) => venue.uuid === group.venues[i].uuid
      );

      if (assignedVenue) {
        assignedVenue.venueGroups.push(group);
      }
    }
  }

  private setGroupInCategory(group: IGroup) {
    const index = this.groupsCopy.findIndex(
      (category) => category.uuid === group.category
    );

    if (index > -1) {
      this.groupsCopy[index].venueGroups.push(group);
    }
  }

  private updateGroupAndUpdateVenues(data: IGroupResponse) {
    const updatedGroup: IGroup = this.getUpdatedGroupReference(data.uuid);

    if (updatedGroup) {
      updatedGroup.name = data.name;
      this.setGroups(this.groupsCopy);

      if (data.venues) {
        this.updateGroupInVenues(updatedGroup, data);
      }
      if (updatedGroup.category !== data.category) {
        this.updateGroupInCategories(updatedGroup, data);
      } else {
        this.setGroups(this.groupsCopy);
      }

      this.setVenues(this.venuesCopy);
      this.notificationService.successWithTranslationKey(
        'SHARED.GROUPS.GROUP_UPDATED',
        { name }
      );
    }
  }

  private updateGroupInVenues(updatedGroup: IGroup, data: IGroupResponse) {
    for (let i = 0; i < updatedGroup.venues.length; i++) {
      const assignedVenue = this.venuesCopy.find(
        (venue) => venue.uuid === updatedGroup.venues[i].uuid
      );

      if (assignedVenue) {
        assignedVenue.venueGroups = assignedVenue.venueGroups.filter(
          (group) => group.uuid !== updatedGroup.uuid
        );
      }
    }
    for (let i = 0; i < data.venues.length; i++) {
      const assignedVenue = this.venuesCopy.find(
        (venue) => venue.uuid === data.venues[i].uuid
      );

      if (assignedVenue) {
        assignedVenue.venueGroups.push(updatedGroup);
      }
    }

    updatedGroup.venues = data.venues;
    updatedGroup.venuesCount = data.venues.length;
  }

  private updateGroupInCategories(updatedGroup: IGroup, data: IGroupResponse) {
    updatedGroup.category = data.category;
    const filteredGroups = this.unassignGroupsFromCategories([updatedGroup]);
    const newCategory = filteredGroups.find(
      (group) => group.uuid === data.category
    );

    if (newCategory) {
      newCategory.venueGroups.push(updatedGroup);
    } else {
      filteredGroups.push(updatedGroup);
    }

    this.setGroups(filteredGroups);
  }

  private updateGroupsAndVenues(
    venueUuid: string,
    groupUuid: string,
    isAssigning: boolean
  ) {
    const selectedGroup: IGroup = this.getUpdatedGroupReference(groupUuid);
    const selectedVenue = this.venuesCopy.find((v) => v.uuid === venueUuid);

    if (selectedGroup && selectedVenue) {
      if (isAssigning) {
        this.assignGroup(selectedVenue, selectedGroup);
      } else {
        this.unassignGroup(selectedVenue, selectedGroup);
      }
    }
  }

  private getUpdatedGroupReference(groupUuid: string): IGroup {
    let selectedGroup: IGroup = null;

    this.groupsCopy.forEach((group) => {
      if (group.isCategory && group.venueGroups) {
        group.venueGroups.forEach((subGroup) => {
          if (subGroup.uuid === groupUuid) {
            selectedGroup = subGroup;
          }
        });

        return false;
      }
      if (group.uuid === groupUuid) {
        selectedGroup = group;
      }
    });

    return selectedGroup;
  }

  private assignGroup(venue: VenueFromBackend, selectedGroup: IGroup) {
    selectedGroup.venuesCount++;
    selectedGroup.venues.push({ uuid: venue.uuid });
    this.setGroups(this.groupsCopy);
    this.venuesCopy
      .filter((v) => v.uuid === venue.uuid)[0]
      .venueGroups.push(selectedGroup);
    this.setVenues(this.venuesCopy);
    this.notificationService.successWithTranslationKey(
      'SHARED.GROUPS.GROUP_ADDED_TO',
      {
        name: selectedGroup.name,
        catName: venue.brandName,
      }
    );
  }

  private unassignGroup(venue: VenueFromBackend, selectedGroup: IGroup) {
    selectedGroup.venuesCount--;
    selectedGroup.venues = selectedGroup.venues.filter(
      (v) => v.uuid !== venue.uuid
    );
    this.setGroups(this.groupsCopy);
    venue.venueGroups = venue.venueGroups.filter(
      (g) => g.uuid !== selectedGroup.uuid
    );
    this.setVenues(this.venuesCopy);
    this.notificationService.successWithTranslationKey(
      'SHARED.GROUPS.GROUP_REMOVED_FROM',
      {
        name: selectedGroup.name,
        catName: venue.brandName,
      }
    );
  }

  private getVenuesCount(group: IGroup) {
    let venues = [];

    group.venueGroups.forEach((venueGroup) => {
      venues = [...venues, ...venueGroup.venues];
    });
    venues = uniqBy(venues, 'uuid');

    return venues.length;
  }

  private removeSessionStorage() {
    this.removeVenuesFilteredByGroupsInSessionStore();
    this.removeSelectedGroupsInSessionStore();
    this.removeSelectedVenuesFilteredByGroupsInSessionStore();
  }
}
