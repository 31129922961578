import { Component, OnInit } from '@angular/core';
import { MultiSelectTagListContent, MultiSelectTagListRef } from './multi-select-tag-list-ref';

@Component({
  selector: 'app-multi-select-tag-list',
  templateUrl: './multi-select-tag-list.component.html',
})
export class MultiSelectTagListComponent implements OnInit {
  content: MultiSelectTagListContent;
  context;

  constructor(private multiSelectTagListRef: MultiSelectTagListRef) {}

  ngOnInit() {
    this.content = this.multiSelectTagListRef.content;
    this.context = {
      close: this.multiSelectTagListRef.close.bind(this.multiSelectTagListRef),
      select: this.multiSelectTagListRef.select.bind(this.multiSelectTagListRef),
    };
  }
}
