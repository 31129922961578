import { VenueCategoryAverageEntry, VenueCategoryDetails } from '../../../shared/interfaces/analytics.interface';
import { FoodbackAccount } from '../../classes/account/account.class';

export class CategoriesUtils {
  static filterCategoriesAveragesByAccount(account: FoodbackAccount, categories: VenueCategoryAverageEntry[]): VenueCategoryAverageEntry[] {
    return categories.filter(category => category.categoryType === account.venueType.uuid);
  }

  static filterCategoriesByAccount(account: FoodbackAccount, categories: VenueCategoryDetails[]): VenueCategoryDetails[] {
    return categories.filter(category => category.categoryType === account.venueType.uuid);
  }
}
