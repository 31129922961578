import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CategoriesBackendService } from '../../../../core/providers/backend/categories-backend.service';
import { GoalsBackendService } from '../../../../core/providers/backend/goals-backend.service';
import { StatementsBackendService } from '../../../../core/providers/backend/statements-backend.service';
import { NotificationService } from '../../../../core/providers/notification/notification.service';
import { CustomValidators } from '../../../custom-validators';
import { FormModels } from '../../../form-models';
import {
  RenameCategoriesModalInput,
  RenameCategoriesStatementModalOutput,
} from './rename-categories-modal.interface';

export const renameCategoriesDialogConfig: MatDialogConfig = {
  width: '600px',
  height: '420px',
  disableClose: true,
};
@Component({
  selector: 'app-rename-categories-modal',
  templateUrl: './rename-categories-modal.component.html',
  styleUrls: ['./rename-categories-modal.component.scss'],
})
export class RenameCategoriesModalComponent implements OnInit, OnDestroy {
  onClose$: Subject<RenameCategoriesStatementModalOutput[]>;
  inputData$: Subject<RenameCategoriesModalInput>;
  renameCategoriesForm: FormGroup;
  isDisabledButton = false;
  errors = FormModels.VenueAccount.VenueErrors.categoryName;

  private onDestroy$ = new Subject<void>();

  constructor(
    private mdDialogRef: MatDialogRef<RenameCategoriesModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: RenameCategoriesModalInput,
    private formBuilder: FormBuilder,
    private statementsBackendService: StatementsBackendService,
    private notificationService: NotificationService,
    private categoriesBackendService: CategoriesBackendService,
    private goalsBackendService: GoalsBackendService
  ) {
    this.onClose$ = new Subject<RenameCategoriesStatementModalOutput[]>();
    this.inputData$ = new Subject<RenameCategoriesModalInput>();
  }

  ngOnInit(): void {
    this.initForm();
  }

  cancel() {
    this.hideModal();
  }

  submit() {
    this.renameCategoriesForm.markAsTouched();

    if (this.renameCategoriesForm.valid) {
      for (let i = 0; i < this.data.statements.length; i++) {
        this.data.statements[i].name =
          this.renameCategoriesForm.value.categories[i].categoryName;
      }

      this.statementsBackendService
        .renameCategoriesNames$(this.data.accountUuid, this.data.statements)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(() => {
          this.categoriesBackendService.clearCachedData();
          this.goalsBackendService.resetAllCachedData();
          this.onClose$.next(this.data.statements);
          this.notificationService.successWithTranslationKey(
            'SHARED.QUESTIONS.CATEGORIES_SUCCESSFUL_CHANGED'
          );
          this.mdDialogRef.close();
        });
    }
  }

  hideModal() {
    this.mdDialogRef.close();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private initForm() {
    this.renameCategoriesForm = this.formBuilder.group({
      categories: this.formBuilder.array(
        [],
        Validators.compose([Validators.required])
      ),
    });
    this.setCategoriesControls();
  }

  private setCategoriesControls() {
    if (!this.data.statements || this.data.statements.length === 0) {
      return;
    }
    const numberOfCategories = 4;
    const maxNameLength = 30;

    for (let i = 0; i < numberOfCategories; i++) {
      (this.renameCategoriesForm.get('categories') as FormArray).push(
        this.formBuilder.group({
          categoryName: [
            this.data.statements[i].name,
            [
              Validators.required,
              Validators.minLength(1),
              Validators.maxLength(maxNameLength),
              CustomValidators.noWhitespaceValidator,
            ],
          ],
        })
      );
    }
  }
}
