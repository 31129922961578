import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UnsplashApiResponse } from '../../../interfaces/unsplash-api.interface';

// our usage of unsplash is so simple, that installing the official library and providing fetch polyfill would be an overkill
@Injectable()
export class UnsplashApiService {
  private static readonly API_KEY = 'd4f08b28c065c75792d1a26e363c115377179c55acbceeedfef3de933dc72dd8';
  private static readonly UNSPLASH_API_PATH = 'https://api.unsplash.com';
  private static readonly RANDOM_PHOTO_PATH = `${UnsplashApiService.UNSPLASH_API_PATH}/photos/random`;
  private httpClient: HttpClient;

  constructor(private handler: HttpBackend) {
    this.httpClient = new HttpClient(handler);
  }

  getRandomRestaurantCoverPhoto(): Observable<UnsplashApiResponse> {
    return this.httpClient.get<UnsplashApiResponse>(UnsplashApiService.RANDOM_PHOTO_PATH, {
      params: {
        client_id: UnsplashApiService.API_KEY,
        query: 'restaurant',
        orientation: 'landscape',
      },
    });
  }
}
