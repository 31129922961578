import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CONSTANTS } from '../../../constants';

@Component({
  selector: 'app-select-search',
  templateUrl: './select-search.component.html',
  styleUrls: ['./select-search.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectSearchComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectSearchComponent implements OnInit, OnDestroy, ControlValueAccessor {
  /* Label of the search placeholder */
  @Input() set isSearchModeEnable(value: boolean) {
    this.searchSelectContainer.nativeElement.parentElement.style.display = value ? '' : 'none';
  }

  /* Current search value */
  get value(): string {
    return this._value;
  }

  /* Label of the search placeholder */
  @Input() placeholderLabel = 'Search';

  /* Label to be shown when no entries are found. Set to null if no message should be shown. */
  @Input() noEntriesFoundLabel = 'No entries found';

  @Input() isSingleAndAllModeEnable: boolean;

  @Input() set panelClass(value: string) {
    this.setCustomPanelClass(value);
  }

  /* Output emitter to send to parent component with the select all boolean */
  @Output() readonly selectAllBooleanEmitter = new EventEmitter<boolean>();
  selectAllCheckbox = new FormControl();

  /* Reference to the search input field */
  @ViewChild('searchSelectContainer', { read: ElementRef })
  searchSelectContainer: ElementRef;

  @ViewChild('searchSelectInput', { read: ElementRef })
  searchSelectInput: ElementRef;

  /* Reference to the MatSelect options */
  _options: QueryList<MatOption>;

  /* Previously selected values when using <mat-select [multiple]="true">*/
  private previousSelectedValues: any[];

  /* Whether the backdrop class has been set */
  private overlayClassSet = false;

  /* Event that emits when the current value changes */
  private change = new EventEmitter<string>();

  /* Subject that emits when the component has been destroyed. */
  private _onDestroy = new Subject<void>();

  private _value: string;

  private isEnableToEmmitSelectAllEvent = false;

  constructor(@Inject(MatSelect) public matSelect: MatSelect, private changeDetectorRef: ChangeDetectorRef) {}

  onChange: Function = (_: any) => {};
  onTouched: Function = (_: any) => {};

  ngOnInit() {
    // set custom panel class
    this.setCustomPanelClass(null);
    // when the select dropdown panel is opened or closed
    this.matSelect.openedChange.pipe(takeUntil(this._onDestroy)).subscribe(opened => {
      if (opened) {
        this.setOverlayClass();
        // focus the search field when opening
        setTimeout(() => this.setPosition(), CONSTANTS.TIME_IN_MS.TIME_10);
        setTimeout(() => this._focus(), CONSTANTS.TIME_IN_MS.TIME_200);

        // Select checkbox if default selection is for all potions
        if (this.matSelect.multiple || this.isSingleAndAllModeEnable) {
          if (this.matSelect.ngControl.value && this.matSelect.ngControl.value.length === this.matSelect.options.length) {
            this.isEnableToEmmitSelectAllEvent = false;
            this.selectAllCheckbox.setValue(true);
            this.isEnableToEmmitSelectAllEvent = true;
          } else {
            this.isEnableToEmmitSelectAllEvent = true;
          }
        }
      } else {
        // clear it when closing
        this._reset();
      }
    });
    // detect changes when the input changes
    this.change.pipe(takeUntil(this._onDestroy)).subscribe(() => {
      this.changeDetectorRef.detectChanges();
    });
    this.selectAllCheckbox.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(response => {
      if (response !== null && this.isEnableToEmmitSelectAllEvent) {
        this.emitSelectAllBooleanToParent();
      }
    });
    this.initMultipleHandling();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  /*
   * Emits the Select All checkbox FormControl value to the parent component
   */
  emitSelectAllBooleanToParent() {
    this.selectAllBooleanEmitter.emit(this.selectAllCheckbox.value);
  }

  _handleKeydown(event: KeyboardEvent) {
    if (event.keyCode === CONSTANTS.KEY_CODES.KEY_32) {
      // do not propagate spaces to MatSelect, as this would select the currently active option
      event.stopPropagation();
    }
  }

  writeValue(value: string) {
    const valueChanged = value !== this._value;

    if (valueChanged) {
      this._value = value;
      this.change.emit(value);
    }
  }

  onInputChange(value: string) {
    const valueChanged = value !== this._value;

    if (valueChanged) {
      this.changeDetectorRef.detectChanges();
      this._value = value;
      this.onChange(value);
      this.change.emit(value);
    }
  }

  onBlur(value: string) {
    this.writeValue(value);
    this.onTouched();
  }

  registerOnChange(fn: Function) {
    this.onChange = fn;
  }

  registerOnTouched(fn: Function) {
    this.onTouched = fn;
  }

  /*
   * Focuses the search input field
   * @private
   */
  _focus() {
    if (!this.searchSelectInput || !this.matSelect.panel) {
      return;
    }
    // save and restore scrollTop of panel, since it will be reset by focus()
    // note: this is hacky
    const panel = this.matSelect.panel.nativeElement;
    const scrollTop = panel.scrollTop;

    // focus
    this.searchSelectInput.nativeElement.focus();
    panel.scrollTop = scrollTop;
  }

  /*
   * Resets the current search value
   * @param {boolean} focus whether to focus after resetting
   * @private
   */
  _reset(focus?: boolean) {
    if (!this.searchSelectInput) {
      return;
    }

    this.searchSelectInput.nativeElement.value = '';
    this.changeDetectorRef.detectChanges();
    this._value = '';
    this.onChange('');
    this.change.emit('');

    if (focus) {
      this._focus();
    }
  }

  /*
   * Sets the overlay class  to correct offsetY
   * so that the selected option is at the position of the select box when opening
   */
  private setOverlayClass() {
    if (this.overlayClassSet) {
      return;
    }
    const overlayClass = 'cdk-overlay-pane-select-search';
    const multipleClass = 'cdk-overlay-pane-select-multiple';

    this.searchSelectInput.nativeElement.parentElement.parentElement.parentElement.parentElement.parentElement.classList.add(overlayClass);

    if (this.matSelect.multiple) {
      this.searchSelectInput.nativeElement.parentElement.parentElement.parentElement.parentElement.parentElement.classList.add(
        multipleClass
      );
    }

    this.overlayClassSet = true;
  }

  /*
   * Initializes handling <mat-select [multiple]="true">
   * Note: to improve this code, mat-select should be extended to allow disabling resetting the selection while filtering.
   */
  private initMultipleHandling() {
    // if <mat-select [multiple]="true">
    // store previously selected values and restore them when they are deselected
    // because the option is not available while we are currently filtering
    this.matSelect.valueChange.pipe(takeUntil(this._onDestroy)).subscribe(values => {
      if (this.matSelect.multiple) {
        let restoreSelectedValues = false;

        if (this.matSelect.options.length === values.length) {
          this.selectAllCheckbox.setValue(true);
        } else {
          this.selectAllCheckbox.setValue(null);
        }
        if (this._value && this._value.length && this.previousSelectedValues && Array.isArray(this.previousSelectedValues)) {
          if (!values || !Array.isArray(values)) {
            values = [];
          }
          const optionValues = this.matSelect.options.map(option => option.value);

          this.previousSelectedValues.forEach(previousValue => {
            if (values.indexOf(previousValue) === -1 && optionValues.indexOf(previousValue) === -1) {
              // if a value that was selected before is deselected and not found in the options, it was deselected
              // due to the filtering, so we restore it.
              values.push(previousValue);
              restoreSelectedValues = true;
            }
          });
        }
        if (restoreSelectedValues) {
          this.matSelect._onChange(values);
        }

        this.previousSelectedValues = values;
      }
    });
  }

  private setCustomPanelClass(name: string) {
    const className = name ? `mat-select-search-panel ${name}` : 'mat-select-search-panel';

    if (this.matSelect.panelClass) {
      if (Array.isArray(this.matSelect.panelClass)) {
        this.matSelect.panelClass = [];
        this.matSelect.panelClass.push(className);
      } else if (typeof this.matSelect.panelClass === 'string') {
        this.matSelect.panelClass = [this.matSelect.panelClass, className];
      } else if (typeof this.matSelect.panelClass === 'object') {
        this.matSelect.panelClass[className] = true;
      }
    } else {
      this.matSelect.panelClass = className;
    }
  }

  private setPosition(): void {
    const panelRef: HTMLElement = document.getElementsByClassName('cdk-overlay-pane-select-search')[0] as HTMLElement;
    const listRef: HTMLElement = document.getElementsByClassName('mat-select-search-panel')[0] as HTMLElement;
    const marginPanel = 75;

    try {
      if (panelRef && panelRef.offsetTop + listRef.offsetHeight + marginPanel > window.innerHeight) {
        const panelWrapRef: HTMLElement = document.getElementsByClassName('mat-select-panel-wrap')[0] as HTMLElement;

        if (panelWrapRef) {
          panelWrapRef.classList.add('mat-select-panel-wrap-top');
        }
      }
      if (listRef) {
        listRef.classList.add('mat-select-search-panel-visible');
      }
    } catch (e) {
      console.warn(e);
    }
  }
}
