import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  IFusionChartCategory,
  IFusionChartData,
  IFusionChartDataSet,
} from '../../../../vendor/types/fusionCharts';
import { DashboardDatesService } from '../../../components/venue/venue-shared/dashboard-dates.service';
import { ChartUtilsService } from '../../../core/utils';
import { CONSTANTS } from '../../constants';
import { IQuestionTrendlineDataSet } from '../questions-stats/interfaces/questions-stats.interface';
import { ISmallTrendlineDataSet } from './small-trendline.interface';

const defaultWidth = '80';
const defaultHeight = '60';

@Component({
  selector: 'app-small-trendline',
  templateUrl: './small-trendline.component.html',
  styleUrls: ['./small-trendline.component.scss'],
})
export class SmallTrendlineComponent {
  @Input() set chartData(values: ISmallTrendlineDataSet) {
    this.initChart(values);
  }

  @Input() showLabels = false;
  @Input() width = defaultWidth;
  @Input() height = defaultHeight;

  dataSource;
  defaultHeight = defaultHeight;
  private chartCategories: IFusionChartCategory[] = [];

  constructor(
    private dashboardDatesService: DashboardDatesService,
    private chartUtilsService: ChartUtilsService,
    private translateService: TranslateService
  ) {}

  private initChart(value: ISmallTrendlineDataSet): void {
    const answerChartData: IFusionChartData[] =
      value && value.answers && value.answers.length > 0
        ? this.getChartData(value.answers)
        : [];
    const chartDataSet: IFusionChartDataSet = value && value.isBarChart
      ? {
          seriesName: this.translateService.instant('SHARED.SYSTEM.FOODBACKS'),
          renderAs: 'column',
          parentYAxis: 'S',
          color:
            CONSTANTS.CHART_COLORS.CAMPAIGNS.CHART_TRENDLINE.backgroundColor,
          data: answerChartData,
        }
      : {
          renderAs: 'splinearea',
          drawAnchors: false,
          plotBorderAlpha: 0,
          parentYAxis: 'S',
          color:
            CONSTANTS.CHART_COLORS.CAMPAIGNS.CHART_TRENDLINE.backgroundColor,
          data: answerChartData,
        };
    const dataset: IFusionChartDataSet[] = [chartDataSet];

    if (value && value.averagePeriods && value.averagePeriods.length > 0) {
      dataset.push({
        seriesName: this.translateService.instant('SHARED.TOP_LEVEL_DASHBOARD.AVG_OVERALL_SCORE'),
        renderAs: 'spline',
        drawAnchors: false,
        plotBorderAlpha: 0,
        color: CONSTANTS.CHART_COLORS.CAMPAIGNS.primaryColor,
        data: this.getChartData(value.averagePeriods),
      });
    }

    this.initChartWithConfig();
    this.dataSource.dataset = dataset;
    this.dataSource.categories = [
      {
        category: this.chartCategories,
      },
    ];
  }

  private initChartWithConfig(): void {
    const defaultMargin = 20;
    const additionalOptions: any = {
      anchorRadius: 3,
      pYAxisMaxValue: 6.0,
      sYAxisMaxValue: 6.0,
      forceYAxisValueDecimals: 0,
      forceSYAxisValueDecimals: 0,
      setAdaptiveYMin: 1,
      showXAxisLine: false,
      showYAxisLine: false,
      showLabels: false,
      showToolTip: this.showLabels,
      showYAxisValues: false,
      showDivLineValues: this.showLabels,
      showDivLineSecondaryValue: this.showLabels,
      showVLineLabelBorder: false,
      showPlotBorder: false,
      showcanvasborder: false,
      divLineAlpha: 0,
      chartLeftMargin: this.showLabels ? defaultMargin : 0,
      chartRightMargin: this.showLabels ? defaultMargin : 0,
      chartTopMargin: 0,
      chartBottomMargin: 0,
      legendPadding: 0,
      canvasPadding: 0,
      legendItemFontSize: 8,
    };

    this.dataSource =
      this.chartUtilsService.initChartWithDataSetAndCategories(
        additionalOptions
      );
  }

  private getChartData(
    values: IQuestionTrendlineDataSet[]
  ): IFusionChartData[] {
    const dateRange = this.dashboardDatesService.getLastXDaysDateRange(
      CONSTANTS.DASHBOARD.LAST_X_DAYS.RESPONSE_RATE
    );
    const dateRangeArray: string[] = this.dashboardDatesService.getAllDates(
      dateRange.start,
      dateRange.end
    );

    this.chartCategories = dateRangeArray.map((date: string) => ({
      label: date,
    }));

    return dateRangeArray.map((date: string) => {
      const valueAtDate = values.find(value => value.date === date);

      return {
        value: valueAtDate ? valueAtDate.count.toString() : '0',
        color: CONSTANTS.CHART_COLORS.CAMPAIGNS.CHART_TRENDLINE.backgroundColor,
        toolText: this.chartUtilsService.getTooltipTextForItem(),
      };
    });
  }
}
