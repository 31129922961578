<div class="PageNotFound">
  <div class="PageNotFound-content">
    <object type="image/svg+xml" data="./assets/svg/ghost.svg" width="47" height="55"></object>
    <span class="h3"
      ><b>{{ 'NOT_FOUND_PAGE.HEADER' | translate }}</b></span
    >
    <span class="h4 mb-lg" [innerHTML]="'NOT_FOUND_PAGE.CONTENT' | translate"> </span>
    <span class="h4">{{ 'NOT_FOUND_PAGE.LOGIN_AGAIN' | translate }}</span>
    <a class="btn btn-foodback text-uppercase mb-lg" href="/login">{{ 'NOT_FOUND_PAGE.GO_TO_LOGIN' | translate }}</a>
    <span class="h4">{{ 'SHARED.SYSTEM.CHEERS' | translate }},<br />{{ 'SHARED.SYSTEM.FOODBACK_TEAM' | translate }}</span>
    <object type="image/svg+xml" data="./assets/svg/white-cloud-logo.svg" width="60" height="60"></object>
  </div>
</div>
