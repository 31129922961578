import { Component } from '@angular/core';
import { LogoutService } from '../login/logout.service';

@Component({
  selector: 'app-no-access',
  templateUrl: './no-access.component.html',
  styleUrls: ['./no-access.component.scss'],
})
export class NoAccessComponent {
  constructor(private logoutService: LogoutService) {}

  logOut(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.logoutService.logOut();
  }
}
