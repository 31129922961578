<form [formGroup]="advertisementForm" class="col-md-7">
  <h4 class="page-header mt0">{{ 'SHARED.SYSTEM.WHAT_IS_THIS' | translate }}</h4>

  <p [innerHTML]="'SHARED.COMPONENTS.ADVERTISEMENT.MOBILE_CATEGORY.ABOUT.CONTENT' | translate"></p>

  <h4 class="page-header mt-xl">{{ 'SHARED.COMPONENTS.ADVERTISEMENT.MOBILE_CATEGORY.FILE.HEADER' | translate }}</h4>

  {{ 'SHARED.COMPONENTS.ADVERTISEMENT.MOBILE_CATEGORY.FILE.CURRENT_FILE_LABEL' | translate }}

  <div class="AdvertisementMobile-currentFile mt-sm" *ngIf="advertisementLastChangeData">
    {{
      'SHARED.COMPONENTS.ADVERTISEMENT.MOBILE_CATEGORY.FILE.CURRENT_FILE_MESSAGE'
        | translate: { '0': getAdvertisementLastChangeDataFormat(), '1': getAdvertisementLastChangeHourFormat() }
    }}
  </div>

  <div class="AdvertisementMobile-currentFile mt-sm" *ngIf="!advertisementLastChangeData">
    <!--TODO Change this for the proper translation-->
    {{ 'SHARED.SYSTEM.NONE' | translate }}
  </div>

  <div class="AdvertisementMobile-uploadWrapper mt-lg">
    <label for="file" class="file-upload btn btn-foodback text-uppercase mr-sm">
      {{ 'SHARED.SYSTEM.REPLACE_AD' | translate }}
      <input
        #selectedPicture
        title="{{ 'SHARED.SYSTEM.REPLACE_AD' | translate }}"
        id="file"
        type="file"
        ng2FileSelect
        onclick="this.value = null" 
        [uploader]="uploader" />
    </label>
    <button class="btn btn-outline btn-foodback text-uppercase" type="button" (click)="backToDefault()">
      {{ 'SHARED.SYSTEM.BACK_TO_DEFAULT' | translate }}
    </button>
  </div>

  <h4 class="page-header mt-xl">{{ 'SHARED.SYSTEM.URL' | translate }}</h4>

  {{ 'SHARED.COMPONENTS.ADVERTISEMENT.MOBILE_CATEGORY.URL.CONTENT' | translate }}

  <div class="mt-lg">
    <label class="c-checkbox checkbox mb0 mt0 clickable checkbox-inline">
      <input formControlName="isAdvertisementEnabled" type="checkbox" />
      <span class="fa fa-check fb-checkbox"></span>
      {{ 'SHARED.COMPONENTS.ADVERTISEMENT.MOBILE_CATEGORY.URL.CHECKBOX_TEXT' | translate }}
    </label>
  </div>
  <mat-form-field class="mt-lg AdvertisementMobile-advertisementUrl">
    <mat-label>{{ 'SHARED.COMPONENTS.ADVERTISEMENT.MOBILE_CATEGORY.URL.INPUT_PLACEHOLDER' | translate }}</mat-label>
    <input
      matInput
      autocomplete="off"
      list="autocompleteOff"
      formControlName="advertisementURLCtrl"
      type="text"
      [placeholder]="'SHARED.COMPONENTS.ADVERTISEMENT.MOBILE_CATEGORY.URL.INPUT_PLACEHOLDER' | translate" />
    <mat-error appErrors="advertisementURLCtrl" [errors]="errors"> </mat-error>
  </mat-form-field>
</form>

<div class="col-md-5">
  <h4 class="page-header mt0">{{ 'SHARED.COMPONENTS.ADVERTISEMENT.MOBILE_CATEGORY.PREVIEW.HEADER' | translate }}</h4>
  {{ 'SHARED.COMPONENTS.ADVERTISEMENT.MOBILE_CATEGORY.PREVIEW.CONTENT' | translate }}
  <div class="AdvertisementMobile-previewWrapper mt-lg">
    <p class="AdvertisementMobile-previewHeader">{{ 'SHARED.COMPONENTS.ADVERTISEMENT.MOBILE_CATEGORY.PREVIEW.DEMO-HEADER' | translate }}</p>
    <span class="AdvertisementMobile-previewSubHeader">{{
      'SHARED.COMPONENTS.ADVERTISEMENT.MOBILE_CATEGORY.PREVIEW.DEMO-SUBHEADER' | translate
    }}</span>
    <span class="AdvertisementMobile-previewFlag flag-icon flag-icon-bigger flag-icon-round" [ngClass]="'flag-icon-' + language"></span>
    <img [src]="'/assets/img/mobile-advertisement-screen.jpg'" class="AdvertisementMobile-previewWrapper-image" />
    <div class="AdvertisementMobile-previewWrapper-advertisementPlacement">
      <img
        *ngIf="avatarURL; else defaultAdScreen"
        [src]="avatarURL"
        alt="adv"
        class="AdvertisementMobile-previewWrapper-advertisementPlacement-image" />
      <ng-template #defaultAdScreen>
        <img src="assets/img/default-adv.png" alt="adv" class="AdvertisementMobile-previewWrapper-advertisementPlacement-image" />
      </ng-template>
      <div *ngIf="imageLoading" class="AdvertisementMobile-loader preloader-wrapper">
        <app-preloader [isSingleMode]="false"></app-preloader>
      </div>
    </div>
  </div>
</div>
