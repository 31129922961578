import { Injectable } from '@angular/core';
import { IDropdownItem } from '../../../shared/interfaces/dropdown/dropdown-item.interface';

const DEFAULT_INTERVAL = 30;

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  createArrayOfSelects(interval: number = 0 || DEFAULT_INTERVAL): IDropdownItem[] {
    const hours: IDropdownItem[] = [];
    const mainCounter = 24;

    for (let i = 0; i < mainCounter; i++) {
      const defaultFormat = `${i}:00`;
      const labelValue = 10;
      const singleLabel = i < labelValue ? `0${defaultFormat}` : defaultFormat;

      hours.push({
        id: singleLabel,
        text: singleLabel,
      });

      // eslint-disable-next-line
      if (interval === DEFAULT_INTERVAL) {
        const format = `${i}:30`;
        const sLabel = i < labelValue ? `0${format}` : format;

        hours.push({
          id: sLabel,
          text: sLabel,
        });
      }
    }

    return hours;
  }

  formatNumber(num: number): string {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1 `);
  }
}
