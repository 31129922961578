import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FileUploaderOptions } from 'ng2-file-upload';
import { environment } from '../../../../environments/environment';
import { CONSTANTS } from '../../../shared/constants';
import { ImageMetaData } from '../../interfaces/image-meta-data';
import { ImageResolutionRequirements } from '../../interfaces/image-resolution-options';
import { ImageValidationMsg } from '../../interfaces/image-validation-msg';
import { NotificationService } from '../notification/notification.service';
import { UserService } from '../user/user.service';

@Injectable()
export class UploadService {
  private validationMsg: ImageValidationMsg = {
    isValid: true,
    validationBodyMsg: 'Image is valid and sent to database',
    validationTitle: 'Validate Image',
  };

  constructor(
    private userService: UserService,
    private translateService: TranslateService,
    private notificationService: NotificationService
  ) {}

  getUploaderOptions(
    maxFileSize: number,
    allowedMimeType: string[] = CONSTANTS.IMAGES.TYPES,
    options?: FileUploaderOptions
  ): FileUploaderOptions {
    const { idToken } = this.userService.getUserDataForRequest();
    const headers = [
      {
        name: 'Authorization',
        value: `Bearer ${idToken}`,
      },
    ];
    const defaultOptions: FileUploaderOptions = {
      headers,
      maxFileSize,
      allowedMimeType,
      autoUpload: true,
      url: environment.urlForUpload,
    };

    return options || defaultOptions;
  }

  validateStrictResolution(imageMetaData: ImageMetaData, requirements: ImageResolutionRequirements): ImageValidationMsg {
    if (
      (imageMetaData.height !== requirements.height && imageMetaData.height !== requirements.optionalHeight) ||
      imageMetaData.width !== requirements.width
    ) {
      this.validationMsg.isValid = false;
      this.validationMsg.validationBodyMsg = this.translateService.instant('SHARED.VALIDATION_MSG.IMAGES.IMAGE_RESOLUTION', {
        width: requirements.width,
        height: requirements.height,
      });
      this.validationMsg.validationTitle = this.translateService.instant('SHARED.VALIDATION_MSG.IMAGES.WRONG_IMAGE_RESOLUTION');
    } else {
      this.setDefaultMsg();
    }

    return this.validationMsg;
  }

  validateStrictMinimumResolution(imageMetaData: ImageMetaData, requirements: ImageResolutionRequirements): ImageValidationMsg {
    if (
      imageMetaData === undefined ||
      imageMetaData.height < requirements.height ||
      imageMetaData.width < requirements.width ||
      imageMetaData.height !== imageMetaData.width
    ) {
      this.validationMsg.isValid = false;
      this.validationMsg.validationBodyMsg = this.translateService.instant('SHARED.VALIDATION_MSG.IMAGES.IMAGE_MIN_RESOLUTION', {
        width: requirements.width,
        height: requirements.height,
        size: 1
      });
      this.validationMsg.validationTitle = this.translateService.instant('SHARED.VALIDATION_MSG.IMAGES.WRONG_IMAGE_RESOLUTION');   
    } else {
      this.setDefaultMsg();
    }

    return this.validationMsg;
  }

  validateBranding(imageMetaData: ImageMetaData): ImageValidationMsg {
    const requirements = {
      minHeight: CONSTANTS.IMAGES.BRANDING_MIN_HEIGHT,
      minWidth: CONSTANTS.IMAGES.BRANDING_MIN_WIDTH,
      minAspectRatio: CONSTANTS.IMAGES.MIN_ASPECT_RADIO,
    };

    if (imageMetaData.height < requirements.minHeight && imageMetaData.width < requirements.minWidth) {
      this.validationMsg.isValid = false;
      this.validationMsg.validationBodyMsg = this.translateService.instant('SHARED.VALIDATION_MSG.IMAGES.IMAGE_SHOULD_HAVE_MIN', {
        width: requirements.minWidth,
        height: requirements.minHeight
      });
      this.validationMsg.validationTitle = this.translateService.instant('SHARED.VALIDATION_MSG.IMAGES.WRONG_IMAGE_RESOLUTION');
    } else {
      this.setDefaultMsg();
    }

    this.validationMsg.isWarning = imageMetaData.width / imageMetaData.height < requirements.minAspectRatio;

    return this.validationMsg;
  }

  displayError(
    title: string = this.translateService.instant('SHARED.VALIDATION_MSG.IMAGES.DEFAULT_TITLE'),
    body: string = this.translateService.instant('SHARED.VALIDATION_MSG.IMAGES.DEFAULT_BODY')
  ) {
    this.notificationService.displaySwalPopup(title, body, 'error');
  }

  private setDefaultMsg() {
    this.validationMsg = {
      isValid: true,
      validationBodyMsg: this.translateService.instant('SHARED.VALIDATION_MSG.IMAGES.IMAGE_IS_VALID'),
      validationTitle: this.translateService.instant('SHARED.VALIDATION_MSG.IMAGES.VALIDATE_IMG'),
    };
  }
}
