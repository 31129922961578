<div class="FilterContainer" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
  <mat-form-field>
    <mat-label>{{ 'HOME.FOODBACKS.COMMENT_CONTAINS' | translate }}</mat-label>
    <input
      [formControl]="commentCtrl"
      matInput
      autocomplete="off"
      list="autocompleteOff"
      [placeholder]="'HOME.FOODBACKS.FILTER_PLACEHOLDER' | translate"/>
  </mat-form-field>
  <mat-checkbox class="ThinCheckbox" [(ngModel)]="hasComment"
                (ngModelChange)="onHasCommentChanged()">{{'HOME.FOODBACKS.HAS_COMMENT' | translate}}</mat-checkbox>
</div>
