<div class="PercentCircleWrapper" [ngClass]="getColorClassForRating()">
  <svg viewBox="0 0 36 36" class="PercentCircle">
    <path
      class="PercentCircle-background"
      [ngClass]="{
        'PercentCircle-backgroundStroke': variant !== PercentCircleVariant.FLAT_PRIMARY
      }"
      d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831" />
    <path
      class="PercentCircle-fill PercentCircle-fill--goal x"
      [ngClass]="{
        'PercentCircle-fill-bigStroke PercentCircle-lightGrayBg': variant === PercentCircleVariant.FLAT_PRIMARY
      }"
      *ngIf="goal"
      [attr.stroke-dasharray]="getPercentageValue(getRoundedGoalValue()) + ', 100'"
      d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831" />
    <path
      class="PercentCircle-fill"
      *ngIf="percentValue"
      [ngClass]="{
        'PercentCircle-fill-bigStroke': variant === PercentCircleVariant.FLAT_PRIMARY,
        'PercentCircle-fill--primary': variant === PercentCircleVariant.FLAT_PRIMARY,
        'PercentCircle-fill--gray': variant === PercentCircleVariant.GRAY,
        'PercentCircle-fill--current-score': variant === PercentCircleVariant.BLUE
      }"
      [attr.stroke-dasharray]="getPercentageValue(getRoundedRatingValue()) + ', 100'"
      d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831" />
    <ng-container *ngIf="!useCustomContent">
      <text x="18" y="22.25" class="PercentCircle-text">{{ percentValue | number: CONSTANTS.NUMBER_PIPE.PERCENT }}%</text>
    </ng-container>
  </svg>
  <div *ngIf="useCustomContent" class="PercentCircleWrapper-customContent">
    <ng-content select="[custom-content]"></ng-content>
  </div>
</div>
