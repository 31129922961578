// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  apiBaseUrl: {
    baseUrl: 'https://dev.devfoodback.com',
    handbook: 'https://dev.devfoodback.com/api/v1',
    translation: './assets/translation',
    authorization: 'https://dev.devfoodback.com/api/v1',
  },
  urlForUpload: 'https://dev.devfoodback.com/api/v1/storage/files',
  buildNumber: 'BUILD_NUMBER',
  version: 'VERSION',
  buildDate: 'BUILD_DATE'
};
