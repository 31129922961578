<app-dialog>
  <app-dialog-title> {{ 'HOME.FOODBACKS.CONTACTED.UPDATE_CONTACT_CUSTOMER' | translate }} </app-dialog-title>
  <app-dialog-content>
    <form [formGroup]="form">
      <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="30px">
        <app-select
          [items]="contactStatuses"
          [label]="'SHARED.SYSTEM.STATUS' | translate"
          [required]="true"
          [isSearchModeEnable]="false"
          formControlName="isContacted" fxFlex>
        </app-select>
        <app-select
          [items]="users"
          [label]="'SHARED.SYSTEM.OWNER' | translate"
          [required]="true"
          [isSearchModeEnable]="true"
          formControlName="ownerUuid" fxFlex>
        </app-select>
      </div>
      <app-textarea-with-counter
        formControlName="note"
        [label]="'HOME.FOODBACKS.CONTACTED.INTERNAL_NOTES' | translate"
        [placeholder]="'HOME.FOODBACKS.CONTACTED.INTERNAL_NOTES' | translate"
        [maxLength]="CONSTANTS.VALIDATION.CONTACT.NOTE_MAX_LENGTH"
        [minHeight]="CONSTANTS.ELEMENTS_HEIGHT.PX_70">
      </app-textarea-with-counter>
    </form>
  </app-dialog-content>
  <app-dialog-actions>
    <button type="button" class="btn btn-foodback btn-foodback--secondary text-uppercase mr-sm" (click)="hide()">
      {{ 'ACCOUNT.FOLDER.QR_URLS.BUTTONS.CANCEL' | translate }}
    </button>
    <button type="button" class="btn btn-foodback text-uppercase" data-dismiss="modal" (click)="save()">
      {{ 'SHARED.SYSTEM.SAVE' | translate }}
    </button>
  </app-dialog-actions>
</app-dialog>
