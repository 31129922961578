<ul
  *ngFor="let sidebar of sidebars"
  (mouseover)="sidebar.submenu.length ? onSidebarHover(sidebar, $event) : $event.preventDefault()"
  (mouseout)="onSidebarBlur(sidebar, $event)"
  class="nav">
  <li [class.active]="sidebar.isActive">
    <a (click)="toggleSidebar(sidebar)">
      <em [ngClass]="sidebar.icon"></em>
      <span>{{ sidebar.title | translate }}</span>
    </a>

    <ul
      [ngClass]="{
        'expanded-submenu': sidebar.isExpanded,
        opening: sidebar.isExpanded
      }"
      class="nav sidebar-subnav">
      <li *ngFor="let submenu of sidebar.submenu">
        <a [routerLink]="submenu.link" routerLinkActive="sub-active">
          <span>{{ submenu.title | translate }}</span>
        </a>
      </li>
    </ul>
  </li>
</ul>
