export enum SidebarSettingsMenus {
  CONFIGURATION = 'CONFIGURATION',
  BILL_FOLDERS = 'BILL_FOLDERS',
  DETAILS = 'DETAILS',
  GOALS = 'GOALS',
  GROUPS = 'GROUPS',
  QUESTIONS = 'QUESTIONS',
  USER_MANAGEMENT = 'USER_MANAGEMENT',
  VENUE_MANAGEMENT = 'VENUE_MANAGEMENT',
  WHEEL_OF_FORTUNE = 'WHEEL_OF_FORTUNE',
  FOODBACK_PORTAL = 'FOODBACK_PORTAL',
  ONE_MORE_QUESTION = 'ONE_MORE_QUESTION',
  CAMPAIGNS = 'CAMPAIGNS',
}
