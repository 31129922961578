import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { pick, pickBy, toPairs } from 'lodash';
import { Observable, Subject } from 'rxjs';
import { NotificationService } from '../../../core/providers/notification/notification.service';
import { CONSTANTS } from '../../constants';
import { VenueCategoryDetails } from '../../interfaces/analytics.interface';
import { SharedComponentViewType } from '../../interfaces/common.interface';
import { I18nAvailableLanguage } from '../../interfaces/i18n.interface';
import {
  Statement,
  StatementActivationChangeStatus,
  StatementGroup,
  StatementPopupType,
  StatementTranslation,
  StatementTranslationFromBackend,
  StatementsFromBackend,
} from '../../interfaces/statements.interface';
import { PatternSortPipe } from '../../pipes/pattern-sort.pipe';

@Injectable()
export class FoodbackQuestionsService {
  // large part of this code were created long long time ago, when things were named in a different way
  // so now: group => category, statement => question

  private static readonly QUESTION_GROUP_ORDER = [
    CONSTANTS.CATEGORIES.NAMES.OVERALL_EXPERIENCE,
    CONSTANTS.CATEGORIES.NAMES.MEAL,
    CONSTANTS.CATEGORIES.NAMES.SERVICE,
    CONSTANTS.CATEGORIES.NAMES.ATMOSPHERE,
    CONSTANTS.CATEGORIES.NAMES.RESTAURANT,
  ];

  private addQuestionSubject: Subject<void> = new Subject<void>();
  private renameCategoriesSubject: Subject<void> = new Subject<void>();

  constructor(private notificationService: NotificationService, private translateService: TranslateService) {}

  mapBackendResponseToQuestionGroups(backendResponse: StatementsFromBackend): StatementGroup[] {
    const patternSortPipe = new PatternSortPipe();
    const orderedGroupNames = patternSortPipe.transform(Object.keys(backendResponse), FoodbackQuestionsService.QUESTION_GROUP_ORDER);

    return orderedGroupNames.map(groupName => {
      const group = backendResponse[groupName];
      const statements: Statement[] = group.map(groupEntry => ({
        ...groupEntry,
        translations: this.getTranslationsFromQuestionGroupEntry(groupEntry.translations),
      }));

      return {
        groupName,
        statements,
        groupUuid: group[0] && group[0].groupUuid, // group uuid is not sent at the top level, so we extract it from the first question
      };
    });
  }

  pickSpecificCategoriesFromStatementsResponse(
    statements: StatementsFromBackend,
    categories: VenueCategoryDetails[]
  ): StatementsFromBackend {
    const categoriesNames = categories.map(category => category.name);

    return pick(statements, categoriesNames);
  }

  getTranslationsFromQuestionGroupEntry(translations: StatementTranslationFromBackend[]): StatementTranslation {
    if (!translations) {
      return {};
    }

    return translations.reduce((reduced, translation) => {
      reduced[translation.lang] = translation.value;

      return reduced;
    }, {});
  }

  getTranslationsForAccountStatementsForm(
    availableLanguages: I18nAvailableLanguage[],
    translations: StatementTranslation
  ): StatementTranslation {
    const emptyTranslations = availableLanguages.reduce((reduced, lang) => {
      reduced[lang.code] = '';

      return reduced;
    }, {});
    const suppliedTranslations = pickBy(translations, (_value, key) =>
      availableLanguages.find(availableLanguage => availableLanguage.code === key)
    );

    return { ...emptyTranslations, ...suppliedTranslations };
  }

  getTranslationsAsKeyValuePairs(translations: StatementTranslation): StatementTranslationFromBackend[] {
    return toPairs(translations)
      .map(([lang, value]: [string, string]) => ({ lang, value }))
      .filter((entry: StatementTranslationFromBackend) => entry.value);
  }

  addQuestion(): void {
    this.addQuestionSubject.next();
  }

  renameCategories(): void {
    this.renameCategoriesSubject.next();
  }

  onAddQuestion(): Observable<void> {
    return this.addQuestionSubject.asObservable();
  }

  onRenameCategories(): Observable<void> {
    return this.renameCategoriesSubject.asObservable();
  }

  displayActivationTogglingConfirmationPopup$(
    viewType: SharedComponentViewType,
    popupType: StatementPopupType,
    newValue: any
  ): Observable<boolean> {
    let translateKey: string;

    switch (popupType) {
      case StatementPopupType.ENFORCEMENT:
        translateKey = newValue
          ? 'SHARED.FOODBACK_STATEMENTS.MAIN.STATE_CHANGE_MODALS.ACCOUNT.ENFORCE'
          : 'SHARED.FOODBACK_STATEMENTS.MAIN.STATE_CHANGE_MODALS.ACCOUNT.STOP_ENFORCING';
        break;
      case StatementPopupType.ACTIVATION:
        translateKey = this.getTranslateKeyForEnablingConfirmation(viewType, newValue);
        break;
      default:
        break;
    }

    return this.notificationService.displayConfirmationPopupWithTranslationKey(translateKey);
  }

  displayStatusChangeResultPopup(
    viewType: SharedComponentViewType,
    popupType: StatementPopupType,
    newValue: any,
    response: StatementActivationChangeStatus
  ): void {
    let translateKey: string;

    switch (popupType) {
      case StatementPopupType.ENFORCEMENT:
        translateKey = newValue
          ? 'SHARED.FOODBACK_STATEMENTS.MAIN.STATE_CHANGE_MODALS.ACCOUNT.ENFORCE_RESULT'
          : 'SHARED.FOODBACK_STATEMENTS.MAIN.STATE_CHANGE_MODALS.ACCOUNT.STOP_ENFORCING_RESULT';
        break;
      case StatementPopupType.ACTIVATION:
        translateKey = this.getTranslateKeyForEnablingResult(viewType, newValue);
        break;
      default:
        break;
    }

    this.notificationService.successWithTranslationKey(translateKey);

    if (response && response.statementActive && response.missingTranslationsForLanguages) {
      this.showMissingTranslationsNotification(response.missingTranslationsForLanguages);
    }
  }

  showMissingTranslationsNotification(missingTranslationsForLanguages: string[]): void {
    missingTranslationsForLanguages.forEach((translationCode: string) => {
      const language: string = this.translateService.instant(`SHARED.LANGUAGES.${translationCode.toUpperCase()}`);
      const info: string = this.translateService.instant('ACCOUNT.FOODBACK.NOTIFICATIONS.MISSING_TRANSLATION.TEXT', { language });

      this.notificationService.warning(info);
    });
  }

  displayEditConfirmationPopup$(): Observable<boolean> {
    return this.notificationService.displayConfirmationPopupWithTranslationKey('ACCOUNT.FOODBACK.NOTIFICATIONS.EDIT_CONFIRMATION_SUCCESS');
  }

  displayDeleteConfirmationPopup$(): Observable<boolean> {
    return this.notificationService.displayConfirmationPopupWithTranslationKey(
      'ACCOUNT.FOODBACK.NOTIFICATIONS.DELETE_CONFIRMATION_SUCCESS'
    );
  }

  private getTranslateKeyForEnablingConfirmation(viewType: SharedComponentViewType, isEnabled: boolean): string {
    switch (viewType) {
      case SharedComponentViewType.ACCOUNT:
        return isEnabled
          ? 'SHARED.FOODBACK_STATEMENTS.MAIN.STATE_CHANGE_MODALS.ACCOUNT.ACTIVATE'
          : 'SHARED.FOODBACK_STATEMENTS.MAIN.STATE_CHANGE_MODALS.ACCOUNT.DEACTIVATE';
      case SharedComponentViewType.VENUE:
        return isEnabled
          ? 'SHARED.FOODBACK_STATEMENTS.MAIN.STATE_CHANGE_MODALS.VENUE.ACTIVATE'
          : 'SHARED.FOODBACK_STATEMENTS.MAIN.STATE_CHANGE_MODALS.VENUE.DEACTIVATE';
      default:
        break;
    }
  }

  private getTranslateKeyForEnablingResult(viewType: SharedComponentViewType, isEnabled: boolean): string {
    switch (viewType) {
      case SharedComponentViewType.ACCOUNT:
        return isEnabled ? 'SHARED.NOTIFICATIONS.ACTIVATE_RESULT_SUCCESS' : 'SHARED.NOTIFICATIONS.DEACTIVATE_RESULT_SUCCESS';
      case SharedComponentViewType.VENUE:
        return isEnabled ? 'SHARED.NOTIFICATIONS.ACTIVATE_RESULT_SUCCESS' : 'SHARED.NOTIFICATIONS.DEACTIVATE_RESULT_SUCCESS';
      default:
        break;
    }
  }
}
