import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { StatementsFromBackend } from '../../../../shared/interfaces/statements.interface';
import { TranslateService } from '@ngx-translate/core';
import { first, mergeMap, takeUntil } from 'rxjs/operators';
import { round } from 'lodash';
import { Observable, Subject, forkJoin } from 'rxjs';
import { GoalsBackendService } from '../../../../core/providers/backend/goals-backend.service';
import { StatementsBackendService } from '../../../../core/providers/backend/statements-backend.service';
import { ContextService } from '../../../../core/providers/context/context.service';
import { NotificationService } from '../../../../core/providers/notification/notification.service';
import { CONSTANTS } from '../../../../shared/constants';
import { GoalsCategoryDetails, GoalsResponseMapped, GoalsUpdateEntry } from '../../../../shared/interfaces/goals-backend.interface';
import { ContextObject } from '../../../../shared/interfaces/login-object';
import { GoalsForm } from './acc-goals.interface';
import { AccGoalsService } from './acc-goals.service';

/**
 * Account's goals settings
 */
@Component({
  selector: 'app-acc-goals',
  templateUrl: './acc-goals.component.html',
  styleUrls: ['./acc-goals.component.scss'],
})
export class AccGoalsComponent implements OnInit, OnDestroy {
  categories: GoalsCategoryDetails[];
  questionsInCategories: string[][];
  goals: GoalsResponseMapped;
  goalsForm: FormGroup;
  contextUuid: string;
  isSaveButtonDisabled = true;
  saving = false;
  accountChildrenNameSingular: string;
  accountChildrenNamePlural: string;
  private MAX_GOALS_PRECISION = 1;
  private isAccountContext: boolean;
  private onDestroy$ = new Subject<void>();

  constructor(
    private formBuilder: FormBuilder,
    private contextService: ContextService,
    private goalsBackend: GoalsBackendService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private statementsBackendService: StatementsBackendService,
    private accGoalsService: AccGoalsService
  ) {}

  onSubmit({ value, valid }: { value: GoalsForm; valid: boolean }): void {
    if (valid) {
      this.manageValidForm(value);
    }
  }

  get categoriesFormControls() {
    return (this.goalsForm.get('categories') as FormArray).controls;
  }

  ngOnInit() {
    this.contextService
      .getContext$()
      .pipe(first(), takeUntil(this.onDestroy$))
      .subscribe((context: ContextObject) => {
        this.isAccountContext = context.type === CONSTANTS.CONTEXT.ACCOUNT;
        this.contextUuid = context.uuid;
        (this.isAccountContext ? this.handleAccountContext$() : this.handleVenueContext$())
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(([goalsResponse, statementsResponse]: [GoalsResponseMapped, StatementsFromBackend]) => {
            this.handleGoalsResponse(goalsResponse);
            this.handleStatementsResponse(statementsResponse);
          });
      });
      this.accountChildrenNameSingular = this.contextService.getAccountChildrenNameTranslation(true);
      this.accountChildrenNamePlural = this.contextService.getAccountChildrenNameTranslation(false);
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private formStatusChanges$(): Observable<string> {
    return this.goalsForm.statusChanges;
  }

  private handleAccountContext$(): Observable<[GoalsResponseMapped, StatementsFromBackend]> {
    return forkJoin([
      this.goalsBackend.getGoalsForAccount$(this.contextUuid),
      this.statementsBackendService.getActiveStatementsForAccount$(this.contextUuid),
    ]);
  }

  private handleVenueContext$(): Observable<[GoalsResponseMapped, StatementsFromBackend]> {
    return forkJoin([
      this.goalsBackend.getGoalsForVenue$(this.contextUuid),
      this.statementsBackendService.getActiveStatementsForVenue$(this.contextUuid),
    ]);
  }

  private handleGoalsResponse(response: GoalsResponseMapped): void {
    const { overallExperience, categories } = response;

    categories.sort((a, b) => a.category.orderPriority - b.category.orderPriority);
    this.categories = categories.map(categoryEntry => categoryEntry.category);
    this.goals = response;
    this.goalsForm = this.formBuilder.group({
      overallExperience: [overallExperience.targetAverage, this.getValidators()],
      categories: this.formBuilder.array(
        categories.map(category => this.formBuilder.control(category.targetAverage, this.getValidators()))
      ),
    });
    this.formStatusChanges$()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(status => {
        this.isSaveButtonDisabled = status !== 'VALID';
      });
    this.goalsForm.updateValueAndValidity();
  }

  private handleStatementsResponse(response: StatementsFromBackend): void {
    this.questionsInCategories = this.categories.map(category => {
      const allActiveCategoryQuestions = response[category.name].filter(question => question.isEnabled);

      return this.accGoalsService
        .sortQuestionsByStandardFirst(allActiveCategoryQuestions)
        .map(question => this.accGoalsService.getEnglishTranslationOrFirst(question));
    });
  }

  private getValidators(): ValidatorFn[] {
    return [Validators.required, Validators.min(CONSTANTS.FOODBACK.RANK_SCALE_LOW), Validators.max(CONSTANTS.FOODBACK.RANK_SCALE)];
  }

  private manageValidForm(value: GoalsForm): void {
    this.saving = true;
    this.updateGoals$(value)
      .pipe(
        mergeMap(() => this.translateService.get('ACCOUNT.GOALS.NOTIFICATIONS.SUCCESS')),
        takeUntil(this.onDestroy$)
      )
      .subscribe((text: string) => {
        this.saving = false;
        this.notificationService.success(text);
      });
  }

  private updateGoals$(value: GoalsForm): Observable<void> {
    const goals: GoalsUpdateEntry[] = this.prepareUpdateRequestPayload(value);

    if (this.isAccountContext) {
      return this.goalsBackend.updateGoalsForAccount$(this.contextUuid, goals);
    }

    return this.goalsBackend.updateGoalsForVenue$(this.contextUuid, goals);
  }

  private prepareUpdateRequestPayload(value: GoalsForm): GoalsUpdateEntry[] {
    const categories: GoalsUpdateEntry[] = value.categories.map((categoryGoal: number, i: number) => ({
      categoryUuid: this.goals.categories[i].category.uuid,
      targetAverage: this.getGoalValueWithProperPrecision(categoryGoal),
    }));

    categories.push({
      categoryUuid: this.goals.overallExperience.category.uuid,
      targetAverage: this.getGoalValueWithProperPrecision(value.overallExperience),
    });

    return categories;
  }

  private getGoalValueWithProperPrecision(goal: number) {
    return round(goal, this.MAX_GOALS_PRECISION);
  }
}
