import { Directive, ElementRef, HostListener, OnInit } from '@angular/core';
import { cloneDeep, debounce } from 'lodash';
import { DomService } from '../../../core/providers/dom/dom.service';
import { Dictionary } from '../../interfaces/common.interface';

/**
 * This directive sets left, right, top, bottom positions according to the resolution.
 * If absolutely positioned element is too much on the right, it'll move it to the left etc.
 */
@Directive({
  selector: '[appAdjustPosition]',
})
export class AdjustPositionDirective implements OnInit {
  // eslint-disable-next-line no-magic-numbers
  @HostListener('window:resize') onResize = debounce(() => this.setPosition(), 100);
  initialClientRect: ClientRect;
  elementPosition: Dictionary<string> = {
    left: '',
  };

  constructor(private elementRef: ElementRef) {}

  ngOnInit() {
    setTimeout(() => {
      this.setPositionValues();
      this.initialClientRect = cloneDeep(this.elementRef.nativeElement.getBoundingClientRect());
      this.setPosition();
    });
  }

  private setPosition() {
    setTimeout(() => {
      const clientRect: ClientRect = this.getClientRect();

      this.setPositionValues();

      if (clientRect.left + clientRect.width > window.innerWidth) {
        this.initialClientRect = cloneDeep(this.elementRef.nativeElement.getBoundingClientRect());
        DomService.setStyles(this.elementRef.nativeElement, [`left: ${this.elementPosition.left}`]);
      } else if (clientRect.left + clientRect.width < window.innerWidth) {
        this.removeAttributesAndSetPosition();
      }
    });
  }

  private removeAttributesAndSetPosition() {
    if (this.elementRef.nativeElement.hasAttribute('style')) {
      DomService.removeAttributes(this.elementRef.nativeElement, ['style']);
      this.setPosition();
    }
  }

  private getClientRect(): ClientRect {
    const currentClientRect: ClientRect = this.elementRef.nativeElement.getBoundingClientRect();

    return currentClientRect.width && currentClientRect.height
      ? this.elementRef.nativeElement.getBoundingClientRect()
      : this.initialClientRect;
  }

  // TODO: Develop in meantime
  private setPositionValues() {
    const clientRect: ClientRect = this.elementRef.nativeElement.getBoundingClientRect();

    if (clientRect.width) {
      this.elementPosition = {
        left: `-${Math.abs(clientRect.width - (window.innerWidth - clientRect.left))}px`,
      };
    }
  }
}
