import { Pipe, PipeTransform } from '@angular/core';
import { reverse, sortBy } from 'lodash';

@Pipe({
  name: 'descending',
})
export class DescendingPipe implements PipeTransform {
  transform<T>(value: T[], arg?: string): T[] {
    return reverse(sortBy<T>(value, (element: T) => element[arg] || ''.toLowerCase()));
  }
}
