<!--
|                 |                                                       |
|                 |                   H E A D E R                         |
|                 |_______________________________________________________|
|                 |<above container> (e.g. message bars that shouldn't    |
|                 |be padded.                                             |
|                 |<div class="container-lg">                             |
|                 |                                                       |
| S I D E B A R   |                                                       |
|                 |              <ng-content></ng-content>                |
|                 |                                                       |
|                 |                                                       |
|                 |                                                       |
|                 |</div>                                                 |
-->

<ng-content select="[aboveContainer]"></ng-content>
<div class="ContentContainerWrapper">
  <div class="ContentContainer">
    <ng-content></ng-content>
  </div>
</div>
