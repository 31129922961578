import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { DashboardQueryParamsService } from '../../../core/providers/backend/dashboard-query-params.service';
import { UtilsService } from '../../../core/utils/utils.service';
import { CONSTANTS } from '../../constants';
import {
  DateRange,
  DateRangeType,
  DynamicDateRange,
  StaticDateRange,
  StaticDateRangePeriod,
} from '../../interfaces/date-range.interface';
import { IDropdownItem } from '../../interfaces/dropdown/dropdown-item.interface';
import { ContentService } from '../layout/content/content.service';
import {
  DateGroupPickerValue,
  StaticPickerEntry,
} from './date-range-picker.interface';
import { DateRangePickerService } from './date-range-picker.service';

@Component({
  selector: 'app-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss'],
  // eslint-disable-next-line @angular-eslint/use-component-view-encapsulation
  encapsulation: ViewEncapsulation.None,
})
export class DateRangePickerComponent implements OnChanges, OnInit, OnDestroy {
  @Input() showStaticRangeCaption: boolean = true;
  @Input() showAdditionalHeader: boolean = true;
  @Input() set isLoading(value: boolean) {
    this._isLoading = value;

    if (this.timeFrameCtrl) {
      if (this._isLoading) {
        this.timeFrameCtrl.disable({ onlySelf: true, emitEvent: false });
        this.dateRangeFromGroup.disable({ onlySelf: true, emitEvent: false });
      } else {
        this.timeFrameCtrl.enable({ onlySelf: true, emitEvent: false });
        this.dateRangeFromGroup.enable({ onlySelf: true, emitEvent: false });
      }
    }
  }

  get isLoading(): boolean {
    return this._isLoading;
  }

  @Input() minDate: Date;
  @Input() showDayOption: boolean = true;
  @Input() selectedDateRange: DateRange;
  @Output() readonly selectedDateRangeChange: EventEmitter<DateRange> =
    new EventEmitter();
  @Output() readonly selectedTimeFrameChange: EventEmitter<IDropdownItem> = new EventEmitter();
  @ViewChild('container') containerRef;

  readonly maxDate: Date =
    this.dateRangePickerService.getCovertedDateWithTimezone(new Date());
  readonly DateGroupPickerValue: typeof DateGroupPickerValue =
    DateGroupPickerValue;
  readonly DateRangeType: typeof DateRangeType = DateRangeType;
  datePickerModel: { start: any; end: any };
  selectedDateRangeType: DateRangeType = DateRangeType.DYNAMIC;
  timeFrameCtrl: FormControl = new FormControl();
  dateRangeFromGroup: FormGroup = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });
  timeFrames: IDropdownItem[] = [];

  private subscriptions: Subscription = new Subscription(null);
  private enableEventsAfterSettingTimeFrame = true;
  private _isLoading: any;
  private endSelectedDateRange: moment.Moment;
  private startSelectedDateRange: moment.Moment;
  private shouldGetTimeRangeFrameFromStore = false;

  constructor(
    private dateRangePickerService: DateRangePickerService,
    private utilsService: UtilsService,
    private activatedRoute: ActivatedRoute,
    private contentService: ContentService,
    private dashboardQueryParamsService: DashboardQueryParamsService
  ) {}

  previousRange(): void {
    this.setDateRange(true);
  }

  nextRange(): void {
    this.setDateRange();
  }

  dateRangePickerValueChanged(dateRange: {
    start: moment.Moment;
    end: moment.Moment;
  }) {
    // eslint-disable-next-line
    if (dateRange) {
      this.selectedDateRange = {
        start: new Date(
          dateRange.start.format(CONSTANTS.DATES_FORMAT.QUERY_PARAM_DATE) +
            CONSTANTS.DATE_TIME_VALUES.MIDNIGHT
        ),
        end: new Date(
          dateRange.end.format(CONSTANTS.DATES_FORMAT.QUERY_PARAM_DATE) +
            CONSTANTS.DATE_TIME_VALUES.MIDNIGHT
        ),
        groupBy: DateGroupPickerValue.CUSTOM,
      };
      this.prepareAndSaveDateRangeInStore();
      this.selectedDateRangeChange.emit(this.selectedDateRange);
      this.startSelectedDateRange = dateRange.start;
      this.endSelectedDateRange = dateRange.end;
    }
  }

  getTranslateArgForStaticPickerValue(staticPickerEntry: StaticPickerEntry) {
    return { [staticPickerEntry.period]: staticPickerEntry.count };
  }

  getTranslateArgForStaticDateRangeValue(dateRange: DateRange) {
    const staticPickerEntry: StaticDateRange = dateRange as StaticDateRange;

    return { [staticPickerEntry.period]: staticPickerEntry.count };
  }

  getRangeName(dateRange: DateRange): string {
    if (this.dateRangePickerService.assertDateRangeTypeDynamic(dateRange)) {
      return dateRange.groupBy;
    }
    if (dateRange && dateRange.period === StaticDateRangePeriod.ALL_TIME) {
      return 'ALL_TIME';
    }

    return `LAST_X_${dateRange.period}`;
  }

  ngOnInit(): void {
    this.setTimeFramesData();
    this.letSubscribeDateRangeChanges();
    this.letSubscribeTimeFrameChanges();

    if (this.selectedDateRange) {
      this.handleInitialSelectedDateRangeSet();
    } else {
      this.setTimeFrameFromStore();
      this.handleSelectedDateRangeChange();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedDateRange && changes.selectedDateRange.currentValue) {
      this.handleSelectedDateRangeChange();
      this.setTimeFrame();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  dateRangeChange(): void {
    if (
      this.dateRangeFromGroup.value.start &&
      this.dateRangeFromGroup.value.end &&
      !this.isDateRangeSame()
    ) {
      this.dateRangePickerValueChanged({
        start: this.dateRangeFromGroup.value.start,
        end: this.dateRangeFromGroup.value.end,
      });
    }
  }

  private onStaticRangeSelected(pickerValue: any): void {
    this.selectedDateRangeType = DateRangeType.STATIC;
    this.selectedDateRange = {
      period: pickerValue.period,
      count: pickerValue.count,
      groupBy: DateGroupPickerValue.STATIC,
    };
    this.selectedDateRangeChange.emit(this.selectedDateRange);
    this.storeData(
      this.dateRangePickerService.convertStaticDateRangeToCustomDynamic(
        this.selectedDateRange as StaticDateRange
      )
    );
  }

  private onSelectedDateGroupChange(selectedGroup: DateGroupPickerValue): void {
    this.selectedDateRangeType = DateRangeType.DYNAMIC;

    if (selectedGroup === DateGroupPickerValue.CUSTOM) {
      this.selectedDateRange =
        this.dateRangePickerService.transformDateRangeToCustom(
          this.selectedDateRange
        );
      this.datePickerModel = {
        start: this.selectedDateRange.start,
        end: this.selectedDateRange.end,
      };
      this.handleSelectedDateRangeChange();
    } else {
      try {
        const selectedDateRange: any =
          this.dateRangePickerService.getDateRangeInStore();

        if (
          this.shouldGetTimeRangeFrameFromStore &&
          selectedDateRange &&
          selectedDateRange.start &&
          selectedDateRange.groupBy === selectedGroup
        ) {
          this.selectedDateRange = {
            start: new Date(
              selectedDateRange.start + CONSTANTS.DATE_TIME_VALUES.MIDNIGHT
            ),
            end: new Date(
              selectedDateRange.end + CONSTANTS.DATE_TIME_VALUES.MIDNIGHT
            ),
            groupBy: selectedGroup,
          };
        } else {
          this.selectedDateRange =
            this.dateRangePickerService.getInitialRangeForGroup(selectedGroup);
        }

        this.shouldGetTimeRangeFrameFromStore = false;
        this.selectedDateRangeChange.emit(this.selectedDateRange);
      } catch (e) {
        console.error('Incorrect data in session storage');
      }
    }

    this.prepareAndSaveDateRangeInStore();
  }

  private setDateRange(isPrevious = false): void {
    if (
      this.isLoading ||
      !this.dateRangePickerService.assertDateRangeTypeDynamic(
        this.selectedDateRange
      )
    ) {
      return;
    }
    const { start, end, groupBy } = this.selectedDateRange as DynamicDateRange;
    let newStart;
    let newEnd;

    if (isPrevious) {
      const value = -1;

      newStart = this.dateRangePickerService.getStartOfNextRange(
        start,
        groupBy,
        value
      );
      newEnd = this.dateRangePickerService.getEndOfNextRange(
        end,
        groupBy,
        value
      );
      this.resetHours(start, end);

      if (
        moment(newStart).isBefore(this.minDate) &&
        moment(newEnd).isBefore(this.minDate)
      ) {
        return;
      }
    } else {
      this.resetHours(start, end);
      newStart = this.dateRangePickerService.getStartOfNextRange(
        start,
        groupBy
      );
      newEnd = this.dateRangePickerService.getEndOfNextRange(end, groupBy);
      this.resetHours(start, end);
    }

    this.selectedDateRange = {
      groupBy,
      start: newStart,
      end: newEnd,
    };
    this.prepareAndSaveDateRangeInStore();
    this.selectedDateRangeChange.emit(this.selectedDateRange);
  }

  private resetHours(start: Date, end: Date): void {
    start.setHours(0, 0, 0);
    end.setHours(0, 0, 0);
  }

  private letSubscribeTimeFrameChanges(): void {
    this.subscriptions.add(
      this.timeFrameCtrl.valueChanges.subscribe((selectedTimeFrame) => {
        this.selectedTimeFrameChange.emit(selectedTimeFrame);
        this.dateRangePickerService.saveSelectedTimeFrameInStore(
          selectedTimeFrame
        );

        if (this.enableEventsAfterSettingTimeFrame) {
          this.emitEventAfterTimeFrameChange(selectedTimeFrame);
        }
        if (this.containerRef) {
          this.contentService.isHeaderRefresh.next(true);
        }
      })
    );
  }

  private emitEventAfterTimeFrameChange(selectedTimeFrame): void {
    switch (selectedTimeFrame.value.value) {
      case DateGroupPickerValue.STATIC:
        this.onStaticRangeSelected(selectedTimeFrame.value);
        break;
      default:
        this.onSelectedDateGroupChange(selectedTimeFrame.value.value);
        break;
    }
  }

  private letSubscribeDateRangeChanges(): void {
    this.subscriptions.add(
      this.dateRangeFromGroup.controls.end.valueChanges.subscribe((value) => {
        if (value && !this.isDateRangeSame()) {
          // this.dateRangePickerValueChanged({start: this.dateRangeFromGroup.value.start, end: value});
        }
      })
    );
  }

  private isDateRangeSame(): boolean {
    return (
      this.startSelectedDateRange &&
      this.dateRangeFromGroup.value.start &&
      this.dateRangeFromGroup.value.start.isSame(this.startSelectedDateRange) &&
      this.endSelectedDateRange &&
      this.dateRangeFromGroup.value.end &&
      this.dateRangeFromGroup.value.end.isSame(this.endSelectedDateRange)
    );
  }

  private prepareAndSaveDateRangeInStore(): void {
    this.storeData(this.selectedDateRange as DynamicDateRange);
  }

  private storeData(selectedDateRange: DynamicDateRange): void {
    this.dateRangePickerService.saveDateRangeInStore({
      start: this.utilsService.prepareDateForRequest(selectedDateRange.start),
      end: this.utilsService.prepareDateForRequest(selectedDateRange.end),
      groupBy: selectedDateRange.groupBy
        ? selectedDateRange.groupBy
        : DateGroupPickerValue.CUSTOM,
    });
  }

  private handleInitialSelectedDateRangeSet(): void {
    if (
      this.dateRangePickerService.assertDateRangeTypeDynamic(
        this.selectedDateRange
      )
    ) {
      this.datePickerModel = {
        start: this.selectedDateRange.start,
        end: this.selectedDateRange.end,
      };
      this.selectedDateRangeType = DateRangeType.DYNAMIC;
    } else {
      const dynamicDateRange: DynamicDateRange =
        this.dateRangePickerService.convertStaticDateRangeToCustomDynamic(
          this.selectedDateRange
        );

      this.datePickerModel = {
        start: dynamicDateRange.start,
        end: dynamicDateRange.end,
      };
      this.selectedDateRangeType = DateRangeType.STATIC;
    }
  }

  private handleSelectedDateRangeChange(): void {
    if (!this.selectedDateRange) {
      return;
    }
    if (this.selectedDateRange.groupBy === DateGroupPickerValue.STATIC) {
      this.handleSelectedDateRangeForStaticGroups();
    } else {
      this.handleSelectedDateRangeForNonStaticGroups();
    }
    const start = moment(
      this.utilsService.prepareDateForRequest(this.datePickerModel.start)
    );
    const end = moment(
      this.utilsService.prepareDateForRequest(this.datePickerModel.end)
    );

    this.dateRangeFromGroup.setValue({ start, end }, { emitEvent: false });
  }

  private handleSelectedDateRangeForNonStaticGroups(): void {
    this.datePickerModel = {
      start: (this.selectedDateRange as DynamicDateRange).start,
      end: (this.selectedDateRange as DynamicDateRange).end,
    };
    this.selectedDateRangeType = DateRangeType.DYNAMIC;
  }

  private handleSelectedDateRangeForStaticGroups(): void {
    const dynamicDateRange: DynamicDateRange =
      this.dateRangePickerService.convertStaticDateRangeToCustomDynamic(
        this.selectedDateRange as StaticDateRange
      );

    this.datePickerModel = {
      start: dynamicDateRange.start,
      end: dynamicDateRange.end,
    };
    this.selectedDateRangeType = DateRangeType.STATIC;
  }

  private setTimeFrame(): void {
    this.setTimeFramesData();
    this.enableEventsAfterSettingTimeFrame = false;

    switch (this.selectedDateRange.groupBy) {
      case DateGroupPickerValue.STATIC:
        this.setTimeFrameForStaticGroups();
        break;
      case DateGroupPickerValue.CUSTOM:
        this.timeFrameCtrl.setValue(
          this.timeFrames[this.timeFrames.length - 1],
          { emitEvent: false }
        );
        this.selectedTimeFrameChange.emit(this.timeFrames[this.timeFrames.length - 1]);
        break;
      default:
        this.setTimeFrameByDefaultGroup();
    }

    this.enableEventsAfterSettingTimeFrame = true;
    this.dateRangePickerService.saveSelectedTimeFrameInStore(
      this.timeFrameCtrl.value
    );

    if (this.selectedDateRange.groupBy === DateGroupPickerValue.CUSTOM) {
      this.storeData(this.selectedDateRange);
    }
    this.dateRangePickerService.storeAllFoodbackDateRange(moment(this.datePickerModel.start), moment(this.datePickerModel.end));

  }

  private setTimeFrameByDefaultGroup(): void {
    const groupIndex = 2;

    this.timeFrames[groupIndex].subItems.forEach((timeFrame: IDropdownItem) => {
      if (
        (this.selectedDateRange as StaticDateRange).groupBy === timeFrame.id
      ) {
        this.timeFrameCtrl.setValue(timeFrame);
        this.selectedTimeFrameChange.emit(timeFrame);
      }
    });
  }

  private setTimeFrameForStaticGroups(): void {
    if (
      (this.selectedDateRange as StaticDateRange).period ===
      StaticDateRangePeriod.DAYS || StaticDateRangePeriod.MONTHS   
    ) {
      this.setTimeFrameSpecificInDaysGroup();
    } else {
      this.timeFrameCtrl.setValue(this.timeFrames[0], { emitEvent: false });
      this.selectedTimeFrameChange.emit(this.timeFrames[0]);
    }
  }

  private setTimeFrameSpecificInDaysGroup(): void {
    this.timeFrames[1].subItems.forEach((timeFrame: IDropdownItem) => {
      if (
        +(this.selectedDateRange as StaticDateRange).count ===
        timeFrame.value.count
      ) {
        this.timeFrameCtrl.setValue(timeFrame, { emitEvent: false });
        this.selectedTimeFrameChange.emit(timeFrame);
      }
    });
  }

  private setTimeFramesData(): void {
    if (this.timeFrames.length === 0) {
      this.dateRangePickerService.prepareTimeFrames();
      this.timeFrames = this.dateRangePickerService.getTimeFrames();
    }
  }

  private setTimeFrameFromStore(): void {
    if (
      this.dashboardQueryParamsService.isCompleteDashboardQueryParams(
        this.activatedRoute.snapshot.queryParams
      )
    ) {
      this.selectedDateRange =
        this.dashboardQueryParamsService.getDateRangeFromQueryParams(
          this.activatedRoute.snapshot.queryParams
        );
      this.handleSelectedDateRangeChange();
      this.setTimeFrame();
      this.selectedDateRangeChange.emit(this.selectedDateRange);
    } else {
      let timeFrame: IDropdownItem = null;
      let selectedDateRange: any = null;

      try {
        timeFrame = this.dateRangePickerService.getSelectedTimeFrameInStore();
        selectedDateRange = this.dateRangePickerService.getDateRangeInStore();
      } catch (e) {
        console.error('Incorrect data in session storage');
      }
      if (timeFrame && timeFrame.value && selectedDateRange) {
        this.setTimeFrameAndSelectProperDateRange(timeFrame, selectedDateRange);
        this.emitEventAfterTimeFrameChange(timeFrame);
      } else {
        this.setDefaultTimeFrame();
        this.onStaticRangeSelected(this.timeFrameCtrl.value.value);
      }
    }
  }

  private setDefaultTimeFrame(): void {
    this.setTimeFrameAndSelectProperDateRange(
      this.timeFrames.find(
        (tF: IDropdownItem) => tF.id === StaticDateRangePeriod.DAYS
      ).subItems[2],
      {
        period: StaticDateRangePeriod.DAYS,
        count: CONSTANTS.DASHBOARD.DEFAULT_LAST_X_DAYS_COUNT,
        groupBy: DateGroupPickerValue.STATIC,
      }
    );
  }

  private setTimeFrameAndSelectProperDateRange(
    timeFrame: IDropdownItem,
    selectedDateRange: DateRange
  ): void {
    this.shouldGetTimeRangeFrameFromStore = true;

    if (timeFrame.value.value !== DateGroupPickerValue.STATIC) {
      this.setDateRangeWhenIsNotStatic(selectedDateRange, timeFrame);
    }

    this.timeFrameCtrl.setValue(timeFrame, { emitEvent: false });
    this.selectedTimeFrameChange.emit(timeFrame);
  }

  private setDateRangeWhenIsNotStatic(
    selectedDateRange: any,
    timeFrame: IDropdownItem
  ): void {
    if (selectedDateRange && selectedDateRange.begin && selectedDateRange.end) {
      this.setDateRangeForFullPeriod(selectedDateRange);
    } else if (
      selectedDateRange &&
      selectedDateRange.start &&
      selectedDateRange.groupBy
    ) {
      this.setDateRangeWithPeriodSpecificByGroup(selectedDateRange, timeFrame);
    }
  }

  private setDateRangeWithPeriodSpecificByGroup(
    selectedDateRange: any,
    timeFrame: IDropdownItem
  ): void {
    this.selectedDateRange = {
      start: new Date(
        selectedDateRange.start + CONSTANTS.DATE_TIME_VALUES.MIDNIGHT
      ),
      end: new Date(
        selectedDateRange.end + CONSTANTS.DATE_TIME_VALUES.MIDNIGHT
      ),
      groupBy: timeFrame.value.value,
    };
    this.selectedDateRangeChange.emit(this.selectedDateRange);
  }

  private setDateRangeForFullPeriod(selectedDateRange: any): void {
    this.dateRangeFromGroup.setValue({
      begin: moment(selectedDateRange.begin),
      end: moment(selectedDateRange.end),
    });
  }
}
