import { Injectable } from '@angular/core';
import { find, get } from 'lodash';
import { Observable, ReplaySubject } from 'rxjs';
import { distinctUntilChanged, map, mergeMap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { CONSTANTS } from '../../../shared/constants';
import { AccountForBackend, VenueFromBackend } from '../../../shared/form-models-interfaces';
import { ContextObject } from '../../../shared/interfaces/login-object';
import { PermissionsEntry } from '../../../shared/interfaces/permissions.interface';
import { ApiService } from '../api/api.service';
import { ContextService } from '../context/context.service';
import { PermissionsBackendService } from './permissions-backend.service';
import { UsersBackendService } from './users-backend.service';

@Injectable()
export class AccountSingleVenueBackendService {
  private singleVenueMode: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private apiService: ApiService,
    private contextService: ContextService,
    private usersBackendService: UsersBackendService,
    private permissionsBackendService: PermissionsBackendService
  ) {
    this.contextService.getContext$().subscribe((context: ContextObject) => {
      this.handleContextChange(context);
    });
  }

  setSingleVenueMode(isSingleVenueMode: boolean) {
    this.singleVenueMode.next(isSingleVenueMode);
  }

  isSingleVenueMode(): Observable<boolean> {
    return this.singleVenueMode.pipe(distinctUntilChanged());
  }

  changeSingleVenueStatus(accountUuid: string, isSingleVenue: boolean): Observable<void> {
    return this.apiService
      .put$(`${environment.apiBaseUrl.handbook}/fbi/accounts/${accountUuid}/singleVenueStatus`, {
        isSingleVenue,
      })
      .pipe(map(response => response.content));
  }

  private handleContextChange(context: ContextObject) {
    this.getSingleVenueModeStatus(context).subscribe((isSingleVenueMode: boolean) => {
      this.setSingleVenueMode(isSingleVenueMode);
    });
  }

  private getSingleVenueModeStatus(context: ContextObject): Observable<boolean> {
    if (context.type === CONSTANTS.CONTEXT.VENUE) {
      return this.getSingleModeStatusForVenueContext(context.uuid);
    }

    return this.getSingleModeStatusForAccountContext(context.uuid);
  }

  private getSingleModeStatusForAccountContext(accountUuid: string): Observable<boolean> {
    return this.usersBackendService.getSingleAccount$(accountUuid).pipe(map((response: AccountForBackend) => response.isSingleVenueMode));
  }

  private getSingleModeStatusForVenueContext(venueUuid: string): Observable<boolean> {
    return this.usersBackendService.getSingleVenue$(venueUuid).pipe(
      mergeMap((venueForBackend: VenueFromBackend) =>
        this.permissionsBackendService
          .getPermissions$()
          .pipe(map((response: PermissionsEntry[]) => find(response, { accountUuid: venueForBackend.accountUuid })))
      ),
      map((foundPermission: PermissionsEntry) => get(foundPermission, 'isSingleVenueMode', false))
    );
  }
}
