import { AbstractControl } from '@angular/forms';
import { Dictionary } from '../../../interfaces/common.interface';
import { QuestionType } from '../../../interfaces/survey.interface';
import { IOneMoreQuestionOptionFormat } from './one-more-question-format/one-more-question.format.interface';

export class OneMoreQuestionValidator {
  static questionFormatOptions(control: AbstractControl): Dictionary<boolean> {
    const formatType: QuestionType = control.value ? control.value.id : null;

    if (formatType && control.parent && control.parent.get('options').value) {
      const options: IOneMoreQuestionOptionFormat[] =
        control.parent.get('options').value;

      if (
        OneMoreQuestionValidator.isOneQuestionFormatNotValid(
          formatType,
          options
        )
      ) {
        return { questionOptions: true };
      }
    }

    return null;
  }

  static questionFormat(control: AbstractControl): Dictionary<boolean> {
    const formatType: QuestionType = control.value
      ? control.value.formatType
      : null;
    const options: IOneMoreQuestionOptionFormat[] = control.value
      ? control.value.options
      : null;

    if (
      formatType &&
      options &&
      OneMoreQuestionValidator.isOneQuestionFormatNotValid(formatType, options)
    ) {
      return { questionOptions: true };
    }

    return null;
  }

  static isOneQuestionFormatNotValid(
    formatType: QuestionType,
    options: IOneMoreQuestionOptionFormat[]
  ): boolean {
    const shouldFormatHasOptions: boolean =
      formatType !== QuestionType.FREE_TEXT &&
      formatType !== QuestionType.BOOLEAN &&
      formatType !== QuestionType.SCALE;
    const minItemsCount = 2;
    let isOptionEmpty = false;

    for (let i = 0; i < options.length; i++) {
      if (options[i].optionValue === '' || !options[i].optionValue) {
        isOptionEmpty = true;
        break;
      }
    }

    return (
      shouldFormatHasOptions &&
      (options.length < minItemsCount || isOptionEmpty)
    );
  }

  static triggerOptions(control: AbstractControl): Dictionary<boolean> {
    if (control.parent && !control.parent.get('isTriggeredAlways').value) {
      const values: boolean[] = control.value;

      if (!values.find((optionValue: boolean) => optionValue)) {
        return { triggerOptions: true };
      }
    }

    return null;
  }
}
