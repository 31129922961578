import { Injectable } from '@angular/core';
import { AbstractControlOptions, FormArray, FormBuilder, FormControl, ValidatorFn, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { find } from 'lodash';
import { CONSTANTS } from '../../constants';
import { I18nAvailableLanguage } from '../../interfaces/i18n.interface';
import { StatementTranslationFromBackend } from '../../interfaces/statements.interface';
import { QuestionType } from '../../interfaces/survey.interface';
import { LanguageFormGroup } from '../one-more-question/one-more-question-edit/one-more-question-languages-dialog/one-more-question-languages-dialog.component';
import {
  ICampaignFormDialogBody,
  ICampaignTranslatedLanguages,
  ICampaignTranslationQuestionOutput,
  ICampaignWelcomeScreenTranslateInputOutput,
} from './campaigns.interface';

@Injectable()
export class CampaignTranslationService {
  constructor(private formBuilder: FormBuilder, private translateService: TranslateService) {}

  getLanguagesForm(config: ICampaignFormDialogBody): FormArray {
    const languagesForms: FormArray = this.formBuilder.array([]);

    if (config.shouldAddDefaultLanguage) {
      // Add default language form;
      languagesForms.push(this.generateFullQuestionForm(config.defaultLanguage, config, true), { emitEvent: false });
    }

    // Add other active language
    config.languageSet.forEach((lang: I18nAvailableLanguage) => {
      if (lang.code !== config.defaultLanguage.code) {
        languagesForms.push(this.generateFullQuestionForm(lang, config, false), { emitEvent: false });
      }
    });

    return languagesForms;
  }

  getWelcomeScreenTranslationStatus(
    welcomeScreenTranslations: ICampaignWelcomeScreenTranslateInputOutput,
    allActiveLanguages: I18nAvailableLanguage[]
  ): I18nAvailableLanguage[] {
    const translatedLanguages: I18nAvailableLanguage[] = [];

    for (let i = 0; i < allActiveLanguages.length; i++) {
      const language: I18nAvailableLanguage = allActiveLanguages[i];

      if (!this.isLanguageTranslated(language.code, welcomeScreenTranslations.title)) {
        // ||!this.isLanguageTranslated(language.code, welcomeScreenTranslations.subtitle)
        continue;
      }

      translatedLanguages.push(language);
    }

    return translatedLanguages;
  }

  getQuestionTranslatedLanguages(
    questionTranslations: ICampaignTranslationQuestionOutput,
    allActiveLanguages: I18nAvailableLanguage[]
  ): I18nAvailableLanguage[] {
    const translatedLanguages: I18nAvailableLanguage[] = [];

    for (let i = 0; i < allActiveLanguages.length; i++) {
      const language: I18nAvailableLanguage = allActiveLanguages[i];

      if (!this.isLanguageTranslated(language.code, questionTranslations.question)) {
        continue;
      }
      let isTranslated = true;

      if (questionTranslations.questionType === QuestionType.CHECKBOX || questionTranslations.questionType === QuestionType.RADIO_BUTTON) {
        for (let j = 0; j < questionTranslations.alternatives.length; j++) {
          const alternativeTranslations: StatementTranslationFromBackend[] = questionTranslations.alternatives[j];

          if (this.isLanguageTranslated(language.code, alternativeTranslations)) {
            isTranslated = true;
          } else {
            isTranslated = false;
            break;
          }
        }
      }
      if (isTranslated) {
        translatedLanguages.push(language);
      }
    }

    return translatedLanguages;
  }

  getTranslatedLanguages(
    translatedLanguages: ICampaignTranslatedLanguages,
    allActiveLanguages: I18nAvailableLanguage[]
  ): I18nAvailableLanguage[] {
    const allTranslatedLanguages: I18nAvailableLanguage[] = [];

    for (let i = 0; i < allActiveLanguages.length; i++) {
      const language: I18nAvailableLanguage = allActiveLanguages[i];

      if (!translatedLanguages.welcomeScreenTranslatedLanguages.find((lang: I18nAvailableLanguage) => lang.code === language.code)) {
        continue;
      }
      let isTranslated = true;

      for (let j = 0; j < translatedLanguages.questionsTranslatedLanguages.length; j++) {
        isTranslated = !!translatedLanguages.questionsTranslatedLanguages[j].find(
          (lang: I18nAvailableLanguage) => lang.code === language.code
        );

        if (!isTranslated) {
          break;
        }
      }
      if (isTranslated) {
        allTranslatedLanguages.push(language);
      }
    }

    return allTranslatedLanguages;
  }

  isLanguageTranslated(languageCode: string, translations: StatementTranslationFromBackend[]): boolean {
    return translations && translations.length > 0 ? this.getTranslationValueByCode(translations, languageCode) !== '' : false;
  }

  getTranslationValueByCode(translations: StatementTranslationFromBackend[], code: string): string {
    if (!translations) {
      return '';
    }
    const translationPhrase: StatementTranslationFromBackend = translations.find(
      (translation: StatementTranslationFromBackend) => translation.lang === code
    );

    return translationPhrase ? translationPhrase.value : '';
  }

  private generateFullQuestionForm(
    language: I18nAvailableLanguage,
    config: ICampaignFormDialogBody,
    isRequired: boolean
  ): LanguageFormGroup {
    return this.createFormGroup(this.prepareControlOptions(isRequired, config, language), language);
  }

  private prepareControlOptions(
    isRequired: boolean,
    config: ICampaignFormDialogBody,
    language: I18nAvailableLanguage
  ): { [key: string]: any; options?: AbstractControlOptions | null } {
    const validators: Validators[] = [Validators.maxLength(CONSTANTS.VALIDATION.CAMPAIGNS.QUESTION_MAX_LENGTH)];

    if (isRequired) {
      validators.push(Validators.required);
    }

    return config.isQuestionDialog
      ? {
          question: [
            this.getTranslationValueByCode((config.translations as ICampaignTranslationQuestionOutput).question, language.code),
            validators,
          ],
          alternatives: this.getAlternatives(config, language, validators),
        }
      : {
          title: [
            this.getTranslationValueByCode((config.translations as ICampaignWelcomeScreenTranslateInputOutput).title, language.code),
            validators,
          ],
          subTitle: [
            this.getTranslationValueByCode((config.translations as ICampaignWelcomeScreenTranslateInputOutput).subtitle, language.code),
          ],
        };
  }

  getTranslationLabel(translationCount: number, allLanguagesCount: number): string {
    return this.translateService.instant('SHARED.SYSTEM.NUMB_OF_NUMB', {
      0: translationCount,
      1: allLanguagesCount,
    });
  }

  private getAlternatives(config: ICampaignFormDialogBody, language: I18nAvailableLanguage, validators: Validators[]): FormArray {
    const alternatives: FormArray = this.formBuilder.array([]);
    const translations: ICampaignTranslationQuestionOutput = config.translations as ICampaignTranslationQuestionOutput;

    for (let i = 0; i < translations.alternatives.length; i++) {
      alternatives.push(
        new FormControl(this.getTranslationValueByCode(translations.alternatives[i], language.code), validators as ValidatorFn[])
      );
    }

    return alternatives;
  }

  private createFormGroup(
    controlsConfig: {
      [key: string]: any;
      options?: AbstractControlOptions | null;
    },
    language: I18nAvailableLanguage
  ): LanguageFormGroup {
    const formGroup: LanguageFormGroup = this.formBuilder.group(controlsConfig) as LanguageFormGroup;

    formGroup.language = language;

    return formGroup;
  }
}
