import { Injectable } from '@angular/core';
import { cloneDeep, find } from 'lodash';
import { BehaviorSubject, Observable, combineLatest as observableCombineLatest } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { FoodbackAccount } from '../../../core/classes/account/account.class';
import { AccountService } from '../../../core/providers/account/account.service';
import { AccountSingleVenueBackendService } from '../../../core/providers/backend/account-single-venue-backend.service';
import { ContextService } from '../../../core/providers/context/context.service';
import { SidebarSettingsMenus } from '../../../shared/enums/sidebar/sidebar-settings-menus.enum';
import { Dictionary } from '../../../shared/interfaces/common.interface';
import { ContextObject } from '../../../shared/interfaces/login-object';
import { SidebarMenu } from '../../../shared/interfaces/sidebar/sidebar-menu.interface';
import { SidebarConstants } from '../sidebar.constants';
import { SidebarService } from '../sidebar.service';

@Injectable()
export class SidebarSettingsService {
  private sidebarMenu$: BehaviorSubject<SidebarMenu> = new BehaviorSubject(cloneDeep(SidebarConstants.SETTINGS.SIDEBAR));

  constructor(
    private accountService: AccountService,
    private contextService: ContextService,
    private accountSingleVenueBackendService: AccountSingleVenueBackendService,
    private sidebarService: SidebarService
  ) {
    this.initSidebarMenu();
    this.updateSidebarMenu();
  }

  getSidebarMenu$(): Observable<SidebarMenu> {
    return this.sidebarMenu$.pipe(filter((sidebarMenu: SidebarMenu) => sidebarMenu !== null));
  }

  private getOptionalSubmenu(type: SidebarSettingsMenus): SidebarMenu {
    return find(SidebarConstants.SETTINGS.OPTIONAL_SUBMENUS, { type });
  }

  private getCampignSubmenu(type: SidebarSettingsMenus): SidebarMenu {
    return find(SidebarConstants.SETTINGS.CAMPAIGNS_SETTNGS, { type });
  }

  private getTakeawaySubmenu(type: SidebarSettingsMenus): SidebarMenu {
    return find(SidebarConstants.SETTINGS.TAKEAWAY_SETTINGS, { type });
  }

  private prepareSidebar(account: FoodbackAccount, isAccountContext: boolean, uuid: string, isSingleVenueMode: boolean) {
    const sidebarMenu: SidebarMenu = cloneDeep(SidebarConstants.SETTINGS.SIDEBAR);

    if (isAccountContext) {
      sidebarMenu.submenu.push(this.getOptionalSubmenu(SidebarSettingsMenus.VENUE_MANAGEMENT));

      if (!isSingleVenueMode) {
        sidebarMenu.submenu.push(this.getOptionalSubmenu(SidebarSettingsMenus.GROUPS));
      }
    } else {
      sidebarMenu.submenu.push(this.getTakeawaySubmenu(SidebarSettingsMenus.FOODBACK_PORTAL));
    }
    if (isAccountContext || isSingleVenueMode) {
      sidebarMenu.submenu.push(this.getOptionalSubmenu(SidebarSettingsMenus.GOALS));
    }

    sidebarMenu.submenu.push(this.getTakeawaySubmenu(SidebarSettingsMenus.WHEEL_OF_FORTUNE));

    if (this.sidebarService.isAccountWithCampaign(account.uuid)) {
      sidebarMenu.submenu.push(this.getCampignSubmenu(SidebarSettingsMenus.CAMPAIGNS));
    }

    this.setSidebarMenu(this.initSidebarURLs(sidebarMenu, isAccountContext), isAccountContext, uuid);
  }

  private setSidebarMenu(sidebarMenu: SidebarMenu, isAccountContext: boolean, uuid: string) {
    this.sidebarMenu$.next(this.sidebarService.getSidebarMenu([sidebarMenu], isAccountContext, uuid)[0]);
  }

  private initSidebarURLs(sidebarMenu: SidebarMenu, isAccountContext: boolean): SidebarMenu {
    const URLS: Dictionary<string> = isAccountContext ? SidebarConstants.SETTINGS.ACCOUNT_URLS : SidebarConstants.SETTINGS.VENUE_URLS;

    sidebarMenu.submenu = sidebarMenu.submenu.map((submenu: SidebarMenu) => {
      submenu.link = URLS[submenu.type.toString()];

      return submenu;
    });

    return sidebarMenu;
  }

  private updateSidebarMenu() {
    observableCombineLatest([
      this.accountService.getAccount$(),
      this.contextService.getContext$(),
      this.accountSingleVenueBackendService.isSingleVenueMode(),
    ]).subscribe(([account, context, isSingleVenueMode]: [FoodbackAccount, ContextObject, boolean]) => {
      this.prepareSidebar(account, ContextService.isAccountContext(context), context.uuid, isSingleVenueMode);
    });
  }

  private initSidebarMenu() {
    this.contextService
      .getContext$()
      .pipe(first())
      .subscribe((context: ContextObject) => {
        const isAccountContext: boolean = ContextService.isAccountContext(context);

        this.setSidebarMenu(this.initSidebarURLs(SidebarConstants.SETTINGS.SIDEBAR, isAccountContext), isAccountContext, context.uuid);
      });
  }
}
